import React, { useEffect } from 'react'
import { createGlobalState } from 'react-use'

import { Checkbox, Col, Form, Input, Row, Select } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'

import { ISeo, SeoType } from 'types/seo.type'

export const DEFAULT_SEO_INFO: ISeo = {
  seoId: '',
  title: '',
  keywords: [],
  canonical: '',
  robots: [],
  description: '',
  type: SeoType.Bounty,
}

export const useSeoInfoState = createGlobalState<ISeo>(DEFAULT_SEO_INFO)

const robotsOptions = [
  { label: 'Index', value: 'index' },
  { label: 'No Index', value: 'noindex' },
  { label: 'No Follow', value: 'nofollow' },
  { label: 'No Archive', value: 'noarchive' },
  { label: 'No Snippet', value: 'nosnippet' },
  { label: 'No Image Index', value: 'noimageindex' },
  { label: 'None', value: 'none' },
]

function FormSeoInfo() {
  const [seoInfo, setSeoInfo] = useSeoInfoState()

  const [form] = Form.useForm()

  useEffect(() => {
    if (seoInfo?.seoId) {
      form.setFieldsValue(seoInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoInfo?.seoId])

  /**
   * Clear SEO info when form unmount
   */
  useEffect(() => {
    return () => {
      setSeoInfo(DEFAULT_SEO_INFO)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(changedValues) => {
        setSeoInfo((prev) => ({ ...prev, ...changedValues }))
      }}
    >
      <Form.Item
        name="title"
        label={<RequiredLabel children="Title" required={false} />}
        rules={[{ required: false }]}
      >
        <Input placeholder="Enter title" />
      </Form.Item>

      <Form.Item
        name="keywords"
        label={<RequiredLabel children="Keywords" required={false} />}
        rules={[{ required: false }]}
      >
        <Select
          mode="tags"
          placeholder="Enter keywords"
          dropdownStyle={{ display: 'none' }}
        />
      </Form.Item>

      <Form.Item
        name="canonical"
        label={<RequiredLabel children="Canonical" required={false} />}
        rules={[{ required: false }]}
      >
        <Input placeholder="https://space3.gg/example" />
      </Form.Item>

      <Form.Item
        name="robots"
        label={
          <RequiredLabel children="Robots (Meta Robot)" required={false} />
        }
        rules={[{ required: false }]}
      >
        <Checkbox.Group>
          <Row>
            {robotsOptions.map((option) => (
              <Col span={12} key={option.value}>
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        name="description"
        label={<RequiredLabel children="Description" required={false} />}
        rules={[{ required: false }]}
      >
        <Input placeholder="Enter description" />
      </Form.Item>
    </Form>
  )
}

export default FormSeoInfo
