import { Typography } from 'antd'

import { ColumnProps } from 'antd/es/table'

import { PlayerRankDataResponse } from 'types/player-leaderboard/player-leaderboard-rank.type'

export const teamColumn: ColumnProps<
  PlayerRankDataResponse & { previousRanking?: number }
>[] = [
  {
    title: 'Rank',
    dataIndex: 'ranking',
    render: (ranking) => <Typography.Text>{ranking ?? '-'}</Typography.Text>,
  },
  {
    title: 'Last rank',
    dataIndex: 'previousRanking',
    render: (previousRanking) => (
      <Typography.Text>{previousRanking ?? '-'}</Typography.Text>
    ),
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    render: (userId) => <Typography.Text>{userId}</Typography.Text>,
  },
  {
    title: 'Point',
    dataIndex: 'points',
    render: (points) => <Typography.Text>{points}</Typography.Text>,
  },
]
