import { Radio } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'
import SpaceVertical from 'components/systems/spaceVertical'

import { convertToStartCase } from 'helper'

import { ValueOf } from 'constant'
import { BountySubcategory } from 'constant/bounty'
import { CreateBounty } from 'services/bountySystem/bounty'

type SubcategoryProps = {
  value?: BountySubcategory
  onChange: (name: keyof CreateBounty, value: ValueOf<CreateBounty>) => void
}

const Subcategory = ({ onChange, value }: SubcategoryProps) => {
  return (
    <SpaceVertical>
      <RequiredLabel>Subcategory</RequiredLabel>
      <Radio.Group
        onChange={(e) => onChange('subcategory', e.target.value)}
        value={value}
      >
        {Object.keys(BountySubcategory).map((item) => {
          const key = item as keyof typeof BountySubcategory
          const value = BountySubcategory[key]
          return (
            <Radio value={value} key={key}>
              {convertToStartCase(item)}
            </Radio>
          )
        })}
      </Radio.Group>
    </SpaceVertical>
  )
}

export default Subcategory
