export enum AttributeComponent {
  Select = 'CPN:SELECT',
  Input = 'CPN:INPUT',
}

export enum LeaderboardPoint {
  TEAM = 'LBP:TEAM',
  COMMUNITY = 'LBP:COMMUNITY',
}

export type AttributeConfig = {
  title: string
  options: string[]
  type: AttributeComponent
  placeholder: string
  hint: string
  required: boolean
}

export interface IProductCategory {
  title: string
  description: string
  variationAttributes: AttributeConfig[]
  specificationConfig: AttributeConfig[]
  deliveryConfig: AttributeConfig[]
}
