import React from 'react'

import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd'

import {
  MinusCircleOutlined as MinusCircleIcon,
  PlusCircleOutlined as PlusCircleIcon,
} from '@ant-design/icons'

import InputImage from 'components/systems/uploadPicture/inputImage'
import RequiredLabel from 'components/systems/requiredSymbol'

import { COMMUNITY_PROFILE_DEFAULT_SECTION } from 'constant/community-profile'

import { MediaType } from 'types/community-profile'

type FormItemOverviewType = {
  form: FormInstance
}

function FormItemOverview({ form }: FormItemOverviewType) {
  return (
    <>
      <Form.Item
        label={<RequiredLabel children="Title" />}
        name="title"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Title is required!',
          },
          {
            max: 400,
            message: 'Title must be less than 400 characters!',
          },
        ]}
      >
        <Input placeholder="Title" />
      </Form.Item>

      <Form.Item
        label={<RequiredLabel children="Description" />}
        name="description"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Description is required!',
          },
          {
            max: 400,
            message: 'Description must be less than 400 characters!',
          },
        ]}
      >
        <Input placeholder="Description" />
      </Form.Item>

      <Form.List name="media">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row
                align="top"
                justify="end"
                gutter={8}
                style={{ position: 'relative' }}
                key={index}
              >
                <Col span={4} style={{ marginTop: 10, height: '100%' }}>
                  {index === 0 && <RequiredLabel children="Media" />}
                </Col>
                <Col span={19}>
                  <Row justify="center">
                    <Typography.Text
                      type="warning"
                      style={{ marginBottom: 12 }}
                    >
                      Media {index + 1}
                    </Typography.Text>
                  </Row>

                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item
                        key={field.name}
                        name={[field.name, 'type']}
                        label={<RequiredLabel children="Type" />}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Type is required!',
                          },
                        ]}
                      >
                        <Select
                          options={[
                            { label: 'Image', value: MediaType.Image },
                            { label: 'Video', value: MediaType.Video },
                          ]}
                          onChange={(value) => {
                            const media = form.getFieldValue('media')
                            media[field.name].type = value

                            return form.setFieldsValue({ media })
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        key={field.name}
                        name={[field.name, 'url']}
                        label={<RequiredLabel children="Url" />}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'URL is required!',
                          },
                        ]}
                      >
                        {form.getFieldValue(['media', field.name, 'type']) ===
                          MediaType.Image && (
                          <InputImage
                            key={field.name}
                            value={form.getFieldValue([
                              'media',
                              field.name,
                              'url',
                            ])}
                            onChange={(value) => {
                              const media = form.getFieldValue('media')
                              media[field.name].url = value

                              return form.setFieldsValue({ media })
                            }}
                          />
                        )}

                        <Input
                          value={form.getFieldValue([
                            'media',
                            field.name,
                            'url',
                          ])}
                          onChange={(e) => {
                            const media = form.getFieldValue('media')
                            media[field.name].url = e.target.value

                            return form.setFieldsValue({ media })
                          }}
                          placeholder="Enter url"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={1} style={{ position: 'unset' }}>
                  <Row justify="end" align="middle">
                    <MinusCircleIcon
                      style={{
                        color: 'red',
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        fontSize: 16,
                      }}
                      onClick={() => remove(field.name)}
                    />
                  </Row>
                </Col>
              </Row>
            ))}

            {form.getFieldValue('media')?.length < 5 && (
              <Form.Item wrapperCol={{ span: 20 }}>
                <Row justify="center" style={{ width: '100%' }}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      return add({
                        ...COMMUNITY_PROFILE_DEFAULT_SECTION.media[0],
                      })
                    }}
                    icon={<PlusCircleIcon />}
                  >
                    Add Media
                  </Button>
                </Row>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </>
  )
}

export default FormItemOverview
