import { ReactNode } from 'react'

import { Avatar, Image, Space, Typography } from 'antd'
import IonIcon from '../ionIcon'

import { RewardType } from 'constant/reward'

import iconNFT from 'static/images/icon/ico-nft.svg'

export type RewardAvatarItemProps = {
  size?: number
  icon?: ReactNode
  rewardType: RewardType
}
const RewardAvatarItem = ({
  size = 49,
  icon = <IonIcon name="diamond-outline" />,
  rewardType,
  ...props
}: RewardAvatarItemProps) => {
  const nftType = rewardType === RewardType.NFTBadge
  const fontSize = nftType ? size / 7 : 'inherit'

  return (
    <Avatar src={rewardType} size={size} className="reward-avatar" {...props}>
      <Space
        direction="vertical"
        size={0}
        className="space-middle-icon"
        align="center"
      >
        {nftType && <Image src={iconNFT} preview={false} width={size / 2} />}
        <Typography.Text
          className="gradient-text"
          style={{ fontSize, fontWeight: 700, lineHeight: '115%' }}
        >
          {nftType ? 'BADGE' : rewardType}
        </Typography.Text>
      </Space>
    </Avatar>
  )
}

export default RewardAvatarItem
