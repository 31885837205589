import { Dispatch, SetStateAction, useMemo } from 'react'

import { Button, Col, Dropdown, MenuProps, Radio, Row, Space } from 'antd'

import { AdsSortOptions } from 'types/ads.type'

const SORT_OPTIONS = [
  {
    label: 'Newest',
    value: AdsSortOptions.Newest,
  },
  {
    label: 'Priority',
    value: AdsSortOptions.Priority,
  },
]

type ControlBarProps = {
  sort: AdsSortOptions
  onChangeSort: Dispatch<SetStateAction<AdsSortOptions>>
}

function ControlBar({ sort, onChangeSort }: ControlBarProps) {
  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: (
          <Row onClick={(e) => e.stopPropagation()}>
            <Col span={24}>
              <Radio.Group
                onChange={(e) => onChangeSort(e.target.value)}
                defaultValue={sort}
              >
                <Space direction="vertical" size={16}>
                  {SORT_OPTIONS.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        ),
        key: '0',
      },
    ],
    [onChangeSort, sort],
  )

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      overlayClassName="sort-filter-container--dropdown"
    >
      <Button style={{ width: '100%' }} ghost>
        Sort
      </Button>
    </Dropdown>
  )
}

export default ControlBar
