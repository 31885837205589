import { Fragment } from 'react'

import { Button, Col, Form, Row, Select } from 'antd'

import RequiredLabel from 'components/systems/requiredSymbol'

import { useAcceler8SeasonsInfinite } from 'hooks/acceler8/useAcceler8SeasonsInfinite'
import { useSyncAcceler8RankingMutation } from 'hooks/acceler8/useSyncAcceler8RankingMutation'

import { notifyError, notifySuccess } from 'helper'

import { IAcceler8Season } from 'types/acceler8/acceler8-season.type'

const initialData: {
  targetSeason: IAcceler8Season | null
  previousSeason: IAcceler8Season | null
} = {
  targetSeason: null,
  previousSeason: null,
}

const Acceler8SyncRanking = () => {
  const [form] = Form.useForm()
  const previousSeason: IAcceler8Season | null = Form.useWatch(
    'previousSeason',
    form,
  )

  const {
    data: seasonData,
    hasNextPage,
    isLoading: isLoadMoreLoading,
    fetchNextPage,
  } = useAcceler8SeasonsInfinite()

  const handleChangeTargetSeason = async (targetSeasonId: number) => {
    const sortedSeasonsData = [...seasonData].sort(
      (seasonA, seasonB) =>
        new Date(seasonA.startedAt).getTime() -
        new Date(seasonB.startedAt).getTime(),
    )

    const targetSeasonIndex = sortedSeasonsData.findIndex(
      (season) => season.id === targetSeasonId,
    )
    const previousSeasonIndex = targetSeasonIndex - 1

    const targetSeason = sortedSeasonsData[targetSeasonIndex]
    const previousSeason = sortedSeasonsData[previousSeasonIndex]

    form.setFieldValue('targetSeason', targetSeason)
    if (previousSeason) {
      form.setFieldValue('previousSeason', previousSeason)
    }
  }

  const handleChangePreviousSeason = (previousSeasonId: number) => {
    const previousSeason = seasonData.find(
      (season) => season.id === previousSeasonId,
    )

    if (previousSeason) {
      form.setFieldValue('previousSeason', previousSeason)
    }
  }

  const syncRanking = async () => {
    try {
      const value = await form.validateFields()

      onSyncRanking(
        {
          currentSeasonId: value.targetSeason.id,
          previousSeasonId: value.previousSeason.id,
        },
        {
          onSuccess: () => {
            notifySuccess('Sync ranking')
          },
          onError: () => {
            notifyError('Sync ranking')
          },
        },
      )
    } catch (error) {
      // to prevent un catch error
    }
  }

  const { mutate: onSyncRanking, isLoading } = useSyncAcceler8RankingMutation()

  return (
    <Form form={form} layout="vertical" initialValues={initialData}>
      <Form.Item
        label={<RequiredLabel children="Target season" required={false} />}
        rules={[{ required: true, message: 'Please enter target season' }]}
        name="targetSeason"
        valuePropName="dummy_prop"
      >
        <Select
          placeholder="Target season"
          onChange={handleChangeTargetSeason}
          options={seasonData.map((season) => ({
            label: `${season.title}: ${season.subTitle}`,
            value: season.id,
          }))}
          dropdownRender={(menu) => (
            <Fragment>
              {menu}
              {hasNextPage && (
                <Row>
                  <Col span={24}>
                    <Button
                      onClick={() => fetchNextPage()}
                      style={{ width: '100%' }}
                      loading={isLoadMoreLoading}
                    >
                      Load more
                    </Button>
                  </Col>
                </Row>
              )}
            </Fragment>
          )}
        />
      </Form.Item>

      <Form.Item
        label={<RequiredLabel children="Previous season" required={false} />}
        rules={[{ required: true, message: 'Please enter previous season' }]}
        name="previousSeason"
        valuePropName="dummy_props"
      >
        <Select
          placeholder="Previous season"
          onChange={handleChangePreviousSeason}
          options={seasonData.map((season) => ({
            label: `${season.title}: ${season.subTitle}`,
            value: season.id,
          }))}
          dropdownRender={(menu) => (
            <Fragment>
              {menu}
              {hasNextPage && (
                <Row>
                  <Col span={24}>
                    <Button
                      onClick={() => fetchNextPage()}
                      style={{ width: '100%' }}
                      loading={isLoadMoreLoading}
                    >
                      Load more
                    </Button>
                  </Col>
                </Row>
              )}
            </Fragment>
          )}
          value={previousSeason?.id}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={syncRanking}
          loading={isLoading}
        >
          Sync
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Acceler8SyncRanking
