import { Col, Input, Row } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'

export type BasicData = { title: string }

const BasicInformation = ({
  data,
  onChange,
}: {
  data: BasicData
  onChange: (data: BasicData) => void
}) => {
  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} xl={14}>
        <RequiredLabel>Category name</RequiredLabel>
      </Col>
      <Col xs={24} xl={14}>
        <Input
          placeholder="Enter category name"
          value={data.title}
          onChange={(e) => onChange({ ...data, title: e.target.value })}
        />
      </Col>
    </Row>
  )
}

export default BasicInformation
