import { Button, Col, Row, Table, Typography } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'

import { COLUMN_TEAM_LEADERBOARD } from './column'
import IonIcon from 'components/systems/ionIcon'
import { WrapLoading } from 'components/systems/loading'
import FilterSearch from 'components/systems/filter/search'
import CustomPagination from 'components/systems/pagination'

import useDelaySearch from 'hooks/useDelaySearch'
import { usePagination } from 'hooks/usePagination'
import { useTeamsLeaderboard } from 'hooks/leaderboard/useTeamsLeaderboard'

import { ROUTES } from 'constant/routes'

type ListTeamProps = {
  seasonId: string
}

const ListTeamsLeaderboard = ({ seasonId }: ListTeamProps) => {
  const navigate = useNavigate()

  const { page, offset, limit, setPage } = usePagination()
  const [searchTerm, setSearchTerm] = useDelaySearch(500, 'search_teams')

  const { data, total, isLoading } = useTeamsLeaderboard({
    seasonId,
    offset,
    limit,
    search: searchTerm,
  })

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row justify="space-between" gutter={[12, 12]}>
          <Col span={8}>
            <FilterSearch
              placeholder="Search team name..."
              setSearch={setSearchTerm}
            />
          </Col>

          <Col style={{ textAlign: 'right' }}>
            <Button
              className="btn-filled"
              icon={<IonIcon name="add-outline" />}
              onClick={() =>
                navigate(
                  generatePath(ROUTES.LEADERBOARD.NEW_TEAM_LEADERBOARD, {
                    seasonId,
                  }),
                )
              }
            >
              Add new team
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <Table
            rowKey="_id"
            className="table-border-collapse community-table"
            columns={COLUMN_TEAM_LEADERBOARD}
            dataSource={data}
            pagination={false}
          />
        </WrapLoading>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {data?.length ? (
            <Col>
              <CustomPagination
                total={total || 0}
                onChange={setPage}
                page={page}
              />
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}

export default ListTeamsLeaderboard
