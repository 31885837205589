import { Typography } from 'antd'
import type { ColumnType } from 'antd/lib/table'

import MultiplierRowAction from './Action'
import TotalWalletsColumn from './TotalWalletsColumn'
import SpaceVertical from 'components/systems/spaceVertical'

import { shortenAddressCustom } from 'helper'

import { IMultiplier } from 'types/multiplier.type'

export const MULTIPLIERS_COLUMNS: ColumnType<IMultiplier>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name: string) => <Typography.Text>{name}</Typography.Text>,
    width: '25%',
  },
  {
    title: '% Multiplier',
    dataIndex: 'multiplier',
    render: (multiplier: number) => (
      <SpaceVertical>
        <Typography.Text>{multiplier * 100}%</Typography.Text>
      </SpaceVertical>
    ),
  },
  {
    title: 'Contract Address',
    dataIndex: ['configs', 'nftConfig', 'contractAddress'],
    render: (contractAddress: string | undefined) => (
      <Typography.Text>
        {shortenAddressCustom(contractAddress, 6, 6)}
      </Typography.Text>
    ),
  },
  {
    title: 'N.o Wallets',
    dataIndex: ['configs', 'whitelistConfig', 'whitelistId'],
    render: (whitelistId: string) => (
      <TotalWalletsColumn whitelistId={whitelistId} />
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    render: (_, record) => <MultiplierRowAction record={record} />,
    width: '10%',
  },
]
