import { Fragment, ReactNode } from 'react'
import { Badge } from 'antd'

type DisableProps = {
  disabled: boolean
  children: ReactNode
  message?: string
  bgColor?: string
}

const Disabled = ({ disabled, children, message, bgColor }: DisableProps) => {
  if (disabled) {
    return (
      <Badge.Ribbon text={message} color={bgColor}>
        <div style={{ cursor: 'not-allowed' }}>
          <div
            style={{ opacity: 0.5, pointerEvents: 'none' }}
            aria-disabled={disabled}
          >
            {children}
          </div>
        </div>
      </Badge.Ribbon>
    )
  }
  return <Fragment>{children}</Fragment>
}

export default Disabled
