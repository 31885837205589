import { Fragment, useState } from 'react'
import Papa from 'papaparse'

import {
  Button,
  Col,
  Image,
  Row,
  Space,
  Typography,
  Upload,
  UploadFile,
} from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'

import IonIcon from 'components/systems/ionIcon'

import { notifyError, notifySuccess } from 'helper'

import { FILE_SIZE_ONE_MB } from 'constant/marketplace'

import iconUpload from 'static/images/bounty/upload.svg'
import csvLogo from 'static/images/bounty/csvLogo.svg'

import { IWalletWhitelist } from 'types/whitelist.type'

type UploadCSVProps = {
  onUploadSuccess: () => void
  onSetAddressList: React.Dispatch<React.SetStateAction<IWalletWhitelist[]>>
}
const UploadCSV = ({ onUploadSuccess, onSetAddressList }: UploadCSVProps) => {
  const [error, setError] = useState(false)
  const [data, setData] = useState<UploadChangeParam<UploadFile<any>>>()

  const handleUpload = () => {
    if (!data) return

    try {
      const file = data.fileList[0]
      Papa.parse(file.originFileObj as File, {
        header: true,
        skipEmptyLines: true,
        complete: ({ data }) => {
          const wallets: IWalletWhitelist[] = []
          const walletSet = new Set(
            data
              .filter((item: any) => !!item?.wallet)
              .map((item: any) => item.wallet),
          )
          walletSet.forEach((wallet) => wallets.push({ wallet }))

          onSetAddressList(wallets as IWalletWhitelist[])
        },
      })

      onUploadSuccess()
      notifySuccess('File uploaded')
    } catch (error) {
      return notifyError(error)
    }
  }

  const onRemove = () => {
    setData(undefined)
    setError(false)
    onSetAddressList([])
  }

  return (
    <Fragment>
      <Row gutter={[0, 38]} justify="center">
        <Col span={24}>
          <Space
            align="center"
            size={4}
            direction="vertical"
            style={{ width: '100%' }}
          >
            <Typography.Title type="success" level={4}>
              Upload CSV
            </Typography.Title>
            <Typography.Text type="secondary">
              You can upload CSV file with maximum size is 20MB
            </Typography.Text>
          </Space>
        </Col>
        <Col span={24}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Upload.Dragger
              accept=".csv"
              maxCount={1}
              className={error ? 'upload-file error' : 'upload-file'}
              beforeUpload={() => false}
              onChange={(result) =>
                new Promise((resolve, reject) => {
                  return Papa.parse(result.file as any, {
                    complete: (_, fileInfo) => {
                      if (fileInfo.size > FILE_SIZE_ONE_MB * 20)
                        return reject('File to large')
                      setData(result)
                      return resolve(result)
                    },
                  })
                })
              }
              showUploadList={false}
              progress={{ strokeWidth: 2, showInfo: true }}
            >
              {!data ? (
                <Space direction="vertical" align="center">
                  <Image src={iconUpload} preview={false} />
                  <Typography.Text type="secondary">
                    Upload file
                  </Typography.Text>
                </Space>
              ) : (
                <Space direction="vertical" size={12} align="center">
                  <Image src={csvLogo} preview={false} />
                  <Typography.Text>{data.file.name}</Typography.Text>
                </Space>
              )}
            </Upload.Dragger>
            {error && (
              <Space align="center">
                <IonIcon
                  style={{ color: '#ff4d4f' }}
                  name="alert-circle-outline"
                />
                <Typography.Text type="danger">
                  Upload file size exceeds 10MB limit
                </Typography.Text>
              </Space>
            )}
          </Space>
        </Col>
        <Col>
          <Space size={20}>
            <Button onClick={onRemove} style={{ width: 130 }} ghost>
              Reset
            </Button>
            <Button
              onClick={handleUpload}
              style={{ width: 130 }}
              type="primary"
              disabled={!data}
            >
              Upload
            </Button>
          </Space>
        </Col>
      </Row>
    </Fragment>
  )
}

export default UploadCSV
