import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Card,
  Col,
  Image,
  Input,
  Modal,
  type ModalProps,
  Row,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd'
import { WrapLoading } from 'components/systems/loading'
import Disabled from 'components/systems/disable'

import { useUserNFTCheckActive } from 'hooks/evm/useUserNft'
import { useA8NftInfinite } from 'hooks/nft/useA8NftInfinite'

import { UserNFTData } from 'store/walletNFT.reducer'

import { convertTokenUrl, shortenAddress } from 'helper'

import { ChainID } from 'constant'

import defaultNftThumbnail from 'static/images/icon/ico-nft.svg'

export type NftSelectPayload = Pick<
  UserNFTData,
  'tokenId' | 'tokenAddress' | 'tokenStandard' | 'amount'
>

type SelectNftProps = {
  value: UserNFTData
  onClose: () => void
  onSelect: (nft: NftSelectPayload) => void
} & ModalProps

function SelectNft({ value, onSelect, onClose, ...props }: SelectNftProps) {
  // Currently, Only support NFT from A8 chain
  const { data, isLoading, hasNextPage, fetchNextPage } = useA8NftInfinite()

  return (
    <Modal
      {...props}
      onCancel={() => onClose && onClose()}
      style={{ maxWidth: 932 }}
      footer={null}
      width="unset"
      destroyOnClose
    >
      <SelectNftModalContent
        nftInfos={Object.values(data).map((nft: any) => {
          return {
            ...nft,
            uniqueAddress: `${nft.tokenId}-${nft.tokenAddress}`,
            address: nft.tokenAddress,
            collection: nft.collectionName,
            thumbnail: nft.image,
          }
        })}
        loadingNFTs={isLoading}
        onChange={onSelect}
        selectedNFT={value}
        onClose={() => {
          onClose && onClose()
        }}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </Modal>
  )
}

type ModalContentProps = {
  nftInfos: UserNFTData[]
  selectedNFT: UserNFTData
  onChange: (nft: NftSelectPayload) => void
  onClose: () => void
  loadingNFTs?: boolean
  hasNextPage?: boolean
  fetchNextPage: () => void
  setItemWeb3Id?: (val: string) => void
}

const SelectNftModalContent = ({
  nftInfos,
  onChange,
  onClose,
  hasNextPage,
  loadingNFTs = false,
  fetchNextPage,
  selectedNFT,
}: ModalContentProps) => {
  const checkActive = useUserNFTCheckActive()
  const [searching, setSearching] = useState(false)
  const [allowNFTWithoutInfo, setAllowNFTWithoutInfo] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [nftData, setNftData] = useState(nftInfos)
  const [selectedNftState, setSelectedNftState] = useState(selectedNFT)

  const chainName = useMemo(() => {
    return ChainID.A8.split(':')[1]
  }, [])

  const onSearching = (searchValue: string) => {
    setSearching(true)
    setSearchValue(searchValue)
  }

  const onSwitch = (checked: boolean) => {
    setAllowNFTWithoutInfo(checked)
  }

  const filterNftData = useCallback(
    (searchValue: string) => {
      const filteredNftData = nftInfos.filter(
        (item) =>
          (!allowNFTWithoutInfo ? item.name && item.thumbnail : true) &&
          item.name?.toLowerCase()?.includes(searchValue.toLowerCase()),
      )

      setNftData(filteredNftData)
    },
    [nftInfos, allowNFTWithoutInfo],
  )

  const onConfirm = async () => {
    if (!selectedNftState) return

    const { tokenId, tokenAddress, tokenStandard, amount } = selectedNftState

    onChange({ tokenAddress, tokenId, tokenStandard, amount: amount ?? 1 })
    onClose()
  }

  useEffect(() => {
    if (searching) {
      setTimeout(() => {
        filterNftData(searchValue)
        setSearching(false)
      }, 500)
    }
  }, [searching, searchValue, filterNftData])

  // update every time NFT props changed
  useEffect(() => {
    setNftData(
      !allowNFTWithoutInfo
        ? nftInfos.filter((nft) => nft.name && nft.thumbnail)
        : nftInfos,
    )
  }, [nftInfos, allowNFTWithoutInfo])

  return (
    <Row gutter={[40, 40]}>
      <Col>
        <Row justify="center" gutter={[16, 16]} wrap={true}>
          <Col>
            <Typography.Title level={3}>Select NFT</Typography.Title>
          </Col>
          <Col span={24}>
            <Space
              style={{ width: '100%' }}
              direction="vertical"
              align="center"
            >
              <Typography.Text>
                {nftData.length > 0
                  ? `You are selecting an NFT from wallet ${shortenAddress(
                      nftData[0]?.ownerOf,
                    )} on ${chainName} Chain`
                  : `You are selecting an NFT from Admin's wallet on ${chainName} Chain`}
              </Typography.Text>
            </Space>
          </Col>
          <Col>
            <Input.Search
              className="search-nft-input"
              placeholder="Search NFT"
              onChange={(e) => onSearching(e.target.value)}
              loading={searching}
              style={{ width: 400, borderRadius: 4 }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        className="nft-scroll-view"
        style={{ width: '100%', maxHeight: '443px', overflowY: 'scroll' }}
      >
        <WrapLoading loading={loadingNFTs} size={52} type="stick">
          <Row>
            <Space
              style={{ width: '100%', justifyContent: 'center' }}
              size={14}
              wrap={true}
            >
              {!nftData.length && (
                <Typography.Text>No NFT found!</Typography.Text>
              )}

              {!!nftData.length &&
                nftData.map((nft) => {
                  const {
                    collection,
                    amount,
                    name,
                    tokenId,
                    thumbnail,
                    image,
                    web3ItemId,
                    tokenStandard,
                  } = nft
                  const uniqueAddress = `${tokenId}-${nft.address}`

                  return (
                    <Disabled
                      key={uniqueAddress}
                      disabled={!checkActive({ web3ItemId })}
                      bgColor="linear-gradient(90deg, #09BEBD 0%, #03C281 49.27%, #6AD743 100%)"
                      message="NFT was listed on the market"
                    >
                      <Card
                        hoverable={true}
                        key={uniqueAddress}
                        style={{ maxWidth: 159 }}
                        bodyStyle={{
                          padding: 0,
                          border:
                            uniqueAddress === selectedNftState.uniqueAddress
                              ? '2px solid #42BAB0'
                              : '2px solid #242F30',
                          position: 'relative',
                        }}
                        onClick={() => setSelectedNftState(nft)}
                      >
                        <Image
                          src={convertTokenUrl(thumbnail ?? image)}
                          fallback={defaultNftThumbnail}
                          preview={false}
                          height={153}
                          width={153}
                        />
                        <Row
                          gutter={[4, 4]}
                          style={{ padding: '6px 12px 8px' }}
                        >
                          <Col span={24}>
                            <Typography.Text
                              style={{ fontSize: 12 }}
                              ellipsis={true}
                              type="secondary"
                            >
                              {collection ?? 'Default Collection'}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text
                              style={{ fontSize: 12 }}
                              ellipsis={true}
                              type="secondary"
                            >
                              Total: {amount}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text strong ellipsis={true}>
                              {name ?? `NFT Item #${tokenId}`}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Tag
                          style={{
                            position: 'absolute',
                            top: 5,
                            right: 0,
                            borderRadius: 6,
                          }}
                          color="rgba(0,0,0,0.6)"
                        >
                          {tokenStandard}
                        </Tag>
                      </Card>
                    </Disabled>
                  )
                })}
            </Space>
          </Row>
          {!!nftData.length && hasNextPage && (
            <Row justify="center" style={{ marginTop: 10 }}>
              <Col>
                <Button
                  type="dashed"
                  size="small"
                  shape="round"
                  style={{ borderRadius: 2 }}
                  onClick={() => fetchNextPage()}
                  loading={loadingNFTs}
                  disabled={!hasNextPage}
                >
                  Load More
                </Button>
              </Col>
            </Row>
          )}
        </WrapLoading>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Space size={10}>
              <Switch checked={allowNFTWithoutInfo} onChange={onSwitch} />
              <Typography.Text>Show NFT with no information</Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space size={10}>
              <Col>
                <Typography.Text strong type="secondary">
                  {/*Footer*/}
                </Typography.Text>
                <Button
                  type="primary"
                  style={{ width: 129, borderRadius: 2 }}
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              </Col>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default SelectNft
