import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import slugify from 'slugify'

import { Button, Col, Form, Input, Row, Select, Switch, Typography } from 'antd'

import RequiredLabel from 'components/systems/requiredSymbol'
import SpaceVertical from 'components/systems/spaceVertical'

import { useSeason } from 'hooks/leaderboard/useSeason'
import { SeasonService } from 'services/bountySystem/season'

import { CreateAndUpdateSeasonDto, SeasonStage } from 'types/season.type'

import { getSpecialChars, notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

const DEFAULT_FORM_DATA: CreateAndUpdateSeasonDto = {
  name: '',
  slug: '',
  stage: SeasonStage.InComing,
  teamLeaderboardEnabled: true,
  communityLeaderboardEnabled: false,
}

const CreateOrUpdateSeason = () => {
  const navigate = useNavigate()
  const seasonId = useParams().seasonId || ''

  const { seasonData, refetch } = useSeason(seasonId)

  const [form] = Form.useForm()
  const nameValue = Form.useWatch('name', form) || ''

  const { mutate, isLoading } = useMutation(
    (data: CreateAndUpdateSeasonDto) => {
      return seasonId
        ? SeasonService.updateSeason(seasonId, data)
        : SeasonService.createSeason(data)
    },
    {
      onSuccess: () => {
        notifySuccess(`${seasonId ? 'Updated' : 'Created'} season`)
        refetch()
        navigate(ROUTES.LEADERBOARD.INDEX)
      },
      onError: (error: any) => {
        notifyError(error)
      },
    },
  )

  const title = !seasonId ? 'Add Season' : 'Update Season'

  const handleSubmit = (values: CreateAndUpdateSeasonDto) => {
    mutate(values)
  }

  const onChangeSlug = () => {
    const seasonName: string = form.getFieldValue('name')
    const newSlug = slugify(seasonName, {
      lower: true,
      remove: getSpecialChars(),
    })

    form.setFieldsValue({
      slug: newSlug,
    })
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonData])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={seasonData || DEFAULT_FORM_DATA}
        >
          <Row>
            <Col xs={24} xl={14}>
              <SpaceVertical>
                <RequiredLabel>Name (5-64 characters)</RequiredLabel>

                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'Please input the name!' },
                    {
                      min: 5,
                      max: 64,
                      message: 'Name must be between 5 - 64 characters.',
                    },
                  ]}
                  extra={
                    <>
                      <Typography.Text type="secondary">
                        Please use the "|" to split the highlight text. &nbsp;
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        Eg: The Winter | 2023 {'->'} &nbsp;
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        <span>Result: </span>
                        <span style={{ color: '#fff' }}>The Winter</span>{' '}
                        <span style={{ color: '#1890ff' }}>2023</span>
                      </Typography.Text>
                    </>
                  }
                >
                  <Input placeholder="Enter name..." />
                </Form.Item>
              </SpaceVertical>
            </Col>

            <Col xs={24} xl={14}>
              <SpaceVertical>
                <RequiredLabel>Slug</RequiredLabel>
                <Row gutter={8} style={{ width: '100%' }}>
                  <Col flex="1">
                    <Form.Item
                      name="slug"
                      rules={[
                        { required: true, message: 'Slug is not empty!' },
                      ]}
                    >
                      <Input placeholder="Slug..." />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      ghost
                      disabled={nameValue.length < 5}
                      onClick={onChangeSlug}
                    >
                      Generate Slug
                    </Button>
                  </Col>
                </Row>
              </SpaceVertical>
            </Col>

            <Col xs={24} xl={14}>
              <SpaceVertical>
                <RequiredLabel>Stage</RequiredLabel>
                <Form.Item
                  name="stage"
                  rules={[
                    { required: true, message: 'Please select the stage!' },
                  ]}
                >
                  <Select placeholder="Select stage...">
                    <Select.Option value={SeasonStage.InComing}>
                      In-coming
                    </Select.Option>
                    <Select.Option value={SeasonStage.Live}>Live</Select.Option>
                    <Select.Option value={SeasonStage.Archived}>
                      Archived
                    </Select.Option>
                  </Select>
                </Form.Item>
              </SpaceVertical>
            </Col>

            <Col xs={24} xl={14}>
              <SpaceVertical>
                <Typography.Text type="secondary">
                  Team Leaderboard Status
                </Typography.Text>
                <Form.Item
                  name="teamLeaderboardEnabled"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the team status!',
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </SpaceVertical>
            </Col>

            <Col xs={24} xl={14}>
              <SpaceVertical>
                <Typography.Text type="secondary">
                  Community Leaderboard Status
                </Typography.Text>
                <Form.Item
                  name="communityLeaderboardEnabled"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the community status!',
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </SpaceVertical>
            </Col>

            <Col span={24}>
              <Form.Item>
                <Button type="primary" loading={isLoading} htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default CreateOrUpdateSeason
