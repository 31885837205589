import { useState } from 'react'
import { useDebounce } from 'react-use'

import { Input, type InputProps } from 'antd'

import IonIcon from 'components/systems/ionIcon'

type SearchBarProps = {
  setSearch: (keyword: string) => void
} & Omit<InputProps, 'onChange'>

const SearchBar = ({
  setSearch,
  placeholder = 'Input keyword...',
  ...props
}: SearchBarProps) => {
  const [keyword, setKeyword] = useState('')

  useDebounce(() => setSearch(keyword), 500, [keyword])

  return (
    <Input
      {...props}
      onChange={(e) => setKeyword(e.target.value)}
      suffix={<IonIcon name="search-outline" />}
      placeholder={placeholder}
    />
  )
}

export default SearchBar
