import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'

export const MENU_ADMIN_ITEMS = [
  {
    label: 'Config',
    key: ROUTES.CONFIG.INDEX,
    icon: <IonIcon name="settings" />,
    children: [
      {
        label: 'Hero Banner',
        key: ROUTES.CONFIG.HEROBANNER,
      },
      {
        label: 'Pin On Top',
        key: ROUTES.CONFIG.PRIORITIES,
      },
    ],
  },
  {
    label: 'Communities',
    key: ROUTES.COMMUNITIES.INDEX,
    icon: <IonIcon name="cart" />,
    children: [
      {
        label: 'Management',
        key: ROUTES.COMMUNITIES.MANAGEMENT,
      },
    ],
  },
  {
    label: 'Acceler8',
    key: ROUTES.ACCELER8.INDEX,
    icon: <IonIcon name="airplane-outline" />,
    children: [
      {
        label: 'Subscription',
        key: ROUTES.ACCELER8.SUBSCRIPTION_MANAGEMENT,
      },
      {
        label: 'Seasons',
        key: ROUTES.ACCELER8.MANAGE_SEASON,
      },
      {
        label: 'Tasks',
        key: ROUTES.ACCELER8.TASK_MANAGEMENT,
      },
      {
        label: 'Teams',
        key: ROUTES.ACCELER8.TEAM_MANAGEMENT,
      },
    ],
  },
  {
    label: 'Player Leaderboard',
    key: ROUTES.PLAYER_LEADERBOARD.INDEX,
    icon: <IonIcon name="accessibility" />,
    children: [
      {
        label: 'Seasons',
        key: ROUTES.PLAYER_LEADERBOARD.SEASON_MANAGEMENT,
      },
      {
        label: 'Tasks',
        key: ROUTES.PLAYER_LEADERBOARD.TASK_MANAGEMENT,
      },
      {
        label: 'Users',
        key: ROUTES.PLAYER_LEADERBOARD.USER_MANAGEMENT,
      },
      {
        label: 'Redemptions',
        key: ROUTES.PLAYER_LEADERBOARD.REDEMPTION_MANAGEMENT,
      },
    ],
  },
  {
    label: 'Affiliation',
    key: ROUTES.AFFILIATION.INDEX,
    icon: <IonIcon name="link-outline" />,
    children: [
      {
        label: 'Subscription',
        key: ROUTES.AFFILIATION.SUBSCRIPTION_MANAGEMENT,
      },
      {
        label: 'Teams',
        key: ROUTES.AFFILIATION.TEAM_MANAGEMENT,
      },
    ],
  },
  {
    label: 'Leaderboard',
    key: ROUTES.LEADERBOARD.INDEX,
    icon: <IonIcon name="cart" />,
  },
  {
    label: 'Rewards Center',
    key: ROUTES.REWARDS_CENTER.INDEX,
    icon: <IonIcon name="cart" />,
    children: [
      {
        label: 'Item Management',
        key: ROUTES.REWARDS_CENTER.ITEMS,
      },
      {
        label: 'Users Shipping',
        key: ROUTES.REWARDS_CENTER.USER_SHIPPING,
      },
      {
        label: 'Category Management',
        key: ROUTES.REWARDS_CENTER.CATEGORY_MANAGEMENT,
      },
      {
        label: 'Collection Management',
        key: ROUTES.REWARDS_CENTER.COLLECTION_MANAGEMENT,
      },
      {
        label: 'Digital Cards Management',
        key: ROUTES.REWARDS_CENTER.DIGITAL_CARDS_MANAGEMENT,
      },
    ],
  },
  {
    label: 'Gacha',
    key: ROUTES.GACHA.INDEX,
    icon: <IonIcon name="medal-outline" />,
    children: [
      {
        label: 'Gacha Management',
        key: ROUTES.GACHA.MANAGEMENT,
      },
      {
        label: 'Mint ticket',
        key: ROUTES.GACHA.MINT_TICKET,
      },
    ],
  },
  {
    label: 'Missions',
    key: ROUTES.BOUNTY.ITEM,
    icon: <IonIcon name="gift" />,
    children: [
      {
        label: 'Mission Management',
        key: ROUTES.BOUNTY.INDEX,
      },
      {
        label: 'CCP Leaderboard',
        key: ROUTES.BOUNTY.CCP_LEADERBOARD,
      },
      {
        label: 'Summary Report',
        key: ROUTES.BOUNTY.REPORT,
      },
      {
        label: 'Mission Multiplier',
        key: ROUTES.BOUNTY.MULTIPLIER_MANAGEMENT,
      },
    ],
  },
  {
    label: 'NFT service',
    key: ROUTES.NFT_SERVICE.ITEM,
    icon: <IonIcon name="folder-open-outline" />,
    children: [
      {
        label: 'NFT Management',
        key: ROUTES.NFT_SERVICE.INDEX,
      },
    ],
  },
  {
    label: 'Badge Management',
    key: ROUTES.NFT_BADGE.INDEX,
    icon: <IonIcon name="ribbon" />,
  },
  {
    label: 'Setting',
    key: ROUTES.COM_SETTING.INDEX,
    icon: <IonIcon name="cog" />,
  },
  {
    label: 'Daily Check-In',
    key: ROUTES.DAILY_CHECK_IN.INDEX + 1,
    icon: <IonIcon name="gift" />,
    children: [
      {
        label: 'Streak Setting',
        key: ROUTES.DAILY_CHECK_IN.INDEX,
      },

      {
        label: 'Summary Report',
        key: ROUTES.DAILY_CHECK_IN.REPORT,
      },
    ],
  },
  {
    label: 'Campaign Management',
    key: ROUTES.CAMPAIGN.INDEX,
    icon: <IonIcon name="megaphone" />,
  },
  {
    label: 'Ads Management',
    key: ROUTES.ADS.ADS_MANAGEMENT,
    icon: <IonIcon name="easel-outline" />,
  },
  {
    label: 'Redemption Management',
    key: ROUTES.REDEMPTION.INDEX,
    icon: <IonIcon name="layers-outline" />,
  },
  {
    label: 'Whitelist Management',
    key: ROUTES.WHITELIST.WHITELIST_MANAGEMENT,
    icon: <IonIcon name="layers-outline" />,
  },
]
