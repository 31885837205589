import { useContext, useEffect, useState } from 'react'

import { Button, Form, FormInstance, Modal, Row, Typography } from 'antd'

import { WhitelistModalContext } from './CreateUpdateContext'
import InputLabel from 'components/systems/input/InputLabel'
import UploadImageSync from 'components/systems/uploadPicture/UploadImageSync'

import { useWhitelist } from 'hooks/whitelist/useWhitelist'
import { useCreateWhitelist } from 'hooks/whitelist/useCreateWhitelist'
import { useUpdateWhitelist } from 'hooks/whitelist/useUpdateWhitelist'
import { useWhitelistDetail } from 'hooks/whitelist/useWhitelistDetail'

import { notifyError, notifySuccess } from 'helper'

import { CreateWhitelistDto, UpdateWhitelistDto } from 'types/whitelist.type'

function CreateUpdateModal() {
  const { isModalOpen, isEdit, selectedWhitelistId, closeModal } = useContext(
    WhitelistModalContext,
  )
  const [form] = Form.useForm()

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => {
        closeModal()
        form.resetFields()
      }}
      footer={null}
    >
      <Typography.Title
        level={3}
        type="success"
        style={{ textAlign: 'center', marginBottom: 16 }}
      >
        {isEdit ? 'Edit Whitelist' : 'Create New Whitelist'}
      </Typography.Title>

      <SaveWhitelistForm
        hideModal={closeModal}
        form={form}
        isEdit={isEdit}
        selectedWhitelistId={selectedWhitelistId}
      />
    </Modal>
  )
}

type SaveWhitelistFormProps = {
  selectedWhitelistId?: string
  isEdit: boolean
  form: FormInstance<CreateWhitelistDto | UpdateWhitelistDto>
  hideModal: () => void
}

const SaveWhitelistForm = ({
  selectedWhitelistId,
  isEdit,
  form,
  hideModal,
}: SaveWhitelistFormProps) => {
  const image = Form.useWatch('thumbnail', form)
  const [isCustomImage, setIsCustomImage] = useState(false)
  const { mutateAsync: createWhitelist, isLoading: isCreating } =
    useCreateWhitelist()
  const { mutateAsync: updateWhitelist, isLoading: isUpdating } =
    useUpdateWhitelist()
  const { data: whitelistDetail } = useWhitelistDetail(selectedWhitelistId)
  const { refetchRoot } = useWhitelist({})

  const handleSubmit = async (
    values: CreateWhitelistDto | UpdateWhitelistDto,
  ) => {
    try {
      if (isEdit) {
        if (!selectedWhitelistId) return
        await updateWhitelist({
          whitelistId: selectedWhitelistId,
          payload: values,
        })
      } else {
        await createWhitelist(values as CreateWhitelistDto)
      }

      notifySuccess('Save Whitelist')
      await refetchRoot()
      hideModal()
    } catch (error) {
      notifyError('Save Whitelist Failed')
    }
  }

  useEffect(() => {
    setIsCustomImage(!!image)
  }, [image])

  useEffect(() => {
    if (isEdit && whitelistDetail) {
      form.setFieldsValue(whitelistDetail)
    }
  }, [whitelistDetail, isEdit, form])

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Item
        name="title"
        rules={[{ required: true, message: 'Please enter title' }]}
      >
        <InputLabel label="Name" placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        name="description"
        rules={[{ required: true, message: 'Please enter description' }]}
      >
        <InputLabel label="Description" placeholder="Enter description" />
      </Form.Item>

      <InputLabel.Switch
        label="Custom Image Upload"
        checked={isCustomImage}
        onChange={setIsCustomImage}
      />

      <Form.Item
        name="thumbnail"
        style={{
          height: isCustomImage ? 'fit-content' : 0,
          transition: 'all 0.3s ease-in-out',
          overflow: 'hidden',
          marginTop: isCustomImage ? 16 : 0,
        }}
        rules={[
          {
            validator: (_, value) => {
              if (isCustomImage && !value) {
                return Promise.reject(new Error('Image is required'))
              }

              return Promise.resolve()
            },
          },
        ]}
      >
        <UploadImageSync />
      </Form.Item>

      <Row justify="space-between">
        <Button type="primary" danger onClick={hideModal}>
          Cancel
        </Button>

        <Button
          type="primary"
          loading={isCreating || isUpdating}
          htmlType="submit"
        >
          Save
        </Button>
      </Row>
    </Form>
  )
}

export default CreateUpdateModal
