import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { createGlobalState } from 'react-use'

import { Button, Col, Row } from 'antd'

import { useCommunityProfile } from 'hooks/community/useCommunityProfile'
import { CommunitySectionType } from 'types/community-profile'

import PreviewCommunitySection from 'view/community/management/profile/previewCommunitySection'

import './index.less'

export const useNewCommunitySection = createGlobalState<{
  type: CommunitySectionType | undefined
}>()

const suffixClsMap = {
  [CommunitySectionType.Overview]: 'overview',
  [CommunitySectionType.System]: 'system-featured',
  [CommunitySectionType.Featured]: 'featured-content',
  [CommunitySectionType.Member]: 'team-members',
  [CommunitySectionType.Company]: 'company',
  [CommunitySectionType.OfficialLink]: 'official-link',
}

function CommunityProfile() {
  const communityId = useParams().communityId || ''

  const { data: communitySections } = useCommunityProfile(communityId)

  const [createNewCommunitySection, setCreateNewCommunitySection] =
    useNewCommunitySection()

  useEffect(() => {
    setCreateNewCommunitySection({ type: undefined })
  }, [communitySections, setCreateNewCommunitySection])

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          {(
            Object.keys(
              CommunitySectionType,
            ) as (keyof typeof CommunitySectionType)[]
          ).map((value, index) => {
            return (
              <Col key={index}>
                <Button
                  type="primary"
                  className={`btn-create-new-section-${
                    suffixClsMap[CommunitySectionType[value]]
                  }`}
                  onClick={() => {
                    setCreateNewCommunitySection({
                      type: CommunitySectionType[value],
                    })
                  }}
                >
                  New {value} Section
                </Button>
              </Col>
            )
          })}
        </Row>
      </Col>

      {communitySections &&
        communitySections.map((section, index) => (
          <Col span={24} key={index}>
            <PreviewCommunitySection sectionData={section} />
          </Col>
        ))}

      {createNewCommunitySection?.type && (
        <Col span={24}>
          <PreviewCommunitySection type={createNewCommunitySection.type} />
        </Col>
      )}
    </Row>
  )
}

export default CommunityProfile
