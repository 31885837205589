import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

export const usePlayerSeasonBefore = (seasonId?: number) => {
  return useQuery({
    queryKey: ['GET_PLAYER_SEASON_BEFORE', seasonId],
    queryFn: () => PlayerLeaderboardService.getPlayerSeasonBefore(seasonId!),
    enabled: !!seasonId,
  })
}
