import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Data } from 'types'
import { IProductData } from 'types/product.type'

export type ProductState = Record<string, Data<IProductData>>

/**
 * Store constructor
 */

const NAME = 'products'
const initialState: ProductState = {}

/**
 * Actions
 */

export const upsetProduct = createAsyncThunk<
  Data<ProductState>,
  { productId: string; data: Data<IProductData> },
  { state: any }
>(`${NAME}/upsetProduct`, async ({ productId, data }, { getState }) => {
  if (!data) throw new Error('Data is empty')
  const { products } = getState()
  const clnProducts = { ...products }

  if (!clnProducts[productId])
    return { [productId]: { ...data, thumbnail: data.thumbnails[0] } }

  clnProducts[productId] = { ...data, thumbnail: data.thumbnails[0] }
  return clnProducts
})

export const removeProduct = createAsyncThunk(
  `${NAME}/removeProduct`,
  async (productId: string) => {
    return productId
  },
)

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(
        upsetProduct.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        removeProduct.fulfilled,
        (state, { payload }) => void delete state[payload],
      ),
})

export default slice.reducer
