import { useParams } from 'react-router-dom'

import { Col, Row, Space, Typography } from 'antd'

import ListNftMetadata from './listNftMetadata'

import { useNftDetail } from 'hooks/nftService/useNftDetail'

import { ChainLabel } from 'constant'

export default function NftMetadataManagement() {
  const nftId = useParams().nftId
  const { data: nft } = useNftDetail(nftId!)

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          NFT Metadata
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={8}>
          <div>
            <Typography.Text type="success">Chain: </Typography.Text>
            <Typography.Text>
              {nft?.chainId ? ChainLabel[nft.chainId] : ''}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="success">Address: </Typography.Text>
            <Typography.Text>{nft?.address}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="success">NFT ID: </Typography.Text>
            <Typography.Text>{nft?._id}</Typography.Text>
          </div>
        </Space>
      </Col>

      <Col span={24}>
        <ListNftMetadata />
      </Col>
    </Row>
  )
}
