import { useQuery } from 'react-query'

import { AffiliationService } from 'services/affiliation-v2'

export const useAffiliationTeamData = (teamId: number) => {
  return useQuery({
    queryKey: ['GET_AFFILIATION_TEAM', teamId],
    queryFn: () => AffiliationService.findTeamData(teamId),
    enabled: !!teamId,
  })
}
