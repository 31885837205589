import { useMutation } from 'react-query'

import { RedemptionService } from 'services/bountySystem/redemption'

import { CreateRedemptionDto } from 'types/redemption.type'

export const useCreateRedemption = () =>
  useMutation({
    mutationKey: ['CREATE_REDEMPTION'],
    mutationFn: (dto: CreateRedemptionDto) => RedemptionService.create(dto),
  })
