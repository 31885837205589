import moment from 'moment'

import { Typography } from 'antd'

import OverallAction from './overallAction'
import ActivityAction from './activityAction'

import { DATE_FORMAT, TIME_FORMAT } from 'constant'

import {
  Acceler8ActivityType,
  Acceler8ActivityTypeLabel,
  ISubscriptionActivity,
  ISubscriptionOverall,
  Acceler8SubscriptionStatus,
  SubscriptionStatusLabel,
} from 'types/acceler8/acceler8-subscription.type'

export const COLUMN_OVERALL = [
  {
    title: 'Game Name',
    dataIndex: 'gameName',
    render: (name: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {name}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Total money spent',
    dataIndex: 'package',
    render: (packageInfo: any) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        $ {packageInfo.price}
      </Typography.Text>
    ),
  },
  {
    title: 'Acceler8 status',
    dataIndex: 'status',
    render: (status: Acceler8SubscriptionStatus) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {SubscriptionStatusLabel[status]}
      </Typography.Text>
    ),
  },
  {
    title: 'Active and Expire dates',
    dataIndex: 'activeDate',
    render: (text: string, record: any) => (
      <Typography.Text>
        {moment.utc(record.startedAt).format(DATE_FORMAT)} (UTC) -{' '}
        {moment.utc(record.endedAt).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: '',
    width: 62,
    render: (data: ISubscriptionOverall) => <OverallAction item={data} />,
  },
]

export const COLUMN_ACTIVITIES = [
  {
    title: 'Game Name',
    dataIndex: 'gameName',
    render: (name: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {name}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Activity',
    dataIndex: 'type',
    render: (type: Acceler8ActivityType) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {Acceler8ActivityTypeLabel[type]}
      </Typography.Text>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'total',
    render: (total: number) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        ${total}
      </Typography.Text>
    ),
  },
  {
    title: 'Time',
    dataIndex: 'activityDate',
    render: (activityDate: string) => (
      <Typography.Text>
        {moment.utc(activityDate).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: '',
    width: 62,
    render: (data: ISubscriptionActivity) => <ActivityAction item={data} />,
  },
]
