export const updateByPath = (obj: any, path: string, value: any) => {
  const pathArr = path.split('.')
  const key = pathArr.pop()
  const target = pathArr.reduce((obj, key) => {
    if (typeof obj[key] !== 'object' && obj[key] !== null) {
      obj[key] = {}
    }
    return obj[key]
  }, obj)
  target[key as string] = value
  return obj
}

export const getFromPath = (obj: any, path: string) => {
  const pathArr = path.split('.')
  const key = pathArr.pop()
  let target: any = obj
  for (const path of pathArr) {
    if (target[path] === undefined) {
      return undefined
    }
    target = target[path]
  }
  return target?.[key as string]
}

export function removeUndefined<T extends object>(origin: T): T {
  const copiedObj: any = {}
  for (const key in origin) {
    if (origin[key] !== undefined) {
      copiedObj[key] = origin[key]
    }
  }
  return copiedObj
}
