import { RankDataResponse } from './acceler8-rank.type'

export interface ITeam {
  id: number
  teamName: string
  referralUsername: string
  leaderId: string
  leaderTelegramId: string
  teamAvatar?: string
  createdAt: string
  removedAt: string | null
}

export enum TeamSortOptions {
  CurrentSeasonRank = 'TEAM_SORT:RANK',
  LastPointContributed = 'TEAM_SORT:LAST_CONTRIBUTED',
}

export type FindTeamsRankDto = {
  seasonId: number | null
  search?: string
  sort: TeamSortOptions
  limit?: number
  offset?: number
}

export type TeamRank = ITeam & {
  points: number | null
  ranking: number | null
}

export type TeamAccumulatedPoints = {
  teamId: number
  accumulativePoints: number
}

export type TeamProfile = ITeam & {
  currentRank?: RankDataResponse | null
  lastRank?: RankDataResponse | null
  totalMember: number
}
