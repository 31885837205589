import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Dropdown, MenuProps } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { TableAffiliationV2Actions } from 'constant/affiliation-v2'
import { ROUTES } from 'constant/routes'
import { SeasonAffiliationData } from 'types/affiliation.type'

const ACTION_ITEMS: MenuProps['items'] = [
  {
    label: TableAffiliationV2Actions.Edit,
    key: TableAffiliationV2Actions.Edit,
  },
  {
    label: TableAffiliationV2Actions.Detail,
    key: TableAffiliationV2Actions.Detail,
  },
]

type RowActionProps = { seasonData: SeasonAffiliationData }
const RowAction = ({ seasonData }: RowActionProps) => {
  const { _id: seasonId } = seasonData
  const navigate = useNavigate()

  const onClickAction = useCallback(
    (key: string) => {
      switch (key) {
        case TableAffiliationV2Actions.Edit:
          // TODO: implement later
          return navigate(`${ROUTES.BOUNTY.EDIT}/${seasonId}`)
        case TableAffiliationV2Actions.Detail:
          // TODO: implement later
          return navigate(`${ROUTES.BOUNTY.EDIT}/${seasonId}`)
      }
    },
    [navigate, seasonId],
  )

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Button
        trigger={['click']}
        type="text"
        icon={<IonIcon name="ellipsis-vertical-outline" />}
        menu={{
          items: ACTION_ITEMS,
          onClick: (menuInfo) => onClickAction(menuInfo.key),
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  )
}

export default RowAction
