import { Typography } from 'antd'

export const REFERRAL_PERCENT_COLUMN = [
  {
    title: 'Level',
    dataIndex: 'level',
    render: (level: number) => <Typography.Text>{level}</Typography.Text>,
  },
  {
    title: 'Percent',
    dataIndex: 'percent',
    render: (percent: number) => (
      <Typography.Text>{percent * 100}%</Typography.Text>
    ),
  },
]
