import { useNavigate } from 'react-router-dom'

import { Button, Col, Row, Typography } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import ListCommunities from './listCommunities'
import './listCommunities/column/index.less'
import CustomPagination from 'components/systems/pagination'

import { useCommunities } from 'hooks/community/useCommunities'
import { usePagination } from 'hooks/usePagination'
import useDelaySearch from 'hooks/useDelaySearch'

import { ROUTES } from 'constant/routes'

const CommunityManagement = () => {
  const navigate = useNavigate()
  const { page, offset, limit, setPage } = usePagination()

  const [searchTerm, setSearchTerm] = useDelaySearch(500, 'search')

  const { communities, total, isLoading } = useCommunities({
    offset,
    limit,
    search: searchTerm,
  })

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Community Management
        </Typography.Title>
      </Col>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Button
          className="btn-filled"
          icon={<IonIcon name="add-outline" />}
          onClick={() => navigate(ROUTES.COMMUNITIES.NEW_COMMUNITY)}
        >
          Add new community
        </Button>
      </Col>

      <Col span={24}>
        <ListCommunities
          isLoading={isLoading}
          data={communities}
          setSearch={setSearchTerm}
        />
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {communities.length ? (
            <Col>
              <CustomPagination total={total} onChange={setPage} page={page} />
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}

export default CommunityManagement
