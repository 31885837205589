import { Row, Col, Typography } from 'antd'
import FeaturedCommunityInput from 'view/config/heroBanner/featuredCommunityInput'

import FeaturedInput from './featuredInput'
import { FeaturedItemType } from 'services/bountySystem/featuredConfig/type'

const HeroBanner = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Hero Banner
        </Typography.Title>
      </Col>
      <Col span={24}>
        <FeaturedInput type={FeaturedItemType.BOUNTY} />
      </Col>
      <Col span={24}>
        <FeaturedInput type={FeaturedItemType.PRODUCT} />
      </Col>
      <Col span={24}>
        <FeaturedCommunityInput />
      </Col>
    </Row>
  )
}

export default HeroBanner
