import { generatePath, useNavigate } from 'react-router-dom'

import { Button } from 'antd'

import { ROUTES } from 'constant/routes'

type ActionProps = {
  teamId: number
}

const Action = ({ teamId }: ActionProps) => {
  const navigate = useNavigate()

  return (
    <Button
      ghost
      onClick={() =>
        navigate(
          generatePath(ROUTES.ACCELER8.TEAM_DETAILS, {
            teamId,
          }),
        )
      }
    >
      Details
    </Button>
  )
}

export default Action
