export enum VerificationMethod {
  Default = 'VERIF:DEFAULT',
  PoW = 'VERIF:POW',
}

export enum TaskCertFactorName {
  Wallet = 'wallet',
  Email = 'email',
  TwitterId = 'twitterId',
  DiscordId = 'discordId',
}
