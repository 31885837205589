import { Typography } from 'antd'

import { useWhitelistWalletsCounter } from 'hooks/multiplier/useMultiplierWalletsCounter'

type TotalWalletsColumnProps = {
  whitelistId: string
}

function TotalWalletsColumn({ whitelistId }: TotalWalletsColumnProps) {
  const { data: total } = useWhitelistWalletsCounter(whitelistId)

  return <Typography.Text style={{ color: '#0FDBD1' }}>{total}</Typography.Text>
}

export default TotalWalletsColumn
