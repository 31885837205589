import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Card, Col, Row, Space, Typography } from 'antd'

import { useTaskData } from 'hooks/task/useTasks'
import MarkdownPreview from 'components/systems/markdown/preview'
import { QuestionAnswerService } from 'services/bountySystem/question-answer'
import { TaskType } from 'templates/types'
import { DateTime } from 'luxon'

type CardTaskProps = { taskId: string }
const CardTask = ({ taskId }: CardTaskProps) => {
  const { title, type, description } = useTaskData(taskId)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const downloadAnswerCsv = useCallback(async () => {
    const timestamp = DateTime.now().toFormat('yyyy-MM-dd-HH-mm-ss')
    const filename = `question-answers-of-${taskId}-${timestamp}.csv`
    const blob = await QuestionAnswerService.downloadCsv(taskId)
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
  }, [taskId])

  return (
    <Card bordered={false}>
      <Row align="middle" wrap={false}>
        <Col flex="auto">
          <Space direction="vertical">
            <Typography.Title level={5}>{title}</Typography.Title>
            {/* <Typography.Text type="secondary">{description}</Typography.Text> */}
            <MarkdownPreview value={description} />
          </Space>
        </Col>
        <Col>
          <Space>
            {type === TaskType.Question && (
              <Button
                ghost
                type="ghost"
                size="large"
                onClick={() => downloadAnswerCsv()}
              >
                Download answers
              </Button>
            )}
            <Button
              onClick={() => navigate(`${pathname}/user-completed/${taskId}`)}
              ghost
              type="ghost"
              size="large"
            >
              View Result
            </Button>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default CardTask
