import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDebounce } from 'react-use'

import {
  Button,
  Col,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import DateRange from 'components/systems/dateRange'
import RequiredLabel from 'components/systems/requiredSymbol'
import SpaceVertical from 'components/systems/spaceVertical'

import { useCommunities } from 'hooks/community/useCommunities'

import {
  Acceler8PackageIds,
  Acceler8StatusLabel,
  Acceler8SubscriptionStatus,
  CreateAcceler8SubscriptionDto,
  ISubscriptionOverall,
  SubscriptionAction,
  UpdateAcceler8SubscriptionDto,
} from 'types/acceler8/acceler8-subscription.type'
import { notifyError, notifySuccess } from 'helper'

export type OnSubmitProps = {
  dto: CreateAcceler8SubscriptionDto | UpdateAcceler8SubscriptionDto
  onError?: (error: any) => void
  onSuccess?: () => void
}

type SubscriptionSetupProps = {
  item?: ISubscriptionOverall
  onSubmit: (data: OnSubmitProps) => void
  action: SubscriptionAction
  loading: boolean
  buttonText: string
  gameName?: boolean
}

const PACKAGE_OPTIONS = [
  {
    label: Acceler8StatusLabel[Acceler8PackageIds.Premium],
    value: Acceler8PackageIds.Premium,
    disabled: true,
  },
  {
    label: Acceler8StatusLabel[Acceler8PackageIds.Exclusive],
    value: Acceler8PackageIds.Exclusive,
    disabled: true,
  },
  {
    label: Acceler8StatusLabel[Acceler8PackageIds.AdminActivates],
    value: Acceler8PackageIds.AdminActivates,
  },
]

function SubscriptionSetup({
  item,
  action,
  onSubmit,
  loading,
  buttonText,
  gameName = false,
}: SubscriptionSetupProps) {
  const [open, setOpen] = useState(false)
  const [gameId, setGameId] = useState<string>()
  const [startedAt, setStartedAt] = useState(item?.startedAt)
  const [endedAt, setEndedAt] = useState(item?.endedAt)
  const [packageId, setPackageId] = useState(item?.packageId)
  const [keyword, setKeyword] = useState('')
  const [search, setSearch] = useState('')
  const { communities, isLoading } = useCommunities({ search })

  const queryClient = useQueryClient()

  const gameOptions = useMemo(
    () =>
      communities.map((community) => ({
        value: community._id,
        label: community.title,
      })),

    [communities],
  )

  const handleSubmit = () => {
    switch (action) {
      case SubscriptionAction.AddSubscription:
        return onSubmit({
          dto: {
            communityId: gameId,
            status: Acceler8SubscriptionStatus.Active,
            packageId,
            startedAt,
            endedAt,
          } as CreateAcceler8SubscriptionDto,
          onError: (error) => {
            notifyError(error)
          },
          onSuccess: async () => {
            setOpen(false)
            notifySuccess('Create subscription')
            await queryClient.invalidateQueries(
              'GET_ACCELER8_SUBSCRIPTION_OVERALL',
            )
          },
        })
      case SubscriptionAction.ActivateSubscription:
        return onSubmit({
          dto: {
            status: Acceler8SubscriptionStatus.Active,
            packageId,
            startedAt,
            endedAt,
          },
          onError: (error) => {
            notifyError(error)
          },
          onSuccess: async () => {
            setOpen(false)
            notifySuccess('Activate subscription')
            await queryClient.invalidateQueries(
              'GET_ACCELER8_SUBSCRIPTION_OVERALL',
            )
          },
        })
    }
  }

  useDebounce(() => setSearch(keyword), 500, [keyword])

  return (
    <Popover
      content={
        <Row gutter={[24, 24]} style={{ maxWidth: 400 }}>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Typography.Title level={4}>
                  Activate Affiliate Mode
                </Typography.Title>
              </Col>
              {gameName && (
                <Col span={24}>
                  <SpaceVertical>
                    <RequiredLabel>
                      <Typography.Title level={5}>Select Game</Typography.Title>
                    </RequiredLabel>
                    <Select
                      showSearch
                      value={gameId}
                      style={{ width: '100%' }}
                      placeholder="Name, GameId"
                      optionFilterProp="children"
                      filterOption={() => true}
                      onSearch={(value) => setKeyword(value)}
                      onChange={(value: string) => {
                        setGameId(value)
                      }}
                      options={gameOptions}
                      loading={isLoading}
                    />
                  </SpaceVertical>
                </Col>
              )}
              <Col span={24}>
                <RequiredLabel>
                  <Typography.Title level={5}>Choose package</Typography.Title>
                </RequiredLabel>
                <Radio.Group
                  onChange={(e) => setPackageId(e.target.value)}
                  value={packageId}
                >
                  <Space direction="vertical">
                    {PACKAGE_OPTIONS.map((pkg) => (
                      <Radio
                        value={pkg.value}
                        disabled={pkg.disabled}
                        key={pkg.value}
                      >
                        {pkg.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Col>
              <Col>
                <DateRange
                  startAt={startedAt}
                  endAt={endedAt}
                  onChangeStartAt={(startedAt) => setStartedAt(startedAt)}
                  onChangeEndAt={(endedAt) => setEndedAt(endedAt)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Button ghost onClick={() => setOpen(false)} loading={loading}>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  ghost
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={
                    (!gameId &&
                      action === SubscriptionAction.AddSubscription) ||
                    !packageId ||
                    !startedAt ||
                    !endedAt
                  }
                >
                  Activate
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
      trigger="click"
      open={open}
      onOpenChange={(newOpen) => setOpen(newOpen)}
    >
      <Button ghost loading={loading}>
        {buttonText}
      </Button>
    </Popover>
  )
}

export default SubscriptionSetup
