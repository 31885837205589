import { bountySystemApi as api } from 'services/base-axios'

import {
  CommunityProfileSection,
  CommunityProfileSectionMap,
  CommunitySectionType,
  GetAllCommunityProfileDto,
} from 'types/community-profile'
import { Data } from 'types'

class CommunityProfile {
  private controller = '/community-profile'

  async getAllSectionByCommunityId(params: GetAllCommunityProfileDto) {
    const { data } = await api.get<
      Data<CommunityProfileSection<CommunitySectionType>>[]
    >(this.controller + '/section', { params })
    return data
  }

  async createCommunitySection(payload: CommunityProfileSectionMap) {
    const { data } = await api.post(this.controller + '/section', payload)
    return data
  }

  async updateCommunitySection(payload: Data<CommunityProfileSectionMap>) {
    const { data } = await api.patch(
      this.controller + '/section/' + payload?._id,
      payload,
    )
    return data
  }

  async removeCommunitySection(sectionId: string) {
    const { data } = await api.delete(this.controller + '/section/' + sectionId)
    return data
  }
}

export const CommunityProfileService = new CommunityProfile()
