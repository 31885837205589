import { Col, Row, Typography } from 'antd'

import MintTicketForm from './MintTicketForm'

const MintGachaTicket = () => {
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={1} type="success">
          Mint tickets for user
        </Typography.Title>
      </Col>

      <Col span={24}>
        <MintTicketForm />
      </Col>
    </Row>
  )
}

export default MintGachaTicket
