import { createContext } from 'react'

interface ContextType {
  isModalOpen: boolean
  setIsModalOpen: (value: boolean) => void
  multiplierId?: string
  setMultiplierId: (value: string | undefined) => void
}

export const MultiplierModalContext = createContext<ContextType>({
  isModalOpen: false,
  setIsModalOpen: () => {},
  multiplierId: undefined,
  setMultiplierId: () => {},
})
