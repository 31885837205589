import { Card, Col, Row, Space, Typography } from 'antd'

import ListStreak from 'view/dailyCheckin/streakChecking/listStreak'
import ListDaily from 'view/dailyCheckin/streakChecking/listDaily'

import { useCheckInRewards } from 'hooks/check-in/useCheckInRewards'

import './index.less'

const DailyCheckIn = () => {
  const { rewards } = useCheckInRewards()

  if (!rewards) return null

  return (
    <Space size={[24, 24]} direction="vertical" style={{ width: '100%' }}>
      <Row>
        <Col span={20} offset={4}>
          <Row className="clip-path-container">
            <Col className="daily-reward-title">
              <Typography.Title level={3} className="gradient-text font-space">
                COLLECT YOUR DAILY REWARDS!
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24} wrap={false}>
        <Col flex="auto">
          <Row gutter={[0, 32]} style={{ background: '#0F191B', padding: 32 }}>
            {rewards.dailyRewards.map((rewards, idx) => (
              <Col span={24}>
                <ListDaily
                  dailyRewards={rewards}
                  title={`Streak ${idx + 1}`}
                  key={idx}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col style={{ minWidth: 300 }}>
          <Card className="daily-rewards-streak-card">
            <Row gutter={[32, 32]}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Typography.Title
                  level={3}
                  type="success"
                  className="font-space"
                >
                  STREAK <br />
                  REWARDS
                </Typography.Title>
              </Col>
              <Col span={24}>
                <ListStreak streakRewards={rewards.streakRewards} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Space>
  )
}

export default DailyCheckIn
