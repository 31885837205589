import { useNavigate } from 'react-router-dom'

import { Button, Col, Input, Row } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'

const ControlBar = () => {
  const navigate = useNavigate()

  return (
    <Row gutter={[24, 24]}>
      <Col flex="auto">
        <Input
          style={{ maxWidth: 300 }}
          placeholder="Search Category"
          suffix={
            <IonIcon style={{ color: '#42BAB0' }} name="search-outline" />
          }
        />
      </Col>
      <Col>
        <Button
          className="btn-filled"
          icon={<IonIcon name="add-outline" />}
          onClick={() =>
            navigate(ROUTES.REWARDS_CENTER.NEW_CATEGORY_MANAGEMENT)
          }
        >
          Add Category
        </Button>
      </Col>
    </Row>
  )
}

export default ControlBar
