import { useMutation } from 'react-query'

import { useUpsertGachaReward } from 'hooks/gacha/useUpsertGachaReward'

import { GachaService } from 'services/marketplace/gacha'

import { formatGachaPrice } from 'helper/gacha'

import {
  GachaRewardType,
  type IGacha,
  type IGachaBaseInfo,
  type UpdateGachaBaseInfoDto,
} from 'types/gacha.type'

export const useUpdateGachaBaseInfo = () => {
  return useMutation({
    mutationKey: ['update-gacha-base-info'],
    mutationFn: (payload: UpdateGachaBaseInfoDto) =>
      GachaService.updateGachaBaseInfo(payload),
  })
}

/**
 * To update gacha:
 *
 * 1. update gacha base info.
 *
 * 2. upsert list gacha reward:
 *  - get gachaId -> map reward with gachaId
 *  - check if reward is a new one -> set default total = available
 *  => upsert list gacha reward
 */

export const useUpdateGacha = () => {
  const { mutateAsync: updateBaseInfo } = useUpdateGachaBaseInfo()
  const { mutateAsync: updateReward } = useUpsertGachaReward()

  return useMutation({
    mutationKey: ['update-gacha'],
    mutationFn: async (payload: Partial<IGacha>) => {
      const { rewards, ...baseInfo } = payload
      const gachaId = baseInfo._id

      if (!gachaId) throw new Error('Gacha ID is required')
      if (!rewards || !rewards.length) throw new Error('Rewards is required')

      const validBaseInfo = formatGachaPrice(baseInfo as IGachaBaseInfo)
      const updatedGachaData = await updateBaseInfo(validBaseInfo)

      const rewardPayload = rewards.map((reward) => {
        const isNewReward = !reward._id

        if (isNewReward)
          return {
            ...reward,
            total: reward.available,
            gachaId,
          }

        if (reward.type === GachaRewardType.NFT)
          return {
            ...reward,
            total: reward.available,
            gachaId,
          }

        return {
          ...reward,
          gachaId,
        }
      })

      await updateReward(rewardPayload)

      return updatedGachaData
    },
  })
}

export const useUnpublishedGacha = () => {
  const { mutateAsync: updateBaseInfo } = useUpdateGachaBaseInfo()

  return useMutation({
    mutationFn: (gachaId: string) =>
      updateBaseInfo({ _id: gachaId, isActive: false }),
  })
}

export const useRepublishGacha = () => {
  const { mutateAsync: updateBaseInfo } = useUpdateGachaBaseInfo()

  return useMutation({
    mutationFn: (gachaId: string) =>
      updateBaseInfo({ _id: gachaId, isActive: true }),
  })
}
