import { useMutation } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { UpdateAcceler8SeasonDto } from 'types/acceler8/acceler8-season.type'

export const useUpdateAcceler8SeasonMutation = () => {
  return useMutation(
    ({ dto, seasonId }: { dto: UpdateAcceler8SeasonDto; seasonId: number }) =>
      Acceler8Service.updateSeason(seasonId, dto),
  )
}
