import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'
import FormPartnerGameTask from './FormPartnerGameTask'

import { useAcceler8SubscriptionActive } from 'hooks/acceler8/useAcceler8SubscriptionActive'
import configs from 'configs'

import { Acceler8SubscriptionWithGameName } from 'types/acceler8/acceler8-subscription.type'
import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'

import './index.less'

function PartnerGameTask({ type }: LeaderboardTaskProps) {
  const taskId = useParams().taskId || ''
  const isEdit = !!taskId

  const [selectedGameSubscription, setSelectedGameSubscription] = useState<
    Acceler8SubscriptionWithGameName | undefined
  >()
  const { data: games } = useAcceler8SubscriptionActive()
  const otherGames = games?.filter(
    (game) => game.communityId !== configs.community.SP3CommunityId,
  )

  const gameOptions = useMemo(
    () =>
      otherGames?.map((game) => ({
        label: game.gameName,
        value: game.communityId,
        key: game.id,
      })) ?? [],
    [otherGames],
  )

  const handleChangeGame = (gameId: string) => {
    const gameFound = games?.find((game) => game.communityId === gameId)

    setSelectedGameSubscription(gameFound)
  }

  useEffect(() => {
    if (!!otherGames?.length && !selectedGameSubscription && !isEdit) {
      handleChangeGame(otherGames?.[0]?.communityId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherGames, isEdit])

  return (
    <Row gutter={[0, 24]} className="acceler8-task-crud-container">
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {isEdit ? 'Edit Task' : 'Create New Task'}
        </Typography.Title>
      </Col>

      <Col span={24}>
        <FormPartnerGameTask
          type={type}
          isEdit={isEdit}
          taskId={taskId}
          gameOptions={gameOptions}
          gameSubscription={selectedGameSubscription}
          selectedGameSubscription={selectedGameSubscription}
          onChangeGame={handleChangeGame}
        />
      </Col>
    </Row>
  )
}

export default PartnerGameTask
