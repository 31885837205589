import { generatePath, useNavigate } from 'react-router-dom'

import { Dropdown } from 'antd'

import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'
import { TableSeasonActions } from 'constant/season'

export type ActionProps = {
  id: string
  seasonId: string
}

const Action = ({ id, seasonId }: ActionProps) => {
  const navigate = useNavigate()

  const items = [
    { label: TableSeasonActions.Edit, key: TableSeasonActions.Edit },
  ]

  const onEdit = () => {
    return navigate(
      generatePath(ROUTES.LEADERBOARD.EDIT_COMMUNITY_LEADERBOARD, {
        seasonId,
      }) + `/${id}`,
    )
  }

  return (
    <Dropdown.Button
      trigger={['click']}
      type="text"
      icon={<IonIcon name="ellipsis-vertical-outline" />}
      placement="bottomRight"
      menu={{
        items: items,
        onClick: (e) => {
          switch (e.key) {
            case TableSeasonActions.Edit:
              return onEdit()
          }
        },
      }}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

export default Action
