import { Col, Row, Tabs, Typography } from 'antd'
import Profile from './profile'
import AdminsSetting from './adminsSetting'
import SystemCcp from './systemCcp'
import DiscordCcp from './discordCcp'
import Social from './social'

const items = [
  { label: 'Profile', key: 'PROFILE', children: <Profile /> },
  { label: 'Social Link', key: 'SOCIAL', children: <Social /> },
  { label: 'Admins', key: 'ADMINS', children: <AdminsSetting /> },
  { label: 'System CCP', key: 'SYSTEM_CCP', children: <SystemCcp /> }, // remember to pass the key prop
  { label: 'Discord CCP', key: 'DISCORD_CCP', children: <DiscordCcp /> },
]

export const CommunitySetting = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Setting
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs items={items} />
      </Col>
    </Row>
  )
}
