import { Fragment, useEffect } from 'react'

import { Button, Form, type FormInstance, Input } from 'antd'

import DateRange from 'components/systems/dateRange'

import UploadImageSync from 'components/systems/uploadPicture/UploadImageSync'

import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import { useGachaSlug } from 'hooks/gacha/useGachaSlug'

import { generateSlug } from 'helper'

import GachaPriceSection from './GachaPriceSection'

type BaseInfoSectionProps = {
  form: FormInstance
}

function BaseInfoSection({ form }: BaseInfoSectionProps) {
  const title = Form.useWatch('title', form)
  const startedAt = Form.useWatch('startedAt', form)
  const endedAt = Form.useWatch('endedAt', form)

  const [{ _id: communityId }] = useCurrentCommunity()
  const { checkIsSlugExisted } = useGachaSlug()

  useEffect(() => {
    form.setFieldValue('communityId', communityId)
  }, [form, communityId])

  return (
    <Fragment>
      <Form.Item name="_id" noStyle />

      <Form.Item
        name="title"
        label="Gacha Name"
        rules={[
          {
            required: true,
            min: 5,
            max: 50,
            message: 'Gacha name must be between 5 and 50 characters',
          },
        ]}
      >
        <Input placeholder="Enter Gacha name" />
      </Form.Item>

      <Form.Item
        name="subtitle"
        label="Gacha Highlight Name"
        rules={[
          {
            required: false,
            max: 50,
            message: 'Gacha highlight name cannot exceed 50 characters',
          },
        ]}
      >
        <Input placeholder="Enter Gacha highlight name" />
      </Form.Item>

      <Form.Item
        name="slug"
        label="Slug"
        validateTrigger="onBlur"
        rules={[
          {
            validator: async (_, value) => {
              if (!value || value?.trim()?.length < 5)
                return Promise.reject(new Error('Slug is required'))

              const isSlugExisted = await checkIsSlugExisted(value)

              if (isSlugExisted)
                return Promise.reject(new Error('Slug is existed'))

              return Promise.resolve()
            },
          },
        ]}
      >
        <Input
          placeholder="Generate slug"
          suffix={
            <Button
              type="primary"
              onClick={() => {
                const gachaSlug = generateSlug(title)
                form.setFieldValue('slug', gachaSlug)
              }}
            >
              Generate slug
            </Button>
          }
        />
      </Form.Item>

      <Form.Item
        name="thumbnail"
        label="Thumbnail"
        rules={[
          {
            required: true,
            message: 'Thumbnail is required',
          },
        ]}
        validateTrigger="onChange"
      >
        <UploadImageSync />
      </Form.Item>

      <Form.Item name="communityId" label="Community ID" noStyle />

      {/*Price*/}
      <Form.Item label="Price">
        <GachaPriceSection form={form} />
      </Form.Item>

      <Form.Item name="startedAt" rules={[{ required: true }]} hidden />
      <Form.Item name="endedAt" rules={[{ required: true }]} hidden />

      <Form.Item
        name="gachaDateRange" // name is only used for validation
        label="Schedule"
        rules={[
          {
            validator: () => {
              if (!startedAt || !endedAt) {
                return Promise.reject(
                  new Error('Please select both start and end date'),
                )
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <DateRange
          fromLabel="Start Date (UTC)"
          toLabel="End Date (UTC)"
          startAt={startedAt}
          endAt={endedAt}
          requiredMark={false}
          onChangeStartAt={(value) => {
            form.setFieldsValue({ startedAt: value })
            form.validateFields(['gachaDateRange'])
          }}
          onChangeEndAt={(value) => {
            form.setFieldsValue({ endedAt: value })
            form.validateFields(['gachaDateRange'])
          }}
        />
      </Form.Item>
    </Fragment>
  )
}

export default BaseInfoSection
