import { createGlobalState } from 'react-use'

import { Col, Row, Tabs, Typography } from 'antd'

import ListSubscriptionOverall from './listSubscriptionOverall'
import ListSubscriptionActivity from './listSubscriptionActivity'

import { SubscriptionTab } from 'constant/affiliation'

const TAB_ITEMS = [
  { label: 'Overall', key: SubscriptionTab.Overall },
  { label: 'Activities', key: SubscriptionTab.Activities },
]

export const useSubscriptionTabSelected = createGlobalState<SubscriptionTab>(
  SubscriptionTab.Overall,
)

const SubscriptionManagement = () => {
  const [currentTab, setCurrentTab] = useSubscriptionTabSelected()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Affiliation Subscription Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs
          items={TAB_ITEMS}
          onChange={(e) => setCurrentTab(e as SubscriptionTab)}
        />
      </Col>

      <Col span={24}>
        {currentTab === SubscriptionTab.Overall ? (
          <ListSubscriptionOverall />
        ) : (
          <ListSubscriptionActivity />
        )}
      </Col>
    </Row>
  )
}

export default SubscriptionManagement
