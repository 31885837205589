import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'

import { Button, Col, Row, Space, Table, Typography } from 'antd'

import CustomPagination from 'components/systems/pagination'
import IonIcon from 'components/systems/ionIcon'
import UploadCSV from './uploadCSV'
import { COLUMN_CARD_CODE } from './column'

import { CardCodeService } from 'services/marketplace/card-code'
import { useCardCodes } from 'hooks/digitalCards/cardCodes/useCardCodes'

import { notifyError } from 'helper'

import { DEFAULT_PAGE } from 'constant'

import sample from 'static/files/sample-card-codes.csv'

const DigitalCardDetails = () => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const digitalCardId = useParams().digitalCardId || ''

  const { cardCodes, total } = useCardCodes({
    page: currentPage,
    digitalCardId: digitalCardId,
  })

  const { mutate } = useMutation({
    mutationKey: [`PURGING_CARD_CODES`],
    mutationFn: async () => {
      const response = await CardCodeService.purgingCardCodes(digitalCardId)

      const timestamp = Date.now()
      const fileBlob = new Blob([response.data])
      const fileUrl = URL.createObjectURL(fileBlob)
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = `digital-card-codes-of-${digitalCardId}-${timestamp}.csv`
      link.click()
      URL.revokeObjectURL(fileUrl)
    },
    onError: (error: any) => {
      notifyError(error)
    },
  })

  const purgingUnusedCards = () => {
    mutate()
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type={'success'}>
          Digital Cards Details
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row justify="space-between">
          <Col xs={8} xl={8}>
            <Button
              icon={<IonIcon name="download-outline" />}
              ghost
              onClick={purgingUnusedCards}
            >
              Purging Unused Cards
            </Button>
          </Col>
          <Col xs={12} xl={10}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button
                    icon={<IonIcon name="download-outline" />}
                    ghost
                    href={sample}
                    target="_blank"
                    download
                  >
                    Download Template
                  </Button>

                  <UploadCSV digitalCardId={digitalCardId} />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Table
          rowKey="_id"
          className="table-border-collapse"
          columns={COLUMN_CARD_CODE}
          dataSource={cardCodes}
          pagination={false}
        />
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!cardCodes.length && (
            <Col>
              <CustomPagination
                total={total}
                onChange={setCurrentPage}
                page={currentPage}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default DigitalCardDetails
