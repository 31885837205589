import { bountySystemApi as api } from 'services/base-axios'

const CONTROLLER = '/summary-report'

class Report {
  async getAll({
    startDate,
    endDate,
  }: {
    startDate?: string
    endDate?: string
  }): Promise<ReportData> {
    return api
      .get(CONTROLLER, { params: { startDate, endDate } })
      .then((data) => data.data)
  }

  async getAllDailyReport(): Promise<DailyReportData> {
    return api.get(`${CONTROLLER}/daily-check-in`).then((data) => data.data)
  }

  async statistical(
    fromDate: string,
    toDate: string,
  ): Promise<{ date: string; total: number }[]> {
    return api
      .get(`${CONTROLLER}/daily-check-in/statistical`, {
        params: { fromDate, toDate },
      })
      .then((data) => data.data)
  }
}

export const ReportService = new Report()

export const DUMMY_REPORT_DATA = {
  totalParticipants: 0,
  totalClaim: 0,
  totalNotCompleted: 0,
  totalCCPReward: 0,
  totalBadgeReward: 0,
  totalCompletedTasks: 0,
  flexibleTasks: 0,
  web3Tasks: 0,
  systemTasks: 0,
}

export type ReportData = {
  totalParticipants: number
  totalClaim: number
  totalNotCompleted: number
  totalCCPReward: number
  totalBadgeReward: number
  totalCompletedTasks: number
  flexibleTasks: number
  web3Tasks: number
  systemTasks: number
}

export type DailyReportData = {
  totalStreak: number
  totalCompletedStreak: number
  usersCheckInDaily: number
}
