import React, { Fragment, useRef, useState } from 'react'

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  Space,
} from 'antd'
import {
  PlusOutlined as AddIcon,
  MinusCircleOutlined as RemoveIcon,
} from '@ant-design/icons'
import RequiredLabel from 'components/systems/requiredSymbol'

import { HEADER_OPTION_DEFAULT } from './header-default-options'
import {
  CommonPartnerTaskAction,
  PartnerTaskProvider,
} from 'types/acceler8/acceler8-task.type'

function ApiSetupSection() {
  const [headerOptions, setHeaderOptions] = useState(HEADER_OPTION_DEFAULT)
  const [newHeaderKey, setNewHeaderKey] = useState('')
  const inputRef = useRef<InputRef>(null)

  const handleAddHeaderKey = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault()
    setHeaderOptions((prev) => [
      ...prev,
      { label: newHeaderKey, value: newHeaderKey },
    ])
    setNewHeaderKey('')
    inputRef.current?.focus()
  }

  return (
    <Fragment>
      {/* TODO: refactor data type later */}
      <Form.Item
        name={['configs', 'meta', 'endpoint']}
        label={<RequiredLabel children="Endpoint" />}
        initialValue="http://www.your-origin.com/api/verifyTask?uid={uid}&email={email}&wallet={wallet}&twitterId={twitterId}&discordId={discordId}"
        rules={[
          {
            required: true,
            message: 'Please enter endpoint',
          },
        ]}
      >
        <Input placeholder="Enter endpoint" />
      </Form.Item>

      <Form.Item
        name={['configs', 'provider']}
        label={<RequiredLabel children="Provider" />}
        initialValue={PartnerTaskProvider.CommonPartner}
        hidden
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={['configs', 'action']}
        label={<RequiredLabel children="Action" />}
        initialValue={CommonPartnerTaskAction.VerifyAPI}
        hidden
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={['configs', 'federatedApiId']}
        label={<RequiredLabel children="Federated Api Id" />}
        initialValue=""
        hidden
      >
        <Input />
      </Form.Item>

      <Form.List name={['configs', 'meta', 'headers']}>
        {(fields, { add, remove }) => (
          <Fragment>
            <RequiredLabel
              required={false}
              children="Headers"
              style={{ marginBottom: 8 }}
            />

            {fields.map((field) => (
              <Row gutter={12} key={field.name}>
                <Col span={8}>
                  <Form.Item
                    name={[field.name, 'key']}
                    rules={[
                      {
                        required: true,
                        message: 'Please select header attribute',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Atrribute name"
                      dropdownRender={(menu) => (
                        <Fragment>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />
                          <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                              placeholder="Enter header name"
                              ref={inputRef}
                              value={newHeaderKey}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => setNewHeaderKey(event.target.value)}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              disabled={!newHeaderKey}
                              onClick={handleAddHeaderKey}
                            >
                              Add item
                            </Button>
                          </Space>
                        </Fragment>
                      )}
                      options={headerOptions}
                    />
                  </Form.Item>
                </Col>

                <Col span={14}>
                  <Form.Item
                    name={[field.name, 'value']}
                    rules={[
                      {
                        required: true,
                        message: 'Please select header value',
                      },
                    ]}
                  >
                    <Input placeholder="Enter value" />
                  </Form.Item>
                </Col>

                <Col span={2}>
                  <Button
                    icon={<RemoveIcon className="remove-icon" />}
                    onClick={() => remove(field.name)}
                  />
                </Col>
              </Row>
            ))}

            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={() =>
                    add({ key: HEADER_OPTION_DEFAULT[0].value, value: '' })
                  }
                  icon={<AddIcon />}
                >
                  Add Attribute
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}
      </Form.List>
    </Fragment>
  )
}

export default ApiSetupSection
