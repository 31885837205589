import { MenuProps } from 'antd'
import { BountyTaskType } from 'constant/bounty'
import { ButtonCreateLoader } from 'templates'
import { TaskKeys } from 'templates/types'

export const MENU_ITEMS: Partial<
  Record<
    BountyTaskType,
    { color: string; icon: string; items: MenuProps['items'] }
  >
> = {
  [BountyTaskType.ConnectToUID]: {
    color: '#A246DB',
    icon: 'person-circle-outline',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.SystemDiscord} />,
        key: TaskKeys.SystemDiscord,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.SystemTwitter} />,
        key: TaskKeys.SystemTwitter,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.SystemWallet} />,
        key: TaskKeys.SystemWallet,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.SystemRoninWallet} />,
        key: TaskKeys.SystemRoninWallet,
      },
    ],
  },
  [BountyTaskType.Token]: {
    color: '#DB4646',
    icon: 'journal-outline',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.HoldToken} />,
        key: TaskKeys.HoldToken,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.HoldNFT} />,
        key: TaskKeys.HoldNFT,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.BridgeToken} />,
        key: TaskKeys.BridgeToken,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.Transaction} />,
        key: TaskKeys.Transaction,
      },
    ],
  },
  [BountyTaskType.OnChain]: {
    color: '#96C291',
    icon: 'link-outline',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.InteractWithContract} />,
        key: TaskKeys.InteractWithContract,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.TokenSpent} />,
        key: TaskKeys.TokenSpent,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.USDSpent} />,
        key: TaskKeys.USDSpent,
      },
    ],
  },
  [BountyTaskType.Twitter]: {
    color: '#1DA1F2',
    icon: 'logo-twitter',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.SystemTwitter} />,
        key: TaskKeys.SystemTwitter,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.LikeTwitter} />,
        key: TaskKeys.LikeTwitter,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.FollowTwitter} />,
        key: TaskKeys.FollowTwitter,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.RetweetTwitter} />,
        key: TaskKeys.RetweetTwitter,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.QuoteTwitter} />,
        key: TaskKeys.QuoteTwitter,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.ReplyTwitter} />,
        key: TaskKeys.ReplyTwitter,
      },
    ],
  },
  [BountyTaskType.Discord]: {
    color: '#5866F2',
    icon: 'logo-discord',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.SystemDiscord} />,
        key: TaskKeys.SystemDiscord,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.JoinDiscord} />,
        key: TaskKeys.JoinDiscord,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.VerifyRoleDiscord} />,
        key: TaskKeys.VerifyRoleDiscord,
      },
      {
        label: (
          <ButtonCreateLoader taskKey={TaskKeys.VerifyInviteFriendsDiscord} />
        ),
        key: TaskKeys.VerifyInviteFriendsDiscord,
      },
    ],
  },
  [BountyTaskType.Telegram]: {
    color: '#4B9BFF',
    icon: 'call-outline',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.JoinTelegram} />,
        key: TaskKeys.JoinTelegram,
      },
    ],
  },
  [BountyTaskType.Facebook]: {
    color: '#3B5998',
    icon: 'logo-facebook',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.LikeFacebookPage} />,
        key: TaskKeys.LikeFacebookPage,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.ShareFacebookPost} />,
        key: TaskKeys.ShareFacebookPost,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.PostFacebookTags} />,
        key: TaskKeys.PostFacebookTags,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.JoinFacebookGroup} />,
        key: TaskKeys.JoinFacebookGroup,
      },
    ],
  },
  [BountyTaskType.Youtube]: {
    color: '#FF0000',
    icon: 'logo-youtube',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.SubscribeYoutube} />,
        key: TaskKeys.SubscribeYoutube,
      },
    ],
  },
  [BountyTaskType.Question]: {
    color: '#cc0066',
    icon: 'help-circle-outline',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.QuestionAnswer} />,
        key: TaskKeys.QuestionAnswer,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.QuestionQuiz} />,
        key: TaskKeys.QuestionQuiz,
      },
    ],
  },
  [BountyTaskType.Partner]: {
    color: '#C28222',
    icon: 'diamond-outline',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.ApeironPlayTime} />,
        key: TaskKeys.ApeironPlayTime,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.OWSRegisteredGame} />,
        key: TaskKeys.OWSRegisteredGame,
      },
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.CustomizeVerifyAPI} />,
        key: TaskKeys.CustomizeVerifyAPI,
      },
    ],
  },
  [BountyTaskType.Custom]: {
    color: '#42BAB0',
    icon: 'navigate-outline',
    items: [
      {
        label: <ButtonCreateLoader taskKey={TaskKeys.Custom} />,
        key: TaskKeys.Custom,
      },
    ],
  },
}
