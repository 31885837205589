import { bountySystemApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import { ReportHistoryFilter } from 'view/bounty/summaryReport/reportHistory/type'

const CONTROLLER = '/bounty-certificate'

export type GetBountiesCertificate = {
  startDate?: string
  endDate?: string
  page?: number
  pageSize?: number
  search?: string
  state?: ReportHistoryFilter
}

class BountyCertificate {
  async getAll({
    startDate,
    endDate,
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
    state,
  }: GetBountiesCertificate) {
    const params = {
      startDate,
      endDate,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      search,
      state,
    }
    return api.get(CONTROLLER, { params }).then((data) => data.data)
  }

  async get(bountyId: string, bountyIndex: number) {
    return api.get(
      `${CONTROLLER}/current?bounty-id=${bountyId}&bounty-index=${bountyIndex}`,
    )
  }

  async getParticipants(bountyId: string) {
    return api.get(`${CONTROLLER}/${bountyId}/participants`)
  }
}

export const BountyCertificateService = new BountyCertificate()
