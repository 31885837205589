import { marketplaceApi as api } from 'services/base-axios'

import { Data, DataList } from 'types'
import { IProductData } from 'types/product.type'
import { FilterProductParams } from './product.type'
import { ProductPriority } from 'hooks/bounty/usePinnedProducts'

const CONTROLLER = '/admin/product'

class Product {
  async get(productId: string): Promise<Data<IProductData>> {
    return api.get(`${CONTROLLER}/${productId}`).then((data) => data.data)
  }

  async find(params: FilterProductParams) {
    const { data } = await api.get<DataList<Data<IProductData>[]>>(CONTROLLER, {
      params,
    })
    return data
  }

  async create(createData: any): Promise<Data<IProductData>> {
    const { data } = await api.post(CONTROLLER, createData)
    return data
  }

  async update(
    productId: string,
    updateData: any,
  ): Promise<Data<IProductData>> {
    const { data } = await api.patch(`${CONTROLLER}/${productId}`, updateData)
    return data
  }

  async updateState(
    productId: string,
    isPublish: boolean,
  ): Promise<Data<IProductData>> {
    const { data } = await api.patch(`${CONTROLLER}/${productId}/publish`, {
      isPublish,
    })
    return data
  }

  async delete(productId: string): Promise<Data<IProductData>> {
    const { data } = await api.delete(`${CONTROLLER}/${productId}`)
    return data
  }

  async getPinnedProducts() {
    return await api.get(`${CONTROLLER}/priorities`)
  }

  async createPinnedProducts(priorities: ProductPriority[]) {
    return await api.put(`${CONTROLLER}/priorities`, { priorities })
  }

  async getProductBySlug(slug: string): Promise<Data<IProductData>> {
    const { data } = await api.get(`${CONTROLLER}/slug/${slug}`)
    return data
  }
}

export const ProductService = new Product()
