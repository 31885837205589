import {
  ApeironTaskAction,
  CommonPartnerTaskAction,
  OccupyWallStreetTaskAction,
  PartnerTaskAction,
  PartnerTaskProvider,
  PartnerTemplateConfigs,
  TaskKeys,
  TaskType,
} from 'templates/types'

export const PARTNER_COLOR_CONFIGS = {
  [PartnerTaskProvider.Apeiron]:
    'linear-gradient(310deg, #7928CA 0%, #FF0080 100%)',
  [PartnerTaskProvider.OccupyWallStreet]: '#242424',
  [CommonPartnerTaskAction.CustomizeVerifyAPI]: '#433c2a',
}

/**
 * thumbnail is unused, use PartnerTaskProvider to render corresponding thumbnail
 */
export const TEMPLATE_CONFIGS: Record<
  PartnerTaskAction,
  PartnerTemplateConfigs
> = {
  [ApeironTaskAction.PlayTime]: {
    type: TaskType.Partner,
    thumbnail: '',
    extra: {
      url: {
        componentType: 'Input',
        extraFieldTitle: 'URL',
        extraFieldPlaceholder: 'https://...',
      },
    },
    title: 'Apeiron - PlayTime',
    color: PARTNER_COLOR_CONFIGS[PartnerTaskProvider.Apeiron],
    defaultData: {
      provider: PartnerTaskProvider.Apeiron,
      action: ApeironTaskAction.PlayTime,
      meta: {},
    },
  },
  [OccupyWallStreetTaskAction.RegisteredGame]: {
    type: TaskType.Partner,
    thumbnail: '',
    extra: {
      url: {
        componentType: 'Input',
        extraFieldTitle: 'URL',
        extraFieldPlaceholder: 'https://...',
      },
    },
    title: 'OccupyWallStreet - Registered Game',
    color: PARTNER_COLOR_CONFIGS[PartnerTaskProvider.OccupyWallStreet],
    defaultData: {
      provider: PartnerTaskProvider.OccupyWallStreet,
      action: OccupyWallStreetTaskAction.RegisteredGame,
      meta: {},
    },
  },
  [CommonPartnerTaskAction.CustomizeVerifyAPI]: {
    type: TaskType.Partner,
    thumbnail: '',
    extra: {},
    title: 'Partner - Customize verify API',
    color: PARTNER_COLOR_CONFIGS[CommonPartnerTaskAction.CustomizeVerifyAPI],
    defaultData: {
      provider: PartnerTaskProvider.CommonPartner,
      action: CommonPartnerTaskAction.CustomizeVerifyAPI,
      meta: {},
    },
  },
}

export const TASK_KEY_TO_PARTNER_CONFIGS = {
  [TaskKeys.ApeironPlayTime]: TEMPLATE_CONFIGS[ApeironTaskAction.PlayTime],
  [TaskKeys.OWSRegisteredGame]:
    TEMPLATE_CONFIGS[OccupyWallStreetTaskAction.RegisteredGame],
  [TaskKeys.CustomizeVerifyAPI]:
    TEMPLATE_CONFIGS[CommonPartnerTaskAction.CustomizeVerifyAPI],
} as Record<TaskKeys, PartnerTemplateConfigs>
