import { useState, useMemo, useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'

import RedemptionsList from './redemptions-list'
import ImportRedemptions from './import-redemption'

import { useLatestPlayerSeasons } from 'hooks/player-leaderboard/useLatestPlayerSeasons'

import RedemptionsFilterContext from './redemptions-list/RedemptionFilterContext'

import { DEFAULT_PAGE } from 'constant'

const PlayerRedemptionManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState<string>('')
  const [filter, setFilter] = useState<Record<string, number | null>>({
    seasonId: null,
  })

  const { data: seasons } = useLatestPlayerSeasons({ limit: 10 })
  const page = Number(searchParams.get('page') ?? DEFAULT_PAGE)

  const seasonIds = useMemo(() => {
    return seasons.map((season) => season.id)
  }, [seasons])

  const setPage = useCallback(
    (_page: number) => {
      setSearchParams({ page: _page.toString() })
    },
    [setSearchParams],
  )

  const onChangeKeyword = useCallback(
    (keyword: string) => {
      setPage(DEFAULT_PAGE)
      setSearch(keyword)
    },
    [setPage, setSearch],
  )

  useEffect(() => {
    if (!filter.seasonId) {
      setFilter((prevFilter) => ({ ...prevFilter, seasonId: seasonIds[0] }))
    }

    setSearchParams((searchParams) => ({
      ...searchParams,
      page: DEFAULT_PAGE.toString(),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.seasonId, seasonIds])

  return (
    <RedemptionsFilterContext.Provider
      value={{
        page,
        search,
        filter,
        seasons,
        setPage,
        setSearch: onChangeKeyword,
        setFilter,
      }}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Typography.Title level={3} type="success">
            Redemption Management
          </Typography.Title>
        </Col>
        <Col span={24}>
          <ImportRedemptions />
        </Col>
        <Col span={24}>
          <RedemptionsList />
        </Col>
      </Row>
    </RedemptionsFilterContext.Provider>
  )
}

export default PlayerRedemptionManagement
