import { Tag } from 'antd'
import { useCallback } from 'react'

import { OrderStatus } from 'types/order.type'

const STATUS_COLOR: Record<OrderStatus, number[]> = {
  [OrderStatus.Done]: [30, 175, 106],
  [OrderStatus.Fail]: [219, 70, 70],
  [OrderStatus.Paid]: [215, 166, 71],
  [OrderStatus.Sending]: [215, 166, 71],
  [OrderStatus.Open]: [219, 70, 70],
  [OrderStatus.Initialized]: [219, 70, 70],
}

export const ORDER_STATUS_LABEL: Record<OrderStatus, string> = {
  [OrderStatus.Open]: 'Fail',
  [OrderStatus.Initialized]: 'Fail',
  [OrderStatus.Fail]: 'Fail',
  [OrderStatus.Paid]: 'In Progress',
  [OrderStatus.Sending]: 'In Progress',
  [OrderStatus.Done]: 'Completed',
}

const OrderState = ({ state }: { state: OrderStatus }) => {
  const tagColor = useCallback(
    (opacity = 1) => {
      const color = STATUS_COLOR[state]
      return `rgba(${color[0]},${color[1]},${color[2]},${opacity})`
    },
    [state],
  )

  return (
    <Tag
      style={{
        margin: 0,
        borderRadius: 28,
        background: tagColor(0.2),
        textTransform: 'capitalize',
        color: tagColor(),
        padding: '4px 10px',
      }}
    >
      {ORDER_STATUS_LABEL[state]}
    </Tag>
  )
}

export default OrderState
