import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { FindPlayerRedemptions } from 'types/player-leaderboard/player-leaderboard-redemption.type'

export const usePlayerRedemptions = (dto: FindPlayerRedemptions) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_PLAYER_REDEMPTIONS', dto],
    queryFn: () => PlayerLeaderboardService.findRedemptions(dto),
    enabled: !!dto.seasonIds?.length,
  })

  return {
    ...rest,
    data: data?.data || [],
    total: data?.total || 0,
  }
}
