import { useMutation } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { CreateAcceler8SeasonDto } from 'types/acceler8/acceler8-season.type'

export const useCreateAcceler8SeasonMutation = () => {
  return useMutation((dto: CreateAcceler8SeasonDto) =>
    Acceler8Service.createSeason(dto),
  )
}
