import { createGlobalState } from 'react-use'

import { Col, Row, Typography } from 'antd'
import CampaignHeader from 'components/campaign/campaignHeader'
import ListCampaign from 'components/campaign/listCampaign'

import './index.less'

export const useSearchCampaignKey = createGlobalState('')

const CampaignManagement = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Campaign Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <CampaignHeader />
      </Col>
      <Col span={24}>
        <ListCampaign />
      </Col>
    </Row>
  )
}
export default CampaignManagement
