import { useQuery } from 'react-query'

import { Acceler8TaskService } from 'services/acceler8-task'

export const useAcceler8Task = (taskId?: string) => {
  return useQuery({
    queryKey: ['GET_ACCELER8_TASK', taskId],
    queryFn: () => Acceler8TaskService.getTaskById(taskId),
    enabled: !!taskId,
  })
}
