import { CSSProperties } from 'react'
import ReactMarkdown from 'react-markdown'

import { Row, Col } from 'antd'

import './style.less'
import remarkGfm from 'remark-gfm'

export type PreviewProps = {
  value?: string
  style?: CSSProperties
}

const MarkdownPreview = ({ value = '', style = {} }: PreviewProps) => {
  return (
    <Row gutter={[8, 8]}>
      <Col span={24} style={style}>
        <ReactMarkdown
          className="markdown-preview line-break"
          remarkPlugins={[remarkGfm]}
          components={{
            code({ node, className, children, inline, ...props }) {
              return (
                <code className={`inline-code ${className}`} {...props}>
                  {children}
                </code>
              )
            },
            script: ({ node, ...props }) => <script {...props} />,
          }}
        >
          {value}
        </ReactMarkdown>
      </Col>
    </Row>
  )
}

export default MarkdownPreview
