import { useState } from 'react'

import { Col, Row, Input, Typography, Button, Space } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import { CreditPointService } from 'services/bountySystem/creditPoint'
import { notifyError, notifySuccess } from 'helper'

const DiscordCcp = () => {
  const [discordId, setDiscordId] = useState('')
  const [ccpAmount, setCcpAmount] = useState('')
  const [loading, setLoading] = useState(false)

  const onFaucet = async () => {
    try {
      setLoading(true)
      await CreditPointService.faucet(discordId, Number(ccpAmount))
      notifySuccess(`Faucet ${ccpAmount} CPP for ${discordId}`)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row gutter={[24, 24]} style={{ paddingTop: 20, maxWidth: 486 }}>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">Discord UID</Typography.Text>
          <Input
            value={discordId}
            placeholder="Enter discord id..."
            onChange={(e) => setDiscordId(e.target.value)}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">CCP Amount</Typography.Text>
          <Input
            value={ccpAmount}
            placeholder="Enter CCP amount..."
            onChange={(e) => setCcpAmount(e.target.value)}
          />
        </Space>
      </Col>

      <Col span={8}>
        <Button
          type="primary"
          icon={<IonIcon name="cash-outline" />}
          onClick={onFaucet}
          loading={loading}
        >
          Faucet
        </Button>
      </Col>
    </Row>
  )
}

export default DiscordCcp
