import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Dropdown, MenuProps } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { MarketItemState, TableMarketActions } from 'constant/marketplace'
import { useProductTypeSelected } from '../../index'
import { ROUTES } from 'constant/routes'
import { ProductService } from 'services/marketplace/product'
import { removeProduct, upsetProduct } from 'store/products.reducer'
import { notifyError, notifySuccess } from 'helper'
import { useFilterProps } from 'view/itemManagement/itemList/newAndfilterBar/filterAndSearch'
import { ProductType } from 'types/product.type'
import { useProductList } from 'hooks/product/useProduct'
import { DateTime } from 'luxon'
import { orderService } from 'services/marketplace/order'

export type ActionProps = {
  itemId: string
  isAvailable: boolean
}

const Action = ({ itemId, isAvailable }: ActionProps) => {
  const [loading, setLoading] = useState(false)
  const [productTypeSelected] = useProductTypeSelected()
  const [{ productState }] = useFilterProps()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { refetch } = useProductList()

  const items: MenuProps['items'] = (() => {
    const items = [
      { label: TableMarketActions.Edit, key: TableMarketActions.Edit },
      {
        label: TableMarketActions.Delete,
        key: TableMarketActions.Delete,
      },
    ]
    if (productState === MarketItemState.Unpublish) {
      items.push({
        label: TableMarketActions.Publish,
        key: TableMarketActions.Publish,
      })
    } else {
      items.push({
        label: TableMarketActions.UnPublish,
        key: TableMarketActions.UnPublish,
      })
    }

    if (productTypeSelected === ProductType.REAL_NFT) {
      items.push({
        label: TableMarketActions.Export,
        key: TableMarketActions.Export,
      })
    }
    return items
  })()

  const onDelete = async () => {
    if (!itemId) return notifyError('Invalid product!')

    try {
      setLoading(true)
      await ProductService.delete(itemId)

      dispatch(removeProduct(itemId))
      notifySuccess(`Product #${itemId} has been deleted`)
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleProductState = async (nextState: string) => {
    if (!itemId) return notifyError('Invalid product!')
    if (!isAvailable)
      return notifyError('The available quantity is not enough fo publication.')

    let state = true
    switch (nextState) {
      case MarketItemState.Publish:
        state = true
        break
      case MarketItemState.Unpublish:
        state = false
        break
      default:
        state = true
        break
    }

    try {
      setLoading(true)
      const product = await ProductService.updateState(itemId, state)

      dispatch(upsetProduct({ productId: product._id, data: product }))
      notifySuccess(`Product #${itemId} has been published`)
      refetch()
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  const onEdit = () => {
    const editRoute =
      productTypeSelected === ProductType.NFT
        ? ROUTES.REWARDS_CENTER.EDIT_ITEM_DIGITAL
        : ROUTES.REWARDS_CENTER.EDIT_ITEM_PHYSICAL
    return navigate(`${editRoute}/${itemId}`)
  }

  const onExport = useCallback(async () => {
    const timestamp = DateTime.now().toFormat('yyyy-MM-dd-HH-mm-ss')
    const filename = `shipping_data-of-item-${itemId}-${timestamp}.csv`
    const blob = await orderService.downloadUsersShippingData(itemId)
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
  }, [itemId])

  return (
    <Dropdown.Button
      trigger={['click']}
      type="text"
      icon={<IonIcon name="ellipsis-vertical-outline" />}
      placement="bottomRight"
      loading={loading}
      menu={{
        items: items,
        onClick: (e) => {
          switch (e.key) {
            case TableMarketActions.Edit:
              return onEdit()
            case TableMarketActions.Delete:
              return onDelete()
            case TableMarketActions.Publish:
            case TableMarketActions.UnPublish:
              return handleProductState(e.key)
            case TableMarketActions.Export:
              return onExport()
          }
        },
      }}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

export default Action
