import { useNavigate } from 'react-router-dom'

import { Dropdown } from 'antd'

import IonIcon from 'components/systems/ionIcon'

import { TableCommunityActions } from 'constant/community'
import { ROUTES } from 'constant/routes'

export type ActionProps = {
  communityId: string
}

const Action = ({ communityId }: ActionProps) => {
  const navigate = useNavigate()

  const items = [
    { label: TableCommunityActions.Edit, key: TableCommunityActions.Edit },
    {
      label: TableCommunityActions.EditProfile,
      key: TableCommunityActions.EditProfile,
    },
  ]

  const onEdit = () => {
    return navigate(`${ROUTES.COMMUNITIES.EDIT_COMMUNITY}/${communityId}`)
  }

  const handleEditProfile = () => {
    return navigate(`${ROUTES.COMMUNITIES.EDIT_PROFILE}/${communityId}`)
  }

  return (
    <Dropdown.Button
      trigger={['click']}
      type="text"
      icon={<IonIcon name="ellipsis-vertical-outline" />}
      placement="bottomRight"
      // loading={loading}
      menu={{
        items: items,
        onClick: (e) => {
          switch (e.key) {
            case TableCommunityActions.Edit:
              return onEdit()
            case TableCommunityActions.EditProfile:
              return handleEditProfile()
          }
        },
      }}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

export default Action
