import { Fragment, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Col, Popconfirm, Row, Space, Table, Typography } from 'antd'
import { ColumnType } from 'antd/lib/table'

import DataPreview from '../dataPreview'
import IonIcon from 'components/systems/ionIcon'
import CustomPagination from 'components/systems/pagination'
import ConfirmDeleteModal from './ConfirmDeleteModal'

import { useUserWhitelist } from 'hooks/whitelist/useUserWhitelist'
import { useDeleteUserWhitelist } from 'hooks/whitelist/useDeleteUserWhitelists'

import { MultiplierService } from 'services/multiplier'

import { notifyError, notifySuccess, shortenAddressCustom } from 'helper'
import { getAccessToken } from 'services/utils'

import { DEFAULT_PAGE, MULTIPLIER_PAGE_SIZE } from 'constant'

import { IUserWhitelist } from 'types/whitelist.type'

import './index.less'

type OnSystemPreviewProps = {
  whitelistId: string
}

function OnSystemPreview({ whitelistId }: OnSystemPreviewProps) {
  const multiplierId = useParams().multiplierId || ''
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [open, setOpen] = useState(false)
  const { data: userWhitelistList, refetch } = useUserWhitelist({
    whitelistId,
    offset: (page - 1) * MULTIPLIER_PAGE_SIZE,
    limit: MULTIPLIER_PAGE_SIZE,
    search: keyword,
  })
  const { mutateAsync: deleteUserWhitelist } = useDeleteUserWhitelist()

  const handleRemove = async (address: string) => {
    try {
      await deleteUserWhitelist({ addresses: [address], whitelistId })

      refetch()
      notifySuccess('Deleted user whitelist')
    } catch (error) {
      notifyError(error)
    }
  }

  const handleRemoveAll = useCallback(async () => {
    try {
      await deleteUserWhitelist({ addresses: [], whitelistId })

      refetch()
      setOpen(false)
      notifySuccess('Deleted all user whitelists')
    } catch (error) {
      notifyError(error)
    }
  }, [deleteUserWhitelist, refetch, whitelistId])

  const exportUserWhitelistUrl = useMemo(() => {
    return MultiplierService.getDownloadWalletWhitelistStatusUrl(multiplierId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplierId, getAccessToken()])

  const addressColumn: ColumnType<IUserWhitelist>[] = [
    {
      title: (
        <Space size={8}>
          <Typography.Text type="secondary">Total</Typography.Text>
          <Typography.Text>{userWhitelistList?.total}</Typography.Text>
        </Space>
      ),
      dataIndex: 'address',
      align: 'left',
      render: (address: string, _, index: number) => (
        <Space>
          <Typography.Text type="secondary">{index + 1}</Typography.Text>
          <Typography.Text>
            {shortenAddressCustom(address, 8, 9)}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: (
        <Button onClick={() => setOpen(true)}>
          <Typography.Text>Clear All</Typography.Text>
        </Button>
      ),
      dataIndex: 'address',
      key: 'action',
      align: 'center',
      render: (address: string) => (
        <Popconfirm
          title="Are you sure to delete this address?"
          onConfirm={() => handleRemove(address)}
        >
          <Button className="remove-btn">
            <IonIcon name="trash-outline" />
          </Button>
        </Popconfirm>
      ),
      width: '14%',
    },
  ]

  return (
    <DataPreview
      header={
        <Space wrap={false}>
          <IonIcon name="document-text" style={{ color: '#0FDBD1' }} />
          <Typography.Title level={5}>ON SYSTEM</Typography.Title>
        </Space>
      }
      dataList={
        <Row className="on-system-section" gutter={[0, 24]} justify="center">
          {userWhitelistList && userWhitelistList.data.length > 0 ? (
            <Fragment>
              <Col span={24}>
                <Row>
                  <Col
                    span={24}
                    style={{
                      padding: '0 12px',
                      borderBottom: '1px solid #ffffff0f',
                    }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col>
                        <Typography.Text type="secondary">
                          Address
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Button href={exportUserWhitelistUrl}>
                          <Space size={4}>
                            <IonIcon name="download-outline" />
                            <Typography.Text type="success">
                              Export
                            </Typography.Text>
                          </Space>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Table
                      rowKey="wallet"
                      dataSource={userWhitelistList.data || []}
                      columns={addressColumn}
                      pagination={false}
                      style={{ height: 525 }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ textAlign: 'center' }}>
                <CustomPagination
                  total={userWhitelistList?.total || 0}
                  onChange={setPage}
                  page={page}
                  pageSize={MULTIPLIER_PAGE_SIZE}
                />
              </Col>
            </Fragment>
          ) : (
            <Col>
              <Typography.Text type="secondary">No Data Here</Typography.Text>
            </Col>
          )}

          <ConfirmDeleteModal
            open={open}
            onCancel={() => setOpen(false)}
            onConfirm={handleRemoveAll}
          />
        </Row>
      }
      onSearch={setKeyword}
    />
  )
}

export default OnSystemPreview
