import { Fragment, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { Button, Col, Input, Row } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'

import useHandle from 'hooks/useHandle'
import { useCommunityLeaderboard } from 'hooks/leaderboard/useCommunityLeaderboard'
import { useCommunityLeaderboardMutation } from 'hooks/leaderboard/useCommunityLeaderboardMutation'

import { UpdateCommunityLeaderboardDto } from 'services/bountySystem/leaderboard/type'

import { notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'
import { DEFAULT_UPDATE_COMMUNITY_LEADERBOARD_DATA } from 'constant/leaderboard'

const EditCommunityLeaderboard = () => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(
    DEFAULT_UPDATE_COMMUNITY_LEADERBOARD_DATA,
  )
  const seasonId = useParams().seasonId || ''
  const communityLeaderboardId = useParams().communityLeaderboardId || ''
  const { data, isLoading } = useCommunityLeaderboard(communityLeaderboardId)

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const { mutateAsync } = useCommunityLeaderboardMutation({
    id: communityLeaderboardId,
    payload: {
      point: formData.point,
    },
  })
  const navigate = useNavigate()

  const validateFields = (formData: UpdateCommunityLeaderboardDto) => {
    const { point } = formData

    if (!(point >= 0)) throw new Error('Point must be a number >= 0')
  }

  const onSave = useHandle(async () => {
    validateFields(formData)
    await mutateAsync()
    notifySuccess('Updated community')
    navigate(generatePath(ROUTES.LEADERBOARD.DETAIL, { seasonId }))
  }, setLoading)

  useEffect(() => {
    if (data) setFormData(data)
  }, [data])

  useEffect(() => {
    setSubmitDisabled(!(formData.point >= 0))
  }, [formData.point])

  if (isLoading) return <Fragment />

  return (
    <Row gutter={[24, 24]} style={{ paddingTop: 20, maxWidth: 486 }}>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Community Name</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={data?.community.title}
              placeholder="Enter team name..."
              disabled={true}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Point </RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={formData.point}
              placeholder="Enter point..."
              onChange={(e) =>
                setFormData({
                  ...formData,
                  point: parseInt(e.target.value || '0'),
                })
              }
              type="numeric"
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Button
          type="primary"
          disabled={submitDisabled}
          onClick={onSave}
          loading={loading}
        >
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default EditCommunityLeaderboard
