import { ReactNode } from 'react'

import { OneItemBountyTaskType } from 'constant/bounty'
import { ButtonCreateLoader } from 'templates'
import { TaskKeys } from 'templates/types'

export const SINGLE_ITEM_MENU: Partial<
  Record<
    OneItemBountyTaskType,
    { color: string; icon: string; component: ReactNode }
  >
> = {
  [OneItemBountyTaskType.FederatedApi]: {
    color: '#C516DC',
    icon: 'link-outline',
    component: <ButtonCreateLoader taskKey={TaskKeys.FederatedApi} />,
  },
}
