import { ReactNode } from 'react'

import { Button, Collapse, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

const AttributeSettingLayout = ({
  title,
  children,
  onRemove,
}: {
  title: string
  children: ReactNode
  onRemove?: () => void
}) => {
  return (
    <Collapse
      className="form-management-collpase"
      defaultActiveKey={['1']}
      expandIconPosition="end"
    >
      <Collapse.Panel
        header={
          <Typography.Title type="success" level={5}>
            {title}
          </Typography.Title>
        }
        key="1"
        extra={
          onRemove ? (
            <Button
              type="text"
              style={{ width: 'auto', height: 'auto', padding: 0 }}
              onClick={(e) => {
                e.stopPropagation()
                onRemove()
              }}
              icon={
                <Typography.Text type="danger">
                  <IonIcon name="trash-outline" />
                </Typography.Text>
              }
            />
          ) : undefined
        }
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  )
}

export default AttributeSettingLayout
