import { Button, Col, Modal, ModalProps, Row, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

type CommonPopupProps = {
  title: string
  onConfirm: () => void
  onCancel: () => void
  loading?: boolean
}

const CommonPopup = ({
  title,
  onConfirm,
  onCancel,
  loading = false,
  ...props
}: CommonPopupProps & ModalProps) => {
  return (
    <Modal
      {...props}
      footer={null}
      width={345}
      closeIcon={<IonIcon name="close-outline" />}
      onCancel={onCancel}
    >
      <Row gutter={[24, 24]} style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Typography.Title level={4} type="success">
            {title}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Space size={20}>
            <Button style={{ width: 129 }} ghost onClick={onCancel}>
              Cancel
            </Button>
            <Button
              loading={loading}
              style={{ width: 129 }}
              type="primary"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  )
}

export default CommonPopup
