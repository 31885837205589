import moment from 'moment'

import { Avatar, Tooltip, Typography } from 'antd'

import SpaceVertical from 'components/systems/spaceVertical'
import GachaRowAction from './Action'

import configs from 'configs'

import { ellipsisText, thousandSeparator } from 'helper'

import type { ColumnType } from 'antd/lib/table'
import type { IGachaWithCount } from 'types/gacha.type'

export const gachaColumn: ColumnType<IGachaWithCount>[] = [
  {
    title: null,
    dataIndex: 'thumbnail',
    key: 'thumbnail',
    align: 'center',
    render: (thumbnail) => <Avatar src={thumbnail} alt="gacha-thumb" />,
  },
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    render: (_, { title, subtitle, slug }) => {
      const linkGacha = configs.user.origin + '/gacha/' + slug

      return (
        <SpaceVertical style={{ textAlign: 'left' }}>
          <Typography.Text>
            <Tooltip
              title={title}
              mouseEnterDelay={title.length > 37 ? 0.2 : 1000}
            >
              <Typography.Text type="secondary">Title: </Typography.Text>
              {ellipsisText(title, 40)}
            </Tooltip>
          </Typography.Text>

          <Typography.Text>
            <Tooltip
              title={subtitle}
              mouseEnterDelay={(subtitle ?? '').length > 34 ? 0.2 : 1000}
            >
              <Typography.Text type="secondary">Subtitle: </Typography.Text>
              {ellipsisText(subtitle ?? '', 37)}
            </Tooltip>
          </Typography.Text>

          <Typography.Link target="_blank" href={linkGacha} type="success">
            {ellipsisText(linkGacha, 50)}
          </Typography.Link>
        </SpaceVertical>
      )
    },
  },
  {
    title: 'Price',
    key: 'price',
    align: 'center',
    render: (_, { variants: [{ priceCCP, priceBadges, priceToken }] }) => (
      <SpaceVertical>
        {priceCCP && <Typography.Text>{priceCCP} EP</Typography.Text>}
        {priceBadges && (
          <Typography.Text>{priceBadges.length} Badges</Typography.Text>
        )}
        {priceToken && (
          <Typography.Text>
            {priceToken.price} {priceToken.tokenName?.toUpperCase()}
          </Typography.Text>
        )}
      </SpaceVertical>
    ),
  },
  {
    title: 'Timeline',
    key: 'timeline',
    align: 'center',
    render: (_, { startedAt, endedAt }) => (
      <SpaceVertical>
        <Typography.Text>{`${moment
          .utc(startedAt)
          .format('YYYY-MM-DD - HH:mm (UTC)')}`}</Typography.Text>

        <Typography.Text>{`${moment
          .utc(endedAt)
          .format('YYYY-MM-DD - HH:mm (UTC)')}`}</Typography.Text>
      </SpaceVertical>
    ),
  },
  {
    title: 'Spun / Sold',
    key: 'count',
    align: 'center',
    render: (_, { totalSpun, totalTicket }) => (
      <Typography.Text type="success">
        {thousandSeparator(totalSpun)} / {thousandSeparator(totalTicket)}
      </Typography.Text>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (_, record) => <GachaRowAction record={record} />,
  },
]
