import { useMutation } from 'react-query'

import { Acceler8TaskService } from 'services/acceler8-task'
import { TriggerSummarySeasonDto } from 'types/general-leaderboard/general-leaderboard.type'

export const useAcceler8SeasonSummary = () => {
  return useMutation((dto: TriggerSummarySeasonDto) =>
    Acceler8TaskService.summarySeason(dto),
  )
}
