import { generatePath, useNavigate } from 'react-router-dom'

import { Button, Col, Row, Table } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import { WrapLoading } from 'components/systems/loading'
import { COLUMN_COMMUNITY_LEADERBOARD } from './table/column'

import { useCommunitiesLeaderboard } from 'hooks/leaderboard/useCommunitiesLeaderboard'

import { ROUTES } from 'constant/routes'

type ListCommunityProps = {
  seasonId: string
}

const ListCommunityLeaderboard = ({ seasonId }: ListCommunityProps) => {
  const navigate = useNavigate()

  const { data, isLoading } = useCommunitiesLeaderboard({
    seasonId,
  })

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row justify="end" gutter={[12, 12]}>
          <Col>
            <Button
              className="btn-filled"
              icon={<IonIcon name="add-outline" />}
              onClick={() =>
                navigate(
                  generatePath(ROUTES.LEADERBOARD.NEW_COMMUNITY_LEADERBOARD, {
                    seasonId,
                  }),
                )
              }
            >
              Add communities
            </Button>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <Table
            rowKey="_id"
            className="table-border-collapse community-table"
            columns={COLUMN_COMMUNITY_LEADERBOARD}
            dataSource={data}
            pagination={false}
          />
        </WrapLoading>
      </Col>
    </Row>
  )
}

export default ListCommunityLeaderboard
