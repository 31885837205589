import { Button } from 'antd'

import {
  AffiliationSubscriptionStatus,
  ISubscriptionOverall,
} from 'types/affiliation.type'

type OverallActionProps = {
  item: ISubscriptionOverall
}

const OverallAction = ({ item }: OverallActionProps) => {
  const onActiveOrDeactive = () => {
    if (item.status === AffiliationSubscriptionStatus.Inactive) {
      //TODO: show Activate Affiliation Mode Modal here
    } else {
      //TODO: Call API to turn off the affiliation mode
    }
  }

  const currentBtnText =
    item.status === AffiliationSubscriptionStatus.Inactive
      ? 'Activate'
      : 'Deactivate'

  return (
    <Button ghost onClick={onActiveOrDeactive}>
      {currentBtnText}
    </Button>
  )
}

export default OverallAction
