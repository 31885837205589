import { Col, Row } from 'antd'
import { IStreakReward } from 'types/check-in.type'
import StreakReward from './streakReward'

const ListStreak = ({ streakRewards }: { streakRewards: IStreakReward[] }) => {
  return (
    <Row gutter={[32, 32]}>
      {[...streakRewards].reverse().map((streakReward, idx) => (
        <Col span={24} key={idx}>
          <StreakReward
            {...streakReward}
            title={`Day ${streakRewards.length - idx}`}
          />
        </Col>
      ))}
    </Row>
  )
}

export default ListStreak
