import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createGlobalState } from 'react-use'

import {
  Col,
  Dropdown,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { TaskType } from 'templates/types'
import { BountyCreateType, BountyState, MissionType } from 'constant/bounty'
import { ROUTES } from 'constant/routes'

import './index.less'

const { FlexibleTask, Hold, System, Trade } = TaskType
const { DApp, Discord } = BountyCreateType

const FILTER_ITEMS = [
  { label: Hold, value: Hold },
  { label: System, value: System },
  { label: Trade, value: Trade },
  { label: FlexibleTask, value: FlexibleTask },
]

const CREATE_BOUNTY_ITEMS = [
  { label: DApp, key: DApp, icon: <IonIcon name="add" /> },
  { label: Discord, key: Discord, icon: <IonIcon name="add" /> },
]

export type FilteredBountyProps = {
  status: BountyState
  taskType: TaskType
  search: string
}

export const useFilterProps = createGlobalState<FilteredBountyProps>({
  status: BountyState.Publish,
  taskType: TaskType.System,
  search: '',
})

const NewAndFilterBar = () => {
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState('')
  const [filteredProps, setFilteredProps] = useFilterProps()

  const onCreateBounty = useCallback(
    (e: any) => {
      switch (e.key) {
        case DApp:
          return navigate(`${ROUTES.BOUNTY.ADD_NEW}/${MissionType.DApp}`)
        case Discord:
          return navigate(`${ROUTES.BOUNTY.ADD_NEW}/${MissionType.Discord}`)
      }
    },
    [navigate],
  )

  useEffect(() => {
    const timeoutId = setTimeout(
      () => setFilteredProps({ ...filteredProps, search: keyword }),
      500,
    )
    return () => clearTimeout(timeoutId)
  }, [filteredProps, keyword, setFilteredProps])

  return (
    <Row align="middle" gutter={[24, 24]} justify="space-between">
      <Col span={8}>
        <Input
          placeholder="Search bounty name..."
          onChange={(e) => setKeyword(e.target.value)}
          suffix={<IonIcon name="search-outline" />}
        />
      </Col>
      <Col>
        <Space style={{ height: '100%' }}>
          <Radio.Group
            value={filteredProps.status}
            onChange={(e) =>
              setFilteredProps({ ...filteredProps, status: e.target.value })
            }
          >
            <Radio value={BountyState.Publish}>Publish</Radio>
            <Radio value={BountyState.UnPublish}>Unpublish</Radio>
            <Radio value={BountyState.Ended}>Ended</Radio>
          </Radio.Group>
        </Space>
      </Col>
      <Col>
        <Select
          value={filteredProps.taskType}
          options={FILTER_ITEMS}
          menuItemSelectedIcon={<IonIcon name="checkmark-outline" />}
          suffixIcon={<IonIcon name="filter-outline" />}
          className="dropdown-btn dropdown-filter"
          onChange={(e) =>
            setFilteredProps({
              ...filteredProps,
              taskType: e,
            })
          }
        />
      </Col>
      <Col>
        <Dropdown.Button
          overlayClassName="sp3-drop-overlay"
          className="dropdown-btn dropdown-create-bounty btn-filled"
          type="text"
          menu={{ items: CREATE_BOUNTY_ITEMS, onClick: onCreateBounty }}
          icon={
            <Space>
              <IonIcon name="add" />
              <Typography.Text strong>Create New Mission</Typography.Text>
              <IonIcon
                style={{ fontWeight: 700 }}
                name="chevron-down-outline"
              />
            </Space>
          }
        />
      </Col>
    </Row>
  )
}

export default NewAndFilterBar
