import { Typography } from 'antd'

import { ColumnType } from 'antd/lib/table'

import Action from './action'

import { ICommunityLeaderboard } from 'types/leaderboard.type'
import { ICommunity } from 'types/community.type'

export const COLUMN_COMMUNITY_LEADERBOARD: ColumnType<ICommunityLeaderboard>[] =
  [
    {
      title: 'Team Name',
      dataIndex: 'community',
      render: (community: ICommunity) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{ margin: 0, maxWidth: 160 }}
        >
          {community.title}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Point',
      dataIndex: 'point',
      width: 62,
      render: (point: number) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          style={{ margin: 0, maxWidth: 160 }}
        >
          {point}
        </Typography.Paragraph>
      ),
    },
    {
      title: '',
      dataIndex: '_id',
      width: 62,
      render: (text: string, record: any) => (
        <Action id={record._id} seasonId={record.seasonId} />
      ),
    },
  ]
