import { Fragment } from 'react'

import { Tooltip, Typography } from 'antd'

import useBountyReward from 'hooks/bounty/useBountyReward'

const RewardInfo = ({
  rewardBadgeIds,
  rewardCCP,
}: {
  rewardCCP: number
  rewardBadgeIds: string[]
}) => {
  const bountyRewards = useBountyReward({ rewardBadgeIds, rewardCCP })
  const rewards = bountyRewards.map(({ amount, rewardType }, index) => (
    <Fragment key={rewardType}>
      {amount} {rewardType}
      {index !== bountyRewards.length - 1 && <span>, </span>}
    </Fragment>
  ))

  return (
    <Tooltip title={rewards}>
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 165 }}
        type="success"
      >
        {rewards}
      </Typography.Paragraph>
    </Tooltip>
  )
}
export default RewardInfo
