import { ChangeEvent, useMemo, useState } from 'react'

import { InputNumber, Typography } from 'antd'
import { Button, Col, Input, Row, Space } from 'antd'
import CardInfo from 'components/systems/cardInfo'
import RequiredLabel from 'components/systems/requiredSymbol'

import { notifyError, notifySuccess } from 'helper'
import { Mint1155NFT, MintNFTService } from 'services/marketplace/evm/mintNft'
import InputImage from 'components/systems/uploadPicture/inputImage'
import { FormNoticeType } from 'constant'

const DUMMY_BADGE_DATA: Mint1155NFT = {
  title: '',
  tokenAddress: '',
  mintTo: '',
  thumbnail: '',
  description: '',
  supply: 0,
}

const MIN_SUPPLY = 1

const MintNft1155 = () => {
  const [nft, setNft] = useState<Mint1155NFT>(DUMMY_BADGE_DATA)
  const [thumbnail, setThumbnail] = useState('')
  const [loading, setLoading] = useState(false)

  const disabled = useMemo(() => {
    const { title, supply } = nft
    return !title || !supply
  }, [nft])

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    return setNft({ ...nft, [name]: value })
  }

  const onConfirm = async () => {
    if (!thumbnail || disabled) return
    try {
      setLoading(true)
      const params = { ...nft, thumbnail }
      await MintNFTService.mint1155Nft(params)
      notifySuccess('Create NFT successfully!')
      // return navigate(ROUTES.NFT_BADGE.INDEX)
      return true
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Typography.Title type="success" level={3}>
          Mint BSC NFT 1155
        </Typography.Title>
      </Col>
      <Col span={24}>
        <CardInfo title="NFT Info">
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text type="secondary">
                  {FormNoticeType.UploadMaxSize}
                </Typography.Text>
                <InputImage onChange={setThumbnail} value={thumbnail} />
                <Input
                  placeholder="thumbnail"
                  value={thumbnail}
                  onChange={(e) => setThumbnail(e.target.value)}
                />
                <Typography.Text type="secondary">
                  Upload or drag and drop a PNG, GIF, or JPG to display in the
                  app.
                </Typography.Text>
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RequiredLabel>Title (5-64 characters)</RequiredLabel>
                <Input
                  onChange={onChange}
                  name="title"
                  value={nft.title}
                  placeholder="Enter Name"
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RequiredLabel>Amount</RequiredLabel>
                <InputNumber
                  onChange={(supply) => setNft({ ...nft, supply: supply || 0 })}
                  value={nft.supply ? nft.supply : undefined}
                  placeholder="Enter supply"
                  min={MIN_SUPPLY}
                />
              </Space>
            </Col>
            {/* <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Text type="secondary">
                  Mint to address
                </Typography.Text>
                <Input
                  onChange={onChange}
                  name="mintTo"
                  value={nft.mintTo}
                  placeholder="0x..."
                />
              </Space>
            </Col> */}
            <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Text type="secondary">
                  Description (5-800 characters)
                </Typography.Text>
                <Input.TextArea
                  value={nft.description}
                  rows={4}
                  placeholder="Enter Description"
                  name="description"
                  onChange={onChange}
                />
              </Space>
            </Col>
          </Row>
        </CardInfo>
      </Col>

      <Col span={24}>
        <Space size={24}>
          <Button style={{ width: 130 }} ghost>
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            disabled={disabled}
            style={{ width: 130 }}
            type="primary"
            loading={loading}
          >
            Mint
          </Button>
        </Space>
      </Col>
    </Row>
  )
}

export default MintNft1155
