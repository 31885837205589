import { useEffect, useState } from 'react'

import { Button, Col, Row, Select, Typography } from 'antd'

import { useBountySetup } from 'view/bounty/management/index'
import { useProductSuggestion } from 'hooks/product/useProductSuggestion'

import { notifyError } from 'helper'

type SelectType = {
  value: string
  label: string
}

function FeaturedRewards() {
  const [options, setOptions] = useState<SelectType[]>([])
  const [bountyData, setBountyData] = useBountySetup()
  const { products, isLoading, fetchProducts } = useProductSuggestion()

  const updateOptions = () => {
    products &&
      setOptions(
        products.map((product) => ({
          value: product._id,
          label: product.title,
        })),
      )
  }

  const updateFeaturedRewards = () => {
    products &&
      setBountyData((prev) => ({
        ...prev,
        featuredRewardIds: products.map((product) => product._id).slice(0, 5),
      }))
  }

  /**
   * If featuredRewardIds is not exist, after fetching products,
   * Update options and then generate featuredRewardIds
   */
  useEffect(() => {
    updateOptions()

    if (
      !bountyData.featuredRewardIds ||
      bountyData.featuredRewardIds?.length === 0
    ) {
      updateFeaturedRewards()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  /**
   * If featuredRewardIds is already exist and option was updated,
   * Remove items that is not in options.
   */
  useEffect(() => {
    if (
      bountyData.featuredRewardIds &&
      bountyData.featuredRewardIds?.length > 0 &&
      options.length > 0
    ) {
      const newItems = bountyData.featuredRewardIds?.filter((id) =>
        options.some((option) => option.value === id),
      )

      setBountyData((prev) => ({
        ...prev,
        featuredRewardIds: newItems,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  /**
   * If featuredRewardIds is already exist,
   * fetch products to get options.
   */
  useEffect(() => {
    if (
      bountyData.featuredRewardIds &&
      bountyData.featuredRewardIds?.length > 0
    ) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Typography.Text type="secondary">Redeemable Items</Typography.Text>
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          loading={isLoading}
          onClick={() => fetchProducts()}
        >
          Generate Redeemable Items
        </Button>
      </Col>
      <Col span={24}>
        <Select
          value={bountyData?.featuredRewardIds || []}
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Enter Reward Id"
          onChange={(value) => {
            if (value.length > 5)
              return notifyError('Please input less than 6 items!')
            setBountyData((prev) => ({
              ...prev,
              featuredRewardIds: value,
            }))
          }}
          options={options}
          filterOption={(input, option) => {
            return (option?.label ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }}
        />
      </Col>
    </Row>
  )
}

export default FeaturedRewards
