import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Col,
  InputNumber,
  Row,
  Select,
  Table,
  TableColumnsType,
  Typography,
} from 'antd'

import InputImage from 'components/systems/uploadPicture/inputImage'
import CreateProductContext from '../FormProductContext'

import { useDigitalCardsInfinite } from 'hooks/digitalCards/useDigitalCards'
import { decodeKey } from 'helper/common'

import { AttributeConfig } from 'services/marketplace/product-category.type'
import { CodeSKU, ISKU } from 'types/product.type'

type SKUData = ISKU & { _id?: string }

export type ProductSKUProps = {
  variations: AttributeConfig[]
  value: SKUData[]
  onChange: (value: ISKU[]) => void
}

const KEYS = {
  Amount: 'amount',
  Thumbnail: 'thumbnail',
  PayableMax: 'Payable Max',
  DigitalCard: 'Digital Card',
}

const FormProductSKU = ({ variations, value, onChange }: ProductSKUProps) => {
  const { isCodeItem } = useContext(CreateProductContext)
  const { digitalCards, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useDigitalCardsInfinite({
      enabled: isCodeItem,
    })
  const [prevSKUs, setPrevSKUs] = useState<SKUData[]>([])

  const handleChangeAvailable = useCallback(
    (id: string, amount: number) => {
      const newValue = [...value]
      for (let i = 0; i < value.length; i++) {
        const elm = value[i]
        if (elm.variationId !== id) continue
        newValue[i] = { ...value[i], available: amount, totalAvailable: amount }
      }

      return onChange(newValue)
    },
    [onChange, value],
  )

  const handleChangeVariations = useCallback(
    (id: string, updateData: CodeSKU) => {
      const newValue = [...value]
      for (let i = 0; i < value.length; i++) {
        const elm = value[i]
        if (elm.variationId !== id) continue

        newValue[i] = { ...value[i], ...updateData }
      }

      return onChange(newValue)
    },
    [onChange, value],
  )

  const handleChangeThumbnail = useCallback(
    (id: string, thumbnail: string) => {
      const newValue = [...value]
      for (let i = 0; i < value.length; i++) {
        const elm = value[i]
        if (elm.variationId !== id) continue
        newValue[i] = { ...value[i], thumbnail }
      }
      return onChange(newValue)
    },
    [onChange, value],
  )

  useEffect(() => {
    setPrevSKUs(value)
    // eslint-disable-next-line
  }, [])

  const variationCols = useMemo(() => {
    return variations.map(({ title }) => {
      return {
        title: title,
        key: 'id',
        render: (data: ISKU) => {
          return (
            <Typography.Text>
              {data.variations[decodeKey(title)]}
            </Typography.Text>
          )
        },
      }
    })
  }, [variations])

  const cardCodeCols = useMemo(() => {
    if (!isCodeItem) return []

    return [
      {
        title: KEYS.DigitalCard,
        key: 'id',
        render: (data: ISKU) => (
          <div style={{ width: '100%', display: 'block' }}>
            <Select
              onChange={(idAndTotal: string) => {
                const [id, total] = idAndTotal.split('^')

                handleChangeVariations(data.variationId, {
                  digitalCardId: id,
                  available: parseInt(total),
                  totalAvailable: parseInt(total),
                })
              }}
              style={{ width: '200px', minWidth: '100%' }}
              value={(() => {
                const item = digitalCards.find(
                  (card) => card._id === data.digitalCardId,
                )
                if (!item) return null

                return item._id + '^' + item.total
              })()}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {hasNextPage && (
                    <Button
                      block
                      type="primary"
                      onClick={() => fetchNextPage()}
                      loading={isFetchingNextPage}
                    >
                      Load more
                    </Button>
                  )}
                </>
              )}
            >
              {digitalCards
                .filter((item) => {
                  if (
                    item.productSKUId &&
                    !prevSKUs.find((sku) => sku?._id === item.productSKUId)
                  )
                    return false

                  return true
                })
                .map((item, idx) => (
                  <Select.Option key={idx} value={item._id + '^' + item.total}>
                    {item.title}{' '}
                  </Select.Option>
                ))}
            </Select>
          </div>
        ),
      },
      {
        title: KEYS.Amount,
        key: 'id',
        render: (data: ISKU) => (
          <InputNumber
            defaultValue={0}
            value={data.available}
            onChange={(val) => {
              handleChangeVariations(data.variationId, {
                available: val || 0,
                totalAvailable: val || 0,
              })
            }}
            min={0}
            max={
              digitalCards.find((card) => card._id === data.digitalCardId)
                ?.total
            }
          />
        ),
      },
      {
        title: KEYS.PayableMax,
        key: 'id',
        render: (data: ISKU) => (
          <InputNumber
            defaultValue={1}
            value={data.payableMax}
            onChange={(val) => {
              handleChangeVariations(data.variationId, { payableMax: val || 1 })
            }}
            min={1}
            disabled
          />
        ),
      },
    ]
  }, [
    digitalCards,
    fetchNextPage,
    isCodeItem,
    isFetchingNextPage,
    handleChangeVariations,
    hasNextPage,
    prevSKUs,
  ])

  const amountCol = useMemo(() => {
    if (isCodeItem) return undefined

    return {
      title: KEYS.Amount,
      key: 'id',
      render: (data: ISKU) => (
        <InputNumber
          defaultValue={0}
          value={data.available}
          onChange={(val) => {
            handleChangeAvailable(data.variationId, val || 0)
          }}
          min={0}
        />
      ),
    }
  }, [isCodeItem, handleChangeAvailable])

  const columns: TableColumnsType<ISKU> = useMemo(() => {
    const cols = [
      {
        title: KEYS.Thumbnail,
        key: 'id',
        render: (data: ISKU) => (
          <InputImage
            value={data.thumbnail}
            onChange={(val) => handleChangeThumbnail(data.variationId, val)}
          />
        ),
      },
      ...variationCols,
      ...cardCodeCols,
    ]

    if (amountCol) cols.push(amountCol)
    return cols
  }, [handleChangeThumbnail, variationCols, cardCodeCols, amountCol])

  return (
    <Row>
      <Col span={24}>
        <Table
          rowKey={({ variationId }) => variationId}
          columns={columns}
          dataSource={value}
          className="table-border-collapse"
        />
      </Col>
    </Row>
  )
}

export default FormProductSKU
