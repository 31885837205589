const useHandle = <T extends Function>(
  action: T,
  setLoading = (loading: boolean) => {},
) => {
  return async (...params: any) => {
    try {
      setLoading(true)
      await action(...params)
    } catch (error: any) {
      const rpMessage = error?.response?.data?.message
      window.notify({ type: 'error', description: rpMessage || error.message })
    } finally {
      setLoading(false)
    }
  }
}

export default useHandle
