import { useMutation } from 'react-query'

import { MultiplierService } from 'services/multiplier'

import { CreateMultiplierDto } from 'types/multiplier.type'

export const useCreateMultiplier = () => {
  return useMutation({
    mutationKey: ['CREATE_MULTIPLIER'],
    mutationFn: (dto: CreateMultiplierDto) =>
      MultiplierService.createMultiplier(dto),
  })
}
