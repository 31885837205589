import { useContext } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Card, Col, Dropdown, Image, Row, Typography } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'

import IonIcon from 'components/systems/ionIcon'
import { WhitelistModalContext } from './CreateUpdateContext'

import { ROUTES } from 'constant/routes'
import { CardWhitelistActions } from 'constant/whitelist'

import whitelistImg from 'static/images/gacha/whitelist.png'

import { WhitelistData } from 'types/whitelist.type'

type CardWhitelistProps = {
  whitelistData: WhitelistData
}

const MENU_ITEMS = [{ label: 'Edit', key: CardWhitelistActions.Edit }]

function CardWhitelist({ whitelistData }: CardWhitelistProps) {
  const navigate = useNavigate()
  const { openModal } = useContext(WhitelistModalContext)

  const { title, description, thumbnail, _id: whitelistId } = whitelistData

  const onItemClick = (e: MenuInfo) => {
    e.domEvent.stopPropagation()
    switch (e.key) {
      case CardWhitelistActions.Edit:
        return openModal(true, whitelistId)
      default:
        return
    }
  }

  return (
    <Card
      hoverable
      bodyStyle={{ padding: 0 }}
      bordered
      onClick={() =>
        navigate(generatePath(ROUTES.WHITELIST.VIEW_DETAIL, { whitelistId }))
      }
      className="card-whitelist"
    >
      <Row>
        <Col span={24}>
          <Image
            src={thumbnail ?? whitelistImg}
            fallback={whitelistImg}
            alt="whitelist"
            style={{ aspectRatio: 1, objectFit: 'cover' }}
            preview={false}
          />

          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown.Button
              type="text"
              style={{ width: 'auto' }}
              className="delete-dropdown"
              menu={{
                items: MENU_ITEMS,
                onClick: onItemClick,
              }}
              icon={
                <div className="delete-dropdown_content">
                  <IonIcon
                    name="ellipsis-vertical"
                    style={{ color: '#FFFFFF' }}
                  />
                </div>
              }
              trigger={['click']}
            />
          </div>
        </Col>

        <Col span={24} style={{ padding: '8px 12px' }}>
          <Typography.Title level={5} ellipsis>
            {title}
          </Typography.Title>

          <Typography.Text ellipsis type="secondary">
            {description}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  )
}

export default CardWhitelist
