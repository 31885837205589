import { useContext } from 'react'

import { Col, Row, Image, Typography } from 'antd'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import { useUserNFTDetails } from 'hooks/evm/useUserNft'
import useIsMobile from 'hooks/systems/useIsMobile'
import defaultNftThumbnail from 'static/images/icon/ico-nft.svg'

export type NftInfoProps = {
  nftAddress: string
}
const NftInfo = ({ nftAddress }: NftInfoProps) => {
  const nftDetails = useUserNFTDetails(nftAddress)
  const isMobile = useIsMobile('xs')
  const { selectedChain } = useContext(SelectChainContext)

  const chainName = selectedChain?.split(':')?.[1] ?? 'BNB'

  const maxWidth = isMobile ? 300 : 400

  return (
    <Row gutter={[24, 24]}>
      <Col>
        <Image
          src={nftDetails?.image ?? defaultNftThumbnail}
          width={88}
          height={88}
        />
      </Col>
      <Col style={{ maxWidth }}>
        <Typography.Text type="secondary" ellipsis={true}>
          {nftDetails?.collectionName ?? 'Default Collection'}
        </Typography.Text>
        <Typography.Title level={4} ellipsis={true}>
          {nftDetails?.name}
        </Typography.Title>
        <Typography.Text type="secondary">
          <Typography.Text type="success">
            {nftDetails?.tokenId}/{' '}
          </Typography.Text>{' '}
          {chainName} Chain
        </Typography.Text>
      </Col>
    </Row>
  )
}

export default NftInfo
