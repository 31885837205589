import { useCallback, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { Button, Col, Image, Row, Space, Table, Typography } from 'antd'

import CardInfo from 'components/systems/cardInfo'
import CustomPagination from 'components/systems/pagination'
import { teamColumn } from './column/TeamColumn'
import { prizeColumn } from './column/PrizeColumn'
import { REFERRAL_PERCENT_COLUMN } from './column/ReferralPercentColumn'

import { usePlayerSeason } from 'hooks/player-leaderboard/usePlayerSeason'
import { usePlayerRanks } from 'hooks/player-leaderboard/usePlayerRanks'
import { usePlayerSeasonBefore } from 'hooks/player-leaderboard/usePlayerSeasonBefore'
import { useAcceler8JobControl } from 'hooks/acceler8/useAcceler8JobControl'
import { useAcceler8SeasonSummary } from 'hooks/acceler8/useAcceler8SeasonSummary'
import { useAcceler8Jobs } from 'hooks/acceler8/useAcceler8Jobs'

import { notifyError, notifySuccess } from 'helper'

import { DEFAULT_PAGE, TIME_FORMAT } from 'constant'
import { ROUTES } from 'constant/routes'

import { PlayerSortOptions } from 'types/player-leaderboard/player-leaderboard-rank.type'
import { ProgramType } from 'types/acceler8/acceler8-program.type'
import { JobStatus, JobType } from 'types/acceler8/acceker8-job.type'

const PAGE_SIZE = 10

function PlayerSeasonDetail() {
  const navigate = useNavigate()
  // TODO: implement when API is ready
  // const [search, setSearch] = useState<string>('')
  const seasonId = Number(useParams().seasonId)
  const [page, setPage] = useState<number>(DEFAULT_PAGE)

  const { data: seasonData } = usePlayerSeason(seasonId)
  const {
    data: currentRanksData,
    isLoading: isBoardLoading,
    total,
  } = usePlayerRanks({
    seasonId,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    sort: PlayerSortOptions.CurrentSeasonRank,
  })

  const { data: previousSeason } = usePlayerSeasonBefore(seasonId)
  const { data: previousRanksData } = usePlayerRanks({
    seasonId: previousSeason?.id,
    userIds: currentRanksData.map((rank) => rank.userId),
  })
  const { mutate: summarySeason, isLoading } = useAcceler8SeasonSummary()
  const { data: seasonSummaryJobs, refetch: refetchJobs } = useAcceler8Jobs({
    type: JobType.SeasonSummary,
    seasonId,
    limit: 1,
  })
  const { pause, resume, cancel, pauseLoading, resumeLoading, cancelLoading } =
    useAcceler8JobControl()

  const [summaryBtnText, summaryBtnDisabled] = useMemo(() => {
    const job = seasonSummaryJobs?.[0]
    if ([JobStatus.Processing, JobStatus.Paused].includes(job?.status as any))
      return ['Summary (In-progress)', true]
    if (job?.status === JobStatus.Completed)
      return ['Summary (Completed)', true]
    return ['Summary', false]
  }, [seasonSummaryJobs])

  const [pauseBtnText, pauseBtnDisabled] = useMemo(() => {
    const job = seasonSummaryJobs?.[0]
    if (job?.status === JobStatus.Processing) return ['Pause Summary', false]
    if (job?.status === JobStatus.Paused) return ['Resume Summary', false]
    return ['Pause Summary', true]
  }, [seasonSummaryJobs])

  const [cancelBtnText, cancelBtnDisabled] = useMemo(() => {
    const job = seasonSummaryJobs?.[0]
    if (job?.status === JobStatus.Processing) return ['Cancel Summary', false]
    return ['Cancel Summary', true]
  }, [seasonSummaryJobs])

  const totalPage = useMemo(() => {
    return Math.ceil(total / PAGE_SIZE)
  }, [total])

  const boardData = useMemo(() => {
    const rankByUserId: Record<string, number | undefined> = {}
    previousRanksData.forEach(
      (rank) => (rankByUserId[rank.userId] = rank.ranking),
    )

    return currentRanksData.map((currentRank) => {
      return {
        ...currentRank,
        previousRanking: rankByUserId[currentRank.userId],
      }
    })
  }, [currentRanksData, previousRanksData])
  // TODO: implement when API is ready
  // const onSearch = useCallback(
  //   (keyword: string) => {
  //     setSearch(keyword)
  //   },
  //   [setSearch],
  // )

  const onSummarySeason = useCallback(async () => {
    const payload = { seasonId, type: ProgramType.Player }
    summarySeason(payload, {
      onSuccess: () => {
        notifySuccess('Season summary')
        refetchJobs()
      },
      onError: (err) => {
        notifyError(err)
      },
    })
  }, [seasonId, summarySeason, refetchJobs])

  const onPauseOrResumeJob = useCallback(async () => {
    const job = seasonSummaryJobs?.[0]
    if (!job) return
    if (job?.status === JobStatus.Processing) {
      pause(job._id, {
        onSuccess: () => {
          notifySuccess('Pause summary')
          refetchJobs()
        },
        onError: (err) => {
          notifyError(err)
        },
      })
      return
    }

    if (job?.status === JobStatus.Paused) {
      resume(job._id, {
        onSuccess: () => {
          notifySuccess('Resume summary')
          refetchJobs()
        },
        onError: (err) => {
          notifyError(err)
        },
      })
      return
    }
  }, [seasonSummaryJobs, pause, refetchJobs, resume])

  const onCancelJob = useCallback(async () => {
    const job = seasonSummaryJobs?.[0]
    if (!job) return
    if ([JobStatus.Processing, JobStatus.Paused].includes(job?.status as any)) {
      cancel(job._id, {
        onSuccess: (data) => {
          notifySuccess('Cancel summary')
          refetchJobs()
        },
        onError: (err) => {
          notifyError(err)
        },
      })
      return
    }
  }, [seasonSummaryJobs, cancel, refetchJobs])

  return (
    <Row gutter={[24, 50]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={3} type="success">
              Season {seasonData?.title}:{' '}
              <Typography.Text type="secondary">
                {seasonData?.subTitle}
              </Typography.Text>
            </Typography.Title>
          </Col>
          <Col>
            <Space>
              <Button
                size="small"
                loading={isLoading}
                ghost
                disabled={summaryBtnDisabled}
                onClick={onSummarySeason}
              >
                {summaryBtnText}
              </Button>
              <Button
                size="small"
                loading={pauseLoading || resumeLoading}
                ghost
                disabled={pauseBtnDisabled}
                onClick={onPauseOrResumeJob}
              >
                {pauseBtnText}
              </Button>
              <Button
                size="small"
                loading={cancelLoading}
                ghost
                disabled={cancelBtnDisabled}
                onClick={onCancelJob}
              >
                {cancelBtnText}
              </Button>
              <Button
                size="small"
                ghost
                onClick={() =>
                  navigate(
                    generatePath(ROUTES.PLAYER_LEADERBOARD.EDIT_SEASON, {
                      seasonId,
                    }),
                  )
                }
              >
                Edit
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Image
              src={seasonData?.banner}
              width="100%"
              style={{ aspectRatio: '22/9', objectFit: 'cover' }}
              preview={false}
            />
          </Col>
          <Col span={24}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Time</Typography.Text>
              <Space direction="vertical" size={0}>
                <Space>
                  <Typography.Text type="success">Start:</Typography.Text>
                  <Typography.Text>
                    {moment.utc(seasonData?.startedAt).format(TIME_FORMAT)} UTC
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text type="success">End:</Typography.Text>
                  <Typography.Text>
                    {moment.utc(seasonData?.endedAt).format(TIME_FORMAT)} UTC
                  </Typography.Text>
                </Space>
              </Space>
            </Space>
          </Col>

          <Col span={24}>
            <CardInfo title="Referral Percent">
              <Table
                dataSource={seasonData?.referralPercents.map(
                  (percent, index) => ({
                    key: index,
                    level: index + 1,
                    percent: percent,
                  }),
                )}
                columns={REFERRAL_PERCENT_COLUMN}
                rowKey="title"
                pagination={false}
              />
            </CardInfo>
          </Col>

          <Col span={10}>
            <CardInfo title="Prize structure">
              <Table
                dataSource={seasonData?.prizeStructures}
                columns={prizeColumn}
                rowKey="title"
                pagination={false}
              />
            </CardInfo>
          </Col>

          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Col>
                <Typography.Text type="secondary">Leaderboard</Typography.Text>
              </Col>
              <Col span={24}>
                {/* TODO: implement when API is ready */}
                {/* <SearchBar setSearch={onSearch} /> */}
              </Col>
              <Col span={24}>
                <Table
                  dataSource={boardData}
                  columns={teamColumn}
                  rowKey="id"
                  loading={isBoardLoading}
                  pagination={false}
                />
              </Col>
              <Col span={24}>
                <Row align="middle">
                  <Col flex="auto">
                    <Typography.Text type="success">
                      Total: {totalPage}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <CustomPagination
                      total={total}
                      onChange={setPage}
                      page={page}
                      pageSize={PAGE_SIZE}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PlayerSeasonDetail
