import { type PropsWithChildren } from 'react'

import { Button, Typography } from 'antd'
import { DeleteOutlined as RemoveIcon } from '@ant-design/icons'

import SpaceVertical from 'components/systems/spaceVertical'

import './index.less'

export type BaseRewardItemProps = PropsWithChildren<{
  title: string
  onRemove: () => void
}>

function BaseRewardItem({ title, children, onRemove }: BaseRewardItemProps) {
  return (
    <div className="gacha--reward-container">
      <SpaceVertical size={16}>
        <Typography.Title level={5} type="success">
          Reward Type: {title}
        </Typography.Title>

        <div className="gacha--reward-content">{children}</div>
      </SpaceVertical>

      <Button
        className="gacha--reward-container--remove"
        icon={<RemoveIcon />}
        danger
        onClick={onRemove}
      />
    </div>
  )
}

export default BaseRewardItem
