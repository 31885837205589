import { useMemo } from 'react'

import { Col, type FormInstance, type FormListFieldData } from 'antd'

import BaseRewardItem, { type BaseRewardItemProps } from './BaseRewardItem'
import RewardEpItem from './RewardEpItem'
import RewardTokenItem from './RewardTokenItem'
import RewardBadgeItem from './RewardBadgeItem'
import RewardNftItem from './RewardNftItem'
import RewardDigitalCardItem from './RewardDigitalCardItem'
import RewardWhitelistItem from './RewardWhitelistItem'
import RewardCongratsItem from './RewardCongratsItem'

import { GachaRewardType } from 'types/gacha.type'

export type RewardItemProps<T = NonNullable<{}>> = {
  prefixNamePath: FormListFieldData['name']
} & T

type RewardItemProviderProps = RewardItemProps &
  Pick<BaseRewardItemProps, 'onRemove'> & {
    type: string
    form: FormInstance
  }

function RewardItemProvider({
  prefixNamePath,
  onRemove,
  type,
  form,
}: RewardItemProviderProps) {
  const { Component, title } = useMemo<{
    Component: JSX.Element | null
    title: string
  }>(() => {
    switch (type) {
      case GachaRewardType.EP:
        return {
          Component: <RewardEpItem prefixNamePath={prefixNamePath} />,
          title: 'EP',
        }

      case GachaRewardType.Token:
        return {
          Component: (
            <RewardTokenItem form={form} prefixNamePath={prefixNamePath} />
          ),
          title: 'TOKEN',
        }

      case GachaRewardType.Badge:
        return {
          Component: <RewardBadgeItem prefixNamePath={prefixNamePath} />,
          title: 'BADGE',
        }

      case GachaRewardType.NFT:
        return {
          Component: (
            <RewardNftItem form={form} prefixNamePath={prefixNamePath} />
          ),
          title: 'NFT',
        }

      case GachaRewardType.GiftCard:
        return {
          Component: (
            <RewardDigitalCardItem
              form={form}
              prefixNamePath={prefixNamePath}
            />
          ),
          title: 'GIFT CARD',
        }

      case GachaRewardType.Whitelist:
        return {
          Component: (
            <RewardWhitelistItem form={form} prefixNamePath={prefixNamePath} />
          ),
          title: 'WHITELIST',
        }

      case GachaRewardType.Congrats:
        return {
          Component: <RewardCongratsItem prefixNamePath={prefixNamePath} />,
          title: 'CONGRATS',
        }

      default:
        return {
          Component: null,
          title: '',
        }
    }
  }, [type, prefixNamePath, form])

  return (
    <Col span={24} style={{ display: !!Component ? 'block' : 'none' }}>
      <BaseRewardItem title={title} onRemove={onRemove}>
        {Component}
      </BaseRewardItem>
    </Col>
  )
}

export default RewardItemProvider
