import { useState } from 'react'

import {
  Avatar,
  Button,
  Col,
  InputNumber,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { useBadgeData } from 'hooks/badges/useBadges'

type NFTItemProps = {
  badgeId: string
  amount: number
  threshold?: number
  onChangeAmount: (value: number | null) => void
  onDelete: (badgeId: string) => void
}
const NFTItem = ({
  badgeId,
  onDelete,
  amount = 1,
  threshold,
  onChangeAmount,
}: NFTItemProps) => {
  const [open, setOpen] = useState(false)
  const { badgeData } = useBadgeData(badgeId)

  const onDeleteNFT = () => {
    onDelete(badgeId)
    setOpen(false)
  }

  return (
    <Row align="middle" wrap={false} gutter={8}>
      <Col flex={1}>
        <Row wrap={false} align="middle" gutter={8}>
          <Col>
            <Avatar src={badgeData.thumbnail} />
          </Col>

          <Col flex={1}>
            <Typography.Title
              level={5}
              ellipsis={{
                tooltip: badgeData.title,
              }}
              style={{ maxWidth: '100%' }}
            >
              {badgeData.title}
            </Typography.Title>
          </Col>
        </Row>
      </Col>

      <Col>
        <Space size={80}>
          <InputNumber
            value={amount}
            onChange={onChangeAmount}
            className="show-number-handler"
            min={1}
            max={threshold}
            width={100}
            style={{ width: 100, height: 32 }}
            size="small"
          />
          <Button
            className="delete-nft"
            type="text"
            icon={<IonIcon name="trash-outline" />}
            onClick={() => setOpen(true)}
          />
          <Modal
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width={346}
            closeIcon={<IonIcon name="close-outline" />}
          >
            <Row style={{ textAlign: 'center' }} gutter={[0, 40]}>
              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Title style={{ color: '#DB4646' }} level={4}>
                    Are you sure to delete
                  </Typography.Title>
                  <Typography.Title type="success" level={4}>
                    {badgeData.title} ?
                  </Typography.Title>
                </Space>
              </Col>

              <Col span={24}>
                <Space size={20}>
                  <Button
                    onClick={() => setOpen(false)}
                    style={{ width: 129 }}
                    ghost
                  >
                    No
                  </Button>
                  <Button
                    onClick={onDeleteNFT}
                    style={{ width: 129 }}
                    type="primary"
                  >
                    Yes
                  </Button>
                </Space>
              </Col>
            </Row>
          </Modal>
        </Space>
      </Col>
    </Row>
  )
}

export default NFTItem
