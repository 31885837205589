import { Avatar, Dropdown, Space, Typography } from 'antd'

import { useUserProfile } from 'hooks/user/useUserProfile'
import { useWalletAddress } from 'hooks/user/useWalletAddress'
import { ChainID } from 'constant'
import { DROPDOWN_MENUS } from './userActionContainer'
import { shortenAddress } from 'helper'

type MobileUserActionProps = { onClick?: (e: any) => void }
const MobileUserAction = ({ onClick = () => {} }: MobileUserActionProps) => {
  const { avatar, userName } = useUserProfile()
  const walletAddress = useWalletAddress(ChainID.Solana)

  return (
    <Space>
      <Dropdown.Button
        type="text"
        className="dropdown-btn pad-less"
        style={{ width: 'auto', height: '100%', cursor: 'pointer' }}
        trigger={['click']}
        menu={{ items: DROPDOWN_MENUS, onClick }}
        icon={<Avatar src={avatar} size={40} />}
      />
      <Space direction="vertical" size={0}>
        <Typography.Title level={5} type="success">
          {userName || 'UNKNOW'}
        </Typography.Title>
        <Space size={6}>
          {/* <Avatar icon="" size={16} /> */}
          <Typography.Text className="caption">
            {shortenAddress(walletAddress)}
          </Typography.Text>
        </Space>
      </Space>
    </Space>
  )
}

export default MobileUserAction
