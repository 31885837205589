import { createContext } from 'react'

import { DEFAULT_PAGE } from 'constant'

export type Acceler8SeasonFilter = {
  page: number
  search: string
  setPage: (page: number) => void
  setSearch: (search: string) => void
}

const PlayerSeasonFilterValue: Acceler8SeasonFilter = {
  page: DEFAULT_PAGE,
  search: '',
  setPage: () => {},
  setSearch: () => {},
}

const FilterContext = createContext(PlayerSeasonFilterValue)

export default FilterContext
