import { useMemo } from 'react'

import { Col, Row, Steps } from 'antd'
import CardInfo from 'components/systems/cardInfo'

import { OrderStatus, ShippingStatus } from 'types/order.type'

type LogisticInfoProps = {
  shippingStatus: ShippingStatus
  orderStatus: OrderStatus
  isDigital: boolean
}
const { Done, Preparing, Sending } = ShippingStatus
const { Initialized, Open, Paid } = OrderStatus
const PHYSICAL_HISTORY = [
  {
    title: 'Preparing',
    // description: '2022/09/03 10:00(UTC)',
  },
  {
    title: 'Sending',
    // description: '2022/09/03 10:00(UTC)',
  },
  {
    title: 'Done',
    // description: '2022/09/03 10:00(UTC)',
  },
]

const DIGITAL_HISTORY = [
  { title: 'Initialized' },
  { title: 'Open' },
  { title: 'Paid' },
  { title: 'Sending' },
  { title: 'Done' },
]

const LogisticInfo = ({
  shippingStatus,
  isDigital,
  orderStatus,
}: LogisticInfoProps) => {
  const currentStep = useMemo(() => {
    if (!isDigital)
      switch (shippingStatus) {
        case Done:
          return 2
        case Sending:
          return 1
        case Preparing:
          return 0
        default:
          return 0
      }
    switch (orderStatus) {
      case OrderStatus.Done:
        return 4
      case OrderStatus.Sending:
        return 3
      case Paid:
        return 2
      case Open:
        return 1
      case Initialized:
        return 0
      default: {
        DIGITAL_HISTORY.push({ title: 'Fail' })
        return 5
      }
    }
  }, [isDigital, orderStatus, shippingStatus])

  return (
    <CardInfo title="Logistic Information">
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Steps
            progressDot
            current={currentStep}
            direction="vertical"
            className="logistic-step"
            items={isDigital ? DIGITAL_HISTORY : PHYSICAL_HISTORY}
          />
        </Col>
      </Row>
    </CardInfo>
  )
}

export default LogisticInfo
