import { Fragment, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import Papa from 'papaparse'

import { Button, Col, Image, Row, Space, Spin, Typography, Upload, UploadFile } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { notifyError, notifySuccess } from 'helper'

import { FILE_SIZE_ONE_MB } from 'constant/marketplace'

import iconUpload from 'static/images/bounty/upload.svg'
import csvLogo from 'static/images/bounty/csvLogo.svg'

type UploadRedemptionsProps = {
  onUploadSuccess: () => void
}
const UploadRedemptions = ({ onUploadSuccess }: UploadRedemptionsProps) => {
  const [file, setFile] = useState<UploadFile>()
  const [error, setError] = useState(false)

  const queryClient = useQueryClient()

  const { mutate: onUpload, isLoading } = useMutation(
    async () => {
      if (!file) return
      await PlayerLeaderboardService.importPlayerRedemptions({ file })
    },
    {
      onSuccess: () => {
        onUploadSuccess()
        notifySuccess('File uploaded successfully')
        queryClient.invalidateQueries('GET_PLAYER_REDEMPTIONS')
      },
      onError: (error: any) => {
        return notifyError(error)
      },
    },
  )

  const onRemove = () => {
    setFile(undefined)
    setError(false)
  }

  return (
    <Fragment>
      <Row gutter={[0, 38]} justify="center">
        <Col span={24}>
          <Space
            align="center"
            size={4}
            direction="vertical"
            style={{ width: '100%' }}
          >
            <Typography.Title type="success" level={4}>
              Upload Player Redemptions CSV
            </Typography.Title>
            <Typography.Text type="secondary">
              You can upload CSV file with maximum size is 20MB
            </Typography.Text>
          </Space>
        </Col>
        <Col span={24}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Spin spinning={isLoading}>
              <Upload.Dragger
                accept=".csv"
                maxCount={1}
                className={error ? 'upload-file error' : 'upload-file'}
                beforeUpload={() => false}
                onChange={(file) =>
                  new Promise((resolve, reject) => {
                    return Papa.parse(file.file as any, {
                      complete: ({ data }, fileInfo) => {
                        if (fileInfo.size > FILE_SIZE_ONE_MB * 20)
                          return reject('File to large')
                        setFile(fileInfo)
                        return resolve(data)
                      },
                    })
                  })
                }
                showUploadList={false}
                progress={{ strokeWidth: 2, showInfo: true }}
              >
                {!file ? (
                  <Space direction="vertical" align="center">
                    <Image src={iconUpload} preview={false} />
                    <Typography.Text type="secondary">
                      Upload file
                    </Typography.Text>
                  </Space>
                ) : (
                  <Space direction="vertical" size={12} align="center">
                    <Image src={csvLogo} preview={false} />
                    <Typography.Text>{file.name}</Typography.Text>
                  </Space>
                )}
              </Upload.Dragger>
            </Spin>
            {error && (
              <Space align="center">
                <IonIcon
                  style={{ color: '#ff4d4f' }}
                  name="alert-circle-outline"
                />
                <Typography.Text type="danger">
                  Upload file size exceeds 10MB limit
                </Typography.Text>
              </Space>
            )}
          </Space>
        </Col>
        <Col>
          <Space size={20}>
            <Button onClick={onRemove} style={{ width: 130 }} ghost>
              Reset
            </Button>
            <Button
              onClick={() => onUpload()}
              style={{ width: 130 }}
              type="primary"
              disabled={!file}
            >
              Upload
            </Button>
          </Space>
        </Col>
      </Row>
    </Fragment>
  )
}

export default UploadRedemptions
