import { Form, FormItemProps, Select } from 'antd'

import RequiredLabel from './requiredSymbol'

import { OptionElement } from 'constant'
import { CHAIN_OPTIONS } from './selectChain/selectChain'

type FormItemSelectChainProps = {
  disabled?: boolean
} & FormItemProps

const FormItemSelectChain = ({
  disabled,
  ...props
}: FormItemSelectChainProps) => {
  const sortChain = (chainA: OptionElement, chainB: OptionElement) => {
    return chainA.value > chainB.value ? 1 : -1
  }

  return (
    <Form.Item
      label={<RequiredLabel children="Network" />}
      rules={[
        {
          required: true,
          message: 'Please select network',
        },
      ]}
      {...props}
    >
      <Select
        placeholder="Enter network"
        options={CHAIN_OPTIONS.sort(sortChain)}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export default FormItemSelectChain
