import {
  CreateTeamLeaderBoardDto,
  UpdateCommunityLeaderboardDto,
} from 'services/bountySystem/leaderboard/type'

export const DEFAULT_CREATE_TEAM_LEADERBOARD_DATA: CreateTeamLeaderBoardDto = {
  seasonId: '',
  name: '',
  point: 0,
}

export const DEFAULT_UPDATE_COMMUNITY_LEADERBOARD_DATA: UpdateCommunityLeaderboardDto =
  {
    point: 0,
  }
