import { useState } from 'react'
import { useDebounce } from 'react-use'

import { Button, Col, Input, Modal, Row, Space, Typography } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import UploadCSV from './uploadCsv'

import sample from 'static/files/sample-player-ranks.csv'

type ImportUsersProps = {
  onFilterUids: (value: string[]) => void
}

const ImportUsers = ({ onFilterUids }: ImportUsersProps) => {
  const [open, setOpen] = useState(false)
  const [keyword, setKeyword] = useState('')

  useDebounce(
    () => {
      const filteredKeyword = keyword.replace(/[^a-zA-Z0-9\s,]/g, '')
      const userIds = filteredKeyword.split(/[,\s]+/).filter((id) => id)
      onFilterUids(userIds)
    },
    500,
    [keyword],
  )

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col span={8}>
        <Input
          onChange={(e) => setKeyword(e.target.value)}
          suffix={<IonIcon name="search-outline" />}
          placeholder="userId1 userId2 userId3 ..."
        />
      </Col>

      <Col>
        <Button
          icon={<IonIcon name="download-outline" />}
          ghost
          href={sample}
          target="_blank"
          download
          style={{ marginRight: 10 }}
        >
          Download Template
        </Button>
        <Button type="primary" onClick={() => setOpen(true)}>
          <Space>
            <IonIcon name="add" />
            <Typography.Text strong>Import Players</Typography.Text>
          </Space>
        </Button>
      </Col>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        destroyOnClose={true}
      >
        <UploadCSV onUploadSuccess={() => setOpen(false)} />
      </Modal>
    </Row>
  )
}

export default ImportUsers
