import { MetadataConfigKey, MetadataConfigs } from 'flexibleTemplate/interface'

export type CategoryName = string

export enum NewOrEditCategoryTabs {
  BasicInformation = 'BasicInformation',
  Specification = 'Specification',
  Delivery = 'Delivery',
}

export type CreateCategoryData = {
  title: string
  configs: MetadataConfigs
}

export type AddFieldCategoryItems = 'size' | 'price' | 'gender' | 'color'

export type FieldType = keyof typeof MetadataConfigKey

export enum CardCategoryAction {
  Edit = 'Edit',
  Delete = 'Delete',
}
