import { CSSProperties } from 'react'

import LogIn from 'components/connectWallet'
import UserActionContainer from './userActionContainer'

import { useIsLoggedIn } from 'hooks/user/useIsLoggedIn'

export type UserInfoProps = { loginStyle?: CSSProperties }
const UserInfo = ({ loginStyle }: UserInfoProps) => {
  const isLoggedIn = useIsLoggedIn()

  if (!isLoggedIn) return <LogIn style={loginStyle} />

  return <UserActionContainer />
}

export default UserInfo
