import { useState } from 'react'
import { createGlobalState } from 'react-use'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Row, Tabs, Typography, UploadFile } from 'antd'

import ListBounty from './listBounty'

import useHandle from 'hooks/useHandle'
import { useStorage } from 'hooks/systems/useStorage'
import { useSEO } from 'hooks/seo/useSEO'

import { BountyService, CreateBounty } from 'services/bountySystem/bounty'
import { TaskService } from 'services/bountySystem/task'
import { FederatedApiService } from 'services/bountySystem/federatedApi'

import { DUMMY_BOUNTY_DATA } from 'hooks/bounty/useBountyData'
import { ROUTES } from 'constant/routes'
import { BountyCategory, CreateAndUpdateTabs } from 'constant/bounty'

import {
  CREATE_TASK_KEY,
  TaskConfigs,
  TaskFederatedApiConfigs,
  TaskKeys,
  TaskType,
} from 'templates/types'
import { VerificationMethod } from 'types/task.type'
import { SeoType } from 'types/seo.type'

export type CreateTasksType = {
  type: TaskType
  configs: TaskConfigs
  title: string
  description: string
  key: TaskKeys
  verificationMethod: VerificationMethod
  id: string
  isPublished?: boolean
}

export const useCreateBountyStep = createGlobalState(CreateAndUpdateTabs.SetUp)
export const useCreateBountyTasks = createGlobalState<CreateTasksType[]>([])
export const useDeleteTaskIds = createGlobalState<string[]>([])

export const useBountySetup = createGlobalState<CreateBounty>(DUMMY_BOUNTY_DATA)
export const useFileData = createGlobalState<UploadFile | undefined>()
export const useFileList = createGlobalState<UploadFile[]>([])

const useBountyFormLoading = createGlobalState<boolean>(false)
export const useUpdateBountyForm = () => {
  const [bountyData] = useBountySetup()
  const [tasks] = useCreateBountyTasks()
  const [loading, setLoading] = useBountyFormLoading()
  const [fileList, setFileList] = useFileList()
  const { onUpload } = useStorage(604800)
  const bountyId = useParams().bountyId || ''
  const navigate = useNavigate()

  const { updateSeoInfo } = useSEO(SeoType.Bounty)

  const onCreateTasks = async (bountyId: string, tasks: CreateTasksType[]) => {
    for (const task of tasks) {
      if (task.type === TaskType.FederatedApi) {
        const configs = task.configs as TaskFederatedApiConfigs
        const headers: Record<string, string> = {}
        configs.headers?.map((value: any) => {
          headers[value.key] = value.value
          return null
        })
        const { data } = await FederatedApiService.create({
          ...configs,
          headers,
        })
        task.configs = { federatedApiId: data._id } as any
      }
      await TaskService.create({
        bountyId,
        title: task.title,
        description: task.description,
        configs: task.configs,
        type: task.type,
        thumbnail: '',
        verificationMethod: task.verificationMethod,
      })
    }
  }

  const onBountyUpdate = useHandle(async () => {
    let thumbnail = bountyData.thumbnail
    if (fileList && fileList[0].originFileObj)
      thumbnail = await onUpload(fileList[0])
    const nextBountyData = {
      ...bountyData,
      thumbnail,
      availableOfReward: bountyData.numberOfReward,
    }

    await BountyService.update(bountyId, nextBountyData).then(() => {
      updateSeoInfo()
    })

    const creatingTasks = tasks.filter((task) =>
      task.id.startsWith(CREATE_TASK_KEY),
    )

    if (creatingTasks.length > 0) {
      await onCreateTasks(bountyId, creatingTasks)
    }

    const updatingTasks = tasks.filter(
      (task) => !task.id.startsWith(CREATE_TASK_KEY),
    )

    await Promise.all(
      updatingTasks.map(async (task) => {
        if (task.type === TaskType.FederatedApi && task.configs) {
          const configs = task.configs as TaskFederatedApiConfigs
          const headers: Record<string, string> = {}
          configs.headers?.map((value: any) => {
            headers[value.key] = value.value
            return null
          })
          await FederatedApiService.update(configs.federatedApiId || '', {
            pattern: configs.pattern,
            headers,
          })
        }
        return TaskService.update(task.id, task)
      }),
    )
    window.notify({
      type: 'success',
      description: 'Updated bounty successfully',
    })
    setFileList([])
    navigate(ROUTES.BOUNTY.INDEX)
  }, setLoading)

  return { onBountyUpdate, formLoading: loading }
}

const { DApp, Discord } = BountyCategory
const TAB_ITEMS = [
  { label: 'System', key: DApp },
  { label: 'Discord', key: Discord },
]

const BountyManagement = () => {
  const [category, setCategory] = useState(DApp)

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Mission Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs
          items={TAB_ITEMS}
          onChange={(e) => setCategory(e as BountyCategory)}
        />
      </Col>
      <Col span={24}>
        <ListBounty category={category} />
      </Col>
    </Row>
  )
}

export default BountyManagement
