import { useQuery, useQueryClient } from 'react-query'

import { WhitelistService } from 'services/marketplace/whitelist'

import { FindAllWhitelistDto } from 'types/whitelist.type'

const ROOT_KEY = 'GET_WHITELIST'
export const useWhitelist = (dto: FindAllWhitelistDto) => {
  const { data, ...rest } = useQuery({
    queryKey: [ROOT_KEY, dto],
    queryFn: () => WhitelistService.getAll(dto),
  })

  const queryClient = useQueryClient()
  const refetchRoot = () =>
    queryClient.refetchQueries({
      queryKey: [ROOT_KEY],
    })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
    refetchRoot,
  }
}
