import { useQuery } from 'react-query'

import { TeamLeaderboardData } from 'types/leaderboard.type'
import { LeaderboardService } from 'services/bountySystem/leaderboard/leaderboard'

export const useTeamLeaderboard = (teamId: string) => {
  return useQuery<TeamLeaderboardData>(
    `GET_ONE_TEAM_LEADERBOARD_DATA:${teamId}`,
    () => LeaderboardService.findOneTeam(teamId),
  )
}
