import moment from 'moment'

import { Typography } from 'antd'
import RowAction from './RowAction'

import { TIME_FORMAT } from 'constant'

import { IAcceler8Season } from 'types/acceler8/acceler8-season.type'
import { ColumnProps } from 'antd/es/table'

export const COLUMN_PLAYER_SEASON: ColumnProps<IAcceler8Season>[] = [
  {
    title: 'Season',
    dataIndex: '',
    render: ({ title, subTitle }: IAcceler8Season) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {title + ': ' + subTitle}
      </Typography.Paragraph>
    ),
  },

  {
    title: 'Total Rewards',
    dataIndex: 'totalRewards',
    render: (totalRewards) => (
      <Typography.Text>${totalRewards}</Typography.Text>
    ),
  },
  {
    title: 'Total Points',
    dataIndex: 'totalPoints',
    render: (totalPoints) => (
      <Typography.Text>{totalPoints ?? '--'}</Typography.Text>
    ),
  },
  {
    title: 'Active and Expire dates',
    dataIndex: '',
    render: ({ startedAt, endedAt }) => (
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>
        {moment.utc(startedAt).format(TIME_FORMAT)} (UTC) -{' '}
        {moment.utc(endedAt).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: '',
    render: (seasonData) => <RowAction seasonData={seasonData} />,
  },
]
