import { useQueries } from 'react-query'

import { GachaService } from 'services/marketplace/gacha'

import { CountGachaTicketType } from 'types/gacha.type'

export const useCountGachaTicket = (gachaIds: string[]) => {
  return useQueries([
    {
      queryKey: ['get-total-ticket', gachaIds],
      queryFn: () =>
        GachaService.countGachaTicket({
          gachaIds,
          type: CountGachaTicketType.All,
        }),
      enabled: !!gachaIds.length,
    },
    {
      queryKey: ['get-total-spun', gachaIds],
      queryFn: () =>
        GachaService.countGachaTicket({
          gachaIds,
          type: CountGachaTicketType.Spun,
        }),
      enabled: !!gachaIds.length,
    },
  ])
}
