import { marketplaceApi as api } from 'services/base-axios'

const CONTROLLER = '/mint-nft'

class MintNFT {
  async mint1155Nft(nft: Mint1155NFT): Promise<MintNFTData> {
    return await api.post(CONTROLLER + '/bsc-1155', nft).then((res) => res.data)
  }

  async mint1155A8Nft(nft: Mint1155NFT): Promise<MintNFTData> {
    return await api.post(CONTROLLER + '/a8-1155', nft).then((res) => res.data)
  }
}

export const MintNFTService = new MintNFT()

export type MintNFTData = {
  transactionHash: string
}

export type Mint721NFT = {
  title: string
  tokenAddress?: string
  mintTo?: string
  description: string
  thumbnail: string
}

export type Mint1155NFT = {
  title: string
  tokenAddress?: string
  mintTo?: string
  supply: number
  description: string
  thumbnail: string
}
