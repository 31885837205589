import { useCallback, useEffect, useState } from 'react'

import { UserService } from 'services/ancient8/user'
import { ChainID } from 'constant'

export const useWalletAddress = (chain: ChainID) => {
  const [walletAddress, setWalletAddress] = useState('')

  const getWalletAddress = useCallback(async () => {
    try {
      const walletAddress = await UserService.getWalletAddress(chain)
      setWalletAddress(walletAddress)
    } catch (err) {
      setWalletAddress('')
    }
  }, [chain])

  useEffect(() => {
    getWalletAddress()
  }, [getWalletAddress])

  return walletAddress
}
