import { useEffect, useState } from 'react'
import { createGlobalState } from 'react-use'

import { Button, Col, Input, Row } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import Filter from 'view/admin/marketplace/collectionManagement/controlBar/filter'
import { ChainID } from 'constant'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'constant/routes'

export const useSearchCollections = createGlobalState('')
export const useChainCollections = createGlobalState(ChainID.BSC)

const CollectionControlBar = () => {
  const [keyword, setKeyword] = useState('')
  const [search, setSearch] = useSearchCollections()
  const [chain, setChain] = useChainCollections()
  const navigate = useNavigate()

  useEffect(() => {
    const timeoutId = setTimeout(() => setSearch(keyword), 500)
    return () => clearTimeout(timeoutId)
  }, [keyword, setSearch])

  return (
    <Row>
      <Col flex={5}>
        <Row justify="start">
          <Input
            suffix={
              <Button
                onClick={() => setKeyword('')}
                type="text"
                style={{ width: 'auto', height: 'auto', padding: 0 }}
                icon={
                  <IonIcon
                    style={{
                      cursor: 'pointer',
                      fontSize: 18,
                      color: '#42BAB0',
                    }}
                    name={search ? 'close-outline' : 'search-outline'}
                  />
                }
              />
            }
            placeholder="Search Badge Collection"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            style={{ width: 244, height: 40, borderRadius: 4 }}
          />
        </Row>
      </Col>
      <Col flex={1}>
        <Row justify="end">
          <Filter chain={chain} onChange={setChain} />
        </Row>
      </Col>
      <Col flex={1}>
        <Row justify="end">
          <Button
            className="admin-primary-btn"
            icon={<IonIcon name="add-outline" />}
            type="primary"
            onClick={() => {
              navigate(ROUTES.REWARDS_CENTER.NEW_COLLECTION_MANAGEMENT)
            }}
          >
            Add Collection
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default CollectionControlBar
