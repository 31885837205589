import { useDispatch } from 'react-redux'

import { Avatar, Button, Col, Dropdown, Row, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import SelectChain from 'components/systems/selectChain/selectChain'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import { useChainId } from 'hooks/useChainId'
import { useUserProfile } from 'hooks/user/useUserProfile'
import { shortenAddress } from 'helper'
import { DROPDOWN_MENUS } from './userActionContainer'
import { onSwitchChainNet } from 'store/main.reducer'

type UserActionProps = { onClick?: (e: any) => void }
const UserAction = ({ onClick }: UserActionProps) => {
  const dispatch = useDispatch()
  const chainId = useChainId()
  const { avatar, userName, _id } = useUserProfile()

  return (
    <SelectChainContext.Provider
      value={{
        selectedChain: chainId,
        setSelectedChain: (chain) => {
          dispatch(onSwitchChainNet(chain))
        },
      }}
    >
      <Row gutter={[24, 24]} align="middle">
        <Col hidden={true}>
          <SelectChain />
        </Col>
        <Col>
          <Dropdown.Button
            type="text"
            className="dropdown-btn pad-less"
            style={{
              width: 'auto',
              height: '100%',
              cursor: 'pointer',
              background: 'transparent',
            }}
            trigger={['click']}
            menu={{ items: DROPDOWN_MENUS, onClick }}
            icon={
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  width: 'auto',
                  height: 'auto',
                }}
              >
                <Space size={12} className="space-middle-icon">
                  <Avatar src={avatar} size={24} />
                  <Typography.Text style={{ fontSize: 14, fontWeight: 700 }}>
                    {userName || shortenAddress(_id)}
                  </Typography.Text>
                  <IonIcon
                    style={{ fontSize: 18, fontWeight: 700 }}
                    name="chevron-down-outline"
                  />
                </Space>
              </Button>
            }
          />
        </Col>
      </Row>
    </SelectChainContext.Provider>
  )
}

export default UserAction
