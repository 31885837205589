import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import { Col, Row, Select } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'

import { CreateBounty } from 'services/bountySystem/bounty'

import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import useCampaigns from 'hooks/campaign/useCampaigns'
import { useBountySetup } from 'view/bounty/management/index'

import { ValueOf } from 'constant'

type SelectType = {
  value: string
  label: string
}

const MissionCampaign = () => {
  const [keyword, setKeyword] = useState('')
  const [search, setSearch] = useState('')
  const [community] = useCurrentCommunity()
  const [options, setOptions] = useState<SelectType[]>([])
  const [bountyData, setBountyData] = useBountySetup()
  const { data, isLoading } = useCampaigns({
    search,
    communityId: community?._id,
  })

  useDebounce(() => setSearch(keyword), 500, [keyword])

  const onChange = (name: keyof CreateBounty, value: ValueOf<CreateBounty>) => {
    setBountyData({ ...bountyData, [name]: value })
  }

  useEffect(() => {
    const campaigns = data?.data ?? []
    const newOptions: SelectType[] = campaigns.map((campaign) => ({
      value: campaign._id,
      label: campaign.title,
    }))
    setOptions(newOptions)
  }, [data?.data])

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <RequiredLabel required={false}>Campaign</RequiredLabel>
      </Col>
      <Col span={24}>
        <Select
          options={options}
          value={bountyData?.campaignId}
          showSearch={true}
          style={{ width: '100%' }}
          placeholder="Search campaign..."
          onChange={(value) => {
            onChange('campaignId', value)
          }}
          onSearch={(val) => setKeyword(val)}
          loading={isLoading}
          filterOption={(input, option) => {
            return (option?.label ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }}
        />
      </Col>
    </Row>
  )
}

export default MissionCampaign
