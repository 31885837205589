import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Form, Row, Typography } from 'antd'

import FormGacha from './form-gacha'

import { useGachaDetail } from 'hooks/gacha/useGachaDetail'

function CreateUpdateGacha() {
  const [form] = Form.useForm()
  const gachaId = useParams()?.gachaId ?? ''
  const isEdit = !!gachaId

  const { data, isLoading } = useGachaDetail(gachaId)

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, form])

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={1} type="success">
          {isEdit ? 'Edit Gacha' : 'Create New Gacha'}
        </Typography.Title>
      </Col>

      <Col span={24}>
        <FormGacha form={form} isEdit={isEdit} isFetching={isLoading} />
      </Col>
    </Row>
  )
}

export default CreateUpdateGacha
