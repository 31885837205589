import { useMutation } from 'react-query'

import { LeaderboardService } from 'services/bountySystem/leaderboard/leaderboard'

export const useCommunitiesLeaderboardMutation = ({
  communityIds,
  seasonId,
}: {
  communityIds: string[]
  seasonId: string
}) => {
  const params = { communityIds, seasonId }
  return useMutation({
    mutationKey: ['CREATE_COMMUNITY_LEADERBOARD_DATA', JSON.stringify(params)],
    mutationFn: () =>
      LeaderboardService.createCommunityLeaderboard(communityIds, seasonId),
  })
}
