import { useContext, useState } from 'react'

import { Button, Col, Empty, Row, Typography } from 'antd'
import { PlusSquareOutlined as AddSquareIcon } from '@ant-design/icons'

import { WhitelistModalContext } from './CreateUpdateContext'
import { WrapLoading } from 'components/systems/loading'
import CardWhitelist from './CardWhitelist'
import SearchBar from 'components/systems/searchBar'
import SpaceBetween from 'components/systems/spaceBetween'
import CustomPagination from 'components/systems/pagination'

import { useWhitelist } from 'hooks/whitelist/useWhitelist'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import './index.less'

function WhitelistManagement() {
  const { openModal } = useContext(WhitelistModalContext)

  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)

  const {
    data: whitelist,
    total,
    isLoading,
  } = useWhitelist({
    search,
    limit: DEFAULT_PAGE_SIZE,
    offset: (currentPage - 1) * DEFAULT_PAGE_SIZE,
  })

  return (
    <Row gutter={[0, 24]} className="whitelist-container">
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Whitelist Management
        </Typography.Title>
      </Col>

      <Col span={24} style={{ width: '100%' }}>
        <Row wrap={false} gutter={10}>
          <Col flex={1}>
            <SearchBar
              setSearch={setSearch}
              placeholder="Search whitelist name..."
              style={{ width: '100%' }}
            />
          </Col>

          <Col style={{ width: 180 }}>
            <Button
              icon={<AddSquareIcon />}
              type="primary"
              block
              onClick={() => openModal(false)}
            >
              Create Whitelist
            </Button>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick" size={40}>
          {!!whitelist.length && (
            <Row gutter={[12, 12]}>
              {whitelist.map((item) => (
                <Col xs={24} sm={12} md={8} lg={6} xl={4} key={item._id}>
                  <CardWhitelist whitelistData={item} />
                </Col>
              ))}

              <Col span={24}>
                <SpaceBetween
                  floatContent={
                    <CustomPagination
                      pageSize={DEFAULT_PAGE_SIZE}
                      page={currentPage}
                      total={total}
                      onChange={setCurrentPage}
                    />
                  }
                >
                  <Typography.Text type="success">
                    Total: {total}
                  </Typography.Text>
                </SpaceBetween>
              </Col>
            </Row>
          )}

          {!whitelist.length && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Badge"
            />
          )}
        </WrapLoading>
      </Col>
    </Row>
  )
}

export default WhitelistManagement
