import { CSSProperties, Fragment, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useBounties } from 'hooks/bounty/useBounties'
import { useTasks } from 'hooks/task/useTasks'
import { Button, Space, Typography } from 'antd'

type BreadcrumbType = { key: string; label: string }
type BreadcrumbProps = { clickable?: boolean; style?: CSSProperties }

const Breadcrumb = ({ clickable = false, style }: BreadcrumbProps) => {
  const { pathname } = useLocation()
  const { bounties } = useBounties({})
  const tasks = useTasks()
  const navigate = useNavigate()

  const convertPathLabel = useCallback((path: string) => {
    if (!path) return ''
    return path.replaceAll('-', ' ')
  }, [])

  const parsePathKey = useCallback(
    (path: string) => {
      if (!pathname || !path) return ''
      return pathname.substring(0, pathname.indexOf(path) + path.length)
    },
    [pathname],
  )

  const defineDataByPath = useCallback(
    (path: string) => {
      const bountyData = bounties[path]
      const taskData = tasks[path]

      if (bountyData) return bountyData.title
      if (taskData) return taskData.title
      return convertPathLabel(path)
    },
    [bounties, convertPathLabel, tasks],
  )

  const mapPathName = useMemo(() => {
    const keys = pathname.split('/')
    const nextMapKeys: BreadcrumbType[] = []
    for (const key of keys) {
      if (key)
        nextMapKeys.push({
          key: parsePathKey(key),
          label: defineDataByPath(key),
        })
    }
    return nextMapKeys
  }, [defineDataByPath, parsePathKey, pathname])

  if (mapPathName.length < 2) return <Fragment />

  return (
    <Space style={style} wrap>
      {mapPathName.map(({ key, label }, idx) => {
        const btnActive = mapPathName.length === idx + 1
        return (
          <Space key={key}>
            <Button
              type="link"
              style={{
                textTransform: 'capitalize',
                color: btnActive ? '#42bab0' : '#4D5A66',
                cursor: btnActive ? 'pointer' : 'default',
                padding: 0,
                height: 'auto',
              }}
              onClick={() => clickable && navigate(key)}
              disabled={key.endsWith('admin')}
            >
              {label}
            </Button>

            {!btnActive && <Typography.Text type="success">/</Typography.Text>}
          </Space>
        )
      })}
    </Space>
  )
}

export default Breadcrumb
