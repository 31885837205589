import env from './env'
import api from './api.config'
import user from './user.config'
import walletSol from './walletSol.config'
import walletEVM from './walletEvm.config'
import community from './community.config'
import blockscout from './blockscout.config'

const configs = {
  api: api[env],
  user: user[env],
  walletSol: walletSol[env],
  walletEVM: walletEVM[env],
  community: community[env],
  blockscout: blockscout[env],
}

export default configs
