import { useCallback, useEffect, useState } from 'react'

import { Card, Row, Col, Typography } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'

import { orderService } from 'services/marketplace/order'
import { WrapLoading } from 'components/systems/loading'

type TotalOrder = {
  totalDone: number
  totalFail: number
  totalPreparing: number
  totalSending: number
}

const DEFAULT_TOTAL_ORDER: TotalOrder = {
  totalDone: 0,
  totalFail: 0,
  totalPreparing: 0,
  totalSending: 0,
}

const Header = () => {
  const [total, setTotal] = useState(DEFAULT_TOTAL_ORDER)
  const [loading, setLoading] = useState(false)

  const fetchTotalOrder = useCallback(async () => {
    try {
      setLoading(true)
      const data = await orderService.getTotal()
      setTotal(data)
    } catch (error) {
      setTotal(DEFAULT_TOTAL_ORDER)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchTotalOrder()
  }, [fetchTotalOrder])

  return (
    <WrapLoading loading={loading} type="stick">
      <Card
        bordered={false}
        bodyStyle={{ padding: '12px 24px' }}
        style={{ background: '#0F191B', borderRadius: 4 }}
      >
        <Row className="shipping-statistic">
          <Col xs={24} sm={12} md={6}>
            <SpaceVertical align="center" block={false}>
              <Typography.Text type="secondary">To Ship</Typography.Text>
              <Typography.Title type="success" level={2}>
                {total.totalPreparing}
              </Typography.Title>
            </SpaceVertical>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <SpaceVertical align="center" block={false}>
              <Typography.Text type="secondary">Shipping</Typography.Text>
              <Typography.Title type="success" level={2}>
                {total.totalSending}
              </Typography.Title>
            </SpaceVertical>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <SpaceVertical align="center" block={false}>
              <Typography.Text type="secondary">Canceled</Typography.Text>
              <Typography.Title type="success" level={2}>
                {total.totalFail}
              </Typography.Title>
            </SpaceVertical>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <SpaceVertical align="center" block={false}>
              <Typography.Text type="secondary">Completed</Typography.Text>
              <Typography.Title type="success" level={2}>
                {total.totalDone}
              </Typography.Title>
            </SpaceVertical>
          </Col>
        </Row>
      </Card>
    </WrapLoading>
  )
}

export default Header
