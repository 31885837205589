import { Image, Typography } from 'antd'

import AdsAction from './Action'

import { ellipsisText } from 'helper'

import { AdsData } from 'types/ads.type'
import { ColumnType } from 'antd/lib/table'

export const COLUMN_ADS: ColumnType<AdsData>[] = [
  {
    title: null,
    dataIndex: 'image',
    align: 'center',
    render: (image) => <Image src={image} alt="ads image" />,
    width: 140,
  },
  {
    title: '',
    dataIndex: 'url',
    render: (url: string) => {
      return (
        <Typography.Link target="_blank" href={url} type="success">
          {ellipsisText(url, 50)}
        </Typography.Link>
      )
    },
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    render: (priority: string) => {
      return <Typography.Text>{priority}</Typography.Text>
    },
  },
  {
    title: 'Action',
    dataIndex: '',
    align: 'center',
    render: (_, record) => <AdsAction adsDetails={record} />,
    width: 100,
  },
]
