import {
  Button,
  Checkbox,
  Col,
  Divider,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd'

import { useAcceler8SubscriptionTabSelected } from '../index'

import { SortFilterProps } from '../listSubscriptionOverall/index'

import { Acceler8SubscriptionTab } from 'constant/acceler8'
import {
  Acceler8PackageIds,
  Acceler8SortOptions,
  Acceler8StatusLabel,
  Acceler8SubscriptionFilter,
  Acceler8SubscriptionStatus,
} from 'types/acceler8/acceler8-subscription.type'

const FILTER_PACKAGE_OPTIONS = [
  {
    label: Acceler8StatusLabel[Acceler8PackageIds.Premium],
    value: Acceler8PackageIds.Premium,
  },
  {
    label: Acceler8StatusLabel[Acceler8PackageIds.Exclusive],
    value: Acceler8PackageIds.Exclusive,
  },
  {
    label: Acceler8StatusLabel[Acceler8PackageIds.AdminActivates],
    value: Acceler8PackageIds.AdminActivates,
  },
]

const FILTER_STATUS_OPTIONS = [
  {
    label: Acceler8StatusLabel[Acceler8SubscriptionStatus.Inactive],
    value: Acceler8SubscriptionStatus.Inactive,
  },
]

const SORT_OPTIONS = [
  {
    label: 'Recently activated',
    value: Acceler8SortOptions.RecentlyActivated,
  },
  {
    label: 'Going to be expired',
    value: Acceler8SortOptions.GoingToBeExpired,
  },
  {
    label: 'Total money spent (decrease)',
    value: Acceler8SortOptions.TotalMoneySpentDecrease,
  },
  {
    label: 'Total money spent (increase)',
    value: Acceler8SortOptions.TotalMoneySpentIncrease,
  },
]

type FilterDropdownProps = {
  sortFilter: SortFilterProps
  handleSortFilter: (
    key: string,
    value: string | Acceler8SubscriptionFilter,
  ) => void
}

const FilterDropdown = ({
  sortFilter,
  handleSortFilter,
}: FilterDropdownProps) => {
  const [currentTab] = useAcceler8SubscriptionTabSelected()

  const title =
    currentTab === Acceler8SubscriptionTab.Overall ? 'Sort & Filter' : 'Filter'

  return (
    <Row gutter={[0, 24]} onClick={(e) => e.stopPropagation()}>
      <Col span={24}>
        <Typography.Title level={4} type="success">
          {title}
        </Typography.Title>
        <Divider />
      </Col>

      {currentTab === Acceler8SubscriptionTab.Overall && (
        <Col span={24}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Typography.Text style={{ color: '#AFB5C2' }}>
                Sort
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Radio.Group
                onChange={(e) => handleSortFilter('sortOption', e.target.value)}
                defaultValue={Acceler8SortOptions.RecentlyActivated}
              >
                <Space direction="vertical" size={16}>
                  {SORT_OPTIONS.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Typography.Text style={{ color: '#AFB5C2' }}>
              Filter
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              onChange={(checkedValue) => {
                const newFilterOptions: Acceler8SubscriptionFilter = {
                  ...sortFilter.filterOptions,
                  packageIds: checkedValue.map((val) => Number(val)),
                }
                handleSortFilter('filterOptions', newFilterOptions)
              }}
              value={sortFilter.filterOptions.packageIds}
            >
              <Space direction="vertical" size={16}>
                {FILTER_PACKAGE_OPTIONS.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    {option.label}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Col>
          <Col>
            <Checkbox.Group
              onChange={(checkedValue) => {
                const newFilterOptions: Acceler8SubscriptionFilter = {
                  ...sortFilter.filterOptions,
                  statuses: checkedValue as Acceler8SubscriptionStatus[],
                }
                handleSortFilter('filterOptions', newFilterOptions)
              }}
              value={sortFilter.filterOptions.statuses}
            >
              <Space direction="vertical" size={16}>
                {FILTER_STATUS_OPTIONS.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    {option.label}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Button className="btn-reset">Reset Filter</Button>
      </Col>
    </Row>
  )
}

export default FilterDropdown
