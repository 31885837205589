import { CSVLink } from 'react-csv'

import { Button, Col, Row, Typography } from 'antd'
import DateRange from 'components/systems/dateRange'
import { TableHistoryReportData } from '.'
import { useMemo } from 'react'
import moment from 'moment'
import { useParseRecurrenceTime } from 'hooks/bounty/useParseRecurrenceTime'
import { BountyCertState } from 'services/bountySystem/bounty'
import { BountyCategory, TASK_NOT_COMPLETED } from 'constant/bounty'

const CSV_HEADER = [
  { label: 'UID', key: 'uid' },
  { label: 'Repeat', key: 'repeat' },
  { label: 'Bounty Name', key: 'name' },
  { label: 'Type', key: 'type' },
  { label: 'Status', key: 'status' },
  { label: 'Rewards', key: 'rewards' },
  { label: 'Time (UTC)', key: 'completedAt' },
]
const FILE_REPORT_NAME = `summary-report-${new Date().toISOString()}.csv`

type ReportInfoHeaderProps = {
  startDate?: string
  endDate?: string
  setEndDate: (endDate: string) => void
  setStartDate: (startDate: string) => void
  historyReport: TableHistoryReportData[]
}

const ReportInfoHeader = ({
  startDate = '',
  endDate = '',
  setEndDate,
  setStartDate,
  historyReport,
}: ReportInfoHeaderProps) => {
  const parseRecurrenceTime = useParseRecurrenceTime()

  const exportFile = useMemo(() => {
    if (!historyReport.length) return []
    const result = []
    for (const report of historyReport) {
      const { uid, bountyData, state, completedAt } = report

      const status =
        moment.utc(bountyData.endedAt).isBefore(moment.utc()) &&
        state === BountyCertState.Processing
          ? TASK_NOT_COMPLETED
          : state
      const rewards = `${bountyData.rewardCCP} CCP, ${bountyData.rewardBadges.length} Badge`
      const type =
        bountyData.category === BountyCategory.DApp ? 'System' : 'Discord'

      result.push({
        uid,
        type,
        status,
        rewards,
        completedAt,
        name: bountyData.title,
        repeat: parseRecurrenceTime(bountyData.recurrenceTime),
      })
    }
    return result
  }, [historyReport, parseRecurrenceTime])

  return (
    <Row gutter={[24, 24]} align="middle">
      <Col xs={24} xl={12}>
        <Typography.Title level={2} type="success">
          Summary Report
        </Typography.Title>
      </Col>
      <Col xs={24} xl={12}>
        <Row gutter={[12, 12]} wrap={false}>
          <Col flex="auto">
            <DateRange
              visibleLabel={false}
              startAt={startDate}
              endAt={endDate}
              onChangeEndAt={setEndDate}
              onChangeStartAt={setStartDate}
            />
          </Col>
          <Col>
            <CSVLink
              filename={FILE_REPORT_NAME}
              headers={CSV_HEADER}
              data={exportFile}
            >
              <Button ghost>Export</Button>
            </CSVLink>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ReportInfoHeader
