import { Col, Form, Input, Row, Select, Typography } from 'antd'
import { Fragment } from 'react'

import { AttributeComponent } from 'types/product-category.type'
import { AttributeConfig } from 'services/marketplace/product-category.type'

const FormAttributeSelect = ({
  attributes,
  value,
  onChange,
}: {
  attributes: AttributeConfig[]
  value: Record<string, string>
  onChange: (value: Record<string, string>) => void
}) => {
  const [form] = Form.useForm()

  const handleChangeForm = (changedFields: any) => {
    const fieldData: { name: string[]; value: string } = changedFields[0]
    if (!fieldData) return
    onChange({ ...value, [fieldData.name[0]]: fieldData.value })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      initialValues={value}
      onFieldsChange={handleChangeForm}
    >
      <Row gutter={[32, 32]} style={{ maxWidth: 486 }}>
        <Col span={24}>
          {attributes.map((attribute) => {
            return (
              <Fragment key={attribute.title}>
                {/* Select component */}
                {attribute.type === AttributeComponent.Select && (
                  <Form.Item
                    name={attribute.title}
                    required={attribute.required}
                    label={
                      <Typography.Text type="secondary">
                        {attribute.title}
                      </Typography.Text>
                    }
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={attribute.placeholder}
                    >
                      {attribute.options.map((option) => (
                        <Select.Option value={option} key={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {/* Input Component */}
                {attribute.type === AttributeComponent.Input && (
                  <Form.Item
                    name={attribute.title}
                    required={attribute.required}
                    label={
                      <Typography.Text type="secondary">
                        {attribute.title}
                      </Typography.Text>
                    }
                  >
                    <Input placeholder={attribute.placeholder} />
                  </Form.Item>
                )}
              </Fragment>
            )
          })}
        </Col>
      </Row>
    </Form>
  )
}

export default FormAttributeSelect
