import { bountySystemApi as api } from 'services/base-axios'

import { DataList } from 'types'

import {
  CommunityLeaderboardData,
  CommunityLeaderboardExtended,
  ITeamLeaderboard,
  TeamLeaderboardData,
} from 'types/leaderboard.type'
import {
  CreateTeamLeaderBoardDto,
  FindCommunityLeaderboardDto,
  FindTeamLeaderBoardDto,
  UpdateCommunityLeaderboardDto,
  UpdateTeamLeaderBoardDto,
} from './type'

class Leaderboard {
  private index = '/leaderboard'

  async findTeams(params: FindTeamLeaderBoardDto) {
    const { data } = await api.get<DataList<ITeamLeaderboard[]>>(
      `${this.index}/team`,
      { params },
    )
    return data
  }

  async findOneTeam(teamId: string) {
    const { data } = await api.get<TeamLeaderboardData>(
      `${this.index}/team/${teamId}`,
    )
    return data
  }

  async updateTeamLeaderboard(teamId: string, data: UpdateTeamLeaderBoardDto) {
    return api.put(`${this.index}/team/${teamId}`, data)
  }

  async removeTeamLeaderboard(teamId: string) {
    return api.delete(`${this.index}/team/${teamId}`)
  }

  async createTeamLeaderboard(data: CreateTeamLeaderBoardDto) {
    return api.post(`${this.index}/team`, data)
  }

  async getCommunities(params: FindCommunityLeaderboardDto) {
    const { data } = await api.get<CommunityLeaderboardData[]>(
      `${this.index}/community`,
      {
        params,
      },
    )
    return data
  }

  async createCommunityLeaderboard(communityIds: string[], seasonId: string) {
    const { data } = await api.post<CommunityLeaderboardData[]>(
      `${this.index}/community`,
      {
        communityIds,
        seasonId,
      },
    )
    return data
  }

  async getCommunityDetails(communityId: string) {
    const { data } = await api.get<CommunityLeaderboardExtended>(
      `${this.index}/community/${communityId}`,
    )
    return data
  }

  async updateCommunityLeaderboard(
    id: string,
    { point }: UpdateCommunityLeaderboardDto,
  ) {
    const { data } = await api.put(`${this.index}/community/${id}`, {
      point,
    })
    return data
  }
}

export const LeaderboardService = new Leaderboard()
