import { useState } from 'react'

import { Checkbox, Col, Form, Input, Radio, Row, Typography } from 'antd'
import ProductAttributes from 'components/product/productAttributes'
import AttributeSettingLayout from './attributeLayout'

import { AttributeConfig } from 'services/marketplace/product-category.type'
import { AttributeComponent } from 'types/product-category.type'

export const DEFAULT_ATTRIBUTE_SETTING: AttributeConfig = {
  title: 'Title Default',
  options: ['Option 1', 'Option 2'],
  type: AttributeComponent.Select,
  placeholder: `Select Title Default`,
  hint: `Please Select Title Default`,
  required: true,
}

const AttributeSetting = ({
  value,
  onChange,
  onRemove,
}: {
  value: AttributeConfig
  onChange: (data: AttributeConfig) => void
  onRemove?: () => void
}) => {
  const [options, setOptions] = useState<string[]>(value.options)
  const [form] = Form.useForm()
  const type = Form.useWatch('type', form)

  const handleChangeForm = (changedFields: any) => {
    const fieldData: { name: string[]; value: string } = changedFields[0]
    if (!fieldData) return
    onChange({ ...value, [fieldData.name[0]]: fieldData.value })
  }

  const handleChangeOption = (options: string[]) => {
    setOptions(options)
    onChange({ ...value, options })
  }

  const handleChangeRequired = (required: boolean) =>
    onChange({ ...value, required })

  return (
    <AttributeSettingLayout title={value.title} onRemove={onRemove}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={value}
        onFieldsChange={handleChangeForm}
      >
        <Row>
          <Col span={24} style={{ maxWidth: 486 }}>
            <Form.Item
              name="type"
              label={<Typography.Text type="secondary">Type</Typography.Text>}
            >
              <Radio.Group>
                <Radio value={AttributeComponent.Select}>Select</Radio>
                <Radio value={AttributeComponent.Input}>Enter Text</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="title"
              required
              label={<Typography.Text type="secondary">Label</Typography.Text>}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="placeholder"
              required
              label={
                <Typography.Text type="secondary">Placeholder</Typography.Text>
              }
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="hint"
              label={<Typography.Text type="secondary">Hint</Typography.Text>}
            >
              <Input />
            </Form.Item>

            <Form.Item name="required">
              <Checkbox
                defaultChecked={value.required}
                onChange={(e) => handleChangeRequired(e.target.checked)}
              >
                <Typography.Text>Mandatory</Typography.Text>
              </Checkbox>
            </Form.Item>

            {type === AttributeComponent.Select && (
              <Form.Item
                label={
                  <Typography.Text type="secondary">
                    Information Select
                  </Typography.Text>
                }
                required
              >
                <ProductAttributes
                  options={[]}
                  value={options}
                  onChange={handleChangeOption}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </AttributeSettingLayout>
  )
}

export default AttributeSetting
