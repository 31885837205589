import axios from 'axios'

import configs from 'configs'
import { getAccessToken } from 'services/utils'

const token = getAccessToken()

const axiosConfig = {
  headers: {
    Authorization: !token ? '' : `Bearer ${token}`,
  },
  timeout: 30000,
  baseURL: configs.api.acceler8TaskApi,
  paramsSerializer: {
    indexes: null,
  },
}

export const acceler8TaskApi = axios.create(axiosConfig)
