import moment from 'moment'

import { Avatar, Card, Col, Row, Space, Tooltip, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import RewardAvatarItem from 'components/systems/rewardAvatar/avatar'
import SettingReward from '../settingReward'

import useCheckIn from '../../useCheckIn'
import { useBadgeDetails } from 'hooks/badges/useBadges'

import { RewardType } from 'constant/reward'

import noImage from 'static/images/system/nodata.svg'

import { IStreakReward } from 'types/check-in.type'

const StreakReward = ({
  reward,
  title,
  fromDate,
  toDate,
}: IStreakReward & { title: string }) => {
  const { isClaimed, canClaim } = useCheckIn(fromDate, toDate, reward?._id)
  const { data: badgeData } = useBadgeDetails(
    reward?.rewardBadge?.badgeId || '',
  )

  const isFinish = moment.utc(toDate).isBefore(moment.utc().startOf('day'))

  return (
    <SettingReward fromDate={fromDate} toDate={toDate} reward={reward}>
      <Card
        bordered={false}
        bodyStyle={{
          padding: '4px 16px',
          background: isClaimed ? '#42BAB0' : '#091112',
          filter: isFinish && !isClaimed ? 'brightness(40%)' : undefined,
        }}
        className={
          !isClaimed
            ? `card-streak ${
                canClaim && reward?._id ? 'card-border-animation' : ''
              }`
            : ' card-streak card-streak-active'
        }
      >
        <Row align="middle">
          <Col flex="auto">
            <Space size={4}>
              <Avatar
                size={24}
                style={{ background: isClaimed ? '#004842' : '#1B2526' }}
              >
                <IonIcon
                  name="checkmark-outline"
                  style={{
                    color: isClaimed ? '#42BAB0' : '#091112',
                    fontWeight: 700,
                  }}
                />
              </Avatar>

              <Typography.Text
                style={{
                  fontWeight: 700,
                  color: isClaimed ? '#0F191B' : '#42BAB0',
                }}
              >
                {title}
              </Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space size={4}>
              {!!reward?.rewardCCP && (
                <Tooltip title={reward.rewardCCP + ' CCP'}>
                  <RewardAvatarItem size={40} rewardType={RewardType.CCP} />
                </Tooltip>
              )}
              {!!badgeData && <Avatar src={badgeData.thumbnail} size={40} />}
              {!badgeData && !reward?.rewardCCP && (
                <Avatar src={noImage} size={40} />
              )}
            </Space>
          </Col>
        </Row>
      </Card>
    </SettingReward>
  )
}

export default StreakReward
