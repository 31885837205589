import { transformUndefinedInput } from 'helper/common'
import React from 'react'

import { Button, Col, Form, Input, Row, Select, Typography } from 'antd'

import {
  MinusCircleOutlined as MinusCircleIcon,
  PlusCircleOutlined as PlusCircleIcon,
} from '@ant-design/icons'

import RequiredLabel from 'components/systems/requiredSymbol'

import { COMMUNITY_PROFILE_DEFAULT_SECTION } from 'constant/community-profile'

import { LinkedMedia } from 'types/community-profile'

function FormItemFeaturedContent() {
  return (
    <Form.List name="featuredContents">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Row
              align="top"
              justify="end"
              gutter={8}
              style={{ position: 'relative' }}
            >
              <Col span={4} style={{ marginTop: 10, height: '100%' }}>
                {index === 0 && <RequiredLabel children="Featured Content" />}
              </Col>

              <Col span={19}>
                <Row justify="center">
                  <Typography.Text type="warning" style={{ marginBottom: 12 }}>
                    Featured content {index + 1}
                  </Typography.Text>
                </Row>

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      key={field.name}
                      name={[field.name, 'linkedMedia']}
                      label={<RequiredLabel children="Linked media" />}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Linked media is required!',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          { label: 'Twitter', value: LinkedMedia.Twitter },
                          { label: 'Youtube', value: LinkedMedia.Youtube },
                          { label: 'Substack', value: LinkedMedia.Substack },
                          { label: 'Mirror', value: LinkedMedia.Mirror },
                          { label: 'Link', value: LinkedMedia.Link },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      key={field.name}
                      name={[field.name, 'url']}
                      rules={[{ required: true, message: 'URL is required' }]}
                      label={<RequiredLabel children="Url" />}
                    >
                      <Input placeholder="Enter url" />
                    </Form.Item>
                  </Col>
                </Row>

                <Col span={24}>
                  <Form.Item
                    key={field.name}
                    label={<RequiredLabel required={false} children="Title" />}
                    name={[field.name, 'title']}
                    rules={[
                      {
                        transform: (value) => transformUndefinedInput(value),
                      },
                    ]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    key={field.name}
                    label={
                      <RequiredLabel required={false} children="Description" />
                    }
                    name={[field.name, 'description']}
                    rules={[
                      {
                        transform: (value) => transformUndefinedInput(value),
                      },
                    ]}
                  >
                    <Input placeholder="Description" />
                  </Form.Item>
                </Col>
              </Col>

              <Col span={1} style={{ position: 'unset' }}>
                <Row justify="end" align="middle">
                  <MinusCircleIcon
                    style={{
                      color: 'red',
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      fontSize: 16,
                    }}
                    onClick={() => remove(field.name)}
                  />
                </Row>
              </Col>
            </Row>
          ))}
          <Form.Item wrapperCol={{ span: 20 }}>
            <Row justify="center" style={{ width: '100%' }}>
              <Button
                type="dashed"
                onClick={() => {
                  add(COMMUNITY_PROFILE_DEFAULT_SECTION.featuredContents[0])
                }}
                icon={<PlusCircleIcon />}
              >
                Add Featured Content
              </Button>
            </Row>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default FormItemFeaturedContent
