import { Typography } from 'antd'

import RowAction from './rowAction'
import TotalCode from './totalCode'
import { IDigitalCard } from 'types/digital-card'

export const COLUMN_DIGITAL_CARDS = [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (title: string) => (
      <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ margin: 0 }}>
        {title}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Total',
    dataIndex: '',
    render: ({ _id }: IDigitalCard) => <TotalCode digitalCardId={_id} />,
  },
  {
    title: '',
    dataIndex: '_id',
    width: 62,
    render: (_id: string) => <RowAction digitalCardId={_id} />,
  },
]
