import { useQuery } from 'react-query'

import { AffiliationService } from 'services/affiliation-v2'

import { FindSubscriptionActivityDto } from 'types/affiliation.type'

export const DEFAULT_FILTER_ACTIVITY_DATA = {
  filterOptions: [],
  searchValue: '',
}

export const useSubscriptionActivity = (dto?: FindSubscriptionActivityDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_SUBSCRIPTION_ACTIVITY', dto],
    queryFn: () => AffiliationService.findSubscriptionActivity(dto),
  })

  return {
    ...rest,
    data: data?.data,
    total: data?.total ?? 0,
  }
}
