import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Col, Menu, Row, RowProps } from 'antd'

import { AppState } from 'store'
import { MenuInfo } from 'rc-menu/lib/interface'
import { MENU_ADMIN_ITEMS } from './sidebarItems'

const ROW_STYLE = {
  position: 'sticky',
  top: 65,
  paddingBottom: 32,
  height: 'calc(100vh - 65px)',
  overflowX: 'hidden',
}

export type SidebarContainerProps = {
  forceVisible?: boolean
  justify?: RowProps['justify']
  isMobile?: boolean
}
const SidebarContainer = ({
  forceVisible = false,
  justify = 'center',
  isMobile = false,
}: SidebarContainerProps) => {
  const [activeKey, setActiveKey] = useState('')
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const visible = useSelector(({ sidebar }: AppState) => sidebar.visible)

  const menuCollapse = useMemo(() => {
    if (forceVisible) return false
    return !visible
  }, [forceVisible, visible])

  const onMenuClick = useCallback(
    async (e: MenuInfo) => {
      const activeKey = e.key
      navigate(activeKey)
      setTimeout(() => {
        setActiveKey(activeKey)
      }, 150)
    },
    [navigate],
  )

  useEffect(() => {
    for (const item of MENU_ADMIN_ITEMS) {
      if (pathname.includes(item.key)) return setActiveKey(item.key)
    }
  }, [pathname])

  const style = !isMobile ? ROW_STYLE : {}
  const cln = isMobile
    ? 'sidebar-container mobile-sidebar'
    : 'sidebar-container'

  return (
    <Row gutter={[0, 48]} style={style} justify={justify} className="scrollbar">
      <Col span={24}>
        <Menu
          style={{ border: 'none' }}
          mode="inline"
          theme="light"
          items={MENU_ADMIN_ITEMS}
          inlineCollapsed={menuCollapse}
          selectedKeys={[activeKey]}
          onClick={onMenuClick}
          className={cln}
        />
      </Col>
    </Row>
  )
}

export default SidebarContainer
