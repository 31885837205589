import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ChainID } from 'constant'
import { chain, onSwitchChain } from 'helper/runtime'

/**
 * Interface & Utility
 */

export type MainState = {
  chain: ChainID
}

/**
 * Store constructor
 */

const NAME = 'main'
const initialState: MainState = {
  chain,
}

/**
 * Actions
 */

export const onSwitchChainNet = createAsyncThunk(
  `${NAME}/onSwitchChainNet`,
  async (chain: ChainID) => {
    const nextChain = onSwitchChain(chain)
    return { chain: nextChain }
  },
)

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder.addCase(
      onSwitchChainNet.fulfilled,
      (state, { payload }) => void Object.assign(state, payload),
    ),
})

export default slice.reducer
