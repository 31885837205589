import { useState } from 'react'

import { Col, Row, Table, Typography } from 'antd'

import ControlBar from '../controlBar'
import { COLUMN_OVERALL } from '../table/column'
import { WrapLoading } from 'components/systems/loading'
import CustomPagination from 'components/systems/pagination'

import {
  DEFAULT_FILTER_OVERALL_DATA,
  useAcceler8SubscriptionOverall,
} from 'hooks/acceler8/useAcceler8SubscriptionOverall'
import { usePagination } from 'hooks/usePagination'
import {
  Acceler8SortOptions,
  Acceler8SubscriptionFilter,
} from 'types/acceler8/acceler8-subscription.type'

export type SortFilterProps = {
  sortOption?: Acceler8SortOptions
  filterOptions: Acceler8SubscriptionFilter
  searchValue: string
}

const ListSubscriptionOverall = () => {
  const [sortFilter, setSortFilter] = useState<SortFilterProps>(
    DEFAULT_FILTER_OVERALL_DATA,
  )

  const { page, offset, limit, setPage } = usePagination()

  const { data, total, isLoading } = useAcceler8SubscriptionOverall({
    limit,
    offset,
    search: sortFilter.searchValue,
    filter: sortFilter.filterOptions,
    sort: sortFilter.sortOption,
  })

  const handleSortFilter = (
    key: string,
    value: string | Acceler8SubscriptionFilter,
  ) => {
    setSortFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <ControlBar
          hasAddButton={true}
          sortFilter={sortFilter}
          handleSortFilter={handleSortFilter}
        />
      </Col>
      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <Table
            rowKey="id"
            columns={COLUMN_OVERALL}
            dataSource={data}
            pagination={false}
          />
        </WrapLoading>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!data?.length && (
            <Col>
              <CustomPagination total={total} onChange={setPage} page={page} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default ListSubscriptionOverall
