import { ChainID } from 'constant'

import { PaginationDto } from 'types'

export enum NftType {
  ERC721 = 'NFT_TYPE:ERC721',
  ERC1155 = 'NFT_TYPE:ERC1155',
}

export interface INft {
  _id: string
  chainId: ChainID
  address: string
  nftType: NftType
  thumbnail: string
}

export type GetListNftDto = {
  address?: string
  chainId?: ChainID
} & PaginationDto

export type CreateNftDto = {
  address: string
  chainId: ChainID
  thumbnail: string
  nftType: NftType
}
