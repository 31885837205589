import moment from 'moment'

import { Typography } from 'antd'

import Action from './Action'

import { formatNumberWithSuffix } from 'helper/common'

import { TIME_FORMAT } from 'constant'

import { TeamRank } from 'types/acceler8/acceler8-team.type'

export const COLUMN_TEAM = [
  {
    title: 'Team Name',
    dataIndex: 'teamName',
    render: (teamName: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {teamName}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Current season rank',
    dataIndex: '',
    render: (teamData: TeamRank) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {teamData.points && teamData.ranking
          ? formatNumberWithSuffix(teamData.ranking)
          : '--'}
      </Typography.Text>
    ),
  },
  {
    title: 'Accumulative points',
    dataIndex: 'accumulativePoints',
    render: (accumulativePoints: number) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {accumulativePoints}
      </Typography.Text>
    ),
  },
  {
    title: 'Points this season',
    dataIndex: 'points',
    render: (points: number) => <Typography.Text>{points}</Typography.Text>,
  },
  {
    title: 'Last point contributed',
    dataIndex: 'lastContributed',
    render: (lastContributed?: string) => (
      <Typography.Text>
        {lastContributed
          ? `${moment.utc(lastContributed).format(TIME_FORMAT)} (UTC)`
          : '--'}
      </Typography.Text>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'removedAt',
    render: (removedAt: string | null) => (
      <Typography.Text>{removedAt ? 'Deleted' : 'Active'}</Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: 'id',
    width: 62,
    render: (teamId: number) => <Action teamId={teamId} />,
  },
]
