import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { Select, Button } from 'antd'

import SpaceVertical from 'components/systems/spaceVertical'

import { useCommunitiesLeaderboard } from 'hooks/leaderboard/useCommunitiesLeaderboard'
import { useCommunitiesLeaderboardMutation } from 'hooks/leaderboard/useCommunitiesLeaderboardMutation'
import { useCommunities } from 'hooks/community/useCommunities'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

const CreateCommunitiesList = () => {
  const navigate = useNavigate()

  const [communityIds, setCommunityIds] = useState<string[]>([])
  const { communities } = useCommunities({})

  const seasonId = useParams().seasonId || ''

  const { data } = useCommunitiesLeaderboard({ seasonId })
  const { mutateAsync, isLoading } = useCommunitiesLeaderboardMutation({
    communityIds: communityIds,
    seasonId,
  })

  const onSave = async () => {
    await mutateAsync()
    notifySuccess('Save communities')
    navigate(generatePath(ROUTES.LEADERBOARD.DETAIL, { seasonId }))
  }

  useEffect(() => {
    if (data) setCommunityIds(data.map((community) => community.communityId))
  }, [data])

  return (
    <SpaceVertical>
      <Select
        value={communityIds}
        mode="tags"
        style={{ width: '100%' }}
        placeholder="Enter Id"
        onChange={(value) => {
          if (value.length > 10)
            return notifyError('Please input less than 10 items!')

          setCommunityIds(value)
        }}
        options={communities.map((option) => ({
          value: option._id,
          label: option.title,
        }))}
      />
      <Button
        type="primary"
        style={{ width: 129, borderRadius: 2 }}
        onClick={() => onSave()}
        loading={isLoading}
      >
        Save
      </Button>
    </SpaceVertical>
  )
}

export default CreateCommunitiesList
