import { Col, Row, Typography } from 'antd'
import ListDigitalCards from './listDigitalCards'

const DigitalCardsManagement = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type={'success'}>
          Digital Cards Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <ListDigitalCards />
      </Col>
    </Row>
  )
}

export default DigitalCardsManagement
