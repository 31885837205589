import { LoadingProps } from './index'

const StickLoader = ({ size, dotSize }: Required<LoadingProps>) => {
  return (
    <div
      style={{
        height: size,
        top: '50%',
        position: 'absolute',
        width: '100%',
        transform: 'translateY(-50%)',
      }}
      className="sp3-stick-loader"
    >
      {[1, 2, 3, 4, 5].map((idx) => (
        <span style={{ width: dotSize }} key={idx} />
      ))}
    </div>
  )
}

export default StickLoader
