import React from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'

import FormSpace3GameTask from './FormSpace3GameTask'

import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'

import './index.less'

function Space3GameTask({ type }: LeaderboardTaskProps) {
  const taskId = useParams().taskId || ''
  const isEdit = !!taskId

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {isEdit ? 'Edit Space3 Task' : 'Create New Space3 Task'}
        </Typography.Title>
      </Col>

      <Col span={24}>
        <FormSpace3GameTask type={type} isEdit={isEdit} taskId={taskId} />
      </Col>
    </Row>
  )
}

export default Space3GameTask
