import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

export const useAcceler8Season = (seasonId?: number) => {
  return useQuery({
    queryKey: ['GET_ACCELER8_SEASON', seasonId],
    queryFn: () => Acceler8Service.findSeason(seasonId),
    enabled: !!seasonId,
  })
}
