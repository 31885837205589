import { useMutation } from 'react-query'

import { MultiplierService } from 'services/multiplier'

export const useUploadCsv = () => {
  return useMutation({
    mutationKey: ['UPLOAD_CSV'],
    mutationFn: (formData: FormData) => MultiplierService.uploadCsv(formData),
  })
}
