import { Navigate, Outlet } from 'react-router-dom'

import { getAccessToken } from 'services/utils'

const PrivateRoutes = () => {
  const token = getAccessToken()

  return token ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoutes
