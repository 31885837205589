import { useContext, useMemo, useState } from 'react'

import { Button, Col, Modal, Row, Select, Space, Typography } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import UploadRedemptions from './UploadRedemptions'
import SearchBar from 'components/systems/searchBar'

import RedemptionsFilterContext from '../redemptions-list/RedemptionFilterContext'

import sample from 'static/files/sample-player-redemptions.csv'

const ImportRedemptions = () => {
  const [open, setOpen] = useState(false)
  const { filter, seasons, setSearch, setFilter } = useContext(
    RedemptionsFilterContext,
  )

  const filterItems = useMemo(() => {
    return seasons.map((season) => {
      return { label: season.title, value: season.id }
    })
  }, [seasons])

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col span={8}>
        <SearchBar setSearch={setSearch} placeholder="Search redemptions..." />
      </Col>
      <Col span={4}>
        <Select
          style={{ width: '100%' }}
          value={filter?.seasonId}
          menuItemSelectedIcon={<IonIcon name="checkmark-outline" />}
          suffixIcon={<IonIcon name="filter-outline" />}
          className="dropdown-btn dropdown-filter"
          placeholder="Filter by Season"
          onChange={(value: number) => {
            setFilter((prev: any) => ({
              ...prev,
              seasonId: value,
            }))
          }}
          options={filterItems}
        />
      </Col>
      <Col>
        <Space size={8}>
          <Button
            icon={<IonIcon name="download-outline" />}
            ghost
            href={sample}
            target="_blank"
            download
          >
            Download Template
          </Button>
          <Button type="primary" onClick={() => setOpen(true)}>
            <Space>
              <IonIcon name="add" />
              <Typography.Text strong>Import Redemptions</Typography.Text>
            </Space>
          </Button>
        </Space>
      </Col>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        destroyOnClose={true}
      >
        <UploadRedemptions onUploadSuccess={() => setOpen(false)} />
      </Modal>
    </Row>
  )
}

export default ImportRedemptions
