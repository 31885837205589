import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

export const usePlayerActiveSeasons = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_PLAYER_ACTIVE_SEASONS'],
    queryFn: () => PlayerLeaderboardService.findActiveSeasons(),
  })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
  }
}
