import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { DUMMY_PRODUCT_DATA } from 'constant/marketplace'
import { DUMMY_PRODUCT_SKU_DATA } from 'hooks/useHandleProductSKU'
import { orderService } from 'services/marketplace/order'
import { DEFAULT_PAGE } from 'constant'
import { Data } from 'types'
import {
  OrderData,
  OrderStatus,
  ShippingStatus,
  PaidInfo,
  PaymentStatus,
} from 'types/order.type'

import { ProductType } from 'types/product.type'

const DUMMY_PAID: Data<PaidInfo> = {
  amount: 0,
  status: PaymentStatus.Open,
  _id: '',
}

const DUMMY_ORDER_DATA: OrderData = {
  _id: '',
  createdAt: '',
  delivery: {},
  orderStatus: OrderStatus.Initialized,
  paidBadge: DUMMY_PAID,
  paidCCP: DUMMY_PAID,
  paidToken: DUMMY_PAID,
  product: '',
  productData: DUMMY_PRODUCT_DATA,
  quantity: 0,
  shippingStatus: ShippingStatus.Preparing,
  sku: '',
  skuData: DUMMY_PRODUCT_SKU_DATA,
  uid: '',
}

export const useOrders = ({
  page = DEFAULT_PAGE,
  pageSize,
  search = '',
  productType,
  orderStatus,
  shippingStatus,
  categoryId,
}: {
  page?: number
  pageSize?: number
  search?: string
  productType?: ProductType
  orderStatus?: OrderStatus
  shippingStatus?: ShippingStatus
  categoryId?: string
}) => {
  const key =
    'ORDER_DATA' +
    JSON.stringify({
      page,
      pageSize,
      search,
      productType,
      shippingStatus,
      orderStatus,
      categoryId,
    })
  const { data, isLoading, refetch } = useQuery(
    key,
    async () => {
      const { data, total } = await orderService.getOrderedItems({
        page,
        pageSize,
        search,
        productType,
        shippingStatus,
        orderStatus,
        categoryId,
      })
      const formatData = data.map((product: any) => ({
        ...product,
        productData: product.productData[0],
        skuData: product.skuData[0],
      }))
      return { data: formatData, total }
    },
    { refetchOnWindowFocus: false },
  )

  return {
    orders: data ? data.data : [],
    total: data ? data.total : 0,
    loading: isLoading,
    refetch,
  }
}

export const useOrderData = (orderId: string) => {
  const [orderData, setOrderData] = useState<OrderData>(DUMMY_ORDER_DATA)
  const [loading, setLoading] = useState(false)

  const fetchOrders = useCallback(async () => {
    try {
      setLoading(true)
      const data = await orderService.getOrderData(orderId)
      setOrderData({ ...data, productData: data.product, skuData: data.sku })
    } catch (error) {
      setOrderData(DUMMY_ORDER_DATA)
    } finally {
      setLoading(false)
    }
  }, [orderId])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  return { orderData, loading, fetchOrders }
}
