import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { FindContributionByProgramDto } from 'types/general-leaderboard/general-leaderboard.type'

export const useAcceler8TotalPointsByProgram = (
  dto: FindContributionByProgramDto,
) =>
  useQuery({
    queryKey: ['TOTAL_POINTS_BY_PROGRAM', dto],
    queryFn: () => Acceler8Service.getTotalPointsByProgram(dto),
    enabled: !!dto.seasonId && !!dto.programIds.length,
  })
