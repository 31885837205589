import { generatePath } from 'react-router-dom'

import { bountySystemApi as api } from 'services/base-axios'

const CONTROLLER = '/question-answer'
const PATHS = {
  exportAnswersToCsv: '/:taskId/export/csv',
}

class QuestionAnswer {
  async downloadCsv(taskId: string): Promise<Blob> {
    const res = await api.get(
      CONTROLLER + generatePath(PATHS.exportAnswersToCsv, { taskId }),
      {
        responseType: 'blob',
      },
    )
    return res.data
  }
}

export const QuestionAnswerService = new QuestionAnswer()
