import { useMemo } from 'react'
import moment from 'moment'

import { Button, Col, Row, Typography } from 'antd'
import DailyReward from './dailyReward'

import { IDailyReward } from 'types/check-in.type'

const ListDaily = ({
  dailyRewards,
  title,
}: {
  dailyRewards: IDailyReward[]
  title: string
}) => {
  const color = useMemo(() => {
    if (moment.utc().isAfter(moment.utc(dailyRewards.at(-1)?.date)))
      return '#42BAB0'
    if (moment.utc().isBefore(moment.utc(dailyRewards[0].date)))
      return '#DB4646'
    return '#4D5A66'
  }, [dailyRewards])

  return (
    <Row wrap={false} gutter={[16, 16]} align="middle">
      <Col span={3}>
        <Button size="small">
          <Typography.Text style={{ color, fontWeight: 700, fontSize: 14 }}>
            {title}
          </Typography.Text>
        </Button>
      </Col>
      {dailyRewards.map((dailyReward, index) => (
        <Col span={3} key={index}>
          <DailyReward {...dailyReward} />
        </Col>
      ))}
    </Row>
  )
}

export default ListDaily
