import { marketplaceApi as api } from 'services/base-axios'

import { ChainID, DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

const CONTROLLER = '/collection'

class Collection {
  async getAll({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
    chainId,
  }: {
    page?: number
    pageSize?: number
    search?: string
    chainId?: ChainID
  }) {
    return await api
      .get(CONTROLLER, {
        params: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          search,
          chainId,
        },
      })
      .then((data) => data.data)
  }

  async create(data: CreateCollection) {
    return api.post(CONTROLLER, data)
  }

  async update(
    collectionId: string,
    data: UpdateCollection,
  ): Promise<CollectionData> {
    return api
      .patch(`${CONTROLLER}/${collectionId}`, { ...data })
      .then((data) => data.data)
  }

  async getCollectionById(id: string): Promise<CollectionData> {
    return await api.get(`${CONTROLLER}/${id}`).then((res) => res.data)
  }
}

export type CollectionData = {
  _id: string
  title: string
  description: string
  thumbnail: string
  totalNFT: number
  socials: Socials
  chainId: ChainID
  collectionAddress: string
}

export type Socials = {
  Website: string
  Twitter: string
  Discord: string
  Telegram: string
}

export type CreateCollection = {
  title: string
  description: string
  thumbnail: string
  chainId: ChainID
  collectionAddress: string
  totalNFT: number
  socials: Socials
}

export type UpdateCollection = Partial<CreateCollection>

export const CollectionService = new Collection()
