import { CSSProperties } from 'react'

import { Image, ImageProps, Skeleton } from 'antd'
import LazyLoad from 'react-lazy-load'

import noImage from 'static/images/system/nodata.svg'

import './index.less'

type ImageLazyLoadProps = {
  src: string
  style?: CSSProperties
  lazyHeight?: number | string
  threshold?: number
  className?: string
}
const ImageLazyLoad = ({
  src,
  style,
  lazyHeight = '100%',
  threshold = 0.2,
  className,
  ...props
}: ImageLazyLoadProps & ImageProps) => {
  return (
    <LazyLoad
      className="sp3-lazyload-img"
      height={lazyHeight}
      threshold={threshold}
    >
      <Image
        className={className}
        style={{ aspectRatio: '1/1', objectFit: 'cover', ...style }}
        src={src}
        preview={false}
        placeholder={<Skeleton.Image active />}
        fallback={noImage}
        {...props}
      />
    </LazyLoad>
  )
}

export default ImageLazyLoad
