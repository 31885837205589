// React's/Next
import { useQuery } from 'react-query'
// UI Libs
// Components
// Hooks/Services
import { SeasonService } from 'services/bountySystem/season'

import { FindSeasonsDto, ISeason } from 'types/season.type'
import { DataList } from 'types'
// Helper functions
// Constants
// Images
// CSS/SCSS/Styles

export const useSeasons = (params: FindSeasonsDto) => {
  const { data, isLoading, error } = useQuery<DataList<ISeason[]>>({
    queryKey: [`GET_LIST_SEASONS`, params],
    queryFn: () => SeasonService.getSeasons(params),
  })

  return {
    seasons: data?.data || [],
    total: data?.total || 0,
    isLoading,
    error,
  }
}

export const useSeason = (seasonId: string) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['GET_SEASON_DETAILS', seasonId],
    queryFn: () => SeasonService.getSeasonById(seasonId),
  })

  return {
    seasonData: data,
    isLoading,
    error,
    refetch,
  }
}
