import { useNavigate, useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'
import SettingCategory from './settingCategory'

import { notifySuccess } from 'helper'
import { ROUTES } from 'constant/routes'
import { ProductCategoryService } from 'services/marketplace/product-category'
import { useProductCategory } from 'hooks/useProductCategory'
import Loading from 'components/systems/loading'
import { IProductCategory } from 'types/product-category.type'

const EditCategory = () => {
  const navigate = useNavigate()
  const { categoryId } = useParams()
  const { data, isLoading } = useProductCategory(categoryId!)

  const handleUpdateCategory = async (value: IProductCategory) => {
    await ProductCategoryService.update(categoryId!, value)
    navigate(ROUTES.REWARDS_CENTER.CATEGORY_MANAGEMENT)
    notifySuccess('Update category')
  }

  if (isLoading) return <Loading type="page" loading />
  if (!data) return null

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Edit Category
        </Typography.Title>
      </Col>
      <Col span={24}>
        <SettingCategory onOk={handleUpdateCategory} value={data} />
      </Col>
    </Row>
  )
}

export default EditCategory
