export enum CommunitySectionType {
  Overview = 'COMMUNITY_CONTENT_TYPE:OVERVIEW',
  System = 'COMMUNITY_CONTENT_TYPE:SYSTEM',
  Featured = 'COMMUNITY_CONTENT_TYPE:FEATURED',
  Member = 'COMMUNITY_CONTENT_TYPE:MEMBER',
  Company = 'COMMUNITY_CONTENT_TYPE:COMPANY',
  OfficialLink = 'COMMUNITY_CONTENT_TYPE:OFFICIAL_LINK',
}

export interface IBasicSection {
  communityId: string

  type: CommunitySectionType

  enabled: boolean

  orderIndex: number
}

export enum MediaType {
  Video = 'MEDIA_TYPE:VIDEO',
  Image = 'MEDIA_TYPE:IMAGE',
}

export interface IMedia {
  type: MediaType

  url: string
}

export interface IOverviewSection {
  title: string

  description: string

  media: IMedia[]
}

export enum LinkedMedia {
  Twitter = 'LINKED_MEDIA:TWITTER',
  Youtube = 'LINKED_MEDIA:YOUTUBE',
  Substack = 'LINKED_MEDIA:SUBSTACK',
  Mirror = 'LINKED_MEDIA:MIRROR',
  Link = 'LINKED_MEDIA:LINK',
}

export interface ISystemFeature {
  title: string

  description: string

  media: IMedia
}

export interface ISystemFeaturesSection {
  systemFeatures: ISystemFeature[]
}

export interface IFeaturedContent {
  linkedMedia: LinkedMedia

  url: string

  title?: string

  description?: string
}

export interface IFeaturedContentsSection {
  featuredContents: IFeaturedContent[]
}

export interface IMemberContent {
  twitterUsername: string

  description: string
}

export interface IMembersSection {
  members: IMemberContent[]
}

export interface ICompanyContent {
  twitterUsername: string
}

export interface ICompaniesSection {
  companies: ICompanyContent[]
}

export enum OfficialType {
  Twitter = 'OFFICIAL:TWITTER',
  Facebook = 'OFFICIAL:FACEBOOK',
  Youtube = 'OFFICIAL:YOUTUBE',
  Discord = 'OFFICIAL:DISCORD',
  Telegram = 'OFFICIAL:TELEGRAM',
  Website = 'OFFICIAL:WEBSITE',
  OfficialLink = 'OFFICIAL:LINK',
}

export interface IOfficialLink {
  officialType: OfficialType

  url: string
}

export interface IOfficialLinksSection {
  officialLinks: IOfficialLink[]
}

export type CommunityProfileSectionMap = {
  [CommunitySectionType.Overview]: IOverviewSection & IBasicSection
  [CommunitySectionType.System]: ISystemFeaturesSection & IBasicSection
  [CommunitySectionType.Featured]: IFeaturedContentsSection & IBasicSection
  [CommunitySectionType.Member]: IMembersSection & IBasicSection
  [CommunitySectionType.Company]: ICompaniesSection & IBasicSection
  [CommunitySectionType.OfficialLink]: IOfficialLinksSection & IBasicSection
}

export type CommunityProfileSection<T extends CommunitySectionType> =
  CommunityProfileSectionMap[T]

export type GetAllCommunityProfileDto = {
  communityId: string
}
