import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Dropdown, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import {
  MarketItemCreateRoute,
  MarketItemCreateType,
} from 'constant/marketplace'

const CREATE_MARKETPLACE_ITEMS = [
  {
    label: MarketItemCreateType.Digital,
    key: MarketItemCreateType.Digital,
    icon: <IonIcon name="add-outline" />,
  },
  {
    label: MarketItemCreateType.Physical,
    key: MarketItemCreateType.Physical,
    icon: <IonIcon name="add-outline" />,
  },
  {
    label: MarketItemCreateType.Code,
    key: MarketItemCreateType.Code,
    icon: <IonIcon name="add-outline" />,
  },
]

const NewAction = () => {
  const navigate = useNavigate()
  const onCreateBounty = useCallback(
    (e: any) => {
      switch (e.key) {
        case MarketItemCreateType.Digital:
          return navigate(MarketItemCreateRoute.Digital)
        case MarketItemCreateType.Physical:
          return navigate(MarketItemCreateRoute.Physical)
        case MarketItemCreateType.Code:
          return navigate(MarketItemCreateRoute.Code)
      }
    },
    [navigate],
  )

  return (
    <Dropdown.Button
      overlayClassName="sp3-drop-overlay"
      className="dropdown-btn dropdown-create-bounty btn-filled"
      type="text"
      menu={{ items: CREATE_MARKETPLACE_ITEMS, onClick: onCreateBounty }}
      icon={
        <Space>
          <IonIcon name="add-outline" />
          <Typography.Text strong>Add New Item</Typography.Text>
          <IonIcon name="chevron-down-outline" />
        </Space>
      }
    />
  )
}

export default NewAction
