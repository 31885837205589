import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Input, Row, Select, Table, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import CustomPagination from 'components/systems/pagination'
import SpaceBetween from 'components/systems/spaceBetween'

import { FilterBadge } from 'constant/badge'
import { COLUMNS } from './column'
import { useCheques } from 'hooks/cheque/useCheques'
import { DEFAULT_PAGE } from 'constant'

const FILTER_ITEMS = [
  { label: 'Show All', value: FilterBadge.ALL },
  { label: FilterBadge.BSC, value: FilterBadge.BSC },
  { label: FilterBadge.MATIC, value: FilterBadge.MATIC },
  { label: FilterBadge.SOL, value: FilterBadge.SOL },
]

const OnChain = () => {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const badgeId = useParams().itemId || ''
  const { cheques, total } = useCheques({ badgeId, page })

  return (
    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
      <Col span={24}>
        <SpaceBetween
          floatContent={
            <Select
              size="small"
              options={FILTER_ITEMS}
              suffixIcon={
                <IonIcon name="filter-outline" style={{ color: '#42BAB0' }} />
              }
              className="dropdown-btn dropdown-filter"
              style={{ width: 140 }}
              defaultValue={FilterBadge.ALL}
            />
          }
        >
          <Input
            placeholder="Search NFT"
            style={{ width: 244 }}
            suffix={
              <IonIcon name="search-outline" style={{ color: '#42BAB0' }} />
            }
          />
        </SpaceBetween>
      </Col>
      <Col span={24}>
        <Table
          className="table-border-collapse"
          columns={COLUMNS}
          dataSource={cheques}
          pagination={false}
          rowKey={({ uid }) => uid}
          rowSelection={{ type: 'checkbox' }}
        />
      </Col>
      <Col span={24}>
        <SpaceBetween
          floatContent={
            <CustomPagination page={page} onChange={setPage} total={total} />
          }
        >
          <Typography.Text type="success">Total: {total}</Typography.Text>
        </SpaceBetween>
      </Col>
    </Row>
  )
}

export default OnChain
