import { Fragment, Key, useCallback, useState } from 'react'

import { Button, Card, Col, Row, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import ChangeState from './changeState'

import { orderService } from 'services/marketplace/order'

import { UserShippingAction } from 'constant/marketplace'
import { ShippingStatus, SHIPPING_STATUS_LABEL } from 'types/order.type'
import { notifyError, notifySuccess } from 'helper'

type OrdersSelectedProps = {
  ordersSelected: Key[]
  refresh: () => void
  onSelectChange: (orderIdsSelected: Key[]) => void
}
const OrdersSelected = ({
  ordersSelected,
  refresh,
  onSelectChange,
}: OrdersSelectedProps) => {
  const [loading, setLoading] = useState(false)
  const [shippingStatus, setShippingStatus] = useState<ShippingStatus>()

  const onConfirm = useCallback(async () => {
    if (!shippingStatus) return
    setLoading(true)

    try {
      await orderService.updateShippingStatus(
        ordersSelected as string[],
        shippingStatus,
      )
      notifySuccess(
        `Update order status to ${SHIPPING_STATUS_LABEL[shippingStatus]}`,
      )
      onSelectChange([])
    } catch (err) {
      notifyError({ message: err })
    } finally {
      await refresh()
      setLoading(false)
    }
  }, [shippingStatus, ordersSelected, onSelectChange, refresh])

  if (!ordersSelected.length) return <Fragment />

  return (
    <Card bordered={false} style={{ background: '#1B2526' }}>
      <Row gutter={[24, 24]} justify="space-between" align="middle">
        <Col>
          <Space>
            <Typography.Text> {ordersSelected.length}</Typography.Text>
            <Typography.Text>
              {ordersSelected.length > 1 ? 'items' : 'item'} has been selected
            </Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <ChangeState
              children={
                <Button
                  type="primary"
                  loading={loading}
                  icon={<IonIcon name="cloud-upload-outline" />}
                  onClick={() => setShippingStatus(ShippingStatus.Sending)}
                >
                  Move to shipping
                </Button>
              }
              onConfirm={onConfirm}
              title={UserShippingAction.MoveToShipping}
            />
            <ChangeState
              children={
                <Button
                  type="primary"
                  loading={loading}
                  icon={<IonIcon name="cloud-upload-outline" />}
                  onClick={() => setShippingStatus(ShippingStatus.Done)}
                >
                  Completed
                </Button>
              }
              onConfirm={onConfirm}
              title={UserShippingAction.Completed}
            />
            <ChangeState
              children={
                <Button
                  type="primary"
                  danger
                  ghost
                  loading={loading}
                  icon={<IonIcon name="trash" />}
                  onClick={() => setShippingStatus(ShippingStatus.Fail)}
                >
                  Cancelled
                </Button>
              }
              onConfirm={onConfirm}
              title={UserShippingAction.Cancelled}
            />
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default OrdersSelected
