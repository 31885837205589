import { Fragment, useState } from 'react'
import Papa from 'papaparse'

import {
  Button,
  Col,
  Image,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { notifySuccess } from 'helper'
import { UserCompletedData } from './index'

import iconUpload from 'static/images/bounty/upload.svg'
import csvLogo from 'static/images/bounty/csvLogo.svg'

const TEN_MB = 10000000

type UploadCSVProps = {
  setUserCompleted: (data: UserCompletedData[]) => void
}

const UploadCSV = ({ setUserCompleted }: UploadCSVProps) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<File>()
  const [error, setError] = useState(false)

  const parse = (file: any): Promise<Array<[string]>> => {
    return new Promise((resolve, reject) => {
      return Papa.parse(file, {
        skipEmptyLines: true,
        complete: ({ data }, fileInfo) => {
          if (fileInfo.size > TEN_MB) return reject('File to large')
          setFile(fileInfo)
          return resolve(data as Array<[string]>)
        },
      })
    })
  }

  const onUpload = async (file: any) => {
    try {
      setError(false)
      setLoading(true)
      setUserCompleted([])
      const listUser = await parse(file)
      const result: UserCompletedData[] = []
      const uidSet = new Set(
        listUser.map(([uid]) => uid).filter((uid) => !!uid),
      )
      uidSet.forEach((uid) => result.push({ uid }))
      setUserCompleted(result)

      setVisible(false)
      return notifySuccess(
        'Result file upload successfully, System is processing to get final results',
      )
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const onRemove = () => {
    setFile(undefined)
    setError(false)
  }

  return (
    <Fragment>
      <Button
        icon={<IonIcon name="cloud-upload-outline" />}
        onClick={() => setVisible(true)}
        type="primary"
      >
        Upload CSV to validate
      </Button>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        closeIcon={<IonIcon name="close-outline" />}
        footer={null}
      >
        <Row gutter={[0, 38]} justify="center">
          <Col span={24}>
            <Space
              align="center"
              size={4}
              direction="vertical"
              style={{ width: '100%' }}
            >
              <Typography.Title type="success" level={4}>
                Upload CSV to validate
              </Typography.Title>
              <Typography.Text type="secondary">
                You can upload CSV file with maximum size is 10MB
              </Typography.Text>
            </Space>
          </Col>
          <Col span={24}>
            <Space style={{ width: '100%' }} direction="vertical">
              <Spin spinning={loading}>
                <Upload.Dragger
                  accept=".csv"
                  maxCount={1}
                  className={error ? 'upload-file error' : 'upload-file'}
                  beforeUpload={onUpload}
                  showUploadList={false}
                  progress={{ strokeWidth: 2, showInfo: true }}
                >
                  {!file ? (
                    <Space direction="vertical" align="center">
                      <Image src={iconUpload} preview={false} />
                      <Typography.Text type="secondary">
                        Upload file
                      </Typography.Text>
                    </Space>
                  ) : (
                    <Space direction="vertical" size={12} align="center">
                      <Image src={csvLogo} preview={false} />
                      <Typography.Text>{file.name}</Typography.Text>
                    </Space>
                  )}
                </Upload.Dragger>
              </Spin>
              {error && (
                <Space align="center">
                  <IonIcon
                    style={{ color: '#ff4d4f' }}
                    name="alert-circle-outline"
                  />
                  <Typography.Text type="danger">
                    Upload file size exceeds 10MB limit
                  </Typography.Text>
                </Space>
              )}
            </Space>
          </Col>
          <Col>
            <Space size={20}>
              <Button onClick={onRemove} style={{ width: 130 }} ghost>
                Cancel
              </Button>
              <Button disabled={!file} style={{ width: 130 }} type="primary">
                Continue
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default UploadCSV
