import { useMutation } from 'react-query'

import { TaskService } from 'services/bountySystem/task'

import type { UpdateTaskDto } from 'services/bountySystem/task/type'

export const useUpdateTask = () => {
  return useMutation({
    mutationKey: ['update-task'],
    mutationFn: ({
      taskId,
      payload,
    }: {
      taskId: string
      payload: UpdateTaskDto
    }) => TaskService.update(taskId, payload),
  })
}
