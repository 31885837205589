import { useQuery, useQueryClient } from 'react-query'

import { nftService } from 'services/nft'

import { GetListMetadataDto } from 'types/nft-metadata.type'

const ROOT_KEY = 'GET_NFTS_METADATA'
export const useNftsMetadata = (dto: GetListMetadataDto, enabled = true) => {
  const queryClient = useQueryClient()
  const { data, ...rest } = useQuery({
    queryKey: [ROOT_KEY, dto],
    queryFn: () => nftService.getListMetadata(dto),
    enabled,
  })
  const refetchRoot = () => queryClient.invalidateQueries(ROOT_KEY)

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
    refetchRoot,
  }
}
