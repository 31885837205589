import { useCallback, useEffect, useState } from 'react'

import { Col, Row, Space, Typography } from 'antd'

import { numeric, randomColor } from 'helper'
import { CIRCLE_PERCENT, REPORT_COLOR_BASE } from './type'

import './index.less'
import SpaceVertical from '../spaceVertical'
import SpaceBetween from '../spaceBetween'

type DonutData = { label: string; value: number }

type DonutElementProps = {
  data: DonutData[]
  colors?: string[]
  label?: string
  size?: number
  stroke?: number
}

const DonutElement = ({
  colors,
  data,
  label = 'Total',
  size = 192,
  stroke = 50,
}: DonutElementProps) => {
  const [legendColors, setLegendColors] = useState<string[]>([])
  const [background, setBackground] = useState('')

  const total = data.reduce((a, b) => a + b.value, 0)

  const colorsBase = colors || REPORT_COLOR_BASE

  const getColor = useCallback(
    (idx: number) => colorsBase[idx] || randomColor(),
    [colorsBase],
  )

  const generateBackground = useCallback(() => {
    if (!data.length || !total) return setBackground('#fff')
    if (data.length === 1)
      return setBackground(`conic-gradient(${getColor(0)} 0deg 360deg)`)

    const firstColor = getColor(0)
    const legendColors = [firstColor]
    let background = `conic-gradient(${firstColor} 0deg`
    let lastDeg = 0

    data.forEach(({ value }, idx) => {
      const numDeg = (value / total) * CIRCLE_PERCENT + lastDeg
      const color = getColor(idx + 1)

      if (idx + 1 < data.length)
        background += ` ${numDeg}deg, ${color} ${numDeg}deg`
      else background += ` 360deg)`

      legendColors.push(color)
      lastDeg = numDeg
    })
    setLegendColors(legendColors)
    return setBackground(background)
  }, [data, getColor, total])

  useEffect(() => {
    generateBackground()
  }, [generateBackground])

  return (
    <Row gutter={[24, 24]} align="middle">
      <Col>
        <div
          className="ccp-chart"
          style={{ width: size, height: size, background }}
        >
          <div
            className="hole"
            style={{ width: size - stroke, height: size - stroke }}
          >
            <Space direction="vertical" align="center" size={0}>
              <Typography.Text className="caption">{label}</Typography.Text>
              <Typography.Title level={2} type="success">
                {numeric(total).format('0,0.[00]')}
              </Typography.Title>
            </Space>
          </div>
        </div>
      </Col>
      {!!data.length && (
        <Col style={{ minWidth: 180 }}>
          <SpaceVertical>
            {data.map((item, idx) => (
              <SpaceBetween
                floatContent={
                  <Typography.Title
                    style={{ fontSize: 16, fontWeight: 700 }}
                    type="success"
                  >
                    {item.value}
                  </Typography.Title>
                }
                key={item.label}
              >
                <Space>
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: '100%',
                      background: legendColors[idx],
                    }}
                  />
                  {item.label}
                </Space>
              </SpaceBetween>
            ))}
          </SpaceVertical>
        </Col>
      )}
    </Row>
  )
}

export default DonutElement
