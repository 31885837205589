import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { FindSubscriptionActivityDto } from 'types/acceler8/acceler8-subscription.type'

export const DEFAULT_FILTER_ACTIVITY_DATA = {
  filterOptions: {
    statuses: [],
    packageIds: [],
  },
  searchValue: '',
}

export const useAcceler8SubscriptionActitivy = (
  dto?: FindSubscriptionActivityDto,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_SUBSCRIPTION_ACTIVITY', dto],
    queryFn: () => Acceler8Service.findSubscriptionActivity(dto),
  })

  return {
    ...rest,
    data: data?.data,
    total: data?.total ?? 0,
  }
}
