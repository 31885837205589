import { Dispatch, SetStateAction } from 'react'

import { Button, Col, Row } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import VariationSetting from './variationSetting'
import AttributeSetting from './attributeSetting'

import { AttributeConfig } from 'services/marketplace/product-category.type'
import { DEFAULT_ATTRIBUTE_SETTING } from './attributeSetting'

const FormAttributeSetting = ({
  attributes,
  onChange,
  type,
}: {
  attributes: AttributeConfig[]
  onChange: Dispatch<SetStateAction<AttributeConfig[]>>
  type?: 'default' | 'variation'
}) => {
  const handleChangeAttribute = (idx: number, data: AttributeConfig) => {
    const newAttributes = [...attributes]
    newAttributes[idx] = { ...data }

    return onChange(newAttributes)
  }

  const handleRemove = (idx: number) => {
    const newAttributes = [...attributes]
    newAttributes.splice(idx, 1)
    return onChange(newAttributes)
  }

  return (
    <Row gutter={[32, 32]}>
      {attributes.map((value, idx) => {
        return (
          <Col span={24} key={idx}>
            {type === 'variation' ? (
              <VariationSetting
                value={value}
                onChange={(value) => handleChangeAttribute(idx, value)}
                onRemove={() => handleRemove(idx)}
              />
            ) : (
              <AttributeSetting
                value={value}
                onChange={(value) => handleChangeAttribute(idx, value)}
                onRemove={() => handleRemove(idx)}
              />
            )}
          </Col>
        )
      })}
      <Col span={24}>
        <Button
          type="primary"
          ghost
          icon={<IonIcon name="add-outline" />}
          onClick={() =>
            onChange([...attributes, { ...DEFAULT_ATTRIBUTE_SETTING }])
          }
        >
          Add Custom Field
        </Button>
      </Col>
    </Row>
  )
}

export default FormAttributeSetting
