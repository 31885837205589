import { memo, useCallback, useMemo, useState } from 'react'

import { Button, Card, Col, Row } from 'antd'

import NFTItem from './nftItem'

import { useBountySetup } from 'view/bounty/management/index'

import { BADGE_AMOUNT_MAX_LEN } from 'constant/badge'

const DEFAULT_PAGE_SIZE = 5

export type BountyDataProps = {
  onDeleteNFT: (badgeId: string) => void
}
const NFTsSelected = ({ onDeleteNFT }: BountyDataProps) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [bountyData, setBountyData] = useBountySetup()

  const totalNFT = bountyData.rewardBadges.length

  const handleClick = () => {
    if (pageSize >= totalNFT) return setPageSize(pageSize - DEFAULT_PAGE_SIZE)
    return setPageSize(pageSize + DEFAULT_PAGE_SIZE)
  }

  const handleChangeAmount = useCallback(
    (badgeId: string, value: number | null) => {
      if (!value) return

      const newRewardBadges = [...bountyData.rewardBadges]
      const index = newRewardBadges.findIndex((e) => e.badgeId === badgeId)
      if (index < 0) return
      newRewardBadges[index].amount = value

      return setBountyData({ ...bountyData, rewardBadges: newRewardBadges })
    },
    [bountyData, setBountyData],
  )

  const btnText = useMemo(() => {
    if (pageSize >= totalNFT) return 'View less'
    return 'View More'
  }, [totalNFT, pageSize])

  if (!totalNFT) return null

  return (
    <Card bodyStyle={{ padding: 16 }}>
      <Row justify="space-between" gutter={[12, 6]}>
        {bountyData.rewardBadges
          .slice(0, pageSize)
          .map(({ badgeId, amount }) => (
            <Col span={24} key={badgeId} className="nft-item">
              <NFTItem
                onDelete={onDeleteNFT}
                badgeId={badgeId}
                amount={amount}
                threshold={BADGE_AMOUNT_MAX_LEN}
                onChangeAmount={(value) => handleChangeAmount(badgeId, value)}
              />
            </Col>
          ))}
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button
            disabled={totalNFT <= DEFAULT_PAGE_SIZE}
            onClick={handleClick}
          >
            {btnText}
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default memo(NFTsSelected)
