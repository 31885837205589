import { useQuery, useQueryClient } from 'react-query'

import { nftService } from 'services/nft'

import { GetListNftDto } from 'types/nft.type'

const ROOT_KEY = 'GET_LIST_NFTS'
export const useListNfts = (dto: GetListNftDto, enabled = true) => {
  const queryClient = useQueryClient()
  const { data, ...rest } = useQuery({
    queryKey: [ROOT_KEY, dto],
    queryFn: () => nftService.getListNfts(dto),
    enabled,
  })

  const refetchRoot = () => queryClient.invalidateQueries(ROOT_KEY)
  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
    refetchRoot,
  }
}
