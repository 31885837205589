import { marketplaceApi as api } from 'services/base-axios'

import { ChainID } from 'constant'

const EVM_CONTROLLER = '/evm/collection-info'
const RONIN_CONTROLLER = '/ronin/collection-info'

class CollectionInfoNft {
  async evmCollectionInfo(getCollectionDto: GetCollectionDto) {
    return api
      .get(EVM_CONTROLLER, {
        params: {
          ...getCollectionDto,
        },
      })
      .then((data) => data.data)
  }

  async roninCollectionInfo(getCollectionDto: GetCollectionDto) {
    return api
      .get(RONIN_CONTROLLER, {
        params: {
          ...getCollectionDto,
        },
      })
      .then((data) => data.data)
  }

  async collectionInfo(getCollectionDto: GetCollectionDto) {
    const { chainId } = getCollectionDto
    if (chainId === ChainID.Ronin) {
      return this.roninCollectionInfo(getCollectionDto)
    }
    return this.evmCollectionInfo(getCollectionDto)
  }
}

export type GetCollectionDto = {
  address: string
  chainId?: string
}

export const CollectionInfoService = new CollectionInfoNft()
