import { useEffect, useMemo, useState } from 'react'

import { Col, Row, Table, Typography } from 'antd'

import ControlBar from '../controlBar'
import { COLUMN_TEAM } from './table/column'
import CustomPagination from 'components/systems/pagination'
import { WrapLoading } from 'components/systems/loading'

import { useLatestAcceler8Seasons } from 'hooks/acceler8/useLatestAcceler8Seasons'
import {
  useAcceler8AccumulativePoints,
  useAcceler8Teams,
} from 'hooks/acceler8/useAcceler8Teams'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import { TeamSortOptions } from 'types/acceler8/acceler8-team.type'

const DEFAULT_FILTER_TEAM_DATA = {
  sortOption: TeamSortOptions.CurrentSeasonRank,
  searchValue: '',
}

export type SortFilterProps = {
  sortOption: TeamSortOptions
  searchValue: string
}

const ListTeam = () => {
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [sortFilter, setSortFilter] = useState<SortFilterProps>(
    DEFAULT_FILTER_TEAM_DATA,
  )

  const { data: latestSeasonsData } = useLatestAcceler8Seasons({ limit: 1 })

  const { data, total, isLoading } = useAcceler8Teams({
    seasonId: latestSeasonsData[0]?.id || 0,
    limit: DEFAULT_PAGE_SIZE,
    offset: (page - 1) * DEFAULT_PAGE_SIZE,
    sort: sortFilter.sortOption,
    search: sortFilter.searchValue,
  })

  const teamIds = useMemo(() => {
    return data?.map((team) => team.id) || []
  }, [data])

  const {
    data: listTeamAccumulatedPoints,
    isLoading: isAccumulativePointLoading,
  } = useAcceler8AccumulativePoints(teamIds)

  const tableData = useMemo(() => {
    const accumulativePointsByTeamId: Record<number, number> = {}
    listTeamAccumulatedPoints?.forEach((data) => {
      accumulativePointsByTeamId[data.teamId] = data.accumulativePoints
    })

    return data.map((team) => ({
      ...team,
      accumulativePoints: accumulativePointsByTeamId[team.id],
    }))
  }, [listTeamAccumulatedPoints, data])

  const handleSortFilter = (key: string, value: string | string[]) => {
    setSortFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  useEffect(() => {
    setPage(DEFAULT_PAGE)
  }, [sortFilter.searchValue])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <ControlBar
          sortFilter={sortFilter}
          handleSortFilter={handleSortFilter}
        />
      </Col>
      <Col span={24}>
        <WrapLoading
          loading={isLoading || isAccumulativePointLoading}
          type="stick"
        >
          <Table
            rowKey="id"
            className="table-border-collapse"
            columns={COLUMN_TEAM}
            dataSource={tableData}
            pagination={false}
          />
        </WrapLoading>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!tableData?.length && (
            <Col>
              <CustomPagination total={total} onChange={setPage} page={page} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default ListTeam
