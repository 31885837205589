import { createContext } from 'react'

import { DEFAULT_PAGE } from 'constant'
import { IPlayerSeason } from 'types/player-leaderboard/player-leaderboard-season.type'

export type PlayerRedemptionsFilter = {
  page: number
  search: string
  filter: Record<string, any>
  seasons: IPlayerSeason[]
  setPage: (page: number) => void
  setSearch: (search: string) => void
  setFilter: (filter: Record<string, any>) => void
}

export const PlayerRedemptionsFilterValue: PlayerRedemptionsFilter = {
  page: DEFAULT_PAGE,
  search: '',
  filter: {},
  seasons: [],
  setPage: () => {},
  setSearch: () => {},
  setFilter: () => {},
}

const RedemptionsFilterContext = createContext(PlayerRedemptionsFilterValue)

export default RedemptionsFilterContext
