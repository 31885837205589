import moment from 'moment'

import { Typography } from 'antd'

import Action from './Action'

import { TIME_FORMAT } from 'constant'

export const COLUMN_TEAM = [
  {
    title: 'Team Name',
    dataIndex: 'teamName',
    render: (teamName: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {teamName}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Current season rank',
    dataIndex: 'currentSeasonRankId',
    render: (currentSeasonRankId: number) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {/*TODO: BE will handle rank */}
        {currentSeasonRankId}st
      </Typography.Text>
    ),
  },
  {
    title: 'Accumulative points',
    dataIndex: 'totalAccumulativePoints',
    render: (totalAccumulativePoints: number) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {totalAccumulativePoints}
      </Typography.Text>
    ),
  },
  {
    title: 'Points this season',
    dataIndex: 'totalPointsCurrentSeason',
    render: (totalPointsCurrentSeason: number) => (
      <Typography.Text>{totalPointsCurrentSeason}</Typography.Text>
    ),
  },
  {
    title: 'Last point contributed',
    dataIndex: 'lastPointContributionTime',
    render: (lastPointContributionTime: string) => (
      <Typography.Text>
        {moment.utc(lastPointContributionTime).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'isDeleted',
    render: (isDeleted: boolean) => (
      <Typography.Text>{isDeleted ? 'Deleted' : 'Active'}</Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: 'id',
    width: 62,
    render: (id: number) => <Action teamId={id} />,
  },
]
