import { useCallback, useEffect, useState } from 'react'

import { ICommunity } from 'types/community.type'
import { CommunityService } from 'services/bountySystem/community'
import { FindCommunityDto } from 'services/bountySystem/community/type'

import { notifyError } from 'helper'
import { useQuery } from 'react-query'

export const useCommunities = ({
  limit,
  offset,
  search,
  communityIds,
}: FindCommunityDto) => {
  const [communities, setCommunities] = useState<ICommunity[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const findCommunities = useCallback(async () => {
    try {
      setLoading(true)
      const { data, total } = await CommunityService.find({
        limit,
        offset,
        search,
        communityIds,
      })
      setCommunities(data)
      setTotal(total)
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }, [limit, offset, search, communityIds])

  useEffect(() => {
    findCommunities()
  }, [findCommunities])

  return { communities, total, isLoading: loading }
}

export const useCommunitiesQuery = (dto: FindCommunityDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['communities', JSON.stringify(dto)],
    queryFn: () => CommunityService.find(dto),
    cacheTime: Infinity,
  })

  return { communities: data?.data, total: data?.total, ...rest }
}

export const useCommunityDetails = (communityId: string) => {
  return useQuery({
    queryKey: ['GET_COMMUNITY_DETAILS', communityId],
    queryFn: () => CommunityService.findById(communityId),
    enabled: !!communityId,
  })
}
