import ListLeaderboardTask from 'components/leaderboard-task/list-task'

import { useAcceler8TaskByGameId } from 'hooks/acceler8/useAcceler8Tasks'
import { useAcceler8TotalParticipantsByProgram } from 'hooks/acceler8/useAcceler8TotalParticipantsByProgram'
import { useAcceler8TotalPointsByProgram } from 'hooks/acceler8/useAcceler8TotalPointsByProgram'

import configs from 'configs'

import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'

type ListLeaderboardTaskProps = {
  seasonId?: number
  gameId?: string
} & LeaderboardTaskProps

function Acceler8ListLeaderboardTask({
  type,
  gameId,
  seasonId = 0,
}: ListLeaderboardTaskProps) {
  const { data } = useAcceler8TaskByGameId({
    communityId: gameId,
    type,
    seasonId,
  })

  const { data: participants } = useAcceler8TotalParticipantsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId,
  })

  const { data: totalPoints } = useAcceler8TotalPointsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId,
  })

  return (
    <ListLeaderboardTask
      isSpace3={gameId === configs.community.SP3CommunityId}
      tasks={data?.tasks}
      participants={participants}
      totalPoints={totalPoints}
    />
  )
}

export default Acceler8ListLeaderboardTask
