import { useMutation } from 'react-query'

import { DailyCheckInService } from 'services/bountySystem/dailyCheckIn'

import { CreateCheckInRewardDto } from 'types/check-in.type'

export const useCreateCheckInReward = () => {
  return useMutation({
    mutationKey: ['CREATE_CHECK_IN_REWARD'],
    mutationFn: (dto: CreateCheckInRewardDto) =>
      DailyCheckInService.createCheckInReward(dto),
  })
}
