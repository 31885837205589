import { Fragment } from 'react'

import { Form } from 'antd'

import InputLabel from 'components/systems/input/InputLabel'
import WeightFormItem from './shared/WeightFormItem'
import TypeFormItem from './shared/TypeFormItem'
import TierFormItem from './shared/TierFormItem'
import AvailableFormItem from './shared/AvailableFormItem'

import { type RewardItemProps } from '.'

function RewardEpItem({ prefixNamePath }: RewardItemProps) {
  return (
    <Fragment>
      <TierFormItem prefixNamePath={prefixNamePath} />

      <Form.Item
        name={[prefixNamePath, 'config', 'amount']}
        rules={[
          {
            required: true,
            message: 'EP must be number',
          },
        ]}
      >
        <InputLabel.Number
          label="EP per Gacha reward"
          placeholder="Enter amount EP"
          type="number"
        />
      </Form.Item>

      {/*Available of EP,Badge and Congrats is Infinite*/}
      <AvailableFormItem
        prefixNamePath={prefixNamePath}
        initialValue={Number.MAX_SAFE_INTEGER}
        hidden
        noStyle
      />

      <WeightFormItem prefixNamePath={prefixNamePath} />

      {/* NOTE: for css dom correctly, type must be placed at the very bottom */}
      <TypeFormItem prefixNamePath={prefixNamePath} />
    </Fragment>
  )
}

export default RewardEpItem
