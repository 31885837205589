import { Fragment, useState } from 'react'

import { Button, Col, Input, Modal, Row, Select, Space } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import RequiredLabel from 'components/systems/requiredSymbol'

import { Role, useAdmins } from 'hooks/community/useUser'

import { bountySystemApi as api } from 'services/base-axios'
import { notifyError } from 'helper'

const { Admin, Operator, User } = Role

const AddNew = () => {
  const [uid, setUid] = useState('')
  const [role, setRole] = useState<Role>()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const { refetch } = useAdmins()

  const handleAddAdmin = async () => {
    try {
      setLoading(true)
      if (!role || !uid) return
      await api.post('/user', { uid, role })
      await refetch()
      setRole(undefined)
      setUid('')
      return setOpen(false)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Button type="primary" onClick={() => setOpen(true)}>
        Add New
      </Button>
      <Modal
        closeIcon={<IonIcon name="close-outline" />}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <RequiredLabel>User ID</RequiredLabel>

              <Input
                value={uid}
                placeholder="Enter user id..."
                onChange={(e) => setUid(e.target.value)}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <RequiredLabel>Role</RequiredLabel>
              <Select
                style={{ width: 120 }}
                onChange={(val) => setRole(val)}
                value={role}
                options={[
                  { value: Admin, label: 'Admin' },
                  { value: Operator, label: 'Operator' },
                  { value: User, label: 'User' },
                ]}
                placeholder="Select role"
              />
            </Space>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button ghost type="ghost" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                disabled={!role || !uid}
                onClick={handleAddAdmin}
                type="primary"
                loading={loading}
              >
                Add New
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default AddNew
