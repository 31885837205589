import { useEffect, useState } from 'react'
import { createGlobalState } from 'react-use'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

import { Col, Row } from 'antd'
import ReportInfoHeader from './header'
import ReportHistory from './reportHistory'
import ReportInfo from './reportInfo'

import { BountyCertData, BountyData } from 'services/bountySystem/bounty'
import { useGetBountyData } from 'hooks/bounty/useBountyData'
import { useBountyCerts } from 'hooks/bounty/useBounties'
import { DEFAULT_PAGE } from 'constant'
import { ReportHistoryFilter } from './reportHistory/type'

export type TableHistoryReportData = BountyCertData & {
  bountyData: BountyData
}

export const useFilterBounty = createGlobalState(ReportHistoryFilter.All)

const SummaryReport = () => {
  const [startDate, setStartDate] = useState(
    moment.utc().subtract(30, 'days').toISOString(),
  )
  const [endDate, setEndDate] = useState(moment.utc().toISOString())
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get('page')) || DEFAULT_PAGE
  const search = searchParams.get('search') || ''
  const [filterBounty] = useFilterBounty()

  const [historyReport, setHistoryReport] = useState<TableHistoryReportData[]>(
    [],
  )

  const { bountyCerts, total } = useBountyCerts({
    startDate,
    endDate,
    page,
    search,
    state: filterBounty,
  })
  const { getBountyData } = useGetBountyData()

  useEffect(() => {
    ;(async () => {
      const newBountyCerts = []
      for (const cert of bountyCerts) {
        const bountyData = await getBountyData(cert.bountyId)
        const nextData = {
          ...cert,
          bountyData: { ...bountyData },
        }
        newBountyCerts.push(nextData)
      }
      setHistoryReport(newBountyCerts)
    })()
  }, [bountyCerts, getBountyData])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <ReportInfoHeader
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          historyReport={historyReport}
        />
      </Col>
      <Col span={24}>
        <ReportInfo endDate={endDate} startDate={startDate} />
      </Col>
      <Col span={24}>
        <ReportHistory
          data={historyReport}
          endDate={endDate}
          startDate={startDate}
          total={total}
        />
      </Col>
    </Row>
  )
}

export default SummaryReport
