import { useMemo, useState } from 'react'

import { Avatar, Space, Typography, Image } from 'antd'

import iconNFT from 'static/images/icon/ico-nft.svg'
import { useBadgeData } from 'hooks/badges/useBadges'
import { OrderData } from 'types/order.type'
import { getTokenLogo } from 'helper'

export enum PriceType {
  CCP = 'CCP',
  TOKEN = 'TOKEN',
  BADGE = 'BADGE',
}

type PriceColumnProps = {
  orderData: OrderData
}

const PriceAvt = ({
  priceType,
  tokenName,
}: {
  priceType?: PriceType
  tokenName: string
}) => {
  const nftType = priceType === PriceType.BADGE
  const fontSize = nftType ? 24 / 7 : 'inherit'

  return (
    <Avatar size={24} src={getTokenLogo(tokenName)} className="reward-avatar">
      <Space
        direction="vertical"
        size={0}
        className="space-middle-icon"
        align="center"
      >
        {nftType && <Image src={iconNFT} preview={false} width={12} />}
        <Typography.Text
          className="gradient-text"
          style={{ fontSize, fontWeight: 700, lineHeight: '115%' }}
        >
          {priceType}
        </Typography.Text>
      </Space>
    </Avatar>
  )
}

const PriceColumn = ({ orderData }: PriceColumnProps) => {
  const [badgeId, setBadgeId] = useState('')
  const { badgeData } = useBadgeData(badgeId)

  const mapPrice = useMemo(() => {
    const { BADGE, CCP, TOKEN } = PriceType
    const { productData, paidCCP, paidToken, paidBadge } = orderData
    const result: { type: PriceType; amount: number; name: string }[] = []
    if (paidCCP.amount)
      result.push({ amount: paidCCP.amount, type: CCP, name: CCP })
    if (paidToken.amount)
      result.push({
        amount: paidToken.amount,
        type: TOKEN,
        name: productData.priceToken.tokenName || '',
      })
    if (paidBadge?.amount) {
      setBadgeId(paidBadge._id)
      result.push({
        amount: 1,
        type: BADGE,
        name: BADGE,
      })
    }

    return result
  }, [orderData])

  return (
    <Space wrap>
      {mapPrice.map(({ amount, type, name }) => (
        <Space size={4} key={type} wrap>
          <PriceAvt priceType={type} tokenName={name} />
          <Typography.Text type="success">{amount}</Typography.Text>
          <Typography.Text
            style={{ textTransform: 'uppercase' }}
            type="success"
          >
            {name === PriceType.BADGE ? badgeData.title : name}
          </Typography.Text>
        </Space>
      ))}
    </Space>
  )
}

export default PriceColumn
