import { useCallback, useEffect, useState } from 'react'

import {
  ChequeData,
  ChequeService,
  ChequeState,
  FilterProps,
} from 'services/bountySystem/cheque'
import { DEFAULT_PAGE } from 'constant'

export const useCheques = ({
  page = DEFAULT_PAGE,
  badgeId,
  state = ChequeState.Redeemed,
}: {
  page?: number
  badgeId?: string
  state?: ChequeState
}) => {
  const [cheques, setCheques] = useState<ChequeData[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchCheques = useCallback(async () => {
    try {
      setLoading(true)
      const { data, total } = await ChequeService.getAll({
        page,
        badgeId,
        state,
      })
      setCheques(data)
      setTotal(total)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [badgeId, page, state])

  useEffect(() => {
    fetchCheques()
  }, [fetchCheques])

  return { cheques, total, loading }
}

export const useCountCheque = ({
  filter,
}: {
  filter: Partial<FilterProps>
}) => {
  const [total, setTotal] = useState(0)

  const fetchTotal = useCallback(async () => {
    try {
      const total = await ChequeService.count({ filter })
      setTotal(total)
    } catch (error) {}
  }, [filter])

  useEffect(() => {
    fetchTotal()
  }, [fetchTotal])

  return { total }
}
