import { useNavigate } from 'react-router-dom'

import { Card, Col, Row, Image, Typography, Dropdown, Divider } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'
import { BadgeData } from 'services/bountySystem/items'
import { MenuInfo } from 'rc-menu/lib/interface'

type CardNftProps = {
  badge: BadgeData
}

const ITEMS = [
  { label: 'View details', key: 'detail' },
  {
    label: <Typography.Text type="danger">Delete</Typography.Text>,
    key: 'delete',
  },
  { label: 'Unpublish', key: 'unPublish' },
]

const CardNft = ({ badge }: CardNftProps) => {
  const navigate = useNavigate()

  const onItemClick = (e: MenuInfo) => {
    e.domEvent.stopPropagation()
    if (e.key === 'detail')
      return navigate(`${ROUTES.NFT_BADGE.INDEX}/${badge._id}`)
  }

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      bordered
      className="card-nft"
      onClick={() => navigate(`${ROUTES.NFT_BADGE.INDEX}/${badge._id}`)}
    >
      <Row>
        <Col span={24}>
          <Image
            preview={false}
            src={badge.thumbnail}
            style={{ aspectRatio: 1 / 1, objectFit: 'cover' }}
          />
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown.Button
              type="text"
              style={{ width: 'auto' }}
              className="delete-dropdown"
              menu={{
                items: ITEMS,
                onClick: onItemClick,
              }}
              icon={
                <div className="delete-dropdown_content">
                  <IonIcon
                    name="ellipsis-vertical"
                    style={{ color: '#FFFFFF' }}
                  />
                </div>
              }
              trigger={['click']}
            />
          </div>
        </Col>
        <Col span={24} style={{ padding: 12 }}>
          <Row gutter={[4, 4]}>
            <Col span={24}>
              <Typography.Text type="success">
                {badge.supply} NFT
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Divider style={{ margin: 0 }} />
            </Col>
            <Col span={24}>
              <Typography.Title ellipsis style={{ color: '#B2BFCB' }} level={5}>
                {badge.title}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default CardNft
