import React, { Fragment } from 'react'

import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd'

import {
  MinusCircleOutlined as MinusCircleIcon,
  PlusCircleOutlined as PlusCircleIcon,
} from '@ant-design/icons'

import InputImage from 'components/systems/uploadPicture/inputImage'
import RequiredLabel from 'components/systems/requiredSymbol'

import { COMMUNITY_PROFILE_DEFAULT_SECTION } from 'constant/community-profile'

import { MediaType } from 'types/community-profile'

import './index.less'

type FormItemSystemFeaturedProps = {
  form: FormInstance
}

function FormItemSystemFeatured({ form }: FormItemSystemFeaturedProps) {
  return (
    <Form.List name="systemFeatures">
      {(fields, { add, remove }) => (
        <Fragment>
          {fields.map((field, index) => (
            <Row
              key={field.name}
              align="top"
              justify="end"
              gutter={8}
              style={{ position: 'relative' }}
            >
              <Col span={4} style={{ marginTop: 10, height: '100%' }}>
                {index === 0 && <RequiredLabel children="System Featured" />}
              </Col>

              <Col span={19}>
                <Row justify="center">
                  <Typography.Text type="warning" style={{ marginBottom: 12 }}>
                    System featured {index + 1}
                  </Typography.Text>
                </Row>

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      label={<RequiredLabel children="Title" />}
                      name={[field.name, 'title']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Title is required!',
                        },
                      ]}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={<RequiredLabel children="Description" />}
                      name={[field.name, 'description']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Description is required!',
                        },
                      ]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Row wrap={false} style={{ width: '100%' }}>
                      <Col span={4}>
                        <RequiredLabel children="Media" />
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name={[field.name, 'media', 'type']}
                          label={<RequiredLabel children="Media type" />}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Type is required!',
                            },
                          ]}
                        >
                          <Select
                            options={[
                              { label: 'Image', value: MediaType.Image },
                              { label: 'Video', value: MediaType.Video },
                            ]}
                            onChange={(value) => {
                              const systemFeatures =
                                form.getFieldValue('systemFeatures')
                              systemFeatures[field.name].media.type = value

                              return form.setFieldsValue({ systemFeatures })
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          name={[field.name, 'media', 'url']}
                          label={<RequiredLabel children="Media url" />}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'URL is required!',
                            },
                          ]}
                          className="label-align-end"
                        >
                          <Fragment>
                            {form.getFieldValue([
                              'systemFeatures',
                              field.name,
                              'media',
                              'type',
                            ]) === MediaType.Image && (
                              <InputImage
                                value={form.getFieldValue([
                                  'systemFeatures',
                                  field.name,
                                  'media',
                                  'url',
                                ])}
                                onChange={(value) => {
                                  const systemFeatures =
                                    form.getFieldValue('systemFeatures')
                                  systemFeatures[field.name].media.url = value

                                  return form.setFieldsValue({ systemFeatures })
                                }}
                              />
                            )}

                            <Input
                              value={form.getFieldValue([
                                'systemFeatures',
                                field.name,
                                'media',
                                'url',
                              ])}
                              onChange={(e) => {
                                const systemFeatures =
                                  form.getFieldValue('systemFeatures')
                                systemFeatures[field.name].media.url =
                                  e.target.value

                                return form.setFieldsValue({ systemFeatures })
                              }}
                              placeholder="Enter url"
                            />
                          </Fragment>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={
                        <RequiredLabel
                          required={false}
                          children="Explore url"
                        />
                      }
                      name={[field.name, 'url']}
                      rules={[
                        {
                          type: 'url',
                          message: 'Please enter a valid url!',
                        },
                      ]}
                    >
                      <Input placeholder="Explore url" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={1} style={{ position: 'unset' }}>
                <Row justify="end" align="middle">
                  <MinusCircleIcon
                    style={{
                      color: 'red',
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      fontSize: 16,
                    }}
                    onClick={() => remove(field.name)}
                  />
                </Row>
              </Col>
            </Row>
          ))}
          <Form.Item wrapperCol={{ span: 20 }}>
            <Row justify="center" style={{ width: '100%' }}>
              <Button
                type="dashed"
                onClick={() => {
                  add({
                    ...COMMUNITY_PROFILE_DEFAULT_SECTION.systemFeatures[0],
                  })
                }}
                icon={<PlusCircleIcon />}
              >
                Add System Featured
              </Button>
            </Row>
          </Form.Item>
        </Fragment>
      )}
    </Form.List>
  )
}

export default FormItemSystemFeatured
