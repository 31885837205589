import { useParams } from 'react-router-dom'

import { Col, Form, Row, Typography } from 'antd'

import FormRedemption from './FormRedemption'

import './index.less'

function CreateUpdateRedemption() {
  const [form] = Form.useForm()
  const redemptionId = useParams()?.redemptionId ?? ''

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={1} type="success">
          {!!redemptionId ? 'Edit Redemption' : 'Create New Redemption'}
        </Typography.Title>
      </Col>

      <Col span={24}>
        <FormRedemption form={form} redemptionId={redemptionId} />
      </Col>
    </Row>
  )
}

export default CreateUpdateRedemption
