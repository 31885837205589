import { bountySystemApi as api } from 'services/base-axios'

import {
  CreateFederatedApiDto,
  IFederatedApi,
  UpdateFederatedAPIDto,
} from './type'

const CONTROLLER = '/admin/federated-api'

class FederatedApi {
  async create(dto: CreateFederatedApiDto) {
    return api.post<IFederatedApi>(CONTROLLER, dto)
  }

  async getById(federatedAPIId: string) {
    return api.get<IFederatedApi>(CONTROLLER + `/${federatedAPIId}`)
  }

  async update(federatedAPIId: string, dto: UpdateFederatedAPIDto) {
    return api.patch<IFederatedApi>(CONTROLLER + `/${federatedAPIId}`, dto)
  }
}

export const FederatedApiService = new FederatedApi()
