import { Form } from 'antd'

import InputLabel from 'components/systems/input/InputLabel'

import { type RewardItemProps } from '..'

function WeightFormItem({ prefixNamePath }: RewardItemProps) {
  return (
    <Form.Item
      name={[prefixNamePath, 'weight']}
      rules={[
        {
          validator: (_, value) => {
            if (!value && value !== 0) {
              return Promise.reject(new Error('Weight is required'))
            }

            if (value < 0 || value > 100) {
              return Promise.reject(
                new Error('Weight must be between 0 and 100'),
              )
            }

            return Promise.resolve()
          },
        },
      ]}
    >
      <InputLabel.Number
        label="Weight"
        placeholder="Enter the weight from 0% -> 100%"
        type="number"
        min={0}
        max={100}
      />
    </Form.Item>
  )
}

export default WeightFormItem
