import { ChainID } from 'constant'

export interface IWeb3Item {
  itemId: string
  chainId: ChainID
  blockData: any
  web3Type: Web3Type
}

export enum Web3Type {
  NFT = 'WEB3_TYPE::NFT',
}
