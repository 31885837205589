import { useNavigate } from 'react-router-dom'

import { Card, Col, Row, Image, Typography, Dropdown, Divider } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'
import { MenuInfo } from 'rc-menu/lib/interface'
import { ICampaign } from 'types/campaign.type'

import noImage from 'static/images/system/nodata.svg'
import { notifyError } from 'helper'

type CampaignCardProps = {
  campaign: ICampaign
}

const ITEMS = [
  { label: 'Edit', key: 'edit' },
  {
    label: <Typography.Text type="danger">Delete</Typography.Text>,
    key: 'delete',
  },
]

const CampaignCard = ({ campaign }: CampaignCardProps) => {
  const navigate = useNavigate()

  const onItemClick = (e: MenuInfo) => {
    e.domEvent.stopPropagation()

    switch (e.key) {
      case 'edit':
        return navigate(`${ROUTES.CAMPAIGN.EDIT_CAMPAIGN}/${campaign._id}`)
      case 'delete':
        return notifyError('Not support yet!')
      default:
        return
    }
  }

  return (
    <Card bodyStyle={{ padding: 0 }} bordered className="campaign-card">
      <Row>
        <Col span={24}>
          <Image
            preview={false}
            src={campaign.thumbnail}
            style={{ aspectRatio: 1, objectFit: 'cover' }}
            fallback={noImage}
          />
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown.Button
              type="text"
              style={{ width: 'auto' }}
              className="delete-dropdown"
              menu={{
                items: ITEMS,
                onClick: onItemClick,
              }}
              icon={
                <div className="delete-dropdown_content">
                  <IonIcon
                    name="ellipsis-vertical"
                    style={{ color: '#FFFFFF' }}
                  />
                </div>
              }
              trigger={['click']}
            />
          </div>
        </Col>
        <Col span={24} className="campaign-card_title">
          <Row gutter={[4, 4]}>
            <Col span={24}>
              <Divider style={{ margin: 0 }} />
            </Col>
            <Col span={24}>
              <Typography.Title
                ellipsis={{ rows: 2, tooltip: campaign.title }}
                level={5}
                type="success"
              >
                {campaign.title}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default CampaignCard
