import { useMutation } from 'react-query'

import { CampaignService } from 'services/bountySystem/campaign.service'

type UpdateBountiesOfCampaignParams = {
  campaignId: string
  bountyIds: string[]
}

export const useUpdateBountiesOfCampaign = () => {
  return useMutation({
    mutationKey: ['UPDATE_BOUNTIES_OF_CAMPAIGN'],
    mutationFn: ({ campaignId, bountyIds }: UpdateBountiesOfCampaignParams) =>
      CampaignService.updateBountiesOfCampaign(campaignId, bountyIds),
  })
}
