import { Form, type FormItemProps, type InputNumberProps } from 'antd'

import InputLabel from 'components/systems/input/InputLabel'

import { type RewardItemProps } from '..'

type AvailableFormItemProps = RewardItemProps<
  Pick<FormItemProps, 'noStyle' | 'initialValue' | 'hidden'> & {
    onChange?: InputNumberProps['onChange']
  }
>

function AvailableFormItem({
  prefixNamePath,
  onChange,
  ...props
}: AvailableFormItemProps) {
  return (
    <Form.Item name={[prefixNamePath, 'available']} {...props}>
      <InputLabel.Number
        label="Available Rewards"
        placeholder="Number of available rewards"
        type="number"
        min={0}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default AvailableFormItem
