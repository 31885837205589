import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { FindLatestAcceler8SeasonsDto } from 'types/acceler8/acceler8-season.type'

export const useLatestAcceler8Seasons = (dto: FindLatestAcceler8SeasonsDto) => {
  const { data, ...rest } = useQuery({
    queryFn: () => Acceler8Service.findLatestSeasons(dto),
    queryKey: ['GET_LATEST_ACCELER8_SEASONS', dto],
  })
  return {
    ...rest,
    data: data ?? [],
  }
}
