import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TokenStandard } from 'types/token.type'

/**
 * Interface & Utility
 */

export type UserNFTData = {
  name: string
  thumbnail: string
  collection: string
  address: string
  uniqueAddress: string
  ownerOf: string
  image: string
  amount: string
  tokenId: number
  tokenAddress: string
  tokenStandard: TokenStandard.ERC721 | TokenStandard.ERC1155
  description: string
  contractType: string
  collectionName: string
  symbol: string
  web3ItemId: string
  attributes?: { attributes: string; value: string }[]
}

export type UserNFTState = {
  userNFTs: Record<string, UserNFTData>
  cursor: string
}

/**
 * Store constructor
 */

const NAME = 'nft'
const initialState: UserNFTState = {
  userNFTs: {},
  cursor: '',
}

/**
 * Actions
 */

export const getUserNFTs = createAsyncThunk<
  UserNFTState,
  { bulk: UserNFTState },
  { state: any }
>(`${NAME}/getUserNFTs`, async ({ bulk }) => {
  return bulk
})

export const updateUserNFTs = createAsyncThunk<
  UserNFTState,
  { userNfts: UserNFTState },
  { state: any }
>(`${NAME}/updateUserNFTs`, async ({ userNfts }, { getState }) => {
  const { nft } = getState()
  if (!userNfts) throw new Error('Nothing to update!')
  return {
    cursor: userNfts.cursor,
    userNFTs: { ...nft.userNFTs, ...userNfts.userNFTs },
  }
})

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(getUserNFTs.fulfilled, (_, { payload }) => payload)
      .addCase(
        updateUserNFTs.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      ),
})

export default slice.reducer
