import { Avatar, Button, Space } from 'antd'

const Socials = () => {
  return (
    <Space size={24}>
      {[
        'https://img.icons8.com/ios/512/telegram.png',
        'https://img.icons8.com/ios-filled/512/discord-logo.png',
        'https://img.icons8.com/ios/512/facebook-new.png',
        'https://img.icons8.com/ios/512/twitter-circled.png',
        'https://img.icons8.com/sf-ultralight/512/reddit.png',
      ].map((src) => (
        <Button
          type="text"
          icon={<Avatar src={src} style={{ background: '#fff' }} />}
          key={src}
        />
      ))}
    </Space>
  )
}

export default Socials
