import { Typography } from 'antd'
import { BountyCategory } from 'constant/bounty'
import { CSSProperties, useMemo } from 'react'

type TagCategoryProps = { category: BountyCategory; style?: CSSProperties }

const TagCategory = ({ category, style }: TagCategoryProps) => {
  const categoryName = useMemo(() => {
    switch (category) {
      case BountyCategory.DApp:
        return 'System'
      case BountyCategory.Discord:
        return 'Discord'
      default:
        return ''
    }
  }, [category])

  return <Typography.Text style={style}>{categoryName}</Typography.Text>
}

export default TagCategory
