import { useContext, useMemo } from 'react'

import { Select, Typography } from 'antd'

import DropdownCustom from './dropdownCustom'

import { ApplicationFrameContext } from '../applicationFrame/ApplicationFrameContext'

import { ApplicationType } from 'types/multiplier.type'

type ApplicationSelectProps = {
  applications: { name: string; id: string }[]
}

export default function ApplicationSelect({
  applications,
}: ApplicationSelectProps) {
  const { applicationType, setSelectedApplications, selectedApplications } =
    useContext(ApplicationFrameContext)

  const placeholder = useMemo(() => {
    switch (applicationType) {
      case ApplicationType.Bounty:
        return 'Mission'
      default:
        return 'Application'
    }
  }, [applicationType])

  return (
    <Select
      showArrow={true}
      showSearch={false}
      mode="multiple"
      style={{ width: '100%' }}
      dropdownRender={(menu) => <DropdownCustom menu={menu} />}
      onChange={(val) => setSelectedApplications(val)}
      tagRender={() => <></>}
      placeholder={placeholder}
      value={selectedApplications}
    >
      {applications.map((elm) => (
        <Select.Option value={elm.id} label={elm.name}>
          <Typography.Text
            style={{ maxWidth: 260 }}
            ellipsis={{ tooltip: `${elm.id} - ${elm.name}` }}
          >
            {elm.id} - {elm.name}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  )
}
