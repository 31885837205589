import { Avatar, Card, Col, Row, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import { BadgeData } from 'services/bountySystem/items'

import IMAGE_DEFAULT from 'static/images/system/nft.jpeg'

export type CardNFTItemProps = {
  active?: boolean
  onSelect?: (id: string) => void
  badgeData: BadgeData
}
const CardNFTItem = ({
  badgeData,
  active = false,
  onSelect = () => {},
}: CardNFTItemProps) => {
  const borderColor = active ? '#42BAB0' : '#244845'
  const background = active ? '#242F30' : 'transparent'

  return (
    <Card
      style={{
        borderRadius: 4,
        borderColor,
        cursor: 'pointer',
        background,
      }}
      bodyStyle={{ padding: 10 }}
      onClick={() => onSelect(badgeData._id)}
    >
      <Row gutter={[24, 24]} align="middle">
        <Col flex="auto">
          <Row gutter={[12, 12]} wrap={false}>
            <Col>
              <Avatar
                src={badgeData.thumbnail || IMAGE_DEFAULT}
                shape="square"
                size={50}
              />
            </Col>
            <Col flex="auto">
              <Typography.Title level={5} ellipsis={{ rows: 1 }}>
                {badgeData.title}
              </Typography.Title>
              {/* <Space>
                <Typography.Text type="success">#125438</Typography.Text>
                <Typography.Text>BEP721</Typography.Text>
              </Space> */}
            </Col>
          </Row>
        </Col>

        <Col style={{ minWidth: 38 }}>
          {active && (
            <IonIcon style={{ color: '#42BAB0' }} name="checkmark-circle" />
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default CardNFTItem
