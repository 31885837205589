import React, { useMemo } from 'react'
import { Col, Row, Select, Typography } from 'antd'

type ProductAttributesProps = {
  value: string[]
  options: string[]
  onChange: (value: string[]) => void
  title?: string
}

type OptionValue = { value: string; label: string }

const ProductAttributes: React.FC<ProductAttributesProps> = ({
  options,
  value,
  title,
  onChange,
}) => {
  const wrapOptions: OptionValue[] = useMemo(() => {
    return options.map((elm) => {
      return { value: elm, label: elm }
    })
  }, [options])

  const wrapValue: OptionValue[] = useMemo(
    () =>
      value.map((elm: string) => {
        return { value: elm, label: elm }
      }),
    [value],
  )

  return (
    <Row gutter={[4, 4]}>
      {title && (
        <Col span={24}>
          <Typography.Text type="secondary">{title}</Typography.Text>
        </Col>
      )}
      <Col span={24}>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          onChange={onChange}
          tokenSeparators={[',']}
          options={wrapOptions}
          value={wrapValue as any}
          placeholder="Select options"
        />
      </Col>
    </Row>
  )
}

export default ProductAttributes
