import { useState } from 'react'
import { useSearchKey } from '../index'

import { Col, Empty, Row, Typography } from 'antd'
import CardNft from './cardNft'
import CustomPagination from 'components/systems/pagination'
import SpaceBetween from 'components/systems/spaceBetween'
import { WrapLoading } from 'components/systems/loading'

import { useBadges } from 'hooks/badges/useBadges'
import { DEFAULT_PAGE } from 'constant'

const PAGE_SIZE = 18

const ListNFT = () => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [search] = useSearchKey()

  const { badges, total, loading } = useBadges({
    page: currentPage,
    pageSize: PAGE_SIZE,
    search,
  })

  return (
    <WrapLoading loading={loading} type="stick" size={40}>
      {badges.length ? (
        <Row gutter={[12, 12]}>
          {badges.map((badge) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={4} key={badge._id}>
              <CardNft badge={badge} />
            </Col>
          ))}
          <Col span={24} />
          <Col span={24}>
            <SpaceBetween
              floatContent={
                <CustomPagination
                  pageSize={PAGE_SIZE}
                  onChange={setCurrentPage}
                  total={total}
                />
              }
            >
              <Typography.Text type="success">Total: {total}</Typography.Text>
            </SpaceBetween>
          </Col>
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Badge" />
      )}
    </WrapLoading>
  )
}

export default ListNFT
