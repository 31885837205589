import { Fragment, ReactNode } from 'react'

import DefaultLoader from './defaultLoader'
import PageLoader from './pageLoader'
import StickLoader from './stickLoader'

import './index.less'

type LoadingType = 'default' | 'stick' | 'page'

type LoadingInnerProps = {
  type: LoadingType
  loading?: boolean
} & Required<LoadingProps>
const LoadingInner = ({
  loading = true,
  size,
  type,
  dotSize,
}: LoadingInnerProps) => {
  if (!loading) return <Fragment />

  return (
    <div className="sp3-inner-loader">
      {(() => {
        switch (type) {
          case 'stick':
            return <StickLoader size={size} dotSize={dotSize} />
          case 'page':
            return <PageLoader size={size} dotSize={dotSize} />
          default:
            return <DefaultLoader size={size} dotSize={dotSize} />
        }
      })()}
    </div>
  )
}

export type LoadingProps = {
  size?: number
  dotSize?: number
}
type LoadingContainerProps = {
  children?: ReactNode
  loading: boolean
  type?: LoadingType
}
const Loading = ({
  size = 56,
  dotSize = 10,
  loading,
  children,
  type = 'default',
}: LoadingProps & LoadingContainerProps) => {
  if (!loading) return <Fragment>{children}</Fragment>
  return (
    <div className="sp3-loader-container">
      <LoadingInner dotSize={dotSize} size={size} type={type} />
    </div>
  )
}

export default Loading

export const WrapLoading = ({
  size = 56,
  dotSize = 10,
  loading,
  children,
  type = 'default',
}: LoadingProps & LoadingContainerProps) => {
  return (
    <div className="sp3-loader-container">
      <LoadingInner
        loading={loading}
        size={size}
        dotSize={dotSize}
        type={type}
      />
      {children}
    </div>
  )
}
