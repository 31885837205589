export enum CommunityState {
  Active = 'COMMUNITY_STATE:ACTIVE',
}

export enum CommunityStatus {
  Live = 'COMMUNITY_STATUS:LIVE',
  Beta = 'COMMUNITY_STATUS:BETA',
  Develop = 'COMMUNITY_STATUS:DEVELOP',
}

export enum CommunityChainID {
  Solana = 'CHAIN_ID:SOLANA',
  BSC = 'CHAIN_ID:BSC',
  Ethereum = 'CHAIN_ID:ETHEREUM',
  Polygon = 'CHAIN_ID:POLYGON',
  Klaytn = 'CHAIN_ID:KLAYTN',
  BORA = 'CHAIN_ID:BORA',
  ISKRA = 'CHAIN_ID:ISKRA',
  Arbitrum = 'CHAIN_ID:ARBITRUM',
  Avalanche = 'CHAIN_ID:AVALANCHE',
  Lootchain = 'CHAIN_ID:LOOTCHAIN',
  Ronin = 'CHAIN_ID:RONIN',
  Viction = 'CHAIN_ID:VICTION',
  Base = 'CHAIN_ID:BASE',
  A8 = 'CHAIN_ID:A8',
  Optimism = 'CHAIN_ID:OPTIMISM',
  Bitcoin = 'CHAIN_ID:BITCOIN',
  Linea = 'CHAIN_ID:LINEA',
  Zksync = 'CHAIN_ID:ZKSYNC',
  Starknet = 'CHAIN_ID:STARKNET',
  Mint = 'CHAIN_ID:MINT',
  Kroma = 'CHAIN_ID:KROMA',
}

export const CommunityChainLabel: Record<CommunityChainID, string> = {
  [CommunityChainID.Solana]: 'SOL',
  [CommunityChainID.BSC]: 'BNB Chain',
  [CommunityChainID.Ethereum]: 'Ethereum',
  [CommunityChainID.Polygon]: 'Polygon',
  [CommunityChainID.Klaytn]: 'Klaytn',
  [CommunityChainID.BORA]: 'BORA',
  [CommunityChainID.ISKRA]: 'ISKRA',
  [CommunityChainID.Arbitrum]: 'Arbitrum',
  [CommunityChainID.Avalanche]: 'Avalanche',
  [CommunityChainID.Lootchain]: 'Loot chain',
  [CommunityChainID.Ronin]: 'Ronin',
  [CommunityChainID.Viction]: 'Viction',
  [CommunityChainID.Base]: 'BASE',
  [CommunityChainID.A8]: 'Ancient8',
  [CommunityChainID.Optimism]: 'Optimism',
  [CommunityChainID.Bitcoin]: 'Bitcoin',
  [CommunityChainID.Linea]: 'Linea',
  [CommunityChainID.Zksync]: 'ZkSync',
  [CommunityChainID.Starknet]: 'Starknet',
  [CommunityChainID.Mint]: 'Mint',
  [CommunityChainID.Kroma]: 'Kroma',
}

export interface ICommunity {
  _id: string

  title: string

  description: string

  ccpTimeMark: string

  ccpMergeMonths: number

  ccpLifeMonths: number

  state: CommunityState

  discordLink: string

  telegramLink: string

  twitterLink: string

  youtubeLink: string

  facebookLink: string

  website: string

  discordId: string

  thumbnail: string

  logo: string

  coverImage: string

  status: CommunityStatus

  genre: string[]

  blockchain: CommunityChainID[]

  slug: string
}

export interface CreateCommunityDto {
  title: string

  description: string

  discordLink?: string

  telegramLink?: string

  twitterLink?: string

  youtubeLink?: string

  facebookLink?: string

  website?: string

  ccpTimeMark: string

  ccpMergeMonths: number

  ccpLifeMonths: number

  state: CommunityState

  discordId?: string

  thumbnail?: string

  logo?: string

  coverImage?: string

  status?: CommunityStatus

  genre?: string[]

  blockchain?: CommunityChainID[]

  slug?: string
}

export type UpdateCommunity = CreateCommunityDto
