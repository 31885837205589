import { Select } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { OrderStatus } from 'types/order.type'

type FilterProps = {
  status?: OrderStatus
  onSelect?: React.Dispatch<React.SetStateAction<OrderStatus | undefined>>
}

const FILTER_ITEMS = [
  { label: 'Show All', value: '' },
  { label: 'Completed', value: OrderStatus.Done },
  { label: 'Fail', value: OrderStatus.Fail },
  { label: 'In Progress', value: OrderStatus.Sending },
]

const Filter = ({ status, onSelect }: FilterProps) => {
  return (
    <Select
      value={status === undefined ? ('' as OrderStatus) : status}
      options={FILTER_ITEMS}
      menuItemSelectedIcon={<IonIcon name="checkmark-outline" />}
      suffixIcon={
        <IonIcon name="filter-outline" style={{ color: '#42BAB0' }} />
      }
      className="dropdown-btn dropdown-filter"
      onChange={onSelect}
    />
  )
}

export default Filter
