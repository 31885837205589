import { Button, Col, Image, Row, Typography } from 'antd'

import { CollectionData } from 'services/marketplace/collection'
import { WrapLoading } from 'components/systems/loading'
import IonIcon from 'components/systems/ionIcon'
import CollectionInfoCard from 'view/admin/marketplace/collectionManagement/collectionDetails/collectionInfoCard'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'constant/routes'
import { Fragment } from 'react'

type CollectionViewProps = {
  loading: boolean
  collection: CollectionData
}
const CollectionView = ({ collection, loading }: CollectionViewProps) => {
  const navigate = useNavigate()

  if (!collection) return <Fragment />
  const socials: Record<string, string> = {
    website: collection.socials.Website || '',
    telegram: collection.socials.Telegram || '',
    twitter: collection.socials.Twitter || '',
    discord: collection.socials.Discord || '',
  }

  const onEditCollection = (id: string) => {
    navigate(`${ROUTES.REWARDS_CENTER.EDIT_COLLECTION_MANAGEMENT}/${id}`)
  }

  return (
    <WrapLoading loading={loading}>
      <Row gutter={[48, 48]}>
        <Col>
          <Row gutter={[48, 48]} wrap={false}>
            <Col>
              <Image
                src={collection.thumbnail}
                width={316}
                height={316}
                style={{ objectFit: 'cover', borderRadius: 10 }}
              />
            </Col>
            <Col>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Typography.Title level={3} color="#FFFFFF">
                        {collection.title}
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[24, 24]}>
                        {Object.keys(socials).map((name, index) => (
                          <Col key={index}>
                            <Button
                              onClick={() => {
                                window.open(socials[name.toLowerCase()] || '')
                              }}
                              type="text"
                              icon={
                                <IonIcon
                                  name={name.toLowerCase()}
                                  style={{ fontSize: 32 }}
                                />
                              }
                              key={name.toLowerCase()}
                              disabled={!socials[name.toLowerCase()]}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col style={{ textAlign: 'justify' }}>
                      <Typography.Text>
                        {collection.description}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Typography.Text style={{ color: '#B6B6B6' }}>
                        INFO
                      </Typography.Text>
                    </Col>
                    <Col span={12}>
                      <CollectionInfoCard
                        address={collection.collectionAddress}
                        chainId={collection.chainId}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ width: 129, borderRadius: 2 }}
            onClick={() => onEditCollection(collection._id)}
          >
            Edit
          </Button>
        </Col>
      </Row>
    </WrapLoading>
  )
}

export default CollectionView
