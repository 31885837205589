import { useCallback, useState } from 'react'
import {
  ProductSKUData,
  ProductSKUService,
} from 'services/marketplace/productSKU'

export const DUMMY_PRODUCT_SKU_DATA: ProductSKUData = {
  _id: '',
  productId: '',
  thumbnail: '',
  totalAvailable: 0,
  available: 0,
  variationId: '',
  variations: {},
}

const useHandleProductSKU = () => {
  const [loading, setLoading] = useState(false)

  const getSKUByProductId = useCallback(async (productId: string) => {
    try {
      setLoading(true)
      const { data, total } = await ProductSKUService.getByProductId(productId)
      return { data, total }
    } catch (err) {
      return { data: [], total: 0 }
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, getSKUByProductId }
}

export default useHandleProductSKU
