import { acceler8TaskApi as api } from 'services/base-axios'
import { Acceler8Service } from 'services/acceler8'

import {
  Acceler8TaskGroupByGame,
  CreateAcceler8TaskDto,
  FindAcceler8TaskDto,
  AC8TaskType,
  UpdateAcceler8TaskDto,
  IAcceler8Task,
} from 'types/acceler8/acceler8-task.type'
import {
  CreateFederatedAPIDto,
  IAcceler8FederatedAPI,
  UpdateFederatedAPIDto,
} from 'types/acceler8/acceler8-federated-api.type'
import { TriggerSummarySeasonDto } from 'types/general-leaderboard/general-leaderboard.type'
import { GetJobsDto, IJob } from 'types/acceler8/acceker8-job.type'
import { Data } from 'types'

const CONTROLLER = '/admin/task'
const JOB_CONTROLLER = '/admin/job'
const SUMMARY_CONTROLLER = '/admin/season/summary'

class Acceler8Task {
  async findAll(dto: FindAcceler8TaskDto): Promise<Acceler8TaskGroupByGame[]> {
    const { data } = await api.get(CONTROLLER, { params: dto })
    return data
  }

  async getTaskById(taskId?: string) {
    const { data } = await api
      .get(`${CONTROLLER}/${taskId}`)
      .then(async (data) => {
        const program = await Acceler8Service.getProgram(data.data.programId)
        const result = {
          ...data,
          data: {
            ...data.data,
            points: program.points,
          },
        }
        if (data.data.type === AC8TaskType.FederatedApi) {
          const { headers, pattern } = await this.getFederatedAPI(
            data.data.configs?.federatedApiId,
          )
          const newHeaders = headers
            ? Object.keys(headers).map((headerKey) => ({
                key: headerKey,
                value: headers[headerKey],
              }))
            : []
          result.data.configs = {
            meta: { endpoint: pattern, headers: newHeaders },
            ...result.data.configs,
          }
        }
        return result
      })
    return data
  }

  async getTasksByProgramIds(programIds: number[]): Promise<IAcceler8Task[]> {
    const { data } = await api.get(`${CONTROLLER}/by-program-ids`, {
      params: { programIds },
    })
    return data
  }

  async createTask(dto: CreateAcceler8TaskDto) {
    const { data } = await api.post(CONTROLLER, dto)
    return data
  }

  async updateTask(taskId: string, dto: UpdateAcceler8TaskDto) {
    const { data } = await api.put(`${CONTROLLER}/${taskId}`, dto)
    return data
  }

  async getFederatedAPI(federatedAPIId?: string) {
    const { data } = await api
      .get(`/federated-api/${federatedAPIId}`)
      .then(async (data) => {
        return {
          ...data,
          data: {
            ...data.data,
          },
        }
      })
    return data
  }

  async createFederatedAPI(
    dto: CreateFederatedAPIDto,
  ): Promise<IAcceler8FederatedAPI> {
    const { data } = await api.post(`/federated-api`, dto)
    return data
  }

  async updateFederatedAPI(federatedAPIId: string, dto: UpdateFederatedAPIDto) {
    const { data } = await api.patch(`/federated-api/${federatedAPIId}`, dto)
    return data
  }

  async summarySeason(dto: TriggerSummarySeasonDto) {
    const { data } = await api.post(`${SUMMARY_CONTROLLER}`, dto)
    return data
  }

  async getJobs(dto: GetJobsDto): Promise<Data<IJob>[]> {
    const { data } = await api.get(JOB_CONTROLLER, { params: dto })
    return data
  }

  async pauseJob(jobId: string) {
    const { data } = await api.patch(`${JOB_CONTROLLER}/${jobId}/pause`)
    return data
  }

  async resumeJob(jobId: string) {
    const { data } = await api.patch(`${JOB_CONTROLLER}/${jobId}/resume`)
    return data
  }

  async cancelJob(jobId: string) {
    const { data } = await api.patch(`${JOB_CONTROLLER}/${jobId}/cancel`)
    return data
  }
}

export const Acceler8TaskService = new Acceler8Task()
