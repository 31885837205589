import { useCallback, useMemo } from 'react'
import moment from 'moment'
import { generatePath, useNavigate } from 'react-router-dom'

import { Button, ButtonProps, Popconfirm, Space, Typography } from 'antd'

import { useUpdateAcceler8TaskStatus } from 'hooks/acceler8/useUpdateAcceler8Task'
import { useAcceler8Tasks } from 'hooks/acceler8/useAcceler8Tasks'

import { ACCELER8_DATE_TIME_FORMAT } from 'constant'
import { ROUTES } from 'constant/routes'

import {
  IAcceler8Task,
  TaskSpace3Configs,
  TaskStatus,
} from 'types/acceler8/acceler8-task.type'
import { ColumnsType } from 'antd/es/table'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

type ButtonActionProps = {
  task: IAcceler8Task
  statusToChange: TaskStatus
  label: string
} & ButtonProps

function ButtonNavigate({ className, href, children, ...props }: ButtonProps) {
  const navigate = useNavigate()
  return (
    <Button
      className={className}
      onClick={() => {
        href && navigate(href)
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

function ButtonAction({
  task,
  className,
  children,
  label,
  statusToChange,
  ...props
}: ButtonActionProps) {
  const { mutateAsync: updateTaskStatus } = useUpdateAcceler8TaskStatus()

  const { refetch } = useAcceler8Tasks({ programType: task.programType })

  const isDisabled = useMemo(() => {
    if (!task) return true
    return (
      task.status === TaskStatus.Inactive &&
      statusToChange === TaskStatus.Expired
    )
  }, [task, statusToChange])

  const isReactive = useMemo(() => {
    if (!task) return false
    if (task.status === TaskStatus.Inactive) return true
    return task.status === statusToChange
  }, [task, statusToChange])

  const handleChangeStatus = useCallback(
    async (status: TaskStatus) => {
      if (!task) return

      await updateTaskStatus({
        taskId: task._id,
        status,
      })

      await refetch()
    },
    [task, updateTaskStatus, refetch],
  )

  if (isDisabled) return null

  if (!isReactive)
    return (
      <Popconfirm
        title="Confirm your action?"
        okText="Yes"
        cancelText="No"
        placement="topRight"
        onConfirm={() => handleChangeStatus(statusToChange)}
      >
        <Button {...props} type="primary" className={className}>
          <Typography.Text type="danger">{label}</Typography.Text>
        </Button>
      </Popconfirm>
    )

  return (
    <Popconfirm
      title="Confirm your action?"
      okText="Yes"
      cancelText="No"
      placement="topRight"
      onConfirm={() => handleChangeStatus(TaskStatus.Active)}
    >
      <Button {...props} type="primary" className={className}>
        <Typography.Text type="success">Reactive</Typography.Text>
      </Button>
    </Popconfirm>
  )
}

export type Acceler8TaskBoard = {
  totalPoints: number
} & IAcceler8Task

export const COLUMN_TASK: ColumnsType<Acceler8TaskBoard> = [
  {
    title: 'Name',
    dataIndex: 'title',
    render: (title: string) => <Typography.Text>{title}</Typography.Text>,
  },
  {
    title: 'Point',
    dataIndex: 'points',
    render: (point: number) => <Typography.Text>{point}</Typography.Text>,
    width: 80,
    align: 'center',
  },
  {
    title: 'Timeline',
    dataIndex: 'timeline',
    render: (_, { startedAt, endedAt }) => (
      <Typography.Text>
        {moment.utc(startedAt).format(ACCELER8_DATE_TIME_FORMAT)} (UTC) -{' '}
        {moment.utc(endedAt).format(ACCELER8_DATE_TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
    width: 240,
    align: 'center',
  },
  {
    title: 'Total Point',
    dataIndex: 'totalPoints',
    render: (point) => <Typography.Text>{point ?? 0}</Typography.Text>,
    align: 'center',
    width: 120,
  },
  {
    title: 'No. of Participants',
    dataIndex: 'participants',
    render: (participants) => (
      <Typography.Text>{participants ?? 0}</Typography.Text>
    ),
    width: 180,
    align: 'center',
  },
  {
    title: '',
    dataIndex: '',
    // TODO: implement later
    render: (_, record) => {
      const routeEdit =
        record.programType === ProgramType.Team
          ? ROUTES.ACCELER8.EDIT_GAME_TASK
          : ROUTES.PLAYER_LEADERBOARD.EDIT_GAME_TASK
      return (
        <Space direction="vertical" className="column-space-action">
          <ButtonNavigate
            className="btn-action-warning"
            href={generatePath(routeEdit, {
              taskId: record._id,
            })}
          >
            <Typography.Text type="warning">Edit</Typography.Text>
          </ButtonNavigate>
          <ButtonAction
            task={record}
            statusToChange={TaskStatus.Expired}
            className="btn-action-danger"
            label="Expire"
          />
          <ButtonAction
            task={record}
            statusToChange={TaskStatus.Inactive}
            className="btn-action-danger"
            label="Inactive"
          />
        </Space>
      )
    },
    width: 50,
    align: 'center',
  },
]

export const COLUMN_TASK_SPACE3: ColumnsType<Acceler8TaskBoard> = [
  {
    title: 'Name',
    dataIndex: 'title',
    render: (_, record) => {
      const configs = record.configs as TaskSpace3Configs
      return (
        <Space direction="vertical">
          <Typography.Text strong>{record.title}</Typography.Text>
          <Typography.Text type="success">{configs.url}</Typography.Text>
        </Space>
      )
    },
  },
  ...COLUMN_TASK.slice(1, -1),
  {
    title: '',
    dataIndex: '',
    // TODO: implement later
    render: (_, record) => {
      const routeEdit =
        record.programType === ProgramType.Team
          ? ROUTES.ACCELER8.EDIT_SPACE3_TASK
          : ROUTES.PLAYER_LEADERBOARD.EDIT_SPACE3_TASK
      return (
        <Space direction="vertical" className="column-space-action">
          <ButtonNavigate
            className="btn-action-warning"
            href={generatePath(routeEdit, {
              taskId: record._id,
            })}
          >
            <Typography.Text type="warning">Edit</Typography.Text>
          </ButtonNavigate>
          <ButtonAction
            task={record}
            statusToChange={TaskStatus.Expired}
            className="btn-action-danger"
            label="Expire"
          />
          <ButtonAction
            task={record}
            statusToChange={TaskStatus.Inactive}
            className="btn-action-danger"
            label="Inactive"
          />
        </Space>
      )
    },
    width: 50,
    align: 'center',
  },
]
