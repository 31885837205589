import { useMutation } from 'react-query'

import { WhitelistService } from 'services/marketplace/whitelist'

import type { DeleteUserWhitelistDto } from 'types/whitelist.type'

export const useDeleteUserWhitelist = () => {
  return useMutation({
    mutationKey: ['DELETE_USER_WHITELIST'],
    mutationFn: (payload: DeleteUserWhitelistDto) =>
      WhitelistService.deleteUserWhitelist(payload),
  })
}
