import { useQuery } from 'react-query'

import { GachaService } from 'services/marketplace/gacha'

export const useGachaDetail = (gachaId: string) => {
  return useQuery({
    queryKey: ['get-gacha-detail', gachaId],
    queryFn: async () => {
      const [baseInfo, { data: rewards }] = await Promise.all([
        GachaService.getGachaBaseInfoById(gachaId),
        GachaService.getGachaRewards({ gachaId }),
      ])

      return { ...baseInfo, rewards }
    },
    enabled: !!gachaId,
    refetchOnWindowFocus: false,
  })
}
