import { useCallback, useState } from 'react'

import { notifyError, notifySuccess } from 'helper'
import { ProductService } from 'services/marketplace/product'

export type ProductPriority = {
  productId: string
  priority: number
}
export const usePinnedProducts = () => {
  const [loading, setLoading] = useState(false)

  const getPinnedProducts = useCallback(async () => {
    try {
      setLoading(true)
      const data = await ProductService.getPinnedProducts()
      return data?.data
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  const createPinnedProducts = useCallback(
    async (priorities: ProductPriority[]) => {
      try {
        setLoading(true)
        const data = await ProductService.createPinnedProducts(priorities)
        notifySuccess('Updated successfully!')
        return data
      } catch (err) {
        notifyError(err)
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  return { loading, getPinnedProducts, createPinnedProducts }
}
