import { useMutation } from 'react-query'

import { AdsService } from 'services/ads'

import { UpdateAdsDto } from 'types/ads.type'

type UpdateAdsParams = {
  adsId: string
  payload: UpdateAdsDto
}

export const useUpdateAds = () => {
  return useMutation({
    mutationKey: ['UPDATE_ADS'],
    mutationFn: ({ adsId, payload }: UpdateAdsParams) =>
      AdsService.updateAds(adsId, payload),
  })
}
