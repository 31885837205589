import { useMutation } from 'react-query'

import { CampaignService } from 'services/bountySystem/campaign.service'

import { UpdateCampaignDto } from 'types/campaign.type'

type UpdateCampaignParams = {
  campaignId: string
  payload: UpdateCampaignDto
}

export const useUpdateCampaign = () => {
  return useMutation({
    mutationKey: ['UPDATE_CAMPAIGN'],
    mutationFn: ({ campaignId, payload }: UpdateCampaignParams) =>
      CampaignService.updateCampaign(campaignId, payload),
  })
}
