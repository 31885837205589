import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Form, FormInstance, Input, Row, Switch } from 'antd'

import { WrapLoading } from 'components/systems/loading'
import BadgeRedemption from './BadgeRedemption'

import { useCreateRedemption } from 'hooks/redemption/useCreateRedemption'
import { useUpdateRedemption } from 'hooks/redemption/useUpdateRedemption'
import { useRedemption } from 'hooks/redemption/useRedemption'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import { IRedemption } from 'types/redemption.type'

type FormRedemptionProps = {
  form: FormInstance<IRedemption>
  redemptionId?: string
}

const FormRedemption = ({ form, redemptionId }: FormRedemptionProps) => {
  const navigate = useNavigate()

  const { data, isLoading } = useRedemption(redemptionId!)

  const { mutateAsync: createRedemption, isLoading: isCreating } =
    useCreateRedemption()
  const { mutateAsync: updateRedemption, isLoading: isUpdating } =
    useUpdateRedemption()

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()

      if (redemptionId) {
        await updateRedemption({ redemptionId, payload: values })
      } else {
        await createRedemption(values)
      }

      notifySuccess(`${redemptionId ? 'Updated' : 'Created'}  Redemption`)
      navigate(ROUTES.REDEMPTION.INDEX)
    } catch (error) {
      notifyError(error)
    }
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, form])

  return (
    <WrapLoading loading={isLoading}>
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        labelCol={{ span: 3 }}
        labelAlign="left"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="badgeId"
          label="Badge"
          rules={[
            {
              required: true,
              message: 'Select one badge',
            },
          ]}
          required
        >
          <BadgeRedemption form={form} />
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[
            {
              required: true,
              message: 'Amount must be a number which was no less than 0',
              validator: (_, value) => {
                if (value >= 1) return Promise.resolve()

                return Promise.reject()
              },
            },
          ]}
          required
        >
          <Input placeholder="Enter amount of badges" />
        </Form.Item>

        <Form.Item name="isEnabled" label="Enable" valuePropName="checked">
          <Switch defaultChecked />
        </Form.Item>

        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            Save
          </Button>
        </Row>
      </Form>
    </WrapLoading>
  )
}

export default FormRedemption
