import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'

import { Button, Col, Form, Input, Row, Typography } from 'antd'

import RequiredLabel from 'components/systems/requiredSymbol'
import SpaceVertical from 'components/systems/spaceVertical'

import { useDigitalCard } from 'hooks/digitalCards/useDigitalCards'
import { DigitalCardService } from 'services/marketplace/digital-card'

import { notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import { IUpdateDigitalCard } from 'types/digital-card'

type FieldType = {
  title: string
  country: string
}

const UpdateDigitalCard = () => {
  const navigate = useNavigate()

  const digitalCardId = useParams().digitalCardId || ''
  const { digitalCard } = useDigitalCard(digitalCardId)

  const [form] = Form.useForm()

  const { mutate, isLoading } = useMutation(
    (data: IUpdateDigitalCard) => {
      return DigitalCardService.update(digitalCardId, data)
    },
    {
      onSuccess: () => {
        notifySuccess('Updated digital card')
        navigate(ROUTES.REWARDS_CENTER.DIGITAL_CARDS_MANAGEMENT)
      },
      onError: (error: any) => {
        window.notify({ type: 'error', description: error })
      },
    },
  )

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digitalCard])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Edit Digital Card
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Form form={form} initialValues={digitalCard} onFinish={mutate}>
          <Row gutter={[24, 24]}>
            <Col xs={24} xl={14}>
              <SpaceVertical>
                <RequiredLabel>Title (5-64 characters)</RequiredLabel>
                <Form.Item<FieldType>
                  name="title"
                  rules={[
                    { required: true, message: 'Please input the title!' },
                    {
                      min: 5,
                      max: 64,
                      message: 'Title must be between 5 - 64 characters.',
                    },
                  ]}
                >
                  <Input placeholder="Enter title..." />
                </Form.Item>
              </SpaceVertical>
            </Col>

            <Col span={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    disabled={
                      !form.isFieldsTouched(false) ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0
                    }
                    loading={isLoading}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default UpdateDigitalCard
