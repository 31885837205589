import { useCallback, useEffect, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import hash from 'object-hash'

import { UserNftDto, UserNftService } from 'services/marketplace/evm/userNft'
import { Web3Type } from 'services/marketplace/we3-item'

import { AppState } from 'store'
import { UserNFTData } from 'store/walletNFT.reducer'

export const useUserNFTs = () => {
  const userNFTs = useSelector((state: AppState) => state.nft.userNFTs)
  return userNFTs
}

export const useUserNFTDetails = (key: string) => {
  const userNFT = useSelector((state: AppState) => state.nft.userNFTs[key])
  return userNFT
}

export const useCurrentCursor = () => {
  return useSelector((state: AppState) => state.nft.cursor)
}

export const useUserNFTCheckActive = () => {
  const products = useSelector((state: AppState) => state.products)

  return useCallback(
    ({ web3ItemId }: { web3ItemId: string }) => {
      return (
        Object.values(products).find(
          (item) => item.web3ItemId === web3ItemId,
        ) === undefined
      )
    },
    [products],
  )
}

export const useQueryUserNFTs = (params: UserNftDto, enabled?: boolean) => {
  return useQuery({
    queryKey: ['GET_USER_NFTS', params],
    queryFn: () => UserNftService.getUserNft(params),
    enabled,
  })
}

export const useNftInfinite = (params: UserNftDto, enabled?: boolean) => {
  const [nftMap, setNftMap] = useState<Record<string, UserNFTData>>()

  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['GET_USER_NFTS_INFINITE', params],
    queryFn: async ({ pageParam = params }) => {
      const data = await UserNftService.getUserNft({
        ...params,
        cursor: pageParam.cursor,
      })

      return {
        data,
        cursor: data.cursor,
      }
    },
    getNextPageParam: (lastPage) => lastPage.cursor,
    enabled,
  })

  useEffect(() => {
    const nftList = data?.pages.flatMap((page) => page.data.result) ?? []
    const nftMapTemp: Record<string, UserNFTData> = {}

    for (const nft of nftList) {
      const nftData = {
        chainId: params.chainId,
        web3Type: Web3Type.NFT,
        blockData: {
          tokenId: nft.tokenId || '',
          tokenAddress: nft.tokenAddress || '',
          srcAddress: nft.ownerOf || '',
          contractType: nft.contractType || '',
          collectionName: nft.collectionName || '',
          symbol: nft.symbol || '',
        },
      }

      const nftUniqueId = `${nft.tokenId}-${nft.tokenAddress}`
      const web3ItemId = hash(nftData)

      nftMapTemp[nftUniqueId] = { ...nft, web3ItemId }
    }

    setNftMap(nftMapTemp)
  }, [data, params.chainId])

  return {
    ...rest,
    data: nftMap ?? {},
  }
}
