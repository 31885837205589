import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Input, Row, Table, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import CustomPagination from 'components/systems/pagination'
import SpaceBetween from 'components/systems/spaceBetween'

import { useCheques } from 'hooks/cheque/useCheques'
import { ChequeState } from 'services/bountySystem/cheque'
import { DEFAULT_PAGE } from 'constant'
import { COLUMNS } from './column'

const OffChain = () => {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const badgeId = useParams().itemId || ''
  const { cheques, total } = useCheques({
    badgeId,
    page,
    state: ChequeState.Initialized,
  })

  return (
    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
      <Col span={24}>
        <Input
          placeholder="Search NFT"
          style={{ width: 244 }}
          suffix={
            <IonIcon name="search-outline" style={{ color: '#42BAB0' }} />
          }
        />
      </Col>
      <Col span={24}>
        <Table
          className="table-border-collapse"
          columns={COLUMNS}
          dataSource={cheques}
          pagination={false}
          rowKey={({ uid }) => uid}
          rowSelection={{ type: 'checkbox' }}
        />
      </Col>
      <Col span={24}>
        <SpaceBetween
          floatContent={<CustomPagination total={total} onChange={setPage} />}
        >
          <Typography.Text type="success">Total: {total}</Typography.Text>
        </SpaceBetween>
      </Col>
    </Row>
  )
}

export default OffChain
