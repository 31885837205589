import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Col, Row } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import FilterSearch from 'components/systems/filter/search'

import { ROUTES } from 'constant/routes'

type NewAndFilterBarProps = {
  onSearch: Dispatch<SetStateAction<string>>
}

const NewAndFilterBar = ({ onSearch }: NewAndFilterBarProps) => {
  const navigate = useNavigate()

  return (
    <Row gutter={[12, 12]}>
      <Col flex="auto">
        <FilterSearch
          style={{ maxWidth: 300 }}
          placeholder="Search title..."
          setSearch={onSearch}
        />
      </Col>
      <Col>
        <Button
          className="btn-filled"
          icon={<IonIcon name="add-outline" />}
          onClick={() => navigate(ROUTES.REWARDS_CENTER.NEW_DIGITAL_CARDS)}
        >
          Add Digital Card
        </Button>
      </Col>
    </Row>
  )
}

export default NewAndFilterBar
