import { Col, Row } from 'antd'

import BountyApplication from '../bounty-application'

import './index.less'

export default function ApplicationManagement() {
  return (
    <Row gutter={[16, 16]} wrap={false}>
      <Col span={8}>
        <BountyApplication />
      </Col>
    </Row>
  )
}
