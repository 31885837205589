import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Col, Input, Row, Space, Typography } from 'antd'
import Recurrence from './recurrence'
import IonIcon from 'components/systems/ionIcon'
import DiscordLink from './discordLink'
import UploadPicture from 'components/systems/uploadPicture'
import RequiredLabel from 'components/systems/requiredSymbol'
import MarkdownEditor from 'components/systems/markdown/editor'
import DateRange from 'components/systems/dateRange'
import Subcategory from './subcategory'
import MissionTotalPrices from './totalPrices'
import LinkedMissions from './linkedMissions'
import MissionCampaign from 'view/bounty/management/createAndUpdate/share/formSetup/missionCampaign'
import SeoInformation from 'components/seo/seoInformation'

import { useBountySlug } from 'hooks/bounty/useBountySlug'
import useDebounce from 'hooks/useDebounce'
import {
  useBountySetup,
  useCreateBountyStep,
  useCreateBountyTasks,
  useFileList,
  useUpdateBountyForm,
} from '../../../index'
import { useSEO } from 'hooks/seo/useSEO'

import { generateSlug } from 'helper'

import { CreateAndUpdateTabs } from 'constant/bounty'
import { ROUTES } from 'constant/routes'
import {
  DESC_MAX_LEN,
  DESC_MIN_LEN,
  TITLE_MAX_LEN,
  TITLE_MIN_LEN,
  type ValueOf,
} from 'constant'
import { type CreateBounty } from 'services/bountySystem/bounty'
import { TaskKeys, TaskType } from 'templates/types'
import { VerificationMethod } from 'types/task.type'
import { SeoType } from 'types/seo.type'

export enum SetUpField {
  Picture = 'picture',
  Recurrence = 'recurrence',
  DateRange = 'date-range',
  Subcategory = 'subcategory',
  TotalPrices = 'total-prices',
  LinkDiscord = 'link-discord',
}

export type FormSetUpProps = {
  fields: SetUpField[]
  nextButton?: boolean
}

export const DEFAULT_DISCORD_TASK = {
  id: '',
  title: 'Discord Task',
  description: 'Do the task on Discord',
  type: TaskType.FlexibleTask,
  key: TaskKeys.SystemDiscord,
  configs: {
    url: '',
    action: 'join-discord',
  },
  thumbnail: '',
  verificationMethod: VerificationMethod.Default,
}

const FormSetUp = ({ fields, nextButton = true }: FormSetUpProps) => {
  const [bountyData, setBountyData] = useBountySetup()
  const [tasks, setTasks] = useCreateBountyTasks()
  const [isSlugError, setIsSlugError] = useState(false)
  const [, setStep] = useCreateBountyStep()
  const [fileList, setFileList] = useFileList()
  const navigate = useNavigate()
  const bountyId = useParams().bountyId || ''
  const isUpdateForm = !!bountyId

  const { checkIsSlugExisted } = useBountySlug()
  const slugDebounce = useDebounce(bountyData.slug, 500)
  const { onBountyUpdate, formLoading } = useUpdateBountyForm()
  const { clearSeoInfo, fetchSeoInfo } = useSEO(SeoType.Bounty)

  const onChange = (name: keyof CreateBounty, value: ValueOf<CreateBounty>) => {
    setBountyData({ ...bountyData, [name]: value })
  }

  const disabled = useMemo(() => {
    const { description, endedAt, startedAt, title } = bountyData

    if (
      title.trim().length < TITLE_MIN_LEN ||
      title.trim().length > TITLE_MAX_LEN ||
      description.trim().length < DESC_MIN_LEN ||
      description.trim().length > DESC_MAX_LEN ||
      isSlugError
    ) {
      return true
    }

    if (!fileList.length) return true

    return !endedAt || !startedAt
  }, [bountyData, fileList, isSlugError])

  useEffect(() => {
    const slug = generateSlug(slugDebounce)
    onChange('slug', slug)
    checkIsSlugExisted(slug).then((isExisted) => {
      setIsSlugError(isExisted)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugDebounce, checkIsSlugExisted])

  useEffect(() => {
    bountyId && fetchSeoInfo()
  }, [bountyId, fetchSeoInfo])

  return (
    <Row gutter={[24, 38]}>
      <Col xs={24} xl={14}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Mission Name (5-500 characters)</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={bountyData.title}
              placeholder="Enter mission name..."
              onChange={(e) => onChange('title', e.target.value)}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} xl={14}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Introduction (5-5000 characters)</RequiredLabel>
          </Col>
          <Col span={24}>
            <MarkdownEditor
              onChange={(e) => onChange('description', e)}
              defaultValue={bountyData.description}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} xl={14}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Slug</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={bountyData.slug}
              onChange={(e) => {
                onChange('slug', e.target.value)
              }}
              suffix={
                <Button
                  type="primary"
                  onClick={() => {
                    onChange('slug', generateSlug(bountyData.title))
                  }}
                  disabled={bountyData.title.length < 5}
                >
                  Generate Slug
                </Button>
              }
            />
          </Col>
          {isSlugError && (
            <Col span={24}>
              <Typography.Text type="danger">
                Slug is already existed!
              </Typography.Text>
            </Col>
          )}
        </Row>
      </Col>
      <Col xs={24} xl={14}>
        <LinkedMissions />
      </Col>
      <Col xs={24} xl={14}>
        <MissionCampaign />
      </Col>
      {fields.includes(SetUpField.Picture) && (
        <Col xs={24} xl={14}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <RequiredLabel>Upload Mission Thumbnail</RequiredLabel>
            </Col>
            <Col span={24}>
              <UploadPicture
                maxCount={1}
                fileList={fileList}
                onChangeFile={({ fileList }) => setFileList(fileList)}
              />
            </Col>
          </Row>
        </Col>
      )}
      {fields.includes(SetUpField.Recurrence) && (
        <Col xs={24} xl={14}>
          <Recurrence onChange={onChange} value={bountyData.recurrenceTime} />
        </Col>
      )}
      {fields.includes(SetUpField.Subcategory) && (
        <Col xs={24} xl={14}>
          <Subcategory onChange={onChange} value={bountyData.subcategory} />
        </Col>
      )}
      {fields.includes(SetUpField.TotalPrices) && (
        <Col xs={24} xl={14}>
          <MissionTotalPrices
            onChange={onChange}
            prices={bountyData.totalPrices}
          />
        </Col>
      )}
      {fields.includes(SetUpField.DateRange) && (
        <Col xs={24} xl={14}>
          <DateRange
            startAt={bountyData.startedAt}
            endAt={bountyData.endedAt}
            onChangeStartAt={(startedAt) =>
              setBountyData({ ...bountyData, startedAt })
            }
            onChangeEndAt={(endedAt) =>
              setBountyData({ ...bountyData, endedAt })
            }
          />
        </Col>
      )}
      {fields.includes(SetUpField.LinkDiscord) && (
        <Col xs={24} xl={14}>
          <DiscordLink
            value={(tasks[0]?.configs as any)?.url || ''}
            onChange={(url) => {
              const newTask = { ...DEFAULT_DISCORD_TASK }
              newTask.configs = { ...newTask.configs, url }
              setTasks([newTask])
            }}
          />
        </Col>
      )}

      <Col xs={24} xl={14}>
        <SeoInformation />
      </Col>

      {nextButton && (
        <Col span={24}>
          <Row gutter={8} justify="space-between">
            <Col flex="auto">
              <Button
                icon={<IonIcon name="close-outline" />}
                ghost
                onClick={() => {
                  clearSeoInfo()
                  navigate(ROUTES.BOUNTY.INDEX)
                }}
              >
                Cancel
              </Button>
            </Col>
            {isUpdateForm && (
              <Col>
                <Button
                  loading={formLoading}
                  onClick={onBountyUpdate}
                  style={{ minWidth: 130 }}
                  type="primary"
                  disabled={isSlugError}
                >
                  Update
                </Button>
              </Col>
            )}
            <Col>
              <Button
                style={{ minWidth: 130 }}
                type="primary"
                onClick={() => setStep(CreateAndUpdateTabs.Enter)}
                disabled={disabled}
              >
                <Space>
                  Next
                  <IonIcon name="arrow-forward-outline" />
                </Space>
              </Button>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}

export default FormSetUp
