import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Form, Row, Typography } from 'antd'

import FormAds from './form-ads'

import { useAdsDetail } from 'hooks/ads/useAdsDetail'

function CreateUpdateAds() {
  const [form] = Form.useForm()
  const adsId = useParams()?.adsId ?? ''
  const isEdit = !!adsId

  const { data, isLoading } = useAdsDetail(adsId)

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data, form])

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={1} type="success">
          {isEdit ? 'Edit Ads' : 'Create New Ads'}
        </Typography.Title>
      </Col>

      <Col span={24}>
        <FormAds
          form={form}
          isEdit={isEdit}
          adsId={adsId}
          isFetching={isLoading}
        />
      </Col>
    </Row>
  )
}

export default CreateUpdateAds
