import { Typography } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'
import { useDigitalCard } from 'hooks/digitalCards/useDigitalCards'

type TotalCodeProps = {
  digitalCardId: string
}

const TotalCode = ({ digitalCardId }: TotalCodeProps) => {
  const { digitalCard } = useDigitalCard(digitalCardId)

  if (!digitalCard || !digitalCard.cardCodeQuantity.length) {
    return <Typography.Text>-</Typography.Text>
  }

  return (
    <SpaceVertical>
      {digitalCard.cardCodeQuantity.map(({ count, country }) => (
        <Typography.Text key={country}>
          {country ?? 'All'}: {count}
        </Typography.Text>
      ))}
    </SpaceVertical>
  )
}

export default TotalCode
