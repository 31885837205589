import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import hash from 'object-hash'

import { Button, Col, Form, Radio, Row, Space, Typography } from 'antd'
import FormBasicInfo from './formBasicInfo'
import FormProductSKU from './formProductSKU'
import CardInfo from 'components/systems/cardInfo'
import FormAttributeSelect from 'components/product/formAttributeSelect'
import FormAttributeSetting from 'components/product/formAttributeSetting'
import SaleInformation from 'components/product/saleInformation'
import IonIcon from 'components/systems/ionIcon'
import OriginalPricesInfo from 'components/product/originalPricesInfo'
import SelectChain from 'components/systems/selectChain/selectChain'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'
import SeoInformation from 'components/seo/seoInformation'

import { useProductCategory } from 'hooks/useProductCategory'
import { useCreateProductData } from '../index'
import { useSEO } from 'hooks/seo/useSEO'
import CreateProductContext from '../FormProductContext'

import { encodeAttributes, generateAttributeIds } from 'helper/common'
import { notifyError } from 'helper'

import { ROUTES } from 'constant/routes'
import { ChainID } from 'constant'

import { LeaderboardPoint } from 'types/product-category.type'
import { AttributeConfig } from 'services/marketplace/product-category.type'
import { IBasicInformation, ISKU, SKUType } from 'types/product.type'
import { SeoType } from 'types/seo.type'

type FormPhysicalItemProps = {
  onOk: () => {}
  loading: boolean
  listSKU: ISKU[]
  setListSKU: (value: ISKU[]) => void
  basicInformation: IBasicInformation
  setBasicInformation: Dispatch<SetStateAction<IBasicInformation>>
  specData: Record<string, string>
  setSpecData: (value: Record<string, string>) => void
  variations: AttributeConfig[]
  setVariations: Dispatch<SetStateAction<AttributeConfig[]>>
  deliveryAttributes: AttributeConfig[]
  setDeliveryAttributes: Dispatch<SetStateAction<AttributeConfig[]>>
  leaderboardPoint?: LeaderboardPoint
  setLeaderboardPoint: Dispatch<SetStateAction<undefined | LeaderboardPoint>>
  productId?: string
}

const FormPhysicalItem = ({
  onOk,
  loading,
  basicInformation,
  variations,
  deliveryAttributes,
  setBasicInformation,
  setVariations,
  setListSKU,
  listSKU,
  specData,
  setSpecData,
  setDeliveryAttributes,
  leaderboardPoint,
  setLeaderboardPoint,
  productId = '',
}: FormPhysicalItemProps) => {
  const navigate = useNavigate()
  const [{ priceToken }] = useCreateProductData()
  const [paymentChain, setPaymentChain] = useState(
    priceToken?.chainId ?? ChainID.BSC,
  )
  const { fetchSeoInfo } = useSEO(SeoType.Product)

  const { isCodeItem } = useContext(CreateProductContext)
  const prevVariationsCount = useRef(variations.length)

  const { data: categoryData } = useProductCategory(basicInformation.categoryId)

  const syncsListSKU = useCallback(() => {
    const variationIds = generateAttributeIds(variations)

    const nextSKUs: ISKU[] = []
    for (const variationId of variationIds) {
      const sku = listSKU.find((e) => e.variationId === variationId)
      const cloneSku = {
        variationId,
        productId,
        available: 0,
        thumbnail: basicInformation.thumbnails[0] || '',
        totalAvailable: 1,
        variations: encodeAttributes(variationId),
        ...sku,
      }
      if (isCodeItem) {
        cloneSku.skuType = SKUType.CardCode
        cloneSku.payableMax = 1
      }
      nextSKUs.push(cloneSku)
    }

    // handle add or remove variation field
    // only for add new attribute
    if (prevVariationsCount.current < variations.length) {
      //move amount from variationId in old list to the first variationId match with variationId in new list
      listSKU.forEach((oldVal) => {
        const item = nextSKUs.find((newValue) => {
          return newValue.variationId.includes(oldVal.variationId)
        })

        if (item) item.available = oldVal.available
      })
    }

    // only for remove attribute
    if (prevVariationsCount.current > variations.length) {
      // move the count all amount of variationId to the new list that match with variationId in old list
      nextSKUs.forEach((newVal) => {
        const amount = listSKU.reduce((previousValue, currentValue) => {
          if (currentValue.variationId.includes(newVal.variationId)) {
            return previousValue + currentValue.available
          }

          return previousValue
        }, 0)

        newVal.available = amount
      })
    }

    prevVariationsCount.current = variations.length

    if (hash(nextSKUs) === hash(listSKU)) return
    return setListSKU(nextSKUs)
  }, [
    basicInformation.thumbnails,
    listSKU,
    productId,
    setListSKU,
    variations,
    isCodeItem,
  ])

  const validateBeforeSubmit = useCallback(() => {
    // check SKU card codes without linking to any cards
    const skuWithoutCard = listSKU.find(
      (sku) => sku.skuType === SKUType.CardCode && !sku.digitalCardId,
    )
    if (skuWithoutCard) return false
    const digitalIds: Record<string, boolean> = {}
    for (let i = 0; i < listSKU.length; i++) {
      const sku = listSKU[i]
      if (sku.digitalCardId) {
        if (digitalIds[sku.digitalCardId.toString()]) return false

        digitalIds[sku.digitalCardId?.toString()] = true
      }
    }
    return true
  }, [listSKU])

  const onSubmit = useCallback(() => {
    const valid = validateBeforeSubmit()
    if (!valid) {
      notifyError('Invalid input!')
      return
    }
    onOk()
  }, [validateBeforeSubmit, onOk])

  useEffect(() => {
    syncsListSKU()
  }, [syncsListSKU])

  useEffect(() => {
    productId && fetchSeoInfo()
  }, [productId, fetchSeoInfo])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Add Physical Item
        </Typography.Title>
      </Col>
      <Col span={24}>
        <SelectChainContext.Provider
          value={{
            selectedChain: ChainID.A8,
            setSelectedChain: () => {},
          }}
        >
          <FormBasicInfo
            value={basicInformation}
            onChange={setBasicInformation}
          />
        </SelectChainContext.Provider>
      </Col>
      <Col span={24}>
        <CardInfo title="Specification">
          {!categoryData ? (
            <Typography.Text>
              Available only after you select a product category
            </Typography.Text>
          ) : (
            <FormAttributeSelect
              attributes={categoryData.specificationConfig}
              value={specData}
              onChange={setSpecData}
            />
          )}
        </CardInfo>
      </Col>
      <Col span={24}>
        <CardInfo title="Variations">
          <FormAttributeSetting
            attributes={variations}
            onChange={setVariations}
            type="variation"
          />
        </CardInfo>
      </Col>
      <Col span={24}>
        <CardInfo title="Sale information">
          <SelectChainContext.Provider
            value={{
              selectedChain: paymentChain,
              setSelectedChain: setPaymentChain,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SelectChain />
              </Col>
              <Col span={24}>
                <SaleInformation />
              </Col>
              <Col span={24}>
                <OriginalPricesInfo />
              </Col>
              <Col span={24}>
                <FormProductSKU
                  variations={variations}
                  value={listSKU}
                  onChange={setListSKU}
                />
              </Col>
            </Row>
          </SelectChainContext.Provider>
        </CardInfo>
      </Col>
      <Col span={24}>
        <CardInfo title="Delivery">
          <FormAttributeSetting
            attributes={deliveryAttributes}
            onChange={setDeliveryAttributes}
          />
        </CardInfo>
      </Col>
      <Col span={24}>
        <CardInfo title="Leaderboard">
          <Form>
            <Row>
              <Col span={24} style={{ maxWidth: 486 }}>
                <Form.Item
                  name="type"
                  label={
                    <Typography.Text type="secondary">Type</Typography.Text>
                  }
                  initialValue={leaderboardPoint}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setLeaderboardPoint(e.target.value)
                    }}
                  >
                    <Radio value={undefined}>None</Radio>
                    <Radio value={LeaderboardPoint.TEAM}>Team</Radio>
                    <Radio value={LeaderboardPoint.COMMUNITY}>Community</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CardInfo>
      </Col>

      <Col span={24}>
        <SeoInformation />
      </Col>

      <Col span={24}>
        <Row justify="space-between">
          <Col flex="auto">
            <Button
              icon={<IonIcon name="close-outline" />}
              ghost
              onClick={() => navigate(ROUTES.REWARDS_CENTER.ITEMS)}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Space size={8}>
              {/* <ScheduleButton onSave={onOk} /> */}
              <Button
                icon={<IonIcon name="arrow-up-outline" />}
                ghost
                onClick={() => onSubmit()}
                loading={loading}
              >
                Publish
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FormPhysicalItem
