import React, { useState } from 'react'
import { createGlobalState, useDebounce } from 'react-use'

import { Col, Input, Radio, Row, Space } from 'antd'
import { MarketItemState } from 'constant/marketplace'

const { Search } = Input

export type FilterProductProps = {
  productState: MarketItemState
  search: string
}
export const useFilterProps = createGlobalState<FilterProductProps>({
  productState: MarketItemState.Publish,
  search: '',
})

const FilterAndSearch = () => {
  const [keyword, setKeyword] = useState('')
  const [filterProps, setFilterProps] = useFilterProps()

  useDebounce(() => setFilterProps({ ...filterProps, search: keyword }), 500, [
    keyword,
  ])

  return (
    <Row align="middle" gutter={[12, 12]}>
      <Col>
        <Search
          placeholder="Search Item"
          style={{ width: 244 }}
          onChange={(e) => {
            setKeyword(e.target.value)
          }}
        />
      </Col>
      <Col flex="auto">
        <Space align="center" direction="vertical" style={{ width: '100%' }}>
          <Radio.Group
            value={filterProps.productState}
            onChange={(e) => {
              setFilterProps({ ...filterProps, productState: e.target.value })
            }}
          >
            <Radio value={MarketItemState.Publish}>
              {MarketItemState.Publish}
            </Radio>
            <Radio value={MarketItemState.Unpublish}>
              {MarketItemState.Unpublish}
            </Radio>
            <Radio value={MarketItemState.Ended}>{MarketItemState.Ended}</Radio>
          </Radio.Group>
        </Space>
      </Col>
    </Row>
  )
}

export default FilterAndSearch
