import { useMutation } from 'react-query'

import { LeaderboardService } from 'services/bountySystem/leaderboard/leaderboard'

import {
  CreateTeamLeaderBoardDto,
  UpdateTeamLeaderBoardDto,
} from 'services/bountySystem/leaderboard/type'

export const useTeamsLeaderboardCreate = (
  payload: CreateTeamLeaderBoardDto,
) => {
  return useMutation({
    mutationKey: `CREATE_TEAM_LEADERBOARD_DATA:${JSON.stringify(payload)}`,
    mutationFn: () => LeaderboardService.createTeamLeaderboard(payload),
  })
}

export const useTeamsLeaderboardUpdate = (
  teamId: string,
  payload: UpdateTeamLeaderBoardDto,
) => {
  return useMutation({
    mutationKey: `UPDATE_TEAM_LEADERBOARD_DATA:${JSON.stringify(payload)}`,
    mutationFn: () => LeaderboardService.updateTeamLeaderboard(teamId, payload),
  })
}

export const useTeamsLeaderboardDelete = (teamId: string) => {
  return useMutation({
    mutationKey: `DELETE_TEAM_LEADERBOARD_DATA:${JSON.stringify(teamId)}`,
    mutationFn: () => LeaderboardService.removeTeamLeaderboard(teamId),
  })
}
