import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'

import FormSetUp from './formSetup'

const AffiliationSeasonForm = () => {
  const seasonId = useParams().seasonId || ''

  const title = !seasonId ? 'Create Season' : 'Edit Season'

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <FormSetUp />
      </Col>
    </Row>
  )
}

export default AffiliationSeasonForm
