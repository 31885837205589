import { useParams } from 'react-router-dom'

import { Col, Row } from 'antd'

import ApplicationManagement from 'components/multiplier/applicationManagement'
import MultiplierWhitelist from 'components/multiplierWhitelist'

import { useMultiplier } from 'hooks/multiplier/useMultiplier'

function MultiplierDetails() {
  const multiplierId = useParams().multiplierId || ''
  const { data } = useMultiplier(multiplierId)

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <ApplicationManagement />
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <MultiplierWhitelist
              whitelistId={data?.configs?.whitelistConfig?.whitelistId || ''}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MultiplierDetails
