import { useCallback } from 'react'

import { Button } from 'antd'

const RowAction = ({ teamId }: { teamId: string }) => {
  const onViewTeamDetail = useCallback((teamId: string) => {
    // TODO: implement later
    console.log(teamId)
  }, [])

  return (
    <Button type="primary" onClick={() => onViewTeamDetail(teamId)}>
      Details
    </Button>
  )
}

export default RowAction
