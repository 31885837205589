import { useMutation } from 'react-query'

import { MultiplierService } from 'services/multiplier'

import { UpdateMultiplierDto } from 'types/multiplier.type'

type UpdateMultiplierParams = {
  multiplierId: string
  payload: UpdateMultiplierDto
}

export const useUpdateMultiplier = () => {
  return useMutation({
    mutationKey: ['UPDATE_MULTIPLIER'],
    mutationFn: ({ multiplierId, payload }: UpdateMultiplierParams) =>
      MultiplierService.updateMultiplier(multiplierId, payload),
  })
}

export const useUnpublishedMultiplier = () => {
  return useMutation({
    mutationKey: ['UNPUBLISH_MULTIPLIER'],
    mutationFn: (multiplierId: string) =>
      MultiplierService.updateMultiplier(multiplierId, { isActive: false }),
  })
}

export const useRepublishedMultiplier = () => {
  return useMutation({
    mutationKey: ['REPUBLISH_MULTIPLIER'],
    mutationFn: (multiplierId: string) =>
      MultiplierService.updateMultiplier(multiplierId, { isActive: true }),
  })
}
