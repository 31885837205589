import { useQuery } from 'react-query'

import { CardCodeService } from 'services/marketplace/card-code'

import { DEFAULT_PAGE } from 'constant'

type FilterCardCodes = {
  digitalCardId: string
  page?: number
}

const fetchCardCodes = async (digitalCardId: string, page: number) => {
  const { data, total } = await CardCodeService.getAll({
    digitalCardId,
    page,
  })
  return { data, total }
}

export const useCardCodes = ({
  page = DEFAULT_PAGE,
  digitalCardId = '',
}: FilterCardCodes) => {
  const {
    data: cardCodes,
    isLoading,
    error,
  } = useQuery(['GET_CARDS_CODES', page, digitalCardId], () =>
    fetchCardCodes(digitalCardId, page),
  )

  return {
    cardCodes: cardCodes?.data || [],
    total: cardCodes?.total || 0,
    isLoading,
    error,
  }
}
