import { useMutation } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { CreateAcceler8SubscriptionDto } from 'types/acceler8/acceler8-subscription.type'

export const useCreateAcceler8Subscription = () => {
  return useMutation({
    mutationKey: ['create-acceler8-subscription'],
    mutationFn: ({ dto }: { dto: CreateAcceler8SubscriptionDto }) =>
      Acceler8Service.createSubscription(dto),
  })
}
