import { useQuery } from 'react-query'

import { MultiplierService } from 'services/multiplier'

export const useWhitelistWalletsCounter = (whitelistId: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_WHITELIST_WALLETS_COUNTER', whitelistId],
    queryFn: () => MultiplierService.getWhitelistWalletsCounter(whitelistId),
    enabled: !!whitelistId,
  })

  return {
    ...rest,
    data: data ?? 0,
  }
}
