import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Col, Row, Table, Typography } from 'antd'

import CustomPagination from 'components/systems/pagination'
import IonIcon from 'components/systems/ionIcon'
import { WrapLoading } from 'components/systems/loading'
import { COLUMN_SEASON } from './table/column'

import { useSeasons } from 'hooks/leaderboard/useSeason'

import { DEFAULT_PAGE } from 'constant'
import { ROUTES } from 'constant/routes'

const ListSeason = () => {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const { seasons, total, isLoading } = useSeasons({
    page: currentPage,
  })

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row justify="end">
              <Button
                className="btn-filled"
                icon={<IonIcon name="add-outline" />}
                onClick={() => navigate(ROUTES.LEADERBOARD.NEW_SEASON)}
              >
                Add Season
              </Button>
            </Row>
          </Col>
          <Col span={24}>
            <WrapLoading loading={isLoading} type="stick">
              <Table
                rowKey="_id"
                className="table-border-collapse"
                columns={COLUMN_SEASON}
                dataSource={seasons}
                onRow={({ _id }) => {
                  return {
                    onClick: () => {
                      navigate(`${ROUTES.LEADERBOARD.INDEX}/${_id}`)
                    },
                  }
                }}
                pagination={false}
              />
            </WrapLoading>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!seasons.length ? (
            <Col>
              <CustomPagination
                total={total}
                onChange={setCurrentPage}
                page={currentPage}
              />
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}

export default ListSeason
