import { useMemo } from 'react'
import moment from 'moment'
import { useQueryClient } from 'react-query'

import { Button } from 'antd'
import SubscriptionSetup, { OnSubmitProps } from '../subscriptionSetup'

import { useUpdateAcceler8Subscription } from 'hooks/acceler8/useUpdateAcceler8Subscription'

import { notifyError, notifySuccess } from 'helper'

import {
  Acceler8SubscriptionStatus,
  ISubscriptionOverall,
  SubscriptionAction,
  UpdateAcceler8SubscriptionDto,
} from 'types/acceler8/acceler8-subscription.type'
import SpaceVertical from 'components/systems/spaceVertical'

type OverallActionProps = {
  item: ISubscriptionOverall
}

const OverallAction = ({ item }: OverallActionProps) => {
  const isActive = useMemo(() => {
    return (
      item.status === Acceler8SubscriptionStatus.Active &&
      moment.utc().isBefore(moment.utc(item.endedAt))
    )
  }, [item])

  return (
    <SpaceVertical>
      <Activate item={item} buttonText={!isActive ? 'Activate' : 'Edit'} />
      {isActive && <Deactivate subscriptionId={item.id} />}
    </SpaceVertical>
  )
}

const Deactivate = ({ subscriptionId }: { subscriptionId: number }) => {
  const { mutate, isLoading: mutateLoading } = useUpdateAcceler8Subscription()
  const queryClient = useQueryClient()

  const onDeactivate = () => {
    mutate(
      {
        subscriptionId: subscriptionId,
        dto: { status: Acceler8SubscriptionStatus.Inactive },
      },
      {
        onError: (error) => {
          notifyError(error)
        },
        onSuccess: async () => {
          notifySuccess('Deactivate subscription')
          await queryClient.invalidateQueries(
            'GET_ACCELER8_SUBSCRIPTION_OVERALL',
          )
        },
      },
    )
    return
  }

  return (
    <Button ghost onClick={onDeactivate} loading={mutateLoading}>
      Deactive
    </Button>
  )
}

const Activate = ({
  item,
  buttonText = 'Activate',
}: OverallActionProps & { buttonText?: string }) => {
  const { mutate, isLoading: mutateLoading } = useUpdateAcceler8Subscription()

  const onActivate = ({ dto, onError, onSuccess }: OnSubmitProps) => {
    mutate(
      {
        subscriptionId: item.id,
        dto: dto as UpdateAcceler8SubscriptionDto,
      },
      {
        onError,
        onSuccess,
      },
    )
  }

  return (
    <SubscriptionSetup
      onSubmit={onActivate}
      action={SubscriptionAction.ActivateSubscription}
      loading={mutateLoading}
      buttonText={buttonText}
      item={item}
    />
  )
}

export default OverallAction
