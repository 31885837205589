import { Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

type BountyStatusProps = { isEnabled?: boolean }
const BountyStatus = ({ isEnabled = false }: BountyStatusProps) => {
  const statusType = isEnabled ? 'success' : 'secondary'
  const statusText = isEnabled ? 'Publish' : 'UnPublish'
  const statusIcon = isEnabled ? 'checkmark-circle' : 'document-lock'

  return (
    <Space size={4}>
      <Typography.Text type={statusType}>
        <IonIcon name={statusIcon} />
      </Typography.Text>
      <Typography.Text type={statusType}>{statusText}</Typography.Text>
    </Space>
  )
}

export default BountyStatus
