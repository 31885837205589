import { Fragment, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import useHandleProduct from 'hooks/useHandleProduct'
import useHandleProductSKU from 'hooks/useHandleProductSKU'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import { useCreateProductData } from './index'

import { LeaderboardPoint } from 'types/product-category.type'
import { IBasicInformation, ISKU, SKUType } from 'types/product.type'
import { AttributeConfig } from 'services/marketplace/product-category.type'
import {
  DEFAULT_BASIC_INFO_DATA,
  DUMMY_PRODUCT_DATA,
} from 'constant/marketplace'
import FormProductContext from './FormProductContext'
import FormPhysicalItem from './formPhysical'

const EditPhysicalItem = () => {
  const [fetched, setFetched] = useState<boolean>(false)
  const [isCodeItem, setIsCodeItem] = useState<boolean>(false)
  const [specData, setSpecData] = useState<Record<string, string>>({})
  const [variations, setVariations] = useState<AttributeConfig[]>([])
  const [deliveries, setDeliveries] = useState<AttributeConfig[]>([])
  const [listSKU, setListSKU] = useState<(ISKU & { _id?: string })[]>([])
  const [basicInformation, setBasicInformation] = useState<IBasicInformation>(
    DEFAULT_BASIC_INFO_DATA,
  )
  const [leaderboardPoint, setLeaderboardPoint] = useState<
    undefined | LeaderboardPoint
  >()

  const productId = useParams().productId || ''
  const [community] = useCurrentCommunity()
  const [globalData, setGlobalData] = useCreateProductData()
  const { loading, getProductData, onUpdateProduct } = useHandleProduct()
  const { getSKUByProductId } = useHandleProductSKU()

  const onUpdate = useCallback(async () => {
    const data = {
      ...globalData,
      ...basicInformation,
      SKUs: listSKU,
      specification: specData,
      deliveryAttributes: deliveries,
      variationAttributes: variations,
      leaderboardPoint: leaderboardPoint,
    }
    onUpdateProduct({ productId, data })
  }, [
    basicInformation,
    deliveries,
    globalData,
    leaderboardPoint,
    listSKU,
    onUpdateProduct,
    productId,
    specData,
    variations,
  ])

  useEffect(() => {
    const initData = async () => {
      if (!productId) return
      const data = await getProductData(productId)
      const { data: SKUs } = await getSKUByProductId(productId)
      const { variationAttributes } = data
      const isCodeItem = !!SKUs.find((sku) => sku.skuType === SKUType.CardCode)
      const basicInfo = {
        communityId: community?._id,
        title: data.title,
        categoryId: data.categoryId,
        chainId: data.chainId,
        description: data.description,
        productType: data.productType,
        thumbnails: data.thumbnails,
        web3ItemId: data.web3ItemId,
        paymentChainId: data?.priceToken?.chainId ?? data.chainId,
        slug: data.slug,
      }

      setGlobalData(data)
      setBasicInformation(basicInfo)
      setVariations(variationAttributes as any)
      setListSKU(SKUs)
      setDeliveries(data.deliveryAttributes as any)
      setSpecData(data.specification)
      setLeaderboardPoint(data.leaderboardPoint)
      setFetched(true)
      setIsCodeItem(isCodeItem)
    }

    /* Init data */
    initData()
  }, [
    community?._id,
    getProductData,
    getSKUByProductId,
    productId,
    setGlobalData,
  ])

  useEffect(() => {
    return () => setGlobalData(DUMMY_PRODUCT_DATA)
  }, [setGlobalData])

  /* Return empty if not fetched */
  if (!fetched) return <Fragment />

  const commonProps = {
    productId,
    onOk: onUpdate,
    loading,
    setListSKU,
    listSKU,
    basicInformation,
    setBasicInformation,
    variations,
    setVariations,
    specData,
    setSpecData,
    setDeliveryAttributes: setDeliveries,
    leaderboardPoint,
    setLeaderboardPoint,
    deliveryAttributes: deliveries,
  }

  return (
    <FormProductContext.Provider value={{ isCodeItem }}>
      <FormPhysicalItem {...commonProps} />
    </FormProductContext.Provider>
  )
}

export default EditPhysicalItem
