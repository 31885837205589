import { useState } from 'react'
import { useDebounce } from 'react-use'

import {
  Button,
  Col,
  Dropdown,
  Input,
  MenuProps,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd'

import IonIcon from 'components/systems/ionIcon'

import { TeamSortOptions } from 'types/affiliation-team.type'
import { SortFilterProps } from '../listTeam'

const SORT_OPTIONS = [
  {
    label: 'Current season rank',
    value: TeamSortOptions.CurrentSeasonRank,
  },
  {
    label: 'Accumulative points',
    value: TeamSortOptions.AccumulativePoints,
  },
  {
    label: 'Last point contributed',
    value: TeamSortOptions.LastPointContributed,
  },
]

type ControlBarProps = {
  sortFilter: SortFilterProps
  handleSortFilter: (key: string, value: string | string[]) => void
}

const ControlBar = ({ sortFilter, handleSortFilter }: ControlBarProps) => {
  const [keyword, setKeyword] = useState('')

  useDebounce(() => handleSortFilter('searchValue', keyword), 500, [keyword])

  const items: MenuProps['items'] = [
    {
      label: (
        <Row onClick={(e) => e.stopPropagation()}>
          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Typography.Text style={{ color: '#AFB5C2' }}>
                  Sort
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Radio.Group
                  onChange={(e) =>
                    handleSortFilter('sortOption', e.target.value)
                  }
                  defaultValue={TeamSortOptions.CurrentSeasonRank}
                >
                  <Space direction="vertical" size={16}>
                    {SORT_OPTIONS.map((option) => (
                      <Radio key={option.value} value={option.value}>
                        {option.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
      key: '0',
    },
  ]

  return (
    <Row gutter={[12, 12]}>
      <Col flex="auto">
        <Input
          placeholder="Search by team name ..."
          onChange={(e) => setKeyword(e.target.value)}
          suffix={<IonIcon name="search-outline" />}
        />
      </Col>

      <Col span={4}>
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          overlayClassName="sort-filter-container--dropdown"
        >
          <Button style={{ width: '100%' }} ghost>
            Sort
          </Button>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default ControlBar
