import { useMemo } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'

import { WhitelistService } from 'services/marketplace/whitelist'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

type FilterWhitelist = {
  search?: string
  page?: number
}

const ROOT_KEY = 'GET-WHITELIST-INFINITE'
export const useWhitelistInfinite = ({
  search = '',
  page = DEFAULT_PAGE,
}: FilterWhitelist) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [ROOT_KEY, page, search],
    queryFn: async ({ pageParam = page }) => {
      const { data, total } = await WhitelistService.getAll({
        offset: (pageParam - 1) * DEFAULT_PAGE_SIZE,
        limit: DEFAULT_PAGE_SIZE,
      })

      return {
        data,
        nextPage:
          pageParam < Math.ceil(total / DEFAULT_PAGE_SIZE)
            ? pageParam + 1
            : undefined,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
  })

  const whitelists = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data],
  )

  const queryClient = useQueryClient()
  const refetchRoot = () =>
    queryClient.refetchQueries({
      queryKey: [ROOT_KEY],
    })

  return {
    data: whitelists ?? [],
    refetchRoot,
    ...rest,
  }
}
