import { marketplaceApi } from 'services/base-axios'

import { DataList } from 'types'
import { AdsData, CreateAdsDto, FindAdsDto, UpdateAdsDto } from 'types/ads.type'

const CONTROLLER_ADS = '/admin/ads'

class Ads {
  async getAdsList(dto: FindAdsDto) {
    const { data } = await marketplaceApi.get<DataList<AdsData[]>>(
      CONTROLLER_ADS,
      {
        params: dto,
      },
    )
    return data
  }

  async findAdsById(adsId: string) {
    const { data } = await marketplaceApi.get<AdsData>(
      `${CONTROLLER_ADS}/${adsId}`,
    )
    return data
  }

  async createAds(dto: CreateAdsDto) {
    const { data } = await marketplaceApi.post<AdsData>(CONTROLLER_ADS, dto)
    return data
  }

  async updateAds(adsId: string, dto: UpdateAdsDto) {
    const { data } = await marketplaceApi.patch<AdsData>(
      `${CONTROLLER_ADS}/${adsId}`,
      dto,
    )
    return data
  }
}

export const AdsService = new Ads()
