import { ChainID } from 'constant'
import storage from './storage'

/**
 * Chain network
 */
const CHAIN_KEY = 'chain:network'

const getChain = () => {
  const currentChainID = storage.get(CHAIN_KEY)
  //init if not existed
  if (!currentChainID) storage.set(CHAIN_KEY, ChainID.BSC)

  return storage.get(CHAIN_KEY)
}
export const chain: ChainID = getChain()

export const onSwitchChain = (value: ChainID) => {
  storage.set(CHAIN_KEY, value)
  return getChain()
}
