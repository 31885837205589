import { Fragment } from 'react'

import { Button } from 'antd'
import {
  Acceler8ActivityType,
  ISubscriptionActivity,
} from 'types/acceler8/acceler8-subscription.type'

type ActivityActionProps = {
  item: ISubscriptionActivity
}

const ActivityAction = ({ item }: ActivityActionProps) => {
  if (item.type === Acceler8ActivityType.Subscription) {
    return (
      <Button href={item.chainLink} target="_blank" ghost>
        Txn
      </Button>
    )
  }

  return <Fragment />
}

export default ActivityAction
