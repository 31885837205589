import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Col, Row, Space, Typography } from 'antd'
import UploadCSV from './uploadCSV'
import CardUser from './cardUser'
import IonIcon from 'components/systems/ionIcon'
import NoData from 'components/systems/noData'
import SpaceVertical from 'components/systems/spaceVertical'

import { CreateMultiCertificate } from 'services/bountySystem/certificate/type'
import { CertificateService } from 'services/bountySystem/certificate'
import { useTaskData } from 'hooks/task/useTasks'
import { convertTzUtcBeforeUpload, notifyError, notifySuccess } from 'helper'
import { ROUTES } from 'constant/routes'

import './index.less'
import sample from 'static/files/sample-user-results.csv'

export type UserCompletedData = {
  uid: string
  _id?: string
}

const UserCompleted = () => {
  const [loading, setLoading] = useState(false)
  const [userCompleted, setUserCompleted] = useState<UserCompletedData[]>([])
  const params = useParams()
  const navigate = useNavigate()
  const taskId = params.taskId || ''
  const taskData = useTaskData(taskId)

  const onSave = useCallback(async () => {
    try {
      setLoading(true)
      const uids = userCompleted.map(({ uid }) => uid)
      const completedAt = userCompleted.map(() => convertTzUtcBeforeUpload())
      const params: CreateMultiCertificate = {
        taskId,
        uids,
        completedAt,
      }
      await CertificateService.createMultiTaskCertificate(params)
      return notifySuccess('Create multi certificates successfully')
    } catch (error) {
      return notifyError(error)
    } finally {
      setLoading(false)
    }
  }, [taskId, userCompleted])

  const onRemove = useCallback(
    async (uid: string, certId?: string) => {
      try {
        if (certId) await CertificateService.deleteOne(certId)

        const nextUsers = [...userCompleted]
        const index = nextUsers.findIndex((user) => user.uid === uid)
        nextUsers.splice(index, 1)
        return setUserCompleted(nextUsers)
      } catch (err) {
        notifyError(err)
      }
    },
    [userCompleted],
  )

  const fetchCert = useCallback(async () => {
    const data = await CertificateService.getListTaskCertificate(taskId)
    const nextData = data.map(({ uid, _id }) => ({ uid, _id }))
    setUserCompleted(nextData)
  }, [taskId])

  useEffect(() => {
    fetchCert()
  }, [fetchCert])

  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <Typography.Title level={2}>
          UID result {taskData.title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row align="middle">
              <Col flex="auto">
                <Button
                  style={{ padding: '0 34px' }}
                  ghost
                  icon={<IonIcon name="arrow-back-outline" />}
                  onClick={() =>
                    navigate(`${ROUTES.BOUNTY.INDEX}/${params.bountyId}`)
                  }
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Space>
                  <Button
                    icon={<IonIcon name="download-outline" />}
                    ghost
                    href={sample}
                    target="_blank"
                    download
                  >
                    Download Template
                  </Button>

                  <UploadCSV setUserCompleted={setUserCompleted} />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {userCompleted.length ? (
              <SpaceVertical>
                <Typography.Text>UID</Typography.Text>
                {userCompleted.map(({ uid, _id }, index) => (
                  <CardUser
                    onRemove={onRemove}
                    _id={_id}
                    key={uid + index}
                    uid={uid}
                  />
                ))}
              </SpaceVertical>
            ) : (
              <NoData
                style={{ marginTop: 100 }}
                title="We couldn’t find any List results."
              />
            )}
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              style={{ width: 120 }}
              onClick={onSave}
              loading={loading}
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default UserCompleted
