import {
  ArrowLeft,
  ArrowRight,
  Person,
  Facebook,
  Discord,
  Twitter,
  Telegram,
  Website,
} from 'static/images/customIcon'

export const CUSTOM_ICONS: Record<string, string> = {
  'arrow-double-left': ArrowLeft,
  'arrow-double-right': ArrowRight,
  'person-info': Person,
  facebook: Facebook,
  discord: Discord,
  twitter: Twitter,
  telegram: Telegram,
  website: Website,
}
