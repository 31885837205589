import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Dropdown } from 'antd'

import IonIcon from 'components/systems/ionIcon'

import { SeasonService } from 'services/bountySystem/season'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'
import { TableSeasonActions } from 'constant/season'

type RowActionProps = {
  seasonId: string
}

const RowAction = ({ seasonId }: RowActionProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: [`CLONE_SEASON`],
    mutationFn: (id: string) => {
      return SeasonService.cloneSeason(id)
    },
    onSuccess: () => {
      notifySuccess('Cloned season')
      queryClient.invalidateQueries('GET_LIST_SEASONS')
    },
    onError: (error: any) => {
      notifyError(error)
    },
  })

  const onCloneSeason = (seasonId: string) => {
    mutate(seasonId)
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Button
        trigger={['click']}
        type="text"
        icon={<IonIcon name="ellipsis-vertical-outline" />}
        menu={{
          items: [
            {
              label: TableSeasonActions.Edit,
              key: TableSeasonActions.Edit,
            },
            {
              label: TableSeasonActions.Clone,
              key: TableSeasonActions.Clone,
            },
          ],
          onClick: (e) => {
            switch (e.key) {
              case TableSeasonActions.Edit:
                return navigate(`${ROUTES.LEADERBOARD.EDIT_SEASON}/${seasonId}`)
              case TableSeasonActions.Clone:
                return onCloneSeason(seasonId)
            }
          },
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  )
}

export default RowAction
