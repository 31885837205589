import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import FormSetUp from './formSetup'

import { useUpdateAcceler8SeasonMutation } from 'hooks/acceler8/useUpdateAcceler8SeasonMutation'
import { useAcceler8Season } from 'hooks/acceler8/useAcceler8Season'

import { notifyError, notifySuccess } from 'helper'
import { ROUTES } from 'constant/routes'
import { UpdateAcceler8SeasonDto } from 'types/acceler8/acceler8-season.type'

type Props = {
  seasonId?: number
}

const FormUpdate = ({ seasonId }: Props) => {
  const { data: initialData } = useAcceler8Season(seasonId)

  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutate, isLoading: mutateLoading } = useUpdateAcceler8SeasonMutation()
  const onMutate = (seasonData: UpdateAcceler8SeasonDto, seasonId?: number) => {
    mutate(
      { dto: seasonData, seasonId: seasonId! },
      {
        onError: (error) => {
          notifyError(error)
        },
        onSuccess: async () => {
          notifySuccess('Update season')
          await queryClient.invalidateQueries('GET_ACCELER8_SEASONS')
          await queryClient.invalidateQueries('GET_ACCELER8_SEASON')
          navigate(ROUTES.ACCELER8.MANAGE_SEASON)
        },
      },
    )
  }

  if (!initialData) return null

  return (
    <FormSetUp
      initialSeasonData={initialData}
      onMutate={onMutate}
      mutateLoading={mutateLoading}
    />
  )
}

export default FormUpdate
