import { FC, lazy, memo, Suspense, useMemo } from 'react'

import {
  ButtonCreateProps,
  CREATE_TASK_KEY,
  FormCreateProps,
  PartnerTaskKeys,
  PartnerTemplateConfigs,
  SocialTaskKeys,
  SocialTemplateConfigs,
  TaskInfo,
  TaskKeys,
  TaskType,
} from './types'
import { useCreateBountyTasks } from 'view/bounty/management'
import { useTaskKey } from './hooks/useTaskKey'
import { TASK_KEY_TO_SOCIAL_CONFIGS } from './social/configs'
import { TASK_KEY_TO_PARTNER_CONFIGS } from './partner/configs'
import { VerificationMethod } from 'types/task.type'

export const ButtonCreateLoader = ({ taskKey }: { taskKey: TaskKeys }) => {
  const [tasks, setTasks] = useCreateBountyTasks()
  const [dynamicConfigs, Component]: [
    SocialTemplateConfigs | PartnerTemplateConfigs | undefined,
    FC<ButtonCreateProps>,
  ] = useMemo(() => {
    const dynamicConfigs =
      TASK_KEY_TO_SOCIAL_CONFIGS[taskKey] ||
      TASK_KEY_TO_PARTNER_CONFIGS[taskKey]
    const dynamicPath = SocialTaskKeys.includes(taskKey)
      ? './social'
      : PartnerTaskKeys.includes(taskKey)
      ? './partner'
      : `./${taskKey}`

    const Component = lazy(() => import(`${dynamicPath}/buttonCreate.tsx`))
    return [dynamicConfigs, Component]
  }, [taskKey])

  return (
    <Suspense>
      <Component
        dynamicConfigs={dynamicConfigs}
        onClick={(type, configs) =>
          setTasks([
            ...tasks,
            {
              type,
              configs,
              key: taskKey,
              title: '',
              description: '',
              verificationMethod: VerificationMethod.Default,
              id: `${CREATE_TASK_KEY}${Math.random()}${taskKey}`,
              isPublished: true,
            },
          ])
        }
      />
    </Suspense>
  )
}

export const FormCreateLoader = memo(
  ({
    type,
    taskInfo,
    onRemove,
    onChange,
  }: {
    type: TaskType
    taskInfo: TaskInfo
    onRemove: () => void
    onChange: (taskInfo: TaskInfo) => void
  }) => {
    const taskKey = useTaskKey(type, taskInfo.configs)

    const Component: FC<FormCreateProps> = useMemo(() => {
      const dynamicPath = SocialTaskKeys.includes(taskKey)
        ? './social'
        : PartnerTaskKeys.includes(taskKey)
        ? './partner'
        : `./${taskKey}`

      return lazy(() => import(`${dynamicPath}/formCreate.tsx`))
    }, [taskKey])

    return (
      <Suspense>
        <Component
          onChange={onChange}
          onRemove={onRemove}
          taskInfo={taskInfo}
        />
      </Suspense>
    )
  },
)
