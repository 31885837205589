import { Fragment } from 'react'

import {
  Button,
  Col,
  Collapse,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Typography,
} from 'antd'
import { DeleteOutlined as RemoveIcon } from '@ant-design/icons'

import PriceBadgeItem from './PriceBadgeItem'
import GachaPriceToken from './GachaPriceToken'

type GachaPriceSectionProps = {
  form: FormInstance
}

const GachaPriceSection = ({ form }: GachaPriceSectionProps) => {
  return (
    <Form.List
      name="variants"
      rules={[
        {
          validator: (_, value) => {
            if (value?.length < 1) {
              return Promise.reject(
                new Error('Please add at least one payment method!'),
              )
            }
            return Promise.resolve()
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => {
        return (
          <Fragment>
            {fields.map((field, index) => (
              <Collapse
                expandIcon={() => null}
                defaultActiveKey={1}
                key={field.name}
                collapsible="disabled"
                style={{ marginBottom: 16 }}
              >
                <Collapse.Panel
                  key={1}
                  header={
                    <Row justify="space-between" align="middle">
                      <Col>
                        <Typography.Title level={4} type="success">
                          Method {index + 1}
                        </Typography.Title>
                      </Col>
                      <Col>
                        <Button
                          className=""
                          icon={<RemoveIcon />}
                          danger
                          onClick={() => remove(field.name)}
                        />
                      </Col>
                    </Row>
                  }
                >
                  <Row gutter={16}>
                    <Col span={3}>
                      <Form.Item name={[field.name, 'priceCCP']}>
                        <InputNumber
                          placeholder="EP"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item name={[field.name, 'originalPriceCCP']}>
                        <InputNumber
                          placeholder="Original EP"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1} />

                    <GachaPriceToken prefixPath={field.name} form={form} />

                    <Col span={3}>
                      <Form.Item name={[field.name, 'priceToken', 'price']}>
                        <InputNumber
                          placeholder="Token amount"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item
                        name={[field.name, 'originalPriceToken', 'price']}
                      >
                        <InputNumber
                          placeholder="Original token"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <PriceBadgeItem prefixPath={field.name} />
                    </Col>

                    <Form.Item
                      name={[field.name, 'priceToken', 'tokenAddress']}
                      hidden
                      noStyle
                    />
                    <Form.Item
                      name={[field.name, 'originalPriceToken', 'chainId']}
                      hidden
                      noStyle
                    />
                    <Form.Item
                      name={[field.name, 'originalPriceToken', 'tokenAddress']}
                      hidden
                      noStyle
                    />
                    <Form.Item
                      name={[field.name, 'originalPriceToken', 'tokenName']}
                      hidden
                      noStyle
                    />
                  </Row>
                </Collapse.Panel>
              </Collapse>
            ))}

            <Button className="btn-dashed" block onClick={() => add()}>
              Add Payment Method
            </Button>

            <Form.ErrorList errors={errors} />
          </Fragment>
        )
      }}
    </Form.List>
  )
}

export default GachaPriceSection
