import { useMutation } from 'react-query'
import { WhitelistService } from 'services/marketplace/whitelist'

import type { CreateWhitelistDto } from 'types/whitelist.type'

export const useCreateWhitelist = () => {
  return useMutation({
    mutationKey: ['createWhitelist'],
    mutationFn: (payload: CreateWhitelistDto) =>
      WhitelistService.create(payload),
  })
}
