import { marketplaceApi as api } from 'services/base-axios'

import { IProductCategory } from './product-category.type'
import { CreateProductCategoryData, ProductCategoryData } from './type'

const CONTROLLER = '/product-category'

class ProductCategory {
  async getAll(): Promise<ProductCategoryData[]> {
    const { data } = await api.get(CONTROLLER)
    return data
  }

  async getById(categoryId: string): Promise<ProductCategoryData> {
    const { data } = await api.get(`${CONTROLLER}/${categoryId}`)
    return data
  }

  async create(dto: IProductCategory): Promise<ProductCategoryData> {
    const { data } = await api.post(CONTROLLER, dto)
    return data
  }
  async update(
    categoryId: string,
    updateData: Partial<CreateProductCategoryData>,
  ): Promise<ProductCategoryData> {
    const { data } = await api.patch(`${CONTROLLER}/${categoryId}`, updateData)
    return data
  }
}

export const ProductCategoryService = new ProductCategory()
