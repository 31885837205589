import { useEffect, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import hash from 'object-hash'

import BlockScoutService from 'services/blockscout'
import { Web3Type } from 'services/marketplace/we3-item'
import configs from 'configs'

import { UserNFTData } from 'store/walletNFT.reducer'

import { ChainID } from 'constant'

export const useA8NftDetail = (tokenAddress: string, tokenId: number) => {
  return useQuery({
    queryKey: ['get-nft-detail', tokenAddress, tokenId],
    queryFn: async () => {
      const { data } = await BlockScoutService.getNftInstanceById(
        tokenAddress,
        tokenId,
      )

      return data
    },
    enabled: !!tokenAddress && !isNaN(tokenId),
  })
}

export const useA8NftInfinite = () => {
  const [nftMap, setNftMap] = useState<Record<string, UserNFTData>>()

  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['get-a8-nft-infinite'],
    queryFn: async ({ pageParam }) => {
      const { data } = await BlockScoutService.getAddressNft(
        configs.walletEVM.adminAddress,
        // type undefined for get all nft
        undefined,
        {
          params: pageParam?.cursor,
        },
      )

      const listNft = data.items

      const result: UserNFTData[] = listNft?.map((nftDetail) => {
        const tokenInfoMeta = nftDetail?.metadata

        return {
          amount: nftDetail.value,
          attributes: tokenInfoMeta?.attributes,
          collectionName: tokenInfoMeta?.collectionName,
          contractType: nftDetail.token_type,
          description: tokenInfoMeta?.description,
          image: tokenInfoMeta?.image || nftDetail?.image_url || '',
          name: tokenInfoMeta?.name || nftDetail?.token?.name || '',
          ownerOf: configs.walletEVM.adminAddress,
          symbol: nftDetail?.token?.symbol ?? '',
          tokenAddress: nftDetail?.token?.address,
          tokenId: nftDetail?.id !== null ? parseInt(nftDetail?.id) : undefined,
          tokenStandard: nftDetail?.token_type,
        } as UserNFTData
      })

      return {
        result,
        cursor: data?.next_page_params,
        chainId: ChainID.A8,
      }
    },
  })

  useEffect(() => {
    const nftList = data?.pages.flatMap((page) => page.result) ?? []
    const nftMapTemp: Record<string, UserNFTData> = {}

    for (const nft of nftList) {
      const nftData = {
        chainId: ChainID.A8,
        web3Type: Web3Type.NFT,
        blockData: {
          tokenId: nft.tokenId || '',
          tokenAddress: nft.tokenAddress || '',
          srcAddress: nft.ownerOf || '',
          contractType: nft.contractType || '',
          collectionName: nft.collectionName || '',
          symbol: nft.symbol || '',
        },
      }

      const nftUniqueId = `${nft.tokenId}-${nft.tokenAddress}`
      const web3ItemId = hash(nftData)

      nftMapTemp[nftUniqueId] = { ...nft, web3ItemId }
    }

    setNftMap(nftMapTemp)
  }, [data])

  return { ...rest, data: nftMap ?? {} }
}

export const useA8NftBaseInfo = (tokenAddress: string) => {
  return useQuery({
    queryKey: ['get-base-nft-info', tokenAddress],
    queryFn: async () => {
      const { data } = await BlockScoutService.getToken(tokenAddress)

      return data
    },
    enabled: !!tokenAddress,
  })
}
