import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd'

import SelectChainInput from 'components/marketPlace/collection/selectChainInput'
import IonIcon from 'components/systems/ionIcon'
import UploadImageSync from 'components/systems/uploadPicture/UploadImageSync'

import { useCreateNft } from 'hooks/nftService/useCreateNft'

import { ChainID, OptionElement } from 'constant'
import { NFT_TYPE_LABEL } from 'constant/nft'
import { notifyError, notifySuccess } from 'helper'

import { CreateNftDto, NftType } from 'types/nft.type'

type SaveNftFormProps = {
  form: FormInstance<CreateNftDto>
  hideModal: () => void
  saveCallback: () => Promise<void>
}

const SaveNftForm = ({ form, hideModal, saveCallback }: SaveNftFormProps) => {
  const { mutateAsync: createNft, isLoading: isCreating } = useCreateNft()

  return (
    <Form
      form={form}
      onFinish={async (values) => {
        try {
          await createNft(values)
          await saveCallback()
          hideModal()
          notifySuccess('Save NFT')
        } catch (e) {
          notifyError('Save NFT Failed')
        }
      }}
      initialValues={{ chainId: ChainID.A8, nftType: NftType.ERC721 }}
    >
      <Form.Item
        name="chainId"
        required
        label="Chain"
        labelCol={{ span: 5 }}
        labelAlign="left"
      >
        <SelectChainInput
          disabled={false}
          onSelect={(val) => form.setFieldValue('chainId', val)}
          //   support A8 chain for now
          chainList={[new OptionElement('A8', ChainID.A8)]}
          defaultValue={ChainID.A8}
        />
      </Form.Item>

      <Form.Item
        name="address"
        rules={[{ required: true }]}
        label="Address"
        labelCol={{ span: 5 }}
        labelAlign="left"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="nftType"
        rules={[{ required: true }]}
        label="NFT type"
        labelCol={{ span: 5 }}
        labelAlign="left"
      >
        <Select
          options={Object.values(NftType).map((type) => ({
            label: NFT_TYPE_LABEL[type],
            value: type,
          }))}
          suffixIcon={
            <IonIcon
              style={{ fontSize: 18, fontWeight: 700, color: '#42bbb0' }}
              name="chevron-down-outline"
            />
          }
        />
      </Form.Item>

      <Form.Item
        name="thumbnail"
        style={{
          height: 'fit-content',
          transition: 'all 0.3s ease-in-out',
          overflow: 'hidden',
          marginTop: 16,
        }}
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.reject(new Error('Image is required'))
              }

              return Promise.resolve()
            },
          },
          { required: true },
        ]}
        label="Thumbnail"
        labelCol={{ span: 5 }}
        labelAlign="left"
      >
        <UploadImageSync />
      </Form.Item>

      <Row justify="end" gutter={15}>
        <Col span={5}>
          <Button type="primary" danger onClick={hideModal} block>
            Cancel
          </Button>
        </Col>
        <Col span={5}>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isCreating}
            disabled={isCreating}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default SaveNftForm
