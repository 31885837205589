import { useQuery, useQueryClient } from 'react-query'

import { AdsService } from 'services/ads'

import { FindAdsDto } from 'types/ads.type'

const ROOT_KEY = 'GET_ADS'

export const useAds = (dto: FindAdsDto) => {
  const { data, ...rest } = useQuery({
    queryKey: [ROOT_KEY, dto],
    queryFn: () => AdsService.getAdsList(dto),
  })

  const queryClient = useQueryClient()
  const refetchRoot = () =>
    queryClient.refetchQueries({
      queryKey: [ROOT_KEY],
    })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
    refetchRoot,
  }
}
