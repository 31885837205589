import { Typography } from 'antd'

import { useA8NftBaseInfo } from 'hooks/nft/useA8NftInfinite'

import { ChainID } from 'constant'

type TitleProps = {
  chainId: ChainID
  address: string
}

export default function Title({ address }: TitleProps) {
  // TODO: should update to support multi-chain
  const { data } = useA8NftBaseInfo(address)

  return <Typography.Text>{data?.name ?? '--'}</Typography.Text>
}
