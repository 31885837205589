import { useMutation } from 'react-query'

import { AdsService } from 'services/ads'

import { CreateAdsDto } from 'types/ads.type'

export const useCreateAds = () => {
  return useMutation({
    mutationKey: ['CREATE_ADS'],
    mutationFn: (dto: CreateAdsDto) => AdsService.createAds(dto),
  })
}
