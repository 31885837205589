import DefaultLoader from './defaultLoader'
import { LoadingProps } from './index'

const PageLoader = ({ size, dotSize }: Required<LoadingProps>) => {
  return (
    <div className="sp3-page-loader">
      <DefaultLoader size={size} dotSize={dotSize} />
    </div>
  )
}

export default PageLoader
