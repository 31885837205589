import { Typography } from 'antd'

import Action from './action'
import { ColumnType } from 'antd/lib/table'
import { ICommunity } from 'types/community.type'

export const COLUMN_COMMUNITY: ColumnType<ICommunity>[] = [
  {
    title: 'Community Name',
    dataIndex: 'title',
    render: (title: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {title}
      </Typography.Paragraph>
    ),
  },
  {
    title: '',
    dataIndex: '_id',
    width: 62,
    render: (_id: string) => <Action communityId={_id} />,
  },
]
