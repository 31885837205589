import { generatePath, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { Dropdown } from 'antd'

import IonIcon from 'components/systems/ionIcon'

import { useTeamsLeaderboardDelete } from 'hooks/leaderboard/useTeamsLeaderboardMutation'

import { notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'
import { TableSeasonActions } from 'constant/season'

export type ActionProps = {
  teamId: string
  seasonId: string
}

const Action = ({ teamId, seasonId }: ActionProps) => {
  const navigate = useNavigate()
  const { mutateAsync } = useTeamsLeaderboardDelete(teamId)
  const queryClient = useQueryClient()

  const items = [
    { label: TableSeasonActions.Edit, key: TableSeasonActions.Edit },

    {
      label: TableSeasonActions.Delete,
      key: TableSeasonActions.Delete,
    },
  ]

  const onEdit = () => {
    return navigate(
      generatePath(ROUTES.LEADERBOARD.EDIT_TEAM_LEADERBOARD, { seasonId }) +
        `/${teamId}`,
    )
  }

  const onDelete = async () => {
    await mutateAsync()
    notifySuccess('Deleted')
    await queryClient.refetchQueries('GET_TEAM_LEADERBOARD_DATA')
  }

  return (
    <Dropdown.Button
      trigger={['click']}
      type="text"
      icon={<IonIcon name="ellipsis-vertical-outline" />}
      placement="bottomRight"
      menu={{
        items: items,
        onClick: (e) => {
          switch (e.key) {
            case TableSeasonActions.Edit:
              return onEdit()
            case TableSeasonActions.Delete:
              return onDelete()
          }
        },
      }}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

export default Action
