import { useParams } from 'react-router-dom'
import moment from 'moment'

import { Button, Col, Empty, Row, Space, Typography } from 'antd'

import Clipboard from 'components/systems/clipboard'
import SpaceVertical from 'components/systems/spaceVertical'
import { WrapLoading } from 'components/systems/loading'

import { Acceler8Service } from 'services/acceler8'

import {
  useAcceler8TeamProfile,
  useAcceler8AccumulativePoints,
} from 'hooks/acceler8/useAcceler8Teams'

import { formatNumberWithSuffix } from 'helper/common'

import { TIME_FORMAT } from 'constant'

const Acceler8TeamDetails = () => {
  const teamId = useParams().teamId || ''

  const { data: teamProfile, isLoading: isTeamProfileLoading } =
    useAcceler8TeamProfile(parseInt(teamId))

  const {
    data: listTeamAccumulatedPoints,
    isLoading: isAccumulativePointLoading,
  } = useAcceler8AccumulativePoints((teamProfile?.id && [teamProfile.id]) || [])

  return (
    <WrapLoading
      loading={isTeamProfileLoading || isAccumulativePointLoading}
      type="stick"
    >
      {!teamProfile ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
      ) : (
        <Row gutter={[24, 36]}>
          <Col span={24}>
            <Typography.Title level={3} type="success">
              {teamProfile.teamName}
            </Typography.Title>
          </Col>

          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>Team Leader</Typography.Text>
                      <Space>
                        <Typography.Text type="secondary">
                          {teamProfile.leaderId}
                        </Typography.Text>
                        <Clipboard content={teamProfile.leaderId || ''} />
                      </Space>
                    </SpaceVertical>
                  </Col>
                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>Leader's Telegram ID</Typography.Text>
                      <Typography.Text type="secondary">
                        {teamProfile.leaderTelegramId}
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>

                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>Creation time</Typography.Text>
                      <Typography.Text type="secondary">
                        {moment.utc(teamProfile.createdAt).format(TIME_FORMAT)}{' '}
                        (UTC)
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>
                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>
                        Last point contribution time
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        {teamProfile.currentRank?.updatedAt
                          ? `${moment
                              .utc(teamProfile.currentRank?.updatedAt)
                              .format(TIME_FORMAT)} (UTC)`
                          : '--'}
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>Total members</Typography.Text>
                      <Typography.Text type="secondary">
                        {teamProfile.totalMember}
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>

                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>Current season rank</Typography.Text>
                      <Typography.Text type="secondary">
                        {teamProfile.currentRank?.ranking &&
                        teamProfile.currentRank?.points
                          ? formatNumberWithSuffix(
                              teamProfile.currentRank.ranking,
                            )
                          : '--'}
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>

                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>
                        Total points current season
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        {teamProfile.currentRank?.points || 0}
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>

                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>Last season rank</Typography.Text>
                      <Typography.Text type="secondary">
                        {teamProfile.lastRank?.ranking
                          ? formatNumberWithSuffix(teamProfile.lastRank.ranking)
                          : '--'}
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>

                  <Col span={24}>
                    <SpaceVertical>
                      <Typography.Text>
                        Total accumulative points
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        {listTeamAccumulatedPoints?.[0].accumulativePoints || 0}
                      </Typography.Text>
                    </SpaceVertical>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Button
              ghost
              href={Acceler8Service.getDownloadTeamAnalyticsUrl(
                parseInt(teamId),
              )}
            >
              Export this team analytics
            </Button>
          </Col>
        </Row>
      )}
    </WrapLoading>
  )
}

export default Acceler8TeamDetails
