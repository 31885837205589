import configs from 'configs'
import { bountySystemApi } from 'services/base-axios'
import { marketplaceApi } from 'services/base-axios'
import { getAccessToken } from 'services/utils'

import { DataList } from 'types'
import {
  BulkCreateMultiAppDto,
  CreateMultiplierDto,
  FindMultipliersDto,
  GetMultiplierApplications,
  IMultiplier,
  IMultiplierApplication,
  UpdateMultiplierDto,
} from 'types/multiplier.type'
import { FindWhitelistWallets } from 'types/whitelist.type'

const CONTROLLER_MULTIPLIER = '/admin/multiplier'
const CONTROLLER_USER_WHITELIST = '/admin/user-whitelist'
const MULTIPLIER_APPLICATION_CONTROLLER = '/admin/multiplier-application'

class Multiplier {
  async getMultipliers(dto: FindMultipliersDto) {
    const { data } = await bountySystemApi.get<DataList<IMultiplier[]>>(
      CONTROLLER_MULTIPLIER,
      {
        params: dto,
      },
    )
    return data
  }

  async getMultiplierDetails(multiplierId: string) {
    const { data } = await bountySystemApi.get<IMultiplier>(
      `${CONTROLLER_MULTIPLIER}/${multiplierId}`,
    )
    return data
  }

  async getWhitelistWalletsCounter(whitelistId: string) {
    const { data } = await marketplaceApi.get<number>(
      `${CONTROLLER_USER_WHITELIST}/count`,
      {
        params: { whitelistId },
      },
    )
    return data
  }

  async getWhitelistWallets(dto: FindWhitelistWallets) {
    const { data } = await marketplaceApi.get<DataList<any[]>>(
      `${CONTROLLER_USER_WHITELIST}/`, // TODO: update api name when mapping
      {
        params: dto,
      },
    )
    return data
  }

  async createMultiplier(dto: CreateMultiplierDto) {
    const { data } = await bountySystemApi.post<IMultiplier>(
      CONTROLLER_MULTIPLIER,
      dto,
    )

    return data
  }

  async updateMultiplier(multiplierId: string, dto: UpdateMultiplierDto) {
    const { data } = await bountySystemApi.patch<IMultiplier>(
      `${CONTROLLER_MULTIPLIER}/${multiplierId}`,
      dto,
    )

    return data
  }

  async uploadCsv(formData: FormData) {
    const { data } = await marketplaceApi.post(
      `${CONTROLLER_USER_WHITELIST}/import`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    return data
  }

  async bulkCreateMultiApp(dto: BulkCreateMultiAppDto) {
    const { data } = await bountySystemApi.post<IMultiplierApplication>(
      `${MULTIPLIER_APPLICATION_CONTROLLER}/bulk-create`,
      dto,
    )

    return data
  }

  async getMultiplierApplication({
    multiplierId,
    applicationType,
  }: GetMultiplierApplications) {
    const { data } = await bountySystemApi.get<[IMultiplierApplication]>(
      `${MULTIPLIER_APPLICATION_CONTROLLER}`,
      {
        params: { multiplierId, applicationType },
      },
    )

    return data
  }

  async getMultiplierApplicationById(id: string) {
    const { data } = await bountySystemApi.get<IMultiplierApplication>(
      `${MULTIPLIER_APPLICATION_CONTROLLER}/${id}`,
    )

    return data
  }

  async deleteMultiplierApplicationById(id: string) {
    const { data } = await bountySystemApi.delete(
      `${MULTIPLIER_APPLICATION_CONTROLLER}/${id}`,
    )

    return data
  }

  getDownloadWalletWhitelistStatusUrl(multiplierId: string) {
    const accessToken = getAccessToken()

    const url = new URL(
      configs.api.bountyApi +
        CONTROLLER_MULTIPLIER +
        '/export-whitelist-status',
    )
    url.searchParams.set('multiplierId', multiplierId)
    url.searchParams.set('accessToken', accessToken)

    return url.toString()
  }
}

export const MultiplierService = new Multiplier()
