import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TaskService } from 'services/bountySystem/task'

import { AppDispatch, AppState } from 'store'
import { TaskData, TaskState, upsetTask } from 'store/tasks.reducer'
import { TaskType } from 'templates/types'
import { VerificationMethod } from 'types/task.type'

const DUMMY_DATA: TaskData = {
  createdAt: '',
  description: '',
  recurrenceTime: 0,
  thumbnail: '',
  title: '',
  updatedAt: '',
  bountyId: '',
  configs: { action: 'connect-discord' },
  type: TaskType.System,
  verificationMethod: VerificationMethod.Default,
  isPublished: true,
}

export const useTasks = () => {
  const tasks = useSelector((state: AppState) => state.tasks)
  return tasks
}

export const useBountySelector = <T>(selector: (tasks: TaskState) => T) => {
  const bounty = useSelector(({ tasks }: AppState) => selector(tasks))
  return bounty
}

export const useTaskData = (taskId: string) => {
  const tasks = useBountySelector((tasks) => tasks)
  const [taskData, setTaskData] = useState<TaskData>(DUMMY_DATA)
  const dispatch = useDispatch<AppDispatch>()

  const getTaskData = useCallback(async () => {
    const task = tasks[taskId]
    if (!task) {
      const { data } = await TaskService.getById(taskId)
      await dispatch(upsetTask({ taskData: { ...data }, taskId: data._id }))
      return setTaskData(data)
    }
    return setTaskData(task)
  }, [dispatch, taskId, tasks])

  useEffect(() => {
    getTaskData()
  }, [getTaskData])

  return taskData
}
