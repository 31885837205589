import { Col, Row, Typography } from 'antd'

import ListSeason from './listSeason'

const AffiliationSeasonManagement = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Affiliation Season Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <ListSeason />
      </Col>
    </Row>
  )
}

export default AffiliationSeasonManagement
