import { marketplaceApi as api } from 'services/base-axios'

import { Data } from 'types'
import { IProductData } from 'types/product.type'
import { FilterProductSuggestionParams } from './product.type'

const CONTROLLER = '/product-suggestion'

class ProductSuggestion {
  async getProductSuggestion(
    params: FilterProductSuggestionParams,
  ): Promise<Data<IProductData>[]> {
    const { data } = await api.get(CONTROLLER, { params })
    return data
  }
}

export const ProductSuggestionService = new ProductSuggestion()
