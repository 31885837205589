import { useMutation } from 'react-query'

import { WhitelistService } from 'services/marketplace/whitelist'

import type { UpdateWhitelistDto } from 'types/whitelist.type'

type UpdateWhitelistParams = {
  whitelistId: string
  payload: UpdateWhitelistDto
}

export const useUpdateWhitelist = () => {
  return useMutation({
    mutationKey: ['useUpdateWhitelist'],
    mutationFn: ({ whitelistId, payload }: UpdateWhitelistParams) =>
      WhitelistService.update(whitelistId, payload),
  })
}
