import { marketplaceApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import { CreateCardCodeDto } from 'types/card-code'

const CONTROLLER = '/admin/card-code'

class CardCode {
  async getAll({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    digitalCardId = '',
  }: {
    page?: number
    pageSize?: number
    digitalCardId: string
  }) {
    return await api
      .get(CONTROLLER, {
        params: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          digitalCardId,
        },
      })
      .then((data) => data.data)
  }

  async create(data: CreateCardCodeDto) {
    return await api.post(CONTROLLER, data)
  }

  async purgingCardCodes(digitalCardId: string) {
    return api.delete(CONTROLLER, {
      params: {
        digitalCardId,
      },
    })
  }
}

export const CardCodeService = new CardCode()
