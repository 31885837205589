import { marketplaceApi as api } from 'services/base-axios'

import { ISKU } from 'types/product.type'

const CONTROLLER = '/product-sku'

export type ProductSKUData = ISKU & {
  _id: string
}

class ProductSKU {
  async getAll() {
    return api.get(CONTROLLER).then((data) => data.data)
  }

  async getByProductId(
    productId: string,
  ): Promise<{ data: ProductSKUData[]; total: number }> {
    return api
      .get(CONTROLLER, { params: { productId } })
      .then((data) => data.data)
  }

  async get(skuId: string) {
    return api.get(`${CONTROLLER}/${skuId}`).then((data) => data.data)
  }

  async create(createData: any) {
    const { data } = await api.post(CONTROLLER, createData)
    return data
  }

  async update(skuId: string, updateData: any) {
    const { data } = await api.patch(`${CONTROLLER}/${skuId}`, updateData)
    return data
  }

  async delete(skuId: string) {
    const { data } = await api.delete(`${CONTROLLER}/${skuId}`)
    return data
  }
}

export const ProductSKUService = new ProductSKU()
