import { Fragment, useState } from 'react'

import {
  DeleteOutlined as RemoveIcon,
  PlusOutlined as AddIcon,
} from '@ant-design/icons'
import { Button, Col, Form, FormInstance, Image, Row } from 'antd'

import SelectNFTBadge from 'components/systems/selectNFTBadge'

import { useBadgeData } from 'hooks/badges/useBadges'

import './index.less'

function BadgeDetail({
  badgeId,
  onRemove = () => {},
}: {
  badgeId: string
  onRemove: () => void
}) {
  const { badgeData } = useBadgeData(badgeId)

  return (
    <div className="badge--img-detail">
      <Image
        src={badgeData.thumbnail}
        preview={false}
        width={100}
        height={100}
      />

      <div className="badge--img-detail--remove">
        <RemoveIcon onClick={onRemove} />
      </div>
    </div>
  )
}

type BadgeRedemptionProps = {
  form: FormInstance
}

function BadgeRedemption({ form }: BadgeRedemptionProps) {
  const [open, setOpen] = useState<boolean>(false)
  const badgeId = Form.useWatch('badgeId', form)

  return (
    <Fragment>
      <Row gutter={[10, 10]}>
        {badgeId && (
          <Col>
            <BadgeDetail
              badgeId={badgeId}
              key={badgeId}
              onRemove={() => form.setFieldValue('badgeId', '')}
            />
          </Col>
        )}
        <Col>
          <Button className="btn-square" onClick={() => setOpen(true)}>
            <AddIcon />
            {!!badgeId ? 'Select Other' : 'Select Badge'}
          </Button>
        </Col>
      </Row>

      <SelectNFTBadge
        open={open}
        badgeSelected={[badgeId] || []}
        setBadgeSelected={(value) => {
          if (value.length < 1) return

          form.setFieldValue('badgeId', value[0])
        }}
        setOpen={setOpen}
      />
    </Fragment>
  )
}

export default BadgeRedemption
