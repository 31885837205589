import { useState } from 'react'

import { Button, Col, Input, Modal, Row, Select, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import CommonPopup from 'view/bounty/management/listBounty/commonPopup'

import { Role, useAdmins } from 'hooks/community/useUser'

import { bountySystemApi as api } from 'services/base-axios'
import { notifyError, notifySuccess, shortenAddress } from 'helper'

const { Admin, Operator, User } = Role

const Action = (props: { uid: string; role: Role; id: string }) => {
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [role, setRole] = useState<Role>(props.role)

  const { refetch } = useAdmins()

  const onUpdate = async () => {
    try {
      setLoadingUpdate(true)
      await api.put(`/user/${props.uid}`, { role })
      await refetch()
      notifySuccess('User updated successfully!')
      return setOpenUpdate(false)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoadingUpdate(false)
    }
  }

  const onDelete = async () => {
    try {
      setLoadingDelete(true)
      await api.delete(`/user/${props.id}`)
      await refetch()
      notifySuccess('The user has been deleted successfully!')
      return setOpenDelete(false)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoadingDelete(false)
    }
  }

  return (
    <Space>
      <Button
        onClick={() => setOpenUpdate(true)}
        size="small"
        ghost
        icon={<IonIcon name="pencil-outline" />}
      />
      <Button
        onClick={() => setOpenDelete(true)}
        size="small"
        ghost
        icon={<IonIcon name="trash-outline" />}
      />

      <Modal
        closeIcon={<IonIcon name="close-outline" />}
        open={openUpdate}
        onCancel={() => setOpenUpdate(false)}
        footer={null}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>User ID</Typography.Text>

              <Input
                readOnly
                value={props.uid}
                placeholder="Enter user id..."
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Role</Typography.Text>
              <Select
                style={{ width: 120 }}
                onChange={(val) => setRole(val)}
                value={role}
                options={[
                  { value: Admin, label: 'Admin' },
                  { value: Operator, label: 'Operator' },
                  { value: User, label: 'User' },
                ]}
                placeholder="Select role"
              />
            </Space>
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button ghost type="ghost" onClick={() => setOpenUpdate(false)}>
                Cancel
              </Button>
              <Button
                onClick={onUpdate}
                disabled={role === props.role}
                type="primary"
                loading={loadingUpdate}
              >
                Update
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>

      <CommonPopup
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onConfirm={onDelete}
        title={`Do you want delete this user: ${shortenAddress(props.uid)}`}
        loading={loadingDelete}
      />
    </Space>
  )
}

export default Action
