import { ChainID } from 'constant'
import { ChainState } from 'store/chain.reducer'
import { IChain } from 'types/chain.type'

export const CHAIN_DEFAULT: IChain = {
  chainNumber: 0,
  chainId: ChainID.BSC,
  scanner: '',
  cluster: '',
  contracts: {},
  paymentCurrencies: [],
  nativeToken: {
    symbol: '',
    decimal: '',
  },
}

export const CHAIN_STATE_DEFAULT: ChainState = {
  [ChainID.A8]: { ...CHAIN_DEFAULT, chainId: ChainID.A8 },
  [ChainID.Arbitrum]: { ...CHAIN_DEFAULT, chainId: ChainID.Arbitrum },
  [ChainID.BSC]: CHAIN_DEFAULT,
  [ChainID.Ethereum]: { ...CHAIN_DEFAULT, chainId: ChainID.Ethereum },
  [ChainID.Optimism]: { ...CHAIN_DEFAULT, chainId: ChainID.Optimism },
  [ChainID.Polygon]: { ...CHAIN_DEFAULT, chainId: ChainID.Polygon },
  [ChainID.Sepolia]: { ...CHAIN_DEFAULT, chainId: ChainID.Sepolia },
  [ChainID.Solana]: { ...CHAIN_DEFAULT, chainId: ChainID.Solana },
  [ChainID.Ronin]: { ...CHAIN_DEFAULT, chainId: ChainID.Ronin },
  [ChainID.Mint]: { ...CHAIN_DEFAULT, chainId: ChainID.Mint },
  [ChainID.Base]: { ...CHAIN_DEFAULT, chainId: ChainID.Base },
  [ChainID.Kroma]: { ...CHAIN_DEFAULT, chainId: ChainID.Kroma },
}
