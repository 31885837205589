import { Fragment, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import Papa from 'papaparse'

import {
  Button,
  Col,
  Image,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd'

import IonIcon from 'components/systems/ionIcon'

import { CardCodeService } from 'services/marketplace/card-code'

import { notifyError, notifySuccess } from 'helper'

import { FILE_SIZE_ONE_MB } from 'constant/marketplace'

import { CardCodeDto, CreateCardCodeDto } from 'types/card-code'

import iconUpload from 'static/images/bounty/upload.svg'
import csvLogo from 'static/images/bounty/csvLogo.svg'
import SpaceVertical from 'components/systems/spaceVertical'
import RequiredLabel from 'components/systems/requiredSymbol'
import { countryOptions } from '../createAndUpdate/common'

type UploadCSVProps = {
  digitalCardId: string
}

const UploadCSV = ({ digitalCardId }: UploadCSVProps) => {
  const [visible, setVisible] = useState(false)
  const [file, setFile] = useState<File>()
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState<CreateCardCodeDto>({
    digitalCardId: '',
    country: '',
    cardCodes: [],
  })

  const queryClient = useQueryClient()

  const parse = (file: any): Promise<CardCodeDto[]> => {
    return new Promise((resolve, reject) => {
      return Papa.parse(file, {
        delimiter: ',',
        header: true,
        skipEmptyLines: true,
        transformHeader: (header, index) => {
          const map: Record<string, string> = {
            Codes: 'code',
            Serial: 'serial',
          }
          return map[header]
        },
        complete: ({ data }, fileInfo) => {
          if (fileInfo.size > FILE_SIZE_ONE_MB * 10)
            return reject('File to large')
          setFile(fileInfo)
          return resolve(data as CardCodeDto[])
        },
      })
    })
  }

  const { mutate: onUpload, isLoading } = useMutation(
    async () => {
      const { cardCodes, country } = formData
      if (!cardCodes.length || !country) {
        throw new Error('Invalid input')
      }

      const chunkSize = 100
      for (let i = 0; i < cardCodes.length; i += chunkSize) {
        const chunk = cardCodes.slice(i, i + chunkSize)

        const data: CreateCardCodeDto = {
          digitalCardId,
          cardCodes: chunk,
          country: country,
        }

        await CardCodeService.create(data)
      }
      setVisible(false)
    },
    {
      onSuccess: () => {
        notifySuccess('Upload file successfully.')
        queryClient.invalidateQueries('GET_CARDS_CODES')
      },
      onError: (error: any) => {
        return notifyError(error)
      },
    },
  )

  const onChangeCardCodes = async (file: any) => {
    if (!file) return
    const cardCodes = await parse(file)
    setFormData({ ...formData, cardCodes })
  }

  const onRemove = () => {
    setFile(undefined)
    setError(false)
  }

  return (
    <Fragment>
      <Button
        icon={<IonIcon name="cloud-upload-outline" />}
        onClick={() => setVisible(true)}
        type="primary"
      >
        Upload Card Codes CSV
      </Button>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        closeIcon={<IonIcon name="close-outline" />}
        footer={null}
      >
        <Row gutter={[0, 38]} justify="center">
          <Col span={24}>
            <Space
              align="center"
              size={4}
              direction="vertical"
              style={{ width: '100%' }}
            >
              <Typography.Title type="success" level={4}>
                Upload Card codes CSV
              </Typography.Title>
              <Typography.Text type="secondary">
                You can upload CSV file with maximum size is 10MB
              </Typography.Text>
            </Space>
          </Col>
          <Col span={24}>
            <SpaceVertical>
              <RequiredLabel>Country</RequiredLabel>
              <Select
                showSearch
                filterOption={(inputValue, option) =>
                  option?.searchKey
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) as boolean
                }
                style={{ width: '100%' }}
                placeholder="Select country"
                options={countryOptions}
                value={formData.country}
                onChange={(e) => setFormData({ ...formData, country: e })}
              />
            </SpaceVertical>
          </Col>
          <Col span={24}>
            <Space style={{ width: '100%' }} direction="vertical">
              <Spin spinning={isLoading}>
                <Upload.Dragger
                  accept=".csv"
                  maxCount={1}
                  className={error ? 'upload-file error' : 'upload-file'}
                  beforeUpload={() => false}
                  onChange={(file) => onChangeCardCodes(file.file)}
                  showUploadList={false}
                  progress={{ strokeWidth: 2, showInfo: true }}
                >
                  {!file ? (
                    <Space direction="vertical" align="center">
                      <Image src={iconUpload} preview={false} />
                      <Typography.Text type="secondary">
                        Upload file
                      </Typography.Text>
                    </Space>
                  ) : (
                    <Space direction="vertical" size={12} align="center">
                      <Image src={csvLogo} preview={false} />
                      <Typography.Text>{file.name}</Typography.Text>
                    </Space>
                  )}
                </Upload.Dragger>
              </Spin>
              {error && (
                <Space align="center">
                  <IonIcon
                    style={{ color: '#ff4d4f' }}
                    name="alert-circle-outline"
                  />
                  <Typography.Text type="danger">
                    Upload file size exceeds 10MB limit
                  </Typography.Text>
                </Space>
              )}
            </Space>
          </Col>
          <Col>
            <Space size={20}>
              <Button onClick={onRemove} style={{ width: 130 }} ghost>
                Reset
              </Button>
              <Button
                onClick={() => onUpload()}
                style={{ width: 130 }}
                type="primary"
              >
                Upload
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default UploadCSV
