import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import FormSetup from './FormSetup'

import { usePlayerSeason } from 'hooks/player-leaderboard/usePlayerSeason'
import { useUpdatePlayerSeasonMutation } from 'hooks/player-leaderboard/useUpdatePlayerSeasonMutation'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import { UpdatePlayerSeasonDto } from 'types/player-leaderboard/player-leaderboard-season.type'

type FromUpdateProps = {
  seasonId?: number
}

function FormUpdate({ seasonId }: FromUpdateProps) {
  const { data: initialData } = usePlayerSeason(seasonId)

  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutate, isLoading: mutateLoading } = useUpdatePlayerSeasonMutation()
  const onMutate = (seasonData: UpdatePlayerSeasonDto, seasonId?: number) => {
    mutate(
      { dto: seasonData, seasonId: seasonId! },
      {
        onError: (error) => {
          notifyError(error)
        },
        onSuccess: async () => {
          notifySuccess('Update season')
          await queryClient.invalidateQueries('GET_PLAYER_SEASONS')
          await queryClient.invalidateQueries('GET_PLAYER_SEASON')
          navigate(ROUTES.PLAYER_LEADERBOARD.SEASON_MANAGEMENT)
        },
      },
    )
  }

  if (!initialData) return null

  return (
    <FormSetup
      initialSeasonData={initialData}
      onMutate={onMutate}
      mutateLoading={mutateLoading}
    />
  )
}

export default FormUpdate
