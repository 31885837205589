import { useQuery } from 'react-query'

import { FindTeamLeaderBoardDto } from 'services/bountySystem/leaderboard/type'
import { LeaderboardService } from 'services/bountySystem/leaderboard/leaderboard'

import { DataList } from 'types'
import { ITeamLeaderboard } from 'types/leaderboard.type'

export const useTeamsLeaderboard = (payload: FindTeamLeaderBoardDto) => {
  const { data, ...other } = useQuery<DataList<ITeamLeaderboard[]>>({
    queryKey: ['GET_TEAM_LEADERBOARD_DATA', payload],
    queryFn: () => LeaderboardService.findTeams(payload),
  })
  return {
    total: data?.total,
    data: data?.data,
    ...other,
  }
}
