import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { CountParticipantByProgramDto } from 'types/general-leaderboard/general-leaderboard.type'

export const usePlayerTotalParticipantsByProgram = (
  dto: CountParticipantByProgramDto,
) =>
  useQuery({
    queryKey: ['COUNT_PLAYER_PARTICIPANTS_BY_PROGRAM', dto],
    queryFn: () => PlayerLeaderboardService.countParticipantsByProgram(dto),
    enabled: !!dto.seasonId && !!dto.programIds.length,
  })
