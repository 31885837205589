import moment from 'moment'

import { Typography } from 'antd'

import OverallAction from './overallAction'
import ActivityAction from './activityAction'

import {
  ActivityType,
  ActivityTypeLabel,
  AffiliationSubscriptionStatus,
  AffiliationStatusLabel,
  ISubscriptionOverall,
  ISubscriptionActivity,
} from 'types/affiliation.type'

import { DATE_FORMAT, TIME_FORMAT } from 'constant'

export const COLUMN_OVERALL = [
  {
    title: 'Game Name',
    dataIndex: 'name',
    render: (name: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {name}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Total money spent',
    dataIndex: 'totalMoney',
    render: (totalMoney: number) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        $ {totalMoney}
      </Typography.Text>
    ),
  },
  {
    title: 'Affiliation status',
    dataIndex: 'status',
    render: (status: AffiliationSubscriptionStatus) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {AffiliationStatusLabel[status]}
      </Typography.Text>
    ),
  },
  {
    title: 'Active and Expire dates',
    dataIndex: 'activeDate',
    render: (text: string, record: any) => (
      <Typography.Text>
        {moment.utc(record.activeDate).format(DATE_FORMAT)} (UTC) -{' '}
        {moment.utc(record.expirationDate).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: '',
    width: 62,
    render: (data: ISubscriptionOverall) => <OverallAction item={data} />,
  },
]

export const COLUMN_ACTIVITIES = [
  {
    title: 'Game Name',
    dataIndex: 'name',
    render: (name: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {name}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Activity',
    dataIndex: 'type',
    render: (type: ActivityType) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {ActivityTypeLabel[type]}
      </Typography.Text>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (amount: number) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        ${amount}
      </Typography.Text>
    ),
  },
  {
    title: 'Time',
    dataIndex: 'activityDate',
    render: (activityDate: string) => (
      <Typography.Text>
        {moment.utc(activityDate).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: '',
    width: 62,
    render: (data: ISubscriptionActivity) => <ActivityAction item={data} />,
  },
]
