import { useCallback } from 'react'

import { Tag } from 'antd'
import { ShippingStatus, SHIPPING_STATUS_LABEL } from 'types/order.type'

const STATUS_COLOR: Record<ShippingStatus, number[]> = {
  [ShippingStatus.Done]: [30, 175, 106],
  [ShippingStatus.Fail]: [219, 70, 70],
  [ShippingStatus.Preparing]: [215, 166, 71],
  [ShippingStatus.Sending]: [66, 186, 176],
}

const ShippingState = ({ state }: { state: ShippingStatus }) => {
  const tagColor = useCallback(
    (opacity = 1) => {
      const color = STATUS_COLOR[state]
      return `rgba(${color[0]},${color[1]},${color[2]},${opacity})`
    },
    [state],
  )

  return (
    <Tag
      style={{
        margin: 0,
        borderRadius: 28,
        background: tagColor(0.2),
        textTransform: 'capitalize',
        color: tagColor(),
        padding: '4px 10px',
      }}
    >
      {SHIPPING_STATUS_LABEL[state]}
    </Tag>
  )
}

export default ShippingState
