import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import DiscordBounty from './discordBounty'
import DappBounty from './dappBounty'

import { BountyCategory, MissionType } from 'constant/bounty'
import { useBountySetup } from '../index'

const ContentByType = () => {
  const bountyType = useParams().bountyType
  const [bountyData] = useBountySetup()

  const isDiscordContent = useMemo(() => {
    if (bountyType) return bountyType === MissionType.Discord
    return bountyData.category === BountyCategory.Discord
  }, [bountyData.category, bountyType])

  if (isDiscordContent) return <DiscordBounty />
  return <DappBounty />
}

export default ContentByType
