export const HEADER_OPTION_DEFAULT = [
  {
    value: 'Accept',
    label: 'Accept',
  },
  {
    value: 'Accept-Encoding',
    label: 'Accept Encoding',
  },
  {
    value: 'Authorization',
    label: 'Authorization',
  },
  {
    value: 'Connection',
    label: 'Connection',
  },
  {
    value: 'Host',
    label: 'Host',
  },
  {
    value: 'Origin',
    label: 'Origin',
  },
  {
    value: 'Referer',
    label: 'Referer',
  },
  {
    value: 'User-Agent',
    label: 'User Agent',
  },
  {
    value: 'X-API-Key',
    label: 'X-API-Key',
  },
]
