import React from 'react'

import { Button, Col, Form, Input, Row, Typography } from 'antd'

import {
  MinusCircleOutlined as MinusCircleIcon,
  PlusCircleOutlined as PlusCircleIcon,
} from '@ant-design/icons/lib/icons'

import RequiredLabel from 'components/systems/requiredSymbol'

import { COMMUNITY_PROFILE_DEFAULT_SECTION } from 'constant/community-profile'

function FormItemTeamMembers() {
  return (
    <Form.List name="members">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Row
              align="top"
              justify="end"
              gutter={8}
              style={{ position: 'relative' }}
            >
              <Col span={4} style={{ marginTop: 10, height: '100%' }}>
                {index === 0 && <RequiredLabel children="Team member" />}
              </Col>

              <Col span={19}>
                <Row justify="center">
                  <Typography.Text type="warning" style={{ marginBottom: 12 }}>
                    Member {index + 1}
                  </Typography.Text>
                </Row>

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      key={field.name}
                      label={<RequiredLabel children="Twitter" />}
                      name={[field.name, 'twitterUsername']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Title is required!',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Twitter username" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      key={field.name}
                      label={<RequiredLabel children="Description" />}
                      name={[field.name, 'description']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Description is required!',
                        },
                      ]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={1} style={{ position: 'unset' }}>
                <Row justify="end" align="middle">
                  <MinusCircleIcon
                    style={{
                      color: 'red',
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      fontSize: 16,
                    }}
                    onClick={() => remove(field.name)}
                  />
                </Row>
              </Col>
            </Row>
          ))}
          <Form.Item wrapperCol={{ span: 20 }}>
            <Row justify="center" style={{ width: '100%' }}>
              <Button
                type="dashed"
                onClick={() => {
                  add(COMMUNITY_PROFILE_DEFAULT_SECTION.members[0])
                }}
                icon={<PlusCircleIcon />}
              >
                Add Team Member
              </Button>
            </Row>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default FormItemTeamMembers
