import { useQuery } from 'react-query'

import { AffiliationService } from 'services/affiliation-v2'

export const useSeasonAffiliation = (seasonId?: string) => {
  return useQuery({
    queryKey: ['GET_SEASON_AFFILIATION', seasonId],
    queryFn: () => AffiliationService.findSeason(seasonId),
    enabled: !!seasonId,
  })
}
