import { bountySystemApi as api } from 'services/base-axios'

import { CreateMultiCertificate } from 'services/bountySystem/certificate/type'
import { BountyCertData } from 'services/bountySystem/bounty'

const TASK_CERTIFICATE = '/task-certificate'
const BOUNTY_CERTIFICATE = '/receipt'

class Certificate {
  async getListTaskCertificate(taskId = ''): Promise<BountyCertData[]> {
    return api
      .get(TASK_CERTIFICATE, { params: { taskId } })
      .then((res) => res.data)
  }

  async deleteOne(certId: string) {
    return api.delete(TASK_CERTIFICATE + `/${certId}`)
  }
  async createMultiTaskCertificate(params: CreateMultiCertificate) {
    return api.post(TASK_CERTIFICATE, params, {
      timeout: 5 * 60 * 1000,
    })
  }
  async getListBountyCertificate() {
    return api.get(BOUNTY_CERTIFICATE)
  }
}

export const CertificateService = new Certificate()
