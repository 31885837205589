import { Select } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ChainID, OptionElement } from 'constant'

export const CHAIN_INPUT = [
  new OptionElement('BSC', ChainID.BSC),
  new OptionElement('ETH', ChainID.Ethereum),
  new OptionElement('A8', ChainID.A8),
  new OptionElement('ARBITRUM', ChainID.Arbitrum),
  new OptionElement('POLYGON', ChainID.Polygon),
  new OptionElement('SOLANA', ChainID.Solana, true),
  new OptionElement('RONIN', ChainID.Ronin),
  new OptionElement('OPTIMISM', ChainID.Optimism),
  new OptionElement('BASE', ChainID.Base),
]

type SelectChainInputProps = {
  disabled: boolean
  defaultValue?: ChainID
  chainList?: OptionElement[]
  onSelect: (chain: string) => void
}

const SelectChainInput = ({
  onSelect,
  disabled,
  chainList = CHAIN_INPUT,
  defaultValue,
}: SelectChainInputProps) => {
  const sortChain = (chainA: OptionElement, chainB: OptionElement) => {
    return chainA.value > chainB.value ? 1 : -1
  }

  return (
    <Select
      options={chainList.sort(sortChain)}
      defaultValue={defaultValue === undefined ? ChainID.BSC : defaultValue}
      onSelect={(chain) => {
        onSelect(chain)
      }}
      disabled={disabled}
      suffixIcon={
        <IonIcon
          style={{ fontSize: 18, fontWeight: 700, color: '#42bbb0' }}
          name="chevron-down-outline"
        />
      }
    />
  )
}

export default SelectChainInput
