import { useMemo, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { bountySystemApi as api } from 'services/base-axios'
import { notifyError, notifySuccess } from 'helper'

const useCheckIn = (fromDate: string, toDate: string, rewardId?: string) => {
  const [loading, setLoading] = useState(false)

  const { data, refetch } = useQuery(
    'DAILY_CHECK_IN_CERTS',
    () => api.get('/check-in/cert'),
    { staleTime: 60000 },
  )

  const isChecked = useMemo(() => {
    if (!data?.data.certs) return false
    for (const cert of data?.data.certs) {
      if (
        Number(moment.utc(fromDate).format('YYYYMMDD')) === cert.ymd &&
        Number(moment.utc(toDate).format('YYYYMMDD')) === cert.ymd
      )
        return true
    }
    return false
  }, [data?.data.certs, fromDate, toDate])

  const isClaimed = useMemo(() => {
    if (!data?.data.receipts) return false
    for (const receipt of data?.data.receipts) {
      if (fromDate === receipt.fromDate && toDate === receipt.toDate)
        return true
    }
    return false
  }, [data?.data.receipts, fromDate, toDate])

  const canCheckIn = useMemo(() => {
    return (
      !isChecked &&
      moment.utc(fromDate).format('YYYYMMDD') ===
        moment.utc().format('YYYYMMDD') &&
      moment.utc(toDate).format('YYYYMMDD') === moment.utc().format('YYYYMMDD')
    )
  }, [fromDate, isChecked, toDate])

  const canClaim = useMemo(() => {
    if (!data?.data) return false
    const { certs } = data.data
    let checkInAmount = 0
    for (const cert of certs) {
      if (
        cert.ymd >= Number(moment.utc(fromDate).format('YYYYMMDD')) &&
        cert.ymd <= Number(moment.utc(toDate).format('YYYYMMDD'))
      )
        checkInAmount++
    }
    // TODO: Validate threshold
    const threshold = Math.abs(moment.utc(fromDate).diff(toDate, 'days')) + 1
    return checkInAmount === threshold && !isClaimed && !!rewardId
  }, [data?.data, fromDate, isClaimed, rewardId, toDate])

  const onClaim = async () => {
    try {
      if (isClaimed) return
      setLoading(true)
      if (!isChecked && fromDate === toDate) {
        await api.post('/check-in/fork', { date: toDate })
      }
      if (!!rewardId) {
        await api.post('/check-in/claim-reward', {
          rewardId: rewardId,
        })
        notifySuccess('Claimed')
      }
    } catch (error) {
      notifyError(error)
    } finally {
      refetch()
      setLoading(false)
    }
  }

  return { isChecked, isClaimed, onClaim, loading, canClaim, canCheckIn }
}

export default useCheckIn
