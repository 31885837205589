import { DEFAULT_GACHA_PRICE } from 'constant/gacha'

import type { IGachaBaseInfo } from 'types/gacha.type'
import { IVariant } from 'types/product.type'

export const formatGachaPrice = (baseInfo: IGachaBaseInfo) => {
  const variants = baseInfo.variants.map((prices) => {
    const variant: IVariant = { ...prices }

    if (!prices.priceCCP) {
      variant.priceCCP = DEFAULT_GACHA_PRICE.priceCCP
      variant.originalPriceCCP = DEFAULT_GACHA_PRICE.originalPriceCCP
    }

    if (!prices.priceBadges?.length) {
      variant.priceBadges = DEFAULT_GACHA_PRICE.priceBadges
    }

    if (!prices.priceToken?.price) {
      variant.priceToken = DEFAULT_GACHA_PRICE.priceToken
      variant.originalPriceToken = DEFAULT_GACHA_PRICE.originalPriceToken
    }

    if (!prices?.originalPriceToken?.price) {
      variant.originalPriceToken = DEFAULT_GACHA_PRICE.originalPriceToken
    }

    return variant
  })

  baseInfo.variants = variants

  return baseInfo
}
