import { Fragment, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import FormDigitalItem from './formDigital'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import useHandleProduct from 'hooks/useHandleProduct'
import useHandleProductSKU from 'hooks/useHandleProductSKU'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'

import { DEFAULT_BASIC_INFO_DATA, ProductType } from 'constant/marketplace'
import { AttributeConfig } from 'services/marketplace/product-category.type'
import { useCreateProductData } from './index'
import { IBasicInformation, ISKU } from 'types/product.type'

const EditDigitalItem = () => {
  const [basicInformation, setBasicInformation] = useState<IBasicInformation>(
    DEFAULT_BASIC_INFO_DATA,
  )
  const [community] = useCurrentCommunity()
  const [listSKU, setListSKU] = useState<(ISKU & { _id?: string })[]>([])
  const [specData, setSpecData] = useState<Record<string, string>>({})
  const [variations, setVariations] = useState<AttributeConfig[]>([])
  const [deliveries, setDeliveries] = useState<AttributeConfig[]>([])
  const [globalData, setGlobalData] = useCreateProductData()
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)
  const productId = useParams().productId || ''

  const { loading, getProductData, onUpdateProduct } = useHandleProduct()
  const { getSKUByProductId } = useHandleProductSKU()

  const onUpdate = useCallback(async () => {
    const data = {
      ...globalData,
      ...basicInformation,
      specification: specData,
      variationAttributes: variations,
      deliveryAttributes: deliveries,
      SKUs: listSKU,
      productId,
    }
    onUpdateProduct({ productId, data })
  }, [
    basicInformation,
    deliveries,
    globalData,
    listSKU,
    onUpdateProduct,
    productId,
    specData,
    variations,
  ])

  useEffect(() => {
    ;(async () => {
      if (!productId) return
      const data = await getProductData(productId)

      // set productData
      setGlobalData(data)
      setBasicInformation({
        communityId: community?._id,
        title: data.title,
        categoryId: data.categoryId,
        chainId: data.chainId,
        description: data.description,
        productType: data.productType as ProductType,
        thumbnails: data.thumbnails,
        web3ItemId: data.web3ItemId,
        slug: data.slug,
      })

      const { data: SKUs } = await getSKUByProductId(productId)
      const { variationAttributes } = data

      setVariations(variationAttributes as any)
      setListSKU(SKUs)
      setDeliveries(data.deliveryAttributes as any)
      setSpecData(data.specification)
      setIsDataFetched(true)
    })()
  }, [
    community?._id,
    getProductData,
    getSKUByProductId,
    productId,
    setGlobalData,
  ])

  if (!isDataFetched) return <Fragment />

  return (
    <SelectChainContext.Provider
      value={{
        selectedChain: basicInformation.chainId,
        setSelectedChain: () => {},
      }}
    >
      <FormDigitalItem
        productId={productId}
        onOk={onUpdate}
        loading={loading}
        setListSKU={setListSKU}
        listSKU={listSKU}
        basicInformation={basicInformation}
        setBasicInformation={setBasicInformation}
        variations={variations}
        setVariations={setVariations}
        setDeliveryAttributes={setDeliveries}
      />
    </SelectChainContext.Provider>
  )
}

export default EditDigitalItem
