export const ROUTES = {
  BOUNTY: {
    ITEM: '/mission',
    INDEX: '/mission/mission-management',
    ADD_NEW: '/mission/mission-management/new',
    EDIT: '/mission/mission-management/edit',
    USER_COMPLETED: '/user-completed',
    REPORT: '/mission/summary-report',
    CCP_LEADERBOARD: '/mission/ccp-leaderboard',
    MULTIPLIER_MANAGEMENT: '/mission/multiplier',
    VIEW_MULTIPLIER: '/mission/multiplier/:multiplierId',
  },
  MEMBER: {
    INDEX: '/member',
  },
  NFT_BADGE: {
    INDEX: '/nft-badge',
    ADD_NEW: '/nft-badge/new',
  },
  MINT_NFT: {
    INDEX: '',
    NFT_1155: '/mint-bsc-nft-1155',
    // NFT_721: '/mint-nft-721',
    SOLANA: '/solana',
    A8: '/mint-a8-nft-1155',
  },
  COM_SETTING: {
    INDEX: '/community/setting',
  },
  DAILY_CHECK_IN: {
    INDEX: '/daily-check-in',
    REPORT: '/daily-check-in/summary-report',
  },
  REWARDS_CENTER: {
    INDEX: '/rewards-center',
    ITEMS: '/rewards-center/item-management',
    NEW_ITEM_DIGITAL: '/rewards-center/item-management/new-digital',
    NEW_ITEM_PHYSICAL: '/rewards-center/item-management/new-physical',
    NEW_ITEM_CODE: '/rewards-center/item-management/new-code',
    EDIT_ITEM_DIGITAL: '/rewards-center/item-management/edit-digital',
    EDIT_ITEM_PHYSICAL: '/rewards-center/item-management/edit-physical',
    EDIT_ITEM_CODE: '/rewards-center/item-management/edit-code',
    USER_SHIPPING: '/rewards-center/users-shipping',
    CATEGORY_MANAGEMENT: '/rewards-center/category-management',
    NEW_CATEGORY_MANAGEMENT: '/rewards-center/category-management/new',
    EDIT_CATEGORY_MANAGEMENT: '/rewards-center/category-management/edit',
    COLLECTION_MANAGEMENT: '/rewards-center/collection-management',
    NEW_COLLECTION_MANAGEMENT: '/rewards-center/collection-management/new',
    EDIT_COLLECTION_MANAGEMENT: '/rewards-center/collection-management/edit',
    DIGITAL_CARDS_MANAGEMENT: '/rewards-center/digital-cards-management',
    NEW_DIGITAL_CARDS: '/rewards-center/digital-cards-management/new',
    EDIT_DIGITAL_CARDS: '/rewards-center/digital-cards-management/edit',
  },
  COMMUNITIES: {
    INDEX: '/communities',
    MANAGEMENT: '/communities/management',
    NEW_COMMUNITY: '/communities/management/new-community',
    EDIT_COMMUNITY: '/communities/management/edit-community',
    EDIT_PROFILE: '/communities-overview/edit-profile',
  },
  AFFILIATION: {
    INDEX: '/affiliation',
    SUBSCRIPTION_MANAGEMENT: '/affiliation/subscription/management',
    TEAM_MANAGEMENT: '/affiliation/team/management',
    TEAM_DETAILS: '/affiliation/team/management/:teamId',
  },
  ACCELER8: {
    INDEX: '/acceler8',
    TASK_MANAGEMENT: '/acceler8/task/management',
    NEW_GAME_TASK: '/acceler8/task/management/new',
    NEW_SPACE3_TASK: '/acceler8/task/management/new-space3',
    EDIT_GAME_TASK: '/acceler8/task/management/edit/:taskId',
    EDIT_SPACE3_TASK: '/acceler8/task/management/edit-space3/:taskId',

    TEAM_MANAGEMENT: '/acceler8/team/management',
    TEAM_DETAILS: '/acceler8/team/management/:teamId',

    SUBSCRIPTION_MANAGEMENT: '/acceler8/subscription/management',

    MANAGE_SEASON: '/acceler8/season',
    NEW_SEASON: '/acceler8/season/new-season',
    EDIT_SEASON: '/acceler8/season/edit-season',
    VIEW_SEASON: '/acceler8/season/view-season',
    SYNC_RANKING: '/acceler8/season/sync-ranking',
  },
  PLAYER_LEADERBOARD: {
    INDEX: '/player-leaderboard',

    SEASON_MANAGEMENT: '/player-leaderboard/season',
    NEW_SEASON: '/player-leaderboard/season/new',
    EDIT_SEASON: '/player-leaderboard/season/edit/:seasonId',
    VIEW_SEASON: '/player-leaderboard/season/view/:seasonId',

    USER_MANAGEMENT: '/player-leaderboard/user',
    REDEMPTION_MANAGEMENT: '/player-leaderboard/redemption',
    EDIT_USER: '/player-leaderboard/user/edit/:userId',

    TASK_MANAGEMENT: '/player-leaderboard/task/management',
    NEW_GAME_TASK: '/player-leaderboard/task/management/new',
    NEW_SPACE3_TASK: '/player-leaderboard/task/management/new-space3',
    EDIT_GAME_TASK: '/player-leaderboard/task/management/edit/:taskId',
    EDIT_SPACE3_TASK: '/player-leaderboard/task/management/edit-space3/:taskId',
  },
  LEADERBOARD: {
    INDEX: '/leaderboard',
    DETAIL: '/leaderboard/:seasonId',
    NEW_SEASON: '/leaderboard/new-season',
    EDIT_SEASON: '/leaderboard/edit-season',

    TEAM_LEADERBOARD: '/leaderboard/teams',
    TEAM_LEADERBOARD_MANAGEMENT: '/leaderboard/teams/management',
    NEW_TEAM_LEADERBOARD: '/leaderboard/:seasonId/teams/management/new-team',
    EDIT_TEAM_LEADERBOARD: '/leaderboard/:seasonId/teams/management/edit-team',

    COMMUNITY_LEADERBOARD: '/leaderboard/communities',
    COMMUNITY_LEADERBOARD_MANAGEMENT: '/leaderboard/communities/management',
    NEW_COMMUNITY_LEADERBOARD:
      '/leaderboard/:seasonId/communities/management/new-team',
    EDIT_COMMUNITY_LEADERBOARD:
      '/leaderboard/:seasonId/communities/management/edit-team',
  },
  GACHA: {
    INDEX: '/gacha',
    MANAGEMENT: '/gacha/management',
    VIEW_DETAIL: '/gacha/management/view/:gachaId',
    NEW_GACHA: '/gacha/management/new',
    EDIT_GACHA: '/gacha/management/edit/:gachaId',
    MINT_TICKET: '/gacha/ticket/mint',
  },
  CONFIG: {
    INDEX: '/config',
    HEROBANNER: '/config/hero-banner',
    PRIORITIES: '/config/priorities',
  },
  CAMPAIGN: {
    INDEX: '/campaign',
    NEW_CAMPAIGN: '/campaign/new-campaign',
    EDIT_CAMPAIGN: '/campaign/edit-campaign',
  },
  AFFILIATION_V2: {
    INDEX: '/affiliation-v2',
    MANAGE_SEASON: '/affiliation-v2/season',
    NEW_SEASON: '/affiliation-v2/season/new-season',
    VIEW_SEASON: '/affiliation-v2/season/view-season/:seasonId',
  },
  ADS: {
    INDEX: '/ads',
    ADS_MANAGEMENT: '/ads/management',
    NEW_ADS: '/ads/management/new',
    EDIT_ADS: '/ads/management/edit/:adsId',
  },
  REDEMPTION: {
    INDEX: '/redemption',
    NEW_REDEMPTION: '/redemption/new',
    EDIT_REDEMPTION: '/redemption/edit/:redemptionId',
  },
  NFT_SERVICE: {
    ITEM: '/nft-service/item',
    INDEX: '/nft-service',
    VIEW_NFT: '/nft-service/view/:nftId',
  },
  WHITELIST: {
    INDEX: '/whitelist',
    WHITELIST_MANAGEMENT: '/whitelist/management',
    VIEW_DETAIL: '/whitelist/management/:whitelistId',
  },
}
