import { createGlobalState } from 'react-use'

import { Col, Row, Typography } from 'antd'
import ListNFT from './listNFT'
import Header from './header'

import './index.less'

export const useSearchKey = createGlobalState('')

const NftBadge = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Badge Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Header />
      </Col>
      <Col span={24}>
        <ListNFT />
      </Col>
    </Row>
  )
}
export default NftBadge
