import { useNavigate } from 'react-router-dom'

import { Card, Col, Row, Image, Typography, Dropdown, Divider } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import { MenuInfo } from 'rc-menu/lib/interface'
import { CollectionData } from 'services/marketplace/collection'

import bnbToken from 'static/images/token/bnb-token.svg'
import { ROUTES } from 'constant/routes'

type CardCollectionProps = {
  collection: CollectionData
}

const ITEMS = [{ label: 'View Details', key: 'detail' }]

const CardCollection = ({ collection }: CardCollectionProps) => {
  const navigate = useNavigate()

  const onItemClick = (e: MenuInfo) => {
    e.domEvent.stopPropagation()
    if (e.key === 'detail')
      navigate(
        `${ROUTES.REWARDS_CENTER.COLLECTION_MANAGEMENT}/${collection._id}`,
      )
  }

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      bordered
      className="card-nft"
      onClick={() => {
        navigate(
          `${ROUTES.REWARDS_CENTER.COLLECTION_MANAGEMENT}/${collection._id}`,
        )
      }}
    >
      <Row>
        <Col span={24}>
          <Image
            preview={false}
            src={collection.thumbnail}
            height={159}
            style={{ objectFit: 'cover' }}
          />
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown.Button
              type="text"
              style={{ width: 'auto' }}
              className="delete-dropdown"
              menu={{
                items: ITEMS,
                onClick: onItemClick,
              }}
              icon={
                <div className="delete-dropdown_content">
                  <IonIcon
                    name="ellipsis-vertical"
                    style={{ color: '#FFFFFF' }}
                  />
                </div>
              }
              trigger={['click']}
            />
          </div>
        </Col>
        <Col span={24} style={{ padding: 12 }}>
          <Row gutter={[4, 4]}>
            <Col span={24}>
              <Row justify="space-between">
                <Typography.Text type="success">
                  {collection?.totalNFT ?? 0} NFT
                </Typography.Text>
                <Image preview={false} src={bnbToken} width={13} />
              </Row>
            </Col>
            <Col span={24}>
              <Divider style={{ margin: 0 }} />
            </Col>
            <Col span={24}>
              <Typography.Title ellipsis style={{ color: '#B2BFCB' }} level={5}>
                {collection?.title ?? 'Default Name'}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default CardCollection
