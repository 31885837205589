import { bountySystemApi } from 'services/base-axios'

import { DataList } from 'types'
import {
  CreateRedemptionDto,
  FindRedemptionsDto,
  IRedemption,
  UpdateRedemptionDto,
} from 'types/redemption.type'

const REDEMPTION_CONTROLLER = '/admin/redemption'
class Redemption {
  async getList(dto?: FindRedemptionsDto) {
    const { data } = await bountySystemApi.get<DataList<IRedemption[]>>(
      REDEMPTION_CONTROLLER,
      {
        params: dto,
      },
    )
    return data
  }

  getById(redemptionId: string) {
    return bountySystemApi
      .get<IRedemption>(`${REDEMPTION_CONTROLLER}/${redemptionId}`)
      .then(({ data }) => data)
  }

  create(dto: CreateRedemptionDto) {
    return bountySystemApi
      .post<IRedemption>(REDEMPTION_CONTROLLER, dto)
      .then(({ data }) => data)
  }

  update(redemptionId: string, dto: UpdateRedemptionDto) {
    return bountySystemApi
      .patch<IRedemption>(`${REDEMPTION_CONTROLLER}/${redemptionId}`, dto)
      .then(({ data }) => data)
  }
}

export const RedemptionService = new Redemption()
