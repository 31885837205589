export enum SeasonTabs {
  Team = 'SEASON_TAB:TEAM',
  Community = 'SEASON_TAB:COMMUNITY',
}

export type FindSeasonsDto = {
  offset?: number

  limit?: number

  search?: string

  page?: number
}

export enum SeasonStage {
  InComing = 'SEASON_STAGE:IN_COMING',
  Live = 'SEASON_STAGE:LIVE',
  Archived = 'SEASON_STAGE:ARCHIVED',
}

export const STAGE_NAME_LABEL: Record<SeasonStage, string> = {
  [SeasonStage.InComing]: 'In-coming',
  [SeasonStage.Live]: 'Live',
  [SeasonStage.Archived]: 'Archived',
}

export interface ISeason {
  _id: string

  name: string

  slug: string

  stage: SeasonStage

  teamLeaderboardEnabled: boolean

  communityLeaderboardEnabled: boolean
}

export type CreateAndUpdateSeasonDto = {
  name: string

  slug: string

  stage: SeasonStage

  teamLeaderboardEnabled: boolean

  communityLeaderboardEnabled: boolean
}
