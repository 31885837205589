import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Col, Row, Table, Typography } from 'antd'

import NewAndFilterBar from './newAndFilterBar'
import CustomPagination from 'components/systems/pagination'
import { COLUMN_SEASON } from './column'
import FilterContext from './filterContext'

import { useAcceler8Seasons } from 'hooks/acceler8/useAcceler8Seasons'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { ROUTES } from 'constant/routes'

const ListSeason = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState<string>('')
  const page = Number(searchParams.get('page') ?? DEFAULT_PAGE)

  const setPage = useCallback(
    (_page: number) => {
      setSearchParams({ page: _page.toString() })
    },
    [setSearchParams],
  )

  const onChangeKeyword = useCallback(
    (keyword: string) => {
      setPage(DEFAULT_PAGE)
      setSearch(keyword)
    },
    [setPage, setSearch],
  )

  const {
    data,
    total,
    isLoading: loading,
  } = useAcceler8Seasons({
    search,
    limit: DEFAULT_PAGE_SIZE,
    offset: DEFAULT_PAGE_SIZE * (page - 1),
  })

  const totalPage = useMemo(() => {
    return Math.ceil(total / DEFAULT_PAGE_SIZE)
  }, [total])

  return (
    <FilterContext.Provider
      value={{ page, search, setPage, setSearch: onChangeKeyword }}
    >
      <Row gutter={[24, 64]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <NewAndFilterBar />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                /* TODO: implement later (or remove) */
                // rowSelection={{
                //   selectedRowKeys: bountySelected[status],
                //   onChange: onSelect,
                // }}
                className="table-border-collapse"
                columns={COLUMN_SEASON}
                dataSource={data}
                onRow={({ id }) => {
                  return {
                    onClick: () => {
                      navigate(`${ROUTES.ACCELER8.VIEW_SEASON}/${id}`)
                    },
                  }
                }}
                pagination={false}
                loading={loading}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align="middle">
            <Col flex="auto">
              <Typography.Text type="success">
                Total: {totalPage}
              </Typography.Text>
            </Col>
            <Col>
              <CustomPagination
                total={total}
                pageSize={DEFAULT_PAGE_SIZE}
                onChange={setPage}
                page={page}
              />
            </Col>
          </Row>
        </Col>
        {/* TODO: implement later (or remove) */}
        {/* <Col span={24}>
        <BountiesSelected
          updateBounties={updateBounties}
          bountySelected={bountySelected}
          setBountySelected={setBountySelected}
        />
      </Col> */}
      </Row>
    </FilterContext.Provider>
  )
}

export default ListSeason
