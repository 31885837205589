import { Button, Col, Modal, Row, Typography } from 'antd'

type ConfirmDeleteModalProps = {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}

function ConfirmDeleteModal({
  open,
  onConfirm,
  onCancel,
}: ConfirmDeleteModalProps) {
  return (
    <Modal
      centered
      width={320}
      open={open}
      footer={null}
      wrapClassName="confirm-delete-modal"
      maskClosable={true}
      closable={false}
      onCancel={onCancel}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Typography.Title level={3} className="confirm-title">
            Do you want to delete ALL?
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Paragraph className="confirm-description">
            If I delete this address, it will no longer be in the whitelist?
          </Typography.Paragraph>
        </Col>
        <Col span={24}>
          <Button className="cancel-btn" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="delete-btn" onClick={onConfirm}>
            Delete
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default ConfirmDeleteModal
