import { useCallback, useEffect, useState } from 'react'

import FormDigitalItem from './formDigital'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import useHandleProduct from 'hooks/useHandleProduct'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import { useCreateProductData } from './index'

import { convertTzUtcBeforeUpload } from 'helper'

import { ChainID } from 'constant'
import { DEFAULT_BASIC_INFO_DATA, ProductType } from 'constant/marketplace'

import { AttributeConfig } from 'services/marketplace/product-category.type'
import { IBasicInformation, ISKU } from 'types/product.type'

const CreateDigitalItem = () => {
  const [paymentChain, setPaymentChain] = useState(ChainID.BSC)
  const [basicInformation, setBasicInformation] = useState<IBasicInformation>(
    DEFAULT_BASIC_INFO_DATA,
  )

  const [community] = useCurrentCommunity()
  const [listSKU, setListSKU] = useState<ISKU[]>([])

  const [variations, setVariations] = useState<AttributeConfig[]>([])
  const [deliveries, setDeliveries] = useState<AttributeConfig[]>([])
  const [globalData] = useCreateProductData()

  const { loading, onCreateProduct } = useHandleProduct()

  useEffect(() => {
    setBasicInformation({
      communityId: community?._id,
      thumbnails: [],
      title: '',
      description: '',
      chainId: paymentChain,
      productType: ProductType.NFT,
      categoryId: '',
      web3ItemId: '',
      slug: '',
    })
  }, [paymentChain, community?._id])

  const onOk = useCallback(async () => {
    onCreateProduct({
      ...globalData,
      ...basicInformation,
      specification: {},
      variationAttributes: variations,
      deliveryAttributes: deliveries,
      SKUs: listSKU,
      startedAt: convertTzUtcBeforeUpload(),
    })
  }, [
    basicInformation,
    deliveries,
    globalData,
    onCreateProduct,
    listSKU,
    variations,
  ])

  return (
    <SelectChainContext.Provider
      value={{
        selectedChain: paymentChain,
        setSelectedChain: setPaymentChain,
      }}
    >
      <FormDigitalItem
        onOk={onOk}
        loading={loading}
        setListSKU={setListSKU}
        listSKU={listSKU}
        basicInformation={basicInformation}
        setBasicInformation={setBasicInformation}
        variations={variations}
        setVariations={setVariations}
        setDeliveryAttributes={setDeliveries}
      />
    </SelectChainContext.Provider>
  )
}

export default CreateDigitalItem
