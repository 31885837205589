import { useMemo } from 'react'

import { Select } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import { useProductCategories } from 'hooks/product/useProduct'

type FilterCategoriesProps = {
  categoryId?: string
  onSelect?: React.Dispatch<React.SetStateAction<string | undefined>>
}
const DEFAULT_ITEM = { label: 'All Categories', value: '' }

const FilterCategories = ({
  categoryId = '',
  onSelect,
}: FilterCategoriesProps) => {
  const categories = useProductCategories()
  const filterCategories = useMemo(
    () =>
      categories.map((category) => ({
        label: category.title,
        value: category._id,
      })),
    [categories],
  )
  return (
    <Select
      value={categoryId}
      options={[DEFAULT_ITEM, ...filterCategories]}
      menuItemSelectedIcon={<IonIcon name="checkmark-outline" />}
      suffixIcon={
        <IonIcon name="filter-outline" style={{ color: '#42BAB0' }} />
      }
      className="dropdown-btn dropdown-filter"
      onChange={onSelect}
    />
  )
}

export default FilterCategories
