import { Typography } from 'antd'

import Action from './action'

import { ColumnType } from 'antd/lib/table'
import { ITeamLeaderboard } from 'types/leaderboard.type'

export const COLUMN_TEAM_LEADERBOARD: ColumnType<ITeamLeaderboard>[] = [
  {
    title: 'Team Name',
    dataIndex: 'name',
    render: (title: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {title}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Point',
    dataIndex: 'point',
    width: 62,
    render: (point: number) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {point}
      </Typography.Paragraph>
    ),
  },
  {
    title: '',
    dataIndex: '_id',
    width: 62,
    render: (text: string, record: any) => (
      <Action teamId={record._id} seasonId={record.seasonId} />
    ),
  },
]
