/**
 * Constructor
 */
import { Env } from 'configs/env'

type Config = {
  SP3CommunityId: string
}

const configs: Record<Env, Config> = {
  development: {
    SP3CommunityId: '63b712f6fb91dddc10ac5ae6',
  },
  staging: {
    SP3CommunityId: '63b712f6fb91dddc10ac5ae6',
  },
  production: {
    SP3CommunityId: '63ef24a762648e70d1e557e3',
  },
}

/**
 * Module exports
 */
export default configs
