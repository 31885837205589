import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Acceler8Service } from 'services/acceler8'
import { Acceler8TaskService } from 'services/acceler8-task'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import {
  TaskPartnerConfigs,
  TaskStatus,
  AC8TaskType,
  UpdateAcceler8TaskWithUpdateProgramTaskDto,
} from 'types/acceler8/acceler8-task.type'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

type UseUpdateAcceler8TaskProps = {
  taskId: string
  dto: UpdateAcceler8TaskWithUpdateProgramTaskDto
}

export const useUpdateAcceler8Task = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationKey: ['UPDATE_ACCELER8_TASK'],
    mutationFn: async ({ taskId, dto }: UseUpdateAcceler8TaskProps) => {
      if (!taskId) return

      const { subscriptionId, points, programId, type } = dto
      const configs = dto.configs as TaskPartnerConfigs

      await Acceler8Service.updateProgram(programId, {
        subscriptionId,
        points,
      })

      if (type === AC8TaskType.FederatedApi && configs) {
        const headers: Record<string, string> = {}
        configs.meta.headers?.map((value: any) => {
          headers[value.key] = value.value
          return null
        })
        await Acceler8TaskService.updateFederatedAPI(
          configs.federatedApiId || '',
          {
            pattern: configs.meta.endpoint || '',
            headers,
          },
        )
      }

      await Acceler8TaskService.updateTask(taskId, dto)

      navigate(
        dto.programType === ProgramType.Team
          ? ROUTES.ACCELER8.TASK_MANAGEMENT
          : ROUTES.PLAYER_LEADERBOARD.TASK_MANAGEMENT,
      )
    },
    onError: () => {
      notifyError('Update task failed!')
    },
  })
}

export const useUpdateAcceler8TaskStatus = () => {
  return useMutation({
    mutationKey: ['UPDATE_ACCELER8_TASK_STATUS'],
    mutationFn: async ({
      taskId,
      status,
    }: {
      taskId: string
      status: TaskStatus
    }) => {
      if (!taskId) return

      await Acceler8TaskService.updateTask(taskId, { status })
    },
    onSuccess: () => {
      notifySuccess('Update task')
    },
    onError: () => {
      notifyError('Update task failed!')
    },
  })
}
