import { useEffect, useState } from 'react'

import { Col, Row, Input, Typography, Button, Space } from 'antd'
import Loading from 'components/systems/loading'
import UploadPicture from 'components/systems/uploadPicture'
import { onConvertFileList } from 'components/systems/uploadPicture/upload'

import { useCommunity } from 'hooks/community/useCommunity'
import { useStorage } from 'hooks/systems/useStorage'

import { bountySystemApi as api } from 'services/base-axios'
import { notifyError, notifySuccess } from 'helper'

import { UploadFile } from 'antd/lib/upload'

const Profile = () => {
  const [loading, setLoading] = useState(false)
  const { data } = useCommunity()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [logo, setLogo] = useState<UploadFile[]>()

  const { onUpload } = useStorage(604800)

  useEffect(() => {
    if (!data?.data) return
    setTitle(data.data.title)
    setDescription(data.data.description)
    if (data.data.thumbnail) {
      setLogo(onConvertFileList([data.data.thumbnail]))
    }
  }, [data?.data])

  const onSave = async () => {
    try {
      setLoading(true)
      const data: { title: string; description: string; thumbnail?: string } = {
        title,
        description,
      }
      if (logo && logo[0].originFileObj)
        data.thumbnail = await onUpload(logo[0])
      await api.patch('/community', data)
      notifySuccess('Updated Profile')
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  if (!data) return <Loading loading />

  return (
    <Row gutter={[24, 24]} style={{ paddingTop: 20, maxWidth: 486 }}>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">
            Community Name (5-64 characters)
          </Typography.Text>
          <Input
            value={title}
            placeholder="Enter community name..."
            onChange={(e) => setTitle(e.target.value)}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">Community Logo</Typography.Text>
          <UploadPicture
            fileList={logo}
            onChangeFile={({ fileList }) => setLogo(fileList)}
            maxCount={1}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">
            Community Introduction (5-800 characters)
          </Typography.Text>
          <Input.TextArea
            value={description}
            placeholder="Enter community introduction..."
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Button type="primary" onClick={onSave} loading={loading}>
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default Profile
