import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { Button, Col, Row, Space, Typography } from 'antd'

import Clipboard from 'components/systems/clipboard'
import SpaceVertical from 'components/systems/spaceVertical'

import { useAffiliationTeamData } from 'hooks/affiliation/useTeamData'
import { AffiliationService } from 'services/affiliation-v2'

import { notifyError } from 'helper'
import { exportToFile } from 'helper/common'

import { TIME_FORMAT, TypeFiles } from 'constant'

const TeamDetails = () => {
  const teamId = useParams().teamId || ''
  const [isExporting, setExporting] = useState(false)

  const { data } = useAffiliationTeamData(parseInt(teamId))

  const { mutate } = useMutation({
    mutationKey: ['EXPORT_TEAM_ANALYTICS', teamId],
    mutationFn: async () => {
      setExporting(true)

      const response = await AffiliationService.exportTeamAnalytics(
        parseInt(teamId),
      )

      exportToFile(response.data, 'team-analytics', TypeFiles.Csv)

      setExporting(false)
    },
    onError: (error: any) => {
      setExporting(false)
      notifyError(error)
    },
  })

  const onExport = () => {
    mutate()
  }

  return (
    <Row gutter={[24, 36]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {data?.teamName}
        </Typography.Title>
      </Col>

      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Team Leader</Typography.Text>
                  <Space>
                    <Typography.Text type="secondary">
                      {data?.leaderId}
                    </Typography.Text>
                    <Clipboard content={data?.leaderId || ''} />
                  </Space>
                </SpaceVertical>
              </Col>
              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Leader's Telegram ID</Typography.Text>
                  <Typography.Text type="secondary">
                    {data?.leaderTelegramId}
                  </Typography.Text>
                </SpaceVertical>
              </Col>

              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Creation time</Typography.Text>
                  <Typography.Text type="secondary">
                    {moment.utc(data?.createdAt).format(TIME_FORMAT)} (UTC)
                  </Typography.Text>
                </SpaceVertical>
              </Col>
              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>
                    Last point contribution time
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {moment.utc(data?.lastContributionTime).format(TIME_FORMAT)}{' '}
                    (UTC)
                  </Typography.Text>
                </SpaceVertical>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Total members</Typography.Text>
                  <Typography.Text type="secondary">
                    {data?.totalMember}
                  </Typography.Text>
                </SpaceVertical>
              </Col>

              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Current season rank</Typography.Text>
                  <Typography.Text type="secondary">
                    {/* TODO: call api by currentSeasonRankId to get rank detail */}
                    {data?.currentSeasonRankId}st
                  </Typography.Text>
                </SpaceVertical>
              </Col>

              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Total points current season</Typography.Text>
                  <Typography.Text type="secondary">
                    {data?.totalPointsCurrentSeason}
                  </Typography.Text>
                </SpaceVertical>
              </Col>

              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Last season rank</Typography.Text>
                  <Typography.Text type="secondary">
                    {/* TODO: call api by lastSeasonRankId to get rank detail */}
                    {data?.lastSeasonRankId}st
                  </Typography.Text>
                </SpaceVertical>
              </Col>

              <Col span={24}>
                <SpaceVertical>
                  <Typography.Text>Total accumulative points</Typography.Text>
                  <Typography.Text type="secondary">
                    {data?.totalAccumulativePoints}
                  </Typography.Text>
                </SpaceVertical>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Button ghost onClick={onExport} loading={isExporting}>
          Export this team analytics
        </Button>
      </Col>
    </Row>
  )
}

export default TeamDetails
