import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useSearchParams, generatePath } from 'react-router-dom'

import { Col, Row, Table, Typography } from 'antd'

import CustomPagination from 'components/systems/pagination'
import NewAndFilterBar from './new-and-filter-bar'
import FilterContext from './FilterContext'

import { usePlayerSeasons } from 'hooks/player-leaderboard/usePlayerSeasons'

import { COLUMN_PLAYER_SEASON } from './column'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { ROUTES } from 'constant/routes'

function ListPlayerSeason() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState<string>('')
  const page = Number(searchParams.get('page') ?? DEFAULT_PAGE)

  const setPage = useCallback(
    (_page: number) => {
      setSearchParams({ page: _page.toString() })
    },
    [setSearchParams],
  )

  const onChangeKeyword = useCallback(
    (keyword: string) => {
      setPage(DEFAULT_PAGE)
      setSearch(keyword)
    },
    [setPage, setSearch],
  )

  const {
    data,
    total,
    isLoading: loading,
  } = usePlayerSeasons({
    search,
    limit: DEFAULT_PAGE_SIZE,
    offset: DEFAULT_PAGE_SIZE * (page - 1),
  })

  const totalPage = useMemo(() => {
    return Math.ceil(total / DEFAULT_PAGE_SIZE)
  }, [total])

  return (
    <FilterContext.Provider
      value={{ page, search, setPage, setSearch: onChangeKeyword }}
    >
      <Row gutter={[24, 64]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <NewAndFilterBar />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                className="table-border-collapse"
                columns={COLUMN_PLAYER_SEASON}
                dataSource={data}
                onRow={({ id }) => ({
                  onClick: () => {
                    navigate(
                      generatePath(ROUTES.PLAYER_LEADERBOARD.VIEW_SEASON, {
                        seasonId: id,
                      }),
                    )
                  },
                })}
                pagination={false}
                loading={loading}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align="middle">
            <Col flex="auto">
              <Typography.Text type="success">
                Total: {totalPage}
              </Typography.Text>
            </Col>
            <Col>
              <CustomPagination
                total={total}
                pageSize={DEFAULT_PAGE_SIZE}
                onChange={setPage}
                page={page}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </FilterContext.Provider>
  )
}

export default ListPlayerSeason
