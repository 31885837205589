import { Col, Row, Typography } from 'antd'
import Hero from './hero'
import ChartStatistical from './chart'

const DailyCheckInReport = () => {
  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <Typography.Title type="success" level={2}>
          Report & Analytics
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Hero />
      </Col>
      <Col span={24}>
        <ChartStatistical />
      </Col>
    </Row>
  )
}
export default DailyCheckInReport
