import { Fragment, useMemo } from 'react'

import { Form, Input, InputNumber, Radio, Select } from 'antd'

import RequiredLabel from 'components/systems/requiredSymbol'
import FormItemSelectChain from 'components/systems/formItemSelectChain'

import { ChainID } from 'constant'
import { TokenStandard } from 'types/token.type'

type TransferTokenSectionProps = {
  supportNative?: boolean
}

const TransferTokenSection = ({
  supportNative = true,
}: TransferTokenSectionProps) => {
  const tokenType = Form.useWatch(['configs', 'tokenType'])

  const tokenTypeOptions = useMemo(() => {
    if (supportNative) {
      return Object.values(TokenStandard).map((tokenStandard) => ({
        value: tokenStandard,
        label: tokenStandard,
      }))
    }

    return Object.values(TokenStandard)
      .filter((token) => token !== TokenStandard.NATIVE)
      .map((tokenStandard) => ({
        value: tokenStandard,
        label: tokenStandard,
      }))
  }, [supportNative])
  return (
    <Fragment>
      <FormItemSelectChain
        name={['configs', 'chain']}
        initialValue={ChainID.A8}
        disabled={true}
      />
      <Form.Item
        name={['configs', 'tokenType']}
        label={<RequiredLabel children="Token type" />}
        rules={[
          {
            required: true,
            message: 'Please select token type',
          },
        ]}
      >
        <Select options={tokenTypeOptions} />
      </Form.Item>
      {tokenType !== TokenStandard.NATIVE && (
        <Form.Item
          name={['configs', 'tokenAddress']}
          label={<RequiredLabel children="Token address" />}
          rules={[
            {
              required: true,
              message: 'Please input token address',
            },
          ]}
        >
          <Input placeholder="Enter token address" />
        </Form.Item>
      )}
      {tokenType === TokenStandard.ERC1155 && (
        <Form.Item
          name={['configs', 'tokenId']}
          label={<RequiredLabel children="Token ID" />}
          rules={[
            {
              required: true,
              message: 'Please input Token ID',
            },
          ]}
        >
          <Input placeholder="Enter Token ID" />
        </Form.Item>
      )}

      <Form.Item
        name={['configs', 'contractAddress']}
        label={<RequiredLabel children="Interact with contract address" />}
        rules={[
          {
            required: true,
            message: 'Please input contract address',
          },
        ]}
      >
        <Input placeholder="Enter contract address" />
      </Form.Item>

      <Form.Item
        name={['configs', 'side']}
        label={<RequiredLabel children="Side" />}
      >
        <Radio.Group>
          <Radio value="from">From</Radio>
          <Radio value="to">To</Radio>
          <Radio value={undefined}>From or To</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name={['configs', 'amount']}
        label={<RequiredLabel children="Amount" />}
        rules={[
          {
            required: true,
            message: 'Please input amount',
          },
        ]}
      >
        <InputNumber placeholder="Enter amount" type="number" />
      </Form.Item>
    </Fragment>
  )
}

export default TransferTokenSection
