import { Fragment } from 'react'

import { Form } from 'antd'

import WeightFormItem from './shared/WeightFormItem'
import TypeFormItem from './shared/TypeFormItem'
import TierFormItem from './shared/TierFormItem'
import AvailableFormItem from './shared/AvailableFormItem'

import type { RewardItemProps } from '.'

function RewardCongratsItem({ prefixNamePath }: RewardItemProps) {
  return (
    <Fragment>
      <TierFormItem prefixNamePath={prefixNamePath} />

      {/*Available of EP, Badge and Congrats is Infinite*/}
      <AvailableFormItem
        prefixNamePath={prefixNamePath}
        initialValue={Number.MAX_SAFE_INTEGER}
        hidden
        noStyle
      />

      <Form.Item
        name={[prefixNamePath, 'config']}
        initialValue={{}}
        hidden
        noStyle
      />

      <WeightFormItem prefixNamePath={prefixNamePath} />

      {/* NOTE: for css dom correctly, type must be placed at the very bottom */}
      <TypeFormItem prefixNamePath={prefixNamePath} />
    </Fragment>
  )
}

export default RewardCongratsItem
