import { ChainID } from 'constant'
import { TokenStandard } from 'types/token.type'
import { FindDto } from 'types'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

export enum AC8TaskType {
  InteractSC = 'TASK_TYPE:INTERACT_SC',
  GasSpent = 'TASK_TYPE:GAS_SPENT',
  TokenSpent = 'TASK_TYPE:TOKEN_SPENT',
  USDSpent = 'TASK_TYPE:USD_SPENT',
  FederatedApi = 'TASK_TYPE:FEDERATED_API',
  Space3 = 'TASK_TYPE:SPACE3',
}

export const TaskTypeLabel: Record<AC8TaskType, string> = {
  [AC8TaskType.InteractSC]: 'Smart Contract Interaction',
  [AC8TaskType.GasSpent]: 'Gas Fee Spent',
  [AC8TaskType.FederatedApi]: 'Federated API',
  [AC8TaskType.TokenSpent]: 'Token spent',
  [AC8TaskType.USDSpent]: 'USD spent',
  [AC8TaskType.Space3]: 'Space3 Task',
}

export enum TaskCategory {
  Game = 'TASK_CATEGORY:GAME',
  Space3 = 'TASK_CATEGORY:SPACE3',
}

export enum TaskStatus {
  Active = 'TASK_STATUS:ACTIVE',
  Inactive = 'TASK_STATUS:INACTIVE',
  Expired = 'TASK_STATUS:EXPIRED',
}

export enum VerificationMethod {
  Default = 'VERIFY:DEFAULT',
}

export enum PartnerTaskProvider {
  Apeiron = 'TASK_PROVIDER:APEIRON',
  CommonPartner = 'TASK_PROVIDER:COMMON_PARTNER',
  OccupyWallStreet = 'TASK_PROVIDER:OCCUPYWALLSTREET',
}

export enum ApeironTaskAction {
  PlayTime = 'APEIRON:PLAYTIME',
}

export enum OccupyWallStreetTaskAction {
  RegisteredGame = 'OCCUPYWALLSTREET:REGISTERED',
}

export enum CommonPartnerTaskAction {
  VerifyAPI = 'PARTNER:VERIFY_API',
}

export enum ContractInteractionType {
  OneWay = 'CONTRACT_INTERACTION:ONE_WAY',
}

export interface TaskPartnerConfigs {
  federatedApiId?: string
  provider: PartnerTaskProvider
  action:
    | ApeironTaskAction
    | CommonPartnerTaskAction
    | OccupyWallStreetTaskAction
  meta?: any
}

export type TaskFederatedApiConfigs = {
  federatedApiId: string
}

export interface TaskInteractWithContractConfigs {
  chain: ChainID
  functionName: string
  inboundContractAddress: string
  contractInteractionType: ContractInteractionType
}

export interface TaskTokenSpentConfigs {
  chain: ChainID
  tokenType: TokenStandard
  tokenAddress: string
  contractAddress: string
  amount: number
  side?: 'from' | 'to'
}

export interface TaskSpace3Configs {
  missionId: string
  url: string
}

export type TaskConfigs =
  | TaskPartnerConfigs
  | TaskInteractWithContractConfigs
  | TaskSpace3Configs
  | TaskTokenSpentConfigs
  | TaskFederatedApiConfigs

export type FindAcceler8TaskDto = {
  seasonId?: number
  programType: ProgramType
} & FindDto

export interface IAcceler8Task {
  _id: string

  title: string

  description?: string

  communityId: string

  seasonId: number

  programId: number

  thumbnail?: string

  tutorial?: string

  isAccumulative: boolean

  category: TaskCategory

  type?: AC8TaskType

  configs: TaskConfigs

  verificationMethod: VerificationMethod

  participants: number

  status: TaskStatus

  startedAt: string

  endedAt: string

  recurrenceTime: number

  programType: ProgramType
}

export type Acceler8TaskGroupByGame = {
  _id: string
  count: number
  tasks: IAcceler8Task[]
}

export type CreateAcceler8TaskDto = Omit<IAcceler8Task, '_id'>

export type UpdateAcceler8TaskDto = Partial<CreateAcceler8TaskDto>

export type CreateAcceler8TaskWithCreateProgramDto = {
  subscriptionId: number
  points: number
} & IAcceler8Task

export type UpdateAcceler8TaskWithUpdateProgramTaskDto = {
  programId: number
} & Partial<CreateAcceler8TaskWithCreateProgramDto>
