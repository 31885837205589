import { Fragment, useState } from 'react'

import { Button, Col, Form, Row } from 'antd'

import { PlusOutlined as AddIcon } from '@ant-design/icons'

import SelectNFTBadge from 'components/systems/selectNFTBadge'
import InputLabel from 'components/systems/input/InputLabel'
import WeightFormItem from './shared/WeightFormItem'
import TypeFormItem from './shared/TypeFormItem'
import AvailableFormItem from './shared/AvailableFormItem'
import TierFormItem from './shared/TierFormItem'
import GachaBadgeDetails from '../GachaBadgeDetails'

import { BADGE_AMOUNT_MAX_LEN } from 'constant/badge'

import { type RewardItemProps } from '.'

function RewardBadgeItem({ prefixNamePath }: RewardItemProps) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Fragment>
      <TierFormItem prefixNamePath={prefixNamePath} />

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.rewards?.[prefixNamePath]?.config !==
          curValues?.rewards?.[prefixNamePath]?.config
        }
      >
        {({ getFieldValue, setFieldsValue, validateFields }) => {
          const namePath = ['rewards', prefixNamePath, 'config', 'badgeId']

          const badgeSelected = getFieldValue(namePath)

          return (
            <Fragment>
              <Row gutter={[10, 24]} align="middle">
                <Col>
                  <Form.Item
                    valuePropName="badgeId"
                    name={[prefixNamePath, 'config', 'badgeId']}
                    hidden={!badgeSelected}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: 'Please select a badge',
                      },
                    ]}
                  >
                    <GachaBadgeDetails
                      onRemove={() =>
                        setFieldsValue({
                          rewards: {
                            [prefixNamePath]: {
                              config: {
                                badgeId: '',
                                amount: 1,
                              },
                            },
                          },
                        })
                      }
                      width={107}
                      height={107}
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Button className="btn-square" onClick={() => setOpen(true)}>
                    <AddIcon />
                    {badgeSelected?.length ? 'Select Other' : 'Select Badge'}
                  </Button>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name={[prefixNamePath, 'config', 'amount']}
                    initialValue={1}
                    hidden={!badgeSelected}
                    style={{ margin: 0 }}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 1 || value > BADGE_AMOUNT_MAX_LEN) {
                            return Promise.reject(
                              new Error('Quantity must be between 1-50'),
                            )
                          }
                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <InputLabel.Number
                      label="Quantity of Badge"
                      placeholder="Enter quantity of badge"
                      min={1}
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <SelectNFTBadge
                open={open}
                badgeSelected={[badgeSelected] || []}
                setBadgeSelected={(value) => {
                  if (value.length < 1) return

                  const [badgeId] = value
                  if (badgeId === badgeSelected) return

                  setFieldsValue({
                    rewards: {
                      [prefixNamePath]: {
                        config: {
                          badgeId,
                          amount: 1,
                        },
                      },
                    },
                  })
                  validateFields([namePath])
                }}
                setOpen={setOpen}
              />
            </Fragment>
          )
        }}
      </Form.Item>

      {/*Available of EP, Badge and Congrats is Infinite*/}
      <AvailableFormItem
        prefixNamePath={prefixNamePath}
        initialValue={Number.MAX_SAFE_INTEGER}
        hidden
        noStyle
      />

      <WeightFormItem prefixNamePath={prefixNamePath} />

      {/* NOTE: for css dom correctly, type must be placed at the very bottom */}
      <TypeFormItem prefixNamePath={prefixNamePath} />
    </Fragment>
  )
}

export default RewardBadgeItem
