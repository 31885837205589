// react
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
// antd
import { Button, Col, Input, Row, Typography } from 'antd'
// components
import InputImage from 'components/systems/uploadPicture/inputImage'
import SpaceVertical from 'components/systems/spaceVertical'
// hooks
import { useProductSlug } from 'hooks/product/useProductSlug'
import useDebounce from 'hooks/useDebounce'
// services
import { generateSlug } from 'helper'
// constants
import { FormNoticeType } from 'constant'
// types
import { IBasicInformation } from 'types/product.type'

type UpdateProductInfoProps = {
  basicInformation: IBasicInformation
  setBasicInformation: Dispatch<SetStateAction<IBasicInformation>>
}

function UpdateProductInfo({
  basicInformation,
  setBasicInformation,
}: UpdateProductInfoProps) {
  const [isSlugError, setIsSlugError] = useState(false)
  const { checkIsSlugExisted } = useProductSlug()
  const slugDebounce = useDebounce(basicInformation.slug, 500)

  const handleChangeThumbnail = (value: string) => {
    setBasicInformation((prevState) => ({
      ...prevState,
      thumbnails: [value],
    }))
  }

  useEffect(() => {
    const slug = generateSlug(slugDebounce)
    setBasicInformation((prevState) => ({
      ...prevState,
      slug,
    }))
    checkIsSlugExisted(slug).then((isExisted) => {
      setIsSlugError(isExisted)
    })
  }, [slugDebounce, checkIsSlugExisted, setBasicInformation])

  return (
    <Row gutter={[0, 24]}>
      <Col span={12}>
        <SpaceVertical style={{ width: '100%' }} size={8}>
          <Typography.Text type="secondary" aria-required>
            Title
          </Typography.Text>
          <Input
            value={basicInformation.title}
            onChange={(value) => {
              setBasicInformation((prevState) => ({
                ...prevState,
                title: value.target.value,
              }))
            }}
          />
        </SpaceVertical>
      </Col>
      <Col span={12}></Col>
      <Col span={12}>
        <SpaceVertical style={{ width: '100%' }} size={8}>
          <Typography.Text type="secondary" aria-required>
            Slug
          </Typography.Text>
          <Input
            value={basicInformation.slug}
            onChange={(e) => {
              setBasicInformation((prevState) => ({
                ...prevState,
                slug: e.target.value,
              }))
            }}
            suffix={
              <Button
                type="primary"
                onClick={() => {
                  setBasicInformation((prevState) => ({
                    ...prevState,
                    slug: generateSlug(basicInformation.title),
                  }))
                }}
                disabled={basicInformation.title.length < 5}
              >
                Generate Slug
              </Button>
            }
          />
          {isSlugError && (
            <Typography.Text type="danger">
              Slug is already existed!
            </Typography.Text>
          )}
        </SpaceVertical>
      </Col>
      <Col span={12}></Col>
      <Col span={12}>
        <SpaceVertical style={{ width: '100%' }} size={8}>
          <Typography.Text type="secondary" aria-required>
            Description
          </Typography.Text>
          <Input
            value={basicInformation.description}
            onChange={(value) => {
              setBasicInformation((prevState: IBasicInformation) => ({
                ...prevState,
                description: value.target.value,
              }))
            }}
          />
        </SpaceVertical>
      </Col>
      <Col span={12}></Col>
      <Col span={12}>
        <SpaceVertical style={{ width: '100%' }} size={8}>
          <Typography.Text type="secondary" aria-required>
            Thumbnail
          </Typography.Text>
          <Typography.Text type="secondary">
            {FormNoticeType.UploadMaxSize}
          </Typography.Text>
          <InputImage
            onChange={handleChangeThumbnail}
            value={basicInformation.thumbnails[0]}
          />
          <Input
            placeholder="thumbnail"
            value={basicInformation.thumbnails[0]}
            onChange={(e) => handleChangeThumbnail(e.target.value)}
          />
          <Typography.Text type="secondary">
            Upload or drag and drop a PNG, GIF, or JPG to display in the app.
          </Typography.Text>
        </SpaceVertical>
      </Col>
    </Row>
  )
}

export default UpdateProductInfo
