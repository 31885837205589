import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import hash from 'object-hash'

import { Button, Col, Row, Space, Typography } from 'antd'
import SaleInformation from 'components/product/saleInformation'
import IonIcon from 'components/systems/ionIcon'
import UpdateProductInfo from './updateProductInfo'
import OriginalPricesInfo from 'components/product/originalPricesInfo'
import SelectWeb3Item from 'components/selectWeb3Item'
import SelectChain from 'components/systems/selectChain/selectChain'
import SeoInformation from 'components/seo/seoInformation'

import { useSEO } from 'hooks/seo/useSEO'

import { encodeAttributes, generateAttributeIds } from 'helper/common'
import { ROUTES } from 'constant/routes'

import { AttributeConfig } from 'services/marketplace/product-category.type'
import { UserNFTData } from 'store/walletNFT.reducer'
import { IBasicInformation, ISKU, ProductType } from 'types/product.type'
import { AttributeComponent } from 'types/product-category.type'
import { SeoType } from 'types/seo.type'

type FormDigitalItemProps = {
  onOk: () => {}
  loading: boolean
  productId?: string
  listSKU: ISKU[]
  setListSKU: (value: ISKU[]) => void
  basicInformation: IBasicInformation
  setBasicInformation: Dispatch<SetStateAction<IBasicInformation>>
  variations: AttributeConfig[]
  setVariations: (value: AttributeConfig[]) => void
  setDeliveryAttributes: (value: AttributeConfig[]) => void
}

const FormDigitalItem = ({
  onOk,
  loading,
  productId = '',
  listSKU,
  setListSKU,
  basicInformation,
  setBasicInformation,
  variations,
  setVariations,
  setDeliveryAttributes,
}: FormDigitalItemProps) => {
  const navigate = useNavigate()
  const id = useParams().productId ?? ''
  const { fetchSeoInfo } = useSEO(SeoType.Product)

  const isEdit = !!id

  useEffect(() => {
    setDeliveryAttributes([
      {
        type: AttributeComponent.Input,
        hint: 'Wallet Address',
        options: ['Wallet Address'],
        placeholder: 'Wallet Address',
        required: true,
        title: 'Wallet Address',
      },
    ])
  }, [setDeliveryAttributes])

  useEffect(() => {
    setVariations([
      {
        type: AttributeComponent.Input,
        hint: 'NFT',
        options: ['NFT'],
        placeholder: 'NFT',
        required: true,
        title: 'NFT',
      },
    ])
  }, [setVariations])

  const syncsListSKU = useCallback(() => {
    const variationIds = generateAttributeIds(variations)
    const nextSKUs: ISKU[] = []
    for (const variationId of variationIds) {
      const sku = listSKU.find((e) => e.variationId === variationId)
      nextSKUs.push({
        variationId,
        productId,
        available: 1,
        thumbnail: '',
        totalAvailable: 1,
        variations: encodeAttributes(variationId),
        ...sku,
      })
    }
    if (hash(nextSKUs) === hash(listSKU)) return
    return setListSKU(nextSKUs)
  }, [listSKU, productId, setListSKU, variations])

  useEffect(() => {
    syncsListSKU()
  }, [syncsListSKU])

  const handleChangeWeb3Item = (itemId: string, nftInfo: UserNFTData) => {
    setBasicInformation({
      ...basicInformation,
      web3ItemId: itemId,
      description: nftInfo?.description || '-',
      title: nftInfo?.name || '-',
      thumbnails: [nftInfo?.image!],
    })
  }

  useEffect(() => {
    if (isEdit) {
      fetchSeoInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {!isEdit ? 'Add' : 'Edit'} Digital Item
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 24]} style={{ marginBottom: 200, marginTop: 24 }}>
          <Col span={24}>
            <SelectChain disabled={isEdit} />
          </Col>

          <Col span={24}>
            <SelectWeb3Item
              web3ItemId={basicInformation.web3ItemId}
              onChange={handleChangeWeb3Item}
              productType={ProductType.NFT}
            />
          </Col>

          <Col span={24}>
            <UpdateProductInfo
              basicInformation={basicInformation}
              setBasicInformation={setBasicInformation}
            />
          </Col>

          <Col span={24}>
            <SaleInformation />
          </Col>

          <Col span={24}>
            <OriginalPricesInfo />
          </Col>

          <Col span={24}>
            <Row>
              <Col span={12}>
                <SeoInformation />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="space-between">
          <Col flex="auto">
            <Button
              icon={<IonIcon name="close-outline" />}
              ghost
              onClick={() => navigate(ROUTES.REWARDS_CENTER.ITEMS)}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Space size={8}>
              {/* <ScheduleButton onSave={onOk} /> */}
              <Button
                icon={<IonIcon name="arrow-up-outline" />}
                ghost
                onClick={() => onOk()}
                loading={loading}
              >
                Publish
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FormDigitalItem
