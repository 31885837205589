import { marketplaceApi as api } from 'services/base-axios'

import { FindSeoDto, ISeo } from 'types/seo.type'

const CONTROLLER = '/admin/seo'

class Seo {
  async getSeo(dto: FindSeoDto) {
    return api.get(CONTROLLER, { params: dto }).then((data) => data.data)
  }

  async createSeo(data: ISeo) {
    return api.post(CONTROLLER, data).then((data) => data.data)
  }

  async updateSeo(params: FindSeoDto, data: ISeo) {
    return api
      .patch(CONTROLLER, data, {
        params,
      })
      .then((data) => data.data)
  }
}

const SeoService = new Seo()

export default SeoService
