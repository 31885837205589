import { marketplaceApi as api } from 'services/base-axios'

import { ChainID } from 'constant'

const CONTROLLER = '/web3-item'

class Web3Item {
  async create(params: CreateWeb3Item): Promise<Web3ItemData> {
    return api.post(CONTROLLER, params).then((data) => data.data)
  }

  async findByItemId(itemId: string): Promise<Web3ItemData> {
    return api.get(CONTROLLER, { params: { itemId } }).then((data) => data.data)
  }

  async findById(id: string): Promise<Web3ItemData> {
    return api.get(`${CONTROLLER}/${id}`).then((res) => res.data)
  }
}

export const Web3ItemService = new Web3Item()

export enum Web3Type {
  NFT = 'WEB3_TYPE::NFT',
}

export type CreateWeb3Item = {
  chainId: ChainID
  web3Type: Web3Type
  blockData: any
}

export type Web3ItemData = {
  _id: string
  itemId: string
  chainId: ChainID
  blockData: any
  web3Type: Web3Type
}
