import { Col, Row, Typography } from 'antd'
import CollectionView from 'view/admin/marketplace/collectionManagement/collectionDetails/collectionView'
import { useCollectionData } from 'hooks/collection/useCollections'
import { useParams } from 'react-router-dom'

const CollectionDetails = () => {
  const collectionId = useParams().collectionId || ''
  const { collectionData, loading } = useCollectionData(collectionId)
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type={'success'}>
          Collection details
        </Typography.Title>
      </Col>
      <Col span={24}>
        <CollectionView collection={collectionData} loading={loading} />
      </Col>
      <Col span={24}></Col>
    </Row>
  )
}

export default CollectionDetails
