import { Col, Row } from 'antd'
import FilterAndSearch from './filterAndSearch'
import NewAction from './newAction'

const NewAndFilterBar = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col flex="auto">
        <FilterAndSearch />
      </Col>
      <Col>
        <NewAction />
      </Col>
    </Row>
  )
}

export default NewAndFilterBar
