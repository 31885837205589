import { useQuery } from 'react-query'

import { MultiplierService } from 'services/multiplier'

import { GetMultiplierApplications } from 'types/multiplier.type'

export const useMultiApps = (dto: GetMultiplierApplications) => {
  return useQuery({
    queryKey: ['GET_MULTIPLIER_APPLICATIONS', JSON.stringify(dto)],
    queryFn: () => MultiplierService.getMultiplierApplication(dto),
    enabled: !!dto.multiplierId,
  })
}
