import { useMutation } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { UpdateAcceler8SubscriptionDto } from 'types/acceler8/acceler8-subscription.type'

export const useUpdateAcceler8Subscription = () => {
  return useMutation(
    ({
      subscriptionId,
      dto,
    }: {
      subscriptionId: number
      dto: UpdateAcceler8SubscriptionDto
    }) => Acceler8Service.updateSubscription(subscriptionId, dto),
  )
}
