import { useNavigate } from 'react-router-dom'

import { Button, Col, Row } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { useHandlePublishBounty } from 'hooks/bounty/useBounties'
import { useSetBounty } from './index'
import { ROUTES } from 'constant/routes'

const Action = () => {
  const navigate = useNavigate()
  const [bountyData, setBountyData] = useSetBounty()
  const { _id: bountyId, isEnabled } = bountyData
  const { loading, handlePublishBounty } = useHandlePublishBounty(bountyId)

  const onHandlePublish = async () => {
    const nextIsEnable = !isEnabled
    const data = await handlePublishBounty(nextIsEnable)
    if (data) setBountyData({ ...data, isEnabled: nextIsEnable })
  }

  const btnIcon = isEnabled ? 'document-lock' : 'cloud-upload'

  return (
    <Row gutter={[24, 24]}>
      <Col>
        <Button
          style={{ minWidth: 130 }}
          icon={<IonIcon name="trash-outline" />}
        >
          Delete
        </Button>
      </Col>
      <Col>
        <Button
          style={{ minWidth: 130 }}
          icon={<IonIcon name="bar-chart-outline" />}
        >
          Report
        </Button>
      </Col>
      <Col>
        <Button
          style={{ minWidth: 130 }}
          icon={<IonIcon name="pencil-outline" />}
          onClick={() => navigate(`${ROUTES.BOUNTY.EDIT}/${bountyId}`)}
        >
          Edit
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          style={{ minWidth: 130 }}
          icon={<IonIcon name={btnIcon} />}
          loading={loading}
          onClick={onHandlePublish}
          ghost={isEnabled}
        >
          {isEnabled ? 'Unpublish' : 'Publish'}
        </Button>
      </Col>
    </Row>
  )
}

export default Action
