import { Col, Row, Typography } from 'antd'

import ListNFT from './listNFT'

export default function NftManagement() {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          NFT Service
        </Typography.Title>
      </Col>
      <Col span={24}>
        <ListNFT />
      </Col>
    </Row>
  )
}
