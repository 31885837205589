import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'
import WatchBountyData from './watchBountyData'
import ContentByType from './content'

import { DUMMY_BOUNTY_DATA } from 'hooks/bounty/useBountyData'
import {
  useBountySetup,
  useDeleteTaskIds,
  useFileList,
  useCreateBountyStep,
} from '../index'

import './index.less'
import { CreateAndUpdateTabs } from 'constant/bounty'

const CreateAndUpdate = () => {
  const [, setFileList] = useFileList()
  const [, setBountyData] = useBountySetup()
  const [, setCreateBountyStep] = useCreateBountyStep()
  const [, setDeleteTaskIds] = useDeleteTaskIds()
  const bountyId = useParams().bountyId || ''

  const title = !bountyId ? 'Create Mission' : 'Edit Mission'

  useEffect(() => {
    return () => {
      setFileList([])
      setDeleteTaskIds([])
      setCreateBountyStep(CreateAndUpdateTabs.SetUp)
      setBountyData(DUMMY_BOUNTY_DATA)
    }
  }, [setBountyData, setCreateBountyStep, setDeleteTaskIds, setFileList])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <ContentByType />
      </Col>

      <WatchBountyData />
    </Row>
  )
}

export default CreateAndUpdate
