import { useCallback, useEffect, useState } from 'react'

import FormPhysicalItem from './formPhysical'

import useHandleProduct from 'hooks/useHandleProduct'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import { AttributeConfig } from 'services/marketplace/product-category.type'
import { useCreateProductData } from './index'
import { useProductCategory } from 'hooks/useProductCategory'
import { IBasicInformation, ISKU } from 'types/product.type'
import { LeaderboardPoint } from 'types/product-category.type'

import { ChainID } from 'constant'
import {
  DEFAULT_BASIC_INFO_DATA,
  DUMMY_PRODUCT_DATA,
} from 'constant/marketplace'
import FormProductContext from './FormProductContext'
import { convertTzUtcBeforeUpload } from 'helper'

const CreatePhysicalItem = ({
  isCodeItem: _isCodeItem = false,
}: {
  isCodeItem?: boolean
}) => {
  const [basicInformation, setBasicInformation] = useState<IBasicInformation>({
    ...DEFAULT_BASIC_INFO_DATA,
    chainId: ChainID.A8,
  })

  const [community] = useCurrentCommunity()
  const { data: categoryData } = useProductCategory(basicInformation.categoryId)
  const [listSKU, setListSKU] = useState<ISKU[]>([])
  const [specData, setSpecData] = useState<Record<string, string>>({})
  const [variations, setVariations] = useState<AttributeConfig[]>([])
  const [deliveries, setDeliveries] = useState<AttributeConfig[]>([])
  const [leaderboardPoint, setLeaderboardPoint] = useState<
    undefined | LeaderboardPoint
  >()
  const [globalData, setGlobalData] = useCreateProductData()
  const { loading, onCreateProduct } = useHandleProduct()

  useEffect(() => {
    setDeliveries(categoryData?.deliveryConfig || [])
    setVariations(categoryData?.variationAttributes || [])
  }, [categoryData])

  useEffect(() => {
    setBasicInformation((prev) => ({
      ...prev,
      communityId: community?._id,
      thumbnails: [],
      title: '',
      description: '',
      categoryId: '',
      web3ItemId: '',
    }))
  }, [community?._id])

  const onOk = useCallback(async () => {
    onCreateProduct({
      ...globalData,
      ...basicInformation,
      specification: specData,
      variationAttributes: variations,
      deliveryAttributes: deliveries,
      SKUs: listSKU,
      startedAt: convertTzUtcBeforeUpload(),
      leaderboardPoint: leaderboardPoint,
    })
  }, [
    basicInformation,
    deliveries,
    globalData,
    onCreateProduct,
    listSKU,
    specData,
    variations,
    leaderboardPoint,
  ])

  useEffect(() => {
    return () => setGlobalData(DUMMY_PRODUCT_DATA)
  }, [setGlobalData])

  return (
    <FormProductContext.Provider value={{ isCodeItem: _isCodeItem }}>
      <FormPhysicalItem
        onOk={onOk}
        loading={loading}
        setListSKU={setListSKU}
        listSKU={listSKU}
        basicInformation={basicInformation}
        setBasicInformation={setBasicInformation}
        variations={variations}
        setVariations={setVariations}
        specData={specData}
        setSpecData={setSpecData}
        setDeliveryAttributes={setDeliveries}
        deliveryAttributes={deliveries}
        leaderboardPoint={leaderboardPoint}
        setLeaderboardPoint={setLeaderboardPoint}
      />
    </FormProductContext.Provider>
  )
}

export default CreatePhysicalItem
