import { useQuery } from 'react-query'

import { AffiliationService } from 'services/affiliation-v2'

import { FindLeaderboardBySeasonAffiliationDto } from 'types/affiliation.type'

export const useLeaderboardBySeasonAffiliation = (
  dto: FindLeaderboardBySeasonAffiliationDto,
) => {
  return useQuery({
    queryKey: ['GET_LEADERBOARD_BY_SEASON_AFFILIATION', dto],
    queryFn: () => AffiliationService.findLeaderboardBySeason(dto),
  })
}
