import { MouseEvent, ReactNode } from 'react'

import { Modal } from 'antd'
import IonIcon from './ionIcon'

import bgModal from 'static/images/profile/bg-modal.svg'

type CustomModalProps = {
  open: boolean
  onCancel: (e: MouseEvent) => void
  children: ReactNode
  zIndex?: number
  width?: number
}

const CustomModal = ({
  open,
  onCancel,
  children,
  zIndex,
  width = 350,
}: CustomModalProps) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={false}
      closeIcon={<IonIcon name="close-outline" />}
      width={width}
      className="clip-path-modal"
      bodyStyle={{ backgroundImage: `url(${bgModal})` }}
      getContainer={() => document.getElementById('root') as HTMLElement} // Must have for pagination css swiper
      zIndex={zIndex}
    >
      {children}
    </Modal>
  )
}

export default CustomModal
