import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'
import {
  FindTeamRankDto,
  FindRankDetailDto,
} from 'types/acceler8/acceler8-rank.type'

export const useAcceler8Ranks = (dto: FindTeamRankDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_RANKS', dto],
    queryFn: () => Acceler8Service.findRanksAndTeams(dto),
    enabled: !!dto.seasonId,
  })

  return {
    ...rest,
    data: data?.data,
    total: data?.total || 0,
  }
}

export const useAcceler8RankDetail = (
  dto: FindRankDetailDto,
  enabled?: boolean,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_RANK_DETAIL', dto],
    queryFn: () => Acceler8Service.findRankDetail(dto),
    enabled,
  })

  return {
    ...rest,
    data,
  }
}
