import { generatePath, useNavigate } from 'react-router-dom'

import { Button } from 'antd'

import { ROUTES } from 'constant/routes'

const RowAction = ({ teamId }: { teamId: number }) => {
  const navigate = useNavigate()

  return (
    <Button
      type="primary"
      onClick={() =>
        navigate(
          generatePath(ROUTES.ACCELER8.TEAM_DETAILS, {
            teamId,
          }),
        )
      }
    >
      Details
    </Button>
  )
}

export default RowAction
