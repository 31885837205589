import { Data, FindDto } from 'types'

export type SeasonAffiliation = {
  name: string

  totalRewards: number

  totalPoints: number

  startDate: Date

  endDate: Date

  banner: string

  prizeStructure: SeasonAffiliationPrize[]
}

export type SeasonAffiliationPrize = {
  title: string
  prize: string
}

export type SeasonAffiliationData = Data<SeasonAffiliation>

// Subscription Start
export type FindSubscriptionOverallDto = {
  offset?: number

  limit?: number

  search?: string

  filter?: AffiliationSubscriptionStatus[]

  sort?: SortOptions
}

export type FindSubscriptionActivityDto = {
  offset?: number

  limit?: number

  search?: string

  filter?: AffiliationSubscriptionStatus[]
}

export enum AffiliationSubscriptionStatus {
  PremiumPackage = 'PREMIUM_PACKAGE',
  ExclusivePackage = 'EXCLUSIVE_PACKAGE',
  AdminActivates = 'ADMIN_ACTIVATES',
  Inactive = 'INACTIVE',
}

export const AffiliationStatusLabel: Record<
  AffiliationSubscriptionStatus,
  string
> = {
  [AffiliationSubscriptionStatus.PremiumPackage]: 'Premium Package',
  [AffiliationSubscriptionStatus.ExclusivePackage]: 'Exclusive Package',
  [AffiliationSubscriptionStatus.AdminActivates]: 'Admin Activates',
  [AffiliationSubscriptionStatus.Inactive]: 'Inactive',
}

export enum SortOptions {
  RecentlyActivated = 'RECENTLY_ACTIVATED',
  GoingToBeExpired = 'GOING_TO_BE_EXPIRED',
  TotalMoneySpentDecrease = 'TOTAL_MONEY_SPENT_DECREASE',
  TotalMoneySpentIncrease = 'TOTAL_MONEY_SPENT_INCREASE',
}

// TODO: Will change later after the endpoint is ready
export interface ISubscriptionOverall {
  _id: string

  name: string

  totalMoney: number

  status: AffiliationSubscriptionStatus

  activeDate: Date

  expirationDate: Date
}

// TODO: Will change activity type enum when designing system is ready
export enum ActivityType {
  SubscribeNewAbcPackage = 'SUBSCRIBE_NEW_ABC_PACKAGE',
  ExpiresAbcPackage = 'EXPIRES_ABC_PACKAGE',
  AdminActivates = 'ADMIN_ACTIVATES',
  AdminDeactivates = 'ADMIN_DEACTIVATES',
}

export const ActivityTypeLabel: Record<ActivityType, string> = {
  [ActivityType.SubscribeNewAbcPackage]: 'Subscribe New [abc] Package',
  [ActivityType.ExpiresAbcPackage]: 'Expires [abc] package',
  [ActivityType.AdminActivates]: 'Admin Activates',
  [ActivityType.AdminDeactivates]: 'Admin Deactivates',
}

// TODO: Will change later after the endpoint is ready
export interface ISubscriptionActivity {
  _id: string

  name: string

  type: ActivityType

  content: string

  chainLink: string

  amount: number

  activityDate: Date

  createdAt: string

  updatedAt: string
}
// Subscription End
export type IActiveAffiliationMode = {
  status: AffiliationSubscriptionStatus

  startedAt: string

  endedAt: string
}
export type TeamAffiliation = {
  teamName: string
  logo: string
  point: number
  lastRank: number
  currentRank: number
}

export type TeamAffiliationData = Data<TeamAffiliation>

export type FindSeasonAffiliationDto = FindDto

export type FindLeaderboardBySeasonAffiliationDto = FindDto & {
  seasonId?: string
}

export type CreateSeasonAffiliationDto = Omit<SeasonAffiliation, 'totalPoints'>
