import { Collapse } from 'antd'
import React from 'react'

import {
  CommunityProfileSection,
  CommunitySectionType,
} from 'types/community-profile'
import FormSectionProvider from 'view/community/management/profile/formCommunitySection'

import { Data } from 'types'

import './index.less'

type PreviewCommunitySectionProps = {
  type?: CommunitySectionType
  sectionData?: Data<CommunityProfileSection<CommunitySectionType>>
}

const titleMap = {
  [CommunitySectionType.Overview]: {
    title: 'Overview Section',
    suffixCls: 'overview',
  },
  [CommunitySectionType.System]: {
    title: 'System Featured Section',
    suffixCls: 'system-featured',
  },
  [CommunitySectionType.Featured]: {
    title: 'Featured Content Section',
    suffixCls: 'featured-content',
  },
  [CommunitySectionType.Member]: {
    title: 'Team Members Section',
    suffixCls: 'team-members',
  },
  [CommunitySectionType.Company]: {
    title: 'Company Section',
    suffixCls: 'company',
  },
  [CommunitySectionType.OfficialLink]: {
    title: 'Official Link Section',
    suffixCls: 'official-link',
  },
}

function PreviewCommunitySection({
  type,
  sectionData,
}: PreviewCommunitySectionProps) {
  return (
    <Collapse
      className={`preview-community-section-${
        titleMap[sectionData?.type ?? type ?? CommunitySectionType.Overview]
          .suffixCls
      }`}
    >
      <Collapse.Panel
        key={1}
        header={
          titleMap[sectionData?.type ?? type ?? CommunitySectionType.Overview]
            .title
        }
      >
        <FormSectionProvider type={type} sectionData={sectionData} />
      </Collapse.Panel>
    </Collapse>
  )
}

export default PreviewCommunitySection
