import { useCallback, useDebugValue, useEffect } from 'react'

import { Button, Dropdown, MenuProps, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ICommunity } from 'types/community.type'
import { useCommunities } from 'hooks/community/useCommunities'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'

export const CommunityToggle = () => {
  const [currentCommunity, setCurrentCommunity] = useCurrentCommunity()
  const { communities } = useCommunities({})

  const onCommunitySwitch = useCallback(
    (community: ICommunity) => {
      setCurrentCommunity(community)
    },
    [setCurrentCommunity],
  )

  useDebugValue(communities)

  useEffect(() => {
    if (currentCommunity === undefined && communities.length > 0) {
      onCommunitySwitch(communities[0])
    }
  }, [communities, currentCommunity, onCommunitySwitch])

  const items: MenuProps['items'] = communities.map((community) => {
    return {
      key: community._id,
      label: community.title,
      onClick: () => onCommunitySwitch(community),
    }
  })

  return (
    <Dropdown.Button
      type="text"
      className="dropdown-btn pad-less"
      style={{
        width: 'auto',
        height: '100%',
        cursor: 'pointer',
        background: 'transparent',
      }}
      trigger={['click']}
      menu={{ items }}
      icon={
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            width: 'auto',
            height: 'auto',
          }}
        >
          <Space size={12} className="space-middle-icon">
            <Typography.Text style={{ fontSize: 14, fontWeight: 700 }}>
              {currentCommunity?.title}
            </Typography.Text>
            <IonIcon
              style={{ fontSize: 18, fontWeight: 700 }}
              name="chevron-down-outline"
            />
          </Space>
        </Button>
      }
    ></Dropdown.Button>
  )
}
