import { useCallback } from 'react'

import { BountyRecurrence } from 'constant/bounty'

export const useParseRecurrenceTime = () => {
  const parseRecurrenceTime = useCallback((recurrenceTime: number) => {
    if (recurrenceTime === BountyRecurrence.Daily) return 'Daily'
    if (recurrenceTime === BountyRecurrence.Weekly) return 'Weekly'
    if (recurrenceTime === BountyRecurrence.Monthly) return 'Monthly'
    return 'Once'
  }, [])

  return parseRecurrenceTime
}
