import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Acceler8Service } from 'services/acceler8'
import { Acceler8TaskService } from 'services/acceler8-task'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import {
  CreateAcceler8TaskDto,
  CreateAcceler8TaskWithCreateProgramDto,
  TaskPartnerConfigs,
  AC8TaskType,
} from 'types/acceler8/acceler8-task.type'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

export const useCreateAcceler8Task = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (dto: CreateAcceler8TaskWithCreateProgramDto) => {
      try {
        const { subscriptionId, points, programType, type } = dto
        const configs = dto.configs as TaskPartnerConfigs

        const { id: programId } = await Acceler8Service.createProgram({
          subscriptionId,
          points,
          type: programType,
        })

        const payload: CreateAcceler8TaskDto = {
          ...dto,
          programId,
        }

        if (type === AC8TaskType.FederatedApi) {
          const headers: Record<string, string> = {}
          configs.meta.headers?.map((value: any) => {
            headers[value.key] = value.value
            return null
          })
          const { _id } = await Acceler8TaskService.createFederatedAPI({
            pattern: configs.meta.endpoint || '',
            headers,
          })
          payload.configs = {
            federatedApiId: _id,
          }
        }

        await Acceler8TaskService.createTask(payload).catch(async () => {
          await Acceler8Service.deleteProgram(programId).catch() // .catch to ignore error from delete program
          throw new Error('Create task failed!')
        })

        notifySuccess('Create task')
        navigate(
          programType === ProgramType.Team
            ? ROUTES.ACCELER8.TASK_MANAGEMENT
            : ROUTES.PLAYER_LEADERBOARD.TASK_MANAGEMENT,
        )
      } catch (e) {
        notifyError('Create task failed!')
      }
    },
  })
}
