import { Fragment, useState } from 'react'

import { Badge, Button, Drawer, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

const Notifications = () => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <Badge
        count={0}
        size="small"
        className="notification-badge"
        style={{ background: '#ffc737' }}
      >
        <Button
          style={{ width: 'auto', height: 'auto', padding: 0 }}
          type="text"
          icon={<IonIcon name="notifications" />}
          onClick={() => setOpen(true)}
        />
      </Badge>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title={<Typography.Title level={5}>Notifications</Typography.Title>}
        closeIcon={<IonIcon name="close-outline" />}
      >
        {/* Content */}
      </Drawer>
    </Fragment>
  )
}

export default Notifications
