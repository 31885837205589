import { Col, Row, Tabs, Typography } from 'antd'
import DigitalShipping from './digitalShipping'
import PhysicalShipping from './physicalShipping'

import { MarketItemType } from 'constant/marketplace'

const UsersShipping = () => {
  const tabItems = [
    {
      label: MarketItemType.Digital,
      key: MarketItemType.Digital,
      children: <DigitalShipping />,
    },
    {
      label: MarketItemType.Physical,
      key: MarketItemType.Physical,
      children: <PhysicalShipping />,
    },
  ]

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Users Shipping
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs style={{ marginBottom: 12 }} items={tabItems} />
      </Col>
    </Row>
  )
}

export default UsersShipping
