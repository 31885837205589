import { FindDto } from 'types'
import { ProgramType } from './acceler8-program.type'

export interface IJob {
  name: string
  type: JobType
  status: JobStatus
  configs: JobConfigs
}

export interface ISeasonSummaryJobConfigs {
  seasonId: number
  programType: ProgramType
}

export type JobConfigs = ISeasonSummaryJobConfigs

export type GetJobsDto = FindDto & {
  type?: JobType
  seasonId?: number
  status?: JobStatus
}

export enum JobType {
  SeasonSummary = 'JOB_TYPE:SEASON_SUMMARY',
}

export enum JobStatus {
  Processing = 'JOB_STATUS:PROCESSING',
  Paused = 'JOB_STATUS:PAUSED',
  Completed = 'JOB_STATUS:COMPLETED',
  Canceled = 'JOB_STATUS:CANCELED',
}
