import moment from 'moment'

import { Typography } from 'antd'

import SpaceVertical from 'components/systems/spaceVertical'

import { formatNumberWithSuffix } from 'helper/common'
import { shortenDecimal } from 'helper'

import { TIME_FORMAT } from 'constant'

import { PlayerRankDataResponse } from 'types/player-leaderboard/player-leaderboard-rank.type'
import type { ColumnType } from 'antd/es/table'

export const COLUMN_USER: ColumnType<PlayerRankDataResponse>[] = [
  {
    title: 'User Id',
    dataIndex: 'userId',
    render: (userId: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 260 }}
      >
        {userId}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Ref percent',
    dataIndex: '',
    width: 120,
    align: 'center',
    render: (_, { referralPercents }) => {
      if (!referralPercents) return '--'
      return (
        <SpaceVertical>
          {referralPercents.map((e, index) => (
            <Typography.Text key={index}>
              Level {index + 1}: {e * 100}%
            </Typography.Text>
          ))}
        </SpaceVertical>
      )
    },
  },
  {
    title: 'Current season rank',
    dataIndex: '',
    align: 'center',
    render: (playerRankDataResponse: PlayerRankDataResponse) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {playerRankDataResponse.points
          ? formatNumberWithSuffix(playerRankDataResponse.ranking)
          : '--'}
      </Typography.Text>
    ),
  },
  {
    title: 'Accumulative points',
    dataIndex: 'accumulativePoints',
    align: 'center',
    render: (accumulativePoints: number) => (
      <Typography.Text style={{ margin: 0, maxWidth: 160 }}>
        {shortenDecimal(accumulativePoints, 2)}
      </Typography.Text>
    ),
  },
  {
    title: 'Points this season',
    dataIndex: 'points',
    align: 'center',
    render: (points: number) => (
      <Typography.Text>{shortenDecimal(points, 2)}</Typography.Text>
    ),
  },
  {
    title: 'Last point contributed',
    dataIndex: 'updatedAt',
    align: 'center',
    render: (updatedAt?: string) => (
      <Typography.Text>
        {updatedAt
          ? `${moment.utc(updatedAt).format(TIME_FORMAT)} (UTC)`
          : '--'}
      </Typography.Text>
    ),
  },
]
