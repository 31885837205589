export interface IAffiliationTeam {
  id: number
  teamName: string
  leaderId: string
  createdAt: string
  leaderTelegramId: string
  totalMember: number
  currentSeasonRankId: number
  lastContributionTime: string
  lastSeasonRankId: number
  totalPointsCurrentSeason: number
  totalAccumulativePoints: number
  state: TeamState
}

export type FindAffiliationTeamDto = {
  offset?: number

  limit?: number

  search?: string

  sort?: string
}

export enum TeamSortOptions {
  CurrentSeasonRank = 'TEAM_SORT:RANK',
  AccumulativePoints = 'ACCUMULATIVE_POINTS',
  LastPointContributed = 'TEAM_SORT:LAST_CONTRIBUTED',
}

export enum TeamState {
  Deleted = 'TEAM_STATE:DELETED',
  Active = 'TEAM_STATE:ACTIVE',
}
