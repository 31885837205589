import { useMemo, useState } from 'react'

import { ButtonProps, Col, Row, Tabs } from 'antd'
import CategoryController from './categoryAction'
import BasicInformation, { BasicData } from './basicInformation'
import FormAttributeSetting from 'components/product/formAttributeSetting'

import { AttributeConfig } from 'services/marketplace/product-category.type'
import { IProductCategory } from 'types/product-category.type'

enum Step {
  Basic = 'Basic',
  Specification = 'Specification',
  Delivery = 'Delivery',
  Variation = 'Variation',
}

type SettingCategoryProps = {
  value?: IProductCategory
  onOk: (value: IProductCategory) => void
}

const DEFAULT_PRODUCT_CATEGORY: IProductCategory = {
  title: '',
  description: '',
  deliveryConfig: [],
  specificationConfig: [],
  variationAttributes: [],
}

const SettingCategory = ({
  value = DEFAULT_PRODUCT_CATEGORY,
  onOk,
}: SettingCategoryProps) => {
  const [stepIdx, setStepIdx] = useState(0)
  const [basicData, setBasicData] = useState<BasicData>({
    title: value.title || '',
  })
  const [specSetting, setSpecSetting] = useState<AttributeConfig[]>(
    value.specificationConfig || [],
  )
  const [delivery, setDelivery] = useState<AttributeConfig[]>(
    value.deliveryConfig || [],
  )
  const [variationAttributes, setVariationAttributes] = useState<
    AttributeConfig[]
  >(value.variationAttributes || [])

  const CATEGORY_TABS = useMemo(() => {
    return [
      {
        label: 'Basic information',
        key: Step.Basic,
        children: <BasicInformation data={basicData} onChange={setBasicData} />,
      },
      {
        label: 'Specification setting',
        key: Step.Specification,
        children: (
          <FormAttributeSetting
            attributes={specSetting}
            onChange={setSpecSetting}
          />
        ),
      },

      {
        label: 'Variations setting',
        key: Step.Variation,
        children: (
          <FormAttributeSetting
            attributes={variationAttributes}
            onChange={setVariationAttributes}
            type="variation"
          />
        ),
      },
      {
        label: 'Delivery setting',
        key: Step.Delivery,
        children: (
          <FormAttributeSetting attributes={delivery} onChange={setDelivery} />
        ),
      },
    ]
  }, [basicData, delivery, specSetting, variationAttributes])

  const rightButtonProps: ButtonProps = useMemo(() => {
    const isFinish = stepIdx === CATEGORY_TABS.length - 1

    if (isFinish) {
      const categoryData: IProductCategory = {
        title: basicData.title,
        description: basicData.title,
        specificationConfig: specSetting,
        deliveryConfig: delivery,
        variationAttributes: variationAttributes,
      }
      return {
        onClick: () => onOk(categoryData),
        title: 'Save',
      }
    }

    return {
      onClick: () => setStepIdx(stepIdx + 1),
      title: 'Next',
    }
  }, [
    CATEGORY_TABS.length,
    basicData.title,
    delivery,
    onOk,
    specSetting,
    stepIdx,
    variationAttributes,
  ])

  return (
    <Row gutter={[48, 48]}>
      <Col span={24}>
        <Tabs
          items={CATEGORY_TABS}
          activeKey={CATEGORY_TABS[stepIdx].key}
          className="form-create-bounty"
        />
      </Col>
      <Col span={24}>
        <CategoryController
          leftButton={{
            title: 'Cancel',
            disabled: !stepIdx,
            onClick: () => setStepIdx(stepIdx - 1),
          }}
          rightButton={rightButtonProps}
        />
      </Col>
    </Row>
  )
}

export default SettingCategory
