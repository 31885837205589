import { useCallback, useMemo, useState } from 'react'

import { Button, Col, Dropdown, Modal, Row, Typography, Input } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { UserShippingAction } from 'constant/marketplace'
import SpaceVertical from 'components/systems/spaceVertical'
import { orderService } from 'services/marketplace/order'
import { notifyError, notifySuccess } from 'helper'
import { ShippingStatus, OrderData } from 'types/order.type'

type ActionProps = {
  onCancel: () => void
  orderId: string
  refresh: () => void
}

type PhysicalShippingActionProps = {
  orderId: string
  refetch: () => void
  orderData: OrderData
}

const PhysicalShippingAction = ({
  orderId,
  refetch,
  orderData,
}: PhysicalShippingActionProps) => {
  const [action, setAction] = useState<UserShippingAction>()
  const [open, setOpen] = useState(false)
  const items = useMemo(() => {
    if (!orderData) return []
    return [
      {
        label: UserShippingAction.MoveToShipping,
        key: ShippingStatus.Sending,
      },
      {
        label: UserShippingAction.Completed,
        key: ShippingStatus.Done,
      },
      {
        label: UserShippingAction.Cancelled,
        key: ShippingStatus.Fail,
      },
    ].filter((order) => order.key !== orderData.shippingStatus)
  }, [orderData])

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Button
        trigger={['click']}
        type="text"
        icon={<IonIcon name="ellipsis-vertical-outline" />}
        placement="bottomRight"
        menu={{
          items: items,
          onClick: (e) => {
            switch (e.key) {
              case UserShippingAction.MoveToShipping:
                setAction(UserShippingAction.MoveToShipping)
                return setOpen(true)

              case UserShippingAction.Completed:
                setAction(UserShippingAction.Completed)
                return setOpen(true)

              case UserShippingAction.Cancelled:
                setAction(UserShippingAction.Cancelled)
                return setOpen(true)
            }
          },
        }}
        onClick={(e) => e.stopPropagation()}
      />

      <Modal
        open={open}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
        style={{ borderRadius: 12 }}
        className="action-modal"
        closable={false}
      >
        {action === UserShippingAction.MoveToShipping && (
          <MoveToShipping
            refresh={refetch}
            onCancel={() => setOpen(false)}
            orderId={orderId}
          />
        )}
        {action === UserShippingAction.Cancelled && (
          <Cancelled
            refresh={refetch}
            onCancel={() => setOpen(false)}
            orderId={orderId}
          />
        )}
        {action === UserShippingAction.Completed && (
          <Completed
            refresh={refetch}
            onCancel={() => setOpen(false)}
            orderId={orderId}
          />
        )}
      </Modal>
    </div>
  )
}

const MoveToShipping = ({ onCancel, orderId, refresh }: ActionProps) => {
  const [loading, setLoading] = useState(false)

  const onConfirm = useCallback(async () => {
    setLoading(true)
    try {
      await orderService.updateShippingStatus([orderId], ShippingStatus.Sending)
      await refresh()
      onCancel()
      notifySuccess('Update order status to shipping!')
    } catch (err) {
      notifyError({ message: err })
    } finally {
      setLoading(false)
    }
  }, [onCancel, orderId, refresh])

  return (
    <Row gutter={[40, 40]}>
      <Col span={24}>
        <Row wrap={true} justify="center" gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title
              level={3}
              style={{ textAlign: 'center' }}
              type="success"
            >
              Move To Shipping
            </Typography.Title>
          </Col>
          <Col span={24}>
            <SpaceVertical block size={8}>
              <Typography.Text type="secondary">Note</Typography.Text>
              <Input.TextArea rows={3} showCount maxLength={1000} />
            </SpaceVertical>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify={'center'} gutter={[20, 20]}>
          <Col>
            <Button ghost style={{ borderRadius: 2 }} onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ borderRadius: 2 }}
              onClick={onConfirm}
              loading={loading}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const Completed = ({ onCancel, orderId, refresh }: ActionProps) => {
  const [loading, setLoading] = useState(false)

  const onConfirm = useCallback(async () => {
    setLoading(true)
    try {
      await orderService.updateShippingStatus([orderId], ShippingStatus.Done)
      await refresh()
      onCancel()
      notifySuccess('Update order status to Completed!')
    } catch (err) {
      notifyError({ message: err })
    } finally {
      setLoading(false)
    }
  }, [onCancel, orderId, refresh])

  return (
    <Row gutter={[40, 40]}>
      <Col span={24}>
        <Row wrap={true} justify="center" gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title
              level={3}
              style={{ textAlign: 'center' }}
              type="success"
            >
              Completed
            </Typography.Title>
          </Col>
          <Col span={24}>
            <SpaceVertical block size={8}>
              <Typography.Text type="secondary">Note</Typography.Text>
              <Input.TextArea rows={3} showCount maxLength={1000} />
            </SpaceVertical>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify={'center'} gutter={[20, 20]}>
          <Col>
            <Button ghost style={{ borderRadius: 2 }} onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ borderRadius: 2 }}
              onClick={onConfirm}
              loading={loading}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const Cancelled = ({ onCancel, orderId, refresh }: ActionProps) => {
  const [loading, setLoading] = useState(false)

  const onConfirm = useCallback(async () => {
    setLoading(true)
    try {
      await orderService.updateShippingStatus([orderId], ShippingStatus.Fail)
      await refresh()
      onCancel()
      notifySuccess('Update order status to cancelled!')
    } catch (err) {
      notifyError({ message: err })
    } finally {
      setLoading(false)
    }
  }, [onCancel, orderId, refresh])

  return (
    <Row gutter={[40, 40]}>
      <Col span={24}>
        <Row wrap={true} justify="center" gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title
              level={3}
              style={{ textAlign: 'center' }}
              type="success"
            >
              Cancelled
            </Typography.Title>
          </Col>
          <Col span={24}>
            <SpaceVertical block size={8}>
              <Typography.Text type="secondary">Note</Typography.Text>
              <Input.TextArea rows={3} showCount maxLength={1000} />
            </SpaceVertical>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify={'center'} gutter={[20, 20]}>
          <Col>
            <Button ghost style={{ borderRadius: 2 }} onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={loading}
              type="primary"
              style={{ borderRadius: 2 }}
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PhysicalShippingAction
