import moment from 'moment'

import { Typography } from 'antd'
import PhysicalShippingAction from './physicalShippingAction'
import PriceColumn from '../../priceColumn'
import OrderState from 'components/marketPlace/order/orderState'
import ShippingState from 'components/marketPlace/order/shippingState'

import { TIME_FORMAT } from 'constant'
import { shortenAddress } from 'helper'
import { IProductData } from 'types/product.type'
import { OrderData, OrderStatus, ShippingStatus } from 'types/order.type'

type PHYSICAL_SHIPPING_COLUMN_PROPS = {
  refetch: () => void
}

export const PHYSICAL_SHIPPING_COLUMN = ({
  refetch,
}: PHYSICAL_SHIPPING_COLUMN_PROPS) => [
  {
    title: 'Order ID',
    dataIndex: '_id',
    render: (_id: string) => (
      <Typography.Text>{shortenAddress(_id)}</Typography.Text>
    ),
  },
  {
    title: 'UID',
    dataIndex: 'uid',
    render: (uid: string) => (
      <Typography.Text>{shortenAddress(uid)}</Typography.Text>
    ),
  },
  {
    title: 'Item Name',
    dataIndex: 'productData',
    render: (productData: IProductData) => (
      <Typography.Text ellipsis={true}>{productData.title}</Typography.Text>
    ),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    render: (quantity: string) => <Typography.Text>{quantity}</Typography.Text>,
  },
  {
    title: 'Total Price',
    dataIndex: '_id',
    render: (_: string, orderData: OrderData) => (
      <PriceColumn orderData={orderData} />
    ),
  },
  {
    dataIndex: 'orderStatus',
    title: 'Order Status',
    render: (status: OrderStatus) => <OrderState state={status} />,
  },
  {
    dataIndex: 'shippingStatus',
    title: 'Shipping Status',
    render: (status: ShippingStatus) => <ShippingState state={status} />,
  },
  {
    title: 'Time',
    dataIndex: 'createdAt',
    render: (time: string) => (
      <Typography.Text>
        {moment.utc(time).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: 'Action',
    dataIndex: '_id',
    render: (orderId: string, orderData: OrderData) => (
      <PhysicalShippingAction
        orderId={orderId}
        refetch={refetch}
        orderData={orderData}
      />
    ),
  },
]
