import { Fragment } from 'react'

import {
  Col,
  Form,
  type FormInstance,
  Image,
  Row,
  Typography,
  Space,
} from 'antd'
import { DeleteOutlined as RemoveIcon } from '@ant-design/icons/lib/icons'

import WeightFormItem from './shared/WeightFormItem'
import TypeFormItem from './shared/TypeFormItem'
import AvailableFormItem from './shared/AvailableFormItem'
import TierFormItem from './shared/TierFormItem'
import RewardNumberFormItem from './shared/RewardNumberFormItem'
import SelectWhitelist from 'components/whitelist/SelectWhitelist'
import { WrapLoading } from 'components/systems/loading'

import { useWhitelistDetail } from 'hooks/whitelist/useWhitelistDetail'

import { type RewardItemProps } from '.'

import whitelistImg from 'static/images/gacha/whitelist.png'

type WhitelistDetailProps = {
  whitelistId: string
  onRemove: () => void
}

function WhitelistDetail({ whitelistId, onRemove }: WhitelistDetailProps) {
  const { data: whitelist, isLoading } = useWhitelistDetail(whitelistId)

  if (!whitelist) return null

  return (
    <Space direction="vertical" size={8}>
      <WrapLoading loading={isLoading}>
        <div className="gacha--img-detail">
          <Image
            src={whitelist.thumbnail ?? whitelistImg}
            fallback={whitelistImg}
            alt="whitelist"
            width={100}
            style={{ aspectRatio: 1, objectFit: 'cover' }}
            preview={false}
          />

          <div className="gacha--img-detail--remove">
            <RemoveIcon onClick={onRemove} />
          </div>
        </div>
      </WrapLoading>
      <Typography.Paragraph
        style={{ maxWidth: 120 }}
        ellipsis={{ tooltip: whitelist.title, rows: 2 }}
      >
        {whitelist.title}
      </Typography.Paragraph>
    </Space>
  )
}

function RewardWhitelistItem({
  form,
  prefixNamePath,
}: RewardItemProps<{ form: FormInstance }>) {
  const rewardId = Form.useWatch(['rewards', prefixNamePath, '_id'], form)
  const isEdit = !!rewardId

  return (
    <Fragment>
      <TierFormItem prefixNamePath={prefixNamePath} />

      <Form.Item
        name={[prefixNamePath, 'config', 'amount']}
        initialValue={1}
        hidden
        noStyle
      />

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues?.rewards?.[prefixNamePath]?.config.whitelistId !==
          curValues?.rewards?.[prefixNamePath]?.config.whitelistId
        }
        style={{ marginBottom: 20 }}
      >
        {({ getFieldValue, setFieldValue }) => {
          const whitelistId = getFieldValue([
            'rewards',
            prefixNamePath,
            'config',
            'whitelistId',
          ])

          return (
            <Row gutter={[8, 8]}>
              <Col>
                {whitelistId && (
                  <WhitelistDetail
                    whitelistId={whitelistId}
                    onRemove={() =>
                      setFieldValue(
                        ['rewards', prefixNamePath, 'config', 'whitelistId'],
                        '',
                      )
                    }
                  />
                )}
              </Col>

              <Col>
                <Form.Item name={[prefixNamePath, 'config', 'whitelistId']}>
                  <SelectWhitelist />
                </Form.Item>
              </Col>
            </Row>
          )
        }}
      </Form.Item>

      <RewardNumberFormItem
        disabled={isEdit}
        prefixNamePath={prefixNamePath}
        onChange={(value) => {
          form.setFieldValue(
            ['rewards', prefixNamePath, 'available'],
            value ?? 0,
          )
        }}
      />
      {isEdit && <AvailableFormItem prefixNamePath={prefixNamePath} />}

      <WeightFormItem prefixNamePath={prefixNamePath} />

      {/* NOTE: for css dom correctly, type must be placed at the very bottom */}
      <TypeFormItem prefixNamePath={prefixNamePath} />
    </Fragment>
  )
}

export default RewardWhitelistItem
