import { Fragment, useEffect, useState } from 'react'

import { Col, Row, UploadFile } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'
import UploadPicture from 'components/systems/uploadPicture'

import { notifyError } from 'helper'
import { useStorage } from 'hooks/systems/useStorage'

export type Fields = {
  logo?: string
  thumbnail?: string
  coverImage?: string
}

export type UploadImageFieldProps = {
  formData?: any
  onImagesChange: (fields: Fields) => void
}

const UploadImageField = (props: UploadImageFieldProps) => {
  const { formData } = props

  const [formUploadImage, setFormUploadImage] = useState<{
    logo: UploadFile[]
    thumbnail: UploadFile[]
    coverImage: UploadFile[]
  }>({
    logo: [],
    thumbnail: [],
    coverImage: [],
  })

  const { onUpload } = useStorage(604800)

  const onUploadFile = async (type: string, fileList: UploadFile<any>[]) => {
    setFormUploadImage((prev) => ({ ...prev, [type]: fileList }))
    if (!fileList.length) {
      props.onImagesChange({ [type]: undefined })
      return
    }
    const typeUrl = await onUpload(fileList[0])
    if (typeUrl) {
      props.onImagesChange({ [type]: typeUrl })
    } else {
      notifyError('Upload failed.')
    }
  }

  const handleChange = async (type: string, fileList: UploadFile<any>[]) => {
    switch (type) {
      case 'logo':
        onUploadFile(type, fileList)
        return
      case 'coverImage':
        onUploadFile(type, fileList)
        return
      case 'thumbnail':
        onUploadFile(type, fileList)
        return
    }
  }

  useEffect(() => {
    if (formData?.logo && formData?.coverImage && formData?.thumbnail) {
      setFormUploadImage({
        logo: [
          {
            uid: 'logo',
            name: 'image.png',
            status: 'done',
            url: formData?.logo,
          },
        ],
        coverImage: [
          {
            uid: 'coverImage',
            name: 'image.png',
            status: 'done',
            url: formData?.coverImage,
          },
        ],
        thumbnail: [
          {
            uid: 'thumbnail',
            name: 'image.png',
            status: 'done',
            url: formData?.thumbnail,
          },
        ],
      })
    }
  }, [formData?.coverImage, formData?.logo, formData?.thumbnail])

  return (
    <Fragment>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Logo</RequiredLabel>
          </Col>
          <Col span={24}>
            <UploadPicture
              fileList={formUploadImage.logo}
              onChangeFile={({ fileList }) => handleChange('logo', fileList)}
              maxCount={1}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Cover Image</RequiredLabel>
          </Col>
          <Col span={24}>
            <UploadPicture
              fileList={formUploadImage.coverImage}
              onChangeFile={({ fileList }) =>
                handleChange('coverImage', fileList)
              }
              maxCount={1}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Thumbnail</RequiredLabel>
          </Col>
          <Col span={24}>
            <UploadPicture
              fileList={formUploadImage.thumbnail}
              onChangeFile={({ fileList }) =>
                handleChange('thumbnail', fileList)
              }
              maxCount={1}
            />
          </Col>
        </Row>
      </Col>
    </Fragment>
  )
}

export default UploadImageField
