import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { FindPlayerSeasonDto } from 'types/player-leaderboard/player-leaderboard-season.type'

export const usePlayerSeasons = (dto?: FindPlayerSeasonDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_PLAYER_SEASON', dto],
    queryFn: () => PlayerLeaderboardService.findSeasons(dto),
  })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
  }
}
