import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Col, Form, Row, Typography, UploadFile } from 'antd'
import { onConvertFileList } from 'components/systems/uploadPicture/upload'
import Loading from 'components/systems/loading'
import FormCollection from './formCollection'

import { notifyError, notifySuccess } from 'helper'
import { useStorage } from 'hooks/systems/useStorage'
import { useCollectionData } from 'hooks/collection/useCollections'

import { ChainID, FormActions } from 'constant'
import { ROUTES } from 'constant/routes'
import {
  CollectionService,
  CreateCollection,
  UpdateCollection,
} from 'services/marketplace/collection'

const { useForm } = Form

const NewOrEditCollection = () => {
  const [form] = useForm()
  const [createLoading, setCreateLoading] = useState(false)
  const navigate = useNavigate()
  const collectionId = useParams().collectionId || ''
  const [image, setImage] = useState<UploadFile[]>([])
  const { onUpload } = useStorage(604800)
  const [totalNFT, setTotalNFT] = useState<number>(0)
  const { collectionData, loading } = useCollectionData(collectionId)

  const title = !collectionId ? 'Add Collection' : 'Edit Collection'

  const formAction = useMemo(
    () => (collectionId ? FormActions.Update : FormActions.Create),
    [collectionId],
  )

  useEffect(() => {
    if (collectionId && collectionData) {
      const filelists: UploadFile[] = onConvertFileList([
        collectionData.thumbnail,
      ])

      form.setFieldValue('contractAddress', collectionData.collectionAddress)
      form.setFieldValue('collectionName', collectionData.title)
      form.setFieldValue('description', collectionData.description)
      form.setFieldValue('chain', collectionData.chainId)
      form.setFieldValue('website', collectionData.socials.Website)
      form.setFieldValue('discord', collectionData.socials.Discord)
      form.setFieldValue('telegram', collectionData.socials.Telegram)
      form.setFieldValue('twitter', collectionData.socials.Twitter)

      setImage(filelists)
    }
  }, [collectionData, form, collectionId])

  const onSummit = async () => {
    if (!image) return
    try {
      setCreateLoading(true)
      let thumbnail = image[0]?.originFileObj ? await onUpload(image[0]) : ''
      const commonData = {
        title: form.getFieldValue('collectionName'),
        description: form.getFieldValue('description'),
        totalNFT,
        socials: {
          Website: form.getFieldValue('website'),
          Twitter: form.getFieldValue('twitter'),
          Discord: form.getFieldValue('discord'),
          Telegram: form.getFieldValue('telegram'),
        },
      }

      switch (formAction) {
        case FormActions.Update: {
          const data: UpdateCollection = {
            ...commonData,
            thumbnail: thumbnail ? thumbnail : collectionData?.thumbnail,
          }
          await CollectionService.update(collectionId, data)
          break
        }
        case FormActions.Create: {
          const data: CreateCollection = {
            ...commonData,
            thumbnail: thumbnail,
            chainId: form.getFieldValue('chain') ?? ChainID.BSC,
            collectionAddress: form.getFieldValue('contractAddress'),
          }
          await CollectionService.create(data)
          break
        }
        default: {
          notifyError('Wrong action!')
          return
        }
      }

      notifySuccess('Create collection successfully!')
      return navigate(ROUTES.REWARDS_CENTER.COLLECTION_MANAGEMENT)
    } catch (error) {
      notifyError(error)
    } finally {
      setCreateLoading(false)
    }
  }

  if (collectionId && loading) return <Loading loading />

  return (
    <Form form={form} onFinish={onSummit}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Typography.Title level={3} type="success">
            {title}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <FormCollection
            setImage={setImage}
            form={form}
            setTotalNFT={setTotalNFT}
            collectionId={collectionId}
            collectionData={collectionData}
            fileList={image}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col>
              <Button
                ghost
                style={{ width: 129, borderRadius: 2 }}
                onClick={() => navigate(ROUTES.REWARDS_CENTER.ITEMS)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{ width: 129, borderRadius: 2 }}
                loading={createLoading}
                htmlType="submit"
              >
                {collectionId ? 'Save' : 'Add'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}
export default NewOrEditCollection
