import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Button, Col, InputNumber, Row, Select, Typography } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'
import CardInfo from 'components/systems/cardInfo'
import SpaceBetween from 'components/systems/spaceBetween'
import SelectNFTBadge from 'components/systems/selectNFTBadge'
import IonIcon from 'components/systems/ionIcon'
import NFTItem from 'view/bounty/management/createAndUpdate/dappBounty/formPrizes/nftSelected/nftItem'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import { useCreateProductData } from 'view/itemManagement'
import { useChains } from 'hooks/chain/useChain'
import usePaymentCurrencies from 'hooks/systems/usePaymentCurrencies'

import { DUMMY_PRICE_TOKEN } from 'constant/marketplace'

import { PriceBadge, PriceToken } from 'types/marketplace.type'

const SaleInformation = () => {
  const [open, setOpen] = useState(false)
  const [chainCurrency, setChainCurrency] = useState<string[]>([])
  const [selectedBadges, setSelectedBadges] = useState<PriceBadge[]>([])

  const chainConfig = useChains()
  const { selectedChain } = useContext(SelectChainContext)
  const [createProductData, setCreateProductData] = useCreateProductData()
  const getPaymentCurrencies = usePaymentCurrencies()

  const nativeToken = useMemo(() => {
    return chainConfig[selectedChain].nativeToken
  }, [chainConfig, selectedChain])

  const paymentCurrencies = useMemo(
    () => getPaymentCurrencies(selectedChain),
    [getPaymentCurrencies, selectedChain],
  )

  const tokenName = useMemo(() => {
    const prevTokenName = createProductData?.priceToken?.tokenName

    if (prevTokenName && paymentCurrencies.includes(prevTokenName)) {
      return prevTokenName
    }

    return paymentCurrencies?.[0] ?? nativeToken.symbol
  }, [
    createProductData?.priceToken?.tokenName,
    nativeToken.symbol,
    paymentCurrencies,
  ])

  const renderCurrencyByChain = useCallback(() => {
    setChainCurrency(paymentCurrencies)
    setCreateProductData((prev) => ({
      ...prev,
      priceToken: {
        ...(paymentCurrencies.length > 0 ? prev.priceToken : DUMMY_PRICE_TOKEN),
        chainId: selectedChain,
        tokenName,
      },
    }))
  }, [tokenName, paymentCurrencies, selectedChain, setCreateProductData])

  useEffect(() => {
    renderCurrencyByChain()
  }, [renderCurrencyByChain])

  useEffect(() => {
    setCreateProductData((prev) => ({
      ...prev,
      priceToken: {
        ...prev?.priceToken,
        chainId: selectedChain,
        tokenName,
      } as PriceToken,
    }))
  }, [tokenName, selectedChain, setCreateProductData])

  const handleChangeBadgePrice = useCallback(
    (badges: string[]) => {
      const newPriceBadges = badges.map((badgeId) => {
        const badgeIndex = selectedBadges.findIndex(
          (item) => item.badgeId === badgeId,
        )
        if (badgeIndex < 0) return { badgeId, amount: 1 }

        return { badgeId, amount: selectedBadges[badgeIndex].amount }
      })

      return setCreateProductData((prev) => ({
        ...prev,
        priceBadges: newPriceBadges,
      }))
    },
    [selectedBadges, setCreateProductData],
  )

  const handleChangeAmount = useCallback(
    (badgeId: string, value: number | null) => {
      if (!value) return

      const newSelectedBadges = [...selectedBadges]
      const index = newSelectedBadges.findIndex((e) => e.badgeId === badgeId)
      if (index < 0) return
      newSelectedBadges[index].amount = value

      return setCreateProductData({
        ...createProductData,
        priceBadges: newSelectedBadges,
      })
    },
    [createProductData, selectedBadges, setCreateProductData],
  )

  const handleDeleteBadgeItem = useCallback(
    (badgeId: string) => {
      const newPriceBadges = selectedBadges.filter(
        (badge) => badge.badgeId !== badgeId,
      )
      return setCreateProductData({
        ...createProductData,
        priceBadges: newPriceBadges,
      })
    },
    [createProductData, selectedBadges, setCreateProductData],
  )

  useEffect(() => {
    // Update list (badgeId and amount) when createProductData.priceBadges have change
    setSelectedBadges(createProductData.priceBadges)

    return () => setSelectedBadges([])
  }, [createProductData.priceBadges])

  return (
    <Row>
      <Col span={12}>
        <SpaceVertical style={{ width: '100%' }} size={8}>
          <Typography.Text type="secondary" aria-required>
            Price
          </Typography.Text>
          {chainCurrency.length > 0 && (
            <InputNumber
              min={0}
              addonAfter={
                <Select
                  value={createProductData.priceToken.tokenName}
                  className="select-after"
                  onChange={(tokenName) =>
                    setCreateProductData((prev) => ({
                      ...prev,
                      priceToken: {
                        ...prev.priceToken,
                        chainId: selectedChain,
                        tokenName,
                      } as PriceToken,
                    }))
                  }
                >
                  {chainCurrency.map((item, idx) => (
                    <Select.Option key={idx} value={item}>
                      {item.toUpperCase()}
                    </Select.Option>
                  ))}
                </Select>
              }
              defaultValue={0}
              style={{ width: '100%' }}
              value={createProductData.priceToken.price || 0}
              onChange={(value: number | null) =>
                setCreateProductData((prev) => ({
                  ...prev,
                  priceToken: {
                    ...prev.priceToken,
                    price: value ?? 0,
                  } as PriceToken,
                }))
              }
            />
          )}
          <InputNumber
            min={0}
            defaultValue={0}
            addonAfter="CCP"
            style={{ width: '100%' }}
            value={createProductData.priceCCP || 0}
            onChange={(value: number | null) =>
              setCreateProductData((prev) => ({
                ...prev,
                priceCCP: value ?? 0,
              }))
            }
          />
          <CardInfo title="NFT Badges">
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <SpaceBetween
                  floatContent={
                    <Fragment>
                      <Button
                        icon={<IonIcon name="add-outline" />}
                        onClick={() => setOpen(true)}
                        ghost
                      >
                        Add Collection
                      </Button>
                      <SelectNFTBadge
                        badgeSelected={selectedBadges.map(
                          (badge) => badge.badgeId,
                        )}
                        setBadgeSelected={handleChangeBadgePrice}
                        multiple={true}
                        open={open}
                        setOpen={setOpen}
                      />
                    </Fragment>
                  }
                >
                  <Typography.Text type="secondary">NFT Badges</Typography.Text>
                </SpaceBetween>
              </Col>
              <Col span={24}>
                {selectedBadges.map(({ badgeId, amount }) => (
                  <NFTItem
                    onDelete={handleDeleteBadgeItem}
                    badgeId={badgeId}
                    amount={amount}
                    onChangeAmount={(value) =>
                      handleChangeAmount(badgeId, value)
                    }
                  />
                ))}
              </Col>
            </Row>
          </CardInfo>
        </SpaceVertical>
      </Col>
    </Row>
  )
}

export default SaleInformation
