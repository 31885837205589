import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Col, Input, Row } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'

import useHandle from 'hooks/useHandle'
import { useTeamsLeaderboardCreate } from 'hooks/leaderboard/useTeamsLeaderboardMutation'

import { CreateTeamLeaderBoardDto } from 'services/bountySystem/leaderboard/type'

import { notifySuccess } from 'helper'

import { DEFAULT_CREATE_TEAM_LEADERBOARD_DATA } from 'constant/leaderboard'
import { ROUTES } from 'constant/routes'

const CreateTeamLeaderboard = () => {
  const navigate = useNavigate()
  const seasonId = useParams().seasonId || ''

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(DEFAULT_CREATE_TEAM_LEADERBOARD_DATA)

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const { mutateAsync } = useTeamsLeaderboardCreate({
    ...formData,
    seasonId: seasonId,
  })

  const validateFields = (formData: CreateTeamLeaderBoardDto) => {
    const { name, point } = formData

    if (name.trim().length < 3 || name.trim().length > 32) {
      throw new Error('Name must be between 3 - 32 characters.')
    }

    if (!(point >= 0)) throw new Error('Point must be a number >= 0')
  }

  const onSave = useHandle(async () => {
    validateFields(formData)

    await mutateAsync()
    notifySuccess('Created team')
    navigate(ROUTES.LEADERBOARD.INDEX + `/${seasonId}`)
  }, setLoading)

  useEffect(() => {
    const { name } = formData
    const isDisabled = !name
    setSubmitDisabled(isDisabled)
  }, [formData])

  return (
    <Row gutter={[24, 24]} style={{ paddingTop: 20, maxWidth: 486 }}>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Team Name (3-32 characters)</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={formData.name}
              placeholder="Enter team name..."
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Point (It should be 0)</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={formData.point}
              placeholder="Enter point..."
              onChange={(e) =>
                setFormData({
                  ...formData,
                  point: parseInt(e.target.value || '0'),
                })
              }
              type="numeric"
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Button
          type="primary"
          disabled={submitDisabled}
          onClick={onSave}
          loading={loading}
        >
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default CreateTeamLeaderboard
