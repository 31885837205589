import { marketplaceApi as api } from 'services/base-axios'

import { GetAllByAdminDto } from './type'
import { IChain } from 'types/chain.type'

const CONTROLLER = '/admin/chain'

class Chain {
  async getAllChain(dto: GetAllByAdminDto): Promise<IChain[]> {
    return api.get(CONTROLLER, { params: dto }).then((res) => res.data)
  }
}

export const chainService = new Chain()
