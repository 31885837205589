import moment from 'moment'

import { Col, DatePicker, Row } from 'antd'

import SpaceVertical from 'components/systems/spaceVertical'
import IonIcon from 'components/systems/ionIcon'
import RequiredLabel from 'components/systems/requiredSymbol'

import { convertTzUtcBeforeUpload } from 'helper'

import { TIME_FORMAT } from 'constant'

export type DateRangeProps = {
  startAt?: string
  endAt?: string
  onChangeStartAt: (date: string) => void
  onChangeEndAt: (date: string) => void
  isError?: boolean
  fromLabel?: string
  toLabel?: string
  visibleLabel?: boolean
  requiredMark?: boolean
  disabled?: { start?: boolean; end?: boolean }
}

const DateRange = ({
  startAt,
  endAt,
  onChangeStartAt,
  onChangeEndAt,
  isError = false,
  fromLabel = 'Start (UTC)',
  toLabel = 'End (UTC)',
  visibleLabel = true,
  requiredMark = true,
  disabled = { start: false, end: false },
}: DateRangeProps) => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <SpaceVertical>
          {visibleLabel && (
            <RequiredLabel required={requiredMark}>{fromLabel}</RequiredLabel>
          )}
          <DatePicker
            onChange={(date) =>
              onChangeStartAt(date ? convertTzUtcBeforeUpload(date) : '')
            }
            style={{ width: '100%' }}
            format={TIME_FORMAT}
            value={startAt ? moment.utc(startAt) : undefined}
            suffixIcon={<IonIcon name="calendar-outline" />}
            allowClear
            status={isError && !startAt ? 'error' : undefined}
            showTime={{ defaultValue: moment.utc('00:00:00', 'HH:mm:ss') }}
            disabled={disabled.start}
          />
        </SpaceVertical>
      </Col>
      <Col span={12}>
        <SpaceVertical>
          {visibleLabel && (
            <RequiredLabel required={requiredMark}>{toLabel}</RequiredLabel>
          )}
          <DatePicker
            onChange={(date) =>
              onChangeEndAt(date ? convertTzUtcBeforeUpload(date) : '')
            }
            style={{ width: '100%' }}
            disabledDate={(currentDate) => currentDate.isBefore(startAt)}
            format={TIME_FORMAT}
            value={endAt ? moment.utc(endAt) : undefined}
            suffixIcon={<IonIcon name="calendar-outline" />}
            allowClear
            clearIcon={<IonIcon name="close-circle" />}
            status={isError && !endAt ? 'error' : undefined}
            showTime={{ defaultValue: moment.utc('00:00:00', 'HH:mm:ss') }}
            disabled={disabled.end}
          />
        </SpaceVertical>
      </Col>
      {/* <Col>
        <SpaceVertical>
          <Typography.Text type="secondary">No end time</Typography.Text>
          <Switch onChange={onSwitchChange} />
        </SpaceVertical>
      </Col> */}
    </Row>
  )
}

export default DateRange
