import { useContext } from 'react'

import {
  EditOutlined as EditIcon,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Space } from 'antd'

import ButtonIcon from 'components/systems/button-base/ButtonIcon'
import { MultiplierModalContext } from 'components/multiplier/createAndUpdate/MultiplierModalContext'

import {
  useRepublishedMultiplier,
  useUnpublishedMultiplier,
} from 'hooks/multiplier/useUpdateMultiplier'
import { useMultipliers } from 'hooks/multiplier/useMultipliers'

import { notifyError, notifySuccess } from 'helper'

import { IMultiplier } from 'types/multiplier.type'

type MultiplierRowActionProps = {
  record: IMultiplier
}

function MultiplierRowAction({ record }: MultiplierRowActionProps) {
  const { setIsModalOpen, setMultiplierId } = useContext(MultiplierModalContext)

  const { _id: multiplierId, isActive } = record

  const { mutateAsync: unpublishedMultiplier } = useUnpublishedMultiplier()
  const { mutateAsync: republishMultiplier } = useRepublishedMultiplier()
  const { refetchRoot } = useMultipliers({})

  const handleChangeStatus = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.stopPropagation()

    try {
      if (isActive) {
        await unpublishedMultiplier(multiplierId)
      } else {
        await republishMultiplier(multiplierId)
      }

      notifySuccess(
        `${isActive ? 'Unpublished Multiplier' : 'Republished Multiplier'}`,
      )
      await refetchRoot()
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <Space size={0}>
      {isActive && (
        <ButtonIcon
          onClick={handleChangeStatus}
          icon={<EyeOutlined />}
          wrapperCls="multiplier-row-action-btn"
        />
      )}

      {!isActive && (
        <ButtonIcon
          onClick={handleChangeStatus}
          icon={<EyeInvisibleOutlined />}
          wrapperCls="multiplier-row-action-btn"
        />
      )}

      <ButtonIcon
        icon={<EditIcon />}
        onClick={(e) => {
          e.stopPropagation()
          setIsModalOpen(true)
          setMultiplierId(multiplierId)
        }}
        wrapperCls="multiplier-row-action-btn"
      />
    </Space>
  )
}

export default MultiplierRowAction
