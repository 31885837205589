import { Nft } from '@metaplex-foundation/js'

// Reward type
export type CcpRewardConfigs = {
  amount: string
}

export type NftBadgeRewardConfigs = {
  collection: string
  nft?: Nft
}

export type RewardConfigs = CcpRewardConfigs | NftBadgeRewardConfigs

export enum RewardType {
  CCP = 'CCP',
  NFTBadge = 'NFTBadge',
}
