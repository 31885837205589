import { Typography } from 'antd'
import { TASK_NOT_COMPLETED } from 'constant/bounty'
import { CSSProperties } from 'react'
import { BountyCertState } from 'services/bountySystem/bounty'

const TAG_COLOR = {
  [BountyCertState.Claimed]: '#1EAF6A',
  [BountyCertState.Completed]: '#1EAF6A',
  [BountyCertState.Processing]: '#D7A647',
  [TASK_NOT_COMPLETED]: '#DB4646',
}

type TagBountyStatusProps = {
  state: BountyCertState
  isEnded?: boolean
  style?: CSSProperties
}

const TagBountyStatus = ({
  state,
  isEnded = false,
  style,
}: TagBountyStatusProps) => {
  const key =
    isEnded && state === BountyCertState.Processing ? TASK_NOT_COMPLETED : state

  const color = TAG_COLOR[key]
  const background = `${color}25`
  const defaultStyle = {
    color,
    background,
    padding: '6px 10px',
    borderRadius: 20,
  }
  const cloneStyle = style ? { ...defaultStyle, ...style } : { ...defaultStyle }

  return <Typography.Text style={cloneStyle}>{key}</Typography.Text>
}

export default TagBountyStatus
