import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'
import OrderInfo from './oderInfo'
import LogisticInfo from './logisticInfo'
import DeliveryInfo from './deliveryInfo'
import { WrapLoading } from 'components/systems/loading'
import ShippingState from 'components/marketPlace/order/shippingState'
import OrderState from 'components/marketPlace/order/orderState'

import { useOrderData } from 'hooks/order/useOrder'
import { ProductType } from 'types/product.type'

import './index.less'

const OrderDetail = () => {
  const orderId = useParams().orderId || ''
  const { orderData, loading } = useOrderData(orderId)
  const { orderStatus, productData, shippingStatus, delivery } = orderData
  const isDigital = productData.productType === ProductType.NFT

  return (
    <WrapLoading loading={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row align="middle">
            <Col flex="auto">
              <Typography.Title type="success" level={3}>
                Order Detail
              </Typography.Title>
            </Col>
            <Col>
              {isDigital ? (
                <OrderState state={orderStatus} />
              ) : (
                <ShippingState state={shippingStatus} />
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <OrderInfo orderData={orderData} />
        </Col>
        <Col span={24}>
          <DeliveryInfo delivery={delivery || {}} />
        </Col>
        <Col span={24}>
          <LogisticInfo
            isDigital={isDigital}
            shippingStatus={shippingStatus}
            orderStatus={orderStatus}
          />
        </Col>
      </Row>
    </WrapLoading>
  )
}

export default OrderDetail
