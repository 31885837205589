import { useQuery } from 'react-query'

import { WhitelistService } from 'services/marketplace/whitelist'

export const useWhitelistDetail = (id?: string) => {
  return useQuery({
    queryKey: ['useWhitelistDetail', id],
    queryFn: async () => WhitelistService.getById(id!),
    enabled: !!id,
  })
}
