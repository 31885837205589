import { generatePath, useNavigate } from 'react-router-dom'

import { Table } from 'antd'

import { WrapLoading } from 'components/systems/loading'
import { MULTIPLIERS_COLUMNS } from './columns'

import { ROUTES } from 'constant/routes'

import { IMultiplier } from 'types/multiplier.type'

type MultipliersTableProps = {
  data: IMultiplier[]
  isLoading: boolean
}

function MultipliersTable({ data, isLoading }: MultipliersTableProps) {
  const navigate = useNavigate()

  return (
    <WrapLoading loading={isLoading}>
      <Table
        rowKey="_id"
        onRow={({ _id: multiplierId }) => {
          return {
            onClick: () => {
              navigate(
                generatePath(ROUTES.BOUNTY.VIEW_MULTIPLIER, {
                  multiplierId,
                }),
              )
            },
          }
        }}
        className="table-border-collapse"
        columns={MULTIPLIERS_COLUMNS}
        dataSource={data}
        pagination={false}
      />
    </WrapLoading>
  )
}

export default MultipliersTable
