import { bountySystemApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import {
  CreateCampaignDto,
  GetCampaignDto,
  ICampaign,
} from 'types/campaign.type'

const CONTROLLER = '/admin/campaign'

class Campaign {
  async createCampaign(payload: CreateCampaignDto) {
    return await api
      .post<ICampaign>(CONTROLLER, payload)
      .then(({ data }) => data)
  }

  async updateCampaign(campaignId: string, payload: CreateCampaignDto) {
    return await api.patch(`${CONTROLLER}/${campaignId}`, payload)
  }

  async getListOfCampaign({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
    communityId,
    isEnabled,
  }: GetCampaignDto) {
    return await api
      .get(CONTROLLER, {
        params: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          search,
          communityId,
          isEnabled,
        },
      })
      .then((data) => data.data)
  }

  async getCampaignDetails(campaignId: string) {
    return await api
      .get(`${CONTROLLER}/${campaignId}`)
      .then((data) => data.data)
  }

  async updateBountiesOfCampaign(campaignId: string, bountyIds: string[]) {
    return api
      .put(`${CONTROLLER}/${campaignId}/bounties`, { bountyIds })
      .then(({ data }) => data)
  }
}

export const CampaignService = new Campaign()
