import React, { Fragment } from 'react'

import { Form, FormInstance, Input } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'

import { useMissionIdBySlug } from 'hooks/acceler8/useMissionIdBySlug'

const getMissionSlug = (url: string) => {
  try {
    const urlObject = new URL(url)
    return urlObject.pathname.split('/').pop()
  } catch (e) {
    return ''
  }
}

function Space3DetailSection({ form }: { form: FormInstance }) {
  const { mutateAsync: fetchMissionBySlug } = useMissionIdBySlug()

  return (
    <Fragment>
      <Form.Item
        label={<RequiredLabel children="Space3 Mission Link" />}
        name={['configs', 'url']}
        rules={[
          {
            required: true,
            message: 'Please enter Space3Mission Link',
          },
          {
            type: 'url',
            message: 'Please enter valid Space3Mission Link',
          },
          {
            validator: async (_, value) => {
              try {
                const slug = getMissionSlug(value)
                if (!slug) {
                  return Promise.reject(
                    new Error('Please enter valid Space3 Mission Link'),
                  )
                }

                const { _id } = await fetchMissionBySlug(slug)

                if (!_id) {
                  return Promise.reject(
                    new Error('Please enter valid Space3 Mission Link'),
                  )
                }

                form.setFieldsValue({ configs: { missionId: _id } })

                return Promise.resolve()
              } catch {
                form.setFieldValue(['configs', 'missionId'], '')
                return Promise.reject(
                  new Error('Please enter valid Space3 Mission Link'),
                )
              }
            },
          },
        ]}
      >
        <Input placeholder="Enter Space3 Mission Link" />
      </Form.Item>

      <Form.Item name={['configs', 'missionId']} label="Misison Id" hidden>
        <Input placeholder="Enter Space3 Mission Id" />
      </Form.Item>
    </Fragment>
  )
}

export default Space3DetailSection
