import { createContext, ReactNode, useState } from 'react'

type WhitelistModalContextProps = {
  isModalOpen: boolean
  isEdit: boolean
  selectedWhitelistId?: string
  openModal: (isEdit: boolean, whitelistId?: string) => void
  closeModal: () => void
}

export const WhitelistModalContext = createContext<WhitelistModalContextProps>({
  isModalOpen: false,
  isEdit: false,
  selectedWhitelistId: undefined,
  openModal: () => {},
  closeModal: () => {},
})

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedWhitelistId, setSelectedWhitelistId] = useState<
    string | undefined
  >()

  const openModal = (isEdit: boolean, whitelistId?: string) => {
    setIsModalOpen(true)
    setIsEdit(isEdit)
    setSelectedWhitelistId(whitelistId)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setIsEdit(false)
    setSelectedWhitelistId(undefined)
  }

  return (
    <WhitelistModalContext.Provider
      value={{
        isModalOpen,
        isEdit,
        selectedWhitelistId,
        openModal,
        closeModal,
      }}
    >
      {children}
    </WhitelistModalContext.Provider>
  )
}
