import { useEffect, useState } from 'react'

import { Col, Input, Row, Space, Table, Typography } from 'antd'
import Header from './header'
import SpaceBetween from 'components/systems/spaceBetween'
import CustomPagination from 'components/systems/pagination'
import Filter from './filter'
import FilterCategories from './filterCategories'
import OrdersSelected from './orderSelected'

import { useOrders } from 'hooks/order/useOrder'
import { PHYSICAL_SHIPPING_COLUMN } from './column'
import { ProductType } from 'types/product.type'
import { DEFAULT_PAGE } from 'constant'
import { ROUTES } from 'constant/routes'
import { ShippingStatus } from 'types/order.type'

import './index.less'
import { openInNewTab } from 'helper/common'

const { Search } = Input

const PhysicalShipping = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [status, setStatus] = useState<ShippingStatus>()
  const [categoryId, setCategoryId] = useState<string>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { orders, total, loading, refetch } = useOrders({
    page,
    productType: ProductType.REAL_NFT,
    search,
    shippingStatus: status,
    categoryId,
  })

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  useEffect(() => {
    setPage(DEFAULT_PAGE)
  }, [status, search, categoryId])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Header />
      </Col>
      <Col span={24}>
        <OrdersSelected
          ordersSelected={selectedRowKeys}
          refresh={refetch}
          onSelectChange={onSelectChange}
        />
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col flex={1}>
                <Search
                  placeholder="Search Item Name, UID"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ width: 400 }}
                />
              </Col>
              <Col>
                <Space size={24}>
                  <FilterCategories
                    categoryId={categoryId}
                    onSelect={setCategoryId}
                  />
                  <Filter status={status} onSelect={setStatus} />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              className="table-border-collapse"
              rowSelection={rowSelection}
              columns={PHYSICAL_SHIPPING_COLUMN({ refetch })}
              dataSource={orders}
              rowKey={({ _id }) => _id}
              pagination={false}
              loading={loading}
              style={{ cursor: 'pointer' }}
              onRow={({ _id }) => ({
                onClick: () =>
                  openInNewTab(ROUTES.REWARDS_CENTER.USER_SHIPPING + `/${_id}`),
              })}
            />
          </Col>
          <Col span={24}>
            <SpaceBetween
              floatContent={
                <CustomPagination
                  page={page}
                  total={total}
                  onChange={(page) => {
                    onSelectChange([])
                    setPage(page)
                  }}
                />
              }
            >
              <Typography.Text type="success">Total: {total}</Typography.Text>
            </SpaceBetween>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <OrdersSelected
          ordersSelected={selectedRowKeys}
          refresh={refetch}
          onSelectChange={onSelectChange}
        />
      </Col>
    </Row>
  )
}

export default PhysicalShipping
