import { useQuery } from 'react-query'

import { CommunityProfileService } from 'services/bountySystem/community/community-profile'

export const useCommunityProfile = (communityId: string) => {
  return useQuery({
    queryKey: ['community-profile', communityId],
    queryFn: () =>
      CommunityProfileService.getAllSectionByCommunityId({ communityId }),
  })
}
