import moment from 'moment'

import { Col, Divider, Row, Typography } from 'antd'
import OrderMetadata from './metadata'
import CardInfo from 'components/systems/cardInfo'
import SpaceVertical from 'components/systems/spaceVertical'
import ImageLazyLoad from 'components/systems/imageLazyLoad'

import { TIME_FORMAT } from 'constant'
import { OrderData } from 'types/order.type'
import { useMapOrderPrice } from 'hooks/order/useMapOrderPrice'
import { ProductType } from 'types/product.type'

type OrderInfoProps = {
  orderData: OrderData
}
const OrderInfo = ({ orderData }: OrderInfoProps) => {
  const { productData, skuData } = orderData
  const isDigital = productData.productType === ProductType.NFT
  const img = isDigital ? productData.thumbnails[0] : skuData.thumbnail
  const mapPrice = useMapOrderPrice(orderData)

  return (
    <CardInfo title="Order Information">
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]} align="middle">
            <Col>
              <ImageLazyLoad lazyHeight={121} width={121} src={img} />
            </Col>
            <Col span={20}>
              <Row gutter={[0, 26]}>
                <Col span={24}>
                  <Typography.Title level={4}>
                    {productData.title}
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <OrderMetadata orderData={orderData} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <SpaceVertical size={12}>
            <Typography.Text type="secondary">Description</Typography.Text>
            <Typography.Text>
              {orderData.productData.description}
            </Typography.Text>
          </SpaceVertical>
        </Col>
        <Col span={24}>
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col span={24}>
          <SpaceVertical size={6}>
            <Typography.Text type="secondary">Total</Typography.Text>
            <Typography.Title type="success" level={2}>
              {mapPrice.map((price, index) => {
                if (index === mapPrice.length - 1) return price
                return price + ' & '
              })}
            </Typography.Title>
          </SpaceVertical>
        </Col>
        <Col span={24}>
          <Row gutter={[24, 25]}>
            <Col span={10}>
              <SpaceVertical size={6}>
                <Typography.Text type="secondary">Order ID</Typography.Text>
                <Typography.Title level={5}>{orderData?._id}</Typography.Title>
              </SpaceVertical>
            </Col>
            <Col span={14}>
              <SpaceVertical size={6}>
                <Typography.Text type="secondary">Create Time</Typography.Text>
                <Typography.Title level={5}>
                  {moment.utc(orderData?.createdAt).format(TIME_FORMAT)} (UTC)
                </Typography.Title>
              </SpaceVertical>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <SpaceVertical size={6}>
            <Typography.Text type="secondary">UID</Typography.Text>
            <Typography.Title level={5}>{orderData?.uid}</Typography.Title>
          </SpaceVertical>
        </Col>
      </Row>
    </CardInfo>
  )
}

export default OrderInfo
