import { useQuery } from 'react-query'

import { nftService } from 'services/nft'

export const useNftDetail = (nftId: string) =>
  useQuery({
    queryKey: ['GET_NFT_DETAIL', nftId],
    queryFn: () => nftService.getNftById(nftId),
    enabled: !!nftId,
  })
