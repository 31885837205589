import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { Button, Col, Image, Row, Space, Table, Typography } from 'antd'

import CardInfo from 'components/systems/cardInfo'
import CustomPagination from 'components/systems/pagination'
import { TeamRankRow, teamColumn } from './teamColumn'
import { prizeColumn } from './prizeColumn'

import { useAcceler8Season } from 'hooks/acceler8/useAcceler8Season'
import { useAcceler8Ranks } from 'hooks/acceler8/useAcceler8Ranks'
import { useAcceler8Teams } from 'hooks/acceler8/useAcceler8Teams'
import { useAcceler8SeasonBefore } from 'hooks/acceler8/useAcceler8SeasonBefore'

import { DEFAULT_PAGE, TIME_FORMAT } from 'constant'
import { ROUTES } from 'constant/routes'

import { TeamSortOptions } from 'types/acceler8/acceler8-team.type'

const PAGE_SIZE = 10

const Acceler8SeasonDetail = () => {
  const navigate = useNavigate()
  // TODO: implement when API is ready
  // const [search, setSearch] = useState<string>('')
  const seasonId = Number(useParams().seasonId)
  const [page, setPage] = useState<number>(DEFAULT_PAGE)

  const { data: seasonData } = useAcceler8Season(seasonId)

  const { data: previousSeasonData } = useAcceler8SeasonBefore(seasonData?.id)

  const {
    data: teamsData,
    isLoading: isBoardLoading,
    total,
  } = useAcceler8Teams({
    seasonId,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    sort: TeamSortOptions.CurrentSeasonRank,
  })

  const { data: ranksData } = useAcceler8Ranks({
    seasonId: previousSeasonData?.id,
    teamIds: teamsData.map((team) => team.id),
  })

  const totalPage = useMemo(() => {
    return Math.ceil(total / PAGE_SIZE)
  }, [total])

  const tableData: TeamRankRow[] = useMemo(() => {
    const ranksByTeamId: Record<number, number> = {}
    ranksData?.forEach((rankData) => {
      ranksByTeamId[rankData.teamId] = rankData.ranking
    })

    return teamsData.map((teamData) => ({
      ...teamData,
      previousRanking: ranksByTeamId[teamData.id],
    }))
  }, [teamsData, ranksData])
  // TODO: implement when API is ready
  // const onSearch = useCallback(
  //   (keyword: string) => {
  //     setSearch(keyword)
  //   },
  //   [setSearch],
  // )

  return (
    <Row gutter={[24, 50]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={3} type="success">
              Season {seasonData?.title}:{' '}
              <Typography.Text type="secondary">
                {seasonData?.subTitle}
              </Typography.Text>
            </Typography.Title>
          </Col>
          <Col>
            <Button
              size="small"
              ghost
              onClick={() =>
                navigate(ROUTES.ACCELER8.EDIT_SEASON + '/' + seasonId)
              }
            >
              Edit
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Image
              src={seasonData?.banner}
              width="100%"
              style={{ aspectRatio: '22/9', objectFit: 'cover' }}
              preview={false}
            />
          </Col>
          <Col span={24}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Time</Typography.Text>
              <Space direction="vertical" size={0}>
                <Space>
                  <Typography.Text type="success">Start:</Typography.Text>
                  <Typography.Text>
                    {moment.utc(seasonData?.startedAt).format(TIME_FORMAT)} UTC
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text type="success">End:</Typography.Text>
                  <Typography.Text>
                    {moment.utc(seasonData?.endedAt).format(TIME_FORMAT)} UTC
                  </Typography.Text>
                </Space>
              </Space>
            </Space>
          </Col>

          <Col span={10}>
            <CardInfo title="Prize structure">
              <Table
                dataSource={seasonData?.prizeStructures}
                columns={prizeColumn}
                rowKey="title"
                pagination={false}
              />
            </CardInfo>
          </Col>

          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Col>
                <Typography.Text type="secondary">Leaderboard</Typography.Text>
              </Col>
              <Col span={24}>
                {/* TODO: implement when API is ready */}
                {/* <SearchBar setSearch={onSearch} /> */}
              </Col>
              <Col span={24}>
                <Table
                  dataSource={tableData}
                  columns={teamColumn}
                  rowKey="id"
                  loading={isBoardLoading}
                  pagination={false}
                />
              </Col>
              <Col span={24}>
                <Row align="middle">
                  <Col flex="auto">
                    <Typography.Text type="success">
                      Total: {totalPage}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <CustomPagination
                      total={total}
                      onChange={setPage}
                      page={page}
                      pageSize={PAGE_SIZE}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Acceler8SeasonDetail
