import { CSSProperties, useCallback, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Button } from 'antd'

import { AppDispatch } from 'store'
import { login, reLogin } from 'store/user.reducer'
import { getCustomers } from 'store/customer.reducer'
import { useIsLoggedIn } from 'hooks/user/useIsLoggedIn'
import { notifyError } from 'helper'
import { ROUTES } from 'constant/routes'
import configs from 'configs'

export type LogInProps = { style?: CSSProperties }

const ConnectWallet = ({ style }: LogInProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const isLoggedIn = useIsLoggedIn()

  const loadUserData = useCallback(() => {
    dispatch(getCustomers())
  }, [dispatch])

  const onLogin = useCallback(async () => {
    if (!code || isLoggedIn) return
    try {
      const { userProfile } = await dispatch(login(code)).unwrap()
      if (userProfile?._id) loadUserData()
    } catch (err) {
      notifyError(err)
    } finally {
      navigate(ROUTES.BOUNTY.INDEX)
    }
  }, [code, isLoggedIn, dispatch, loadUserData, navigate])

  useEffect(() => {
    onLogin()
  }, [onLogin])

  const handleReLogin = useCallback(async () => {
    const { userProfile } = await dispatch(reLogin()).unwrap()
    if (userProfile?._id) loadUserData()
  }, [dispatch, loadUserData])

  useEffect(() => {
    handleReLogin()
  }, [handleReLogin])

  return (
    <Button
      type="primary"
      className="clip-path"
      style={{ ...style, fontWeight: 700 }}
      onClick={() => window.open(configs.api.ancient8Oath, '_self')}
    >
      Login with A8 ID
    </Button>
  )
}

export default ConnectWallet
