import { useQuery, useQueryClient } from 'react-query'

import { WhitelistService } from 'services/marketplace/whitelist'

import { CheckWalletWhitelistsExisted } from 'types/whitelist.type'

const ROOT_KEY = 'CHECK_WALLET_WHITELISTS_EXISTED'
export default function useCheckWalletWhitelistsExisted(
  dto: CheckWalletWhitelistsExisted,
) {
  const data = useQuery({
    queryKey: [ROOT_KEY, JSON.stringify(dto)],
    queryFn: async () => WhitelistService.checkWalletWhitelistsExisted(dto),
    enabled: !!dto.whitelistId && !!dto.addresses?.length,
  })

  const queryClient = useQueryClient()
  const refetchRoot = () =>
    queryClient.refetchQueries({
      queryKey: [ROOT_KEY],
    })

  return {
    ...data,
    refetchRoot,
  }
}
