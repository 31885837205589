import { useQuery, useInfiniteQuery } from 'react-query'

import { DigitalCardService } from 'services/marketplace/digital-card'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

type FilterDigitalCards = {
  page?: number
  search?: string
}

const fetchDigitalCards = async (search: string, page: number) => {
  const { data, total } = await DigitalCardService.getAll({
    page,
    search,
  })
  return { data, total }
}

export const useDigitalCards = ({
  enabled = true,
  page = DEFAULT_PAGE,
  search = '',
}: FilterDigitalCards & { enabled?: boolean }) => {
  const {
    data: digitalCards,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['GET_DIGITAL_CARDS', page, search],
    queryFn: () => fetchDigitalCards(search, page),
    enabled,
  })

  return {
    digitalCards: digitalCards?.data || [],
    total: digitalCards?.total || 0,
    isLoading,
    error,
  }
}

export const useDigitalCardsInfinite = ({
  enabled = true,
  search = '',
  page = DEFAULT_PAGE,
}: FilterDigitalCards & { enabled?: boolean }) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['GET_DIGITAL_CARDS_INFINITE', page, search],
    queryFn: async ({ pageParam = page }) => {
      const { data, total } = await fetchDigitalCards(search, pageParam)

      return {
        nextPage:
          pageParam < Math.ceil(total / DEFAULT_PAGE_SIZE)
            ? pageParam + 1
            : undefined,
        data,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    keepPreviousData: true,
    enabled,
  })

  const digitalCards = data?.pages.flatMap((page) => page.data)

  return {
    digitalCards: digitalCards ?? [],
    ...rest,
  }
}

export const useDigitalCard = (digitalCardId: string) => {
  const { data, isLoading, error } = useQuery(
    ['GET_DIGITAL_CARD_DETAILS', digitalCardId],
    () => DigitalCardService.getDigitalCardById(digitalCardId),
  )

  return {
    digitalCard: data,
    isLoading,
    error,
  }
}
