import { useQuery } from 'react-query'

import { AffiliationService } from 'services/affiliation-v2'

import { FindSubscriptionOverallDto, SortOptions } from 'types/affiliation.type'

export const DEFAULT_FILTER_OVERALL_DATA = {
  sortOption: SortOptions.RecentlyActivated,
  filterOptions: [],
  searchValue: '',
}

export const useSubscriptionOverall = (dto?: FindSubscriptionOverallDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_SUBSCRIPTION_OVERALL', dto],
    queryFn: () => AffiliationService.findSubscriptionOverall(dto),
  })

  return {
    ...rest,
    data: data?.data,
    total: data?.total ?? 0,
  }
}
