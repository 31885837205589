import { Typography } from 'antd'

import RowAction from './rowAction'

import { STAGE_NAME_LABEL, SeasonStage } from 'types/season.type'

export const COLUMN_SEASON = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name: string) => (
      <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ margin: 0 }}>
        {name}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Stage',
    dataIndex: 'stage',
    render: (stage: SeasonStage) => (
      <Typography.Text>{STAGE_NAME_LABEL[stage]}</Typography.Text>
    ),
  },
  {
    title: '',
    dataIndex: '_id',
    width: 62,
    render: (_id: string) => <RowAction seasonId={_id} />,
  },
]
