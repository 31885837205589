import { useState } from 'react'

const useHandleImageSize = () => {
  const [cached, setCached] = useState<Record<string, number>>({})

  const onHandleImageSize = async (url: string) => {
    if (cached[url]) {
      return cached[url]
    }

    try {
      const res = await fetch(url)
      const data = await res.blob()
      setCached({
        ...cached,
        [url]: data.size,
      })
      return data.size
    } catch (error) {}
  }

  return { onHandleImageSize }
}

export default useHandleImageSize
