import { useMutation } from 'react-query'

import { nftService } from 'services/nft'

import { CreateNftDto } from 'types/nft.type'

export const useCreateNft = () =>
  useMutation({
    mutationKey: ['CREATE_NFT'],
    mutationFn: (dto: CreateNftDto) => nftService.createNft(dto),
  })
