import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'
import FormUpdate from './formUpdate'
import FormCreate from './formCreate'

const Acceler8SeasonForm = () => {
  const seasonId = useParams().seasonId
  const title = !seasonId ? 'Create Season' : 'Edit Season'

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        {seasonId ? (
          <FormUpdate seasonId={seasonId ? Number(seasonId) : undefined} />
        ) : (
          <FormCreate />
        )}
      </Col>
    </Row>
  )
}

export default Acceler8SeasonForm
