import {
  Input,
  InputNumber,
  type InputProps,
  type InputNumberProps,
  Row,
  Col,
  Switch,
  type SwitchProps,
  Radio,
  type RadioGroupProps,
} from 'antd'

type BaseInputLabelProps = {
  label: string
  labelWidth?: number
}

type InputLabelProps = BaseInputLabelProps & Omit<InputProps, 'prefix'>

type InputLabelNumberProps = BaseInputLabelProps &
  Omit<InputNumberProps, 'prefix'>
type InputLabelCheckboxProps = BaseInputLabelProps & SwitchProps
type InputLabelRadioGroupProps = BaseInputLabelProps & RadioGroupProps

function InputLabel({
  label,
  labelWidth = 170,
  type,
  ...props
}: InputLabelProps) {
  return (
    <Input
      prefix={<label style={{ width: labelWidth }}>{label}</label>}
      {...props}
    />
  )
}

function InputLabelNumber({
  label,
  labelWidth = 170,
  style,
  ...props
}: InputLabelNumberProps) {
  return (
    <InputNumber
      prefix={<label style={{ width: labelWidth }}>{label}</label>}
      style={{ width: '100%', ...style }}
      {...props}
    />
  )
}

function InputLabelSwitch({
  label,
  labelWidth = 170,
  ...props
}: InputLabelCheckboxProps) {
  return (
    <Row align="middle" style={{ padding: '8px 11px' }}>
      <Col style={{ width: labelWidth + 5 }}>
        <label>{label}</label>
      </Col>
      <Col>
        <Switch {...props} />
      </Col>
    </Row>
  )
}

function InputLabelRadioGroup({
  label,
  labelWidth = 170,
  ...props
}: InputLabelRadioGroupProps) {
  return (
    <Row align="middle" style={{ padding: '8px 0 8px 11px' }} gutter={[0, 12]}>
      <Col style={{ width: labelWidth + 5 }}>
        <label>{label}</label>
      </Col>
      <Col>
        <Radio.Group {...props} />
      </Col>
    </Row>
  )
}

InputLabel.Number = InputLabelNumber
InputLabel.Switch = InputLabelSwitch
InputLabel.RadioGroup = InputLabelRadioGroup

export default InputLabel
