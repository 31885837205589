import { WalletConfig } from './common'
import { Env } from './env'

const configs: Record<Env, WalletConfig> = {
  development: {
    adminAddress: '2Mdbxjidw1oHPwkSsFqfaFcYHjLUrNdkVt98Xc1K5dac',
  },
  staging: {
    adminAddress: '2Mdbxjidw1oHPwkSsFqfaFcYHjLUrNdkVt98Xc1K5dac',
  },
  production: {
    adminAddress: '2Mdbxjidw1oHPwkSsFqfaFcYHjLUrNdkVt98Xc1K5dac',
  },
}

/**
 * Module exports
 */
export default configs
