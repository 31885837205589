import { useState } from 'react'

import { Button, Col, Modal, Row, Space } from 'antd'

import ApplicationRecord from './applicationRecord'
import ApplicationSelect from '../applicationSelect'
import ApplicationModal from './applicationModal'

import { IMultiplierApplication } from 'types/multiplier.type'

type ApplicationFrameProps = {
  applications: { name: string; id: string }[]
  appliedApplications: IMultiplierApplication[]
  maxShow?: number
}

export default function ApplicationFrame({
  applications,
  appliedApplications,
  maxShow = 4,
}: ApplicationFrameProps) {
  const [open, setOpen] = useState(false)

  return (
    <Row
      style={{
        padding: 12,
        borderRadius: 6,
        border: '1px solid rgba(255, 255, 255, 0.10)',
        boxSizing: 'border-box',
      }}
    >
      <Col span={24}>
        <ApplicationSelect applications={applications} />
      </Col>
      <Col span={24}>
        {appliedApplications.slice(0, maxShow).map((elm, index) => (
          <ApplicationRecord application={elm} key={index + elm._id} />
        ))}
        {appliedApplications.length > maxShow && (
          <Space style={{ width: '100%', justifyContent: 'end' }}>
            <Button
              type="text"
              onClick={() => {
                setOpen(true)
              }}
              style={{ color: '#0FDBD1' }}
            >
              See all
            </Button>
          </Space>
        )}
      </Col>
      <Modal
        open={open}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation()
          setOpen(false)
        }}
        style={{ maxWidth: 674 }}
      >
        <ApplicationModal applications={appliedApplications} />
      </Modal>
    </Row>
  )
}
