import { useQuery } from 'react-query'

import { CampaignService } from 'services/bountySystem/campaign.service'

import { ICampaign } from 'types/campaign.type'

const useCampaign = (campaignId?: string) => {
  return useQuery({
    queryKey: ['CAMPAIGNS', campaignId],
    queryFn: async (): Promise<ICampaign> =>
      await CampaignService.getCampaignDetails(campaignId!),
    enabled: !!campaignId,
  })
}

export default useCampaign
