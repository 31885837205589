import { useQuery } from 'react-query'

import { AdsService } from 'services/ads'

export const useAdsDetail = (adsId: string) => {
  return useQuery({
    queryKey: ['GET_ADS_DETAILS', adsId],
    queryFn: () => AdsService.findAdsById(adsId),
    enabled: !!adsId,
  })
}
