import { Col, Image, Row, Tooltip, Typography } from 'antd'

import { useBadgeData } from 'hooks/badges/useBadges'

import { ellipsisText } from 'helper'

type BadgeInfoProps = {
  badgeId: string
}

function BadgeInfo({ badgeId }: BadgeInfoProps) {
  const { badgeData } = useBadgeData(badgeId)
  const { title, thumbnail } = badgeData

  return (
    <Row gutter={[10, 10]} wrap={false} style={{ textAlign: 'left' }}>
      <Col>
        <Image
          src={thumbnail}
          alt="badge-thumb"
          style={{ borderRadius: 4, objectFit: 'cover' }}
          width={44}
          height={44}
          preview={false}
        />
      </Col>
      <Col flex={1}>
        <Tooltip title={title} mouseEnterDelay={title.length > 37 ? 0.2 : 1000}>
          <Typography.Text>{ellipsisText(title, 40)}</Typography.Text>
        </Tooltip>
      </Col>
    </Row>
  )
}

export default BadgeInfo
