import { marketplaceApi as api } from 'services/base-axios'

const CONTROLLER = '/mint-nft'
const PATH_SOLANA = '/solana'

class MintSolanaNFT {
  async getById(itemId: string): Promise<any> {
    return await api.get(CONTROLLER + `/${itemId}`).then((res) => res.data)
  }
  async create(nftInfo: CreateMintSolanaNFT): Promise<MintNFTData> {
    return await api
      .post(CONTROLLER + PATH_SOLANA, nftInfo)
      .then((res) => res.data)
  }
}

export const MintSolanaNFTService = new MintSolanaNFT()

export type MintNFTData = {
  title: string
  tokenAddress: string
  expiredAt: string
  thumbnail: string
  description: string
  supply: number
  _id: string
}

export type CreateMintSolanaNFT = {
  name: string
  description: string
  symbol: string
  thumbnail: string
}
