import { affiliateSystemApi as api } from 'services/base-axios'

import {
  CreateSeasonAffiliationDto,
  FindLeaderboardBySeasonAffiliationDto,
  FindSeasonAffiliationDto,
  FindSubscriptionActivityDto,
  FindSubscriptionOverallDto,
  SeasonAffiliationData,
  TeamAffiliationData,
} from 'types/affiliation.type'
import { FindAffiliationTeamDto } from 'types/affiliation-team.type'

export const SEASON_AFFILIATION_DUMMY_DATA: SeasonAffiliationData[] = [
  {
    _id: 'top1',
    name: 'Tom & Jerry',
    totalRewards: 100000,
    totalPoints: 25172,
    startDate: new Date('10/1/2023'),
    endDate: new Date('10/12/2023'),
    banner:
      'https://press-start.com.au/wp-content/uploads/2022/09/God-of-War-Ragnarok-Trailer.jpg',
    prizeStructure: [
      { title: '1st', prize: '$5000' },
      { title: '2nd', prize: '$4000' },
      { title: '3rd', prize: '$2000' },
    ],
  },
  {
    _id: 'top2',
    name: 'Mickey & Friends',
    totalRewards: 110000,
    totalPoints: 30550,
    startDate: new Date('10/5/2023'),
    endDate: new Date('10/20/2023'),
    banner: 'https://example.com/images/mickey-banner.jpg',
    prizeStructure: [
      { title: '1st', prize: '$5000' },
      { title: '2nd', prize: '$4000' },
      { title: '3rd', prize: '$3000' },
    ],
  },
  {
    _id: 'top3',
    name: 'SpongeBob & Patrick',
    totalRewards: 105000,
    totalPoints: 28760,
    startDate: new Date('10/10/2023'),
    endDate: new Date('10/22/2023'),
    banner: 'https://example.com/images/spongebob-banner.jpg',
    prizeStructure: [
      { title: '1st', prize: '$4500' },
      { title: '2nd', prize: '$3500' },
      { title: '3rd', prize: '$2500' },
    ],
  },
  {
    _id: 'top4',
    name: 'Batman & Robin',
    totalRewards: 120000,
    totalPoints: 32050,
    startDate: new Date('10/12/2023'),
    endDate: new Date('10/25/2023'),
    banner: 'https://example.com/images/batman-banner.jpg',
    prizeStructure: [
      { title: '1st', prize: '$5200' },
      { title: '2nd', prize: '$4200' },
      { title: '3rd', prize: '$2200' },
    ],
  },
  {
    _id: 'top5',
    name: 'Homer & Marge',
    totalRewards: 115000,
    totalPoints: 31000,
    startDate: new Date('10/15/2023'),
    endDate: new Date('10/28/2023'),
    banner: 'https://example.com/images/simpsons-banner.jpg',
    prizeStructure: [
      { title: '1st', prize: '$5100' },
      { title: '2nd', prize: '$4100' },
      { title: '3rd', prize: '$2300' },
    ],
  },
  {
    _id: 'top6',
    name: 'Mario & Luigi',
    totalRewards: 125000,
    totalPoints: 33500,
    startDate: new Date('10/18/2023'),
    endDate: new Date('10/30/2023'),
    banner: 'https://example.com/images/mario-banner.jpg',
    prizeStructure: [
      { title: '1st', prize: '$5400' },
      { title: '2nd', prize: '$4400' },
      { title: '3rd', prize: '$2400' },
    ],
  },
]

export const TEAM_AFFILIATION_DUMMY_DATA: TeamAffiliationData[] = [
  {
    _id: 'team_1',
    teamName: 'Shinichi Kudo',
    logo: 'https://cdn2.vectorstock.com/i/1000x1000/07/51/ragnarok-esport-mascot-logo-design-vector-30790751.jpg',
    point: 39820,
    lastRank: 3910,
    currentRank: 302,
  },
  {
    _id: 'team_2',
    teamName: 'Hercule Poirot',
    logo: 'https://cdn2.vectorstock.com/i/1000x1000/08/52/detective-esport-mascot-logo-design-vector-30880852.jpg',
    point: 40210,
    lastRank: 4012,
    currentRank: 290,
  },

  {
    _id: 'team_3',
    teamName: 'Sherlock Holmes',
    logo: 'https://cdn2.vectorstock.com/i/1000x1000/09/53/sherlock-esport-mascot-logo-design-vector-30990953.jpg',
    point: 38750,
    lastRank: 3825,
    currentRank: 310,
  },

  {
    _id: 'team_4',
    teamName: 'Sam Spade',
    logo: 'https://cdn2.vectorstock.com/i/1000x1000/10/54/spade-esport-mascot-logo-design-vector-31010154.jpg',
    point: 39000,
    lastRank: 3750,
    currentRank: 295,
  },

  {
    _id: 'team_5',
    teamName: 'Miss Marple',
    logo: 'https://cdn2.vectorstock.com/i/1000x1000/11/55/marple-esport-mascot-logo-design-vector-31111155.jpg',
    point: 38020,
    lastRank: 3650,
    currentRank: 315,
  },

  {
    _id: 'team_6',
    teamName: 'Philip Marlowe',
    logo: 'https://cdn2.vectorstock.com/i/1000x1000/12/56/marlowe-esport-mascot-logo-design-vector-31221256.jpg',
    point: 39600,
    lastRank: 3880,
    currentRank: 305,
  },
]

class Affiliation {
  // TODO: update when BE is ready
  private index = '/admin'

  async findSeasons(params?: FindSeasonAffiliationDto) {
    const { data } = await api.get<SeasonAffiliationData[]>(
      `${this.index}/season`,
      { params },
    )
    return {
      data,
      total: data.length,
    }
  }

  async findSubscriptionOverall(params?: FindSubscriptionOverallDto) {
    const { data } = await api.get(`${this.index}/subscription/overall`, {
      params,
    })

    return data
  }

  async findSubscriptionActivity(params?: FindSubscriptionActivityDto) {
    const { data } = await api.get(`${this.index}/subscription/activity`, {
      params,
    })

    return data
  }

  async findTeamData(teamId: number) {
    // TODO: implement later after affiliate source is ready
    const { data } = await api.get(`${this.index}/team/${teamId}`)
    return data
  }

  async findTeams(params: FindAffiliationTeamDto) {
    const { data } = await api.get(`${this.index}/team`, {
      params,
    })
    return data
  }

  async exportTeamAnalytics(teamId: number) {
    return api.get(`${this.index}/team/export`, {
      params: {
        teamId,
      },
      responseType: 'blob',
    })
  }

  async findSeason(seasonId?: string) {
    // TODO: implement later
    // const { data } = await api.get<SeasonAffiliationData>(
    //   `${this.index}/season`,
    //   { params: { seasonId } },
    // )
    // return data

    // TODO: remove later
    console.log(seasonId)

    return SEASON_AFFILIATION_DUMMY_DATA[0]
  }

  async findLeaderboardBySeason(
    dto?: FindLeaderboardBySeasonAffiliationDto, // seasonId?: string
  ) {
    // TODO: remove later
    console.log(dto)
    return TEAM_AFFILIATION_DUMMY_DATA
  }

  async createSeason(data: CreateSeasonAffiliationDto) {
    return await api.post(`${this.index}/season`, { data })
  }
}

export const AffiliationService = new Affiliation()
