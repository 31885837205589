import { useContext, useMemo, useState } from 'react'

import { Col, Row, Typography } from 'antd'

import SearchBar from 'components/systems/searchBar'
import ApplicationRecord from './applicationRecord'

import { ApplicationFrameContext } from './ApplicationFrameContext'

import { ApplicationType, IMultiplierApplication } from 'types/multiplier.type'

type ApplicationModalProps = {
  applications: IMultiplierApplication[]
}

export default function ApplicationModal({
  applications,
}: ApplicationModalProps) {
  const [search, setSearch] = useState('')
  const { applicationType } = useContext(ApplicationFrameContext)

  const searchedApplications = useMemo(
    () =>
      // TODO: Implement search with real data
      applications.filter((elm) => true),
    [applications],
  )

  const title = useMemo(() => {
    switch (applicationType) {
      case ApplicationType.Bounty:
        return 'Mission List Applied'
      default:
        return 'Mission'
    }
  }, [applicationType])

  return (
    <Row gutter={[24, 24]} justify={'center'}>
      <Col>
        <Typography.Title level={4} type="success">
          {title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <SearchBar setSearch={setSearch} value={search} placeholder="Search" />
      </Col>
      <Col>
        {searchedApplications.map((elm, index) => (
          <ApplicationRecord application={elm} key={index + elm._id} />
        ))}
      </Col>
    </Row>
  )
}
