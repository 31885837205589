export enum FeaturedItemType {
  BOUNTY = 'FEATURED:BOUNTY',
  PRODUCT = 'FEATURED:PRODUCT',
  COMMUNITY = 'FEATURED:COMMUNITY',
}

export type FindFeaturedDto = {
  type?: FeaturedItemType
}

type FeaturedItemMap = {
  [FeaturedItemType.BOUNTY]: {
    type: FeaturedItemType.BOUNTY
    bountyIds?: string[]
  }
  [FeaturedItemType.PRODUCT]: {
    type: FeaturedItemType.PRODUCT
    productIds?: string[]
  }
  [FeaturedItemType.COMMUNITY]: {
    type: FeaturedItemType.COMMUNITY
    communitiesFeatured?: CommunityFeatured[]
  }
}

type FeaturedItem<T extends FeaturedItemType> = FeaturedItemMap[T]

export type CreateFeaturedDto =
  | FeaturedItem<FeaturedItemType.BOUNTY>
  | FeaturedItem<FeaturedItemType.PRODUCT>
  | FeaturedItem<FeaturedItemType.COMMUNITY>

/**
 * NOTE: This is the type from Backend
 */

// export type CreateFeaturedDto = {
//   type: FeaturedItemType
//
//   bountyIds?: string[]
//
//   productIds?: string[]
//
//   communitiesFeatured?: CommunityFeatured[]
// }

export interface ICommunityTheme {
  normalizeTitle: string

  highlightTitle: string

  coverImage: string

  thumbnail: string
}

export type CommunityFeatured = {
  communityId: string

  communityTheme: ICommunityTheme
}
