import { Env } from './env'

/**
 * Constructor
 */

type Config = {
  origin: string
}

const configs: Record<Env, Config> = {
  /**
   * Development configurations
   */
  development: {
    origin: "http://localhost:3000",
  },

  /**
   * Staging configurations
   */
  staging: {
    origin: 'https://space3.dev',
  },

  /**
   * Production configurations
   */
  production: {
    origin: 'https://space3.gg',
  },
}

/**
 * Module exports
 */
export default configs
