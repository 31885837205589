import React from 'react'

import { Col, Input, Row, Typography } from 'antd'

export type DiscordLinkProps = {
  value?: string
  onChange: (url: string) => void
}

const DiscordLink = ({ value, onChange }: DiscordLinkProps) => {
  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Typography.Text type="secondary">Discord Mission Link</Typography.Text>
      </Col>
      <Col span={24}>
        <Input
          value={value}
          placeholder="Discord Mission Link"
          onChange={(e) => onChange(e.target.value)}
        />
      </Col>
    </Row>
  )
}

export default DiscordLink
