import { useMutation } from 'react-query'

import { GachaService } from 'services/marketplace/gacha'

import type { UpsertGachaRewardDto } from 'types/gacha.type'

export const useUpsertGachaReward = () => {
  return useMutation({
    mutationKey: ['update-gacha-reward'],
    mutationFn: (payload: UpsertGachaRewardDto) =>
      GachaService.upsertGachaReward(payload),
    retry: 3,
    retryDelay: 500,
  })
}
