import {
  Row,
  Col,
  Input,
  Space,
  Typography,
  Form,
  UploadFile,
  Spin,
  Image,
} from 'antd'
import SelectChainInput from 'components/marketPlace/collection/selectChainInput'
import RequiredLabel from 'components/systems/requiredSymbol'
import UploadPicture from 'components/systems/uploadPicture'
import { notifyError } from 'helper'
import { FormInstance } from 'rc-field-form'
import { useState } from 'react'
import { CollectionInfoService } from 'services/marketplace/evm/collectionInfo'
import { LoadingOutlined } from '@ant-design/icons'
import { CUSTOM_ICONS } from 'components/systems/ionIcon/customs'
import { CollectionData } from 'services/marketplace/collection'
import { FormNoticeType } from 'constant'

const { Item } = Form

type FormCollectionProps = {
  setImage: (image: UploadFile[]) => void
  form: FormInstance
  setTotalNFT: (totalNFT: number) => void
  collectionId: string
  collectionData: CollectionData
  fileList?: UploadFile[]
}

const FormCollection = ({
  setImage,
  form,
  setTotalNFT,
  collectionId,
  collectionData,
  fileList,
}: FormCollectionProps) => {
  const [loading, setLoading] = useState(false)
  const socialPlaceholders: Record<string, string> = {
    Website: 'Enter Website URL',
    Telegram: 'Enter Telegram URL',
    Twitter: 'Enter Twitter URL',
    Discord: 'Enter Discord URL',
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Contract Address</RequiredLabel>
          </Col>
          <Col xs={24} xl={12}>
            <Item
              name="contractAddress"
              rules={[
                { required: true, message: 'Contract Address is required' },
              ]}
            >
              <Input
                onPaste={async (address) => {
                  const text = address.clipboardData.getData('text')
                  try {
                    setLoading(true)
                    const data = await CollectionInfoService.collectionInfo({
                      address: text,
                      chainId: form.getFieldValue('chain'),
                    })
                    setTotalNFT(data.amount)
                    form.setFieldValue('collectionName', data.name)
                  } catch (error) {
                    notifyError(error)
                  } finally {
                    setLoading(false)
                  }
                }}
                placeholder="Enter Contract Address"
                disabled={collectionId !== ''}
                suffix={
                  <Spin
                    spinning={loading}
                    indicator={
                      <LoadingOutlined style={{ fontSize: 16 }} spin />
                    }
                  />
                }
              />
            </Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Space direction="vertical">
          <Typography.Text type="secondary">
            {FormNoticeType.UploadMaxSize}
          </Typography.Text>
          <UploadPicture
            fileList={fileList}
            onChangeFile={({ fileList }) => setImage(fileList)}
            maxCount={1}
          />
          <Typography.Text type="secondary">
            Upload or drag and drop a PNG, GIF, or JPG to display in the app.
          </Typography.Text>
        </Space>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Collection Name (5-64 characters)</RequiredLabel>
          </Col>
          <Col xs={24} xl={12}>
            <Item
              name="collectionName"
              rules={[
                { required: true, message: 'Collection Name is required' },
              ]}
            >
              <Input placeholder="Enter Collection Name" />
            </Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Desciption (5-800 characters)</RequiredLabel>
          </Col>
          <Col xs={24} xl={12}>
            <Item
              name="description"
              rules={[{ required: true, message: 'Description is required' }]}
            >
              <Input.TextArea />
            </Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Chain</RequiredLabel>
          </Col>
          <Col xs={24} xl={12}>
            <Item name="chain">
              <SelectChainInput
                defaultValue={collectionData.chainId}
                disabled={collectionId !== ''}
                onSelect={async (chain) => {
                  form.setFieldValue('chain', chain)
                  try {
                    setLoading(true)
                    const data = await CollectionInfoService.collectionInfo({
                      address: form.getFieldValue('contractAddress'),
                      chainId: form.getFieldValue('chain'),
                    })
                    setTotalNFT(Number(data.amount))
                    form.setFieldValue('collectionName', data.name)
                  } catch (error) {
                    notifyError(error)
                  } finally {
                    setLoading(false)
                  }
                }}
              />
            </Item>
          </Col>
        </Row>
      </Col>
      {Object.keys(socialPlaceholders).map((social) => (
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Row gutter={[8, 0]} style={{ alignItems: 'center' }}>
                <Col>
                  <Image
                    preview={false}
                    width={24}
                    src={CUSTOM_ICONS[social.toLocaleLowerCase()]}
                  />
                </Col>
                <Col>
                  <Typography.Text type="secondary">{social}</Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Item name={social.toLocaleLowerCase()}>
                <Input placeholder={socialPlaceholders[social]} />
              </Item>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

export default FormCollection
