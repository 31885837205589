import { useState } from 'react'

import { Col, Row, Table, Typography } from 'antd'

import ControlBar from '../controlBar'
import { COLUMN_TEAM } from './table/column'
import CustomPagination from 'components/systems/pagination'

import { useAffiliationTeams } from 'hooks/affiliation/useTeams'

import { TeamSortOptions } from 'types/affiliation-team.type'

import { DEFAULT_PAGE } from 'constant'

const DEFAULT_FILTER_TEAM_DATA = {
  sortOption: TeamSortOptions.CurrentSeasonRank,
  searchValue: '',
}

export type SortFilterProps = {
  sortOption?: TeamSortOptions
  searchValue: string
}

const ListTeam = () => {
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [sortFilter, setSortFilter] = useState<SortFilterProps>(
    DEFAULT_FILTER_TEAM_DATA,
  )

  const { data, total } = useAffiliationTeams({
    search: sortFilter.searchValue,
    sort: sortFilter.sortOption,
  })

  const handleSortFilter = (key: string, value: string | string[]) => {
    setSortFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <ControlBar
          sortFilter={sortFilter}
          handleSortFilter={handleSortFilter}
        />
      </Col>
      <Col span={24}>
        <Table
          rowKey="id"
          className="table-border-collapse"
          columns={COLUMN_TEAM}
          dataSource={data}
          pagination={false}
        />
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!data?.length && (
            <Col>
              <CustomPagination total={total} onChange={setPage} page={page} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default ListTeam
