import { ReactNode } from 'react'

import { Col, Row } from 'antd'

import SearchBar from 'components/systems/searchBar'

type DataPreviewProps = {
  header: ReactNode
  dataList: ReactNode
  onSearch: (value: string) => void
}

function DataPreview({ header, dataList, onSearch }: DataPreviewProps) {
  return (
    <Row
      gutter={[0, 20]}
      style={{
        padding: '32px 20px',
        background: '#14181C',
        borderRadius: 16,
        maxHeight: 854,
      }}
    >
      <Col>{header}</Col>
      <Col span={24}>
        <SearchBar
          setSearch={onSearch}
          placeholder="Search address"
          style={{ width: '100%', borderRadius: 8 }}
        />
      </Col>
      <Col style={{ minHeight: 390 }} span={24}>
        {dataList}
      </Col>
    </Row>
  )
}

export default DataPreview
