import { useState } from 'react'

import { Button, Col, Row, Tabs, Typography } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'

import MultipliersTable from './table/MultipliersTable'
import SearchBar from 'components/systems/searchBar'
import CustomPagination from 'components/systems/pagination'
import CreateAndUpdateMultiplier from 'components/multiplier/createAndUpdate'
import { MultiplierModalContext } from 'components/multiplier/createAndUpdate/MultiplierModalContext'

import { useMultipliers } from 'hooks/multiplier/useMultipliers'

import { DEFAULT_PAGE, MULTIPLIER_PAGE_SIZE } from 'constant'

import { MultiplierStatus } from 'types/multiplier.type'

const tabItems = [
  {
    key: MultiplierStatus.OnGoing,
    label: 'ON GOING',
  },
  {
    key: MultiplierStatus.Unpublished,
    label: 'UNPUBLISHED',
  },
]

function MultiplierManagement() {
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [multiplierId, setMultiplierId] = useState<string | undefined>('')

  const [search, setSearch] = useState<string>('')
  const [state, setState] = useState<MultiplierStatus>(MultiplierStatus.OnGoing)

  const {
    data: multipliers,
    total,
    isLoading,
  } = useMultipliers({
    search,
    isActive: state === MultiplierStatus.OnGoing,
    limit: MULTIPLIER_PAGE_SIZE,
    offset: (page - 1) * MULTIPLIER_PAGE_SIZE,
  })

  return (
    <MultiplierModalContext.Provider
      value={{ isModalOpen, setIsModalOpen, multiplierId, setMultiplierId }}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Typography.Title level={3}>MULTIPLIER LIST</Typography.Title>
        </Col>

        <Col span={24}>
          <Row wrap={false} gutter={10}>
            <Col flex={1}>
              <SearchBar
                setSearch={setSearch}
                placeholder="Search multiplier name"
                style={{ width: '100%' }}
              />
            </Col>
            <Col style={{ width: 180 }}>
              <Button
                icon={<PlusCircleFilled />}
                block
                onClick={() => setIsModalOpen(true)}
                style={{ width: 206 }}
                className="create-button"
              >
                Create Multiplier
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Tabs
            activeKey={state}
            onChange={(key) => {
              setState(key as MultiplierStatus)
              setPage(DEFAULT_PAGE)
            }}
            type="card"
            items={tabItems}
          />
        </Col>

        <Col span={24}>
          <MultipliersTable data={multipliers} isLoading={isLoading} />
        </Col>

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Typography.Text type="success">Total: {total}</Typography.Text>
            </Col>

            <Col>
              <CustomPagination
                total={total}
                page={page}
                onChange={setPage}
                pageSize={MULTIPLIER_PAGE_SIZE}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CreateAndUpdateMultiplier />
    </MultiplierModalContext.Provider>
  )
}

export default MultiplierManagement
