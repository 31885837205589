import { ancient8Api as api } from 'services/base-axios'

import { AuthType, ChainID } from 'constant'

import { UserProfile } from 'store/user.reducer'
import { IA8IdProfile } from 'types/user.type'

const CONTROLLER = '/user'
const PATHS = {
  profile: '/profile',
  authEntities: '/profile/auth-entities',
}

class User {
  async fetchUserProfile(): Promise<UserProfile> {
    const { data } = await api.get(CONTROLLER + PATHS.profile)
    return data
  }

  async fetchAuthEntities(): Promise<any[]> {
    const { data } = await api.get(CONTROLLER + PATHS.authEntities)
    return data
  }

  async getWalletAddress(chain: ChainID) {
    const data = await this.fetchAuthEntities()
    const authType = AuthType[chain]
    if (!data) return ''
    const entity = data.find((e) => e.type === authType)
    return entity?.credential?.walletAddress
  }

  async getUserProfileByUid(uid: string): Promise<IA8IdProfile> {
    const {
      data: [userProfile],
    } = await api.post(`${CONTROLLER}/openapi-profile`, {
      uids: [uid],
    })
    return userProfile
  }
}

export const UserService = new User()
