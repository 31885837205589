import moment from 'moment'

import { Typography } from 'antd'

import { TIME_FORMAT } from 'constant'
import { shortenAddress } from 'helper'

export const CCP_LEADERBOARD_COLUMN = [
  {
    title: 'UID',
    dataIndex: 'uid',
    render: (uid: string) => (
      <Typography.Text>{shortenAddress(uid)}</Typography.Text>
    ),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    render: (balance: number) => (
      <Typography.Text ellipsis={true}>{balance}</Typography.Text>
    ),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    render: (total: number) => <Typography.Text>{total}</Typography.Text>,
  },
  {
    title: 'Last Used',
    dataIndex: 'updatedAt',
    render: (updatedAt: string) => (
      <Typography.Text>
        {moment.utc(updatedAt).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: 'Expired Time',
    dataIndex: 'expiredYmd',
    render: (expiredTime: number) => (
      <Typography.Text>
        {moment.utc(expiredTime, 'YYYYMMDD').format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
]
