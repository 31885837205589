import { Form, Radio } from 'antd'

import InputLabel from 'components/systems/input/InputLabel'

import { type RewardItemProps } from '..'
import { GachaRewardTier } from 'types/gacha.type'

import './index.less'

const tierOptions = Object.keys(GachaRewardTier).map((key) => ({
  label: key,
  value: GachaRewardTier[key as keyof typeof GachaRewardTier],
}))

function TierItem({ label, value }: { label: string; value: string }) {
  return (
    <Radio
      value={value}
      className={`reward-tier--item reward-tier--item--${label.toLowerCase()}`}
    >
      {label}
    </Radio>
  )
}

function TierFormItem({ prefixNamePath }: RewardItemProps) {
  return (
    <Form.Item
      name={[prefixNamePath, 'tier']}
      rules={[
        {
          required: true,
          message: 'Tier is required',
        },
      ]}
      initialValue={GachaRewardTier.Common}
    >
      <InputLabel.RadioGroup
        className="reward-tier"
        label="Reward Tier"
        size="large"
      >
        {tierOptions.map(({ label, value }) => (
          <TierItem key={value} label={label} value={value} />
        ))}
      </InputLabel.RadioGroup>
    </Form.Item>
  )
}

export default TierFormItem
