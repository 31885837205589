import { useState } from 'react'
import { useMutation } from 'react-query'
import Papa from 'papaparse'
import { useParams } from 'react-router-dom'

import {
  Button,
  Col,
  Image,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd'

import IonIcon from 'components/systems/ionIcon'

import { useNftsMetadata } from 'hooks/nftMetadata/useNftsMedatada'
import { nftService } from 'services/nft'

import { notifyError, notifySuccess } from 'helper'

import { FILE_SIZE_ONE_MB } from 'constant/marketplace'

import iconUpload from 'static/images/bounty/upload.svg'
import csvLogo from 'static/images/bounty/csvLogo.svg'

import { UploadMetadataDto } from 'types/nft-metadata.type'

type UploadCSVProps = {
  setOpen: (val: boolean) => void
}

const UploadCSV = ({ setOpen }: UploadCSVProps) => {
  const nftId = useParams().nftId
  const [file, setFile] = useState<File>()
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState<UploadMetadataDto[]>([])

  const { refetchRoot } = useNftsMetadata({}, false)

  const parse = (file: any): Promise<UploadMetadataDto[]> => {
    return new Promise((resolve, reject) => {
      return Papa.parse(file, {
        delimiter: ',',
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => {
          const map: Record<string, string> = {
            'Token ID': 'tokenId',
            URI: 'uri',
          }
          return map[header]
        },
        complete: ({ data }, fileInfo) => {
          if (fileInfo.size > FILE_SIZE_ONE_MB * 10)
            return reject('File to large')
          setFile(fileInfo)
          return resolve(data as UploadMetadataDto[])
        },
      })
    })
  }

  const { mutate: onUpload, isLoading: uploading } = useMutation(
    async () => {
      if (!nftId) {
        throw new Error('Missing NFT')
      }

      const chunkSize = 300
      let leftPointer = 0
      try {
        while (leftPointer < formData.length) {
          const data = formData.slice(leftPointer, leftPointer + chunkSize)
          await nftService.bulkCreateMetadata({
            nftId,
            data,
          })
          leftPointer += chunkSize
        }
      } catch (error) {
        const totalUploaded = Math.min(leftPointer, formData.length)
        throw new Error(
          `Upload Failed: Only the first ${totalUploaded} tokens were successfully uploaded`,
        )
      } finally {
        setOpen(false)
      }
    },
    {
      onSuccess: () => {
        notifySuccess('Upload file')
        refetchRoot()
      },
      onError: (error: any) => {
        notifyError(error)
      },
    },
  )

  const onFileChange = async (file: any) => {
    if (!file) return
    const data = await parse(file)
    setFormData(data)
  }

  const onRemove = () => {
    setFile(undefined)
    setError(false)
  }

  return (
    <Modal
      open={true}
      onCancel={() => setOpen(false)}
      closeIcon={<IonIcon name="close-outline" />}
      footer={null}
    >
      <Row gutter={[0, 38]} justify="center">
        <Col span={24}>
          <Space
            align="center"
            size={4}
            direction="vertical"
            style={{ width: '100%' }}
          >
            <Typography.Title type="success" level={4}>
              Upload NFT Metadata CSV
            </Typography.Title>
            <Typography.Text type="secondary">
              You can upload CSV file with maximum size is 10MB
            </Typography.Text>
          </Space>
        </Col>

        <Col span={24}>
          <Space style={{ width: '100%' }} direction="vertical">
            <Spin spinning={uploading}>
              <Upload.Dragger
                accept=".csv"
                maxCount={1}
                className={error ? 'upload-file error' : 'upload-file'}
                beforeUpload={() => false}
                onChange={(file) => onFileChange(file.file)}
                showUploadList={false}
                progress={{ strokeWidth: 2, showInfo: true }}
              >
                {!file ? (
                  <Space direction="vertical" align="center">
                    <Image src={iconUpload} preview={false} />
                    <Typography.Text type="secondary">
                      Upload file
                    </Typography.Text>
                  </Space>
                ) : (
                  <Space direction="vertical" size={12} align="center">
                    <Image src={csvLogo} preview={false} />
                    <Typography.Text>{file.name}</Typography.Text>
                  </Space>
                )}
              </Upload.Dragger>
            </Spin>
            {error && (
              <Space align="center">
                <IonIcon
                  style={{ color: '#ff4d4f' }}
                  name="alert-circle-outline"
                />
                <Typography.Text type="danger">
                  Upload file size exceeds 10MB limit
                </Typography.Text>
              </Space>
            )}
          </Space>
        </Col>
        <Col>
          <Space size={20}>
            <Button
              onClick={onRemove}
              style={{ width: 130 }}
              ghost
              disabled={uploading}
            >
              Reset
            </Button>
            <Button
              onClick={() => onUpload()}
              style={{ width: 130 }}
              type="primary"
              loading={uploading}
            >
              Upload
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  )
}

export default UploadCSV
