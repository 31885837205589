import { CSSProperties, ReactNode } from 'react'

import { Space, Typography } from 'antd'

type RequiredLabelProps = {
  children?: ReactNode
  required?: boolean
  style?: CSSProperties
}

const RequiredLabel = ({
  children,
  required = true,
  style = {},
}: RequiredLabelProps) => {
  return (
    <Space size={4} style={{ ...style, display: 'flex' }}>
      <Typography.Text type="secondary">{children}</Typography.Text>
      {required && <span style={{ color: '#DB4646' }}>*</span>}
    </Space>
  )
}

export default RequiredLabel
