import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Col, InputNumber, Row, Select, Typography } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import { useCreateProductData } from 'view/itemManagement'
import usePaymentCurrencies from 'hooks/systems/usePaymentCurrencies'
import { useChains } from 'hooks/chain/useChain'

import { PriceToken } from 'types/marketplace.type'
import { DUMMY_PRICE_TOKEN } from 'constant/marketplace'

const OriginalPricesInfo = () => {
  const chainConfig = useChains()
  const { selectedChain } = useContext(SelectChainContext)
  const [chainCurrency, setChainCurrency] = useState([] as Array<string>)
  const [createProductData, setCreateProductData] = useCreateProductData()
  const getPaymentCurrencies = usePaymentCurrencies()

  const nativeToken = useMemo(() => {
    return chainConfig[selectedChain].nativeToken
  }, [chainConfig, selectedChain])

  const paymentCurrencies = useMemo(
    () => getPaymentCurrencies(selectedChain),
    [getPaymentCurrencies, selectedChain],
  )

  const tokenName = useMemo(() => {
    const prevTokenName = createProductData?.originalPriceToken?.tokenName

    if (prevTokenName && paymentCurrencies.includes(prevTokenName)) {
      return prevTokenName
    }

    return paymentCurrencies?.[0] ?? nativeToken.symbol
  }, [
    createProductData?.originalPriceToken?.tokenName,
    nativeToken.symbol,
    paymentCurrencies,
  ])

  const renderCurrenciesByChain = useCallback(() => {
    setChainCurrency(paymentCurrencies)
    setCreateProductData((prev) => ({
      ...prev,
      originalPriceToken: {
        ...(paymentCurrencies.length > 0
          ? prev?.originalPriceToken
          : DUMMY_PRICE_TOKEN),
        tokenName,
      } as PriceToken,
    }))
  }, [paymentCurrencies, setCreateProductData, tokenName])

  useEffect(() => {
    renderCurrenciesByChain()
  }, [renderCurrenciesByChain])

  useEffect(() => {
    setCreateProductData((prev) => ({
      ...prev,
      originalPriceToken: {
        ...prev?.originalPriceToken,
        chainId: selectedChain,
        tokenName,
      } as PriceToken,
    }))
  }, [tokenName, selectedChain, setCreateProductData])

  return (
    <Row>
      <Col span={12}>
        <SpaceVertical style={{ width: '100%' }} size={8}>
          <Typography.Text type="secondary" aria-required>
            Original Prices
          </Typography.Text>
          {chainCurrency.length > 0 && (
            <InputNumber
              min={0}
              addonAfter={
                <Select
                  value={createProductData?.originalPriceToken?.tokenName}
                  className="select-after"
                  onChange={(tokenName) => {
                    setCreateProductData((prev) => ({
                      ...prev,
                      originalPriceToken: {
                        ...prev?.originalPriceToken,
                        chainId: selectedChain,
                        tokenName,
                      } as PriceToken,
                    }))
                  }}
                >
                  {chainCurrency.map((item, idx) => (
                    <Select.Option key={idx} value={item}>
                      {item.toUpperCase()}
                    </Select.Option>
                  ))}
                </Select>
              }
              defaultValue={0}
              style={{ width: '100%' }}
              value={createProductData?.originalPriceToken?.price || 0}
              onChange={(value: number | null) =>
                setCreateProductData((prev) => ({
                  ...prev,
                  originalPriceToken: {
                    ...prev?.originalPriceToken,
                    price: value ?? 0,
                  } as PriceToken,
                }))
              }
            />
          )}
          <InputNumber
            min={0}
            defaultValue={0}
            addonAfter="CCP"
            style={{ width: '100%' }}
            value={createProductData?.originalPriceCCP || 0}
            onChange={(value: number | null) =>
              setCreateProductData((prev) => ({
                ...prev,
                originalPriceCCP: value ?? 0,
              }))
            }
          />
        </SpaceVertical>
      </Col>
    </Row>
  )
}

export default OriginalPricesInfo
