import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { useGetBountyData } from 'hooks/bounty/useBountyData'
import { useDeleteMultiApp } from 'hooks/multiplier/useDeleteMultiApp'
import { useMultiApps } from 'hooks/multiplier/useMultiApps'

import { notifyError, notifySuccess } from 'helper'

import { ApplicationType, IMultiplierApplication } from 'types/multiplier.type'

type ApplicationRecordProps = {
  application: IMultiplierApplication
  typoStyle?: CSSProperties
}
export default function ApplicationRecord({
  application: { _id, applicationType, applicationId },
  typoStyle = {},
}: ApplicationRecordProps) {
  const multiplierId = useParams().multiplierId || ''
  const [applicationName, setApplicationName] = useState('')
  const { getBountyData } = useGetBountyData()
  const { mutateAsync: deleteApplication, isLoading } = useDeleteMultiApp()
  const { refetch } = useMultiApps({
    multiplierId,
    applicationType,
  })

  const onDelete = useCallback(async () => {
    try {
      await deleteApplication(_id)

      await refetch()
      notifySuccess('Deleted multiplier application')
    } catch (error) {
      notifyError(error)
    }
  }, [_id, deleteApplication, refetch])

  useEffect(() => {
    ;(async () => {
      switch (applicationType) {
        case ApplicationType.Bounty:
          const data = await getBountyData(applicationId)
          setApplicationName(data.title)
          break
        default:
          break
      }
    })()
  }, [applicationId, applicationType, getBountyData])

  return (
    <Row
      gutter={[8, 8]}
      style={{ padding: '8px 0px', overflow: 'hidden' }}
      wrap={false}
    >
      <Col>
        {!isLoading ? (
          <IonIcon name="trash-outline" onClick={onDelete} />
        ) : (
          <IonIcon name="loading" />
        )}
      </Col>
      <Col flex={1}>
        <Typography.Text
          style={{ maxWidth: 300, ...typoStyle }}
          ellipsis={{ tooltip: `${applicationId} - ${applicationName}` }}
        >
          {applicationId} - {applicationName}
        </Typography.Text>
      </Col>
    </Row>
  )
}
