import { bountySystemApi as api } from 'services/base-axios'

import {
  CreateCheckInRewardDto,
  ICheckInReward,
  IDailyReward,
  IStreakReward,
} from 'types/check-in.type'

const CONTROLLER = '/check-in'

class DailyCheckIn {
  async checkIn() {
    return api.post(CONTROLLER).then((data) => data.data)
  }

  async getCheckIn() {
    return api.get(CONTROLLER)
  }

  async createCheckInReward(dto: CreateCheckInRewardDto) {
    return api.post<ICheckInReward>('/check-in/reward', dto)
  }

  async getCheckInRewards() {
    const { data } = await api.get<{
      dailyRewards: IDailyReward[][]
      streakRewards: IStreakReward[]
    }>('/check-in/reward')

    return data
  }
}

export const DailyCheckInService = new DailyCheckIn()
