import { Key, useState } from 'react'

import { Col, Row, Table } from 'antd'

import { COLUMN_DIGITAL_ITEM, COLUMN_PHYSICAL_ITEM } from './column'

import { Data } from 'types'
import { IProductData, ProductType } from 'types/product.type'
import { useProductTypeSelected } from '../../index'

import './index.less'

type TableMarketItemProps = {
  data: Data<IProductData>[]
  onRowClick?: (itemId: string) => void
}
const TableMarketItem = ({
  data,
  onRowClick = () => {},
}: TableMarketItemProps) => {
  const [productSelected, setProductSelected] = useState<Key[]>([])
  const [productTypeSelected] = useProductTypeSelected()

  const tableColumn =
    productTypeSelected === ProductType.REAL_NFT
      ? COLUMN_PHYSICAL_ITEM
      : COLUMN_DIGITAL_ITEM

  return (
    <Row>
      <Col span={24}>
        <Table
          rowKey="_id"
          rowSelection={{
            selectedRowKeys: productSelected,
            onChange: setProductSelected,
          }}
          className="table-border-collapse market-table"
          columns={tableColumn}
          dataSource={Object.values(data)}
          onRow={(e) => {
            return {
              onClick: () => onRowClick(e._id),
            }
          }}
        />
      </Col>
    </Row>
  )
}

export default TableMarketItem
