import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

export const usePlayerSeason = (seasonId?: number) => {
  return useQuery({
    queryKey: ['GET_PLAYER_SEASON', seasonId],
    queryFn: () => PlayerLeaderboardService.findSeason(seasonId),
    enabled: !!seasonId,
  })
}
