import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch } from 'store'
import { getTasksByBounty } from 'store/tasks.reducer'
import { useTasks } from './useTasks'

export const useTaskByBountyId = (bountyId: string) => {
  const tasks = useTasks()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getTasksByBounty(bountyId))
  }, [bountyId, dispatch])

  const filteredTasks = useMemo(
    () => Object.keys(tasks).filter((id) => tasks[id].bountyId === bountyId),
    [bountyId, tasks],
  )

  return filteredTasks
}
