import ListLeaderboardTask from 'components/leaderboard-task/list-task'

import { useAcceler8TaskByGameId } from 'hooks/acceler8/useAcceler8Tasks'
import { usePlayerTotalParticipantsByProgram } from 'hooks/player-leaderboard/usePlayerTotalParticipantsByProgram'
import { usePlayerTotalPointsByProgram } from 'hooks/player-leaderboard/usePlayerTotalPointsByProgram'

import configs from 'configs'

import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'

type ListLeaderboardTaskProps = {
  seasonId?: number
  gameId?: string
} & LeaderboardTaskProps

function PlayerLeaderboardTasksList({
  type,
  gameId,
  seasonId = 0,
}: ListLeaderboardTaskProps) {
  const { data } = useAcceler8TaskByGameId({
    communityId: gameId,
    type,
    seasonId,
  })

  const { data: participants } = usePlayerTotalParticipantsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId,
  })

  const { data: totalPoints } = usePlayerTotalPointsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId,
  })

  return (
    <ListLeaderboardTask
      isSpace3={gameId === configs.community.SP3CommunityId}
      tasks={data?.tasks}
      participants={participants}
      totalPoints={totalPoints}
    />
  )
}

export default PlayerLeaderboardTasksList
