import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'

import brandAdmin from 'static/images/logo/brand-admin.svg'
import { ROUTES } from 'constant/routes'

export type BrandProps = { style?: CSSProperties }
const Brand = ({ style }: BrandProps) => {
  const navigate = useNavigate()

  return (
    <img
      onClick={() => navigate(ROUTES.BOUNTY.INDEX)}
      style={{ cursor: 'pointer', ...style }}
      src={brandAdmin}
      alt="brand"
    />
  )
}

export default Brand
