import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Col, Row, Space } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import FormSetUp, { SetUpField } from '../share/formSetup'

import { BountyService } from 'services/bountySystem/bounty'
import { TaskService } from 'services/bountySystem/task'

import { useStorage } from 'hooks/systems/useStorage'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import { useBountySetup, useCreateBountyTasks, useFileList } from '../../index'
import { useSEO } from 'hooks/seo/useSEO'

import { notifyError, notifySuccess } from 'helper'

import { BountyCategory } from 'constant/bounty'
import { ROUTES } from 'constant/routes'

import { SeoType } from 'types/seo.type'

const DiscordBounty = () => {
  const [loading, setLoading] = useState(false)
  const [currentCommunity] = useCurrentCommunity()
  const [bountyData] = useBountySetup()
  const [tasks] = useCreateBountyTasks()
  const navigate = useNavigate()
  const [fileList] = useFileList()
  const { onUpload } = useStorage(604800)
  const bountyId = useParams().bountyId || ''
  const { createSeoInfo, updateSeoInfo } = useSEO(SeoType.Bounty)

  const onCreate = async () => {
    try {
      setLoading(true)
      if (!fileList.length) throw new Error('Invalid thumbnail')
      const thumbnail = await onUpload(fileList[0])
      const bounty = await BountyService.create({
        ...bountyData,
        communityId: currentCommunity._id,
        category: BountyCategory.Discord,
        thumbnail,
      })

      for (const task of tasks) {
        await TaskService.create({
          ...task,
          bountyId: bounty.data._id,
          thumbnail: '',
        })
      }

      await createSeoInfo(bounty.data._id)

      notifySuccess('Create bounty')
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
      navigate(ROUTES.BOUNTY.INDEX)
    }
  }

  const onUpdate = async () => {
    try {
      setLoading(true)

      let thumbnail = bountyData.thumbnail
      if (fileList.length && fileList[0].originFileObj)
        thumbnail = await onUpload(fileList[0])

      await BountyService.update(bountyId, {
        ...bountyData,
        communityId: currentCommunity._id,
        category: BountyCategory.Discord,
        thumbnail,
      })

      for (const task of tasks) {
        await TaskService.update(task.id, task)
      }

      updateSeoInfo()

      notifySuccess('Update bounty')
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
      navigate(ROUTES.BOUNTY.INDEX)
    }
  }

  const onSubmit = async () => {
    if (bountyId) onUpdate()
    else onCreate()
  }

  const disabled = useMemo(
    () => !Object.values(bountyData) || !tasks.length || !fileList.length,
    [bountyData, fileList.length, tasks.length],
  )

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <FormSetUp
          fields={[
            SetUpField.Picture,
            SetUpField.DateRange,
            SetUpField.LinkDiscord,
          ]}
          nextButton={false}
        />
      </Col>
      <Col span={24}>
        <Row justify="space-between">
          <Col flex="auto">
            <Button
              icon={<IonIcon name="close-outline" />}
              ghost
              onClick={() => navigate(ROUTES.BOUNTY.INDEX)}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Space size={8}>
              <Button
                style={{ minWidth: 130 }}
                type="primary"
                onClick={onSubmit}
                disabled={disabled}
                loading={loading}
              >
                <Space>Submit</Space>
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DiscordBounty
