import moment from 'moment'

import { Space, Typography } from 'antd'
import ItemName from './itemName'
import Action from './action'

import { ChainID, ChainLabel, TIME_FORMAT } from 'constant'
import { IProductData } from 'types/product.type'

import noImage from 'static/images/system/nodata.svg'

// export const COLUMN_DIGITAL_ITEM: TableProps<Data<IProductData>>['columns'] = [
export const COLUMN_DIGITAL_ITEM = [
  {
    title: 'Item Name',
    dataIndex: 'title',
    render: (time: number, data: IProductData) => (
      <ItemName
        name={data.title}
        favorite={data.totalFavorite}
        thumbnail={data.thumbnails[0] || noImage}
        view={0}
      />
    ),
    className: 'market-table-cel',
  },
  {
    title: 'Price',
    dataIndex: '',
    render: (data: IProductData) => {
      return (
        <Space size={6} direction="vertical">
          {!!data.priceToken?.price && (
            <Typography.Text
              style={{ textTransform: 'uppercase' }}
              type="success"
            >
              {data.priceToken.price} {data.priceToken.tokenName}
            </Typography.Text>
          )}
          {!!data.priceCCP && (
            <Typography.Text
              style={{ textTransform: 'uppercase' }}
              type="success"
            >
              {data.priceCCP} CCP
            </Typography.Text>
          )}
          {!!data.priceBadges.length && (
            <Typography.Text
              style={{ textTransform: 'uppercase' }}
              type="success"
            >
              {data.priceBadges.length} Badge
            </Typography.Text>
          )}
        </Space>
      )
    },
  },
  {
    title: 'Chain',
    dataIndex: 'chainId',
    render: (chainId: ChainID) => (
      <Typography.Text>{ChainLabel[chainId]}</Typography.Text>
    ),
  },

  {
    title: 'Creation Time',
    dataIndex: 'createdAt',
    render: (creationTime: string) => (
      <Typography.Text>
        {moment.utc(creationTime).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },

  {
    title: '',
    dataIndex: '_id',
    render: (_id: string, data: IProductData) => (
      <Action isAvailable={data.availableQuantity > 0} itemId={_id} />
    ),
  },
]

export const COLUMN_PHYSICAL_ITEM = [
  {
    title: 'Item Name',
    dataIndex: 'title',
    render: (time: number, data: IProductData) => (
      <ItemName
        name={data.title}
        favorite={data.totalFavorite}
        thumbnail={data.thumbnails[0] || noImage}
        view={0}
      />
    ),
    className: 'market-table-cel',
  },
  {
    title: 'Available',
    dataIndex: 'availableQuantity',
    render: (amount: any) => {
      return (
        <Typography.Text style={{ textTransform: 'uppercase' }} type="success">
          {amount}
        </Typography.Text>
      )
    },
  },
  {
    title: 'Price',
    dataIndex: '',
    render: (data: IProductData) => {
      return (
        <Space size={6} direction="vertical">
          {!!data.priceToken?.price && (
            <Typography.Text
              style={{ textTransform: 'uppercase' }}
              type="success"
            >
              {data.priceToken.price} {data.priceToken.tokenName}
            </Typography.Text>
          )}
          {!!data.priceCCP && (
            <Typography.Text
              style={{ textTransform: 'uppercase' }}
              type="success"
            >
              {data.priceCCP} CCP
            </Typography.Text>
          )}
          {data.priceBadges.length > 0 && (
            <Typography.Text
              style={{ textTransform: 'uppercase' }}
              type="success"
            >
              {data.priceBadges.length} Badge
            </Typography.Text>
          )}
        </Space>
      )
    },
  },
  {
    title: 'Sold',
    dataIndex: '',
    render: (data: IProductData) => {
      const total = data.totalQuantity || 0
      const availabel = data.availableQuantity || 0
      return <Typography.Text>{total - availabel}</Typography.Text>
    },
  },
  {
    title: 'Creation Time',
    dataIndex: 'createdAt',
    render: (creationTime: string) => (
      <Typography.Text>
        {moment.utc(creationTime).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },

  {
    title: '',
    dataIndex: '_id',
    render: (_id: string, data: IProductData) => (
      <Action isAvailable={data.availableQuantity > 0} itemId={_id} />
    ),
  },
]
