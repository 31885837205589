import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import FormSetup from './FormSetup'

import { useCreatePlayerSeasonMutation } from 'hooks/player-leaderboard/useCreatePlayerSeasonMutation'

import { notifyError, notifySuccess } from 'helper'
import { ROUTES } from 'constant/routes'
import { PickPartial } from 'types'
import {
  CreatePlayerSeasonDto,
  IPlayerSeason,
} from 'types/player-leaderboard/player-leaderboard-season.type'

export type PlayerSeasonInitialData = PickPartial<
  IPlayerSeason,
  'startedAt' | 'endedAt' | 'id'
>

const DUMMY_PLAYER_SEASON_DATA: PlayerSeasonInitialData = {
  title: '',
  subTitle: '',
  totalRewards: 0,
  banner: '',
  referralPercents: [0],
  prizeStructures: [
    { title: 'Top 1', priceText: '' },
    { title: 'Top 2', priceText: '' },
    { title: 'Top 3', priceText: '' },
    { title: 'Top 4 - 6', priceText: '' },
    { title: 'Top 7 - 10', priceText: '' },
  ],
  callToAction: {
    title: '',
    link: '',
  },
  startedAt: undefined,
  endedAt: undefined,
}

function FormCreate() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutate, isLoading: mutateLoading } = useCreatePlayerSeasonMutation()
  const onMutate = (seasonData: CreatePlayerSeasonDto) => {
    mutate(seasonData, {
      onError: (error) => {
        notifyError(error)
      },
      onSuccess: async () => {
        notifySuccess('Created season')
        //TODO: merge to hooks later
        await queryClient.invalidateQueries('GET_PLAYER_SEASON')
        navigate(ROUTES.PLAYER_LEADERBOARD.SEASON_MANAGEMENT)
      },
    })
  }

  return (
    <FormSetup
      initialSeasonData={DUMMY_PLAYER_SEASON_DATA}
      onMutate={onMutate}
      mutateLoading={mutateLoading}
    />
  )
}

export default FormCreate
