import { bountySystemApi as api } from 'services/base-axios'

import { DataList } from 'types'
import {
  FindSeasonsDto,
  CreateAndUpdateSeasonDto,
  ISeason,
} from 'types/season.type'

export class Season {
  private index = '/season'

  async getSeasons(params: FindSeasonsDto) {
    const { data } = await api.get<DataList<ISeason[]>>(`${this.index}`, {
      params,
    })
    return data
  }

  async getSeasonById(id: string) {
    if (!id) return

    const { data } = await api.get<ISeason>(`${this.index}/${id}`)
    return data
  }

  async createSeason(data: CreateAndUpdateSeasonDto) {
    return api.post(`${this.index}`, data)
  }

  async cloneSeason(seasonId: string) {
    return api.post(`${this.index}/${seasonId}/clone-with-leaderboard`)
  }

  async updateSeason(seasonId: string, data: CreateAndUpdateSeasonDto) {
    return api.patch(`${this.index}/${seasonId}`, data)
  }

  async deleteSeason(seasonId: string) {
    return api.delete(`${this.index}/${seasonId}`)
  }
}

export const SeasonService = new Season()
