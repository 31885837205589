import { Table } from 'antd'

import { HISTORY_COLUMN } from './historyColumn'
import { TableHistoryReportData } from '../index'

type TableReportHistoryProps = {
  startDate?: string
  endDate?: string
  data: TableHistoryReportData[]
}

const TableReportHistory = ({
  endDate = '',
  startDate = '',
  data,
}: TableReportHistoryProps) => {
  return (
    <Table
      columns={HISTORY_COLUMN}
      className="table-border-collapse report-table"
      dataSource={data}
      pagination={false}
    />
  )
}

export default TableReportHistory
