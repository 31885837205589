import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

export const useAcceler8ActiveSeasons = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_ACTIVE_SEASON'],
    queryFn: () => Acceler8Service.findActiveSeasons(),
  })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
  }
}
