import moment from 'moment'

import { Typography } from 'antd'
import RewardInfo from './rewardInfo'
import RowAction from './rowAction'
import TagRecurrenceTime from 'components/systems/tagRecurrenceTime'

import { TIME_FORMAT } from 'constant'
import { BountyData } from 'services/bountySystem/bounty'

export const COLUMN_BOUNTY = [
  {
    title: 'Name',
    dataIndex: 'title',
    render: (title: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 160 }}
      >
        {title}
      </Typography.Paragraph>
    ),
  },

  {
    title: 'Repeat',
    dataIndex: 'recurrenceTime',
    render: (time: number) => <TagRecurrenceTime time={time} />,
  },
  {
    title: 'Number of tasks',
    dataIndex: 'totalTasks',
    render: (totalTasks: number) => (
      <Typography.Text>{totalTasks}</Typography.Text>
    ),
  },
  {
    title: 'Open In',
    dataIndex: 'startedAt',
    render: (startedAt: string) => (
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>
        {moment.utc(startedAt).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
  {
    title: 'End In',
    dataIndex: 'endedAt',
    render: (endedAt: string) => (
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>
        {moment.utc(endedAt).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },

  {
    title: 'Rewards',
    dataIndex: 'rewardCCP',
    render: (rewardCCP: number, { rewardBadges }: BountyData) => (
      <RewardInfo
        rewardCCP={rewardCCP}
        rewardBadgeIds={rewardBadges?.map((e) => e.badgeId)}
      />
    ),
  },

  {
    title: '',
    dataIndex: '',
    render: (bountyData: BountyData) => <RowAction bountyData={bountyData} />,
  },
]
