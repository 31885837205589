import moment from 'moment'

import { Button, Card, Image } from 'antd'

import SettingReward from '../settingReward'

import useCheckIn from '../../useCheckIn'
import { useBadgeDetails } from 'hooks/badges/useBadges'

import success from 'static/images/success.svg'
import noImage from 'static/images/system/nodata.svg'

import { IDailyReward } from 'types/check-in.type'

const DailyReward = ({ date, reward }: IDailyReward) => {
  const isFinish = moment.utc(date).isBefore(moment.utc().startOf('day'))
  const { isChecked } = useCheckIn(date, date, reward?._id)
  const { data: badgeData } = useBadgeDetails(
    reward?.rewardBadge?.badgeId || '',
  )

  const today =
    moment.utc().format('YYYYMMDD') === moment.utc(date).format('YYYYMMDD')

  return (
    <SettingReward fromDate={date} toDate={date} reward={reward}>
      <Card
        aria-disabled={true}
        style={{
          padding: 0,
        }}
        hoverable
        bodyStyle={{ padding: 0, position: 'relative' }}
        className={`${isChecked ? 'daily-rewards-streak-card' : ''} ${
          today ? 'card-border-animation' : ''
        }`}
      >
        <Image
          preview={false}
          src={badgeData?.thumbnail || ''}
          fallback={noImage}
          style={{
            width: '100%',
            aspectRatio: '1/1',
            objectFit: 'cover',
            filter: isFinish && !isChecked ? 'brightness(40%)' : undefined,
          }}
        />

        {isChecked && (
          <div style={{ position: 'absolute', bottom: 4, right: 4 }}>
            <Image src={success} preview={false} height={27} width={27} />
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            filter: isFinish && !isChecked ? 'brightness(40%)' : undefined,
          }}
        >
          <Button
            style={{ padding: 6, height: 32, width: 32, fontWeight: 700 }}
          >
            {moment.utc(date).date()}
          </Button>
        </div>
      </Card>
    </SettingReward>
  )
}

export default DailyReward
