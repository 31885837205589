import { Fragment } from 'react'

import { Form, Input, InputNumber, Select } from 'antd'

import RequiredLabel from 'components/systems/requiredSymbol'
import FormItemSelectChain from 'components/systems/formItemSelectChain'

import { ChainID } from 'constant'
import { ContractInteractionType } from 'types/acceler8/acceler8-task.type'

function InteractScSection() {
  return (
    <Fragment>
      <FormItemSelectChain
        name={['configs', 'chain']}
        initialValue={ChainID.A8}
        disabled={true}
      />

      <Form.Item
        name={['configs', 'contractInteractionType']}
        label={<RequiredLabel children="Contract interaction type" />}
        initialValue={ContractInteractionType.OneWay}
        rules={[
          {
            required: true,
            message: 'Please select contract interaction type',
          },
        ]}
      >
        <Select
          options={[{ label: '1-way', value: ContractInteractionType.OneWay }]}
          disabled
        />
      </Form.Item>

      <Form.Item
        name={['configs', 'inboundContractAddress']}
        label={<RequiredLabel children="Inbound contract address" />}
        rules={[
          {
            required: true,
            message: 'Please enter inbound contract address',
          },
        ]}
      >
        <Input placeholder="Enter inbound contract address" />
      </Form.Item>

      <Form.Item
        name={['configs', 'functionName']}
        label={<RequiredLabel children="Function name" />}
        rules={[
          {
            required: true,
            message: 'Please enter function name',
          },
        ]}
      >
        <Input placeholder="Enter function name" />
      </Form.Item>

      <Form.Item
        name={['configs', 'numberOfTxs']}
        label={<RequiredLabel children="Number of transactions" />}
        rules={[
          {
            required: true,
            message: 'Please enter number of transactions',
          },
        ]}
      >
        <InputNumber placeholder="Enter number of transactions" />
      </Form.Item>
    </Fragment>
  )
}

export default InteractScSection
