import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

export const useAcceler8SeasonBefore = (seasonId?: number) => {
  return useQuery({
    queryKey: ['GET_ACCELER8_SEASON_BEFORE', seasonId],
    queryFn: () => Acceler8Service.getAcceler8SeasonBefore(seasonId!),
    enabled: !!seasonId,
  })
}
