import { Data, FindDto } from 'types'

export interface IAds {
  image: string

  url: string

  isPublished: boolean

  startedAt: string

  endedAt: string

  type: AdsType

  priority: number
}

export enum AdsType {
  DailyCheckin = 'ADS_TYPE::DAILY_CHECKIN',
}

export enum AdsStatus {
  OnGoing = 'ADS_STATUS::ONGOING',
  Ended = 'ADS_STATUS::ENDED',
  Unpublished = 'ADS_STATUS::UNPUBLISHED',
}

export type AdsData = Data<IAds>

export type FindAdsDto = {
  status: AdsStatus
  sort?: AdsSortOptions
} & FindDto

export enum AdsSortOptions {
  Newest = 'ADS_SORT:NEWEST',
  Priority = 'ADS_SORT:PRIORITY',
}

export type CreateAdsDto = {
  image: string
  url: string
  isPublished: boolean
  startedAt: string
  endedAt: string
  type: AdsType
  priority?: number
}

export type UpdateAdsDto = Omit<CreateAdsDto, 'priority'> & {
  priority: number
}
