import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  ModalProps,
  Row,
  Space,
  Typography,
} from 'antd'
import IonIcon from '../ionIcon'
import { WrapLoading } from '../loading'
import SpaceVertical from '../spaceVertical'
import CardNFTItem from './cardNFTItem'

import { ChainID, DEFAULT_PAGE } from 'constant'
import { useBadges } from 'hooks/badges/useBadges'
import { BadgeData } from 'services/bountySystem/items'

type SelectNFTBadgeProps = {
  supply?: number
  chainId?: ChainID
  multiple?: boolean
  badgeSelected: string[]
  setBadgeSelected: (val: string[]) => void
  setOpen: (val: boolean) => void
}

const SelectNFTBadge = ({
  supply,
  chainId,
  multiple,
  badgeSelected = [],
  setBadgeSelected,
  setOpen,
  ...props
}: ModalProps & SelectNFTBadgeProps) => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [currBadges, setCurrBadges] = useState<BadgeData[]>([])
  const [tmp, setTmp] = useState<string[]>([])
  const { badges, loading, total } = useBadges({
    search,
    supply,
    chainId,
    page,
  })

  // Infinite Scroll
  const observer = useRef<IntersectionObserver | null>(null)
  const hasMore = useMemo(
    () => currBadges.length < total,
    [currBadges.length, total],
  )

  useEffect(() => {
    if (badges.length) setCurrBadges((prev) => [...prev, ...badges])
    else setCurrBadges([])
  }, [badges, supply])

  const listRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((page) => page + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore],
  )

  useEffect(() => {
    if (supply || search) {
      setCurrBadges([])
      setPage(DEFAULT_PAGE)
    }
  }, [search, supply])

  const onSelectItems = (badgeId: string) => {
    // check if allow multiple selected
    // previous selected will be accepted or else will be reset
    const clnBadges = multiple ? [...tmp] : []

    if (!clnBadges.includes(badgeId)) clnBadges.push(badgeId)
    else {
      const indexOf = clnBadges.indexOf(badgeId)
      clnBadges.splice(indexOf, 1)
    }
    return setTmp(clnBadges)
  }

  const handleSelect = () => {
    setBadgeSelected(tmp)
    setOpen(false)
  }

  useEffect(() => {
    setTmp(badgeSelected)
  }, [badgeSelected])

  return (
    <Modal onCancel={() => setOpen(false)} footer={false} {...props}>
      <Row gutter={[24, 24]} justify="center">
        <Col span={24} style={{ textAlign: 'center' }}>
          <SpaceVertical align="center">
            <Typography.Title level={4} style={{ color: '#42BAB0' }}>
              Selected Collection NFTs
            </Typography.Title>
            <Typography.Text>
              {badgeSelected.length} of {total} Nft selected in your collection
            </Typography.Text>
          </SpaceVertical>
        </Col>
        <Col span={24}>
          <Input
            placeholder="Search for Collection NFT"
            onChange={(e) => setSearch(e.target.value)}
            suffix={
              <Button
                type="text"
                style={{ width: 'auto', height: 'auto', padding: 0 }}
                icon={<IonIcon name="search-outline" />}
              />
            }
          />
        </Col>
        <Col span={24} style={{ height: 350 }} className="scrollbar">
          <WrapLoading type="stick" loading={loading}>
            <Row gutter={[10, 10]} justify="center">
              {currBadges.length ? (
                currBadges.map((badeData, index) => (
                  <Col
                    span={24}
                    key={badeData._id}
                    ref={
                      index === currBadges.length - 1 ? (e) => listRef(e) : null
                    }
                  >
                    <CardNFTItem
                      badgeData={badeData}
                      onSelect={onSelectItems}
                      active={tmp.includes(badeData._id)}
                    />
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <Empty />
                </Col>
              )}
            </Row>
          </WrapLoading>
        </Col>
        <Col>
          <Space size={20}>
            <Button
              icon={<IonIcon name="arrow-back-outline" />}
              ghost
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleSelect}
              disabled={!tmp.length}
            >
              Select
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  )
}

export default SelectNFTBadge
