import { useCallback, useEffect, useState } from 'react'
import {
  CollectionData,
  CollectionService,
  Socials,
} from 'services/marketplace/collection'
import { ChainID } from 'constant'

export const DUMMY_COLLECTION_DATA: CollectionData = {
  _id: '',
  title: '',
  description: '',
  thumbnail: '',
  totalNFT: 0,
  socials: {} as Socials,
  chainId: ChainID.BSC,
  collectionAddress: '',
}

type UseCollectionProps = {
  page?: number
  pageSize?: number
  search?: string
  chainId?: ChainID
}
export const useCollections = (props: UseCollectionProps) => {
  const { page, pageSize, search, chainId } = props
  const [collections, setCollections] = useState<CollectionData[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchCollections = useCallback(async () => {
    try {
      setLoading(true)
      const { data, total } = await CollectionService.getAll({
        page,
        pageSize,
        search,
        chainId,
      })

      setCollections(data)
      setTotal(total)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [page, pageSize, search, chainId])

  useEffect(() => {
    fetchCollections()
  }, [fetchCollections])

  return { collections, total, loading }
}

export const useCollectionData = (collectionId: string) => {
  const [collectionData, setCollectionData] = useState<CollectionData>(
    DUMMY_COLLECTION_DATA,
  )
  const [loading, setLoading] = useState(false)

  const fetchCollectionData = useCallback(async () => {
    try {
      setLoading(true)
      const data = await CollectionService.getCollectionById(collectionId)
      setCollectionData(data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [collectionId])

  useEffect(() => {
    fetchCollectionData()
  }, [fetchCollectionData])

  return { collectionData, loading }
}
