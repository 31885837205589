import { useEffect, useMemo } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { CSVLink } from 'react-csv'

import CardInfo from 'components/systems/cardInfo'

import { usePlayerSeasons } from 'hooks/player-leaderboard/usePlayerSeasons'
import { usePlayerAccumulativePoints } from 'hooks/player-leaderboard/usePlayerAccumulativePoints'
import { usePlayerRanks } from 'hooks/player-leaderboard/usePlayerRanks'
import { usePlayerPointDistribution } from 'hooks/player-leaderboard/usePlayerPointDistribution'
import { useAcceler8TasksByPrograms } from 'hooks/acceler8/useAcceler8TasksByPrograms'
import { useUserProfileByUid } from 'hooks/user/useUserProfileByUid'

import { Acceler8Service } from 'services/acceler8'
import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { notifyError, notifySuccess } from 'helper'

import { EXPORT_TIME_FORMAT } from 'constant'

import {
  IPlayerRank,
  UpdateReferralPercent,
} from 'types/player-leaderboard/player-leaderboard-rank.type'

export type PlayerInitialData = Pick<
  IPlayerRank,
  'referralPercents' | 'seasonId'
>

const CSV_HEADER = [
  { label: 'UID', key: 'userId' },
  { label: 'TwitterId', key: 'twitterId' },
  { label: 'Wallet', key: 'walletAddress' },
  { label: 'Task Name', key: 'taskName' },
  { label: 'Organic point', key: 'organicPoints' },
  { label: 'Referral Contributed Point', key: 'refPoints' },
]

function EditPlayerRank() {
  const userId = useParams().userId || ''
  const [form] = Form.useForm<PlayerInitialData | null>()
  const seasonId = Form.useWatch('seasonId', form)

  const { data: listSeason } = usePlayerSeasons()

  const {
    data: [playerRankData],
  } = usePlayerRanks({
    seasonId,
    userIds: [userId],
  })

  const {
    data: [userAccumulativePoints],
  } = usePlayerAccumulativePoints([userId])

  const { mutate: updateReferralPercent, isLoading: mutateLoading } =
    useMutation({
      mutationKey: ['UPDATE_PLAYER_REFERRAL_PERCENT', userId],
      mutationFn: (payload: UpdateReferralPercent) =>
        PlayerLeaderboardService.updateReferralPercent(payload),
      onSuccess: () => {
        notifySuccess('Updated player')
      },
      onError: (e: any) => {
        notifyError(e)
      },
    })

  const {
    walletAddress,
    twitterId,
    isLoading: profileLoading,
  } = useUserProfileByUid(userId)
  const { data: playerPointDistribution, isLoading: pointDistributionLoading } =
    usePlayerPointDistribution({
      uid: userId,
      seasonId,
    })
  const { data: tasks, isLoading: taskLoading } = useAcceler8TasksByPrograms(
    playerPointDistribution?.map((item) => item.programId),
  )

  const exportFileName = useMemo(
    () =>
      `player-${userId}-season-${seasonId}-${moment().format(
        EXPORT_TIME_FORMAT,
      )}.csv`,
    [seasonId, userId],
  )

  const loadingExportUserEarningPoint = useMemo(
    () => pointDistributionLoading || taskLoading || profileLoading,
    [pointDistributionLoading, profileLoading, taskLoading],
  )

  const exportData = useMemo(() => {
    return (
      playerPointDistribution?.map(
        ({ refPoints, organicPoints, programId }) => {
          const taskName =
            tasks?.find((task) => task.programId === programId)?.title ?? ''

          return {
            userId,
            twitterId,
            walletAddress,
            taskName,
            refPoints,
            organicPoints,
          }
        },
      ) ?? []
    )
  }, [playerPointDistribution, tasks, twitterId, userId, walletAddress])

  const seasonOptions = useMemo(
    () =>
      listSeason.map((season) => ({
        label: season.title,
        value: season.id,
        key: season.id,
      })) ?? [],
    [listSeason],
  )

  const onSave = async () => {
    const values = await form.validateFields()

    if (!values) return

    const referralPercents: number[] | null = values.referralPercents?.length
      ? values.referralPercents
      : null

    updateReferralPercent({
      userId,
      seasonId: values.seasonId,
      referralPercents,
    })
  }

  useEffect(() => {
    if (!playerRankData) return

    form.setFieldValue('referralPercents', playerRankData.referralPercents)
  }, [playerRankData, form])

  if (!listSeason.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
  }

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Player Information
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Form form={form} layout="vertical" onFinish={onSave}>
          <Form.Item
            name="seasonId"
            label={<Typography.Text>Seasons</Typography.Text>}
            initialValue={listSeason[0].id}
          >
            <Select
              placeholder="Select season"
              value={listSeason[0].id}
              options={seasonOptions}
            />
          </Form.Item>

          <Form.Item label={<Typography.Text>UID</Typography.Text>}>
            <Typography.Text type="secondary" copyable={{ text: userId }}>
              {userId}
            </Typography.Text>
          </Form.Item>

          <Form.Item
            label={
              <Typography.Text>Total points current season</Typography.Text>
            }
          >
            <Typography.Text type="secondary">
              {(playerRankData && playerRankData.points) || 0}
            </Typography.Text>
          </Form.Item>

          <Form.Item
            label={<Typography.Text>Total accumulative points</Typography.Text>}
          >
            <Typography.Text type="secondary">
              {(userAccumulativePoints &&
                userAccumulativePoints.accumulativePoints) ||
                0}
            </Typography.Text>
          </Form.Item>

          <Form.Item>
            <CardInfo title="Referral Percents">
              <Form.List name="referralPercents">
                {(subFields, subOpt, { errors }) => (
                  <Row gutter={[0, 12]}>
                    <Col span={24}>
                      <Form.ErrorList errors={errors} />
                    </Col>
                    {subFields.map((subField, index) => (
                      <Col key={subField.key} span={24}>
                        <Space>
                          <Form.Item noStyle>
                            <Typography.Text>Level {index + 1}</Typography.Text>
                          </Form.Item>
                          <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                            {({ getFieldValue }) => (
                              <Form.Item
                                name={subField.name}
                                style={{ width: 250, marginBottom: 0 }}
                              >
                                <Input
                                  min={0}
                                  max={100}
                                  placeholder="Please enter the percent"
                                  suffix={
                                    <Tag
                                      title="Extra information"
                                      color="green"
                                    >
                                      ={' '}
                                      {Math.floor(
                                        (getFieldValue([
                                          'referralPercents',
                                          subField.name,
                                        ]) ?? 0) * 10000, //get only 2 decimals
                                      ) / 100}
                                      %
                                    </Tag>
                                  }
                                />
                              </Form.Item>
                            )}
                          </Form.Item>
                          <CloseOutlined
                            onClick={() => subOpt.remove(subField.name)}
                          />
                        </Space>
                      </Col>
                    ))}
                    <Col span={24}>
                      <Button
                        type="dashed"
                        onClick={() => subOpt.add()}
                        block
                        disabled={subFields.length === 5}
                      >
                        + Add
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form.List>
            </CardInfo>
          </Form.Item>

          <Space size={16}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ minWidth: 130 }}
              loading={mutateLoading}
            >
              Save
            </Button>

            <Button
              type="primary"
              href={Acceler8Service.getDownloadReferrersUrl(seasonId, userId)}
            >
              Export User's Referral Data
            </Button>

            <CSVLink
              filename={exportFileName}
              headers={CSV_HEADER}
              data={exportData}
            >
              <Button
                type="primary"
                loading={loadingExportUserEarningPoint}
                disabled={loadingExportUserEarningPoint}
              >
                {loadingExportUserEarningPoint
                  ? 'Generating...'
                  : 'Export User Earning Point History'}
              </Button>
            </CSVLink>
          </Space>
        </Form>
      </Col>
    </Row>
  )
}

export default EditPlayerRank
