import { useMemo } from 'react'

import { Col, Row, Table } from 'antd'

import {
  Acceler8TaskBoard,
  COLUMN_TASK,
  COLUMN_TASK_SPACE3,
} from 'components/leaderboard-task/list-task/column'

import {
  TotalParticipantsOfProgram,
  TotalPointsOfProgram,
} from 'types/acceler8/acceler8-program.type'
import { IAcceler8Task } from 'types/acceler8/acceler8-task.type'

type ListLeaderboardTaskProps = {
  isSpace3: boolean
  tasks?: IAcceler8Task[]
  participants?: TotalParticipantsOfProgram[]
  totalPoints?: TotalPointsOfProgram[]
}

function ListLeaderboardTask({
  isSpace3,
  tasks,
  participants,
  totalPoints,
}: ListLeaderboardTaskProps) {
  const boardData: Acceler8TaskBoard[] = useMemo(() => {
    if (!tasks) return []

    const participantsByProgramId: Record<number, number> = {}
    participants?.forEach(
      (participantData) =>
        (participantsByProgramId[participantData.programId] =
          participantData.participants),
    )

    const totalPointsByProgramId: Record<number, number> = {}
    totalPoints?.forEach(
      (totalPointData) =>
        (totalPointsByProgramId[totalPointData.programId] =
          totalPointData.totalTaskPoints ?? 0),
    )

    return tasks.map((task) => ({
      ...task,
      participants: participantsByProgramId[task.programId],
      totalPoints: totalPointsByProgramId[task.programId],
    }))
  }, [tasks, participants, totalPoints])

  return (
    <Row>
      <Col span={24}>
        <Table
          className="table-border-collapse"
          columns={!isSpace3 ? COLUMN_TASK : COLUMN_TASK_SPACE3}
          dataSource={boardData}
          rowKey="_id"
        />
      </Col>
    </Row>
  )
}

export default ListLeaderboardTask
