import { UploadFile } from 'antd'

export const onConvertFileList = (data: string[]) => {
  const newFileList: UploadFile[] = data.map((url, idx) => ({
    uid: `-${idx + 1}`,
    name: `file_${idx + 1}`,
    status: 'done',
    url,
  }))
  return newFileList
}
