import { useDispatch, useSelector } from 'react-redux'

import { Col, Drawer, Row } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import UserInfo from 'view/header/userInfo'
import SidebarContainer from './sidebarContainer'

import { setVisible } from 'store/sidebar.reducer'
import { AppState } from 'store'

const MobileView = () => {
  const dispatch = useDispatch()
  const visible = useSelector(({ sidebar }: AppState) => sidebar.visible)

  return (
    <Drawer
      headerStyle={{ border: 'none' }}
      open={visible}
      onClose={() => dispatch(setVisible(false))}
      closeIcon={<IonIcon style={{ fontSize: 24 }} name="close-outline" />}
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <UserInfo loginStyle={{ margin: '0 24px' }} />
        </Col>
        <Col span={24}>
          <SidebarContainer forceVisible isMobile />
        </Col>
      </Row>
    </Drawer>
  )
}

export default MobileView
