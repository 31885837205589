import { TypeFiles } from 'constant'

export const decodeKey = (key: string) => {
  return key.toLowerCase().replace(/ /g, '_')
}

export const encodeAttributes = (keys: string): Record<string, string> => {
  const attributes = keys.split('^')
  const result: Record<string, string> = {}
  for (const attribute of attributes) {
    const [title, value] = attribute.split(':')
    result[title] = value
  }
  return result
}

export const decodeAttribute = (title: string, option: string) => {
  const key = `${title}:${option}`
  return decodeKey(key)
}

export const sumAttribute = (a: string, b: string) => {
  return a + `^` + b
}

export const generateAttributeIds = (
  attributes: { title: string; options: string[] }[],
) => {
  let currentIds: string[] = []
  for (const { title, options } of attributes) {
    const newIds: string[] = []
    for (const option of options) {
      const newId = decodeAttribute(title, option)
      if (!currentIds.length) {
        newIds.push(newId)
        continue
      }
      for (const id of currentIds) newIds.push(sumAttribute(id, newId))
    }
    currentIds = newIds
  }
  return currentIds
}

export const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

/**
 * Only used for transform text input value
 *
 * If value is an empty string(""), return `undefined`
 */
export const transformUndefinedInput = (value: any) => {
  if (value === undefined) return undefined

  if (typeof value !== 'string') return value

  const inputTrim = value.trim()
  if (inputTrim === '') return undefined

  return inputTrim
}

/**
 * Remove field or element value is undefined or empty string
 *
 * Only used for Object with two level
 */
export const removeEmpty = (object: any): any => {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const value = object[key]

      if (value === undefined || value === '') {
        delete object[key]
        continue
      }

      if (Array.isArray(value) && value.length === 0) {
        delete object[key]
        continue
      }

      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (typeof item === 'object' && item !== null) {
              removeEmpty(item)
            }
          })
          continue
        }

        removeEmpty(value)
      }
    }
  }

  return object
}

/**
 * Remove field or element value is undefined or empty string
 *
 * Only used for Object/Array with more than two level
 */
export function removeEmptyNested(object: any): any {
  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i++) {
      const value = object[i]

      if (typeof value === 'object' && value !== null) {
        removeEmptyNested(value)
      }
    }
  }

  if (typeof object === 'object' && object !== null) {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key]

        if (value === undefined || value === '') {
          delete object[key]
          continue
        }

        if (Array.isArray(value) && value.length === 0) {
          delete object[key]
          continue
        }

        if (typeof value === 'object' && value !== null) {
          removeEmptyNested(value)
        }
      }
    }
  }

  return object
}

/**
 * Export to file
 */
export const exportToFile = (
  data: Blob | MediaSource,
  fileName: string,
  typeFile: TypeFiles = TypeFiles.Csv,
) => {
  const timestamp = Date.now()
  const fileUrl = URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = fileUrl
  link.download = `${fileName}-${timestamp}.${typeFile}`
  link.click()
  URL.revokeObjectURL(fileUrl)
}

/**
 *
 * Format Ordinal Number Suffixes
 */
export const formatNumberWithSuffix = (number: number) => {
  if (number > 3 && number < 21) return number + ' th'

  switch (number % 10) {
    case 1:
      return number + ' st'
    case 2:
      return number + ' nd'
    case 3:
      return number + ' rd'
    default:
      return number + ' th'
  }
}
