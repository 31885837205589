import React from 'react'

import { Collapse, Typography } from 'antd'
import FormSeoInfo from 'components/seo/formSeoInfo'

function SeoInformation() {
  return (
    <Collapse expandIcon={() => null} defaultActiveKey={1}>
      <Collapse.Panel
        key={1}
        header={
          <Typography.Title level={4} type="success">
            SEO Information (Optional)
          </Typography.Title>
        }
      >
        <FormSeoInfo />
      </Collapse.Panel>
    </Collapse>
  )
}

export default SeoInformation
