import type { Dispatch, SetStateAction } from 'react'

import { Dropdown, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ChainID } from 'constant'

type FilterProps = {
  chain: ChainID
  onChange: Dispatch<SetStateAction<ChainID>>
}

const FILTER_ITEMS = [
  {
    label: 'BSC',
    key: ChainID.BSC,
  },
  {
    label: 'ETH',
    key: ChainID.Ethereum,
  },
  {
    label: 'A8',
    key: ChainID.A8,
  },
  {
    label: 'ARBITRUM',
    key: ChainID.Arbitrum,
  },
  {
    label: 'POLYGON',
    key: ChainID.Polygon,
  },
  {
    label: 'SOLANA',
    key: ChainID.Solana,
    disabled: true,
  },
  {
    label: 'RONIN',
    key: ChainID.Ronin,
  },
  {
    label: 'OPTIMISM',
    key: ChainID.Optimism,
  },
  {
    label: 'BASE',
    key: ChainID.Base,
  },
]

const Filter = ({ chain, onChange }: FilterProps) => {
  return (
    <Dropdown.Button
      type="text"
      className="dropdown-btn pad-less"
      style={{ width: 'auto', height: '100%' }}
      trigger={['click']}
      icon={
        <Space
          style={{
            border: ' 1px solid #242F30',
            borderRadius: 4,
            padding: '6px 12px',
            width: 140,
            justifyContent: 'space-between',
          }}
        >
          <IonIcon name="filter-outline" style={{ color: '#42BAB0' }} />
          <Typography.Text style={{ fontSize: 12 }}>
            {FILTER_ITEMS.find(({ key }) => key === chain)?.label}
          </Typography.Text>
          <IonIcon name="chevron-down-outline" style={{ color: '#42BAB0' }} />
        </Space>
      }
      menu={{
        items: FILTER_ITEMS,
        onClick: (e) => onChange(e.key as ChainID),
      }}
    />
  )
}

export default Filter
