import { useNavigate } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'
import SettingCategory from './settingCategory'

import { notifySuccess } from 'helper'
import { ROUTES } from 'constant/routes'
import { ProductCategoryService } from 'services/marketplace/product-category'
import { IProductCategory } from 'types/product-category.type'

const CreateCategory = () => {
  const navigate = useNavigate()

  const handleCreateCategory = async (value: IProductCategory) => {
    await ProductCategoryService.create({
      ...value,
      description: value.title,
    })
    navigate(ROUTES.REWARDS_CENTER.CATEGORY_MANAGEMENT)
    notifySuccess('Create category')
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Add Category
        </Typography.Title>
      </Col>
      <Col span={24}>
        <SettingCategory onOk={handleCreateCategory} />
      </Col>
    </Row>
  )
}

export default CreateCategory
