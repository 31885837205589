import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'
import { FindTeamsRankDto } from 'types/acceler8/acceler8-team.type'

export const useAcceler8TeamProfile = (teamId: number) => {
  return useQuery({
    queryKey: ['GET_ACCELER8_TEAM_PROFILE', teamId],
    queryFn: () => Acceler8Service.getTeamProfile(teamId),
  })
}

export const useAcceler8AccumulativePoints = (teamIds: number[]) => {
  return useQuery({
    queryKey: ['GET_ACCELER8_ACCUMULATIVE_POINTS', ...teamIds],
    queryFn: () => Acceler8Service.getTeamAccumulativePoint(teamIds),
    enabled: !!teamIds.length,
  })
}

export const useAcceler8Teams = (dto: FindTeamsRankDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_TEAMS', dto],
    queryFn: () => Acceler8Service.getTeams(dto),
    enabled: !!dto.seasonId,
  })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
  }
}
