import { Radio } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'
import SpaceVertical from 'components/systems/spaceVertical'

import { ValueOf } from 'constant'
import { BountyRecurrence } from 'constant/bounty'
import { CreateBounty } from 'services/bountySystem/bounty'

const BOUNTY_RECURRENCE = {
  Once: BountyRecurrence.Once,
  Daily: BountyRecurrence.Daily,
  Weekly: BountyRecurrence.Weekly,
  Monthly: BountyRecurrence.Monthly,
}

export type RecurrenceProps = {
  onChange: (name: keyof CreateBounty, value: ValueOf<CreateBounty>) => void
  value: BountyRecurrence
}

const Recurrence = ({ onChange, value }: RecurrenceProps) => {
  return (
    <SpaceVertical>
      <RequiredLabel>Recurrence</RequiredLabel>
      <Radio.Group
        onChange={(e) => onChange('recurrenceTime', e.target.value)}
        value={value}
      >
        {Object.keys(BOUNTY_RECURRENCE).map((item) => (
          <Radio value={(BOUNTY_RECURRENCE as any)[item]} key={item}>
            {item}
          </Radio>
        ))}
      </Radio.Group>
    </SpaceVertical>
  )
}

export default Recurrence
