import { ChangeEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Button, Col, Input, InputNumber, Row, Space, Typography } from 'antd'
import CardInfo from 'components/systems/cardInfo'
import RequiredLabel from 'components/systems/requiredSymbol'
import InputImage from 'components/systems/uploadPicture/inputImage'

import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import { useChainId } from 'hooks/useChainId'

import { onSwitchChainNet } from 'store/main.reducer'

import { BadgeService, CreateBadge } from 'services/bountySystem/items'
import { notifyError, notifySuccess } from 'helper'

import { ChainID, FormNoticeType } from 'constant'
import { ROUTES } from 'constant/routes'

const DUMMY_BADGE_DATA: CreateBadge = {
  title: '',
  thumbnail: '',
  description: '',
  supply: 0,
  configs: {
    [ChainID.A8]: {
      contractAddress: '',
      tokenId: 0,
    },
  },
}

const MIN_SUPPLY = 1

const NewNftBadge = () => {
  const dispatch = useDispatch()
  const [badge, setBadge] = useState<CreateBadge>(DUMMY_BADGE_DATA)
  const [thumbnail, setThumbnail] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [maxSupply, setMaxSupply] = useState(MIN_SUPPLY)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const chainId = useChainId()

  const disabled = useMemo(() => {
    const { title, supply, configs } = badge
    return !title || !supply || !configs
  }, [badge])

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    return setBadge({ ...badge, [name]: value })
  }

  // const onSolConfigChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { value, name } = e.target

  //   const nextConfigs = {
  //     ...badge.configs,
  //     [ChainID.Solana]: { tokenAddress: value },
  //   }
  //   return setBadge({ ...badge, [name]: nextConfigs })
  // }

  // const onConfigChange = (itemData: UserNFTData) => {
  //   setThumbnail(itemData.image)
  //   setMaxSupply(Number(itemData.amount))
  //   setBadge({
  //     ...badge,
  //     title: itemData.name,
  //     description: itemData.description,
  //     supply: Number(itemData.amount),
  //     configs: {
  //       ...badge.configs,
  //       [chainId]: {
  // contractAddress: itemData.tokenAddress,
  // tokenId: itemData.tokenId,
  //       },
  //     },
  //   })
  // }

  const onConfirm = async () => {
    if (!thumbnail || disabled || !badge.configs) return
    // if (badge.supply > maxSupply) {
    //   notifyError({ message: 'Invalid max supply!' })
    //   return
    // }

    try {
      setLoading(true)
      const params = { ...badge, thumbnail }
      await BadgeService.create(params)
      notifySuccess('Create badge successfully!')
      return navigate(ROUTES.NFT_BADGE.INDEX)
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SelectChainContext.Provider
      value={{
        selectedChain: chainId,
        setSelectedChain: (chain: ChainID) => {
          dispatch(onSwitchChainNet(chain))
        },
      }}
    >
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Typography.Title type="success" level={3}>
            Create Badge
          </Typography.Title>
        </Col>
        {/* <Col span={24}>
          <CardInfo title="On-Chain data config">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SelectChain />
              </Col>
              <Col span={24}>
                <SelectWeb3Item
                  onChange={(_, data) => {
                    onConfigChange(data)
                  }}
                  productType={ProductType.REAL_NFT}
                />
              </Col>

              <Col span={24}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <RequiredLabel required={false}>SOL Collection</RequiredLabel>
                  <Input
                    onChange={onSolConfigChange}
                    name="configs"
                    placeholder="Contract ID..."
                    value={badge.configs?.[ChainID.Solana]?.tokenAddress}
                  />
                </Space>
              </Col>
            </Row>
          </CardInfo>
        </Col> */}
        <Col span={24}>
          <CardInfo title="Off-Chain data config">
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text type="secondary">
                    {FormNoticeType.UploadMaxSize}
                  </Typography.Text>
                  <InputImage onChange={setThumbnail} value={thumbnail} />
                  <Typography.Text type="secondary">
                    Upload or drag and drop a PNG, GIF, or JPG to display in the
                    app.
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <RequiredLabel>Badge Name (5-64 characters)</RequiredLabel>
                  <Input
                    onChange={onChange}
                    name="title"
                    value={badge.title}
                    placeholder="Enter Name"
                  />
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <RequiredLabel>Max supply</RequiredLabel>
                  <InputNumber
                    onChange={(supply) => {
                      setBadge({ ...badge, supply: supply || 0 })
                    }}
                    value={badge.supply ? badge.supply : undefined}
                    placeholder="Enter supply"
                    min={MIN_SUPPLY}
                  />
                </Space>
              </Col>
              <Col span={24}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Typography.Text type="secondary">
                    Description (5-800 characters)
                  </Typography.Text>
                  <Input.TextArea
                    value={badge.description}
                    rows={4}
                    placeholder="Enter Description"
                    name="description"
                    onChange={onChange}
                  />
                </Space>
              </Col>
            </Row>
          </CardInfo>
        </Col>

        <Col span={24} />
        <Col span={24}>
          <Space size={24}>
            <Button style={{ width: 130 }} ghost type="ghost">
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              disabled={disabled}
              style={{ width: 130 }}
              type="primary"
              loading={loading}
            >
              Create
            </Button>
          </Space>
        </Col>
      </Row>
    </SelectChainContext.Provider>
  )
}

export default NewNftBadge
