import moment from 'moment'

import { Typography } from 'antd'
import TagRecurrenceTime from 'components/systems/tagRecurrenceTime'
import TagCategory from 'components/bounty/tagCategory'
import RewardInfo from '../../management/listBounty/column/rewardInfo'

import { shortenAddress } from 'helper'
import { BountyData } from 'services/bountySystem/bounty'
import { TIME_FORMAT } from 'constant'
import TagBountyStatus from './tagBountyStatus'
import { TableHistoryReportData } from '../index'

export const HISTORY_COLUMN = [
  {
    title: 'UID',
    dataIndex: 'uid',
    render: (title: string) => (
      <Typography.Paragraph style={{ margin: 0 }} ellipsis={{ rows: 1 }}>
        {shortenAddress(title)}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Repeat',
    dataIndex: 'bountyData',
    render: ({ recurrenceTime }: BountyData) => (
      <TagRecurrenceTime time={recurrenceTime} />
    ),
  },
  {
    title: 'Bounty Name',
    dataIndex: 'bountyData',
    render: ({ title }: BountyData) => (
      <Typography.Paragraph
        style={{ margin: 0, maxWidth: '200px' }}
        ellipsis={{ rows: 1 }}
      >
        {title}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'bountyData',
    render: ({ category }: BountyData) => (
      <TagCategory
        style={{ display: 'block', width: '60px' }}
        category={category}
      />
    ),
  },
  {
    title: 'Status',
    dataIndex: '',
    render: ({ state, bountyData }: TableHistoryReportData) => (
      <TagBountyStatus
        state={state}
        isEnded={moment.utc(bountyData.endedAt).isBefore(moment.utc())}
        style={{ display: 'block', width: 'max-content' }}
      />
    ),
  },
  {
    title: 'Reward',
    dataIndex: 'bountyData',
    render: ({ rewardCCP, rewardBadges }: BountyData) => (
      <RewardInfo
        rewardCCP={rewardCCP}
        rewardBadgeIds={rewardBadges.map((e) => e.badgeId)}
      />
    ),
  },
  {
    title: 'Time',
    dataIndex: 'completedAt',
    render: (completedAt: string) => (
      <Typography.Text>
        {moment.utc(completedAt).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
]
