import { useNavigate } from 'react-router-dom'

import { Button, Form, Input, Radio, Row, type FormInstance } from 'antd'

import DateRange from 'components/systems/dateRange'
import { WrapLoading } from 'components/systems/loading'
import UploadImageSync from 'components/systems/uploadPicture/UploadImageSync'

import { useCreateAds } from 'hooks/ads/useCreateAds'
import { useUpdateAds } from 'hooks/ads/useUpdateAds'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import { AdsType } from 'types/ads.type'

type FormAdsProps = {
  isEdit?: boolean
  adsId?: string
  form: FormInstance
  isFetching: boolean
}

function FormAds({
  form,
  isFetching,
  isEdit = false,
  adsId = '',
}: FormAdsProps) {
  const navigate = useNavigate()

  const { mutateAsync: createAds, isLoading: isCreating } = useCreateAds()
  const { mutateAsync: updateAds, isLoading: isUpdating } = useUpdateAds()

  const startedAt = Form.useWatch('startedAt', form)
  const endedAt = Form.useWatch('endedAt', form)

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      if (isEdit) {
        await updateAds({ adsId, payload: values })
      } else {
        await createAds(values)
      }
      notifySuccess(`${isEdit ? 'Updated' : 'Created'} Ads`)
      navigate(ROUTES.ADS.ADS_MANAGEMENT)
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <WrapLoading loading={isFetching}>
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        labelCol={{ span: 5 }}
        labelAlign="left"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="image"
          label="Image"
          rules={[
            {
              required: true,
              message: 'Image is required',
            },
          ]}
          validateTrigger="onChange"
        >
          <UploadImageSync />
        </Form.Item>

        <Form.Item name="isPublished" label="Status" initialValue={true}>
          <Radio.Group>
            <Radio value={true}>Published</Radio>
            <Radio value={false}>Unpublished</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="url"
          label="Ads URL"
          rules={[{ required: true }, { type: 'url' }]}
        >
          <Input placeholder="Enter URL" />
        </Form.Item>

        <Form.Item
          label="Priority"
          name="priority"
          rules={[{ type: 'number' }]}
          getValueFromEvent={(e) => parseInt(e.target.value)}
        >
          <Input type="number" placeholder="Priority" min={0} />
        </Form.Item>

        <Form.Item name="startedAt" rules={[{ required: true }]} hidden />
        <Form.Item name="endedAt" rules={[{ required: true }]} hidden />

        <Form.Item name="type" initialValue={AdsType.DailyCheckin} hidden />

        <Form.Item
          name="adsDateRange"
          label="Date range"
          rules={[
            {
              validator: () => {
                if (!startedAt || !endedAt) {
                  return Promise.reject(
                    new Error('Please select both start and end date'),
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <DateRange
            fromLabel="Start Date (UTC)"
            toLabel="End Date (UTC)"
            startAt={startedAt}
            endAt={endedAt}
            requiredMark={false}
            onChangeStartAt={(value) => {
              form.setFieldsValue({ startedAt: value })
              form.validateFields(['adsDateRange'])
            }}
            onChangeEndAt={(value) => {
              form.setFieldsValue({ endedAt: value })
              form.validateFields(['adsDateRange'])
            }}
          />
        </Form.Item>

        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </Row>
      </Form>
    </WrapLoading>
  )
}

export default FormAds
