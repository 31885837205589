import { useQuery, useQueryClient } from 'react-query'

import { WhitelistService } from 'services/marketplace/whitelist'

import { FindWhitelistWallets } from 'types/whitelist.type'

const ROOT_KEY = 'GET_USER_WHITELIST'
export const useUserWhitelist = (dto: FindWhitelistWallets) => {
  const data = useQuery({
    queryKey: [ROOT_KEY, JSON.stringify(dto)],
    queryFn: async () => WhitelistService.getUserWhitelist(dto),
    enabled: !!dto.whitelistId,
  })

  const queryClient = useQueryClient()
  const refetchRoot = () =>
    queryClient.refetchQueries({
      queryKey: [ROOT_KEY],
    })

  return {
    ...data,
    refetchRoot,
  }
}
