import { Card, Col, Row, Typography } from 'antd'
import { WrapLoading } from 'components/systems/loading'
import SpaceBetween from 'components/systems/spaceBetween'

import { numeric } from 'helper'
import { ReportData } from 'services/bountySystem/report'

type BountyReportProps = {
  reportData: ReportData
  loading?: boolean
}

const BountyReport = ({ reportData, loading = false }: BountyReportProps) => {
  const {
    totalParticipants,
    totalClaim,
    totalNotCompleted,
    totalBadgeReward,
    totalCCPReward,
  } = reportData

  return (
    <WrapLoading loading={loading} type="stick">
      <Card
        className="card-bounty-report"
        bordered={false}
        style={{ borderRadius: 8, background: '#0F191B' }}
      >
        <Row gutter={[0, 0]} className="bounty-rp-wrap">
          {/* total participant */}
          <Col span={24} className="bounty-rp-item">
            <SpaceBetween
              floatContent={
                <Typography.Title level={5}>
                  {numeric(totalParticipants).format('0,0.[00]')}
                </Typography.Title>
              }
            >
              <Typography.Text type="secondary">
                Total participants
              </Typography.Text>
            </SpaceBetween>
          </Col>
          {/* total claim */}
          <Col span={24} className="bounty-rp-item">
            <SpaceBetween
              floatContent={
                <Typography.Title level={5}>
                  {numeric(totalClaim).format('0,0.[00]')}
                </Typography.Title>
              }
            >
              <Typography.Text type="secondary">Total Claim</Typography.Text>
            </SpaceBetween>
          </Col>
          {/* bounty not completed */}
          <Col span={24} className="bounty-rp-item">
            <SpaceBetween
              floatContent={
                <Typography.Title level={5}>
                  {numeric(totalNotCompleted).format('0,0.[00]')}
                </Typography.Title>
              }
            >
              <Typography.Text type="secondary">
                Bounty not completed
              </Typography.Text>
            </SpaceBetween>
          </Col>
          {/* total cpp reward */}
          <Col span={24} className="bounty-rp-item">
            <SpaceBetween
              floatContent={
                <Typography.Title level={5}>
                  {numeric(totalCCPReward).format('0,0.[00]')}
                </Typography.Title>
              }
            >
              <Typography.Text type="secondary">
                Total CCP reward
              </Typography.Text>
            </SpaceBetween>
          </Col>
          {/* total badge reward */}
          <Col span={24} className="bounty-rp-item">
            <SpaceBetween
              floatContent={
                <Typography.Title level={5}>
                  {numeric(totalBadgeReward).format('0,0.[00]')}
                </Typography.Title>
              }
            >
              <Typography.Text type="secondary">
                Total NFT reward
              </Typography.Text>
            </SpaceBetween>
          </Col>
        </Row>
      </Card>
    </WrapLoading>
  )
}

export default BountyReport
