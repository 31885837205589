import { Col, Row, Typography } from 'antd'

import ListTeam from './listTeam'

const TeamManagement = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Affiliation Team Management
        </Typography.Title>
      </Col>

      <Col span={24}>
        <ListTeam />
      </Col>
    </Row>
  )
}

export default TeamManagement
