import MobileView from './mobileView'
import SidebarContainer from './sidebarContainer'

import useIsMobile from 'hooks/systems/useIsMobile'

import './index.less'

const Sidebar = () => {
  const isMobile = useIsMobile()

  return isMobile ? <MobileView /> : <SidebarContainer />
}

export default Sidebar
