import { useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { Col, Row, Table, Typography } from 'antd'

import ToolBar from './toolBar'
import CustomPagination from 'components/systems/pagination'
import { COLUMN_NFT_METADATA } from './column'

import { useNftsMetadata } from 'hooks/nftMetadata/useNftsMedatada'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

const ListNFTMetadata = () => {
  const nftId = useParams().nftId
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(searchParams.get('page') ?? DEFAULT_PAGE)

  const setPage = useCallback(
    (_page: number) => {
      setSearchParams({ page: _page.toString() })
    },
    [setSearchParams],
  )

  const {
    data,
    total,
    isFetching: loading,
  } = useNftsMetadata({
    nftId,
    limit: DEFAULT_PAGE_SIZE,
    offset: DEFAULT_PAGE_SIZE * (page - 1),
  })

  return (
    <Row gutter={[24, 64]}>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <ToolBar />
          </Col>
          <Col span={24}>
            <Table
              rowKey="_id"
              className="table-border-collapse"
              columns={COLUMN_NFT_METADATA}
              dataSource={data}
              pagination={false}
              loading={loading}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          <Col>
            <CustomPagination
              total={total}
              pageSize={DEFAULT_PAGE_SIZE}
              onChange={setPage}
              page={page}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ListNFTMetadata
