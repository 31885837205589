import {
  Button,
  Checkbox,
  Col,
  Divider,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

import { useSubscriptionTabSelected } from '../index'

import {
  AffiliationSubscriptionStatus,
  AffiliationStatusLabel,
  SortOptions,
} from 'types/affiliation.type'
import { SortFilterProps } from '../listSubscriptionOverall/index'

import { SubscriptionTab } from 'constant/affiliation'

const FILTER_OPTIONS = [
  {
    label: AffiliationStatusLabel[AffiliationSubscriptionStatus.PremiumPackage],
    value: AffiliationSubscriptionStatus.PremiumPackage,
  },
  {
    label:
      AffiliationStatusLabel[AffiliationSubscriptionStatus.ExclusivePackage],
    value: AffiliationSubscriptionStatus.ExclusivePackage,
  },
  {
    label: AffiliationStatusLabel[AffiliationSubscriptionStatus.AdminActivates],
    value: AffiliationSubscriptionStatus.AdminActivates,
  },
  {
    label: AffiliationStatusLabel[AffiliationSubscriptionStatus.Inactive],
    value: AffiliationSubscriptionStatus.Inactive,
  },
]

const SORT_OPTIONS = [
  {
    label: 'Recently activated',
    value: SortOptions.RecentlyActivated,
  },
  {
    label: 'Going to be expired',
    value: SortOptions.GoingToBeExpired,
  },
  {
    label: 'Total money spent (decrease)',
    value: SortOptions.TotalMoneySpentDecrease,
  },
  {
    label: 'Total money spent (increase)',
    value: SortOptions.TotalMoneySpentIncrease,
  },
]

type FilterDropdownProps = {
  sortFilter: SortFilterProps
  handleSortFilter: (key: string, value: string | string[]) => void
}

const FilterDropdown = ({
  sortFilter,
  handleSortFilter,
}: FilterDropdownProps) => {
  const [currentTab] = useSubscriptionTabSelected()

  const title =
    currentTab === SubscriptionTab.Overall ? 'Sort & Filter' : 'Filter'

  return (
    <Row gutter={[0, 24]} onClick={(e) => e.stopPropagation()}>
      <Col span={24}>
        <Typography.Title level={4} type="success">
          {title}
        </Typography.Title>
        <Divider />
      </Col>

      {currentTab === SubscriptionTab.Overall && (
        <Col span={24}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Typography.Text style={{ color: '#AFB5C2' }}>
                Sort
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Radio.Group
                onChange={(e) => handleSortFilter('sortOption', e.target.value)}
                defaultValue={SortOptions.RecentlyActivated}
              >
                <Space direction="vertical" size={16}>
                  {SORT_OPTIONS.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Typography.Text style={{ color: '#AFB5C2' }}>
              Filter
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              onChange={(checkedValue: CheckboxValueType[]) =>
                handleSortFilter('filterOptions', checkedValue as string[])
              }
              value={sortFilter.filterOptions}
            >
              <Space direction="vertical" size={16}>
                {FILTER_OPTIONS.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    {option.label}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Button className="btn-reset">Reset Filter</Button>
      </Col>
    </Row>
  )
}

export default FilterDropdown
