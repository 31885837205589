import { useState } from 'react'

import { Button, ButtonProps, Col, Row } from 'antd'

type CategoryActionProps = {
  leftButton: ButtonProps
  rightButton: ButtonProps
}
const CategoryAction = ({ leftButton, rightButton }: CategoryActionProps) => {
  const [loading, setLoading] = useState(false)

  const handleRightClick = async (e: any) => {
    if (!rightButton.onClick) return
    try {
      setLoading(true)
      await rightButton.onClick(e)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row gutter={[24, 24]}>
      <Col flex="auto">
        <Button type="primary" ghost {...leftButton}>
          {leftButton.title}
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          {...rightButton}
          loading={loading}
          onClick={handleRightClick}
        >
          {rightButton.title}
        </Button>
      </Col>
    </Row>
  )
}

export default CategoryAction
