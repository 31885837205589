import { ModalProvider } from './CreateUpdateContext'
import CreateUpdateModal from './CreateUpdateModal'
import WhitelistManagement from './WhitelistManagement'

import './index.less'

function WhitelistWrapper() {
  return (
    <ModalProvider>
      <WhitelistManagement />
      <CreateUpdateModal />
    </ModalProvider>
  )
}

export default WhitelistWrapper
