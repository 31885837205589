import { bountySystemApi as api } from 'services/base-axios'

import { FindCommunityDto } from 'services/bountySystem/community/type'
import { DataList } from 'types'
import {
  CreateCommunityDto,
  ICommunity,
  UpdateCommunity,
} from 'types/community.type'

export class Community {
  private index = '/community'

  async find(params: FindCommunityDto) {
    const { data } = await api.get<DataList<ICommunity[]>>(
      this.index + '/list',
      { params },
    )
    return data
  }

  async findById(communityId: string) {
    const { data } = await api.get<ICommunity>(`${this.index}/${communityId}`)
    return data
  }

  async createCommunity(data: CreateCommunityDto): Promise<ICommunity> {
    return api.post(this.index, data).then((data) => data.data)
  }

  async editCommunity(
    communityId: string,
    data: UpdateCommunity,
  ): Promise<ICommunity> {
    return api
      .patch(`${this.index}/${communityId}`, data)
      .then((data) => data.data)
  }
}

export const CommunityService = new Community()
