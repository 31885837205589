import { useState } from 'react'

import { Button, Col, Row, Space, Typography } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import CreateNFTPopUp from '../createNft'

import { useListNfts } from 'hooks/nftService/useListNfts'

const ToolBar = () => {
  const [open, setOpen] = useState(false)
  const { refetchRoot } = useListNfts({}, false)
  return (
    <Row align="middle" gutter={[24, 24]} justify="space-between">
      <Col span={8}>
        {/* TODO: implement later */}
        {/* <SearchBar setSearch={setSearch} placeholder="Search season name..." /> */}
      </Col>
      <Col>
        <Button type="primary" onClick={() => setOpen(true)}>
          <Space>
            <IonIcon name="add" />
            <Typography.Text strong>Create New NFT</Typography.Text>
          </Space>
        </Button>
      </Col>
      <CreateNFTPopUp
        open={open}
        setOpen={setOpen}
        saveCallback={refetchRoot}
      />
    </Row>
  )
}

export default ToolBar
