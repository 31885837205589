import { TaskConfigs, TaskKeys, TaskType } from 'templates/types'

export const useTaskKey = (type: TaskType, configs: TaskConfigs) => {
  const taskConfig: any = configs
  let taskKey = type.toString().toLowerCase()
  taskKey = [TaskType.Social, TaskType.Partner].includes(type)
    ? `${taskKey}-${taskConfig?.action?.toLowerCase().replace(':', '-')}`
    : taskConfig?.action
    ? `${taskKey}-${taskConfig.action}`
    : taskKey

  return taskKey as TaskKeys
}
