import { useEffect, useState } from 'react'

import UploadPicture from '.'

import { useStorage } from 'hooks/systems/useStorage'
import useHandle from 'hooks/useHandle'

import { notifyError } from 'helper'

import { FILE_SIZE_ONE_MB } from 'constant/marketplace'

import { type UploadChangeParam } from 'antd/lib/upload'
import { type UploadFile } from 'antd/lib/upload/interface'

type UploadImageSyncProps = {
  value?: string
  onChange?: (value?: string) => void
}

function UploadImageSync({ value, onChange }: UploadImageSyncProps) {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<UploadFile[]>()

  const { onUpload } = useStorage(604800)

  const onChangeFile = useHandle(async ({ fileList }: UploadChangeParam) => {
    if (!fileList) return
    const fileChange = fileList[0]

    if (!fileChange) return setFile([])

    try {
      setLoading(true)
      if (!fileChange.size || fileChange.size > 20 * FILE_SIZE_ONE_MB) {
        return notifyError('Cannot upload images over 20MB.')
      }

      const thumbnails = await onUpload(fileChange)

      if (onChange && thumbnails) onChange(thumbnails)

      setFile([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: thumbnails,
        },
      ])
    } catch (e) {
      notifyError('Upload image failed.')
      if (onChange) onChange(undefined)

      setFile([])
    } finally {
      setLoading(false)
    }
  }, setLoading)

  useEffect(() => {
    if (value && !file) {
      setFile([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: value,
        },
      ])
    }
  }, [value, file])

  return (
    <UploadPicture
      fileList={file}
      onChangeFile={(file) => onChangeFile(file)}
      maxCount={1}
      loading={loading}
    />
  )
}

export default UploadImageSync
