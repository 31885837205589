import { useMutation } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { UpdatePlayerSeasonDto } from 'types/player-leaderboard/player-leaderboard-season.type'

export const useUpdatePlayerSeasonMutation = () => {
  return useMutation(
    ({ dto, seasonId }: { dto: UpdatePlayerSeasonDto; seasonId: number }) =>
      PlayerLeaderboardService.updateSeason(seasonId, dto),
  )
}
