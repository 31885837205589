import { useInfiniteQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { DEFAULT_PAGE_SIZE } from 'constant'

import { FindAcceler8SeasonDto } from 'types/acceler8/acceler8-season.type'

export const useAcceler8SeasonsInfinite = ({
  offset,
  limit = DEFAULT_PAGE_SIZE,
  search,
}: FindAcceler8SeasonDto | undefined = {}) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['GET_ACCELER8_SEASONS_INFINITIE', { offset, limit, search }],
    queryFn: async ({ pageParam: page = 1 }) => {
      const { data, total } = await Acceler8Service.findSeasons({
        search,
        offset: (page - 1) * limit,
        limit,
      })

      return {
        nextPage: page < Math.ceil(total / limit) ? page + 1 : undefined,
        data,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
  })

  const seasonsData = data?.pages.flatMap((page) => page.data)

  return {
    ...rest,
    data: seasonsData ?? [],
  }
}
