import { generatePath, useNavigate } from 'react-router-dom'

import { Popconfirm, Space } from 'antd'
import {
  DeleteOutlined as UnpublishedIcon,
  EditOutlined as EditIcon,
  UndoOutlined as RepublishIcon,
} from '@ant-design/icons'

import ButtonIcon from 'components/systems/button-base/ButtonIcon'
import ActionExport from './ActionExport'

import { useGachaList } from 'hooks/gacha/useGachaList'
import {
  useRepublishGacha,
  useUnpublishedGacha,
} from 'hooks/gacha/useUpdateGacha'

import { ROUTES } from 'constant/routes'

import type { IGachaBaseInfo } from 'types/gacha.type'

import './index.less'

type GachaRowActionProps = {
  record: IGachaBaseInfo
}

function GachaRowAction({ record }: GachaRowActionProps) {
  const gachaId = record?._id
  const isActive = !!record?.isActive

  const navigate = useNavigate()

  const { mutateAsync: unpublishedGacha } = useUnpublishedGacha()
  const { mutateAsync: republishGacha } = useRepublishGacha()
  const { refetchRoot } = useGachaList()

  return (
    <Space size={0}>
      {isActive && (
        <Popconfirm
          title="Are you sure to Unpublish?"
          onConfirm={async () => {
            await unpublishedGacha(gachaId ?? '')
            await refetchRoot()
          }}
        >
          <ButtonIcon
            icon={<UnpublishedIcon />}
            wrapperCls="gacha-row-action-btn"
          />
        </Popconfirm>
      )}

      {!isActive && (
        <Popconfirm
          title="Are you sure to Republish?"
          onConfirm={async () => {
            await republishGacha(gachaId ?? '')
            await refetchRoot()
          }}
        >
          <ButtonIcon
            icon={<RepublishIcon rotate={75} />}
            wrapperCls="gacha-row-action-btn"
          />
        </Popconfirm>
      )}

      <ButtonIcon
        icon={<EditIcon />}
        onClick={() =>
          navigate(generatePath(ROUTES.GACHA.EDIT_GACHA, { gachaId }))
        }
        wrapperCls="gacha-row-action-btn"
      />

      <ActionExport gacha={record} />
    </Space>
  )
}

export default GachaRowAction
