import { useCallback } from 'react'

import { ChainID } from 'constant'
import { useChains } from 'hooks/chain/useChain'

const usePaymentCurrencies = () => {
  const chainConfig = useChains()

  const getPaymentCurrencies = useCallback(
    (chainId: ChainID) => {
      return chainConfig[chainId].paymentCurrencies.map(
        (currency) => currency.symbol,
      )
    },
    [chainConfig],
  )

  return getPaymentCurrencies
}

export default usePaymentCurrencies
