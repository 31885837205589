import { useQuery } from 'react-query'
import numbro from 'numbro'

import { Card, Col, Row, Typography } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'

import { ReportService } from 'services/bountySystem/report'

const Hero = () => {
  const { data } = useQuery(`summary-report:get-daily`, () =>
    ReportService.getAllDailyReport(),
  )

  return (
    <Card bodyStyle={{ padding: 32, background: '#0F191B' }} bordered={false}>
      <Row align="middle">
        <Col
          md={8}
          style={{ textAlign: 'center', borderRight: 'solid 2px #1B2526' }}
        >
          <SpaceVertical>
            <Typography.Text>Total streak</Typography.Text>
            <Typography.Title type="success" level={2}>
              {numbro(data?.totalStreak).format('0,0.[000]')}
            </Typography.Title>
          </SpaceVertical>
        </Col>
        <Col
          md={8}
          style={{ textAlign: 'center', borderRight: 'solid 2px #1B2526' }}
        >
          <SpaceVertical>
            <Typography.Text>Daily check-in by user </Typography.Text>
            <Typography.Title type="success" level={2}>
              {numbro(data?.usersCheckInDaily).format('0,0.[000]')}
            </Typography.Title>
          </SpaceVertical>
        </Col>
        <Col md={8} style={{ textAlign: 'center' }}>
          <SpaceVertical>
            <Typography.Text>Streak completion by user</Typography.Text>
            <Typography.Title type="success" level={2}>
              {numbro(data?.totalCompletedStreak).format('0,0.[000]')}
            </Typography.Title>
          </SpaceVertical>
        </Col>
      </Row>
    </Card>
  )
}

export default Hero
