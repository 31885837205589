import { CSSProperties, Dispatch, SetStateAction } from 'react'

import { Input } from 'antd'

import IonIcon from 'components/systems/ionIcon'

type SearchProps = {
  style?: CSSProperties
  placeholder?: string
  setSearch: Dispatch<SetStateAction<string>>
}

const FilterSearch = ({ style, placeholder = '', setSearch }: SearchProps) => {
  return (
    <Input
      style={style}
      placeholder={placeholder}
      onChange={(e) => setSearch(e.target.value)}
      suffix={<IonIcon name="search-outline" />}
    />
  )
}

export default FilterSearch
