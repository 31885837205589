import { Fragment, ReactNode, useState } from 'react'

import CommonPopup from './commonPopup'

import { UserShippingAction } from 'constant/marketplace'

type ChangeStateProps = {
  onConfirm: () => void
  children: ReactNode
  title: UserShippingAction
}

const ChangeState = ({
  onConfirm,

  children,
  title,
}: ChangeStateProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <span onClick={() => setOpen(true)}>{children}</span>
      <CommonPopup
        open={open}
        onCancel={() => setOpen(false)}
        title={title}
        onConfirm={() => {
          onConfirm()
          setOpen(false)
        }}
        maskClosable
        destroyOnClose
      />
    </Fragment>
  )
}

export default ChangeState
