import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Button, Form, Input, Modal, Row, Typography } from 'antd'
import { FormInstance } from 'antd/es/form/hooks/useForm'

import InputImage from 'components/systems/uploadPicture/inputImage'

import { CommunityFeatured } from 'services/bountySystem/featuredConfig/type'

type FormCommunityFeaturedProps = {
  form?: FormInstance
  isEdit?: boolean
  setIsEdit?: Dispatch<SetStateAction<boolean>>
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
  editData?: CommunityFeatured
  setEditData?: Dispatch<SetStateAction<CommunityFeatured | undefined>>
  onSubmit: (values: CommunityFeatured) => void
}

function FormCommunityFeatured({
  form,
  isEdit,
  setIsEdit,
  openModal,
  setOpenModal,
  editData,
  setEditData,
  onSubmit,
}: FormCommunityFeaturedProps) {
  const [coverImageUrl, setCoverImageUrl] = useState<string | undefined>('')
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>('')

  const handleChangeCoverImageUrl = (value: string) => {
    setCoverImageUrl(value)
  }

  const handleChangeThumbnailUrl = (value: string) => {
    setThumbnailUrl(value)
  }

  const handleCancel = () => {
    setOpenModal(false)
    setIsEdit && setIsEdit(false)
    setEditData && setEditData(undefined)
    setCoverImageUrl('')
    setThumbnailUrl('')
    form?.resetFields()
  }

  const handleSubmit = (values: {
    communityId: string
    normalizeTitle: string
    highlightTitle: string
  }) => {
    const payload: CommunityFeatured = {
      communityId: values.communityId,
      communityTheme: {
        thumbnail: thumbnailUrl ?? '',
        coverImage: coverImageUrl ?? '',
        normalizeTitle: values.normalizeTitle,
        highlightTitle: values.highlightTitle,
      },
    }

    onSubmit(payload)
    handleCancel()
  }

  useEffect(() => {
    if (isEdit) {
      setCoverImageUrl(editData?.communityTheme?.coverImage)
      setThumbnailUrl(editData?.communityTheme?.thumbnail)
      form?.setFields([
        { name: 'communityId', value: editData?.communityId },
        {
          name: 'normalizeTitle',
          value: editData?.communityTheme?.normalizeTitle,
        },
        {
          name: 'highlightTitle',
          value: editData?.communityTheme?.highlightTitle,
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, editData])

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      footer={null}
      forceRender
      destroyOnClose
    >
      <Typography.Title level={4} type="success" style={{ marginBottom: 22 }}>
        {isEdit ? 'Edit' : 'Add'} Featured Community
      </Typography.Title>

      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 24 }}
        labelAlign="left"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Community ID"
          name="communityId"
          required
          rules={[{ required: true, message: 'Community ID is required!' }]}
        >
          <Input disabled={isEdit} />
        </Form.Item>
        <Form.Item
          label="Normalize Title"
          name="normalizeTitle"
          required
          rules={[{ required: true, message: 'Normalize Title is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Highlight Title"
          name="highlightTitle"
          required
          rules={[{ required: true, message: 'Highlight Title is required!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Cover Image"
          name="coverImage"
          required
          rules={[
            {
              validator: () => {
                if (!coverImageUrl || coverImageUrl.length === 0) {
                  return Promise.reject(new Error('Cover Image is required!'))
                }

                return Promise.resolve()
              },
            },
          ]}
        >
          <InputImage
            key="upload-cover-image"
            value={coverImageUrl}
            onChange={handleChangeCoverImageUrl}
          />
          <Input
            value={coverImageUrl}
            onChange={(e) => handleChangeCoverImageUrl(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          required
          rules={[
            {
              validator: () => {
                if (!thumbnailUrl || thumbnailUrl.length === 0) {
                  return Promise.reject(new Error('Thumbnail is required!'))
                }

                return Promise.resolve()
              },
            },
          ]}
        >
          <InputImage
            key="upload-thumbnail"
            value={thumbnailUrl}
            onChange={handleChangeThumbnailUrl}
          />
          <Input
            value={thumbnailUrl}
            onChange={(e) => handleChangeThumbnailUrl(e.target.value)}
          />
        </Form.Item>

        <Row justify="space-between" style={{ padding: '0 20px' }}>
          <Button
            type="primary"
            style={{ width: 129, borderRadius: 2 }}
            onClick={handleCancel}
            danger
          >
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{ width: 129, borderRadius: 2 }}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

export default FormCommunityFeatured
