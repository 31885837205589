import { useParams } from 'react-router-dom'

import { Col, Row, Tabs, Typography } from 'antd'
import Hero from './hero'
import OnChain from './onChain'
import OffChain from './offChain'

import { useBadgeData } from 'hooks/badges/useBadges'

const TAB_ITEMS = [
  { label: 'On-chain', key: 'on-chain', children: <OnChain /> },
  { label: 'Off-chain', key: 'off-chain', children: <OffChain /> },
]

const BadgeDetails = () => {
  const badgeId = useParams().itemId || ''
  const { badgeData } = useBadgeData(badgeId)

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {badgeData.title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Hero badgeId={badgeId} badgeConfig={badgeData.configs} />
      </Col>
      <Col span={24}>
        <Tabs items={TAB_ITEMS} />
      </Col>
    </Row>
  )
}

export default BadgeDetails
