import { useNavigate } from 'react-router-dom'

import { Button, Col, Input, Row } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'
import { useSearchKey } from '../index'

const Header = () => {
  const [search, setSearch] = useSearchKey()

  const navigate = useNavigate()

  return (
    <Row>
      <Col flex="auto">
        <Input
          suffix={
            <Button
              onClick={() => setSearch('')}
              type="text"
              style={{ width: 'auto', height: 'auto', padding: 0 }}
              icon={
                <IonIcon
                  style={{ cursor: 'pointer', fontSize: 18, color: '#42BAB0' }}
                  name={search ? 'close-outline' : 'search-outline'}
                />
              }
            />
          }
          className="search-nft"
          placeholder="Search Nft"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 245, height: 36, borderRadius: 4 }}
        />
      </Col>
      <Col>
        <Button
          className="admin-primary-btn"
          icon={<IonIcon name="add-outline" />}
          type="primary"
          onClick={() => navigate(ROUTES.NFT_BADGE.ADD_NEW)}
        >
          Create NFT Badge
        </Button>
      </Col>
    </Row>
  )
}

export default Header
