import { useMemo } from 'react'

import { Col, Row, Typography } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'

import { PRODUCT_TYPE_LABEL } from 'types/product.type'
import { useMapOrderPrice } from 'hooks/order/useMapOrderPrice'
import { OrderData } from 'types/order.type'

const Content = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => (
  <SpaceVertical>
    <Typography.Text type="secondary">{label}</Typography.Text>
    <Typography.Text style={{ color: '#B2BFCB' }}>{value}</Typography.Text>
  </SpaceVertical>
)

const OrderMetadata = ({ orderData }: { orderData: OrderData }) => {
  const product = orderData.productData
  const mapPrice = useMapOrderPrice(orderData)

  const priceLabel = useMemo(() => {
    let label = ''
    mapPrice.forEach((price, index) => {
      if (index === mapPrice.length - 1) return (label += price)
      return (label += price + ' & ')
    })
    return label
  }, [mapPrice])

  return (
    <Row gutter={[24, 24]}>
      <Col sm={24} xs={12} md={8}>
        <Content label="Price" value={priceLabel} />
      </Col>
      <Col sm={24} xs={12} md={8}>
        <Content label="Quantity" value={orderData.quantity} />
      </Col>
      <Col sm={24} xs={12} md={8}>
        <Content label="Type" value={PRODUCT_TYPE_LABEL[product.productType]} />
      </Col>
    </Row>
  )
}

export default OrderMetadata
