import { bountySystemApi as api } from 'services/base-axios'

import {
  FindFeaturedDto,
  CreateFeaturedDto,
} from 'services/bountySystem/featuredConfig/type'

export class Featured {
  private index = '/featured'

  async find(params: FindFeaturedDto) {
    const { data } = await api.get(this.index, {
      params,
    })
    return data
  }

  async create(params: CreateFeaturedDto) {
    const { data } = await api.post(this.index, {
      ...params,
    })
    return data
  }
}

export const FeaturedService = new Featured()
