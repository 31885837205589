import { useState } from 'react'

import { Col, Row, Table, Typography } from 'antd'

import ControlBar from '../controlBar'
import { COLUMN_ACTIVITIES } from '../table/column'
import { WrapLoading } from 'components/systems/loading'
import CustomPagination from 'components/systems/pagination'

import {
  DEFAULT_FILTER_ACTIVITY_DATA,
  useSubscriptionActivity,
} from 'hooks/affiliation/useSubscriptionActitivy'
import { usePagination } from 'hooks/usePagination'

import { AffiliationSubscriptionStatus } from 'types/affiliation.type'

export type FilterProps = {
  filterOptions: AffiliationSubscriptionStatus[]
  searchValue: string
}

const ListSubscriptionActivity = () => {
  const [sortFilter, setSortFilter] = useState<FilterProps>(
    DEFAULT_FILTER_ACTIVITY_DATA,
  )
  const { page, offset, limit, setPage } = usePagination()

  const { data, total, isLoading } = useSubscriptionActivity({
    limit,
    offset,
    search: sortFilter.searchValue,
    filter: sortFilter.filterOptions,
  })

  const handleFilter = (key: string, value: string | string[]) => {
    setSortFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <ControlBar sortFilter={sortFilter} handleSortFilter={handleFilter} />
      </Col>
      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <Table
            rowKey="_id"
            columns={COLUMN_ACTIVITIES}
            dataSource={data}
            pagination={false}
          />
        </WrapLoading>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!data?.length ? (
            <Col>
              <CustomPagination total={total} onChange={setPage} page={page} />
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}

export default ListSubscriptionActivity
