import { Image, Typography } from 'antd'

import RowAction from './rowAction'

export const teamColumn = [
  {
    title: 'Rank',
    dataIndex: 'currentRank',
    render: (currentRank: number) => (
      <Typography.Text>{currentRank}</Typography.Text>
    ),
  },
  {
    title: 'Last rank',
    dataIndex: 'lastRank',
    render: (lastRank: number) => <Typography.Text>{lastRank}</Typography.Text>,
  },
  {
    title: 'Avatar',
    dataIndex: 'logo',
    render: (logo: string) => <Image src={logo} width={50} height={50} />,
  },
  {
    title: 'Info',
    dataIndex: 'teamName',
    render: (teamName: string) => <Typography.Text>{teamName}</Typography.Text>,
  },
  {
    title: 'Point',
    dataIndex: 'point',
    render: (point: number) => <Typography.Text>{point}</Typography.Text>,
  },
  {
    title: 'Action',
    dataIndex: '_id',
    render: (id: string) => <RowAction teamId={id} />,
  },
]
