import { useCallback, useMemo } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { Col, Row, Table, Typography } from 'antd'

import ToolBar from './toolBar'
import CustomPagination from 'components/systems/pagination'

import { useListNfts } from 'hooks/nftService/useListNfts'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { ROUTES } from 'constant/routes'
import { COLUMN_NFT } from './column'

const ListNFT = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(searchParams.get('page') ?? DEFAULT_PAGE)

  const setPage = useCallback(
    (_page: number) => {
      setSearchParams({ page: _page.toString() })
    },
    [setSearchParams],
  )
  const {
    data,
    total,
    isLoading: loading,
  } = useListNfts({
    limit: DEFAULT_PAGE_SIZE,
    offset: DEFAULT_PAGE_SIZE * (page - 1),
  })

  const totalPage = useMemo(() => {
    return Math.ceil(total / DEFAULT_PAGE_SIZE)
  }, [total])

  return (
    <Row gutter={[24, 64]}>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <ToolBar />
          </Col>
          <Col span={24}>
            <Table
              rowKey="_id"
              className="table-border-collapse"
              columns={COLUMN_NFT}
              dataSource={data}
              onRow={({ _id: nftId }) => {
                return {
                  onClick: () => {
                    navigate(
                      generatePath(ROUTES.NFT_SERVICE.VIEW_NFT, {
                        nftId,
                      }),
                    )
                  },
                }
              }}
              pagination={false}
              loading={loading}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {totalPage}</Typography.Text>
          </Col>
          <Col>
            <CustomPagination
              total={total}
              pageSize={DEFAULT_PAGE_SIZE}
              onChange={setPage}
              page={page}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ListNFT
