export enum ProgramType {
  Team = 'PROGRAM_TYPE::TEAM',
  Player = 'PROGRAM_TYPE::PLAYER',
}

export interface IAcceler8Program {
  id: number

  points: number

  subscriptionId: number

  type: ProgramType

  createdAt: string

  updatedAt: string
}

export type TotalPointsOfProgram = {
  programId: number
  totalTaskPoints: number
}

export type TotalParticipantsOfProgram = {
  programId: number
  participants: number
}

export type CreateAcceler8ProgramDto = {
  subscriptionId: number

  points: number

  type: ProgramType
}

export type UpdateAcceler8ProgramDto = Partial<
  Omit<CreateAcceler8ProgramDto, 'type'>
>
