import { Typography } from 'antd'
import { shortenAddress } from 'helper'

export const COLUMNS = [
  {
    title: 'User ID',
    dataIndex: 'uid',
    render: (uid: string) => (
      <Typography.Text style={{ color: '#B2BFCB' }}>
        {shortenAddress(uid)}
      </Typography.Text>
    ),
  },
]
