import { bountySystemApi as api } from 'services/base-axios'

import { ChainID, DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import { BadgeData } from './items'

const CONTROLLER = '/cheque'

class Cheque {
  async getAll({
    page: offset = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    badgeId,
    state,
    owner,
  }: {
    page?: number
    pageSize?: number
    badgeId?: string
    state?: ChequeState
    owner?: string
  }) {
    return api
      .get(CONTROLLER, {
        params: {
          offset: (offset - 1) * pageSize,
          limit: pageSize,
          badgeId,
          state,
          owner,
        },
      })
      .then((res) => res.data)
  }
  async count({ filter }: { filter: Partial<FilterProps> }) {
    const params = { ...filter }
    return api
      .get(`${CONTROLLER}/count`, {
        params,
      })
      .then((res) => res.data)
  }
}

export const ChequeService = new Cheque()

export type FilterProps = {
  badgeId: string
  chainId: ChainID
  isUsed: boolean
}

export type ChequeData = {
  _id: string
  badgeId: BadgeData
  state: ChequeState
  uid: string
  createdAt: string
  updatedAt: string
  isUsed: boolean
  walletAddress?: string
  chainId?: ChainID
}

export enum ChequeState {
  Initialized = 'CHEQUE_STATE:INITIALIZE',
  Processing = 'CHEQUE_STATE:PROCESSING',
  Redeemed = 'CHEQUE_STATE:REDEEMED',
}
