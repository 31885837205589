import { Form, Modal, Typography } from 'antd'

import SaveNftForm from './form'

import { CreateNftDto } from 'types/nft.type'

type CreateNFTPopUpProps = {
  open: boolean
  setOpen: (open: boolean) => void
  saveCallback: () => Promise<void>
}

const CreateNFTPopUp = ({
  open,
  setOpen,
  saveCallback,
}: CreateNFTPopUpProps) => {
  const [form] = Form.useForm<CreateNftDto>()

  return (
    <Modal
      open={open}
      onCancel={() => {
        setOpen(false)
        form.resetFields()
      }}
      footer={null}
      width={600}
    >
      <Typography.Title
        level={3}
        type="success"
        style={{ textAlign: 'center', marginBottom: 16 }}
      >
        Create New NFT
      </Typography.Title>
      <SaveNftForm
        form={form}
        hideModal={() => setOpen(false)}
        saveCallback={async () => {
          form.resetFields()
          await saveCallback()
        }}
      />
    </Modal>
  )
}

export default CreateNFTPopUp
