import { createContext } from 'react'

import { ApplicationType } from 'types/multiplier.type'

interface ContextType {
  applicationType: ApplicationType
  selectedApplications: string[]
  search: string
  setSelectedApplications: (value: string[]) => void
  setSearch: (value: string) => void
}

export const ApplicationFrameContext = createContext<ContextType>({
  applicationType: ApplicationType.Bounty,
  selectedApplications: [],
  search: '',
  setSelectedApplications: () => {},
  setSearch: () => {},
})
