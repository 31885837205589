import React, { useState } from 'react'

import { Button, Col, Form, type FormInstance, Menu, Popover, Row } from 'antd'

import RewardItemProvider from './reward-item'

import { GachaRewardType } from 'types/gacha.type'

const gachaRewardOptions = Object.keys(GachaRewardType).map((key) => ({
  label: `Add ${key}`,
  key: GachaRewardType[key as keyof typeof GachaRewardType],
}))

function GachaRewardSection({ form }: { form: FormInstance }) {
  const [openPopover, setOpenPopover] = useState(false)
  const rewards = Form.useWatch('rewards', form)

  return (
    <Form.Item label="Rewards">
      <Form.List name="rewards">
        {(fields, { add, remove }) => {
          return (
            <Row gutter={[0, 16]}>
              {fields.map((field) => (
                <RewardItemProvider
                  key={field.name}
                  form={form}
                  prefixNamePath={field.name}
                  type={rewards?.[field.name]?.type ?? ''}
                  onRemove={() => remove(field.name)}
                />
              ))}

              <Col span={24}>
                <Popover
                  content={
                    <Menu
                      items={gachaRewardOptions}
                      onClick={({ key }) => {
                        add({ type: key })
                        setOpenPopover(false)
                      }}
                      mode="vertical"
                      onPointerLeave={() => setOpenPopover(false)}
                    />
                  }
                  showArrow={false}
                  open={openPopover}
                  overlayClassName="gacha--popover--add-reward"
                >
                  <Button
                    className="btn-dashed"
                    block
                    onClick={() => setOpenPopover((prev) => !prev)}
                  >
                    Add Reward
                  </Button>
                </Popover>
              </Col>
            </Row>
          )
        }}
      </Form.List>
    </Form.Item>
  )
}

export default GachaRewardSection
