import { useDispatch, useSelector } from 'react-redux'

import { Button, Col, Row, Space } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import Brand from 'components/systems/brand'
import UserInfo from './userInfo'
import Notifications from './notifications'
import { CommunityToggle } from './communityToggle'

import { AppState } from 'store'
import { setVisible } from 'store/sidebar.reducer'

const HeaderContainer = () => {
  const dispatch = useDispatch()
  const visible = useSelector(({ sidebar }: AppState) => sidebar.visible)

  return (
    <Row gutter={[0, 24]} justify="space-between" wrap={false} align="middle">
      <Col style={{ display: 'flex' }}>
        <Space className="space-middle-icon" size={12}>
          <Button
            type="text"
            style={{ padding: 0, width: 'auto', height: 'auto' }}
            onClick={() => dispatch(setVisible(!visible))}
            icon={<IonIcon style={{ fontSize: 24 }} name="menu-outline" />}
          />
          <Brand />
          <CommunityToggle />
        </Space>
      </Col>
      <Col>
        <Space size={24}>
          <Notifications />
          <UserInfo />
        </Space>
      </Col>
    </Row>
  )
}

export default HeaderContainer
