import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { FindLatestPlayerSeasonsDto } from 'types/player-leaderboard/player-leaderboard-season.type'

export const useLatestPlayerSeasons = (dto: FindLatestPlayerSeasonsDto) => {
  const { data, ...rest } = useQuery({
    queryFn: () => PlayerLeaderboardService.findLatestSeasons(dto),
    queryKey: ['GET_LATEST_PLAYER_SEASONS', dto],
  })
  return {
    ...rest,
    data: data ?? [],
  }
}
