import React, { useState } from 'react'

import { Col, Empty, Row, Typography } from 'antd'
import CustomPagination from 'components/systems/pagination'
import SpaceBetween from 'components/systems/spaceBetween'

import { DEFAULT_PAGE } from 'constant'
import CardCollection from 'view/admin/marketplace/collectionManagement/listCollection/CardCollection'
import { useCollections } from 'hooks/collection/useCollections'
import {
  useChainCollections,
  useSearchCollections,
} from 'view/admin/marketplace/collectionManagement/controlBar'
import { WrapLoading } from 'components/systems/loading'

const PAGE_SIZE = 18

const ListCollection = () => {
  const [search] = useSearchCollections()
  const [chainId] = useChainCollections()
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)

  const { collections, total, loading } = useCollections({
    page: currentPage,
    pageSize: PAGE_SIZE,
    search,
    chainId,
  })

  return (
    <WrapLoading loading={loading} type="stick" size={40}>
      {collections.length ? (
        <Row gutter={[12, 12]}>
          {collections.map((collection) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={4} key={collection._id}>
              <CardCollection collection={collection} />
            </Col>
          ))}
          <Col span={24} />
          <Col span={24}>
            <SpaceBetween
              floatContent={
                <CustomPagination
                  pageSize={PAGE_SIZE}
                  onChange={setCurrentPage}
                  total={total}
                />
              }
            >
              <Typography.Text type="success">Total: {total}</Typography.Text>
            </SpaceBetween>
          </Col>
        </Row>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Collection"
        />
      )}
    </WrapLoading>
  )
}

export default ListCollection
