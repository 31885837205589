import { Typography } from 'antd'

import { shortenAddress } from 'helper'
import { ChainID, ChainLabel } from 'constant'

export const COLUMNS = [
  {
    title: 'User ID',
    dataIndex: 'uid',
    render: (title: string) => <Typography.Text>{title}</Typography.Text>,
  },
  {
    title: 'Wallet Address',
    dataIndex: 'walletAddress',
    render: (wallet: string) => (
      <Typography.Text>{shortenAddress(wallet)}</Typography.Text>
    ),
  },
  {
    title: 'Network',
    dataIndex: 'chainId',
    render: (chainId: ChainID) => (
      <Typography.Text>{ChainLabel[chainId]}</Typography.Text>
    ),
  },
]
