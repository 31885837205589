import { useState } from 'react'

import { Col, Input, Row, Table, Typography } from 'antd'
import SpaceBetween from 'components/systems/spaceBetween'
import CustomPagination from 'components/systems/pagination'

import { useOrders } from 'hooks/order/useOrder'
import { DIGITAL_SHIPPING_COLUMN } from './column'
import { DEFAULT_PAGE } from 'constant'
import { ProductType } from 'types/product.type'
import { ROUTES } from 'constant/routes'
import Filter from './filter'
import { OrderStatus } from 'types/order.type'
import { openInNewTab } from 'helper/common'

const { Search } = Input

const DigitalShipping = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [status, setStatus] = useState<OrderStatus>()
  const { orders, total, loading } = useOrders({
    page,
    search,
    productType: ProductType.NFT,
    orderStatus: status,
  })

  return (
    <Row align="middle">
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col flex={1}>
            <Search
              placeholder="Search Item Name, UID"
              onChange={(e) => setSearch(e.target.value)}
              style={{ width: 400 }}
            />
          </Col>
          <Col>{<Filter status={status} onSelect={setStatus} />}</Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          className="table-border-collapse"
          columns={DIGITAL_SHIPPING_COLUMN}
          dataSource={orders}
          rowKey={({ _id }) => _id}
          pagination={false}
          loading={loading}
          style={{ cursor: 'pointer' }}
          onRow={({ _id }) => ({
            onClick: () =>
              openInNewTab(ROUTES.REWARDS_CENTER.USER_SHIPPING + `/${_id}`),
          })}
        />
      </Col>
      <Col span={24}>
        <SpaceBetween
          floatContent={
            <CustomPagination page={page} total={total} onChange={setPage} />
          }
        >
          <Typography.Text type="success">Total: {total}</Typography.Text>
        </SpaceBetween>
      </Col>
    </Row>
  )
}

export default DigitalShipping
