import { useMutation } from 'react-query'
import { Acceler8TaskService } from 'services/acceler8-task'

export const useAcceler8JobControl = () => {
  const { mutate: pause, isLoading: pauseLoading } = useMutation({
    mutationFn: (id: string) => Acceler8TaskService.pauseJob(id),
  })

  const { mutate: resume, isLoading: resumeLoading } = useMutation({
    mutationFn: (id: string) => Acceler8TaskService.resumeJob(id),
  })

  const { mutate: cancel, isLoading: cancelLoading } = useMutation({
    mutationFn: (id: string) => Acceler8TaskService.cancelJob(id),
  })

  return {
    pause,
    resume,
    cancel,
    pauseLoading,
    resumeLoading,
    cancelLoading,
  }
}
