import { createGlobalState } from 'react-use'

import { Col, Row, Tabs, Typography } from 'antd'
import ListItemByCategory from './itemList'

import { DUMMY_PRODUCT_DATA, ProductType } from 'constant/marketplace'
import { ProductCateGoryState } from 'services/marketplace/type'
import { IProductData } from 'types/product.type'

export const useSetProductCategories = createGlobalState<ProductCateGoryState>()

export const useCreateProductData =
  createGlobalState<IProductData>(DUMMY_PRODUCT_DATA)

export const useProductTypeSelected = createGlobalState<ProductType>(
  ProductType.NFT,
)

const ItemManagement = () => {
  const [productTypeSelected, setProductTypeSelected] = useProductTypeSelected()

  const tabItems = [
    {
      label: 'Digital',
      key: ProductType.NFT,
    },
    {
      label: 'Physical',
      key: ProductType.REAL_NFT,
    },
  ]

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Item Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs
          activeKey={productTypeSelected}
          style={{ marginBottom: 12 }}
          items={tabItems}
          onChange={(e) => setProductTypeSelected(e as ProductType)}
        />
      </Col>
      <Col span={24}>
        <ListItemByCategory />
      </Col>
    </Row>
  )
}

export default ItemManagement
