import { Tabs } from 'antd'
import { CreateAndUpdateTabs } from 'constant/bounty'
import FormEnterTask from './formEnterTask'
import FormPrizes from './formPrizes'
import FormSetUp, { SetUpField } from '../share/formSetup'

import { useCreateBountyStep } from '../../index'
import './index.less'

const TAB_ITEMS = [
  {
    label: CreateAndUpdateTabs.SetUp,
    key: CreateAndUpdateTabs.SetUp,
    children: (
      <FormSetUp
        fields={[
          SetUpField.Picture,
          SetUpField.Recurrence,
          SetUpField.Subcategory,
          SetUpField.TotalPrices,
          SetUpField.DateRange,
        ]}
      />
    ),
  },
  {
    label: CreateAndUpdateTabs.Enter,
    key: CreateAndUpdateTabs.Enter,
    children: <FormEnterTask />,
  },
  {
    label: CreateAndUpdateTabs.Prizes,
    key: CreateAndUpdateTabs.Prizes,
    children: <FormPrizes />,
  },
]

const DappBounty = () => {
  const [step] = useCreateBountyStep()

  return (
    <Tabs
      items={TAB_ITEMS}
      activeKey={step}
      className="form-create-bounty"
      destroyInactiveTabPane
    />
  )
}

export default DappBounty
