import { useCallback, useEffect, useState } from 'react'

import { Row, Col, Typography, Select, Button } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'

import {
  MissionPriority,
  usePinnedMissions,
} from 'hooks/bounty/usePinnedMissions'
import {
  ProductPriority,
  usePinnedProducts,
} from 'hooks/bounty/usePinnedProducts'

import { notifyError } from 'helper'
import { BountyData } from 'services/bountySystem/bounty'
import { IProductData } from 'types/product.type'
import { Data } from 'types'

const Priorities = () => {
  const [pinnedMissions, setPinnedMissions] = useState<string[]>([])
  const [pinnedProducts, setPinnedProducts] = useState<string[]>([])

  const {
    loading: missionLoading,
    getPinnedMissions,
    createPinnedMissions,
  } = usePinnedMissions()
  const {
    loading: productLoading,
    getPinnedProducts,
    createPinnedProducts,
  } = usePinnedProducts()

  const onSaveMissions = useCallback(
    (missionIds: string[]) => {
      const payload = missionIds.reverse().map((missionId, index) => {
        //TODO: should be change later
        // For now, we use order as priority, mission with lower order will have higher priority
        return {
          bountyId: missionId,
          priority: index + 1,
        } as MissionPriority
      })

      createPinnedMissions(payload)
    },
    [createPinnedMissions],
  )
  const onSaveProducts = useCallback(
    (productIds: string[]) => {
      const payload = productIds.reverse().map((productId, index) => {
        //TODO: should be change later
        // For now, we use order as priority, mission with lower order will have higher priority
        return {
          productId: productId,
          priority: index + 1,
        } as ProductPriority
      })

      createPinnedProducts(payload)
    },
    [createPinnedProducts],
  )

  useEffect(() => {
    const initData = async () => {
      const data = await getPinnedMissions()

      if (data) {
        const listMissionId = data.map((mission: BountyData) => mission._id)
        setPinnedMissions(listMissionId)
      }
    }

    initData()
  }, [getPinnedMissions])

  useEffect(() => {
    const initData = async () => {
      const data = await getPinnedProducts()

      if (data) {
        const listProductId = data.map(
          (product: Data<IProductData>) => product._id,
        )
        setPinnedProducts(listProductId)
      }
    }

    initData()
  }, [getPinnedProducts])
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Pin On Top
        </Typography.Title>
      </Col>
      <Col span={24}>
        <SpaceVertical>
          <Typography.Text type="secondary">Top Missions</Typography.Text>
          <Select
            value={pinnedMissions}
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Enter Id"
            onChange={(value) => {
              if (value.length > 6)
                return notifyError('Please input less than 6 missions!')
              setPinnedMissions(value)
            }}
          />
          <Button
            type="primary"
            style={{ width: 129, borderRadius: 2 }}
            onClick={() => onSaveMissions(pinnedMissions)}
            loading={missionLoading}
          >
            Save
          </Button>
        </SpaceVertical>
      </Col>
      <Col span={24}>
        <SpaceVertical>
          <Typography.Text type="secondary">Top Products</Typography.Text>
          <Select
            value={pinnedProducts}
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Enter Id"
            onChange={(value) => {
              if (value.length > 6)
                return notifyError('Please input less than 6 products!')
              setPinnedProducts(value)
            }}
          />
          <Button
            type="primary"
            style={{ width: 129, borderRadius: 2 }}
            onClick={() => onSaveProducts(pinnedProducts)}
            loading={productLoading}
          >
            Save
          </Button>
        </SpaceVertical>
      </Col>
    </Row>
  )
}

export default Priorities
