import { bountySystemApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

const CONTROLLER = '/credit-point'

export type CreditPointData = {
  _id: string
  uid: string
  total: number
  balance: number
  updatedAt: string
  expiredYmd: number
}

class CreditPoint {
  faucet(discordId: string, amount: number) {
    return api.put(`${CONTROLLER}/faucet`, {
      discordId,
      cash: amount,
      bank: 0,
      reason: 'community-faucet',
    })
  }

  async getAll({
    page: offset = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search,
  }: {
    page?: number
    pageSize?: number
    search?: string
  }) {
    return api
      .get(`${CONTROLLER}`, {
        params: {
          offset: (offset - 1) * pageSize,
          limit: pageSize,
          search,
        },
      })
      .then((res) => res.data)
  }

  async exportLeaderBoard(): Promise<Blob> {
    const res = await api.get(`${CONTROLLER}/leader-board/export`, {
      responseType: 'blob',
    })
    return res.data
  }
}

export const CreditPointService = new CreditPoint()
