import { useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Col, Row, Table, Typography } from 'antd'

import { COLUMN_USER } from './table/column'
import CustomPagination from 'components/systems/pagination'
import { WrapLoading } from 'components/systems/loading'
import ImportUsers from '../importUsers'

import { useLatestPlayerSeasons } from 'hooks/player-leaderboard/useLatestPlayerSeasons'
import { usePlayerRanks } from 'hooks/player-leaderboard/usePlayerRanks'
import { usePlayerAccumulativePoints } from 'hooks/player-leaderboard/usePlayerAccumulativePoints'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { ROUTES } from 'constant/routes'

const ListUser = () => {
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [userIds, setUserIds] = useState<string[]>([])
  const navigate = useNavigate()

  const { data: latestSeasons } = useLatestPlayerSeasons({ limit: 2 })

  const {
    data: currentUserRanks,
    total,
    isLoading,
  } = usePlayerRanks({
    seasonId: latestSeasons[0]?.id,
    limit: DEFAULT_PAGE_SIZE,
    offset: (page - 1) * DEFAULT_PAGE_SIZE,
    userIds,
  })

  const { data: previousUserRanks } = usePlayerRanks({
    seasonId: latestSeasons[1]?.id,
    userIds: currentUserRanks?.map((rank) => rank.userId),
  })

  const { data: userAccumulativePoints } = usePlayerAccumulativePoints(
    currentUserRanks?.map((rank) => rank.userId) || [],
  )

  const tableData = useMemo(() => {
    if (!currentUserRanks) return []

    const accumulativePointsByUserId: Record<string, number> = {}
    userAccumulativePoints?.forEach((data) => {
      accumulativePointsByUserId[data.userId] = data.accumulativePoints
    })

    const previousUserRanksByUserId: Record<string, number> = {}
    previousUserRanks?.forEach((data) => {
      previousUserRanksByUserId[data.userId] = data.ranking
    })

    return currentUserRanks.map((userRank) => ({
      ...userRank,
      accumulativePoints: accumulativePointsByUserId[userRank.userId],
      previousRanking: previousUserRanksByUserId[userRank.userId],
    }))
  }, [userAccumulativePoints, currentUserRanks, previousUserRanks])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <ImportUsers onFilterUids={setUserIds} />
      </Col>

      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <Table
            rowKey="id"
            className="table-border-collapse"
            columns={COLUMN_USER}
            dataSource={tableData}
            onRow={({ userId }) => ({
              onClick: () => {
                navigate(
                  generatePath(ROUTES.PLAYER_LEADERBOARD.EDIT_USER, {
                    userId,
                  }),
                )
              },
            })}
            pagination={false}
          />
        </WrapLoading>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!tableData?.length && (
            <Col>
              <CustomPagination total={total} onChange={setPage} page={page} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default ListUser
