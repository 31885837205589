import { useQuery } from 'react-query'
import { AffiliationService } from 'services/affiliation-v2'
import { FindAffiliationTeamDto } from 'types/affiliation-team.type'

export const useAffiliationTeams = (dto: FindAffiliationTeamDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_AFFILIATION_TEAMS', dto],
    queryFn: () => AffiliationService.findTeams(dto),
  })

  return {
    ...rest,
    data: data?.data,
    total: data?.total ?? 0,
  }
}
