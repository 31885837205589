import { useQuery } from 'react-query'

import { CampaignService } from 'services/bountySystem/campaign.service'

import { GetCampaignDto, ICampaign } from 'types/campaign.type'

const useCampaigns = (getCampaignDto: GetCampaignDto) => {
  return useQuery({
    queryKey: ['CAMPAIGNS', JSON.stringify(getCampaignDto)],
    queryFn: async (): Promise<{ data: ICampaign[]; total: number }> =>
      await CampaignService.getListOfCampaign(getCampaignDto),
  })
}

export default useCampaigns
