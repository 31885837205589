import { memo, ReactNode } from 'react'

import { Avatar, Typography } from 'antd'
import RewardAvatarItem from './avatar'
import IonIcon from '../ionIcon'

import './index.less'
import { RewardType } from 'constant/reward'

export type GroupRewardAvatarProps = {
  size?: number
  icon?: ReactNode
  bountyId: string
}

/**
 * Mint/Token avatar, supporting LP tokens
 * @param size - Avatar size. Default 24px.
 * @param icon - Fallback icon for unknown token
 * @returns name
 */

const GroupRewardAvatar = ({
  size = 49,
  icon = <IonIcon name="diamond-outline" />,
  bountyId,
  ...props
}: GroupRewardAvatarProps) => {
  const rewardType = [{ category: RewardType.CCP, amount: 10 }]

  if (!rewardType.length)
    return <Typography.Text type="secondary">No data</Typography.Text>

  return (
    <Avatar.Group
      maxCount={2}
      style={{ display: 'block', whiteSpace: 'nowrap' }}
      size={size}
      {...props}
    >
      {rewardType.map(({ category }) => {
        return (
          <RewardAvatarItem key={category} size={size} rewardType={category} />
        )
      })}
    </Avatar.Group>
  )
}

export default memo(GroupRewardAvatar)
