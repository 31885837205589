import React, { useEffect, useMemo, useState } from 'react'

import { Button, Card, Col, Form, Image, Row, Typography } from 'antd'
import {
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
  PlusCircleOutlined as PlusIcon,
} from '@ant-design/icons'

import FormCommunityFeatured from 'view/config/heroBanner/formCommunityFeatured'

import { useFeaturedItems } from 'hooks/featured/useFeatured'

import { notifyError } from 'helper'

import {
  CommunityFeatured,
  FeaturedItemType,
} from 'services/bountySystem/featuredConfig/type'

function FeaturedCommunityInput() {
  const [openModal, setOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState<CommunityFeatured | undefined>()
  const [communitiesFeatured, setCommunitiesFeatured] =
    useState<CommunityFeatured[]>()

  const { loading, findFeaturedItems, createFeaturedItems } = useFeaturedItems()

  const [form] = Form.useForm()

  const isDisabled = useMemo(
    () => !communitiesFeatured || communitiesFeatured.length !== 3,
    [communitiesFeatured],
  )

  const handleRemoveCommunity = (communityId: string) => {
    setCommunitiesFeatured((prevState) =>
      prevState?.filter((e) => e.communityId !== communityId),
    )
  }

  const handleEdit = (communityFeatured: CommunityFeatured) => {
    setIsEdit(true)
    setOpenModal(true)
    setEditData(communityFeatured)
  }

  const onSubmitChange = (values: CommunityFeatured) => {
    setCommunitiesFeatured((prevState) => {
      if (!prevState) return

      const index = prevState.findIndex(
        (e) => e.communityId === values.communityId,
      )

      if (index !== -1) {
        prevState[index] = values
        return prevState
      }

      return [...prevState, values]
    })
  }

  const handleSave = async () => {
    try {
      await createFeaturedItems({
        type: FeaturedItemType.COMMUNITY,
        communitiesFeatured,
      })
      setOpenModal(false)
    } catch (err) {
      notifyError(err)
    }
  }

  const fetchCommunitiesFeatured = async () => {
    try {
      const data = await findFeaturedItems(FeaturedItemType.COMMUNITY)
      setCommunitiesFeatured(data)
    } catch (err) {
      notifyError(err)
    }
  }

  useEffect(() => {
    fetchCommunitiesFeatured()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={24}>
          <Typography.Text type="secondary">
            List Featured Community
          </Typography.Text>
        </Col>

        <Col span={24}>
          <Row gutter={[24, 24]}>
            {communitiesFeatured?.map((communityFeatured, index) => (
              <Col sm={24} md={12} lg={8} xl={8} key={index}>
                <Card style={{ marginTop: 16 }}>
                  <Row gutter={[0, 8]}>
                    <Col span={24}>
                      <Typography.Text type="secondary">
                        Community ID
                      </Typography.Text>
                      <br />
                      <Typography.Text>
                        {communityFeatured.communityId}
                      </Typography.Text>
                    </Col>

                    <Col span={24}>
                      <Typography.Text type="secondary">
                        Normalize Title
                      </Typography.Text>
                      <br />
                      <Typography.Text>
                        {communityFeatured.communityTheme.normalizeTitle}
                      </Typography.Text>
                    </Col>

                    <Col span={24}>
                      <Typography.Text type="secondary">
                        Highlight Title
                      </Typography.Text>
                      <br />
                      <Typography.Text style={{ color: '#0fdbd1' }}>
                        {communityFeatured.communityTheme.highlightTitle}
                      </Typography.Text>
                    </Col>

                    <Col span={24}>
                      <Typography.Text type="secondary">
                        Cover Image
                      </Typography.Text>
                    </Col>

                    <Col span={24}>
                      <Image
                        style={{ width: '100%', height: 'auto' }}
                        src={communityFeatured.communityTheme.coverImage}
                      />
                    </Col>

                    <Col span={24}>
                      <Typography.Text type="secondary">
                        Thumbnail
                      </Typography.Text>
                    </Col>

                    <Col span={24}>
                      <Image
                        style={{ width: '100%', height: 'auto' }}
                        src={communityFeatured.communityTheme.thumbnail}
                      />
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between">
                        <Button
                          onClick={() => handleEdit(communityFeatured)}
                          icon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() =>
                            handleRemoveCommunity(communityFeatured.communityId)
                          }
                          danger
                          icon={<DeleteIcon />}
                        >
                          Remove
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>

          {communitiesFeatured && communitiesFeatured?.length < 3 && (
            <Button
              style={{ display: 'flex', alignItems: 'center', height: 49 }}
              onClick={() => {
                setIsEdit(false)
                setOpenModal(true)
              }}
            >
              <PlusIcon style={{ fontSize: 25 }} />
              Add Community
            </Button>
          )}
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            style={{ width: 129, borderRadius: 2 }}
            disabled={isDisabled}
            onClick={handleSave}
            loading={loading}
          >
            Save
          </Button>
        </Col>
      </Row>

      <FormCommunityFeatured
        form={form}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        openModal={openModal}
        editData={editData}
        setOpenModal={setOpenModal}
        setEditData={setEditData}
        onSubmit={onSubmitChange}
      />
    </>
  )
}

export default FeaturedCommunityInput
