import { useCallback, useEffect, useState } from 'react'

import { Col, Row } from 'antd'
import BountyReport from './bountyReport'
import TaskReport from './taskReport'

import {
  DUMMY_REPORT_DATA,
  ReportData,
  ReportService,
} from 'services/bountySystem/report'
import { notifyError } from 'helper'

import './index.less'

type ReportInfoProps = { startDate: string; endDate: string }

const ReportInfo = ({ endDate, startDate }: ReportInfoProps) => {
  const [loading, setLoading] = useState(false)
  const [reportData, setReportData] = useState<ReportData>(DUMMY_REPORT_DATA)

  const fetchReport = useCallback(async () => {
    if (!startDate && !endDate) return setReportData(DUMMY_REPORT_DATA)

    try {
      setLoading(true)
      const data = await ReportService.getAll({ startDate, endDate })
      setReportData(data)
    } catch (err) {
      setReportData(DUMMY_REPORT_DATA)
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }, [endDate, startDate])

  useEffect(() => {
    fetchReport()
  }, [fetchReport])

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} xl={12}>
        <TaskReport reportData={reportData} loading={loading} />
      </Col>
      <Col xs={24} xl={12}>
        <BountyReport loading={loading} reportData={reportData} />
      </Col>
    </Row>
  )
}

export default ReportInfo
