import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import hash from 'object-hash'

import {
  Button,
  Card,
  Col,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd'
import Disabled from 'components/systems/disable'
import IonIcon from 'components/systems/ionIcon'
import { WrapLoading } from 'components/systems/loading'
import NftInfo from 'view/itemManagement/nftInfo'
import { SelectChainContext } from 'components/systems/selectChain/selectChainContext'

import {
  useCurrentCursor,
  useQueryUserNFTs,
  useUserNFTCheckActive,
  useUserNFTs,
} from 'hooks/evm/useUserNft'
import { Web3Type } from 'services/marketplace/we3-item'

import {
  getUserNFTs,
  updateUserNFTs,
  UserNFTData,
  UserNFTState,
} from 'store/walletNFT.reducer'

import { shortenAddress } from 'helper'

import { ProductType } from 'constant/marketplace'
import { ChainID } from 'constant'

import defaultNftThumbnail from 'static/images/icon/ico-nft.svg'
const { Search } = Input

export type NFT = {
  name: string
  thumbnail: string
  amount: string
  collection: string
  address: string
  uniqueAddress: string
  ownerOf: string
  isActive: boolean
  tokenId: number
  tokenAddress: string
  web3ItemId: string
}

type NftSelectionProps = {
  value: string
  onChange: (val: string) => void
  productType: ProductType
  setItemWeb3Id?: (val: string) => void
}

type ModalContentProps = {
  nftInfos: NFT[]
  selectedNFT: string
  onChange: (val: string) => void
  onClose: () => void
  loadingNFTs?: boolean
  productType: ProductType
  setItemWeb3Id?: (val: string) => void
}

const NftSelection = ({ value, onChange, productType }: NftSelectionProps) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const userNFTs = useUserNFTs()
  const { selectedChain } = useContext(SelectChainContext)

  const { data, isLoading } = useQueryUserNFTs({
    chainId: selectedChain,
    limit: 50,
    productType: productType,
  })

  /* define a function to get NFT data */
  const fetchUserNftData = useCallback(() => {
    if (data) {
      const bulk: UserNFTState = {} as UserNFTState
      const userNftData: Record<string, UserNFTData> = {}

      for (const nft of data.result) {
        const web3Data = {
          chainId: data.chainId as ChainID,
          web3Type: Web3Type.NFT,
          blockData: {
            tokenId: nft.tokenId || '',
            tokenAddress: nft.tokenAddress || '',
            srcAddress: nft.ownerOf || '',
            contractType: nft.contractType || '',
            collectionName: nft.collectionName || '',
            symbol: nft.symbol || '',
          },
        }
        const web3ItemId = hash(web3Data)
        userNftData[`${nft.tokenId}-${nft.tokenAddress}`] = {
          ...nft,
          web3ItemId,
        }
      }

      bulk.cursor = data.cursor
      bulk.userNFTs = userNftData

      dispatch(getUserNFTs({ bulk }))
    }
  }, [data, dispatch])

  useEffect(() => {
    fetchUserNftData()
  }, [fetchUserNftData])

  return (
    <Row>
      <Col span={12}>
        {!value ? (
          <Button
            icon={<IonIcon name="add-outline" />}
            ghost
            onClick={() => {
              setOpen(true)
            }}
            className="select-nft-button"
          >
            Select NFT
          </Button>
        ) : (
          <Row justify="space-between" wrap={false}>
            <Col flex="auto">
              <NftInfo nftAddress={value} />
            </Col>
            <Col>
              <Button
                size="small"
                icon={<IonIcon name="sync-outline" />}
                ghost
                onClick={() => {
                  setOpen(true)
                }}
                className="select-nft-button"
              >
                Change NFT
              </Button>
            </Col>
          </Row>
        )}
      </Col>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        style={{ maxWidth: 931 }}
        className="nft-selection"
        width="unset"
        footer={null}
        destroyOnClose
      >
        <ModalContent
          nftInfos={Object.values(userNFTs).map((nft: any) => {
            return {
              ...nft,
              uniqueAddress: `${nft.tokenId}-${nft.tokenAddress}`,
              address: nft.tokenAddress,
              collection: nft.collectionName,
              thumbnail: nft.image,
            }
          })}
          loadingNFTs={isLoading}
          onChange={onChange}
          selectedNFT={value}
          onClose={() => setOpen(false)}
          productType={productType}
        />
      </Modal>
    </Row>
  )
}

const ModalContent = ({
  nftInfos,
  onChange,
  selectedNFT,
  onClose,
  loadingNFTs = false,
  productType,
}: ModalContentProps) => {
  const dispatch = useDispatch()
  const checkActive = useUserNFTCheckActive()
  const [searching, setSearching] = useState(false)
  const [allowNFTWithoutInfo, setAllowNFTWithoutInfo] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [nftData, setNftData] = useState(nftInfos)
  const [currentSelectedNFT, setCurrentSelectedNFT] = useState(selectedNFT)
  const currentCursor = useCurrentCursor()

  const { selectedChain } = useContext(SelectChainContext)

  const { data, isLoading } = useQueryUserNFTs(
    {
      chainId: selectedChain,
      limit: 10,
      cursor: currentCursor,
      productType: productType,
    },
    !!selectedChain,
  )

  const chainName = useMemo(() => {
    if (!selectedChain) return ''

    return selectedChain.split(':')[1]
  }, [selectedChain])

  const onSearching = (searchValue: string) => {
    setSearching(true)
    setSearchValue(searchValue)
  }

  const onSwitch = (checked: boolean) => {
    setAllowNFTWithoutInfo(checked)
  }

  const filterNftData = useCallback(
    (searchValue: string) => {
      const filteredNftData = nftInfos.filter(
        (item) =>
          (!allowNFTWithoutInfo ? item.name && item.thumbnail : true) &&
          item.name?.toLowerCase()?.includes(searchValue.toLowerCase()),
      )
      setNftData(filteredNftData)
    },
    [nftInfos, allowNFTWithoutInfo],
  )

  const handleLoadMore = useCallback(async () => {
    if (!data) return

    const bulk: UserNFTState = {} as UserNFTState
    const userNftData: Record<string, UserNFTData> = {}
    // re-mapping on new data
    for (const nft of data.result) {
      userNftData[`${nft.tokenId}-${nft.tokenAddress}`] = nft
    }
    bulk.cursor = data?.cursor
    bulk.userNFTs = { ...userNftData }

    // emit data change event to redux
    dispatch(updateUserNFTs({ userNfts: bulk }))
  }, [data, dispatch])

  const onConfirm = async () => {
    onChange(currentSelectedNFT)
    onClose()
  }

  // track on searching event
  useEffect(() => {
    if (searching) {
      setTimeout(() => {
        filterNftData(searchValue)
        setSearching(false)
      }, 500)
    }
  }, [searching, searchValue, filterNftData])

  // update every time NFT props changed
  useEffect(() => {
    setNftData(
      !allowNFTWithoutInfo
        ? nftInfos.filter((nft) => {
            return nft.name && nft.thumbnail
          })
        : nftInfos,
    )
  }, [nftInfos, allowNFTWithoutInfo])

  return (
    <Row gutter={[40, 40]}>
      <Col>
        <Row justify="center" gutter={[16, 16]} wrap={true}>
          <Col>
            <Typography.Title level={3}>Select NFT</Typography.Title>
          </Col>
          <Col span={24}>
            <Space
              style={{ width: '100%' }}
              direction="vertical"
              align="center"
            >
              <Typography.Text>
                {nftData.length > 0
                  ? `You are selecting an NFT from wallet ${shortenAddress(
                      nftData[0]?.ownerOf,
                    )} on ${chainName} Chain`
                  : `You are selecting an NFT from Admin's wallet on ${chainName} Chain`}
              </Typography.Text>
            </Space>
          </Col>
          <Col>
            <Search
              className="search-nft-input"
              placeholder="Search NFT"
              onChange={(e) => onSearching(e.target.value)}
              loading={searching}
              style={{ width: 400, borderRadius: 4 }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        className="nft-scroll-view"
        style={{ width: '100%', maxHeight: '443px', overflowY: 'scroll' }}
      >
        <WrapLoading loading={loadingNFTs} size={52} type="stick">
          <Row>
            <Space
              style={{ width: '100%', justifyContent: 'center' }}
              size={14}
              wrap={true}
            >
              {nftData.length === 0 ? (
                <Typography.Text>No NFT found!</Typography.Text>
              ) : (
                nftData.map(
                  (
                    {
                      name,
                      thumbnail,
                      amount,
                      collection,
                      uniqueAddress,
                      tokenId,
                      web3ItemId,
                    },
                    index,
                  ) => (
                    <Disabled
                      key={index}
                      disabled={!checkActive({ web3ItemId })}
                      bgColor="linear-gradient(90deg, #09BEBD 0%, #03C281 49.27%, #6AD743 100%)"
                      message="NFT was listed on the market"
                    >
                      <Card
                        hoverable={true}
                        key={index}
                        style={{ maxWidth: 159 }}
                        bodyStyle={{
                          padding: 0,
                          border:
                            uniqueAddress === currentSelectedNFT
                              ? '2px solid #42BAB0'
                              : '2px solid #242F30',
                        }}
                        onClick={() => setCurrentSelectedNFT(uniqueAddress)}
                      >
                        <Image
                          src={thumbnail ?? defaultNftThumbnail}
                          preview={false}
                          height={153}
                          width={153}
                        />
                        <Row
                          gutter={[4, 4]}
                          style={{ padding: '6px 12px 8px' }}
                        >
                          <Col span={24}>
                            <Typography.Text
                              style={{ fontSize: 12 }}
                              ellipsis={true}
                              type="secondary"
                            >
                              {collection ?? 'Default Collection'}
                            </Typography.Text>
                          </Col>
                          <Col span={24}>
                            <Typography.Text
                              style={{ fontSize: 12 }}
                              ellipsis={true}
                              type="secondary"
                            >
                              Total: {amount}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text strong ellipsis={true}>
                              {name ?? `NFT Item #${tokenId}`}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Card>
                    </Disabled>
                  ),
                )
              )}
            </Space>
          </Row>
          {nftData.length === 0 ||
          currentCursor === null ||
          currentCursor === undefined ? (
            <Fragment />
          ) : (
            <Row justify="center" style={{ marginTop: 10 }}>
              <Col>
                <Button
                  type="dashed"
                  size="small"
                  shape="round"
                  style={{ borderRadius: 2 }}
                  onClick={handleLoadMore}
                  loading={isLoading}
                  disabled={
                    currentCursor === null || currentCursor === undefined
                  }
                >
                  Load More
                </Button>
              </Col>
            </Row>
          )}
        </WrapLoading>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Space size={10}>
              <Switch checked={allowNFTWithoutInfo} onChange={onSwitch} />
              <Typography.Text>Show NFT with no information</Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space size={10}>
              <Col>
                <Typography.Text strong type="secondary">
                  {/*Footer*/}
                </Typography.Text>
                <Button
                  type="primary"
                  style={{ width: 129, borderRadius: 2 }}
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              </Col>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default NftSelection
