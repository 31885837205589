import { Fragment, useMemo, useState } from 'react'

import { Button, Col, Image, Modal, Row, Select, Typography } from 'antd'

import ButtonIcon from 'components/systems/button-base/ButtonIcon'
import { VerticalAlignBottomOutlined as DownloadIcon } from '@ant-design/icons'

import { useGachaReward } from 'hooks/gacha/useGachaReward'

import { GachaService } from 'services/marketplace/gacha'
import { WhitelistService } from 'services/marketplace/whitelist'

import {
  GachaRewardLabel,
  GachaRewardType,
  IGachaBaseInfo,
  IWhitelistRewardConfig,
} from 'types/gacha.type'

import DownloadCircle from 'static/images/icon/download-circle.svg'

type ActionExportProps = {
  gacha: IGachaBaseInfo
}

function ActionExport({ gacha }: ActionExportProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [rewardType, setRewardType] = useState<GachaRewardType>()

  const { data: gachaRewards } = useGachaReward({
    gachaId: gacha._id || '',
  })

  const typeSet = new Set(gachaRewards.map((reward) => reward.type))

  const excludedTypes: GachaRewardType[] = [
    GachaRewardType.GiftCard, // TODO: remove this when BE (Gift card) is ready
    GachaRewardType.Congrats,
  ]

  const rewardTypes: GachaRewardType[] = Array.from(typeSet).filter(
    (type) => !excludedTypes.includes(type),
  )

  const exportRewardUrl = useMemo(() => {
    if (rewardType === GachaRewardType.Whitelist) {
      const whitelistIds = gachaRewards
        .filter((reward) => reward.type === GachaRewardType.Whitelist)
        .map((reward) => (reward.config as IWhitelistRewardConfig).whitelistId)

      return WhitelistService.getDownloadUserWhitelistUrl(whitelistIds)
    }

    return GachaService.getDownloadGachaRewardUrl(
      rewardType || GachaRewardType.Congrats,
      gacha._id || '',
    )
  }, [gacha._id, gachaRewards, rewardType])

  return (
    <Fragment>
      <ButtonIcon
        icon={<DownloadIcon />}
        onClick={() => setIsOpen(true)}
        wrapperCls="gacha-row-action-btn"
      />

      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        style={{ maxWidth: 450 }}
        wrapClassName="export-gacha-reward-modal"
      >
        <Row gutter={[0, 24]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Image src={DownloadCircle} preview={false} className="logo" />
          </Col>
          <Col span={24}>
            <Typography.Title level={4} className="title">
              Export {gacha.title} Data
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Select
              style={{ width: '100%' }}
              onChange={setRewardType}
              options={rewardTypes.map((type) => ({
                value: type,
                label: GachaRewardLabel[type],
              }))}
            />
          </Col>
          <Col span={24}>
            <Button
              block
              icon={<DownloadIcon />}
              href={exportRewardUrl}
              disabled={!rewardType}
              className="btn-submit"
            >
              Download
            </Button>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default ActionExport
