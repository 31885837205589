import { useQuery } from 'react-query'

import { MultiplierService } from 'services/multiplier'

export const useMultiplier = (multiplierId: string) => {
  return useQuery({
    queryKey: ['GET_MULTIPLIER_DETAILS', multiplierId],
    queryFn: () => MultiplierService.getMultiplierDetails(multiplierId),
    enabled: !!multiplierId,
  })
}
