import { useState } from 'react'
import { Col, Empty, Row, Typography } from 'antd'

import CustomPagination from 'components/systems/pagination'
import SpaceBetween from 'components/systems/spaceBetween'
import { WrapLoading } from 'components/systems/loading'
import CampaignCard from 'components/campaign/listCampaign/campaignCard'

import { useSearchCampaignKey } from 'view/campaign'
import useCampaigns from 'hooks/campaign/useCampaigns'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'

import { DEFAULT_PAGE } from 'constant'

const PAGE_SIZE = 18

const ListCampaign = () => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [search] = useSearchCampaignKey()
  const [community] = useCurrentCommunity()

  const { data, isLoading } = useCampaigns({
    page: currentPage,
    pageSize: PAGE_SIZE,
    search,
    communityId: community?._id,
  })

  return (
    <WrapLoading loading={isLoading} type="stick" size={40}>
      {data?.data?.length ? (
        <Row gutter={[12, 12]}>
          {data.data.map((campaign) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={4} key={campaign?._id}>
              <CampaignCard campaign={campaign} />
            </Col>
          ))}
          <Col span={24} />
          <Col span={24}>
            <SpaceBetween
              floatContent={
                <CustomPagination
                  pageSize={PAGE_SIZE}
                  onChange={setCurrentPage}
                  total={data?.total}
                />
              }
            >
              <Typography.Text type="success">
                Total: {data.total}
              </Typography.Text>
            </SpaceBetween>
          </Col>
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Campaign" />
      )}
    </WrapLoading>
  )
}

export default ListCampaign
