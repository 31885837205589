import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { ProductService } from 'services/marketplace/product'

export const useProductSlug = () => {
  const productId = useParams()?.productId

  const fetchProductSlug = useCallback(async (slug: string) => {
    return await ProductService.getProductBySlug(slug)
  }, [])

  const checkIsSlugExisted = useCallback(
    async (slug: string): Promise<boolean> => {
      if (!slug) return false

      try {
        const product = await fetchProductSlug(slug)

        if (!product._id) return false
        return product._id !== productId
      } catch (e) {
        // Error mean slug is not existed
        return false
      }
    },
    [productId, fetchProductSlug],
  )

  return {
    checkIsSlugExisted,
  }
}
