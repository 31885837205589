import { useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { useCountGachaTicket } from 'hooks/gacha/useCountGachaTicket'

import { GachaService } from 'services/marketplace/gacha'

import { DEFAULT_PAGE_SIZE } from 'constant'

import { GachaStates, type IGachaWithCount } from 'types/gacha.type'

export type GachaListParams = {
  search?: string
  communityId?: string
  state?: GachaStates
  page?: number
  pageSize?: number
}

const ROOT_KEY = 'get-gacha-list'

export const useGachaList = ({
  search,
  communityId,
  state,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
}: GachaListParams | undefined = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [ROOT_KEY, search, communityId, state, page, pageSize],
    queryFn: () => {
      return GachaService.findAllGacha({
        search,
        communityId,
        state,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      })
    },
    enabled: !!state && !!communityId,
  })

  const gachaIds = useMemo(
    () => data?.data.map((gacha) => gacha._id ?? ''),
    [data?.data],
  )
  const [{ data: dataTotalTicket }, { data: dataTotalSpun }] =
    useCountGachaTicket(gachaIds ?? [])

  const dataWithCount = useMemo(() => {
    return data?.data.map((gacha) => {
      const totalTicket = dataTotalTicket?.find(
        (ticket) => ticket.gachaId === gacha._id,
      )
      const totalSpun = dataTotalSpun?.find(
        (ticket) => ticket.gachaId === gacha._id,
      )

      return {
        ...gacha,
        totalTicket: totalTicket?.count ?? 0,
        totalSpun: totalSpun?.count ?? 0,
      } as IGachaWithCount
    })
  }, [data, dataTotalTicket, dataTotalSpun])

  const queryClient = useQueryClient()
  const refetchRoot = () =>
    queryClient.refetchQueries({
      queryKey: [ROOT_KEY],
    })

  return {
    ...rest,
    data: dataWithCount ?? [],
    total: data?.total ?? 0,
    refetchRoot,
  }
}
