import { Fragment, useMemo } from 'react'

import { Form, FormInstance, Radio } from 'antd'

import InteractScSection from './InteractScSection'
import ApiSetupSection from './ApiSetupSection'
import TransferTokenSection from './TransferTokenSection'
import GasSpentSection from './GasSpentSection'

import { AC8TaskType, TaskTypeLabel } from 'types/acceler8/acceler8-task.type'

const taskTypeOptions = Object.values(AC8TaskType)
  .filter((type) => type !== AC8TaskType.Space3)
  .map((type) => {
    return {
      label: TaskTypeLabel[type],
      key: type,
    }
  })

type PartnerInfoSectionProps = {
  form: FormInstance
  isEdit: boolean
}

function PartnerInfoSection({ form, isEdit }: PartnerInfoSectionProps) {
  const type = Form.useWatch('type', form)

  const section = useMemo(() => {
    switch (type) {
      case AC8TaskType.InteractSC:
        return <InteractScSection />
      case AC8TaskType.GasSpent:
        return <GasSpentSection />
      case AC8TaskType.FederatedApi:
        return <ApiSetupSection />
      case AC8TaskType.TokenSpent:
        return <TransferTokenSection />
      case AC8TaskType.USDSpent:
        return <TransferTokenSection />
      default:
        return <Fragment />
    }
  }, [type])

  return (
    <Fragment>
      <Form.Item
        name="type"
        initialValue={taskTypeOptions[0].key}
        rules={[
          {
            required: true,
            message: 'Please select task type',
          },
        ]}
      >
        <Radio.Group disabled={isEdit}>
          {taskTypeOptions.map((option) => (
            <Radio.Button type="primary" key={option.key} value={option.key}>
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      {section}
    </Fragment>
  )
}

export default PartnerInfoSection
