import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'

import { DEFAULT_SEO_INFO, useSeoInfoState } from 'components/seo/formSeoInfo'

import SeoService from 'services/seo'

import { SeoType } from 'types/seo.type'

export const useSEO = (type: SeoType) => {
  const params = useParams()
  const [seoInfo, setSeoInfo] = useSeoInfoState()

  const id = useMemo(() => {
    switch (type) {
      case SeoType.Product:
        return params.productId
      case SeoType.Bounty:
        return params.bountyId
      case SeoType.Game:
        return params.communityId
      case SeoType.Gacha:
        return params.gachaId
      default:
        return undefined
    }
  }, [type, params])

  const clearSeoInfo = useCallback(() => {
    setSeoInfo(DEFAULT_SEO_INFO)
  }, [setSeoInfo])

  const { mutate: fetchSeoInfo } = useMutation({
    mutationKey: ['fetch-seo', type, id],
    mutationFn: async () => {
      if (!id) return

      return await SeoService.getSeo({ seoId: id, type })
    },
    onSuccess: (data) => {
      setSeoInfo(data)
    },
  })

  const { mutateAsync: createSeoInfo } = useMutation({
    mutationKey: ['create-seo', type, id, seoInfo],
    mutationFn: async (id: string) => {
      if (!id) return

      return await SeoService.createSeo({
        ...seoInfo,
        seoId: id,
        type,
      })
    },
    onSuccess: () => {
      clearSeoInfo()
    },
    onError: () => {
      clearSeoInfo()
    },
  })

  const { mutateAsync: updateSeoInfo } = useMutation({
    mutationKey: ['update-seo', type, id, seoInfo],
    mutationFn: async () => {
      if (!id) return
      return await SeoService.updateSeo({ seoId: id, type }, seoInfo)
    },
    onSuccess: () => {
      clearSeoInfo()
    },
    onError: () => {
      clearSeoInfo()
    },
  })

  return { fetchSeoInfo, clearSeoInfo, createSeoInfo, updateSeoInfo }
}
