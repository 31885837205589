import { CommunityState, CreateCommunityDto } from 'types/community.type'

import { convertTzUtcBeforeUpload } from 'helper'

export enum TableCommunityActions {
  Edit = 'Edit',
  EditProfile = 'Edit Profile',
  Delete = 'Delete',
  Publish = 'Publish',
  UnPublish = 'Unpublish',
}

export const DEFAULT_CREATE_COMMUNITY_DATA: CreateCommunityDto = {
  title: '',
  description: '',
  discordLink: undefined,
  telegramLink: undefined,
  twitterLink: undefined,
  youtubeLink: undefined,
  facebookLink: undefined,
  website: undefined,
  state: CommunityState.Active,
  discordId: undefined,
  ccpTimeMark: convertTzUtcBeforeUpload(),
  ccpMergeMonths: 0,
  ccpLifeMonths: 0,
  logo: undefined,
  coverImage: undefined,
  thumbnail: undefined,
  status: undefined,
  genre: undefined,
  blockchain: undefined,
  slug: undefined,
}
