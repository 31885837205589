import { Form } from 'antd'

import { type RewardItemProps } from '..'

function TypeFormItem({ prefixNamePath }: RewardItemProps) {
  return (
    <Form.Item
      name={[prefixNamePath, 'type']}
      rules={[
        {
          required: true,
          message: 'Type is required',
        },
      ]}
      hidden
      noStyle
    />
  )
}

export default TypeFormItem
