import { Typography } from 'antd'

export const prizeColumn = [
  {
    title: 'Rank',
    dataIndex: 'title',
    render: (title: string) => <Typography.Text>{title}</Typography.Text>,
  },
  {
    title: 'Prize',
    dataIndex: 'prize',
    render: (prize: string) => <Typography.Text>{prize}</Typography.Text>,
  },
]
