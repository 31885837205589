import { Col, Row } from 'antd'

import NewAndFilterBar from './newAndfilterBar'
import TableMarketItem from './tableMarketItem'

import { WrapLoading } from 'components/systems/loading'

import { useProductList } from 'hooks/product/useProduct'

const ListItemByCategory = () => {
  const { isLoading, data } = useProductList()

  return (
    <Row gutter={[24, 24]}>
      {/* <Col span={24}>
        <CardTotal
          productType={
            productTypeSelected === ItemManagementTabs.Digital
              ? ProductType.NFT
              : ProductType.REAL_NFT
          }
        />
      </Col> */}
      <Col span={24}>
        <NewAndFilterBar />
      </Col>
      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <TableMarketItem data={data} />
        </WrapLoading>
      </Col>
    </Row>
  )
}

export default ListItemByCategory
