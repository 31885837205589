import { createContext } from 'react'

export type CreateProductType = {
  isCodeItem: boolean
}

const FormProductContext = createContext<CreateProductType>({
  isCodeItem: false,
})

export default FormProductContext
