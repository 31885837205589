import { useContext } from 'react'

import { Col, Row, Table, Typography } from 'antd'

import { PLAYER_REDEMPTION_COLUMNS } from './table/column'
import CustomPagination from 'components/systems/pagination'
import { WrapLoading } from 'components/systems/loading'

import { usePlayerRedemptions } from 'hooks/player-leaderboard/usePlayerRedemptions'

import RedemptionsFilterContext from './RedemptionFilterContext'

import { DEFAULT_PAGE_SIZE } from 'constant'

const RedemptionsList = () => {
  const { filter, page, search, setPage } = useContext(RedemptionsFilterContext)

  const {
    data: redemptions,
    total,
    isLoading,
  } = usePlayerRedemptions({
    seasonIds: [filter?.seasonId],
    limit: DEFAULT_PAGE_SIZE,
    offset: (page - 1) * DEFAULT_PAGE_SIZE,
    search,
  })

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <Table
            rowKey="id"
            className="table-border-collapse"
            columns={PLAYER_REDEMPTION_COLUMNS}
            dataSource={redemptions}
            pagination={false}
          />
        </WrapLoading>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!redemptions?.length && (
            <Col>
              <CustomPagination total={total} onChange={setPage} page={page} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default RedemptionsList
