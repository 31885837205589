import {
  CategoryScale,
  Chart,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#CCCCCC',
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: '#CCCCCC',
      },
      grid: {
        display: false,
      },
    },
  },
}

type SpaceChartProps = {
  yData: number[]
  labels: string[]
}

const SpaceChart = ({ yData, labels }: SpaceChartProps) => {
  const data = {
    labels,
    datasets: [
      {
        label: 'users',
        data: yData,
        backgroundColor: '#42BAB0',
        borderRadius: 9999,
      },
    ],
  }

  return <Bar data={data} options={options} />
}

export default SpaceChart
