import { MouseEvent, useCallback, useState } from 'react'
import copy from 'copy-to-clipboard'

import { Tooltip, Button } from 'antd'

import IonIcon from './ionIcon'

export type ClipboardProps = {
  content: string
}

const Clipboard = ({ content }: ClipboardProps) => {
  const [copied, setCopied] = useState(false)

  const onCopy = useCallback(
    async (value: string, e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      copy(value)
      setCopied(true)
      setTimeout(() => {
        return setCopied(false)
      }, 2000)
    },
    [],
  )

  return (
    <Tooltip title="Copied" open={copied}>
      <Button
        className="clipboard"
        type="text"
        icon={
          <IonIcon
            name="copy-outline"
            style={{ color: '#4d5a66' }}
            onClick={(e: any) => onCopy(content, e)}
            size="large"
          />
        }
      />
    </Tooltip>
  )
}

export default Clipboard
