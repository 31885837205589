import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { GachaService } from 'services/marketplace/gacha'

export const useGachaSlug = () => {
  const gachaId = useParams()?.gachaId

  const fetchGachaSlug = useCallback(async (slug: string) => {
    return await GachaService.getGachaBaseInfoBySlug(slug)
  }, [])

  const checkIsSlugExisted = useCallback(
    async (slug: string): Promise<boolean> => {
      if (!slug) return false

      try {
        const gacha = await fetchGachaSlug(slug)

        if (!gacha?._id) return false
        return gacha._id !== gachaId
      } catch (e) {
        // Error mean slug is not existed
        return false
      }
    },
    [gachaId, fetchGachaSlug],
  )

  return {
    checkIsSlugExisted,
  }
}
