import { useEffect, useState } from 'react'

const useDelaySearch = (delayTime: number, field: string) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<
    Record<string, any>
  >({
    [field]: '',
  })

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDebouncedSearchTerm((prev: any) => {
        return {
          ...prev,
          [field]: searchTerm,
        }
      })
    }, delayTime)

    return () => clearTimeout(delayTimer)
  }, [searchTerm, delayTime, field])

  return [debouncedSearchTerm[field], setSearchTerm]
}

export default useDelaySearch
