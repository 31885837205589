import { useMutation } from 'react-query'

import { MultiplierService } from 'services/multiplier'

export const useDeleteMultiApp = () => {
  return useMutation({
    mutationKey: ['DELETE_MULTI_APP'],
    mutationFn: (id: string) =>
      MultiplierService.deleteMultiplierApplicationById(id),
  })
}
