import { bountySystemApi as api } from 'services/base-axios'

import {
  BountyCategory,
  BountyRecurrence,
  BountySubcategory,
} from 'constant/bounty'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import { MissionPriority } from 'hooks/bounty/usePinnedMissions'
import { DataList } from 'types'

const CONTROLLER = '/admin/bounty'
const CONTROLLER_USER = '/bounty'

class Bounty {
  async create(data: CreateBounty) {
    return api.post(CONTROLLER, data)
  }

  async getAll({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    category = BountyCategory.ALL,
    search = '',
    ...params
  }: GetBountyDto) {
    return api
      .get<DataList<BountyData[]>>(CONTROLLER, {
        params: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          category,
          search,
          ...params,
        },
      })
      .then((data) => data.data)
  }

  async getById(bountyId: string) {
    return api.get<BountyData>(`${CONTROLLER}/${bountyId}`)
  }

  async getPinnedMissions() {
    return api.get(`${CONTROLLER}/priorities`)
  }

  async createPinnedMissions(priorities: MissionPriority[]) {
    return api.put(`${CONTROLLER}/priorities`, { priorities })
  }

  async update(bountyId: string, data: UpdateBounty): Promise<BountyData> {
    return api
      .post(`${CONTROLLER}/${bountyId}`, { ...data })
      .then((data) => data.data)
  }

  async getBountyBySlug(slug: string): Promise<BountyData> {
    const { data } = await api.get(`${CONTROLLER_USER}/slug/${slug}`)
    return data
  }
}

export const BountyService = new Bounty()

export interface IRewardBadge {
  badgeId: string
  amount: number
}

export type CreateBounty = {
  communityId: string
  title: string
  slug: string
  description: string
  endedAt: string
  startedAt: string
  thumbnail: string
  isEnabled: boolean
  recurrenceTime: BountyRecurrence
  category: BountyCategory
  numberOfReward: number
  availableOfReward: number
  rewardCCP: number
  rewardBadges: IRewardBadge[]
  subcategory?: BountySubcategory
  totalPrices?: string[]
  linkedMissions: string[]
  campaignId?: string
  featuredRewardIds?: string[]
}

export type BountyData = {
  _id: string
  communityId: string
  title: string
  slug: string
  description: string
  thumbnail: string
  startedAt: string
  endedAt: string
  isEnabled: boolean
  createdAt: string
  updatedAt: string
  recurrenceTime: number
  totalFavorite: number
  participants: number
  category: BountyCategory
  numberOfReward: number
  availableOfReward: number
  rewardCCP: number
  rewardBadges: IRewardBadge[]
  subcategory?: BountySubcategory
  totalPrices?: string[]
  priority?: number
  linkedMissions: string[]
  featuredRewardIds?: string[]
}

export type UpdateBounty = Partial<CreateBounty>

// Bounty Certificate
export enum BountyCertState {
  Processing = 'Processing',
  Completed = 'Completed',
  Claimed = 'Claimed',
}

export type BountyCertData = {
  _id: string
  bountyId: string
  bountyIndex: number
  uid: string
  completedTasks: string[]
  completedAt: Date
  state: BountyCertState
}

export type GetBountyDto = {
  communityId?: string
  page?: number
  pageSize?: number
  category?: BountyCategory
  isEnabled?: boolean
  isEnded?: boolean
  search?: string
  lteEnded?: Date
  gteEnded?: Date
  campaignId?: string
}
