import { Col, Row, Typography } from 'antd'

import CollectionControlBar from 'view/admin/marketplace/collectionManagement/controlBar'
import ListCollection from 'view/admin/marketplace/collectionManagement/listCollection'

const CollectionManagement = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type={'success'}>
          Collection Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <CollectionControlBar />
      </Col>
      <Col span={24}>
        <ListCollection />
      </Col>
    </Row>
  )
}

export default CollectionManagement
