import { Fragment, Key, useState } from 'react'

import { Card, Col, Row, Space, Typography } from 'antd'
import ChangeState from './changeState'

import { BountyService } from 'services/bountySystem/bounty'
import { notifyError, notifySuccess } from 'helper'
import { useFilterProps } from '../newAndFilterBar'
import { BountyState } from 'constant/bounty'
import { BountySelected } from '../index'

type BountiesSelectedProps = {
  bountySelected: BountySelected
  setBountySelected: (val: BountySelected) => void
  updateBounties: (val: Key[]) => void
}
const BountiesSelected = ({
  bountySelected,
  updateBounties,
  setBountySelected,
}: BountiesSelectedProps) => {
  const [loading, setLoading] = useState(false)
  const [{ status }] = useFilterProps()
  const listBounties = bountySelected[status]

  const isPublish = status === BountyState.Publish

  if (!listBounties.length) return <Fragment />

  const onConfirm = async () => {
    try {
      setLoading(true)
      for (const bountyId of listBounties) {
        const id = bountyId.toString()
        await BountyService.update(id, { isEnabled: !isPublish })
      }
      updateBounties(listBounties)
      notifySuccess('Updated bounty')
      setBountySelected({ ...bountySelected, [status]: [] })
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card bordered={false} style={{ background: '#1B2526' }}>
      <Row gutter={[24, 24]} justify="space-between" align="middle">
        <Col>
          <Space>
            <Typography.Text> {listBounties.length}</Typography.Text>
            <Typography.Text>
              {listBounties.length > 1 ? 'items' : 'item'} has been selected
            </Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            {/* <Button danger ghost icon={<IonIcon name="trash" />} disabled>
              Delete
            </Button> */}
            <ChangeState
              isPublish={isPublish}
              onConfirm={onConfirm}
              loading={loading}
            />
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

export default BountiesSelected
