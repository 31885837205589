import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Form, type FormInstance, Row, Col, Typography } from 'antd'

import { WrapLoading } from 'components/systems/loading'
import BaseInfoSection from './BaseInfoSection'
import GachaRewardSection from './GachaRewardSection'
import SeoInformation from 'components/seo/seoInformation'

import { useCreateGacha } from 'hooks/gacha/useCreateGacha'
import { useUpdateGacha } from 'hooks/gacha/useUpdateGacha'
import { useSEO } from 'hooks/seo/useSEO'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import { SeoType } from 'types/seo.type'

import './index.less'

type FormGachaProps = {
  isEdit?: boolean
  isFetching: boolean
  form: FormInstance
}

function FormGacha({ form, isEdit, isFetching }: FormGachaProps) {
  const { mutateAsync: createGacha, isLoading: isCreating } = useCreateGacha()
  const { mutateAsync: updateGacha, isLoading: isUpdating } = useUpdateGacha()
  const navigate = useNavigate()

  const { fetchSeoInfo, createSeoInfo, updateSeoInfo } = useSEO(SeoType.Gacha)

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      if (isEdit) {
        await updateGacha(values)
        await updateSeoInfo()
      } else {
        const data = await createGacha(values)
        await createSeoInfo(data._id!)
      }

      notifySuccess(`${isEdit ? 'Updated' : 'Created'}  Gacha`)
      navigate(ROUTES.GACHA.MANAGEMENT)
    } catch (error) {
      notifyError(error)
    }
  }

  useEffect(() => {
    isEdit && fetchSeoInfo()
  }, [fetchSeoInfo, isEdit])

  return (
    <WrapLoading loading={isFetching}>
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        labelCol={{ span: 3 }}
        labelAlign="left"
        onFinish={handleSubmit}
      >
        <BaseInfoSection form={form} />

        <GachaRewardSection form={form} />

        <Row className="seo-section">
          <Col span={3}>
            <Typography.Text>SEO Info (Optional)</Typography.Text>
          </Col>
          <Col span={21}>
            <SeoInformation />
          </Col>
        </Row>

        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </Row>
      </Form>
    </WrapLoading>
  )
}

export default FormGacha
