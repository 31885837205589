import { useQuery } from 'react-query'

import { marketplaceApi as api } from 'services/base-axios'

import { ChainID } from 'constant'

import { IWeb3Item, Web3Type } from 'types/web3-item.type'

const WEB3_ITEM_DEFAULT: IWeb3Item = {
  blockData: {},
  chainId: ChainID.BSC,
  itemId: '',
  web3Type: Web3Type.NFT,
}

const useWeb3Item = (web3ItemId: string) => {
  const { data } = useQuery(
    `WEB3_ITEM:${web3ItemId}`,
    async () => {
      return api.get<IWeb3Item>(`/web3-item/${web3ItemId}`).then((data) => data)
    },
    { enabled: !!web3ItemId, staleTime: 60000 }, // 60 second
  )
  return data?.data || WEB3_ITEM_DEFAULT
}

export default useWeb3Item
