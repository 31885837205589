import { Col, Row, Space, Typography } from 'antd'
import Brand from 'components/systems/brand'
import Socials from 'components/systems/socials'

import './index.less'

const Footer = () => {
  return (
    <Row gutter={[0, 32]} justify="center" className="footer">
      <Col md={24} lg={6}>
        <Space direction="vertical" size={16}>
          <Brand />
          <Typography.Text type="secondary">
            Space for web3 gamers
          </Typography.Text>
        </Space>
      </Col>
      <Col md={24} lg={12}>
        <Space direction="vertical" size={24}>
          <Typography.Title level={2}>Join Our Community</Typography.Title>
          <Typography.Text>
            Connect with Space3 community to update the newest thing about the
            market and our business.
          </Typography.Text>
          <Socials />
        </Space>
      </Col>
      <Col md={24} lg={6}>
        <Space size={32} align="start">
          <Space direction="vertical" size={16}>
            <Typography.Text>Gameplay</Typography.Text>
            <Typography.Text>NFT Systems</Typography.Text>
            <Typography.Text>Team</Typography.Text>
          </Space>
          <Space direction="vertical" size={16}>
            <Typography.Text>Privacy Policy</Typography.Text>
            <Typography.Text>Terms & Conditions</Typography.Text>
          </Space>
        </Space>
      </Col>
    </Row>
  )
}

export default Footer
