import { useCallback, useState } from 'react'

import { UploadFile } from 'antd'

import { StorageService } from 'services/bountySystem/storage'

export const useStorage = (cacheMaxAge?: number) => {
  const [uploading, setUploading] = useState<boolean>(false)

  const onUpload = useCallback(
    async (file: UploadFile) => {
      let thumbnailUrl = ''
      try {
        setUploading(true)
        const formData = new FormData()
        const fileData = file.originFileObj as File
        formData.append('file', fileData)
        const {
          data: { url },
        } = await StorageService.upload(formData, cacheMaxAge)
        thumbnailUrl = url
      } catch {
      } finally {
        setUploading(false)
        return thumbnailUrl
      }
    },
    [cacheMaxAge],
  )

  const onUploadFileList = useCallback(
    async (fileList: UploadFile[]) => {
      const oldFileList: string[] = []
      const uploadFileList: UploadFile[] = []

      for (const file of fileList) {
        if (file.url) oldFileList.push(file.url)
        if (file.originFileObj) uploadFileList.push(file)
      }

      const newFileList = await Promise.all(
        uploadFileList.map(async (file) => {
          const thumbnail = await onUpload(file)
          return thumbnail
        }),
      )

      return [...oldFileList, ...newFileList]
    },
    [onUpload],
  )

  return { onUpload, onUploadFileList, uploading }
}
