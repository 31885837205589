import moment from 'moment'

import { Typography } from 'antd'

import { TIME_FORMAT } from 'constant'

export const COLUMN_CARD_CODE = [
  {
    title: 'Code',
    dataIndex: 'code',
    render: (code: string) => (
      <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ margin: 0 }}>
        {code}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Country',
    dataIndex: 'country',
    render: (country: string) => <Typography.Text>{country}</Typography.Text>,
  },

  {
    title: 'Owner',
    dataIndex: 'owner',
    render: (owner: string) => <Typography.Text>{owner}</Typography.Text>,
  },
  {
    title: 'Deleted At',
    dataIndex: 'deletedAt',
    render: (deleteAt: string) => (
      <Typography.Text style={{ whiteSpace: 'nowrap' }}>
        {moment.utc(deleteAt).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
]
