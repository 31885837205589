import { bountySystemApi as api } from 'services/base-axios'

const CONTROLLER = '/oauth'
const PATHS = {
  login: '/exchange-admin-access-token',
}

class Auth {
  async login(code: string): Promise<{ data: { accessToken: string } }> {
    return api.post(CONTROLLER + PATHS.login, {
      code,
    })
  }
}

export const AuthService = new Auth()
