import { useMutation } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { CreatePlayerSeasonDto } from 'types/player-leaderboard/player-leaderboard-season.type'

export const useCreatePlayerSeasonMutation = () => {
  return useMutation((dto: CreatePlayerSeasonDto) =>
    PlayerLeaderboardService.createSeason(dto),
  )
}
