import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'

import { Button, Col, Input, Row, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import FilterContext from '../filterContext'

import { ROUTES } from 'constant/routes'

const NewAndFilterBar = () => {
  const navigate = useNavigate()
  const { setSearch } = useContext(FilterContext)
  const [keyword, setKeyword] = useState('')

  useDebounce(() => setSearch(keyword), 500, [keyword])

  const onCreateSeason = useCallback(() => {
    navigate(`${ROUTES.AFFILIATION_V2.NEW_SEASON}`)
  }, [navigate])

  return (
    <Row align="middle" gutter={[24, 24]} justify="space-between">
      <Col span={8}>
        <Input
          placeholder="Search season name..."
          onChange={(e) => setKeyword(e.target.value)}
          suffix={<IonIcon name="search-outline" />}
        />
      </Col>
      <Col>
        <Button type="primary" onClick={onCreateSeason}>
          <Space>
            <IonIcon name="add" />
            <Typography.Text strong>Create New Season</Typography.Text>
          </Space>
        </Button>
      </Col>
    </Row>
  )
}

export default NewAndFilterBar
