import { createGlobalState } from 'react-use'

import { Col, Row, Tabs, Typography } from 'antd'

import ListSubscriptionOverall from './listSubscriptionOverall'
import ListSubscriptionActivity from './listSubscriptionActivity'

import { Acceler8SubscriptionTab } from 'constant/acceler8'

const TAB_ITEMS = [
  { label: 'Overall', key: Acceler8SubscriptionTab.Overall },
  { label: 'Activities', key: Acceler8SubscriptionTab.Activities },
]

export const useAcceler8SubscriptionTabSelected =
  createGlobalState<Acceler8SubscriptionTab>(Acceler8SubscriptionTab.Overall)

const Acceler8SubscriptionManagement = () => {
  const [currentTab, setCurrentTab] = useAcceler8SubscriptionTabSelected()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Acceler8 Subscription Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs
          items={TAB_ITEMS}
          onChange={(e) => setCurrentTab(e as Acceler8SubscriptionTab)}
        />
      </Col>

      <Col span={24}>
        {currentTab === Acceler8SubscriptionTab.Overall ? (
          <ListSubscriptionOverall />
        ) : (
          <ListSubscriptionActivity />
        )}
      </Col>
    </Row>
  )
}

export default Acceler8SubscriptionManagement
