import { Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { INftMetadata } from 'types/nft-metadata.type'

export const COLUMN_NFT_METADATA: ColumnsType<INftMetadata> = [
  {
    title: 'Token ID',
    dataIndex: 'tokenId',
    render: (tokenId: number) => <Typography.Text>{tokenId}</Typography.Text>,
    width: '10%',
  },
  {
    title: 'URI',
    dataIndex: 'uri',
    render: (uri: string) => <Typography.Text>{uri}</Typography.Text>,
    width: '30%',
  },
  {
    title: 'Encrypted URI',
    dataIndex: 'encryptedUri',
    render: (encryptedUri: string) => (
      <Typography.Text>{encryptedUri}</Typography.Text>
    ),
    width: '35%',
  },
  {
    title: 'Encryption Key',
    dataIndex: 'encryptionKey',
    render: (encryptionKey: string) => (
      <Typography.Text>{encryptionKey}</Typography.Text>
    ),
    width: '15%',
  },
]
