import { useMemo } from 'react'

import { RewardType } from 'constant/reward'

const useBountyReward = ({
  rewardBadgeIds,
  rewardCCP,
}: {
  rewardCCP: number
  rewardBadgeIds: string[]
}) => {
  const bountyRewards = useMemo(() => {
    const result: { amount: number; rewardType: RewardType }[] = []
    const amountNFT = rewardBadgeIds?.length
    if (rewardCCP)
      result.push({ amount: rewardCCP, rewardType: RewardType.CCP })
    if (amountNFT)
      result.push({
        amount: amountNFT,
        rewardType: RewardType.NFTBadge,
      })
    return result
  }, [rewardBadgeIds, rewardCCP])

  return bountyRewards
}

export default useBountyReward
