import { Typography } from 'antd'

export const prizeColumn = [
  {
    title: 'Rank',
    dataIndex: 'title',
    render: (title: string) => <Typography.Text>{title}</Typography.Text>,
  },
  {
    title: 'Prize',
    dataIndex: 'priceText',
    render: (priceText: string) => (
      <Typography.Text>{priceText}</Typography.Text>
    ),
  },
]
