import React from 'react'

import { Button, Col, Form, Input, Row, Select, Typography } from 'antd'

import {
  MinusCircleOutlined as MinusCircleIcon,
  PlusCircleOutlined as PlusCircleIcon,
} from '@ant-design/icons/lib/icons'

import RequiredLabel from 'components/systems/requiredSymbol'

import { COMMUNITY_PROFILE_DEFAULT_SECTION } from 'constant/community-profile'
import { OfficialType } from 'types/community-profile'

function FormItemOfficialLink() {
  return (
    <Form.List name="officialLinks">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Row
              align="top"
              justify="end"
              gutter={8}
              style={{ position: 'relative' }}
            >
              <Col span={4} style={{ marginTop: 10, height: '100%' }}>
                {index === 0 && <RequiredLabel children="Official link" />}
              </Col>

              <Col span={19}>
                <Row justify="center">
                  <Typography.Text type="warning" style={{ marginBottom: 12 }}>
                    Official link {index + 1}
                  </Typography.Text>
                </Row>

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      key={field.name}
                      label={<RequiredLabel children="Official type" />}
                      name={[field.name, 'officialType']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Official type is required!',
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            label: 'Website',
                            value: OfficialType.Website,
                          },
                          {
                            label: 'Twitter',
                            value: OfficialType.Twitter,
                          },
                          {
                            label: 'Discord',
                            value: OfficialType.Discord,
                          },
                          {
                            label: 'Telegram',
                            value: OfficialType.Telegram,
                          },
                          {
                            label: 'Facebook',
                            value: OfficialType.Facebook,
                          },
                          {
                            label: 'Youtube',
                            value: OfficialType.Youtube,
                          },
                          {
                            label: 'Official link',
                            value: OfficialType.OfficialLink,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      key={field.name}
                      label={<RequiredLabel children="Url" />}
                      name={[field.name, 'url']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Url is required!',
                        },
                      ]}
                    >
                      <Input placeholder="Enter url" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={1} style={{ position: 'unset' }}>
                <Row justify="end" align="middle">
                  <MinusCircleIcon
                    style={{
                      color: 'red',
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      fontSize: 16,
                    }}
                    onClick={() => remove(field.name)}
                  />
                </Row>
              </Col>
            </Row>
          ))}
          <Form.Item wrapperCol={{ span: 20 }}>
            <Row justify="center" style={{ width: '100%' }}>
              <Button
                type="dashed"
                onClick={() => {
                  add(COMMUNITY_PROFILE_DEFAULT_SECTION.officialLinks[0])
                }}
                icon={<PlusCircleIcon />}
              >
                Add Official Link
              </Button>
            </Row>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default FormItemOfficialLink
