import { useNavigate } from 'react-router-dom'

import { Card, Col, Dropdown, Row, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'
import { ProductCategoryData } from 'services/marketplace/type'
import { CardCategoryAction } from '../type'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'

type CardCategoryProps = { categoryData: ProductCategoryData }
const CardCategory = ({ categoryData }: CardCategoryProps) => {
  const navigate = useNavigate()

  const onClick: MenuClickEventHandler = (e) => {
    if (e.key === CardCategoryAction.Edit)
      return navigate(
        `${ROUTES.REWARDS_CENTER.EDIT_CATEGORY_MANAGEMENT}/${categoryData._id}`,
      )
  }

  return (
    <Card
      style={{ background: '#0F191B', borderRadius: 4 }}
      bodyStyle={{ padding: '6px 24px' }}
      bordered={false}
    >
      <Row gutter={[24, 24]} align="middle">
        <Col flex="auto">
          <Typography.Text>{categoryData.title}</Typography.Text>
        </Col>
        <Col>
          <Dropdown.Button
            trigger={['click']}
            type="text"
            icon={<IonIcon name="ellipsis-vertical-outline" />}
            menu={{
              items: [
                {
                  label: CardCategoryAction.Edit,
                  key: CardCategoryAction.Edit,
                  icon: <IonIcon name="build-outline" />,
                },
                {
                  label: CardCategoryAction.Delete,
                  key: CardCategoryAction.Delete,
                  icon: <IonIcon name="trash-outline" />,
                },
              ],
              onClick,
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default CardCategory
