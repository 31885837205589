import { useEffect, useState } from 'react'
import { Button, Col, Input, Row, Table, Typography } from 'antd'
import { DateTime } from 'luxon'

import SpaceBetween from 'components/systems/spaceBetween'
import CustomPagination from 'components/systems/pagination'
import useHandle from 'hooks/useHandle'

import { DEFAULT_PAGE } from 'constant'
import { CCP_LEADERBOARD_COLUMN } from './ccpLeaderboardColumn'
import { useCreditPoints } from 'hooks/creditPoint/useCreditPoint'
import { CreditPointService } from 'services/bountySystem/creditPoint'

const { Search } = Input

const CCPLeaderboard = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [isExporting, setExporting] = useState(false)

  const { creditPoints, total, loading, fetchCreditPoints } = useCreditPoints()

  const onExport = useHandle(async () => {
    const timestamp = DateTime.now().toFormat('yyyy-MM-dd-HH-mm-ss')
    const filename = `ccp_leader_board-${timestamp}.csv`
    const blob = await CreditPointService.exportLeaderBoard()
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
  }, setExporting)

  useEffect(() => {
    fetchCreditPoints({ page })
  }, [fetchCreditPoints, page])

  useEffect(() => {
    const timeoutId = setTimeout(() => fetchCreditPoints({ search }), 500)
    return () => clearTimeout(timeoutId)
  }, [fetchCreditPoints, search])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          CCP Leaderboard
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col flex="auto">
                <Search
                  placeholder="Search Item"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ width: 400 }}
                />
              </Col>
              <Col>
                <Button ghost loading={isExporting} onClick={onExport}>
                  Export
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              className="table-border-collapse"
              columns={CCP_LEADERBOARD_COLUMN}
              dataSource={creditPoints}
              rowKey={({ _id }) => _id}
              pagination={false}
              loading={loading}
            />
          </Col>
          <Col span={24}>
            <SpaceBetween
              floatContent={
                <CustomPagination
                  page={page}
                  total={total}
                  onChange={setPage}
                />
              }
            >
              <Typography.Text type="success">Total: {total}</Typography.Text>
            </SpaceBetween>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default CCPLeaderboard
