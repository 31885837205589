import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { FindPlayerRankDto } from 'types/player-leaderboard/player-leaderboard-rank.type'

export const usePlayerRanks = (dto: FindPlayerRankDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_PLAYER_RANKS', dto],
    queryFn: () => PlayerLeaderboardService.findPlayerRanks(dto),
    enabled: !!dto.seasonId,
  })

  return {
    ...rest,
    data: data?.data || [],
    total: data?.total || 0,
  }
}
