import { Fragment } from 'react'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import { useGachaInfinite } from 'hooks/gacha/useGachaInfinite'

import { GachaService } from 'services/marketplace/gacha'
import { notifyError, notifySuccess } from 'helper'

import { BulkMintTicketDto, GachaStates } from 'types/gacha.type'

const MINT_TICKET_INITIAL_VALUE: BulkMintTicketDto = {
  gachaId: '',
  ticketList: [{ uid: '', quantity: 0 }],
}

const MintTicketForm = () => {
  const [form] = Form.useForm<BulkMintTicketDto>()

  const {
    data: gachaData,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useGachaInfinite({ state: GachaStates.OnGoing })

  const { mutate: mintTicket, isLoading } = useMutation({
    mutationKey: ['MINT_GACHA_TICKET'],
    mutationFn: (dto: BulkMintTicketDto) => GachaService.bulkMintTicket(dto),
    onError: (error: any) => {
      if (error instanceof AxiosError) {
        const message = error.response?.data.message
        if (Array.isArray(message)) {
          return notifyError('Mint failed: ' + message.join(', '))
        }
        return notifyError('Mint failed: ' + message)
      }

      notifyError(error?.message || 'Mint failed')
    },
    onSuccess: () => {
      notifySuccess('Mint ticket')
    },
  })

  return (
    <Form
      form={form}
      onFinish={(value) => {
        if (!value.ticketList.length) {
          return notifyError('Invalid ticket list')
        }
        mintTicket(value)
      }}
      labelCol={{ span: 5 }}
      labelAlign="left"
      initialValues={MINT_TICKET_INITIAL_VALUE}
    >
      <Form.Item
        name="gachaId"
        label="Select gacha"
        rules={[{ required: true }]}
      >
        <Select
          dropdownRender={(menu) => (
            <Fragment>
              {menu}
              {hasNextPage && (
                <Row>
                  <Col span={24}>
                    <Button
                      onClick={() => fetchNextPage()}
                      block
                      loading={isFetching}
                    >
                      Load more
                    </Button>
                  </Col>
                </Row>
              )}
            </Fragment>
          )}
        >
          {gachaData.map((gacha) => (
            <Select.Option value={gacha._id} key={gacha._id}>
              {gacha.title} - {gacha._id}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Ticket list" required>
        <Form.List name="ticketList">
          {(subFields, subOpt, { errors }) => (
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Form.ErrorList errors={errors} />
              </Col>
              {subFields.map((subField) => (
                <Col key={subField.name} span={24}>
                  <Row gutter={10} align="middle">
                    <Col span={10}>
                      <Form.Item
                        noStyle
                        name={[subField.name, 'uid']}
                        rules={[
                          {
                            message: 'uid must be a valid a mongoId',
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="uid" />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        noStyle
                        name={[subField.name, 'quantity']}
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              if (parseInt(value) >= 1) return Promise.resolve()

                              return Promise.reject(
                                new Error(
                                  'quantity must be a number which is greater than 1',
                                ),
                              )
                            },
                          },
                        ]}
                      >
                        <Input placeholder="quantity" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name)
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              ))}
              <Col span={24}>
                <Button type="dashed" onClick={() => subOpt.add()} block>
                  + Add uid
                </Button>
              </Col>
            </Row>
          )}
        </Form.List>
      </Form.Item>

      <Row justify="end">
        <Col>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Mint ticket
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default MintTicketForm
