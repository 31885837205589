import { bountySystemApi as api } from 'services/base-axios'

import { CreateTaskDto, UpdateTaskDto } from 'services/bountySystem/task/type'

const CONTROLLER = '/admin/task'

class Task {
  async getAll() {
    return api.get(CONTROLLER)
  }

  async getById(taskId: string) {
    return api.get(CONTROLLER + `/${taskId}`)
  }

  async create(data: CreateTaskDto) {
    return api.post(CONTROLLER, data)
  }

  async update(taskId: string, data: UpdateTaskDto) {
    return api.patch(CONTROLLER + '/' + taskId, data).then((data) => data.data)
  }

  async getByBountyId(bountyId: string) {
    return api
      .get(CONTROLLER + '/get-by-bounty', {
        params: { bountyId },
      })
      .then((data) => data.data)
  }
}

export const TaskService = new Task()
