import { configureStore } from '@reduxjs/toolkit'
import middleware from './middleware'

import ui from './ui.reducer'
import sidebar from './sidebar.reducer'
import user from './user.reducer'
import tasks from './tasks.reducer'
import products from './products.reducer'
import productFilters from './productFilters.reducer'
import customers from './customer.reducer'
import main from './main.reducer'
import nft from './walletNFT.reducer'
import chain from './chain.reducer'

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
  reducer: {
    sidebar,
    ui,
    user,
    tasks,
    products,
    productFilters,
    customers,
    main,
    nft,
    chain,
  },
})
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
