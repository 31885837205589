import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Col, Row, Typography } from 'antd'
import { PlusSquareOutlined as AddSquareIcon } from '@ant-design/icons'

import RedemptionTable from './RedemptionTable'
import CustomPagination from 'components/systems/pagination'

import { useRedemptions } from 'hooks/redemption/useRedemptions'

import { ROUTES } from 'constant/routes'
import { DEFAULT_PAGE_SIZE } from 'constant'

function RedemptionManagement() {
  const navigate = useNavigate()

  const [page, setPage] = useState<number>(1)

  const { data, total, isLoading } = useRedemptions({
    offset: (page - 1) * DEFAULT_PAGE_SIZE,
    limit: DEFAULT_PAGE_SIZE,
  })

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Redemption Management
        </Typography.Title>
      </Col>

      <Col span={24}>
        <Row justify="end" gutter={[12, 12]}>
          <Col style={{ width: 180 }}>
            <Button
              icon={<AddSquareIcon />}
              type="primary"
              block
              onClick={() => navigate(ROUTES.REDEMPTION.NEW_REDEMPTION)}
            >
              New Redemption
            </Button>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <RedemptionTable data={data} isLoading={isLoading} />
      </Col>

      <Col span={24} style={{ marginTop: 24 }}>
        <Row justify="space-between">
          <Col>
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>

          <Col>
            <CustomPagination
              total={total}
              page={page}
              onChange={setPage}
              pageSize={DEFAULT_PAGE_SIZE}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default RedemptionManagement
