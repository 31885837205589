import { useState } from 'react'

import { Col, Row, Input, Form, Typography, Button, Space } from 'antd'

import { useCommunity } from 'hooks/community/useCommunity'

import { bountySystemApi as api } from 'services/base-axios'
import { notifyError, notifySuccess } from 'helper'

const { Item, useForm } = Form

const Social = () => {
  const [loading, setLoading] = useState(false)
  const [form] = useForm()
  const { data } = useCommunity()

  const onSummit = async () => {
    try {
      setLoading(true)
      const formData = form.getFieldsValue()
      await api.patch('/community', formData)
      notifySuccess('Updated Profile')
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form form={form} onFinish={onSummit}>
      <Row gutter={[24, 24]} style={{ paddingTop: 20 }}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>Twitter</Typography.Text>
            <Item
              name="twitterLink"
              initialValue={data?.data.twitterLink || ''}
            >
              <Input placeholder="Enter Twitter URL" />
            </Item>
          </Space>
        </Col>

        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>Discord</Typography.Text>
            <Item
              name="discordLink"
              initialValue={data?.data?.discordLink || ''}
            >
              <Input placeholder="Enter Discord URL" />
            </Item>
          </Space>
        </Col>

        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>Facebook</Typography.Text>
            <Item
              name="facebookLink"
              initialValue={data?.data?.facebookLink || ''}
            >
              <Input placeholder="Enter Facebook URL" />
            </Item>
          </Space>
        </Col>

        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>Youtube</Typography.Text>
            <Item
              name="youtubeLink"
              initialValue={data?.data?.youtubeLink || ''}
            >
              <Input placeholder="Enter Youtube URL" />
            </Item>
          </Space>
        </Col>

        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text type="secondary">Website</Typography.Text>
            <Item name="website" initialValue={data?.data.website || ''}>
              <Input placeholder="Enter Website URL" />
            </Item>
          </Space>
        </Col>

        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text type="secondary">Telegram</Typography.Text>
            <Item
              name="telegramLink"
              initialValue={data?.data.telegramLink || ''}
            >
              <Input placeholder="Enter Telegram URL" />
            </Item>
          </Space>
        </Col>

        <Col span={24}>
          <Button type="primary" loading={loading} htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default Social
