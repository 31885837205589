import { useCallback, useEffect, useMemo, useState } from 'react'

import { BountyData, BountyService } from 'services/bountySystem/bounty'
import {
  BountyCategory,
  BountyRecurrence,
  BountySubcategory,
} from 'constant/bounty'

export const DUMMY_BOUNTY_DATA: BountyData = {
  _id: '',
  communityId: '',
  title: '',
  slug: '',
  description: '',
  startedAt: '',
  endedAt: '',
  isEnabled: true,
  thumbnail: '',
  recurrenceTime: BountyRecurrence.Once,
  category: BountyCategory.DApp,
  createdAt: '',
  updatedAt: '',
  totalFavorite: 0,
  participants: 0,
  availableOfReward: 0,
  numberOfReward: 0,
  rewardBadges: [],
  rewardCCP: 0,
  subcategory: BountySubcategory.None,
  totalPrices: [],
  linkedMissions: [],
}

export const DUMMY_GET_BOUNTY_DATA: BountyData = {
  _id: '',
  communityId: '',
  title: '',
  slug: '',
  description: '',
  startedAt: '',
  endedAt: '',
  isEnabled: true,
  thumbnail: '',
  recurrenceTime: BountyRecurrence.Once,
  category: BountyCategory.DApp,
  createdAt: '',
  updatedAt: '',
  totalFavorite: 0,
  participants: 0,
  availableOfReward: 0,
  numberOfReward: 0,
  rewardBadges: [],
  rewardCCP: 0,
  subcategory: BountySubcategory.None,
  totalPrices: [],
  linkedMissions: [],
}

export const useGetBountyData = () => {
  const [loading, setLoading] = useState(false)

  const getBountyData = useCallback(
    async (bountyId: string): Promise<BountyData> => {
      if (!bountyId) return DUMMY_GET_BOUNTY_DATA
      try {
        setLoading(true)
        const { data } = await BountyService.getById(bountyId)
        return data
      } catch (error) {
        return DUMMY_GET_BOUNTY_DATA
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  return { getBountyData, loading }
}

export const useBountyData = (bountyId: string) => {
  const [bountyData, setBountyData] = useState<BountyData>(DUMMY_BOUNTY_DATA)
  const { getBountyData } = useGetBountyData()

  useEffect(() => {
    ;(async () => {
      const bounty = await getBountyData(bountyId)
      if (bounty) setBountyData({ ...bounty })
    })()
  }, [bountyId, getBountyData])

  return bountyData
}

export const useBountyIndex = (bountyId: string) => {
  const bountyData = useBountyData(bountyId)

  const index = useMemo(() => {
    const date = new Date()
    if (!bountyData.recurrenceTime) return 0
    const lifeTime = date.getTime() - new Date(bountyData.startedAt).getTime()
    const index = Number.parseInt(
      (lifeTime / bountyData.recurrenceTime).toFixed(0),
    )
    return index
  }, [bountyData.recurrenceTime, bountyData.startedAt])

  return index
}
