import { useCallback, useEffect, useState } from 'react'

import { Card, Col, Row, Typography } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'

import { ChequeService } from 'services/bountySystem/cheque'
import { useCountCheque } from 'hooks/cheque/useCheques'
import { useParseChain } from 'hooks/badges/useBadges'
import { ChainID, ChainLabel } from 'constant'
import { BadgeConfigs } from 'services/bountySystem/items'

const DIVIDER = { borderRight: 'solid 2px #1B2526' }

type HeroProps = {
  badgeConfig: BadgeConfigs
  badgeId: string
}

const Hero = ({ badgeConfig, badgeId }: HeroProps) => {
  const [total, setTotal] = useState<Record<ChainID, number>>()
  const chains = useParseChain(badgeConfig)
  const { total: totalHolders } = useCountCheque({ filter: { badgeId } })
  const { total: totalUsed } = useCountCheque({
    filter: { badgeId, isUsed: true },
  })

  const countTotalByChain = useCallback(async () => {
    const chains = Object.keys(badgeConfig) as ChainID[]
    let result: any = {}
    for (const chainId of chains) {
      const data: number = await ChequeService.count({
        filter: { chainId, badgeId },
      })
      result[chainId] = data
    }
    return setTotal(result)
  }, [badgeConfig, badgeId])

  useEffect(() => {
    countTotalByChain()
  }, [countTotalByChain])

  return (
    <Card
      bordered={false}
      style={{ background: '#0F191B' }}
      bodyStyle={{ padding: '16px 24px' }}
    >
      <Row gutter={[12, 12]} style={{ textAlign: 'center' }}>
        {/* Total badge */}
        <Col xs={24} sm={12} md={12} xl={6} style={DIVIDER}>
          <SpaceVertical align="center">
            <Typography.Text type="secondary">
              Total badges holders
            </Typography.Text>
            <Typography.Title type="success" level={3}>
              {totalHolders}
            </Typography.Title>
          </SpaceVertical>
        </Col>
        {/* Total used */}
        <Col xs={24} sm={12} md={12} xl={6} style={DIVIDER}>
          <SpaceVertical align="center">
            <Typography.Text type="secondary">Used</Typography.Text>
            <Typography.Title type="success" level={3}>
              {totalUsed}
            </Typography.Title>
          </SpaceVertical>
        </Col>
        {/* Total badge BNB*/}
        {chains.map((chain, index) => (
          <Col
            xs={24}
            sm={12}
            md={12}
            xl={12 / chains.length}
            key={chain}
            style={index !== chains.length - 1 ? DIVIDER : {}}
          >
            <SpaceVertical align="center">
              <Typography.Text type="secondary">
                Badges on {ChainLabel[chain]}
              </Typography.Text>
              <Typography.Title type="success" level={3}>
                {total?.[chain] || 0}
              </Typography.Title>
            </SpaceVertical>
          </Col>
        ))}
      </Row>
    </Card>
  )
}

export default Hero
