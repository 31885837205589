import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

export const usePlayerAccumulativePoints = (userIds: string[]) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_PLAYER_ACCUMULATIVE_POINTS', userIds],
    queryFn: () => PlayerLeaderboardService.getAccumulativePoints(userIds),
    enabled: !!userIds.length,
  })

  return {
    ...rest,
    data: data || [],
  }
}
