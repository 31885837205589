import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import useSWR from 'swr'

import {
  BadgeConfigs,
  BadgeData,
  BadgeService,
} from 'services/bountySystem/items'
import { bountySystemApi as api } from 'services/base-axios'

import { ChainID } from 'constant'

export const DUMMY_BADGE_DATA: BadgeData = {
  _id: '',
  title: '',
  configs: { [ChainID.BSC]: { collection: '' } },
  expiredAt: '',
  thumbnail: '',
  description: '',
  supply: 0,
}

type UseBadgeProps = {
  page?: number
  pageSize?: number
  search?: string
  chainId?: ChainID
  supply?: number
}

export const useBadges = (props: UseBadgeProps) => {
  const [badges, setBadges] = useState<BadgeData[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const { page, pageSize, search, chainId, supply } = props

  const fetchBadges = useCallback(async () => {
    try {
      setLoading(true)
      const { data, total } = await BadgeService.getAll({
        page,
        pageSize,
        search,
        chainId,
        supply,
      })

      setBadges(data)
      setTotal(total)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [page, pageSize, search, chainId, supply])

  useEffect(() => {
    fetchBadges()
  }, [fetchBadges])

  return { badges, total, loading }
}

export const useBadgeData = (badgeId: string) => {
  const [badgeData, setBadge] = useState<BadgeData>(DUMMY_BADGE_DATA)
  const [loading, setLoading] = useState(false)

  const fetchBadge = useCallback(async () => {
    if (!badgeId) return
    try {
      setLoading(true)
      const badgeData = await BadgeService.getById(badgeId)
      setBadge(badgeData)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [badgeId])

  useEffect(() => {
    fetchBadge()
  }, [fetchBadge])

  return { badgeData, loading }
}

export const useBadgeDetails = (badgeId: string) => {
  return useQuery({
    queryKey: ['GET_BADGE_DETAILS', badgeId],
    queryFn: () => BadgeService.getById(badgeId),
    enabled: !!badgeId,
  })
}

export const useAllBadges = (props: UseBadgeProps) => {
  const [loading, setLoading] = useState(false)
  const { search, chainId, supply } = props

  const { data: badges, mutate } = useSWR(`user_history`, async () => {
    try {
      let offset = 0
      let limit = 500
      let badges: BadgeData[] = []
      setLoading(true)
      while (true) {
        const { data } = await api.post('/badges', {
          limit,
          offset,
          search,
          chainId,
          supply,
        })

        badges = badges.concat(data)
        if (data.length < limit) break
        else offset = offset + limit
      }
      return badges
    } catch (err) {
    } finally {
      setLoading(false)
    }
  })

  const refresh = useCallback(() => mutate(), [mutate])

  return { badges, loading, refresh }
}

export const useParseChain = (badgeConfigs: BadgeConfigs) => {
  return Object.keys(badgeConfigs) as ChainID[]
}
