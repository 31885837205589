import { useCallback, useEffect, useState } from 'react'

import {
  CreditPointData,
  CreditPointService,
} from 'services/bountySystem/creditPoint'

export const useCreditPoints = () => {
  const [creditPoints, setCreditPoints] = useState<CreditPointData[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchCreditPoints = useCallback(
    async ({
      page,
      pageSize,
      search,
    }: {
      page?: number
      pageSize?: number
      search?: string
    }) => {
      try {
        setLoading(true)
        const { data, total } = await CreditPointService.getAll({
          page,
          pageSize,
          search,
        })
        setCreditPoints(data)
        setTotal(total)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  useEffect(() => {
    fetchCreditPoints({})
  }, [fetchCreditPoints])

  return { creditPoints, total, loading, fetchCreditPoints }
}
