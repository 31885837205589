import { Col, Row, Image, Typography, Space } from 'antd'
import IonIcon from 'components/systems/ionIcon'

export type ItemNameProps = {
  name: string
  favorite: number
  view: number
  thumbnail: string
}

const ItemName = ({ name, favorite, view, thumbnail }: ItemNameProps) => {
  return (
    <Row gutter={[10, 10]} wrap={false}>
      <Col>
        <Image
          src={thumbnail}
          style={{ borderRadius: 4 }}
          width={44}
          height={44}
          preview={false}
        />
      </Col>
      <Col flex={1}>
        <Row style={{ flexDirection: 'column' }}>
          <Col>
            <Typography.Text style={{ margin: 0 }} ellipsis>
              {name}
            </Typography.Text>
          </Col>
          <Col>
            <Space style={{ width: '100%' }} size={8}>
              <Typography.Text type="secondary">
                <IonIcon name="eye-outline" /> {view}
              </Typography.Text>
              <Typography.Text type="secondary">
                <IonIcon name="heart-outline" /> {favorite}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ItemName
