import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { FindAcceler8SeasonDto } from 'types/acceler8/acceler8-season.type'

export const useAcceler8Seasons = (dto?: FindAcceler8SeasonDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_SEASONS', dto],
    queryFn: () => Acceler8Service.findSeasons(dto),
  })

  return {
    ...rest,
    data: data?.data,
    total: data?.total ?? 0,
  }
}
