import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Radio, Row, Space, Typography } from 'antd'
import { Button, Checkbox, Col, InputNumber } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

import NFTsSelected from './nftSelected'
import SpaceBetween from 'components/systems/spaceBetween'
import SpaceVertical from 'components/systems/spaceVertical'
import IonIcon from 'components/systems/ionIcon'
import CardInfo from 'components/systems/cardInfo'
import SelectNFTBadge from 'components/systems/selectNFTBadge'
import FeaturedRewards from './featuredRewards'

import {
  CreateTasksType,
  useBountySetup,
  useFileList,
  useUpdateBountyForm,
} from 'view/bounty/management'
import { useCreateBountyStep } from 'view/bounty/management'
import { useCreateBountyTasks } from 'view/bounty/management'

import { useSEO } from 'hooks/seo/useSEO'
import { useStorage } from 'hooks/systems/useStorage'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'

import { TaskService } from 'services/bountySystem/task'
import { BountyService, CreateBounty } from 'services/bountySystem/bounty'
import { FederatedApiService } from 'services/bountySystem/federatedApi'

import { notifyError } from 'helper'

import { BountyPrizes, CreateAndUpdateTabs } from 'constant/bounty'
import { ROUTES } from 'constant/routes'
import { ValueOf } from 'constant'
import { RewardType } from 'constant/reward'

import { SeoType } from 'types/seo.type'
import { TaskFederatedApiConfigs, TaskType } from 'templates/types'

const OPTION_METHOD = [
  {
    label: BountyPrizes.FCFS,
    value: BountyPrizes.FCFS,
  },
  {
    label: BountyPrizes.LuckyDraw,
    value: BountyPrizes.LuckyDraw,
    disabled: true,
  },
]

const REWARD_OPTION = [
  { label: 'CCP', value: RewardType.CCP },
  { label: 'NFT Badge', value: RewardType.NFTBadge },
]

const FormPrizes = () => {
  const [loading, setLoading] = useState(false)
  const [currentCommunity] = useCurrentCommunity()
  const [open, setOpen] = useState(false)
  const [selectedReward, setSelectedReward] = useState<RewardType[]>([])
  const [, setStep] = useCreateBountyStep()
  const [bountyData, setBountyData] = useBountySetup()
  const { onBountyUpdate, formLoading } = useUpdateBountyForm()
  const [tasks] = useCreateBountyTasks()
  const [fileList, setFileList] = useFileList()
  const bountyId = useParams().bountyId || ''
  const navigate = useNavigate()
  const { onUpload } = useStorage(604800)
  const isUpdateForm = !!bountyId
  const { createSeoInfo } = useSEO(SeoType.Bounty)

  const onChange = (name: keyof CreateBounty, value: ValueOf<CreateBounty>) => {
    setBountyData({ ...bountyData, [name]: value })
  }

  const onCheck = (checkedValues: CheckboxValueType[]) => {
    const newBountyData = {
      ...bountyData,
      ...(!checkedValues.includes(RewardType.NFTBadge) && { rewardBadges: [] }),
      ...(!checkedValues.includes(RewardType.CCP) && { rewardCCP: 0 }),
    }
    setBountyData(newBountyData)
    setSelectedReward(checkedValues as RewardType[])
  }

  const onCreateTasks = async (bountyId: string, tasks: CreateTasksType[]) => {
    for (const task of tasks) {
      if (task.type === TaskType.FederatedApi) {
        const configs = task.configs as TaskFederatedApiConfigs
        const headers: Record<string, string> = {}
        configs.headers?.map((value: any) => {
          headers[value.key] = value.value
          return null
        })
        const { data } = await FederatedApiService.create({
          ...configs,
          headers,
        })
        task.configs = { federatedApiId: data._id } as any
      }
      await TaskService.create({
        bountyId,
        title: task.title,
        description: task.description,
        configs: task.configs,
        type: task.type,
        thumbnail: '',
        verificationMethod: task.verificationMethod,
      })
    }
  }

  const onCreate = async () => {
    if (bountyData.numberOfReward <= 0) {
      return window.notify({
        type: 'error',
        description: 'Total Rewards (Greater than 0)',
      })
    }

    if (!selectedReward.length) {
      return window.notify({
        type: 'error',
        description: 'Please choose at least one reward.',
      })
    }

    if (!selectedReward.includes(RewardType.CCP)) {
      setBountyData({ ...bountyData, rewardCCP: 0 })
    } else if (bountyData.rewardCCP <= 0) {
      return window.notify({
        type: 'error',
        description: 'CCP reward (Greater than 0)',
      })
    }

    if (!selectedReward.includes(RewardType.NFTBadge)) {
      setBountyData({ ...bountyData, rewardBadges: [] })
    } else if (!bountyData.rewardBadges.length) {
      return window.notify({
        type: 'error',
        description: 'Badge reward must be at least 1',
      })
    }

    try {
      setLoading(true)
      if (!fileList.length) throw new Error('Invalid bounty thumbnail')
      const thumbnail = await onUpload(fileList[0])
      const nextBountyData = {
        ...bountyData,
        communityId: currentCommunity._id,
        thumbnail,
        availableOfReward: bountyData.numberOfReward,
      }
      /** Init Bounty */
      const bounty = await BountyService.create(nextBountyData)
      /** Init Tasks */
      await onCreateTasks(bounty.data._id, tasks)

      // TODO: Implement create SEO later
      await createSeoInfo(bounty.data._id)

      window.notify({
        type: 'success',
        description: 'Create bounty successfully',
      })
      setFileList([])
      setStep(CreateAndUpdateTabs.SetUp)
      navigate(ROUTES.BOUNTY.INDEX)
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
    // Flow create
  }

  const handleUpdateBounty = async () => {
    if (bountyData.numberOfReward <= 0) {
      return window.notify({
        type: 'error',
        description: 'Total Rewards (Greater than 0)',
      })
    }

    if (!selectedReward.length) {
      return window.notify({
        type: 'error',
        description: 'Please choose at least one reward.',
      })
    }

    if (selectedReward.includes(RewardType.CCP) && bountyData.rewardCCP <= 0) {
      return window.notify({
        type: 'error',
        description: 'CCP reward (Greater than 0)',
      })
    }

    if (
      selectedReward.includes(RewardType.NFTBadge) &&
      !bountyData.rewardBadges.length
    ) {
      return window.notify({
        type: 'error',
        description: 'Badge reward must be at least 1',
      })
    }
    onBountyUpdate()
  }

  const handleDeleteNFT = useCallback(
    (badgeId: string) => {
      const nextRewardBadges = bountyData.rewardBadges
      const index = nextRewardBadges.findIndex((e) => e.badgeId === badgeId)
      if (index !== -1) nextRewardBadges.splice(index, 1)
      return setBountyData({ ...bountyData, rewardBadges: nextRewardBadges })
    },
    [bountyData, setBountyData],
  )

  useEffect(() => {
    const { rewardCCP, rewardBadges } = bountyData

    if (!bountyId) return

    const nextRewards: RewardType[] = []
    if (rewardCCP) nextRewards.push(RewardType.CCP)
    if (rewardBadges.length) nextRewards.push(RewardType.NFTBadge)
    setSelectedReward(nextRewards)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <SpaceVertical>
          <Typography.Text type="secondary">Rewards Method</Typography.Text>
          <Radio.Group
            defaultValue={BountyPrizes.FCFS}
            options={OPTION_METHOD}
            disabled={isUpdateForm}
          />
        </SpaceVertical>
      </Col>
      <Col span={24}>
        <SpaceVertical>
          <Typography.Text type="secondary">
            Total Rewards (Greater than 0)
          </Typography.Text>
          <InputNumber
            min={1}
            placeholder="Enter total Rewards"
            value={bountyData.numberOfReward}
            onChange={(val) => onChange('numberOfReward', val || 0)}
            disabled={isUpdateForm}
          />
        </SpaceVertical>
      </Col>
      <Col span={24}>
        <Checkbox.Group
          value={selectedReward}
          options={REWARD_OPTION}
          onChange={onCheck}
        />
      </Col>
      {selectedReward.includes(RewardType.CCP) && (
        <Col span={24}>
          <CardInfo title="Number of CCP">
            <SpaceVertical>
              <Typography.Text type="secondary">Number of CCP</Typography.Text>
              <InputNumber
                min={1}
                value={bountyData.rewardCCP}
                placeholder="Enter amount CCP rewards"
                onChange={(val) => onChange('rewardCCP', val || 1)}
              />
            </SpaceVertical>
          </CardInfo>
        </Col>
      )}
      {selectedReward.includes(RewardType.NFTBadge) && (
        <Col span={24}>
          <CardInfo title="NFT Reward">
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <SpaceBetween
                  floatContent={
                    <Fragment>
                      <Button
                        icon={<IonIcon name="add-outline" />}
                        onClick={() => setOpen(true)}
                        ghost
                      >
                        Add Collection
                      </Button>
                      <SelectNFTBadge
                        badgeSelected={bountyData.rewardBadges?.map(
                          (e) => e.badgeId,
                        )}
                        setBadgeSelected={(badges) => {
                          const keepingValue = bountyData.rewardBadges?.filter(
                            (e) => badges.includes(e.badgeId),
                          )
                          const removedValueIds = keepingValue?.map(
                            (e) => e.badgeId,
                          )
                          const addedValue = badges
                            .filter((e) => !removedValueIds.includes(e))
                            .map((e) => ({ badgeId: e, amount: 1 }))

                          onChange('rewardBadges', [
                            ...keepingValue,
                            ...addedValue,
                          ])
                        }}
                        open={open}
                        setOpen={setOpen}
                        multiple
                        supply={bountyData.numberOfReward}
                      />
                    </Fragment>
                  }
                >
                  <Typography.Text type="secondary">NFT Reward</Typography.Text>
                </SpaceBetween>
              </Col>
              <Col span={24}>
                <NFTsSelected onDeleteNFT={handleDeleteNFT} />
              </Col>
            </Row>
          </CardInfo>
        </Col>
      )}

      <Col span={24}>
        <FeaturedRewards />
      </Col>

      <Col span={24}>
        <Space>
          <IonIcon
            style={{ color: '#DB4646', fontSize: 16 }}
            name="alert-circle-outline"
          />
          <Typography.Text type="danger">
            It is mandatory to enter all information of 1 or both types of
            rewards
          </Typography.Text>
        </Space>
      </Col>

      <Col flex="auto">
        <Button
          ghost
          icon={<IonIcon name="arrow-back-outline" />}
          onClick={() => setStep(CreateAndUpdateTabs.Enter)}
        >
          Back
        </Button>
      </Col>
      {isUpdateForm && (
        <Button
          loading={formLoading}
          onClick={handleUpdateBounty}
          style={{ minWidth: 130 }}
          type="primary"
        >
          Update
        </Button>
      )}

      {!isUpdateForm && (
        <Col>
          <Button
            type="primary"
            onClick={onCreate}
            loading={loading}
            icon={<IonIcon name="save-outline" />}
            style={{ minWidth: 90 }}
          >
            Save
          </Button>
        </Col>
      )}
    </Row>
  )
}
export default FormPrizes
