import { useQuery } from 'react-query'

import { LeaderboardService } from 'services/bountySystem/leaderboard/leaderboard'

import { CommunityLeaderboardExtended } from 'types/leaderboard.type'

export const useCommunityLeaderboard = (id: string) => {
  return useQuery<CommunityLeaderboardExtended>({
    queryKey: [`GET_ONE_COMMUNITY_LEADERBOARD_DATA`, id],
    queryFn: () => LeaderboardService.getCommunityDetails(id),
    cacheTime: 0,
  })
}
