import React, { useState } from 'react'
import { createGlobalState } from 'react-use'

import { Col, Row, Select } from 'antd'
import TableReportHistory from './tableReportHistory'
import CustomPagination from 'components/systems/pagination'

import { DEFAULT_PAGE } from 'constant'
import { ReportHistoryFilter } from './type'

import './index.less'
import SearchReport from './searchReport'
import { TableHistoryReportData, useFilterBounty } from '../index'

export const useTotalBountyCerts = createGlobalState(0)

type ReportHistoryProps = {
  startDate: string
  endDate: string
  total: number
  data: TableHistoryReportData[]
}

const ReportHistory = ({
  endDate,
  startDate,
  data,
  total,
}: ReportHistoryProps) => {
  const [filterKey, setFilterKey] = useFilterBounty()
  const [page, setPage] = useState(DEFAULT_PAGE)

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <SearchReport page={page} />
          </Col>
          <Col>
            <Select
              value={filterKey}
              onChange={setFilterKey}
              style={{ minWidth: 150 }}
            >
              <Select.Option
                key={ReportHistoryFilter.All}
                value={ReportHistoryFilter.All}
              >
                Show All
              </Select.Option>

              <Select.Option
                key={ReportHistoryFilter.Processing}
                value={ReportHistoryFilter.Processing}
              >
                Processing
              </Select.Option>

              <Select.Option
                key={ReportHistoryFilter.Completed}
                value={ReportHistoryFilter.Completed}
              >
                Completed
              </Select.Option>
              <Select.Option
                key={ReportHistoryFilter.Claimed}
                value={ReportHistoryFilter.Claimed}
              >
                Claimed
              </Select.Option>
            </Select>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <TableReportHistory
          startDate={startDate}
          endDate={endDate}
          data={data}
        />
      </Col>
      <Col span={24}>
        <CustomPagination total={total} onChange={setPage} page={page} />
      </Col>
    </Row>
  )
}

export default ReportHistory
