import { ChangeEvent, useMemo, useState } from 'react'

import { Typography, UploadFile } from 'antd'
import { Button, Col, Input, Row, Space } from 'antd'
import CardInfo from 'components/systems/cardInfo'
import RequiredLabel from 'components/systems/requiredSymbol'
import UploadPicture from 'components/systems/uploadPicture'

import { useStorage } from 'hooks/systems/useStorage'
import { notifyError, notifySuccess } from 'helper'

import {
  CreateMintSolanaNFT,
  MintSolanaNFTService,
} from 'services/marketplace/solana/mintNft'
import SpaceVertical from 'components/systems/spaceVertical'

const DUMMY_BADGE_DATA: CreateMintSolanaNFT = {
  name: 'Space3 name',
  description: 'Space3 description',
  symbol: 'SP3D',
  thumbnail: '',
}

const NewSolanaNFT = () => {
  const [nftInfo, setNftInfo] = useState<CreateMintSolanaNFT>(DUMMY_BADGE_DATA)
  const [image, setImage] = useState<UploadFile[]>()
  const [loading, setLoading] = useState(false)
  const { onUpload } = useStorage(604800)

  const disabled = useMemo(() => {
    const { name, description, symbol } = nftInfo
    return !name || !description || !symbol
  }, [nftInfo])

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    return setNftInfo({ ...nftInfo, [name]: value })
  }

  const onConfirm = async () => {
    if (!image || disabled) return
    try {
      setLoading(true)
      const thumbnail = await onUpload(image[0])
      const params = { ...nftInfo, thumbnail }
      await MintSolanaNFTService.create(params)
      notifySuccess('Create Solana NFT successfully!')
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Typography.Title type="success" level={3}>
          Create Solana NFT
        </Typography.Title>
      </Col>
      <Col span={24}>
        <CardInfo title="NFT Info">
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text type="secondary">Upload Image</Typography.Text>
                <UploadPicture
                  onChangeFile={({ fileList }) => setImage(fileList)}
                  maxCount={1}
                />
                <Typography.Text type="secondary">
                  Upload or drag and drop a PNG, GIF, or JPG to display in the
                  app.
                </Typography.Text>
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RequiredLabel>Name (5-64 characters)</RequiredLabel>
                <Input
                  onChange={onChange}
                  name="name"
                  value={nftInfo.name}
                  placeholder="Enter Name"
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RequiredLabel>Description (5-800 characters)</RequiredLabel>
                <Input.TextArea
                  onChange={(e) =>
                    setNftInfo({ ...nftInfo, description: e.target.value })
                  }
                  value={nftInfo.description}
                  placeholder="Enter description"
                  rows={4}
                  name="description"
                />
              </Space>
            </Col>
            <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Text type="secondary">Symbol</Typography.Text>
                <Input
                  value={nftInfo.symbol}
                  placeholder="Enter Symbol"
                  name="symbol"
                  onChange={onChange}
                />
              </Space>
            </Col>
          </Row>
        </CardInfo>
      </Col>

      <Col span={24}>
        <SpaceVertical size={24} align="end" block={false}>
          <Button
            onClick={onConfirm}
            disabled={disabled}
            style={{ width: 130 }}
            type="primary"
            loading={loading}
          >
            Mint
          </Button>
        </SpaceVertical>
      </Col>
    </Row>
  )
}

export default NewSolanaNFT
