import { useFilterProps } from 'view/itemManagement/itemList/newAndfilterBar/filterAndSearch'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { notifyError } from 'helper'
import { AppState } from 'store'
import { ProductState } from 'store/products.reducer'
import { ProductCategoryData } from 'services/marketplace/type'
import { ProductCategoryService } from 'services/marketplace/product-category'
import { useProductTypeSelected } from 'view/itemManagement'
import { QuerySelector } from 'types/mongoose'
import { IProductData } from 'types/product.type'
import { Data } from 'types'
import { MarketItemState } from 'constant/marketplace'
import { useQueryData } from 'hooks/useQueryData'
import { useQuery } from 'react-query'
import { ProductService } from '../../services/marketplace/product'
import { useCurrentCommunity } from '../community/useCurrentCommunity'

export const useProducts = () => {
  const products = useSelector((state: AppState) => state.products)
  return products
}

export const useProductSelector = <T>(
  selector: (products: ProductState) => T,
) => {
  const product = useSelector(({ products }: AppState) => selector(products))
  return product
}

export const useProductCategories = () => {
  const [categories, setCategories] = useState<ProductCategoryData[]>([])

  const getProductCategory = useCallback(async () => {
    try {
      const categories = await ProductCategoryService.getAll()
      setCategories(categories)
    } catch (err) {
      notifyError(err)
    }
  }, [])

  useEffect(() => {
    getProductCategory()
  }, [getProductCategory])

  return categories
}

/** @deprecated */
export const useProductFilteredGlobal = () => {
  const [productTypeSelected] = useProductTypeSelected()
  const [{ search, productState }] = useFilterProps()

  const filter = useMemo((): QuerySelector<Data<IProductData>> => {
    switch (productState) {
      case MarketItemState.Publish:
        return {
          availableQuantity: { $gt: 0 },
          startedAt: { $lte: `{{NOW}}` },
          isPublish: { $eq: true },
          isDeleted: { $eq: false },
          productType: { $eq: productTypeSelected },
        }
      case MarketItemState.Unpublish:
        return {
          startedAt: { $lte: `{{NOW}}` },
          isDeleted: { $eq: false },
          isPublish: { $eq: false },
          productType: { $eq: productTypeSelected },
          availableQuantity: { $gt: 0 },
        }
      case MarketItemState.Ended:
        return {
          availableQuantity: { $eq: 0 },
          isDeleted: { $eq: true },
          productType: { $eq: productTypeSelected },
        }

      default:
        return {}
    }
  }, [productState, productTypeSelected])

  return useQueryData<Data<IProductData>>(
    '/admin/product',
    { search: search },
    { filter },
  )
}

export const useProductList = () => {
  const [community] = useCurrentCommunity()
  const [productTypeSelected] = useProductTypeSelected()
  const [{ productState, search }] = useFilterProps()

  const stateParams = useMemo(() => {
    switch (productState) {
      case MarketItemState.Publish:
        return {
          isPublish: true,
          isDeleted: false,
        }
      case MarketItemState.Unpublish:
        return {
          isPublish: false,
          isDeleted: false,
        }
      case MarketItemState.Ended:
        return {
          isEnded: true,
        }
      default:
        return {}
    }
  }, [productState])
  const params = {
    ...stateParams,
    productType: productTypeSelected,
    communityId: community?._id,
    search,
    // TODO: add pagination
  }
  const { data, isLoading, refetch } = useQuery(
    'PRODUCT_DATA:' + JSON.stringify(params),
    async () => ProductService.find(params),
  )

  return {
    data: data?.data ?? [],
    total: data?.total,
    isLoading,
    refetch,
  }
}
