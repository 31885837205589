import { useContext, useMemo } from 'react'

import NftSelection from 'view/itemManagement/nftSelection'

import { useUserNFTs } from 'hooks/evm/useUserNft'
import useWeb3Item from 'hooks/useWeb3Item'

import {
  CreateWeb3Item,
  Web3ItemService,
  Web3Type,
} from 'services/marketplace/we3-item'
import { UserNFTData } from 'store/walletNFT.reducer'

import { ProductType } from 'constant/marketplace'
import { SelectChainContext } from './systems/selectChain/selectChainContext'

const SelectWeb3Item = ({
  productType,
  onChange,
  web3ItemId = '',
}: {
  web3ItemId?: string
  productType: ProductType
  onChange: (val: string, info: UserNFTData) => void
}) => {
  const userNFTs = useUserNFTs()
  const web3Data = useWeb3Item(web3ItemId)
  const { selectedChain } = useContext(SelectChainContext)

  const selectedNft = useMemo(() => {
    if (!web3Data?.blockData?.tokenAddress) return ''
    const nft = web3Data?.blockData
    return `${nft.tokenId}-${nft.tokenAddress}`
  }, [web3Data])

  const handleSelectNft = async (selectedNft: string) => {
    if (!userNFTs?.[selectedNft]) return onChange('', {} as any)

    const nftInfo = userNFTs[selectedNft]
    let blockData = {
      ...nftInfo,
      tokenId: nftInfo.tokenId || '',
      tokenAddress: nftInfo.tokenAddress,
      srcAddress: nftInfo.ownerOf,
      contractType: nftInfo.contractType,
      collectionName: nftInfo.collectionName || '',
      symbol: nftInfo.symbol || '',
    }
    if (blockData?.amount !== undefined) {
      // Remove amount for hash blockData
      // @ts-ignore
      delete blockData.amount
    }

    const params: CreateWeb3Item = {
      blockData,
      chainId: selectedChain,
      web3Type: Web3Type.NFT,
    }
    Web3ItemService.create(params).then((val) => {
      onChange(val._id, nftInfo)
    })
  }

  return (
    <NftSelection
      onChange={handleSelectNft}
      value={selectedNft}
      productType={productType}
    />
  )
}
export default SelectWeb3Item
