import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Col, Row, Table, Typography } from 'antd'

import CustomPagination from 'components/systems/pagination'
import NewAndFilterBar from './newAndFilterBar'
import { COLUMN_DIGITAL_CARDS } from './column'

import { useDigitalCards } from 'hooks/digitalCards/useDigitalCards'
import useDelaySearch from 'hooks/useDelaySearch'

import { ROUTES } from 'constant/routes'
import { DEFAULT_PAGE } from 'constant'

const ListDigitalCards = () => {
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [searchVal, setSearchVal] = useDelaySearch(500, 'search')
  const { digitalCards, total } = useDigitalCards({
    page: currentPage,
    search: searchVal,
  })

  const navigate = useNavigate()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <NewAndFilterBar onSearch={setSearchVal} />
          </Col>
          <Col span={24}>
            <Table
              rowKey="_id"
              className="table-border-collapse"
              columns={COLUMN_DIGITAL_CARDS}
              dataSource={digitalCards}
              onRow={({ _id }) => {
                return {
                  onClick: () => {
                    navigate(
                      `${ROUTES.REWARDS_CENTER.DIGITAL_CARDS_MANAGEMENT}/${_id}`,
                    )
                  },
                }
              }}
              pagination={false}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {total}</Typography.Text>
          </Col>
          {!!digitalCards.length ? (
            <Col>
              <CustomPagination
                total={total}
                onChange={setCurrentPage}
                page={currentPage}
              />
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}

export default ListDigitalCards
