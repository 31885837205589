import { ActivityType } from 'types/activity.type'
import { ICommunity } from 'types/community.type'

export enum Acceler8SubscriptionStatus {
  Active = 'SUBSCRIPTION_STATUS:ACTIVE',
  Inactive = 'SUBSCRIPTION_STATUS:INACTIVE',
}

export type Acceler8SubscriptionFilter = {
  statuses: Acceler8SubscriptionStatus[]
  packageIds: number[]
}

export enum Acceler8PackageIds {
  Premium = 1,
  Exclusive = 2,
  AdminActivates = 3,
}

export const Acceler8StatusLabel: Record<string, string> = {
  [Acceler8PackageIds.Premium]: 'Premium Package',
  [Acceler8PackageIds.Exclusive]: 'Exclusive Package',
  [Acceler8PackageIds.AdminActivates]: 'Admin Activates',
  [Acceler8SubscriptionStatus.Inactive]: 'Inactive',
}

export enum Acceler8SortOptions {
  RecentlyActivated = 'RECENTLY_ACTIVATED',
  GoingToBeExpired = 'GOING_TO_BE_EXPIRED',
  TotalMoneySpentDecrease = 'TOTAL_MONEY_SPENT_DECREASE',
  TotalMoneySpentIncrease = 'TOTAL_MONEY_SPENT_INCREASE',
}

// Subscription Start
export type FindSubscriptionOverallDto = {
  offset?: number

  limit?: number

  search?: string

  filter?: Acceler8SubscriptionFilter

  sort?: Acceler8SortOptions
}

export type FindSubscriptionActivityDto = {
  offset?: number

  limit?: number

  search?: string

  filter?: Acceler8SubscriptionFilter

  types: ActivityType[]
}

export interface ISubscriptionActivity {
  _id: string

  name: string

  type: Acceler8ActivityType

  content: string

  chainLink: string

  amount: number

  activityDate: Date

  createdAt: string

  updatedAt: string
}

export enum Acceler8ActivityType {
  Subscription = 'ACTIVITY_TYPE:SUBSCRIPTION',
  ExpiresAbcPackage = 'EXPIRES_ABC_PACKAGE',
  AdminActivates = 'ADMIN_ACTIVATES',
  AdminDeactivates = 'ADMIN_DEACTIVATES',
}

export interface ISubscriptionOverall {
  id: number

  communityId: string

  gameName: string

  packageId: number

  status: Acceler8SubscriptionStatus

  endedAt: string

  startedAt: string
}

export type Acceler8Subscription = {
  id: number

  communityId: string

  packageId: number

  description: string

  startedAt: string

  endedAt: string

  status: Acceler8SubscriptionStatus
}

export type Acceler8SubscriptionWithGameName = Acceler8Subscription & {
  gameName: ICommunity['title']
}

export const Acceler8ActivityTypeLabel: Record<Acceler8ActivityType, string> = {
  [Acceler8ActivityType.Subscription]: 'Subscribe New [abc] Package',
  [Acceler8ActivityType.ExpiresAbcPackage]: 'Expires [abc] package',
  [Acceler8ActivityType.AdminActivates]: 'Admin Activates',
  [Acceler8ActivityType.AdminDeactivates]: 'Admin Deactivates',
}

export const SubscriptionStatusLabel: Record<
  Acceler8SubscriptionStatus,
  string
> = {
  [Acceler8SubscriptionStatus.Active]: 'Active',
  [Acceler8SubscriptionStatus.Inactive]: 'Inactive',
}

export type Acceler8SubscriptionData = Acceler8Subscription & { id: number }

export type UpdateAcceler8SubscriptionDto = {
  packageId?: number
  status?: Acceler8SubscriptionStatus
  startedAt?: string
  endedAt?: string
}

export type CreateAcceler8SubscriptionDto = {
  communityId: string
  packageId: number
  startedAt: string
  endedAt: string
  status?: Acceler8SubscriptionStatus
  description?: string
}

export enum SubscriptionAction {
  AddSubscription = 'ADD_SUBSCRIPTION',
  ActivateSubscription = 'ACTIVATE_SUBSCRIPTION',
}
