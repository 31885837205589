import { marketplaceApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import { OrderData, OrderStatus, ShippingStatus } from 'types/order.type'
import { ProductType } from 'types/product.type'

const CONTROLLER = '/order'
const ADMIN_CONTROLLER = '/admin-order'

class Order {
  async getOrderData(orderId: string) {
    return api.get(`${CONTROLLER}/${orderId}`).then((data) => data.data)
  }

  async getOrderedItems({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
    productType,
    orderStatus,
    shippingStatus,
    categoryId,
  }: {
    page?: number
    pageSize?: number
    search?: string
    productType?: ProductType
    orderStatus?: OrderStatus
    shippingStatus?: ShippingStatus
    categoryId?: string
  }): Promise<{ total: number; data: OrderData[] }> {
    const params: any = {
      offset: (page - 1) * pageSize,
      limit: pageSize,
      search,
      productType,
    }
    if (shippingStatus) params['shippingStatus'] = shippingStatus
    if (orderStatus) params['orderStatus'] = orderStatus
    if (categoryId) params['categoryId'] = categoryId

    return api.get(ADMIN_CONTROLLER, { params }).then((data) => data.data)
  }

  async updateShippingStatus(
    orderIds: string[],
    shippingStatus: ShippingStatus,
  ) {
    return api
      .put(`${CONTROLLER}/shipping/status`, {
        shippingStatus,
        orderIds,
      })
      .then((data) => data.data)
  }

  async getTotal() {
    return api.post(`${CONTROLLER}/count`).then((data) => data.data)
  }

  async downloadUsersShippingData(itemId: string) {
    const res = await api.get(
      `${CONTROLLER}/shipping/export?productId=${itemId}`,
      {
        responseType: 'blob',
      },
    )
    return res.data
  }
}

export const orderService = new Order()
