import { useCallback } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'

import { Dropdown, MenuProps } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'
import { TableAcceler8V2Actions } from 'constant/acceler8-v2'

import { IAcceler8Season } from 'types/acceler8/acceler8-season.type'

const ACTION_ITEMS: MenuProps['items'] = [
  {
    label: TableAcceler8V2Actions.Edit,
    key: TableAcceler8V2Actions.Edit,
  },
]

type RowActionProps = { seasonData: IAcceler8Season }
const RowAction = ({ seasonData }: RowActionProps) => {
  const { id: seasonId } = seasonData
  const navigate = useNavigate()

  const onClickAction = useCallback(
    (key: string) => {
      switch (key) {
        case TableAcceler8V2Actions.Edit:
          return navigate(
            generatePath(ROUTES.PLAYER_LEADERBOARD.EDIT_SEASON, { seasonId }),
          )
      }
    },
    [navigate, seasonId],
  )

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Button
        trigger={['click']}
        type="text"
        icon={<IonIcon name="ellipsis-vertical-outline" />}
        menu={{
          items: ACTION_ITEMS,
          onClick: (menuInfo) => onClickAction(menuInfo.key),
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  )
}

export default RowAction
