import { useNavigate } from 'react-router-dom'

import { Dropdown } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ROUTES } from 'constant/routes'
import { TableMarketActions } from 'constant/marketplace'

type RowActionProps = {
  digitalCardId: string
}

const RowAction = ({ digitalCardId }: RowActionProps) => {
  const navigate = useNavigate()

  const onDelete = (digitalCardId: string) => {}

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Button
        trigger={['click']}
        type="text"
        icon={<IonIcon name="ellipsis-vertical-outline" />}
        menu={{
          items: [
            { label: TableMarketActions.Edit, key: TableMarketActions.Edit },
          ],
          onClick: (e) => {
            switch (e.key) {
              case TableMarketActions.Edit:
                return navigate(
                  `${ROUTES.REWARDS_CENTER.EDIT_DIGITAL_CARDS}/${digitalCardId}`,
                )
              case TableMarketActions.Delete:
                return onDelete(digitalCardId)
            }
          },
        }}
        onClick={(e) => e.stopPropagation()}
        // loading={loading}
      />
    </div>
  )
}

export default RowAction
