import { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { Button, Input } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { DEFAULT_PAGE } from 'constant'
import { ROUTES } from 'constant/routes'

let timeout: ReturnType<typeof setTimeout>

type SearchReportProps = {
  page?: number
}
const SearchReport = ({ page = DEFAULT_PAGE }: SearchReportProps) => {
  const [searchKey, setSearchKey] = useState('')
  const navigate = useNavigate()

  const onChangeSearch = useCallback(() => {
    navigate({
      pathname: ROUTES.BOUNTY.REPORT,
      search: `?${createSearchParams({
        search: searchKey,
        page: page.toString(),
      })}`,
    })
  }, [navigate, searchKey, page])

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      onChangeSearch()
    }, 500)
  }, [onChangeSearch])

  return (
    <Input
      onChange={(e) => setSearchKey(e.target.value)}
      placeholder="Search for bounty name, ID ..."
      suffix={
        <Button
          style={{ width: 'auto', height: 'auto', padding: 0 }}
          icon={<IonIcon name="search-outline" />}
        />
      }
    />
  )
}

export default SearchReport
