import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { Infix } from 'store/ui.reducer'

export type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

/**
 * @param breakPoint 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
 * @default 'md'
 */

const useIsMobile = (breakPoint: BreakPoint = 'md') => {
  const infix = useSelector((state: AppState) => state.ui.infix)
  return infix <= Infix[breakPoint]
}

export default useIsMobile
