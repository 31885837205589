import { useQuery } from 'react-query'

import { DailyCheckInService } from 'services/bountySystem/dailyCheckIn'

export const useCheckInRewards = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['DAILY_CHECK_IN_REWARDS'],
    queryFn: () => DailyCheckInService.getCheckInRewards(),
  })

  return {
    ...rest,
    rewards: data,
  }
}
