import { nftApi as api } from 'services/base-axios'

import { DataList } from 'types'
import {
  BulkCreateMetadataDto,
  GetListMetadataDto,
  INftMetadata,
} from 'types/nft-metadata.type'
import { CreateNftDto, GetListNftDto, INft } from 'types/nft.type'

const NFT_CONTROLLER = '/admin/nft'
const NFT_METADATA_CONTROLLER = '/admin/metadata'
class NftService {
  async getListNfts(dto: GetListNftDto) {
    const { data } = await api.get<DataList<INft[]>>(NFT_CONTROLLER, {
      params: dto,
    })
    return data
  }

  async createNft(dto: CreateNftDto) {
    const { data } = await api.post<INft>(NFT_CONTROLLER, dto)
    return data
  }

  async getNftById(nftId: string) {
    const { data } = await api.get<INft>(`${NFT_CONTROLLER}/${nftId}`)
    return data
  }

  async getListMetadata(dto: GetListMetadataDto) {
    const { data } = await api.get<DataList<INftMetadata[]>>(
      NFT_METADATA_CONTROLLER,
      {
        params: dto,
      },
    )
    return data
  }

  async bulkCreateMetadata(body: BulkCreateMetadataDto) {
    const { data } = await api.post(NFT_METADATA_CONTROLLER, body)
    return data
  }
}

export const nftService = new NftService()
