import { Fragment, ReactNode, useState } from 'react'
import moment from 'moment'

import { Button, Col, InputNumber, Row, Space, Spin, Typography } from 'antd'
import CardInfo from 'components/systems/cardInfo'
import SpaceBetween from 'components/systems/spaceBetween'
import IonIcon from 'components/systems/ionIcon'
import SelectNFTBadge from 'components/systems/selectNFTBadge'
import NFTItem from 'view/bounty/management/createAndUpdate/dappBounty/formPrizes/nftSelected/nftItem'
import CustomModal from 'components/systems/customModal'

import useCheckIn from '../useCheckIn'
import { useCheckInRewards } from 'hooks/check-in/useCheckInRewards'
import { useCreateCheckInReward } from 'hooks/check-in/useCreateCheckinReward'

import { notifyError, notifySuccess } from 'helper'

import { BADGE_AMOUNT_MAX_LEN } from 'constant/badge'

import { CheckInRewardData, CreateCheckInRewardDto } from 'types/check-in.type'

const SettingReward = ({
  fromDate,
  toDate,
  reward,
  children,
}: {
  fromDate: string
  toDate: string
  reward?: CheckInRewardData
  children: ReactNode
}) => {
  const { refetch } = useCheckInRewards()
  const {
    isChecked,
    onClaim,
    loading: isClaiming,
  } = useCheckIn(fromDate, toDate, reward?._id)
  const { mutateAsync: createCheckInReward, isLoading: isCreating } =
    useCreateCheckInReward()

  const [open, setOpen] = useState(false)
  const [openSelectBadge, setOpenSelectBadge] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ccpReward, setCcpReward] = useState(reward?.rewardCCP || 0)
  const [selectedBadges, setSelectedBadges] = useState<string[]>(
    reward?.rewardBadge ? [reward?.rewardBadge?.badgeId] : [],
  )
  const [badgeAmount, setBadgeAmount] = useState(
    reward?.rewardBadge?.amount || 1,
  )

  const handleCreate = async () => {
    try {
      const data: CreateCheckInRewardDto = {
        rewardCCP: ccpReward,
        fromDate: fromDate,
        toDate: toDate,
      }
      if (selectedBadges.length > 0) {
        data.rewardBadge = {
          badgeId: selectedBadges[0],
          amount: badgeAmount,
        }
      }

      await createCheckInReward(data)
      notifySuccess('Updated Reward')
      await refetch()
      setOpen(false)
    } catch (error) {
      notifyError(error)
    }
  }

  const handleCheckIn = async () => {
    try {
      setLoading(true)
      await onClaim()
      notifySuccess('Check In')
      setOpen(false)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeAmount = (value: number | null) => {
    if (!value) {
      setBadgeAmount(1)
      return
    }
    setBadgeAmount(value)
  }

  // const isFinish = moment.utc(date).isBefore(moment.utc().startOf('day'))

  return (
    <Fragment>
      <Row onClick={() => setOpen(true)}>
        <Col span={24} style={{ cursor: 'pointer' }}>
          <Spin spinning={loading}>{children}</Spin>
        </Col>
      </Row>

      <CustomModal open={open} onCancel={() => setOpen(false)} width={500}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Typography.Text type="secondary">Date: </Typography.Text>
              <Typography.Text>
                {moment.utc(fromDate).format('DD/MM/YYYY')} (UTC) -{' '}
                {moment.utc(toDate).format('DD/MM/YYYY')} (UTC)
              </Typography.Text>
            </Space>
          </Col>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text type="secondary">Reward CCP</Typography.Text>
              <InputNumber
                defaultValue={0}
                addonAfter="CCP"
                style={{ width: '100%' }}
                value={ccpReward}
                onChange={(value: number | null) => setCcpReward(value || 0)}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text type="secondary">Reward Badge</Typography.Text>
              <CardInfo title="NFT Badges">
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <SpaceBetween
                      floatContent={
                        <Fragment>
                          <Button
                            icon={<IonIcon name="add-outline" />}
                            onClick={() => setOpenSelectBadge(true)}
                            ghost
                          >
                            Add Collection
                          </Button>
                          <SelectNFTBadge
                            badgeSelected={selectedBadges}
                            setBadgeSelected={setSelectedBadges}
                            multiple={false}
                            open={openSelectBadge}
                            setOpen={setOpenSelectBadge}
                          />
                        </Fragment>
                      }
                    >
                      <Typography.Text type="secondary">
                        NFT Badges
                      </Typography.Text>
                    </SpaceBetween>
                  </Col>
                  {selectedBadges.length > 0 && (
                    <Col span={24}>
                      {selectedBadges.map((item) => (
                        <NFTItem
                          onDelete={() => setSelectedBadges([])}
                          badgeId={item}
                          amount={badgeAmount}
                          threshold={BADGE_AMOUNT_MAX_LEN}
                          onChangeAmount={handleChangeAmount}
                        />
                      ))}
                    </Col>
                  )}
                </Row>
              </CardInfo>
            </Space>
          </Col>
          <Col span={12}>
            <Button
              type="dashed"
              block
              onClick={handleCheckIn}
              disabled={isChecked}
              loading={isClaiming}
            >
              Check In
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              block
              loading={isCreating}
              onClick={handleCreate}
            >
              Update
            </Button>
          </Col>
        </Row>
      </CustomModal>
    </Fragment>
  )
}

export default SettingReward
