import { useCallback, useState } from 'react'

import { useCurrentCommunity } from '../community/useCurrentCommunity'
import { useBountySetup } from 'view/bounty/management'

import { notifyError } from 'helper'

import { ProductSuggestionService } from 'services/marketplace/product-suggestion'

import { Data } from 'types'
import { IProductData } from 'types/product.type'

export const useProductSuggestion = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState<Data<IProductData>[]>()

  const [currentCommunity] = useCurrentCommunity()
  const [bountyData] = useBountySetup()

  const fetchProducts = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await ProductSuggestionService.getProductSuggestion({
        badgeIds: bountyData?.rewardBadges.map((e) => e.badgeId),
        communityId: currentCommunity?._id,
        limit: 10,
        offset: 0,
      })

      setProducts(res)
    } catch (err) {
      notifyError(err)
    } finally {
      setIsLoading(false)
    }
  }, [bountyData?.rewardBadges, currentCommunity?._id])

  return { products, isLoading, fetchProducts }
}
