import { useMutation } from 'react-query'

import { MultiplierService } from 'services/multiplier'

import { BulkCreateMultiAppDto } from 'types/multiplier.type'

export const useBulkCreateMultiApp = () => {
  return useMutation({
    mutationKey: ['BULK_CREATE_MULTI_APP'],
    mutationFn: (dto: BulkCreateMultiAppDto) =>
      MultiplierService.bulkCreateMultiApp(dto),
  })
}
