import { Fragment } from 'react'
import DataWatcher from './data.watcher'
import UIWatcher from './ui.watcher'

const Watcher = () => {
  return (
    <Fragment>
      <UIWatcher />
      <DataWatcher />
    </Fragment>
  )
}

export default Watcher
