import hash from 'object-hash'
import { useQuery } from 'react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { marketplaceApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { QUERY_CLIENT } from 'index'

import { QuerySelector, QuerySort } from 'types/mongoose'

type Param = {
  page?: number
  pageSize?: number
  search?: string
}

export const useQueryData = <T extends Object>(
  controller: string,
  params: Param,
  body: { filter?: QuerySelector<T>; sort?: QuerySort<T> },
) => {
  const [hashId, setHashId] = useState('')
  const [loading, setLoading] = useState(true)

  const {
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
  } = params
  const _hashId = hash({ controller, params, body })

  useEffect(() => {
    setLoading(true)
    const timeout = setTimeout(() => {
      setHashId(_hashId)
      setLoading(false)
    }, 300)
    return () => clearTimeout(timeout)
  }, [_hashId])

  const { data, isLoading, isFetching } = useQuery(
    `USE_FILTER_DATA:${controller}:${hashId}`,
    async () => {
      return api
        .post<{ data: T[]; total: number }>(`${controller}/filter`, body, {
          params: {
            offset: (page - 1) * pageSize,
            limit: pageSize,
            search,
          },
        })
        .then((data) => data)
    },
    { enabled: !!hashId, staleTime: 60000 }, // 60 second
  )

  const result = useMemo(() => {
    if (!data?.data) return { data: [], total: 0 }
    return {
      data: data?.data.data,
      total: data?.data.total,
    }
  }, [data?.data])

  const refetch = useCallback(() => {
    QUERY_CLIENT.refetchQueries({
      predicate: (query) => {
        const id = query.queryKey.toString()
        return id === `USE_FILTER_DATA:${controller}:${hashId}`
      },
    })
    QUERY_CLIENT.removeQueries({
      predicate: (query) => {
        const id = query.queryKey.toString()
        return id.startsWith(`USE_FILTER_DATA:${controller}`) && id !== hashId
      },
    })
  }, [controller, hashId])

  return {
    data: result.data,
    total: result.total,
    isLoading: loading || isLoading || isFetching,
    refetch,
  }
}
