import moment from 'moment'

import { Typography } from 'antd'

import { TIME_FORMAT } from 'constant'

import { ColumnsType } from 'antd/es/table'
import { PlayerRedemption } from 'types/acceler8/acceler8-redemption.type'

export const PLAYER_REDEMPTION_COLUMNS: ColumnsType<PlayerRedemption> = [
  {
    title: 'User Id',
    dataIndex: 'userId',
    width: 180,
    render: (userId: string) => (
      <Typography.Paragraph
        ellipsis={{ rows: 1 }}
        style={{ margin: 0, maxWidth: 260 }}
      >
        {userId}
      </Typography.Paragraph>
    ),
  },
  {
    title: 'Season',
    dataIndex: 'seasonId',
    align: 'center',
    render: (seasonId: number) => (
      <Typography.Text style={{ maxWidth: 160 }}>
        {seasonId ?? '--'}
      </Typography.Text>
    ),
  },
  {
    title: 'Points',
    dataIndex: 'points',
    align: 'center',
    render: (points: number) => (
      <Typography.Text>{points ?? '--'}</Typography.Text>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'title',
    align: 'center',
    render: (title: string) => (
      <Typography.Text>{title ?? '--'}</Typography.Text>
    ),
  },
  {
    title: 'Timestamps (UTC)',
    dataIndex: 'createdAt',
    align: 'center',
    width: 160,
    render: (createdAt: string) => (
      <Typography.Text>
        {moment.utc(createdAt).format(TIME_FORMAT)}
      </Typography.Text>
    ),
  },
]
