import { useMemo } from 'react'

import {
  Form,
  type FormItemProps,
  Image,
  Select,
  Space,
  Typography,
} from 'antd'

import { useChain } from 'hooks/chain/useChain'

import { ChainID } from 'constant'

import ethTokenLogo from 'static/images/token/eth-token.svg'
import usdtTokenLogo from 'static/images/token/usdt-token.svg'
import usdcTokenLogo from 'static/images/token/usdc-token.svg'

import './index.less'

const genTokenIcon = (symbol: string) => {
  if (!symbol) return ethTokenLogo

  switch (symbol.toLowerCase()) {
    case 'eth':
      return ethTokenLogo
    case 'usdt':
      return usdtTokenLogo
    case 'usdc':
      return usdcTokenLogo
    default:
      return ethTokenLogo
  }
}

function SelectTokenFormItem({ name, className, ...props }: FormItemProps) {
  // Currently only support A8 Chain
  const { paymentCurrencies } = useChain({
    chainId: ChainID.A8,
  })

  const tokenItem = useMemo(
    () =>
      paymentCurrencies.map(({ symbol }) => ({
        label: (
          <Space size={16} align="center">
            <div className="select-token-form-item--token-logo">
              <Image src={genTokenIcon(symbol)} width="auto" preview={false} />
            </div>
            <Typography.Text type="success">
              {symbol.toUpperCase()}
            </Typography.Text>
          </Space>
        ),
        value: symbol,
      })),
    [paymentCurrencies],
  )

  return (
    <Form.Item
      {...props}
      name={name}
      initialValue="eth"
      className={`select-token-form-item ${className}`}
    >
      <Select options={tokenItem} />
    </Form.Item>
  )
}

export default SelectTokenFormItem
