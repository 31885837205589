import { ProductCategoryService } from 'services/marketplace/product-category'
import { useQuery } from 'react-query'

export const useProductCategory = (categoryId: string) => {
  const data = useQuery(
    `product-category:${categoryId}:data`,
    () => ProductCategoryService.getById(categoryId),
    { enabled: !!categoryId },
  )
  return data
}

export const useProductCategories = () => {
  const data = useQuery(`product-category:get-all`, () =>
    ProductCategoryService.getAll(),
  )
  return data
}
