import { useMutation } from 'react-query'

import { CampaignService } from 'services/bountySystem/campaign.service'

import { CreateCampaignDto } from 'types/campaign.type'

export const useCreateCampaign = () => {
  return useMutation({
    mutationKey: ['CREATE_CAMPAIGN'],
    mutationFn: (dto: CreateCampaignDto) => CampaignService.createCampaign(dto),
  })
}
