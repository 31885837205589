import { Env } from './env'

/**
 * Constructor
 */

type Config = {
  ancient8Api: string
  ancient8Oath: string
  ancient8Profile: string
  bountyApi: string
  marketplaceApi: string
  acceler8Api: string
  acceler8TaskApi: string
  affiliateApi: string
  nftApi: string
}

const configs: Record<Env, Config> = {
  /**
   * Development configurations
   */
  development: {
    ancient8Api: 'https://id-api-dev.ancient8.gg/api',
    ancient8Oath:
      'https://auth.ancient8.dev/oauth?clientKey=e15162460eb9e4fd91c1c9dce01deb7b&redirectUri=http://localhost:3003/oauth',
    ancient8Profile: 'https://auth.ancient8.dev/profile',
    bountyApi: 'http://127.0.0.1:9000',
    marketplaceApi: 'http://127.0.0.1:9001',
    acceler8Api: 'http://127.0.0.1:9003/api',
    acceler8TaskApi: 'http://localhost:9004/api/v1',
    affiliateApi: 'http://localhost:9005',
    nftApi: 'http://localhost:9006/api',
  },

  /**
   * Staging configurations
   */
  staging: {
    ancient8Api: 'https://id-api-dev.ancient8.gg/api',
    ancient8Oath:
      'https://auth.ancient8.dev/oauth?clientKey=64ac2f6a13dcb06c4cc331c92f810e16&redirectUri=https://portal.space3.dev',
    ancient8Profile: 'https://auth.ancient8.dev/profile',
    bountyApi: 'https://bounty.space3.dev',
    marketplaceApi: 'https://market.space3.dev',
    acceler8Api: 'https://ac8-affiliate.space3.dev/api',
    acceler8TaskApi: 'https://ac8-task.space3.dev/api/v1',
    affiliateApi: 'https://affiliate.space3.dev',
    nftApi: 'https://nft.space3.dev/api',
  },

  /**
   * Production configurations
   */
  production: {
    ancient8Api: 'https://id-api.ancient8.gg/api',
    ancient8Oath:
      'https://auth.ancient8.gg/oauth?clientKey=3b2bb65411af785b9bd770ad02487514&redirectUri=https://portal.space3.gg',
    ancient8Profile: 'https://auth.ancient8.gg/profile',
    bountyApi: 'https://v2.bounty.space3.gg',
    marketplaceApi: 'https://v2.market.space3.gg',
    acceler8Api: 'https://ac8-affiliate.space3.gg/api',
    acceler8TaskApi: 'https://ac8-task.space3.gg/api/v1',
    affiliateApi: 'https://affiliate.space3.gg',
    // TODO: update later
    nftApi: 'https://nft.space3.gg/api',
  },
}

/**
 * Module exports
 */
export default configs
