import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Col, Row, Space, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'
import SearchBar from 'components/systems/searchBar'

import FilterContext from '../FilterContext'

import { ROUTES } from 'constant/routes'

const NewAndFilterBar = () => {
  const navigate = useNavigate()
  const { setSearch } = useContext(FilterContext)

  return (
    <Row align="middle" gutter={[24, 24]} justify="space-between">
      <Col span={8}>
        <SearchBar setSearch={setSearch} placeholder="Search season name..." />
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() => navigate(ROUTES.PLAYER_LEADERBOARD.NEW_SEASON)}
        >
          <Space>
            <IonIcon name="add" />
            <Typography.Text strong>Create New Season</Typography.Text>
          </Space>
        </Button>
      </Col>
    </Row>
  )
}

export default NewAndFilterBar
