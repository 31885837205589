import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ChainID } from 'constant'
import { CHAIN_STATE_DEFAULT } from 'constant/chain'

import { IChain } from 'types/chain.type'

export type ChainState = Record<ChainID, IChain>

/**
 * Store constructor
 */

const NAME = 'chain'
const initialState: ChainState = CHAIN_STATE_DEFAULT

/**
 * Actions
 */

export const setChains = createAsyncThunk<
  ChainState,
  { chainConfig: ChainState },
  { state: any }
>(`${NAME}/setChains`, ({ chainConfig }) => {
  return chainConfig
})

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder.addCase(
      setChains.fulfilled,
      (state, { payload }) => void Object.assign(state, payload),
    ),
})

export default slice.reducer
