import { useMemo, useState } from 'react'

import { Space, Typography, Upload, Image, Spin, Modal } from 'antd'
import IonIcon from '../ionIcon'

import { useStorage } from 'hooks/systems/useStorage'
import { notifyError } from 'helper'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload'

import './index.less'

type InputImageProps = {
  value?: string
  onChange?: (val: string) => void
}

const InputImage = ({ onChange, value = '' }: InputImageProps) => {
  const [loading, setLoading] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const { onUpload } = useStorage(604800)

  const onChangeFile = async (e: UploadChangeParam) => {
    if (!e.fileList.length) return
    try {
      setLoading(true)
      const image = await onUpload(e.fileList[0])
      if (onChange) await onChange(image)
    } catch (error) {
      notifyError(error)
      if (onChange) await onChange('')
    } finally {
      setLoading(false)
    }
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const fileList = useMemo(() => {
    if (!value) return undefined
    const data: UploadFile = { name: value, uid: '1', url: value }
    return [data]
  }, [value])

  return (
    <Spin spinning={loading}>
      <Upload
        accept=".png,.jpg,.webp,.jpeg,.gif"
        maxCount={1}
        onChange={onChangeFile}
        beforeUpload={() => false}
        progress={{ strokeWidth: 2, showInfo: true }}
        listType="picture-card"
        onPreview={handlePreview}
        fileList={fileList}
        onRemove={() => {
          if (onChange) return onChange('')
        }}
      >
        <Space direction="vertical" align="center">
          <IonIcon
            name="cloud-upload"
            style={{ color: '#42BAB0', fontSize: 20 }}
          />
          <Typography.Text style={{ fontSize: 12 }} type="success">
            Upload Picture
          </Typography.Text>
        </Space>
      </Upload>
      <Modal
        open={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        closeIcon={<IonIcon name="close-outline" />}
      >
        <Image src={previewImage} preview={false} />
      </Modal>
    </Spin>
  )
}

export default InputImage
