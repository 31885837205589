import { useState } from 'react'

import { DEFAULT_PAGE_SIZE } from 'constant'

type PaginationProps = {
  limit?: number
  page?: number
}

export const usePagination = (props?: PaginationProps) => {
  const [page, setPage] = useState(props?.page || 1)
  const [limit, setLimit] = useState(props?.limit || DEFAULT_PAGE_SIZE)

  return {
    page,
    setPage,
    limit,
    setLimit,
    offset: limit * (page - 1),
  }
}
