import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import FormSetUp from './formSetup'

import { useCreateAcceler8SeasonMutation } from 'hooks/acceler8/useCreateAcceler8SeasonMutation'

import { notifyError, notifySuccess } from 'helper'
import { ROUTES } from 'constant/routes'
import {
  IAcceler8Season,
  CreateAcceler8SeasonDto,
} from 'types/acceler8/acceler8-season.type'
import { PickPartial } from 'types'

export type Acceler8SeasonInitialData = PickPartial<
  IAcceler8Season,
  'startedAt' | 'endedAt' | 'id'
>

const DUMMY_AFFILIATION_SEASON_DATA: Acceler8SeasonInitialData = {
  title: '',
  subTitle: '',
  totalRewards: 0,
  banner: '',
  prizeStructures: [
    { title: 'Top 1', priceText: '' },
    { title: 'Top 2', priceText: '' },
    { title: 'Top 3', priceText: '' },
    { title: 'Top 4 - 6', priceText: '' },
    { title: 'Top 7 - 10', priceText: '' },
  ],
  callToAction: {
    title: '',
    link: '',
  },
  startedAt: undefined,
  endedAt: undefined,
}

const FormCreate = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutate, isLoading: mutateLoading } = useCreateAcceler8SeasonMutation()
  const onMutate = (seasonData: CreateAcceler8SeasonDto) => {
    mutate(seasonData, {
      onError: (error) => {
        notifyError(error)
      },
      onSuccess: async () => {
        notifySuccess('Create season successfully!')
        await queryClient.invalidateQueries('GET_ACCELER8_SEASONS')
        navigate(ROUTES.ACCELER8.MANAGE_SEASON)
      },
    })
  }

  return (
    <FormSetUp
      initialSeasonData={DUMMY_AFFILIATION_SEASON_DATA}
      onMutate={onMutate}
      mutateLoading={mutateLoading}
    />
  )
}

export default FormCreate
