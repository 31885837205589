import { Configuration, DefaultApi } from 'blockscout-cli'

import configs from 'configs'

import { ChainID } from 'constant'

const configApi = new Configuration({
  basePath: configs.blockscout[ChainID.A8].basePath,
})

const BlockScoutService = new DefaultApi(configApi)

export default BlockScoutService
