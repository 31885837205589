import { Fragment } from 'react'

import { Button } from 'antd'

import { ActivityType, ISubscriptionActivity } from 'types/affiliation.type'

type ActivityActionProps = {
  item: ISubscriptionActivity
}

const ActivityAction = ({ item }: ActivityActionProps) => {
  if (item.type === ActivityType.SubscribeNewAbcPackage) {
    return (
      <Button href={item.chainLink} target="_blank" ghost>
        Txn
      </Button>
    )
  }

  return <Fragment />
}

export default ActivityAction
