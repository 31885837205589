import { Col, Row, Typography } from 'antd'
import CardInfo from 'components/systems/cardInfo'
import SpaceVertical from 'components/systems/spaceVertical'

type DeliveryInfoProps = {
  delivery: Record<string, string>
}

const Content = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => (
  <SpaceVertical size={6}>
    <Typography.Text type="secondary">{label}</Typography.Text>
    <Typography.Text>{value}</Typography.Text>
  </SpaceVertical>
)

const DeliveryInfo = ({ delivery }: DeliveryInfoProps) => {
  return (
    <CardInfo title="Delivery Information">
      <Row gutter={[24, 24]}>
        {Object.keys(delivery).map((id) => (
          <Col key={id} sm={24} xs={24} md={12}>
            <Content label={id} value={delivery[id]} />
          </Col>
        ))}
      </Row>
    </CardInfo>
  )
}

export default DeliveryInfo
