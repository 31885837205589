export enum SeoType {
  Bounty = 'SEO:BOUNTY',
  Product = 'SEO:PRODUCT',
  Game = 'SEO:GAME',
  Gacha = 'SEO:GACHA',
}

export interface ISeo {
  seoId: string

  title?: string

  description?: string

  canonical?: string

  keywords?: string[]

  robots?: string[]

  type: SeoType
}

export type FindSeoDto = {
  seoId: string
  type: SeoType
}
