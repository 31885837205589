import { useMutation } from 'react-query'
import { BountyService } from 'services/bountySystem/bounty'

export const useMissionIdBySlug = () => {
  return useMutation({
    mutationKey: ['GET_MISSION_ID_BY_SLUG'],
    mutationFn: async (slug: string) => BountyService.getBountyBySlug(slug),
    onError: () => {
      // to prevent error from showing up
      return
    },
  })
}
