import { Fragment, useState } from 'react'

import { Button } from 'antd'
import CommonPopup from '../commonPopup'
import IonIcon from 'components/systems/ionIcon'

type ChangeStateProps = {
  onConfirm: () => void
  loading: boolean
  isPublish: boolean
}

const ChangeState = ({ onConfirm, loading, isPublish }: ChangeStateProps) => {
  const [open, setOpen] = useState(false)
  const title = `Confirm to ${isPublish ? 'Unpublish' : 'publish'} bounty task?`
  return (
    <Fragment>
      <Button
        type="primary"
        icon={<IonIcon name="cloud-upload-outline" />}
        onClick={() => setOpen(true)}
        loading={loading}
      >
        {isPublish ? 'Unpublish' : 'Publish'}
      </Button>
      <CommonPopup
        open={open}
        onCancel={() => setOpen(false)}
        title={title}
        onConfirm={onConfirm}
      />
    </Fragment>
  )
}

export default ChangeState
