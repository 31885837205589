import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { Button, Col, Image, Row, Space, Table, Typography } from 'antd'

import { prizeColumn } from './prizeColumn'
import CardInfo from 'components/systems/cardInfo'
import { teamColumn } from './teamColumn'
import SearchBar from '../searchBar'

import { useSeasonAffiliation } from 'hooks/affiliation-v2/useSeasonAffiliation'
import { useLeaderboardBySeasonAffiliation } from 'hooks/affiliation-v2/useLeaderboardBySeasonAffiliation'

import { TIME_FORMAT } from 'constant'
import { ROUTES } from 'constant/routes'

const AffiliationSeasonDetail = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>('')
  const seasonId = useParams().seasonId
  const { data: seasonData } = useSeasonAffiliation(seasonId)
  const { data: leaderboardData, isLoading: isBoardLoading } =
    useLeaderboardBySeasonAffiliation({ seasonId, search })

  const onEditSeason = useCallback(() => {
    // TODO: implement later
    navigate(ROUTES.AFFILIATION_V2.NEW_SEASON)
  }, [navigate])

  const onSearch = useCallback(
    (keyword: string) => {
      setSearch(keyword)
    },
    [setSearch],
  )

  return (
    <Row gutter={[24, 50]}>
      <Col span={24}>
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={3} type="success">
            Season {seasonData?.name}
          </Typography.Title>
          <Button size="small" ghost onClick={onEditSeason}>
            Edit
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Image
              src={seasonData?.banner}
              width={'100%'}
              style={{ aspectRatio: '22/9', objectFit: 'cover' }}
              preview={false}
            />
          </Col>
          <Col span={24}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Time</Typography.Text>
              <Space direction="vertical" size={0}>
                <Space>
                  <Typography.Text type="success">Start:</Typography.Text>
                  <Typography.Text>
                    {moment.utc(seasonData?.startDate).format(TIME_FORMAT)} UTC
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text type="success">End:</Typography.Text>
                  <Typography.Text>
                    {moment.utc(seasonData?.endDate).format(TIME_FORMAT)} UTC
                  </Typography.Text>
                </Space>
              </Space>
            </Space>
          </Col>

          <Col span={10}>
            <CardInfo title="Prize structure">
              <Table
                dataSource={seasonData?.prizeStructure}
                columns={prizeColumn}
                rowKey={({ title }) => title}
                pagination={false}
              />
            </CardInfo>
          </Col>

          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Col>
                <Typography.Text type="secondary">Leaderboard</Typography.Text>
              </Col>
              <Col span={24}>
                <SearchBar setSearch={onSearch} />
              </Col>
              <Col span={24}>
                <Table
                  dataSource={leaderboardData}
                  columns={teamColumn}
                  rowKey={({ _id }) => _id}
                  loading={isBoardLoading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AffiliationSeasonDetail
