import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { GetPlayerPointsDistributionDto } from 'types/player-leaderboard/player-leaderboard-rank.type'

export const usePlayerPointDistribution = (
  payload: GetPlayerPointsDistributionDto,
) => {
  return useQuery({
    queryKey: ['get-player-point-distribution', payload],
    queryFn: () => PlayerLeaderboardService.getUserPointsDistribution(payload),
  })
}
