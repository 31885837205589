import { WalletConfig } from './common'
import { Env } from './env'

const configs: Record<Env, WalletConfig> = {
  development: {
    adminAddress: '0x865260E7825C13fFB62E8127b1015Da670205502',
  },
  staging: {
    adminAddress: '0x865260E7825C13fFB62E8127b1015Da670205502',
  },
  production: {
    adminAddress: '0xfd3Fc91f2342c2bF3178A5f8BDE3F2967Fc91Df0',
  },
}

/**
 * Module exports
 */
export default configs
