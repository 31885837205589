import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import validator from 'validator'
import slugify from 'slugify'

import { Button, Col, Input, Row, Select, Typography } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'
import UploadImageField from './uploadImageField'
import SpaceVertical from 'components/systems/spaceVertical'
import SeoInformation from 'components/seo/seoInformation'
import { BlockchainOptions, StatusOptions } from './createCommunity'

import { useSEO } from 'hooks/seo/useSEO'
import useHandle from 'hooks/useHandle'
import { useCommunityDetails } from 'hooks/community/useCommunities'

import { CommunityService } from 'services/bountySystem/community'
import { notifySuccess } from 'helper'

import { DEFAULT_CREATE_COMMUNITY_DATA } from 'constant/community'
import { ROUTES } from 'constant/routes'

import { SeoType } from 'types/seo.type'

const EditCommunity = () => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(DEFAULT_CREATE_COMMUNITY_DATA)
  const {
    title,
    description,
    logo,
    website,
    coverImage,
    thumbnail,
    status,
    blockchain,
    genre,
    slug,
  } = formData

  const [submitDisabled, setSubmitDisabled] = useState(true)

  const communityId = useParams().communityId || ''
  const { data: community } = useCommunityDetails(communityId)

  const navigate = useNavigate()

  const { fetchSeoInfo, updateSeoInfo } = useSEO(SeoType.Game)

  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/

  const validateFields = () => {
    if (title.trim().length < 5 || title.trim().length > 64) {
      throw new Error('Title must be between 5 - 64 characters.')
    }
    if (description.trim().length < 5 || description.trim().length > 800) {
      throw new Error('Description must be between 5 - 800 characters.')
    }
    if (!website) {
      throw new Error('Please enter the URL.')
    }
    if (website && !validator.isURL(website)) {
      throw new Error('Please enter the correct URL.')
    }
    if (!logo) {
      throw new Error('Please choose the logo.')
    }
    if (!coverImage) {
      throw new Error('Please choose the cover image.')
    }
    if (!thumbnail) {
      throw new Error('Please choose the thumbnail.')
    }
    if (!status) {
      throw new Error('Please choose the status.')
    }
    if (!slug) {
      throw new Error('Please enter the slug.')
    }
  }

  const onChangeSlug = (value: string) => {
    setFormData({
      ...formData,
      slug: slugify(value, {
        lower: true,
        remove: specialChars,
      }),
    })
  }

  const onSave = useHandle(async () => {
    validateFields()

    const data = { ...formData }
    data.slug = slugify(data.slug || '', {
      lower: true,
      remove: specialChars,
    })

    await CommunityService.editCommunity(communityId, data)
    updateSeoInfo()
    notifySuccess('Updated community')
    navigate(ROUTES.COMMUNITIES.MANAGEMENT)
  }, setLoading)

  useEffect(() => {
    const isDisabled =
      !title ||
      !description ||
      !logo ||
      !coverImage ||
      !thumbnail ||
      !status ||
      !slug
    setSubmitDisabled(isDisabled)
  }, [
    title,
    description,
    logo,
    coverImage,
    thumbnail,
    status,
    blockchain,
    slug,
  ])

  useEffect(() => {
    if (!community) return
    setFormData(community)
  }, [community])

  useEffect(() => {
    communityId && fetchSeoInfo()
  }, [communityId, fetchSeoInfo])

  return (
    <Row gutter={[24, 24]} style={{ paddingTop: 20, maxWidth: 486 }}>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Community Name (5-64 characters)</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={title}
              placeholder="Enter community name..."
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Introduction (5-800 characters)</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input.TextArea
              value={description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              placeholder="Enter community introduction..."
              rows={5}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Website</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={formData.website}
              placeholder="Enter community website..."
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, website: e.target.value }))
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <SpaceVertical>
              <RequiredLabel>Status</RequiredLabel>
              <Select
                style={{ width: '100%' }}
                placeholder="Select status"
                options={StatusOptions}
                value={status}
                onChange={(status) => setFormData({ ...formData, status })}
              />
            </SpaceVertical>
          </Col>
          <Col span={24}>
            <SpaceVertical>
              <Typography.Text type="secondary">Blockchain</Typography.Text>
              <Select
                style={{ width: '100%' }}
                placeholder="Select blockchain"
                mode="multiple"
                options={BlockchainOptions}
                value={blockchain}
                onChange={(blockchain) =>
                  setFormData({ ...formData, blockchain })
                }
              />
            </SpaceVertical>
          </Col>
          <Col span={24}>
            <SpaceVertical>
              <Typography.Text type="secondary">Genre</Typography.Text>
              <Select
                value={genre || []}
                style={{ width: '100%' }}
                mode="tags"
                placeholder="Enter genre..."
                onChange={(genre) => setFormData({ ...formData, genre })}
              />
            </SpaceVertical>
          </Col>
          <Col span={24}>
            <SpaceVertical>
              <RequiredLabel>Slug</RequiredLabel>
              <Row gutter={8} style={{ width: '100%' }}>
                <Col flex="1">
                  <Input
                    value={slug}
                    placeholder="Enter slug..."
                    onChange={(e) =>
                      setFormData({ ...formData, slug: e.target.value })
                    }
                  />
                </Col>
                <Col>
                  <Button
                    ghost
                    disabled={title ? false : true}
                    onClick={() => onChangeSlug(title)}
                  >
                    Generate Slug
                  </Button>
                </Col>
              </Row>
            </SpaceVertical>
          </Col>
        </Row>
      </Col>

      <UploadImageField
        formData={formData}
        onImagesChange={(images) =>
          setFormData((prev) => ({ ...prev, ...images }))
        }
      />

      <Col span={24}>
        <SeoInformation />
      </Col>

      <Col span={24}>
        <Button
          type="primary"
          disabled={submitDisabled}
          onClick={onSave}
          loading={loading}
        >
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default EditCommunity
