import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Col, Input, Row, Space, UploadFile } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import UploadPicture from 'components/systems/uploadPicture'
import RequiredLabel from 'components/systems/requiredSymbol'
import DateRange from 'components/systems/dateRange'
import SpaceVertical from 'components/systems/spaceVertical'
import CardInfo from 'components/systems/cardInfo'

import { useStorage } from 'hooks/systems/useStorage'
import { useSeasonAffiliationMutation } from 'hooks/affiliation-v2/useSeasonAffiliationMutation'

import { SeasonAffiliation } from 'types/affiliation.type'
import { PickPartial } from 'types'

import { TITLE_MIN_LEN, ValueOf } from 'constant'
import { ROUTES } from 'constant/routes'

type SeasonAffiliationInitialType = PickPartial<
  SeasonAffiliation,
  'startDate' | 'endDate'
>

const DUMMY_AFFILIATION_SEASON_DATA: SeasonAffiliationInitialType = {
  name: '',
  totalRewards: 0,
  totalPoints: 0,
  banner: '',
  prizeStructure: [
    { title: 'Top 1', prize: '' },
    { title: 'Top 2', prize: '' },
    { title: 'Top 3', prize: '' },
    { title: 'Top 4 - 6', prize: '' },
    { title: 'Top 7 - 10', prize: '' },
  ],
}

const FormSetUp = () => {
  const navigate = useNavigate()
  const [seasonData, setSeasonData] = useState<SeasonAffiliationInitialType>(
    DUMMY_AFFILIATION_SEASON_DATA,
  )
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const { onUpload, uploading } = useStorage(604800)
  const { mutateAsync, isLoading } = useSeasonAffiliationMutation()

  const onChange = useCallback(
    (name: keyof SeasonAffiliation, value: ValueOf<SeasonAffiliation>) => {
      setSeasonData((prev) => ({ ...prev, [name]: value }))
    },
    [],
  )

  const disabled = useMemo(() => {
    const { name, startDate, endDate, totalRewards } = seasonData

    if (name.trim().length < TITLE_MIN_LEN) return true

    if (!startDate) return true

    if (!endDate) return true

    if (!fileList.length) return true

    if (totalRewards <= 0) return true

    return false
  }, [seasonData, fileList])

  const onSave = useCallback(async () => {
    let thumbnail = seasonData.banner
    if (fileList && fileList[0].originFileObj)
      thumbnail = await onUpload(fileList[0])

    const newSeasonData: SeasonAffiliation = {
      ...seasonData,
      startDate: seasonData.startDate!,
      endDate: seasonData.endDate!,
      banner: thumbnail,
      prizeStructure: seasonData.prizeStructure.filter(
        (prizeInfo) => prizeInfo.title && prizeInfo.prize,
      ),
    }

    await mutateAsync(newSeasonData)
  }, [seasonData, fileList, mutateAsync, onUpload])

  return (
    <Row gutter={[24, 38]}>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Season name (5-500 characters)</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={seasonData.name}
              placeholder="Enter season name..."
              onChange={(e) => onChange('name', e.target.value)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <DateRange
          startAt={seasonData.startDate?.toDateString()}
          endAt={seasonData.endDate?.toDateString()}
          onChangeStartAt={(startDate) =>
            setSeasonData({ ...seasonData, startDate: new Date(startDate) })
          }
          onChangeEndAt={(endDate) =>
            setSeasonData({ ...seasonData, endDate: new Date(endDate) })
          }
        />
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Upload Mission Thumbnail</RequiredLabel>
          </Col>
          <Col span={24}>
            <UploadPicture
              maxCount={1}
              fileList={fileList}
              onChangeFile={({ fileList }) => setFileList(fileList)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RequiredLabel>Total rewards</RequiredLabel>
          </Col>
          <Col span={24}>
            <Input
              value={seasonData.totalRewards}
              placeholder="Enter total rewards..."
              onChange={(e) => onChange('totalRewards', e.target.value)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <CardInfo title="Prize structure">
          <SpaceVertical size={20} style={{ maxWidth: 600 }}>
            {seasonData.prizeStructure.map((prizeInfo, index) => (
              <Space key={prizeInfo.title}>
                <Input
                  value={prizeInfo.title}
                  onChange={(e) => {
                    const prizeStructure = [...seasonData.prizeStructure]
                    prizeStructure[index] = {
                      ...prizeStructure[index],
                      title: e.target.value,
                    }
                    setSeasonData({
                      ...seasonData,
                      prizeStructure,
                    })
                  }}
                />
                <Input
                  value={prizeInfo.prize}
                  onChange={(e) => {
                    const prizeStructure = [...seasonData.prizeStructure]
                    prizeStructure[index] = {
                      ...prizeStructure[index],
                      prize: e.target.value,
                    }
                    setSeasonData({
                      ...seasonData,
                      prizeStructure,
                    })
                  }}
                  placeholder="Input price..."
                  style={{ width: 400 }}
                />
              </Space>
            ))}
          </SpaceVertical>
        </CardInfo>
      </Col>

      <Col span={24}>
        <Row gutter={8} justify="space-between">
          <Col flex="auto">
            <Button
              icon={<IonIcon name="close-outline" />}
              ghost
              onClick={() => navigate(ROUTES.BOUNTY.INDEX)}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              style={{ minWidth: 130 }}
              type="primary"
              onClick={onSave}
              disabled={disabled}
              loading={isLoading || uploading}
            >
              <Space>Save</Space>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FormSetUp
