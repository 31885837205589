import { bountySystemApi as api } from 'services/base-axios'

import { ChainID, DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

const CONTROLLER = '/badge'

class Badge {
  async getAll({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
    chainId,
    supply,
  }: {
    page?: number
    pageSize?: number
    search?: string
    chainId?: ChainID
    supply?: number
  }): Promise<{ data: BadgeData[]; total: number }> {
    return await api
      .get(CONTROLLER, {
        params: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          search,
          chainId,
          supply,
        },
      })
      .then((res) => res.data)
  }

  async getById(itemId: string): Promise<BadgeData> {
    return await api.get(CONTROLLER + `/${itemId}`).then((res) => res.data)
  }
  async create(badge: CreateBadge): Promise<BadgeData> {
    return await api.post(CONTROLLER, badge).then((res) => res.data)
  }
}

export const BadgeService = new Badge()

export type BadgeData = {
  title: string
  configs: BadgeConfigs
  expiredAt: string
  thumbnail: string
  description: string
  supply: number
  _id: string
}

export type CreateBadge = {
  title: string
  configs?: BadgeConfigs
  supply: number
  description: string
  thumbnail: string
}

export type BadgeConfigs = Partial<Record<ChainID, any>>
