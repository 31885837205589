import { marketplaceApi as api } from 'services/base-axios'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

import { DataList } from 'types'

import {
  CardCodeQuantity,
  ICreateDigitalCard,
  IDigitalCard,
  IUpdateDigitalCard,
} from 'types/digital-card'

const CONTROLLER = '/admin/digital-card'

class DigitalCard {
  async getAll({
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    search = '',
  }: {
    page?: number
    pageSize?: number
    search?: string
  }) {
    return await api
      .get<DataList<IDigitalCard[]>>(CONTROLLER, {
        params: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          search,
        },
      })
      .then((data) => data.data)
  }

  async getDigitalCardById(
    id: string,
  ): Promise<IDigitalCard & { cardCodeQuantity: CardCodeQuantity[] }> {
    return await api.get(`${CONTROLLER}/${id}`).then((res) => res.data)
  }

  async create(data: ICreateDigitalCard) {
    return api.post(CONTROLLER, data)
  }

  async update(
    digitalCardId: string,
    data: IUpdateDigitalCard,
  ): Promise<IDigitalCard> {
    return api
      .patch(`${CONTROLLER}/${digitalCardId}`, { ...data })
      .then((data) => data.data)
  }
}

export const DigitalCardService = new DigitalCard()
