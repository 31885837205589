import { useCallback, useState } from 'react'

import { notifyError, notifySuccess } from 'helper'
import { BountyService } from 'services/bountySystem/bounty'

export type MissionPriority = {
  bountyId: string
  priority: number
}
export const usePinnedMissions = () => {
  const [loading, setLoading] = useState(false)

  const getPinnedMissions = useCallback(async () => {
    try {
      setLoading(true)
      const data = await BountyService.getPinnedMissions()
      return data?.data
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  const createPinnedMissions = useCallback(
    async (priorities: MissionPriority[]) => {
      try {
        setLoading(true)
        const data = await BountyService.createPinnedMissions(priorities)
        notifySuccess('Updated successfully!')
        return data
      } catch (err) {
        notifyError(err)
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  return { loading, getPinnedMissions, createPinnedMissions }
}
