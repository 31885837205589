import { LoadingProps } from './index'

const DefaultLoader = ({ size, dotSize }: Required<LoadingProps>) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        fontSize: dotSize,
      }}
      className="sp3-loader"
    >
      <div className="face">
        <div className="circle"></div>
      </div>
      <div className="face">
        <div className="circle"></div>
      </div>
    </div>
  )
}

export default DefaultLoader
