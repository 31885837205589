import { generatePath, useNavigate } from 'react-router-dom'

import { Popconfirm, Space } from 'antd'
import {
  EditOutlined as EditIcon,
  UndoOutlined as RepublishIcon,
  DeleteOutlined as UnpublishedIcon,
} from '@ant-design/icons'

import ButtonIcon from 'components/systems/button-base/ButtonIcon'

import { useAds } from 'hooks/ads/useAds'
import { useUpdateAds } from 'hooks/ads/useUpdateAds'

import { notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import { AdsData, AdsStatus } from 'types/ads.type'

type AdsActionProps = {
  adsDetails: AdsData
}

function AdsAction({ adsDetails }: AdsActionProps) {
  const navigate = useNavigate()
  const { _id: adsId, ...update } = adsDetails
  const isActive = !!adsDetails.isPublished

  const { mutateAsync: updateAds } = useUpdateAds()

  const { refetchRoot } = useAds({ status: AdsStatus.OnGoing })

  return (
    <Space size={0}>
      {isActive && (
        <Popconfirm
          title="Are you sure to Unpublish?"
          onConfirm={async () => {
            await updateAds({
              adsId,
              payload: {
                ...update,
                isPublished: false,
              },
            })
            notifySuccess(`#${adsId} has been unpublished`)
            await refetchRoot()
          }}
        >
          <ButtonIcon
            icon={<UnpublishedIcon />}
            wrapperCls="gacha-row-action-btn"
          />
        </Popconfirm>
      )}

      {!isActive && (
        <Popconfirm
          title="Are you sure to Republish?"
          onConfirm={async () => {
            await updateAds({
              adsId,
              payload: {
                ...update,
                isPublished: true,
              },
            })
            notifySuccess(`#${adsId} has been published`)
            await refetchRoot()
          }}
        >
          <ButtonIcon
            icon={<RepublishIcon rotate={75} />}
            wrapperCls="gacha-row-action-btn"
          />
        </Popconfirm>
      )}

      <ButtonIcon
        icon={<EditIcon />}
        onClick={() => navigate(generatePath(ROUTES.ADS.EDIT_ADS, { adsId }))}
        wrapperCls="gacha-row-action-btn"
      />
    </Space>
  )
}

export default AdsAction
