import { useInfiniteQuery } from 'react-query'
import { GachaService } from 'services/marketplace/gacha'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { GachaListParams } from './useGachaList'

export const useGachaInfinite = ({
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_SIZE,
  search,
  communityId,
  state,
}: GachaListParams | undefined = {}) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [
      'GET_GACHA_INFINITIE',
      { page, pageSize, search, communityId, state },
    ],
    queryFn: async ({ pageParam: page = 1 }) => {
      const { data, total } = await GachaService.findAllGacha({
        search,
        communityId,
        state,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      })

      return {
        nextPage: page < Math.ceil(total / pageSize) ? page + 1 : undefined,
        data,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
  })

  const gachaData = data?.pages.flatMap((page) => page.data)

  return {
    ...rest,
    data: gachaData ?? [],
  }
}
