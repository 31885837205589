import { Typography, Select } from 'antd'
import SpaceVertical from 'components/systems/spaceVertical'

import { ValueOf } from 'constant'
import { CreateBounty } from 'services/bountySystem/bounty'

type MissionTotalPricesProps = {
  prices?: string[]
  onChange: (name: keyof CreateBounty, value: ValueOf<CreateBounty>) => void
}

const MissionTotalPrices = ({ onChange, prices }: MissionTotalPricesProps) => {
  return (
    <SpaceVertical>
      <Typography.Text type="secondary">Total Prices</Typography.Text>
      <Select
        value={prices || []}
        style={{ width: '100%' }}
        mode="tags"
        placeholder="Enter total prices..."
        onChange={(e) => onChange('totalPrices', e)}
      />
    </SpaceVertical>
  )
}

export default MissionTotalPrices
