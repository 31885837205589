import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Category, MarketplaceTabs, SortOptions } from 'constant/marketplace'

export type FilterState = {
  tab: MarketplaceTabs
  category: Category
  sort: SortOptions
  keyword: string
}

/**
 * Store constructor
 */

const NAME = 'productFilters'
const initialState: FilterState = {
  tab: MarketplaceTabs.Popular,
  category: Category.All,
  sort: SortOptions.SortAZ,
  keyword: '',
}

/**
 * Actions
 */

export const updateProductFilter = createAsyncThunk(
  `${NAME}/updateProductFilter`,
  async (filter: Partial<FilterState>) => {
    return { ...filter }
  },
)

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder.addCase(
      updateProductFilter.fulfilled,
      (state, { payload }) => void Object.assign(state, payload),
    ),
})

export default slice.reducer
