import { Image } from 'antd'

import { DeleteOutlined as RemoveIcon } from '@ant-design/icons'

import { useBadgeData } from 'hooks/badges/useBadges'

import './index.less'

type BadgeDetailProps = {
  badgeId?: string
  width?: number
  height?: number
  onRemove: () => void
}

function GachaBadgeDetails({
  badgeId,
  width = 30,
  height = 30,
  onRemove = () => {},
}: BadgeDetailProps) {
  const { badgeData } = useBadgeData(badgeId ?? '')

  return (
    <div className="gacha--img-detail" style={{ padding: 4 }}>
      <Image
        src={badgeData.thumbnail}
        preview={false}
        width={width}
        height={height}
      />

      <div className="gacha--img-detail--remove">
        <RemoveIcon onClick={onRemove} />
      </div>
    </div>
  )
}

export default GachaBadgeDetails
