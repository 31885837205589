import { useQuery } from 'react-query'

import { PlayerLeaderboardService } from 'services/player-leaderboard'

import { FindContributionByProgramDto } from 'types/general-leaderboard/general-leaderboard.type'

export const usePlayerTotalPointsByProgram = (
  dto: FindContributionByProgramDto,
) =>
  useQuery({
    queryKey: ['TOTAL_PLAYER_POINTS_BY_PROGRAM', dto],
    queryFn: () => PlayerLeaderboardService.getTotalPointsByProgram(dto),
    enabled: !!dto.seasonId && !!dto.programIds.length,
  })
