import { marketplaceApi as api } from 'services/base-axios'
import { getAccessToken } from 'services/utils'

import configs from 'configs'

import type {
  BulkMintTicketDto,
  CountGachaTicket,
  CountGachaTicketDto,
  CreateGachaBaseInfoDto,
  FindAllGacha,
  FindGachaRewardDto,
  GachaRewardType,
  IGachaBaseInfo,
  IGachaReward,
  UpdateGachaBaseInfoDto,
  UpsertGachaRewardDto,
} from 'types/gacha.type'
import type { DataList } from 'types'

const CONTROLLER_GACHA = '/admin/gacha'
const CONTROLLER_GACHA_TICKET = '/admin/gacha-ticket'
const CONTROLLER_GACHA_REWARD = '/admin/gacha-reward'

class Gacha {
  /**
   *  Gacha Base Info
   */

  async getGachaBaseInfoBySlug(slug: string) {
    const { data } = await api.get<IGachaBaseInfo>(
      CONTROLLER_GACHA + `/slug/${slug}`,
    )
    return data
  }

  async findAllGacha(payload: FindAllGacha) {
    const { data } = await api.get<DataList<IGachaBaseInfo[]>>(
      CONTROLLER_GACHA,
      {
        params: payload,
      },
    )
    return { data: data?.data ?? [], total: data?.total ?? 0 }
  }

  async getGachaBaseInfoById(gachaId: string) {
    const { data } = await api.get<IGachaBaseInfo>(
      CONTROLLER_GACHA + `/${gachaId}`,
    )
    return data
  }

  async createGachaBaseInfo(payload: CreateGachaBaseInfoDto) {
    const { data } = await api.post<IGachaBaseInfo>(CONTROLLER_GACHA, payload)
    return data
  }

  async updateGachaBaseInfo(payload: UpdateGachaBaseInfoDto) {
    const gachaId = payload._id
    const { data } = await api.put<IGachaBaseInfo>(
      CONTROLLER_GACHA + `/${gachaId}`,
      payload,
    )
    return data
  }

  /**
   *  Gacha Reward
   */

  async getGachaRewards(payload: FindGachaRewardDto) {
    const { data } = await api.get<DataList<IGachaReward[]>>(
      CONTROLLER_GACHA_REWARD,
      {
        params: payload,
      },
    )

    return { data: data?.data ?? [], total: data?.total ?? 0 }
  }

  async upsertGachaReward(payload: UpsertGachaRewardDto) {
    const { data } = await api.post<IGachaReward>(
      CONTROLLER_GACHA_REWARD,
      payload,
    )
    return data
  }

  getDownloadGachaRewardUrl(rewardType: GachaRewardType, gachaId: string) {
    const accessToken = getAccessToken()
    const paramsObj = { rewardType, gachaId, accessToken }

    const searchParams = new URLSearchParams(paramsObj)
    const url = new URL(
      configs.api.marketplaceApi + `${CONTROLLER_GACHA}/export?` + searchParams,
    )

    return url.toString()
  }

  async bulkMintTicket(dto: BulkMintTicketDto) {
    const { data } = await api.post<IGachaReward>(
      `${CONTROLLER_GACHA_TICKET}/bulk-mint`,
      dto,
    )
    return data
  }

  async countGachaTicket(params: CountGachaTicketDto) {
    const { data } = await api.get<CountGachaTicket[]>(
      `${CONTROLLER_GACHA_TICKET}/count`,
      {
        params,
      },
    )
    return data
  }
}

export const GachaService = new Gacha()
