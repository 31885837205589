import DonutElement from 'components/systems/donutElement'
import { WrapLoading } from 'components/systems/loading'
import { ReportData } from 'services/bountySystem/report'

type TaskReportProps = {
  reportData: ReportData
  loading?: boolean
}

const TaskReport = ({ loading = false, reportData }: TaskReportProps) => {
  const data = [
    { label: 'Web3 task', value: reportData.web3Tasks },
    { label: 'System task', value: reportData.systemTasks },
    { label: 'Flexible task', value: reportData.flexibleTasks },
  ]

  return (
    <WrapLoading loading={loading} type="stick">
      <DonutElement data={data} />
    </WrapLoading>
  )
}

export default TaskReport
