import { Tag } from 'antd'
import { BountyRecurrence } from 'constant/bounty'
import { useParseRecurrenceTime } from 'hooks/bounty/useParseRecurrenceTime'

const COLOR = {
  [BountyRecurrence.Once]: '#2F1D1D',
  [BountyRecurrence.Daily]: '#364147',
  [BountyRecurrence.Weekly]: '#25392B',
  [BountyRecurrence.Monthly]: '#332A1C',
}

type TagRecurrenceTimeProps = { time: number }
const TagRecurrenceTime = ({ time }: TagRecurrenceTimeProps) => {
  const parseRecurrenceTime = useParseRecurrenceTime()

  return (
    <Tag
      style={{
        background: COLOR[time],
        padding: '5px 8px',
        minWidth: 80,
        textAlign: 'center',
        textTransform: 'uppercase',
      }}
      className="caption"
    >
      {parseRecurrenceTime(time)}
    </Tag>
  )
}

export default TagRecurrenceTime
