import { Dispatch, SetStateAction } from 'react'
import { Col, Row, Table } from 'antd'

import FilterSearch from 'components/systems/filter/search'
import { COLUMN_COMMUNITY } from './column'

import { ICommunity } from 'types/community.type'
import { WrapLoading } from 'components/systems/loading'

type ListCommunitiesProps = {
  data: ICommunity[]
  setSearch: Dispatch<SetStateAction<string>>
  isLoading: boolean
}

const ListCommunities = ({
  data,
  setSearch,
  isLoading,
}: ListCommunitiesProps) => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row align="middle" gutter={[12, 12]}>
          <Col span={8}>
            <FilterSearch
              placeholder="Search community name..."
              setSearch={setSearch}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <WrapLoading loading={isLoading} type="stick">
          <Table
            rowKey="_id"
            className="table-border-collapse community-table"
            columns={COLUMN_COMMUNITY}
            dataSource={data}
            pagination={false}
          />
        </WrapLoading>
      </Col>
    </Row>
  )
}

export default ListCommunities
