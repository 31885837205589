import { useState } from 'react'

import {
  Button,
  Col,
  Form,
  type FormListFieldData,
  InputNumber,
  Row,
  Space,
} from 'antd'
import { PlusOutlined as AddIcon } from '@ant-design/icons'

import SelectNFTBadge from 'components/systems/selectNFTBadge'
import GachaBadgeDetails from './GachaBadgeDetails'

import type { PriceBadge } from 'types/marketplace.type'

import './index.less'

type PriceBadgeItemProps = {
  prefixPath: FormListFieldData['name']
}

function PriceBadgeItem({ prefixPath }: PriceBadgeItemProps) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Form.Item
      shouldUpdate={(prevValues, curValues) => {
        return (
          prevValues?.variants?.[prefixPath]?.priceBadges !==
          curValues?.variants?.[prefixPath]?.priceBadges
        )
      }}
      noStyle
    >
      {({ getFieldValue, setFieldValue }) => {
        const badgeSelected: PriceBadge[] =
          getFieldValue(['variants', prefixPath, 'priceBadges']) ?? []

        return (
          <Form.List name={[prefixPath, 'priceBadges']}>
            {(fields, { remove }) => (
              <Row gutter={12} align="middle">
                {fields.map(({ name, key, ...restField }) => (
                  <Col key={key}>
                    <Space
                      style={{
                        background: 'rgba(255, 255, 255, 0.04)',
                        height: 'fit-content',
                        padding: '4px 8px',
                        borderRadius: 8,
                      }}
                    >
                      <Form.Item
                        {...restField}
                        valuePropName="badgeId"
                        name={[name, 'badgeId']}
                        noStyle
                      >
                        <GachaBadgeDetails onRemove={() => remove(key)} />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'amount']}
                        initialValue={1}
                        shouldUpdate={false}
                        validateTrigger={['onChange', 'onBlur']}
                        style={{ margin: 0 }}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value < 1) {
                                return Promise.reject(new Error('')) // not show msg, just show error on border style
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          className="show-number-handler"
                          style={{ width: 60 }}
                          size="small"
                          min={1}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                ))}

                <Col>
                  <Button onClick={() => setOpen(true)}>
                    <AddIcon />
                    Badge
                  </Button>
                </Col>

                <SelectNFTBadge
                  open={open}
                  badgeSelected={badgeSelected.map((e) => e.badgeId) ?? []}
                  setBadgeSelected={(value) => {
                    const keepingValue = badgeSelected?.filter((e) =>
                      value.includes(e.badgeId),
                    )
                    const removedValueIds = keepingValue?.map((e) => e.badgeId)
                    const addedValue = value
                      .filter((e) => !removedValueIds.includes(e))
                      .map((e) => ({ badgeId: e, amount: 1 }))

                    setFieldValue(
                      ['variants', prefixPath, 'priceBadges'],
                      [...keepingValue, ...addedValue],
                    )
                  }}
                  setOpen={setOpen}
                  multiple
                />
              </Row>
            )}
          </Form.List>
        )
      }}
    </Form.Item>
  )
}

export default PriceBadgeItem
