import { Typography } from 'antd'

import BadgeInfo from './BadgeInfo'
import RedemptionAction from './Action'

import type { ColumnType } from 'antd/lib/table'
import { IRedemption } from 'types/redemption.type'

export const redemptionColumn: ColumnType<IRedemption>[] = [
  {
    title: 'Name',
    dataIndex: 'badgeId',
    align: 'center',
    render: (badgeId) => <BadgeInfo badgeId={badgeId} />,
    width: 300,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    align: 'center',
    render: (amount: number) => <Typography.Text>{amount}</Typography.Text>,
  },
  {
    title: 'Action',
    align: 'center',
    render: (_, record) => <RedemptionAction redemptionDetails={record} />,
    width: 100,
  },
]
