import { useQuery } from 'react-query'

import { Acceler8Service } from 'services/acceler8'

import { CountParticipantByProgramDto } from 'types/general-leaderboard/general-leaderboard.type'

export const useAcceler8TotalParticipantsByProgram = (
  dto: CountParticipantByProgramDto,
) =>
  useQuery({
    queryKey: ['COUNT_PARTICIPANTS_BY_PROGRAM', dto],
    queryFn: () => Acceler8Service.countParticipantsByProgram(dto),
    enabled: !!dto.seasonId && !!dto.programIds.length,
  })
