import { marketplaceApi as api } from 'services/base-axios'

const CONTROLLER = '/customer'

export type CustomerData = {
  phone: string
  address: string
  firstName: string
  lastName: string
  country: string
  province: string
  city: string
  zipCode: string
}

class Customer {
  async getCustomers() {
    return api.get(CONTROLLER).then((data) => data.data)
  }

  async updateCustomer(customer: CustomerData) {
    return api.post(CONTROLLER, customer).then((data) => data.data)
  }
}

export const customerService = new Customer()
