import { Fragment } from 'react'

import { Form, Input, InputNumber } from 'antd'
import RequiredLabel from 'components/systems/requiredSymbol'
import FormItemSelectChain from 'components/systems/formItemSelectChain'

import { ChainID } from 'constant'

function GasSpentSection() {
  return (
    <Fragment>
      <FormItemSelectChain
        name={['configs', 'chain']}
        initialValue={ChainID.A8}
        disabled={true}
      />

      <Form.Item
        name={['configs', 'toAddress']}
        label={<RequiredLabel children="Inbound contract address" />}
        rules={[
          {
            required: true,
            message: 'Please enter inbound contract address',
          },
        ]}
      >
        <Input placeholder="Enter inbound contract address" />
      </Form.Item>

      <Form.Item
        name={['configs', 'gas']}
        label={<RequiredLabel children="Gas fees spend value" />}
        rules={[
          {
            type: 'number',
            required: true,
            message: 'Please enter gas fees spend value',
          },
        ]}
      >
        <InputNumber placeholder="Enter gas fees spend" />
      </Form.Item>
    </Fragment>
  )
}

export default GasSpentSection
