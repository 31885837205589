import { useMutation } from 'react-query'

import { LeaderboardService } from 'services/bountySystem/leaderboard/leaderboard'
import { UpdateCommunityLeaderboardDto } from 'services/bountySystem/leaderboard/type'

export const useCommunityLeaderboardMutation = ({
  id,
  payload,
}: {
  id: string
  payload: UpdateCommunityLeaderboardDto
}) => {
  return useMutation({
    mutationKey: `UPDATE_COMMUNITY_LEADERBOARD_DATA:${JSON.stringify({
      id,
      payload,
    })}`,
    mutationFn: () =>
      LeaderboardService.updateCommunityLeaderboard(id, payload),
  })
}
