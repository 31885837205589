import { useMemo } from 'react'
import { IOrder } from 'types/order.type'

export const useMapOrderPrice = (orderData: IOrder) => {
  const mapPrice = useMemo(() => {
    const { paidBadge, paidCCP, paidToken } = orderData
    let result: string[] = []
    if (paidBadge?.amount) result.push(`${paidBadge.amount} BADGE`)
    if (paidToken.amount) result.push(`${paidToken.amount} TOKEN`)
    if (paidCCP.amount) result.push(`${paidCCP.amount} CCP`)
    return result
  }, [orderData])
  return mapPrice
}
