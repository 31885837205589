import { Card, Typography } from 'antd'
import { ReactNode } from 'react'

type CardInfoProps = {
  children: ReactNode
  title: string
}

const CardInfo = ({ children, title }: CardInfoProps) => {
  return (
    <Card
      bordered
      headStyle={{ border: 'none', display: 'flex' }}
      title={
        <Typography.Title level={4} type="success">
          {title}
        </Typography.Title>
      }
    >
      {children}
    </Card>
  )
}
export default CardInfo
