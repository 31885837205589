import { Form, InputNumberProps, type FormItemProps } from 'antd'

import InputLabel from 'components/systems/input/InputLabel'

import { type RewardItemProps } from '..'

type AmountFormItemProps = RewardItemProps<
  Pick<FormItemProps, 'noStyle' | 'initialValue' | 'hidden'> & {
    onChange?: InputNumberProps['onChange']
    disabled?: InputNumberProps['disabled']
  }
>

function RewardNumberFormItem({
  prefixNamePath,
  disabled,
  onChange,
  ...props
}: AmountFormItemProps) {
  return (
    <Form.Item
      name={[prefixNamePath, 'total']}
      rules={[
        {
          required: true,
          message: 'Total Rewards must be number',
        },
      ]}
      {...props}
    >
      <InputLabel.Number
        disabled={disabled}
        label="Total Rewards"
        placeholder="Enter the total reward can be distributed"
        type="number"
        min={0}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default RewardNumberFormItem
