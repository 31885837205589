import { ChainID } from 'constant'
import { PriceBadge, PriceToken } from './marketplace.type'
import { LeaderboardPoint } from './product-category.type'

export enum ProductType {
  NFT = 'PRODUCT_TYPE:NFT',
  REAL_NFT = 'PRODUCT_TYPE:REAL_NFT',
}

export const PRODUCT_TYPE_LABEL: Record<ProductType, string> = {
  [ProductType.NFT]: 'NFT',
  [ProductType.REAL_NFT]: 'Real NFT',
}

export enum SortOptions {
  SortAZ = 'sort-a-z',
  SortZA = 'sort-z-a',
  PriceHighLow = 'price-high-low',
  PriceLowHigh = 'price-low-high',
}

export interface IAttributeConfig {
  title: string
  options: string[]
  type: string
  placeholder: string
  hint: string
  required: boolean
}

export interface IBasicInformation {
  communityId: string

  web3ItemId: string

  title: string

  thumbnails: string[]

  description: string

  productType: ProductType

  chainId: ChainID

  categoryId: string

  slug: string
}

export interface IPrice {
  priceCCP: number

  priceToken: PriceToken

  priceBadges: PriceBadge[]

  originalPriceCCP?: number

  originalPriceToken?: PriceToken
}

export interface IVariant extends IPrice {}

export interface IProductData extends IBasicInformation, IPrice {
  specification: Record<string, string>

  variationAttributes: IAttributeConfig[]

  deliveryAttributes: IAttributeConfig[]
  // Private
  totalQuantity: number

  totalFavorite: number

  availableQuantity: number

  sellerWallet: string
  // Schedule
  isDeleted: boolean

  startedAt: string

  isPublish: boolean

  priority?: number

  leaderboardPoint?: LeaderboardPoint
}

export interface ISKU {
  productId: string

  totalAvailable: number

  available: number

  thumbnail: string

  variationId: string

  variations: Record<string, string>

  skuType?: SKUType

  payableMax?: number

  digitalCardId?: string
}

export interface CodeSKU
  extends Partial<
    Pick<ISKU, 'available' | 'totalAvailable' | 'payableMax' | 'digitalCardId'>
  > {}

export enum SKUType {
  CardCode = 'SKUTYPE:CARD_CODE',
}
