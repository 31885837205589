import { CheckboxOptionType } from 'antd'

import { RecurrentTime, RecurrentTimeLabel } from 'constant'

export const RECURRENT_TIME_OPTIONS: CheckboxOptionType[] = Object.keys(
  RecurrentTime,
)
  // number in enum are both key and value so we need to filter number
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({
    label: RecurrentTimeLabel[RecurrentTime[key as keyof typeof RecurrentTime]],
    value: RecurrentTime[key as keyof typeof RecurrentTime],
  }))
