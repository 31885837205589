import { useState } from 'react'

import { Col, Form, Input, Row, Typography } from 'antd'
import ProductAttributes from 'components/product/productAttributes'
import AttributeSettingLayout from './attributeLayout'

import { AttributeComponent } from 'types/product-category.type'
import { AttributeConfig } from 'services/marketplace/product-category.type'

const VariationSetting = ({
  value,
  onChange,
  onRemove,
}: {
  value: AttributeConfig
  onChange: (value: AttributeConfig) => void
  onRemove?: () => void
}) => {
  const [options, setOptions] = useState<string[]>(value.options)
  const [form] = Form.useForm()

  const handleChangeForm = (changedFields: any) => {
    const fieldData: { name: string[]; value: string } = changedFields[0]
    if (!fieldData) return
    onChange({
      ...value,
      [fieldData.name[0]]: fieldData.value,
      required: true,
      type: AttributeComponent.Select,
    })
  }

  const handleChangeOption = (options: string[]) => {
    setOptions(options)
    onChange({ ...value, options })
  }

  return (
    <AttributeSettingLayout title={value.title} onRemove={onRemove}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={value}
        onFieldsChange={handleChangeForm}
      >
        <Row>
          <Col span={24} style={{ maxWidth: 486 }}>
            <Form.Item
              name="title"
              required
              label={
                <Typography.Text type="secondary">
                  Title (5-64 characters)
                </Typography.Text>
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text type="secondary">Options</Typography.Text>
              }
              required
            >
              <ProductAttributes
                options={[]}
                value={options}
                onChange={handleChangeOption}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </AttributeSettingLayout>
  )
}

export default VariationSetting
