import { useQuery } from 'react-query'

import { Acceler8TaskService } from 'services/acceler8-task'
import { GetJobsDto } from 'types/acceler8/acceker8-job.type'

export const useAcceler8Jobs = (dto: GetJobsDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_ACCELER8_JOBS', dto],
    queryFn: () => Acceler8TaskService.getJobs(dto),
    enabled: !!dto.seasonId,
  })

  return { ...rest, data: data ?? [] }
}
