import { useState } from 'react'
import { useDebounce } from 'react-use'

import { Button, Col, Dropdown, Input, MenuProps, Row } from 'antd'

import FilterDropdown from './filterDropdown'
import IonIcon from 'components/systems/ionIcon'
import SubscriptionSetup, { OnSubmitProps } from '../subscriptionSetup'

import { useAcceler8SubscriptionTabSelected } from '../index'
import { useCreateAcceler8Subscription } from 'hooks/acceler8/useCreateAcceler8Subsciption'

import { SortFilterProps } from '../listSubscriptionOverall/index'
import {
  CreateAcceler8SubscriptionDto,
  SubscriptionAction,
} from 'types/acceler8/acceler8-subscription.type'

import { Acceler8SubscriptionTab } from 'constant/acceler8'
import { Acceler8SubscriptionFilter } from 'types/acceler8/acceler8-subscription.type'

import './index.less'

type ControlBarProps = {
  hasAddButton?: boolean
  sortFilter: SortFilterProps
  handleSortFilter: (
    key: string,
    value: string | Acceler8SubscriptionFilter,
  ) => void
}

const ControlBar = ({
  hasAddButton = false,
  sortFilter,
  handleSortFilter,
}: ControlBarProps) => {
  const [currentTab] = useAcceler8SubscriptionTabSelected()
  const { mutate, isLoading: mutateLoading } = useCreateAcceler8Subscription()

  const [keyword, setKeyword] = useState('')

  const items: MenuProps['items'] = [
    {
      label: (
        <FilterDropdown
          sortFilter={sortFilter}
          handleSortFilter={handleSortFilter}
        />
      ),
      key: '0',
    },
  ]

  useDebounce(() => handleSortFilter('searchValue', keyword), 500, [keyword])

  const buttonLabel =
    currentTab === Acceler8SubscriptionTab.Overall ? 'Sort & Filter' : 'Filter'

  const onCreateSubcription = ({ dto, onError, onSuccess }: OnSubmitProps) => {
    mutate(
      {
        dto: dto as CreateAcceler8SubscriptionDto,
      },
      {
        onError,
        onSuccess,
      },
    )
  }

  return (
    <Row gutter={[12, 12]}>
      <Col flex="auto">
        <Input
          placeholder="Search by game name ..."
          onChange={(e) => setKeyword(e.target.value)}
          suffix={<IonIcon name="search-outline" />}
        />
      </Col>
      {hasAddButton && (
        <Col>
          <SubscriptionSetup
            onSubmit={onCreateSubcription}
            action={SubscriptionAction.AddSubscription}
            loading={mutateLoading}
            buttonText=" Add Subscription"
            gameName
          />
        </Col>
      )}

      <Col span={4}>
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          overlayClassName="sort-filter-container--dropdown"
        >
          <Button style={{ width: '100%' }} ghost>
            {buttonLabel}
          </Button>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default ControlBar
