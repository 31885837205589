import { type Key, useState } from 'react'

import { Table } from 'antd'

import { WrapLoading } from 'components/systems/loading'
import { gachaColumn } from './Column'

import type { IGachaWithCount } from 'types/gacha.type'

type GachaListTableProps = {
  data: IGachaWithCount[]
  isLoading: boolean
}

function GachaListTable({ data, isLoading }: GachaListTableProps) {
  const [gachaSelected, setGachaSelected] = useState<Key[]>()

  return (
    <WrapLoading loading={isLoading}>
      <Table
        rowKey="_id"
        rowSelection={{
          selectedRowKeys: gachaSelected,
          onChange: setGachaSelected,
        }}
        className="table-border-collapse"
        columns={gachaColumn}
        dataSource={data}
        pagination={false}
      />
    </WrapLoading>
  )
}

export default GachaListTable
