import { useMutation } from 'react-query'

import { RedemptionService } from 'services/bountySystem/redemption'

import { UpdateRedemptionDto } from 'types/redemption.type'

type UpdateRedemption = {
  redemptionId: string
  payload: UpdateRedemptionDto
}

export const useUpdateRedemption = () =>
  useMutation({
    mutationKey: ['UPDATE_REDEMPTION'],
    mutationFn: ({ redemptionId, payload }: UpdateRedemption) =>
      RedemptionService.update(redemptionId, payload),
  })
