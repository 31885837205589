import { useState } from 'react'
import { useDebounce } from 'react-use'

import { Button, Col, Dropdown, Input, MenuProps, Row } from 'antd'

import FilterDropdown from './filterDropdown'
import IonIcon from 'components/systems/ionIcon'

import { SortFilterProps } from '../listSubscriptionOverall/index'
import { useSubscriptionTabSelected } from '../index'

import { SubscriptionTab } from 'constant/affiliation'

import './index.less'

type ControlBarProps = {
  sortFilter: SortFilterProps
  handleSortFilter: (key: string, value: string | string[]) => void
}

const ControlBar = ({ sortFilter, handleSortFilter }: ControlBarProps) => {
  const [currentTab] = useSubscriptionTabSelected()

  const [keyword, setKeyword] = useState('')

  const items: MenuProps['items'] = [
    {
      label: (
        <FilterDropdown
          sortFilter={sortFilter}
          handleSortFilter={handleSortFilter}
        />
      ),
      key: '0',
    },
  ]

  useDebounce(() => handleSortFilter('searchValue', keyword), 500, [keyword])

  const buttonLabel =
    currentTab === SubscriptionTab.Overall ? 'Sort & Filter' : 'Filter'

  return (
    <Row gutter={[12, 12]}>
      <Col flex="auto">
        <Input
          placeholder="Search by game name ..."
          onChange={(e) => setKeyword(e.target.value)}
          suffix={<IonIcon name="search-outline" />}
        />
      </Col>

      <Col span={4}>
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          overlayClassName="sort-filter-container--dropdown"
        >
          <Button style={{ width: '100%' }} ghost>
            {buttonLabel}
          </Button>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default ControlBar
