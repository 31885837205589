import { Image, Space, Typography } from 'antd'
import { CSSProperties } from 'react'

import imgNodata from 'static/images/system/nodata.svg'

export type NoDataProps = { title?: string; style?: CSSProperties }
const NoData = ({
  style,
  title = 'Has not linked to any spreadsheet file. Please generate a spreadsheet to sync result',
}: NoDataProps) => {
  return (
    <Space
      direction="vertical"
      align="center"
      style={{ width: '100%', ...style }}
    >
      <Image src={imgNodata} preview={false} />
      <Typography.Text style={{ color: '#4F7777' }}>{title}</Typography.Text>
    </Space>
  )
}

export default NoData
