import { useEffect } from 'react'
import { createGlobalState } from 'react-use'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { Card, Col, Row, Space, Typography } from 'antd'
import CardTask from './cardTask'
import Action from './action'
import BountyStatus from './bountyStatus'
import TagRecurrenceTime from 'components/systems/tagRecurrenceTime'
import RewardAvatar from 'components/systems/rewardAvatar'
import MarkdownPreview from 'components/systems/markdown/preview'
import TagCategory from 'components/bounty/tagCategory'

import { TIME_FORMAT } from 'constant'
import { useTaskByBountyId } from 'hooks/task/useTaskByBountyId'
import { DUMMY_BOUNTY_DATA, useBountyData } from 'hooks/bounty/useBountyData'
import { BountyData } from 'services/bountySystem/bounty'

export const useSetBounty = createGlobalState<BountyData>(DUMMY_BOUNTY_DATA)

const BountyDetails = () => {
  const bountyId = useParams().bountyId || ''
  const bountyData = useBountyData(bountyId)
  const tasks = useTaskByBountyId(bountyId)
  const [bounty, setBounty] = useSetBounty()
  const {
    description,
    recurrenceTime,
    endedAt,
    startedAt,
    title,
    category,
    isEnabled,
  } = bounty

  useEffect(() => {
    if (bountyId) setBounty(bountyData)
  }, [bountyData, bountyId, setBounty])

  return (
    <Row gutter={[24, 32]}>
      <Col span={24}>
        <Typography.Title level={2}>{title}</Typography.Title>
      </Col>
      <Col span={24}>
        <Space direction="vertical">
          <Typography.Text type="secondary">Description</Typography.Text>
          <MarkdownPreview value={description} />
        </Space>
      </Col>

      {/* Type */}
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Type</Typography.Text>
              <TagCategory category={category} />
            </Space>
          </Col>
          <Col xs={24} md={12}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Status</Typography.Text>
              <BountyStatus isEnabled={isEnabled} />
            </Space>
          </Col>
        </Row>
      </Col>

      {/* Recurrence */}
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Repeat</Typography.Text>
              <TagRecurrenceTime time={recurrenceTime} />
            </Space>
          </Col>
          <Col xs={24} md={12}>
            <Space direction="vertical">
              <Typography.Text type="secondary">Time</Typography.Text>
              <Space direction="vertical" size={0}>
                <Typography.Text>
                  Start: {moment.utc(startedAt).format(TIME_FORMAT)} UTC
                </Typography.Text>
                <Typography.Text>
                  End: {moment.utc(endedAt).format(TIME_FORMAT)} UTC
                </Typography.Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Space direction="vertical">
          <Typography.Text type="secondary">Reward</Typography.Text>
          <RewardAvatar bountyId={bountyId} />
        </Space>
      </Col>
      <Col span={24}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Typography.Text type="secondary">Task</Typography.Text>
          <Row gutter={[24, 24]}>
            {tasks.length ? (
              tasks.map((taskId) => (
                <Col span={24} key={taskId}>
                  <CardTask taskId={taskId} />
                </Col>
              ))
            ) : (
              <Col span={24}>
                <Card bordered={false}>
                  <Typography.Text type="secondary">No data</Typography.Text>
                </Card>
              </Col>
            )}
          </Row>
        </Space>
      </Col>
      <Col span={24}>
        <Action />
      </Col>
    </Row>
  )
}

export default BountyDetails
