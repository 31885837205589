import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Tabs, Typography } from 'antd'

import ListTeamsLeaderboard from './listTeamsLeaderboard'
import ListCommunityLeaderboard from './listCommunityLeaderboard'

import { useSeason } from 'hooks/leaderboard/useSeason'

import { SeasonTabs } from 'types/season.type'

const TAB_ITEMS = [
  { label: 'Team', key: SeasonTabs.Team },
  { label: 'Community', key: SeasonTabs.Community },
]

const SeasonDetails = () => {
  const [tab, setTab] = useState(SeasonTabs.Team)
  const seasonId = useParams().seasonId || ''
  const { seasonData } = useSeason(seasonId)

  const currentTabContent: React.ReactNode = useMemo(() => {
    switch (tab) {
      case SeasonTabs.Team:
        return <ListTeamsLeaderboard seasonId={seasonId} />
      case SeasonTabs.Community:
        return <ListCommunityLeaderboard seasonId={seasonId} />
    }
  }, [seasonId, tab])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {seasonData?.name}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Tabs items={TAB_ITEMS} onChange={(e) => setTab(e as SeasonTabs)} />
      </Col>
      <Col span={24}>{currentTabContent}</Col>
    </Row>
  )
}

export default SeasonDetails
