import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { customerService } from 'services/marketplace/customer'

/**
 * Interface & Utility
 */

export type CustomerData = {
  _id: string
  phone: string
  address: string
  firstName: string
  lastName: string
  country: string
  province: string
  city: string
  zipCode: string
}

export type CustomerState = Record<string, CustomerData>

/**
 * Store constructor
 */

const NAME = 'customer'
const initialState: CustomerState = {}

/**
 * Actions
 */

export const getCustomers = createAsyncThunk(
  `${NAME}/getCustomers`,
  async () => {
    const bulk: CustomerState = {}
    try {
      const data = await customerService.getCustomers()
      for (const customer of data) {
        bulk[customer._id] = customer
      }
    } catch (error) {}
    return bulk
  },
)

export const updateCustomer = createAsyncThunk<
  Partial<CustomerState>,
  { customer: CustomerData },
  { state: any }
>(`${NAME}/updateCustomer`, async ({ customer }, { getState }) => {
  const { customers } = getState()
  const clonedCustomer = { ...customers }
  try {
    const { data } = await customerService.updateCustomer(customer)
    clonedCustomer[customer._id] = data
  } catch (error) {}

  return clonedCustomer
})

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(
        getCustomers.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        updateCustomer.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      ),
})

export default slice.reducer
