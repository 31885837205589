import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { UserService } from 'services/ancient8/user'

import { AuthType } from 'types/user.type'

export const useUserProfileByUid = (uid: string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['get-user-profile-by-uid', uid],
    queryFn: () => UserService.getUserProfileByUid(uid),
    enabled: !!uid,
  })

  const walletAddress = useMemo(() => {
    return (
      data?.userAuths.find((auth) => auth.type === AuthType.EVMChain)
        ?.credential?.walletAddress ?? ''
    )
  }, [data])

  const twitterId = useMemo(() => {
    return (
      data?.userAuths.find((auth) => auth.type === AuthType.Twitter)?.credential
        ?.twitterUserId ?? ''
    )
  }, [data])

  return { ...rest, profile: data, walletAddress, twitterId }
}
