import { useState } from 'react'

import { Image, Modal, Space, Typography, Upload } from 'antd'
import IonIcon from '../ionIcon'
import SpaceVertical from '../spaceVertical'

import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import { UploadListType, UploadFile } from 'antd/lib/upload/interface'

import './index.less'
import { FormNoticeType } from 'constant'

type UploadPictureProps = {
  maxCount?: number
  onChangeFile?: (param: UploadChangeParam) => void
  accept?: string
  type?: UploadListType
  fileList?: UploadFile[]
  loading?: boolean
  multiple?: boolean
}
const UploadPicture = ({
  onChangeFile,
  maxCount = 10,
  accept = '.png,.jpg,.webp,.jpeg,.gif',
  type = 'picture-card',
  fileList,
  loading = false,
}: UploadPictureProps) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const multiple = maxCount > 1

  return (
    <SpaceVertical>
      <Upload
        disabled={loading}
        accept={accept}
        maxCount={maxCount}
        fileList={fileList}
        onChange={onChangeFile}
        beforeUpload={() => false}
        progress={{ strokeWidth: 2, showInfo: true }}
        listType={type}
        multiple={multiple}
        onPreview={handlePreview}
      >
        <Space direction="vertical" align="center">
          <IonIcon
            name="cloud-upload"
            style={{ color: '#42BAB0', fontSize: 20 }}
          />
          <Typography.Text style={{ fontSize: 12 }} type="success">
            {FormNoticeType.UploadMaxSize}
          </Typography.Text>
        </Space>
      </Upload>

      <Modal
        open={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        closeIcon={<IonIcon name="close-outline" />}
      >
        <Image src={previewImage} preview={false} />
      </Modal>
    </SpaceVertical>
  )
}

export default UploadPicture
