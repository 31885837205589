import { useMutation } from 'react-query'

import { useUpsertGachaReward } from 'hooks/gacha/useUpsertGachaReward'

import { GachaService } from 'services/marketplace/gacha'

import { formatGachaPrice } from 'helper/gacha'

import type {
  CreateGachaBaseInfoDto,
  IGacha,
  IGachaBaseInfo,
} from 'types/gacha.type'

export const useCreateGachaBaseInfo = () => {
  return useMutation({
    mutationKey: ['create-gacha-base-info'],
    mutationFn: (dto: CreateGachaBaseInfoDto) =>
      GachaService.createGachaBaseInfo(dto),
  })
}

/**
 * To create a new gacha:
 *
 * 1. create gacha base info.
 *
 * 2. create list gacha reward:
 *  - get gachaId from response -> map reward with gachaId
 *  => create list gacha reward
 */

export const useCreateGacha = () => {
  const { mutateAsync: createBaseInfo } = useCreateGachaBaseInfo()
  const { mutateAsync: upsertReward } = useUpsertGachaReward()

  return useMutation({
    mutationKey: ['create-gacha'],
    mutationFn: async (payload: IGacha) => {
      const { rewards, ...baseInfo } = payload

      const validBaseInfo = formatGachaPrice(baseInfo as IGachaBaseInfo)
      if (!rewards || !rewards.length) throw new Error('Rewards is required')

      const newGachaData = await createBaseInfo(validBaseInfo)

      if (!newGachaData._id) throw new Error('Gacha ID is required')

      const rewardPayload = rewards.map((reward) => ({
        ...reward,
        total: reward.available,
        gachaId: newGachaData._id!,
      }))

      await upsertReward(rewardPayload)

      return newGachaData
    },
  })
}
