import { ISKU, IProductData } from './product.type'
import { Data } from 'types'

export type TxId<T> = {
  txId: string
} & T

export enum OrderStatus {
  Open = 'ORDER_STATUS:OPEN',
  Initialized = 'ORDER_STATUS:INITIALIZE',
  Paid = 'ORDER_STATUS:PAID',
  Sending = 'ORDER_STATUS:SENDING',
  Done = 'ORDER_STATUS:DONE',
  Fail = 'ORDER_STATUS:FAIL',
}

export enum ShippingStatus {
  Preparing = 'SHIPPING:PREPARING',
  Sending = 'ORDER_STATUS:SENDING',
  Done = 'ORDER_STATUS:DONE',
  Fail = 'ORDER_STATUS:FAIL',
}

export enum PaymentStatus {
  Open = 'PAYMENT_STATUS:OPEN',
  Paid = 'PAYMENT_STATUS:PAID',
}

export type PaidInfo = {
  amount: number
  status: PaymentStatus
}
export interface IOrder {
  uid: string
  quantity: number
  product: string
  productData: Data<IProductData>
  skuData: Data<ISKU>
  sku: string
  paidToken: Data<PaidInfo>
  paidCCP: Data<PaidInfo>
  paidBadge: Data<PaidInfo>
  orderStatus: OrderStatus
  shippingStatus: ShippingStatus
  delivery: Record<string, string>
  createdAt: string
}

export type OrderData = Data<IOrder>

export const SHIPPING_STATUS_LABEL: Record<ShippingStatus, string> = {
  [ShippingStatus.Done]: 'Completed',
  [ShippingStatus.Fail]: 'Cancellation',
  [ShippingStatus.Preparing]: 'To Ship',
  [ShippingStatus.Sending]: 'Shipping',
}
