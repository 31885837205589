import { Col, Row, Typography } from 'antd'

import { useProductCategories } from 'hooks/product/useProduct'
import CardCategory from './cardCategory'
import ControlBar from './controlBar'

const CategoryManagement = () => {
  const categories = useProductCategories()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Category Management
        </Typography.Title>
      </Col>
      <Col span={24}>
        <ControlBar />
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col flex="auto">
            <Typography.Text type="secondary">Category name</Typography.Text>
          </Col>
          <Col>
            <Typography.Text type="secondary">Action</Typography.Text>
          </Col>
          {categories.map((category, idx) => (
            <Col span={24} key={idx}>
              <CardCategory categoryData={category} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default CategoryManagement
