import { useCallback, useEffect, useState } from 'react'

import { Typography, Select, Button } from 'antd'

import SpaceVertical from 'components/systems/spaceVertical'

import { useFeaturedItems } from 'hooks/featured/useFeatured'

import { FeaturedItemType } from 'services/bountySystem/featuredConfig/type'
import { IFeaturedHeroBannerItem } from 'types/featured.type'

import { notifyError } from 'helper'

export type FeaturedInputProps = {
  type: FeaturedItemType
}

const FeaturedInput = ({ type }: FeaturedInputProps) => {
  const [featuredItems, setFeaturedItems] = useState<string[]>()
  const { findFeaturedItems, createFeaturedItems, loading } = useFeaturedItems()

  const handleCreateFeaturedItems = useCallback(() => {
    let payload

    switch (type) {
      case FeaturedItemType.BOUNTY:
        payload = {
          type: FeaturedItemType.BOUNTY,
          bountyIds: featuredItems,
        }
        break
      case FeaturedItemType.PRODUCT:
        payload = {
          type: FeaturedItemType.PRODUCT,
          productIds: featuredItems,
        }
        break
      default:
        throw new Error('Invalid type!')
    }
    return createFeaturedItems(payload)
  }, [type, createFeaturedItems, featuredItems])

  useEffect(() => {
    ;(async () => {
      if (!type) return
      const data = await findFeaturedItems(type)
      if (data) {
        let listItems = data.map((item: IFeaturedHeroBannerItem) =>
          type === FeaturedItemType.BOUNTY ? item.bountyId : item.productId,
        )
        setFeaturedItems(listItems)
      }
    })()
  }, [findFeaturedItems, type])

  return (
    <SpaceVertical>
      <Typography.Text type="secondary">
        List Featured {type === FeaturedItemType.BOUNTY ? 'Bounty' : 'Product'}
      </Typography.Text>
      <Select
        value={featuredItems || []}
        mode="tags"
        style={{ width: '100%' }}
        placeholder="Enter Id"
        onChange={(value) => {
          if (value.length > 5)
            return notifyError('Please input less than 5 items!')
          setFeaturedItems(value)
        }}
      />
      <Button
        type="primary"
        style={{ width: 129, borderRadius: 2 }}
        onClick={handleCreateFeaturedItems}
        loading={loading}
      >
        Save
      </Button>
    </SpaceVertical>
  )
}

export default FeaturedInput
