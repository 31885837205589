import { Card, Col, Row, Typography } from 'antd'

import { shortenAddressCustom } from 'helper'
import IonIcon from 'components/systems/ionIcon'
import { ChainID } from 'constant'

type CollectionInfoCardProps = {
  address?: string
  chainId?: ChainID
}

const CHAIN_CONFIGS = [
  { key: ChainID.BSC, label: 'BNB Chain' },
  { key: ChainID.Solana, label: 'SOLANA Chain' },
]

const CollectionInfoCard = ({ address, chainId }: CollectionInfoCardProps) => {
  return (
    <Card
      bordered={false}
      style={{
        background: '#0F191B',
        borderRadius: 6,
        fontSize: 16,
        width: 348,
      }}
      bodyStyle={{ padding: 16 }}
    >
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Typography.Text type="secondary">Contract address:</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Link
            type="success"
            href={
              chainId === ChainID.BSC
                ? `https://bscscan.com/address/${address}`
                : `https://solscan.io/account/${address}`
            }
            target="_blank"
          >
            {shortenAddressCustom(address)} <IonIcon name="open-outline" />
          </Typography.Link>
        </Col>
      </Row>
      <Row wrap={false}>
        <Col span={12}>
          <Typography.Text type="secondary">Blockchain:</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text type="success">
            {CHAIN_CONFIGS.find(({ key }) => key === chainId)?.label ?? ''}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  )
}

export default CollectionInfoCard
