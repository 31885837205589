import { useCallback, useEffect, useMemo } from 'react'

import { Button, Form, Row, Select } from 'antd'

import BaseInfoSection from '../share/form-section/BaseInfoSection'
import PartnerInfoSection from '../share/form-section/PartnerInfoSection'
import { WrapLoading } from 'components/systems/loading'

import { useCreateAcceler8Task } from 'hooks/acceler8/useCreateAcceler8Task'
import { useAcceler8Task } from 'hooks/acceler8/useAcceler8Task'
import { useUpdateAcceler8Task } from 'hooks/acceler8/useUpdateAcceler8Task'
import { useAcceler8ActiveSeasons } from 'hooks/acceler8/useAcceler8CurrentSeason'
import { usePlayerActiveSeasons } from 'hooks/player-leaderboard/usePlayerCurrentSeason'

import { Acceler8SubscriptionWithGameName } from 'types/acceler8/acceler8-subscription.type'
import { TaskCategory } from 'types/acceler8/acceler8-task.type'
import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

type FormPartnerTaskProps = {
  isEdit: boolean
  taskId?: string
  gameOptions: { label: string; value: string }[]
  selectedGameSubscription?: Acceler8SubscriptionWithGameName
  gameSubscription?: Acceler8SubscriptionWithGameName
  onChangeGame: (gameId: string) => void
} & LeaderboardTaskProps

function FormPartnerGameTask({
  type,
  isEdit,
  taskId,
  gameOptions,
  selectedGameSubscription,
  gameSubscription,
  onChangeGame,
}: FormPartnerTaskProps) {
  const [form] = Form.useForm()

  const { data: taskDetail, isLoading } = useAcceler8Task(taskId)
  const { data: currentSeason } = useAcceler8ActiveSeasons()
  const { data: playerSeasons } = usePlayerActiveSeasons()
  const { mutateAsync: createTask, isLoading: creating } =
    useCreateAcceler8Task()
  const { mutateAsync: updateTask, isLoading: updating } =
    useUpdateAcceler8Task()

  const seasonOptions = useMemo(() => {
    switch (type) {
      case ProgramType.Team:
        return currentSeason?.map((season) => ({
          value: season.id,
          label: season.title,
        }))
      case ProgramType.Player:
        return playerSeasons.map((season) => ({
          value: season.id,
          label: season.title,
        }))
      default:
        return []
    }
  }, [currentSeason, playerSeasons, type])

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields()

    if (isEdit && taskId) {
      const payload = { ...taskDetail, ...values }
      return updateTask({ taskId, dto: payload })
    }

    if (!gameSubscription) return

    const payloadCreate = {
      ...values,
      subscriptionId: gameSubscription.id,
      category: TaskCategory.Game,
      programType: type,
    }

    await createTask(payloadCreate)
  }, [
    createTask,
    form,
    gameSubscription,
    isEdit,
    taskId,
    updateTask,
    taskDetail,
    type,
  ])

  useEffect(() => {
    if (isEdit && taskDetail) {
      form.setFieldsValue(taskDetail)
    }
  }, [form, isEdit, taskDetail])

  useEffect(() => {
    if (selectedGameSubscription) {
      form.setFieldsValue({
        communityId: selectedGameSubscription?.communityId,
      })
    }
  }, [selectedGameSubscription, form])

  useEffect(() => {
    return () => form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrapLoading loading={isLoading} type="stick">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        colon={false}
        requiredMark={false}
        className="form-partner-task-container"
      >
        <Form.Item name="communityId">
          <Select
            options={gameOptions}
            className="select-game"
            onSelect={(value) => {
              onChangeGame(value)
            }}
            disabled={isEdit}
          />
        </Form.Item>

        <BaseInfoSection
          form={form}
          seasonOptions={seasonOptions}
          isEdit={isEdit}
        />

        <PartnerInfoSection form={form} isEdit={isEdit} />

        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="form-partner-task--btn-submit"
            loading={creating || updating}
          >
            {isEdit ? 'Update' : 'Publish'}
          </Button>
        </Row>
      </Form>
    </WrapLoading>
  )
}

export default FormPartnerGameTask
