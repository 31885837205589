import { generatePath, useNavigate } from 'react-router-dom'

import { Popconfirm, Space } from 'antd'
import {
  EditOutlined as EditIcon,
  UndoOutlined as RepublishIcon,
  DeleteOutlined as UnpublishedIcon,
} from '@ant-design/icons'

import ButtonIcon from 'components/systems/button-base/ButtonIcon'

import { useRedemptions } from 'hooks/redemption/useRedemptions'
import { useUpdateRedemption } from 'hooks/redemption/useUpdateRedemption'

import { notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'

import { IRedemption } from 'types/redemption.type'

type RedemptionActionProps = {
  redemptionDetails: IRedemption
}

function RedemptionAction({ redemptionDetails }: RedemptionActionProps) {
  const navigate = useNavigate()
  const { _id: redemptionId, ...update } = redemptionDetails
  const isActive = !!redemptionDetails.isEnabled

  const { mutateAsync: updateRedemption } = useUpdateRedemption()

  const { refetchRoot } = useRedemptions()

  const handleRedemptionStatus = async () => {
    try {
      if (isActive) {
        await updateRedemption({
          redemptionId,
          payload: {
            ...update,
            isEnabled: false,
          },
        })
      } else {
        await updateRedemption({
          redemptionId,
          payload: {
            ...update,
            isEnabled: true,
          },
        })
      }

      notifySuccess(
        `#${redemptionId} has been ${isActive ? 'unpublished' : 'published'}`,
      )
      refetchRoot()
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <Space size={0}>
      {isActive && (
        <Popconfirm
          title="Are you sure to Unpublish?"
          onConfirm={handleRedemptionStatus}
        >
          <ButtonIcon
            icon={<UnpublishedIcon />}
            wrapperCls="gacha-row-action-btn"
          />
        </Popconfirm>
      )}

      {!isActive && (
        <Popconfirm
          title="Are you sure to Republish?"
          onConfirm={handleRedemptionStatus}
        >
          <ButtonIcon
            icon={<RepublishIcon rotate={75} />}
            wrapperCls="gacha-row-action-btn"
          />
        </Popconfirm>
      )}

      <ButtonIcon
        icon={<EditIcon />}
        onClick={() =>
          navigate(
            generatePath(ROUTES.REDEMPTION.EDIT_REDEMPTION, { redemptionId }),
          )
        }
        wrapperCls="gacha-row-action-btn"
      />
    </Space>
  )
}

export default RedemptionAction
