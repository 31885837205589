import {
  CommunitySectionType,
  LinkedMedia,
  MediaType,
  OfficialType,
} from 'types/community-profile'

export const COMMUNITY_PROFILE_DEFAULT_SECTION = {
  // basic info
  orderIndex: 0,
  enabled: true,
  type: CommunitySectionType.Overview,
  // overview
  title: '',
  description: '',
  media: [{ type: MediaType.Image, url: undefined }],
  // system features
  systemFeatures: [
    {
      title: undefined,
      description: undefined,
      media: { type: MediaType.Image, url: undefined },
    },
  ],
  // featured content
  featuredContents: [
    {
      linkedMedia: LinkedMedia.Twitter,
      url: undefined,
      title: undefined,
      description: undefined,
    },
  ],
  // team members
  members: [
    {
      twitterUsername: undefined,
      description: undefined,
    },
  ],
  // company
  companies: [
    {
      twitterUsername: undefined,
    },
  ],
  // official link
  officialLinks: [{ officialType: OfficialType.Website, url: undefined }],
}
