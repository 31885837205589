import moment from 'moment'

import { Typography } from 'antd'
import PriceColumn from '../../priceColumn'
import OrderState from 'components/marketPlace/order/orderState'

import { TIME_FORMAT } from 'constant'
import { shortenAddress } from 'helper'
import { OrderStatus } from 'types/order.type'
import { OrderData } from 'types/order.type'
import { IProductData } from 'types/product.type'

export const DIGITAL_SHIPPING_COLUMN = [
  {
    title: 'Order ID',
    dataIndex: '_id',
    render: (_id: string) => (
      <Typography.Text>{shortenAddress(_id)}</Typography.Text>
    ),
  },
  {
    title: 'UID',
    dataIndex: 'uid',
    render: (uid: string) => (
      <Typography.Text>{shortenAddress(uid)}</Typography.Text>
    ),
  },
  {
    title: 'Item Name',
    dataIndex: 'productData',
    render: (productData: IProductData) => (
      <Typography.Text ellipsis={true}>{productData.title}</Typography.Text>
    ),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    render: (quantity: string) => <Typography.Text>{quantity}</Typography.Text>,
  },
  {
    title: 'Total Price',
    dataIndex: '_id',
    render: (_: string, orderData: OrderData) => (
      <PriceColumn orderData={orderData} />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'orderStatus',
    render: (status: OrderStatus) => <OrderState state={status} />,
  },
  {
    title: 'Time',
    dataIndex: 'createdAt',
    render: (time: string) => (
      <Typography.Text>
        {moment.utc(time).format(TIME_FORMAT)} (UTC)
      </Typography.Text>
    ),
  },
]
