import { Typography } from 'antd'

import RowAction from './rowAction'

import { TeamRank } from 'types/acceler8/acceler8-team.type'
import { ColumnType } from 'antd/lib/table'

export type TeamRankRow = TeamRank & {
  previousRanking?: number
}

export const teamColumn: ColumnType<TeamRankRow>[] = [
  {
    title: 'Rank',
    dataIndex: 'ranking',
    render: (ranking: number) => (
      <Typography.Text>{ranking ?? '-'}</Typography.Text>
    ),
  },
  {
    title: 'Last rank',
    dataIndex: 'previousRanking',
    render: (previousRanking: number) => (
      <Typography.Text>{previousRanking ?? '-'}</Typography.Text>
    ),
  },
  {
    title: 'Team name',
    dataIndex: 'teamName',
    render: (teamName: string) => <Typography.Text>{teamName}</Typography.Text>,
  },
  {
    title: 'Point',
    dataIndex: 'points',
    render: (points: number) => <Typography.Text>{points}</Typography.Text>,
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (id: number) => <RowAction teamId={id} />,
  },
]
