import { useState } from 'react'

import { Button, Col, Row, Space, Typography } from 'antd'

import IonIcon from 'components/systems/ionIcon'
import UploadCSV from './uploadCSV'

import sample from 'static/files/sample-nft-metadata.csv'

const ToolBar = () => {
  const [open, setOpen] = useState(false)
  return (
    <Row align="middle" gutter={[24, 24]} justify="end">
      <Col>
        <Button type="primary" onClick={() => setOpen(true)}>
          <Space>
            <IonIcon name="add" />
            <Typography.Text strong>Upload metadata</Typography.Text>
          </Space>
        </Button>
        <Button
          icon={<IonIcon name="download-outline" />}
          ghost
          href={sample}
          target="_blank"
          download
        >
          Download Template
        </Button>
      </Col>
      {open && <UploadCSV setOpen={setOpen} />}
    </Row>
  )
}

export default ToolBar
