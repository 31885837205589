// Admin
import { ONE_DAY_IN_MILLIS } from 'constant'

export enum BountyTabs {
  System = 'system',
  Discord = 'discord',
}

export enum CreateAndUpdateTabs {
  SetUp = 'Set Up',
  Enter = 'How to Enter',
  Prizes = 'Prizes',
}

export enum BountyState {
  Publish = 'Publish',
  UnPublish = 'UnPublish',
  Ended = 'Ended',
}

export enum BountyFilter {
  SystemTask = 'System Task',
  Web2Task = 'Web2 Task',
  Web3Task = 'Web3 Task',
}

export enum BountyCreateType {
  Discord = 'Create Discord Mission',
  DApp = 'Create DApp Mission',
}

export enum BountyRecurrence {
  Once = 0,
  Daily = ONE_DAY_IN_MILLIS,
  Weekly = ONE_DAY_IN_MILLIS * 7,
  Monthly = ONE_DAY_IN_MILLIS * 30,
}

export enum BountyReward {
  nft = 'NFT BADGE',
  cpp = 'CPP',
}

export enum BountyPublishStyle {
  PublishNow = 'Publish now',
  Schedule = 'Schedule',
}

export enum BountyTaskType {
  Custom = 'Custom',
  Twitter = 'Twitter',
  Discord = 'Discord',
  Telegram = 'Telegram',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
  Token = 'Token',
  ConnectToUID = 'Connect To A8 ID',
  Question = 'Question',
  Partner = 'Partner',
  OnChain = 'On-Chain',
}

export enum OneItemBountyTaskType {
  FederatedApi = 'FederatedApi',
}

export enum BountyTaskTwitter {
  Follow = 'Follow on Twitter',
  Like = 'Like Tweet',
  Connect = 'Connect Tweet',
}

export enum BountyTaskDiscord {
  Join = 'Join a server',
  Like = 'Like Post',
}

export enum BountyTaskTelegram {
  Join = 'Join a Channel/Group',
  View = 'View a Post',
}

export enum BountyTaskNFT {
  Hold = 'Hold NFT',
  Trade = 'Volume trade NFT',
}

export enum BountyTaskToken {
  Hold = 'Hold Token',
  Trade = 'Volume trade Token',
}

export enum BountyTaskWallet {
  Add = 'Add SOL Wallet',
  Connect = 'Connect EVM Wallet',
}

export enum CreateBountyActionState {
  Blank = 'Blank',
  Filling = 'Filling',
  Finish = 'Finish',
}

export enum BountyPrizes {
  FCFS = 'FCFS',
  LuckyDraw = 'Lucky Draw',
}

export enum TableBountyActions {
  Edit = 'Edit',
  Delete = 'Delete',
  Publish = 'Publish',
  UnPublish = 'Unpublish',
  Report = 'Report',
  Schedule = 'Schedule',
}

export enum BountyCategory {
  DApp = 'BOUNTY_CATEGORY:DAPP',
  Discord = 'BOUNTY_CATEGORY:DISCORD',
  ALL = '',
}

export enum BountySubcategory {
  None = '',
  InGame = 'BOUNTY_SUBCATEGORY:IN_GAME',
  SocialMedia = 'BOUNTY_SUBCATEGORY:SOCIAL_MEDIA',
}

// User
export enum BountyUserTabs {
  All = 'All',
  Following = 'Following',
  History = 'History',
}

export enum BountyUserSort {
  Newest = 'Newest Bounty',
  HotBounty = 'Hot Bounty',
  UpComing = 'Up coming',
}

export enum BountyUserDisplay {
  Grid,
  List,
}

export const TASK_NOT_COMPLETED = 'Not Completed'

export enum MissionType {
  Discord = 'discord-mission',
  DApp = 'dapp-mission',
}
