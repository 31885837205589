import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { BountyService } from 'services/bountySystem/bounty'

export const useBountySlug = () => {
  const bountyId = useParams()?.bountyId

  const fetchBountySlug = useCallback(async (slug: string) => {
    return await BountyService.getBountyBySlug(slug)
  }, [])

  const checkIsSlugExisted = useCallback(
    async (slug: string): Promise<boolean> => {
      if (!slug) return false

      try {
        const bounty = await fetchBountySlug(slug)

        if (!bounty._id) return false
        return bounty._id !== bountyId
      } catch (e) {
        // Error mean slug is not existed
        return false
      }
    },
    [bountyId, fetchBountySlug],
  )

  return {
    checkIsSlugExisted,
  }
}
