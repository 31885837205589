import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'

import { ReportHistoryFilter } from 'view/bounty/summaryReport/reportHistory/type'

import {
  BountyCertData,
  BountyData,
  BountyService,
  GetBountyDto,
} from 'services/bountySystem/bounty'
import { BountyCertificateService } from 'services/bountySystem/bountyCert'

import { useCurrentCommunity } from '../community/useCurrentCommunity'

import { notifyError } from 'helper'
import { removeUndefined } from 'helper/object'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'
import { BountyCategory } from 'constant/bounty'

export type BountyState = Record<string, BountyData>

type FilterBounty = {
  page?: number
  category?: BountyCategory
  search?: string
  campaignId?: string
}

export const useBounties = ({
  page = DEFAULT_PAGE,
  category = BountyCategory.ALL,
  search = '',
  campaignId,
}: FilterBounty) => {
  const [community] = useCurrentCommunity()
  const [bounties, setBounties] = useState<BountyState>({})
  const [total, setTotal] = useState(0)

  const fetchBounties = useCallback(async () => {
    const bulk: BountyState = {}
    try {
      const { data, total } = await BountyService.getAll({
        communityId: community?._id,
        page,
        category,
        search: search.length <= 3 ? '' : search,
        campaignId,
      })
      for (const bounty of data) bulk[bounty._id] = bounty
      setTotal(total)
      setBounties(bulk)
    } catch (error) {}
  }, [category, community?._id, page, search, campaignId])

  useEffect(() => {
    fetchBounties()
  }, [fetchBounties])

  return { bounties, total }
}

export const useHandlePublishBounty = (bountyId: string) => {
  const [loading, setLoading] = useState(false)

  const handlePublishBounty = useCallback(
    async (isEnabled: boolean) => {
      try {
        setLoading(true)
        const data = await BountyService.update(bountyId, {
          isEnabled,
        })
        return data
      } catch (err) {
        notifyError(err)
      } finally {
        setLoading(false)
      }
    },
    [bountyId],
  )

  return { handlePublishBounty, loading }
}

export const useBountyCerts = ({
  page = DEFAULT_PAGE,
  search = '',
  startDate = '',
  endDate = '',
  state = ReportHistoryFilter.All,
}) => {
  const [bountyCerts, setBountyCerts] = useState<BountyCertData[]>([])
  const [total, setTotal] = useState(0)

  const fetchBounties = useCallback(async () => {
    try {
      const { data, total } = await BountyCertificateService.getAll({
        page,
        search: search.length <= 3 ? '' : search,
        startDate,
        endDate,
        state,
      })

      setTotal(total)
      setBountyCerts(data)
    } catch (error) {}
  }, [endDate, page, search, startDate, state])

  useEffect(() => {
    fetchBounties()
  }, [fetchBounties])

  return { bountyCerts, total }
}

const BOUNTY_INFINITE_ROOT_KEY = 'GET_BOUNTY_INFINITE'
export const useBountyInfinite = ({
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_SIZE,
  enabled = true,
  ...restDto
}: GetBountyDto & { enabled?: boolean }) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [BOUNTY_INFINITE_ROOT_KEY, page, pageSize, { ...restDto }],
    queryFn: async ({ pageParam = page }) => {
      const { data, total } = await BountyService.getAll(
        removeUndefined({
          page: pageParam,
          pageSize,
          ...restDto,
        }),
      )

      return {
        data,
        nextPage:
          pageParam < Math.ceil(total / pageSize) ? pageParam + 1 : undefined,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled,
  })

  const queryClient = useQueryClient()
  const refetchRoot = () =>
    queryClient.refetchQueries({
      queryKey: [BOUNTY_INFINITE_ROOT_KEY],
    })

  const bounties = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data],
  )

  return {
    data: bounties ?? [],
    refetchRoot,
    ...rest,
  }
}
