import { Button, Card, Col, Row, Typography } from 'antd'
import IonIcon from 'components/systems/ionIcon'

type CardUserProps = {
  uid: string
  _id?: string
  onRemove: (uid: string, _id?: string) => void
}

const CardUser = ({ uid, _id, onRemove }: CardUserProps) => {
  return (
    <Card
      style={{ background: '#0F191B' }}
      bodyStyle={{ padding: '10px 24px' }}
      bordered={false}
    >
      <Row align="middle">
        <Col flex="auto">
          <Typography.Text style={{ color: '#8AADB4' }}>{uid}</Typography.Text>
        </Col>
        <Col>
          <Button
            className="remove-btn"
            type="text"
            icon={<IonIcon name="trash-outline" style={{ fontSize: 24 }} />}
            onClick={() => onRemove(uid, _id)}
          />
        </Col>
      </Row>
    </Card>
  )
}
export default CardUser
