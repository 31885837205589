import { useEffect, useRef, useState } from 'react'
import EasyMDE from 'easymde'

import 'easymde/dist/easymde.min.css'
import './style.less'

export type MarkdownEditorProps = {
  defaultValue?: string
  placeholder?: string
  onChange?: (value: string) => void
  height?: string
}

const MarkdownEditor = ({
  defaultValue = '',
  placeholder = '',
  onChange = (value: string) => {},
  height = 'auto',
}: MarkdownEditorProps) => {
  const [isTyping, setIsTyping] = useState(false)
  const ref = useRef(null)
  const easyMDE = useRef<EasyMDE | null>(null)

  useEffect(() => {
    if (isTyping) return
    if (easyMDE.current) easyMDE.current.toTextArea()

    easyMDE.current = new EasyMDE({
      initialValue: defaultValue,
      placeholder: placeholder,
      element: ref.current || undefined,
      lineNumbers: true,
      showIcons: ['code', 'table'],
      hideIcons: ['side-by-side', 'preview', 'fullscreen', 'guide'],
      minHeight: height,
      maxHeight: height,
      spellChecker: false,
      forceSync: true,
    })
  }, [ref, height, defaultValue, isTyping, placeholder])

  useEffect(() => {
    if (easyMDE.current) {
      easyMDE.current.codemirror.on('focus', () => setIsTyping(true))
      easyMDE.current.codemirror.on('change', (e) => {
        const value = e.getValue()
        onChange(value)
      })
    }
  }, [onChange])

  useEffect(() => {
    return () => {
      if (easyMDE.current) easyMDE.current = null
    }
  }, [])

  return (
    <div className="markdown-editor">
      <textarea ref={ref} style={{ display: 'none' }} />
    </div>
  )
}

export default MarkdownEditor
