import { Select } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { ShippingStatus } from 'types/order.type'

type FilterProps = {
  status?: ShippingStatus
  onSelect?: React.Dispatch<React.SetStateAction<ShippingStatus | undefined>>
}

const FILTER_ITEMS = [
  { label: 'All Shipping Status', value: '' },
  { label: 'To Ship', value: ShippingStatus.Preparing },
  { label: 'Shipping', value: ShippingStatus.Sending },
  { label: 'Cancelled', value: ShippingStatus.Fail },
  { label: 'Completed', value: ShippingStatus.Done },
]

const Filter = ({ status, onSelect }: FilterProps) => {
  return (
    <Select
      value={status === undefined ? ('' as ShippingStatus) : status}
      options={FILTER_ITEMS}
      menuItemSelectedIcon={<IonIcon name="checkmark-outline" />}
      suffixIcon={
        <IonIcon name="filter-outline" style={{ color: '#42BAB0' }} />
      }
      className="dropdown-btn dropdown-filter"
      onChange={onSelect}
    />
  )
}

export default Filter
