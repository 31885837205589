import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useProductList } from './product/useProduct'
import { useSEO } from 'hooks/seo/useSEO'
import { upsetProduct } from 'store/products.reducer'
import { ProductService } from 'services/marketplace/product'
import { notifyError, notifySuccess } from 'helper'

import { ChainID } from 'constant'
import { ProductType } from 'constant/marketplace'
import { ROUTES } from 'constant/routes'

import { SeoType } from 'types/seo.type'
import { LeaderboardPoint } from 'types/product-category.type'
import { PriceBadge, PriceToken } from 'types/marketplace.type'
import { AttributeConfig } from 'services/marketplace/product-category.type'
import { IProductData, ISKU } from 'types/product.type'
import { Data } from 'types'

const DUMMY_PRODUCT_PHYSICAL_DATA: Data<IProductData> = {
  communityId: '',
  _id: '',
  title: '',
  slug: '',
  thumbnails: [''],
  description: '',
  totalQuantity: 0,
  availableQuantity: 0,
  productType: ProductType.REAL_NFT,
  totalFavorite: 0,
  chainId: ChainID.BSC,
  categoryId: '',
  priceCCP: 0,
  priceToken: {
    price: 0,
    tokenAddress: '',
    chainId: ChainID.BSC,
    tokenName: '',
  },
  originalPriceCCP: 0,
  originalPriceToken: {
    price: 0,
    tokenAddress: '',
    chainId: ChainID.BSC,
    tokenName: '',
  },
  priceBadges: [],
  sellerWallet: '',
  specification: {
    Size: '',
    Color: '',
  },
  variationAttributes: [
    {
      title: '',
      options: [''],
      type: 'CPN:SELECT',
      placeholder: '',
      hint: '',
      required: true,
    },
  ],
  deliveryAttributes: [
    {
      title: '',
      options: [],
      type: 'CPN:INPUT',
      placeholder: '',
      hint: '',
      required: true,
    },
  ],
  web3ItemId: '',
  startedAt: '',
  isPublish: true,
  isDeleted: false,
}

type CreateProductData = {
  communityId: string
  title: string
  thumbnails: Array<string>
  description: string
  productType: ProductType
  chainId: ChainID
  priceCCP: number
  priceToken: PriceToken
  priceBadges: PriceBadge[]
  startedAt: string
  specification: Record<string, string>
  variationAttributes: AttributeConfig[]
  deliveryAttributes: AttributeConfig[]
  SKUs: ISKU[]
  originalPriceCCP?: number
  originalPriceToken?: PriceToken
  leaderboardPoint?: LeaderboardPoint
}

export interface UpdateProductData extends Partial<CreateProductData> {}

const useHandleProduct = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { refetch } = useProductList()
  const { createSeoInfo, updateSeoInfo } = useSEO(SeoType.Product)

  const onCreateProduct = useCallback(
    async (data: CreateProductData) => {
      try {
        setLoading(true)
        const result = await ProductService.create(data)
        dispatch(upsetProduct({ productId: result._id, data: result }))
        await createSeoInfo(result._id)
        notifySuccess('Create product')
        refetch()
        navigate(ROUTES.REWARDS_CENTER.ITEMS)
      } catch (error) {
        notifyError(error)
      } finally {
        setLoading(false)
      }
    },
    [createSeoInfo, dispatch, navigate, refetch],
  )

  const onUpdateProduct = useCallback(
    async ({
      data,
      productId,
    }: {
      productId: string
      data: CreateProductData
    }) => {
      try {
        setLoading(true)
        const result = await ProductService.update(productId, data)
        updateSeoInfo()
        dispatch(upsetProduct({ productId: result._id, data: result }))
        notifySuccess('Update product')
        refetch()
        return navigate(ROUTES.REWARDS_CENTER.ITEMS)
      } catch (error) {
        notifyError(error)
      } finally {
        setLoading(false)
      }
    },
    [dispatch, navigate, refetch, updateSeoInfo],
  )

  const getProductData = useCallback(async (productId: string) => {
    try {
      setLoading(true)
      const data = await ProductService.get(productId)
      return data
    } catch (err) {
      return DUMMY_PRODUCT_PHYSICAL_DATA
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, onCreateProduct, onUpdateProduct, getProductData }
}

export default useHandleProduct
