import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Button, Col, Input, Row, Select, Typography, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'

import CardInfo from 'components/systems/cardInfo'
import SpaceVertical from 'components/systems/spaceVertical'
import RequiredLabel from 'components/systems/requiredSymbol'
import UploadPicture from 'components/systems/uploadPicture'

import { useProductCategories } from 'hooks/useProductCategory'
import useHandle from 'hooks/useHandle'
import { useStorage } from 'hooks/systems/useStorage'
import useHandleImageSize from 'hooks/useHandleImageSize'
import { useProductSlug } from 'hooks/product/useProductSlug'
import useDebounce from 'hooks/useDebounce'

import { generateSlug } from 'helper'

import { DEFAULT_BASIC_INFO_DATA, FILE_SIZE_ONE_MB } from 'constant/marketplace'
import { FormNoticeType } from 'constant'

import { IBasicInformation } from 'types/product.type'

type FormBasicInfoProps = {
  value?: IBasicInformation
  onChange: Dispatch<SetStateAction<IBasicInformation>>
}

const FormBasicInfo = ({
  value = DEFAULT_BASIC_INFO_DATA,
  onChange,
}: FormBasicInfoProps) => {
  const [isSlugInvalid, setIsSlugInvalid] = useState(false)
  const { checkIsSlugExisted } = useProductSlug()
  const slugDebounce = useDebounce(value.slug, 500)

  const fileList = useMemo<UploadFile[] | undefined>(() => {
    if (!value) return undefined
    return value.thumbnails.map((thumbnail, index) => {
      return { name: '', uid: `${index}`, url: thumbnail }
    })
  }, [value])

  const [loading, setLoading] = useState(false)
  const { data: categories } = useProductCategories()
  const { onUpload } = useStorage(604800)
  let { onHandleImageSize } = useHandleImageSize()
  let fileListCount = useRef(fileList?.length)

  function bytesToMB(bytes: number) {
    return bytes / FILE_SIZE_ONE_MB
  }

  const handleChangeFile = useHandle(
    async ({ fileList }: UploadChangeParam) => {
      if (fileList.length === fileListCount.current) {
        return
      }
      const lastCount = fileListCount.current
      fileListCount.current = fileList.length

      if (fileList && fileList.length >= 6) {
        throw new Error('Cannot upload over 5 images.')
      }

      let sum = 0
      let thumbnails: Set<string> = new Set()

      for (let file of fileList) {
        if (file.url) {
          const fileSizeUpload: number =
            (await onHandleImageSize(file.url)) || 0
          const size = bytesToMB(fileSizeUpload)
          sum += size
          thumbnails.add(file.url)
        } else {
          const imageSize: number = bytesToMB(file.size || 0)
          sum += imageSize
        }

        if (sum > 20) {
          fileListCount.current = lastCount
          throw new Error('Cannot upload images over 20MB.')
        }
      }

      await Promise.all(
        fileList.map(async (file: any) => {
          if (!file?.url) {
            const image = await onUpload(file)
            thumbnails.add(image)
          }
        }),
      )

      onChange((prevValue) => ({
        ...prevValue,
        thumbnails: Array.from(thumbnails),
      }))
    },
    setLoading,
  )

  const options = useMemo(() => {
    if (!categories) return []
    return categories.map((elm) => ({
      label: elm.title,
      value: elm._id,
    }))
  }, [categories])

  useEffect(() => {
    const slug = generateSlug(slugDebounce)
    onChange((prevState) => ({
      ...prevState,
      slug,
    }))
    checkIsSlugExisted(slug).then((isExisted) => {
      setIsSlugInvalid(isExisted)
    })
  }, [slugDebounce, checkIsSlugExisted, onChange])

  return (
    <CardInfo title="Basic Information">
      <Row gutter={[24, 24]} style={{ maxWidth: 486 }}>
        <Col span={24}>
          <SpaceVertical>
            <Typography.Text type="secondary">
              {FormNoticeType.UploadMaxSize}
            </Typography.Text>
            <UploadPicture
              fileList={fileList}
              onChangeFile={handleChangeFile}
              maxCount={6}
              loading={loading}
            />
            <Typography.Text type="secondary">
              Upload or drag and drop a PNG, GIF, or JPG to display in the app.
            </Typography.Text>
          </SpaceVertical>
        </Col>
        <Col span={24}>
          <SpaceVertical>
            <RequiredLabel>Title (5-64 characters)</RequiredLabel>
            <Input
              placeholder="Enter name"
              value={value.title}
              onChange={(e) => onChange({ ...value, title: e.target.value })}
            />
          </SpaceVertical>
        </Col>
        <Col span={24}>
          <SpaceVertical>
            <RequiredLabel>Slug</RequiredLabel>
            <Input
              placeholder="Enter name"
              value={value.slug}
              onChange={(e) =>
                onChange((prevState) => ({
                  ...prevState,
                  slug: e.target.value,
                }))
              }
              suffix={
                <Button
                  type="primary"
                  onClick={() => {
                    onChange((prevState) => ({
                      ...prevState,
                      slug: generateSlug(value?.title || ''),
                    }))
                  }}
                  disabled={value.title.length < 5}
                >
                  Generate Slug
                </Button>
              }
            />
            {isSlugInvalid && (
              <Typography.Text type="danger">
                Slug is already existed!
              </Typography.Text>
            )}
          </SpaceVertical>
        </Col>
        <Col span={24}>
          <SpaceVertical>
            <Typography.Text type="secondary">Category</Typography.Text>
            <Select
              placeholder="Select category"
              options={options}
              value={value.categoryId}
              style={{ width: '100%' }}
              onChange={(categoryId) => onChange({ ...value, categoryId })}
            />
          </SpaceVertical>
        </Col>
        <Col span={24}>
          <SpaceVertical>
            <Typography.Text type="secondary">
              Description (5-800 characters)
            </Typography.Text>
            <Input.TextArea
              placeholder="Enter description..."
              value={value.description}
              onChange={(e) =>
                onChange({ ...value, description: e.target.value })
              }
            />
          </SpaceVertical>
        </Col>
      </Row>
    </CardInfo>
  )
}

export default FormBasicInfo
