import { useQuery } from 'react-query'

import { LeaderboardService } from 'services/bountySystem/leaderboard/leaderboard'
import { FindCommunityLeaderboardDto } from 'services/bountySystem/leaderboard/type'

import { CommunityLeaderboardData } from 'types/leaderboard.type'

export const useCommunitiesLeaderboard = (
  payload: FindCommunityLeaderboardDto,
) => {
  const { data, isLoading, error } = useQuery<CommunityLeaderboardData[]>({
    queryKey: ['GET_COMMUNITY_LEADERBOARD_DATA', payload],
    queryFn: () => LeaderboardService.getCommunities(payload),
    cacheTime: 0,
  })

  return {
    data: data || [],
    isLoading,
    error,
  }
}
