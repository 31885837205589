import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import IonIcon from 'components/systems/ionIcon'
import MobileUserAction from './mobileUserAction'

import UserAction from './userAction'
import useIsMobile from 'hooks/systems/useIsMobile'

import { logout } from 'store/user.reducer'

import configs from 'configs'

const A8_PROFILE = configs.api.ancient8Profile

export const DROPDOWN_MENUS = [
  {
    label: 'Profile',
    key: 'profile',
    icon: <IonIcon name="browsers-outline" />,
  },
  {
    label: 'Logout',
    key: 'log-out',
    icon: <IonIcon name="log-out-outline" />,
  },
]

const UserActionContainer = () => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const onDropItemClick = useCallback(
    (e: any) => {
      switch (e.key) {
        case 'profile':
          return window.open(A8_PROFILE, '_blank')
        case 'log-out':
          return dispatch(logout())
      }
    },
    [dispatch],
  )

  if (isMobile) return <MobileUserAction onClick={onDropItemClick} />

  return <UserAction onClick={onDropItemClick} />
}

export default UserActionContainer
