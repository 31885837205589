import { Image, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import Title from './title'

import { ChainID, ChainLabel } from 'constant'
import { NFT_TYPE_LABEL } from 'constant/nft'

import { INft, NftType } from 'types/nft.type'

export const COLUMN_NFT: ColumnsType<INft> = [
  {
    title: 'Name',
    dataIndex: '',
    render: ({ chainId, address }: INft) => (
      <Title chainId={chainId} address={address} />
    ),
    width: '25%',
  },
  {
    title: 'Chain',
    dataIndex: 'chainId',
    render: (chainId: ChainID) => (
      <Typography.Text>{ChainLabel[chainId]}</Typography.Text>
    ),
    width: '10%',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    render: (address: string) => <Typography.Text>{address}</Typography.Text>,
    width: '40%',
  },
  {
    title: 'NFT Type',
    dataIndex: 'nftType',
    render: (nftType: NftType) => (
      <Typography.Text>{NFT_TYPE_LABEL[nftType]}</Typography.Text>
    ),
    width: '15%',
  },
  {
    title: 'Thumbnail',
    dataIndex: 'thumbnail',
    render: (thumbnail: string) => (
      <Image src={thumbnail} width={50} height={50} preview={false} />
    ),
    align: 'left',
  },
]
