import { useMemo, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import { Card, Col, DatePicker, Row, Typography } from 'antd'

import SpaceChart from './spaceChart'
import IonIcon from 'components/systems/ionIcon'

import { ReportService } from 'services/bountySystem/report'

const DATE_FORMAT = 'YYYY-MM-DD'

const ChartStatistical = () => {
  const [startDate, setStartDate] = useState(
    moment.utc().utcOffset(-moment.parseZone().utcOffset()),
  )
  const [endDate, setEndDate] = useState(
    moment.utc().utcOffset(-moment.parseZone().utcOffset()).add(10, 'days'),
  )

  const { data: statistical } = useQuery(
    `summary-report:statistical-${startDate}:${endDate}`,
    () =>
      ReportService.statistical(
        moment.utc(startDate).format(DATE_FORMAT),
        moment.utc(endDate).format(DATE_FORMAT),
      ),
  )

  const labels = useMemo(() => {
    if (!statistical) return []
    return statistical.map(({ date }) => date)
  }, [statistical])

  const data = useMemo(() => {
    if (!statistical) return []
    return statistical.map(({ total }) => total)
  }, [statistical])

  return (
    <Card bordered={false} bodyStyle={{ padding: 32, background: '#0F191B' }}>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Row align="middle">
            <Col flex="auto">
              <Typography.Title level={3}>Statistics</Typography.Title>
            </Col>
            <Col>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <DatePicker
                    onChange={(date) =>
                      setStartDate(
                        moment
                          .utc(date)
                          .utcOffset(-moment.parseZone().utcOffset()),
                      )
                    }
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                    value={startDate}
                    suffixIcon={<IonIcon name="calendar-outline" />}
                    allowClear
                  />
                </Col>
                <Col span={12}>
                  <DatePicker
                    onChange={(date) =>
                      setEndDate(
                        moment
                          .utc(date)
                          .utcOffset(-moment.parseZone().utcOffset()),
                      )
                    }
                    style={{ width: '100%' }}
                    disabledDate={(currentDate) =>
                      currentDate.isBefore(moment.utc(startDate))
                    }
                    format={DATE_FORMAT}
                    value={endDate}
                    suffixIcon={<IonIcon name="calendar-outline" />}
                    allowClear
                    clearIcon={<IonIcon name="close-circle" />}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <SpaceChart labels={labels} yData={data} />
        </Col>
      </Row>
    </Card>
  )
}

export default ChartStatistical
