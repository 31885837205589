import { useState } from 'react'
import { useDebounce } from 'react-use'

import { Input } from 'antd'

import IonIcon from 'components/systems/ionIcon'

const SearchBar = ({ setSearch }: { setSearch: (keyword: string) => void }) => {
  const [keyword, setKeyword] = useState('')

  useDebounce(() => setSearch(keyword), 500, [keyword])

  return (
    <Input
      placeholder="Search team name..."
      onChange={(e) => setKeyword(e.target.value)}
      suffix={<IonIcon name="search-outline" />}
    />
  )
}

export default SearchBar
