import { Table } from 'antd'

import { WrapLoading } from 'components/systems/loading'
import { redemptionColumn } from './Column'

import { IRedemption } from 'types/redemption.type'

type RedemptionTableProps = {
  data: IRedemption[]
  isLoading: boolean
}

function RedemptionTable({ data, isLoading }: RedemptionTableProps) {
  return (
    <WrapLoading loading={isLoading}>
      <Table
        rowKey="_id"
        className="table-border-collapse"
        columns={redemptionColumn}
        dataSource={data}
        pagination={false}
      />
    </WrapLoading>
  )
}

export default RedemptionTable
