import IonIcon from './ionIcon'
import { Button, Pagination, Space } from 'antd'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

const FIRST_PAGE = 1

export type CustomPaginationProps = {
  pageSize?: number
  onChange?: (page: number) => void
  total: number
  page?: number
}

const CustomPagination = ({
  pageSize = DEFAULT_PAGE_SIZE,
  page = DEFAULT_PAGE,
  onChange = () => {},
  total = 0,
}: CustomPaginationProps) => {
  const onPageChange = (page: number) => {
    onChange(page)
  }

  const lastPage = Math.ceil(total / pageSize)

  return (
    <Space>
      <Button
        size="small"
        className="btn-pagination"
        onClick={() => onPageChange(FIRST_PAGE)}
        disabled={page === 1}
        icon={<IonIcon name="arrow-double-left" style={{ fontSize: 12 }} />}
      />
      <Pagination
        total={total}
        defaultPageSize={pageSize}
        showSizeChanger={false}
        current={page}
        onChange={onPageChange}
      />
      <Button
        size="small"
        onClick={() => onPageChange(lastPage)}
        className="btn-pagination"
        disabled={page === lastPage}
        icon={<IonIcon name="arrow-double-right" style={{ fontSize: 12 }} />}
      />
    </Space>
  )
}

export default CustomPagination
