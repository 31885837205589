export interface IPlayerRank {
  id: number

  seasonId: number

  userId: string

  points: number

  updatedAt: string

  referralPercents?: number[]
}

export type PlayerRankDataResponse = IPlayerRank & {
  ranking: number
}

export type PlayerAccumulativePoints = {
  userId: string
  accumulativePoints: number
}

export enum PlayerSortOptions {
  CurrentSeasonRank = 'PLAYER_SORT:RANK',
  LastPointContributed = 'PLAYER_SORT:LAST_CONTRIBUTED',
}

export type FindPlayerRankDto = {
  seasonId?: number
  userIds?: string[]
  offset?: number
  limit?: number
  sort?: PlayerSortOptions
}

export type UpdateReferralPercent = {
  userId: string
  seasonId: number
  referralPercents: number[] | null
}

export type GetPlayerPointsDistributionDto = {
  uid: string
  seasonId: number
}

export type PlayerPointsDistribution = {
  programId: number
  organicPoints: number
  refPoints: number
}
