import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Col, Row, Tabs, Typography } from 'antd'
import { PlusSquareOutlined as AddSquareIcon } from '@ant-design/icons'

import AdsListTable from './AdsListTable'
import ControlBar from './controlBar'

import { useAds } from 'hooks/ads/useAds'

import { ROUTES } from 'constant/routes'

import { AdsSortOptions, AdsStatus } from 'types/ads.type'

const tabItems = [
  {
    key: AdsStatus.OnGoing,
    label: 'ON GOING',
  },
  {
    key: AdsStatus.Ended,
    label: 'ENDED',
  },
  {
    key: AdsStatus.Unpublished,
    label: 'UNPUBLISHED',
  },
]

function AdsManagement() {
  const navigate = useNavigate()

  const [status, setStatus] = useState<AdsStatus>(AdsStatus.OnGoing)
  const [sort, setSort] = useState<AdsSortOptions>(AdsSortOptions.Newest)

  const { data, isLoading } = useAds({ status, sort })

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Ads Management
        </Typography.Title>
      </Col>

      <Col span={24}>
        <Row justify="end" gutter={[12, 12]}>
          <Col>
            <ControlBar sort={sort} onChangeSort={setSort} />
          </Col>
          <Col style={{ width: 180 }}>
            <Button
              icon={<AddSquareIcon />}
              type="primary"
              block
              onClick={() => navigate(ROUTES.ADS.NEW_ADS)}
            >
              Create New Ads
            </Button>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Tabs
          activeKey={status}
          onChange={(key) => setStatus(key as AdsStatus)}
          type="card"
          items={tabItems}
        />
      </Col>
      <Col span={24}>
        <AdsListTable data={data} isLoading={isLoading} />
      </Col>
    </Row>
  )
}

export default AdsManagement
