import { Key, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createGlobalState } from 'react-use'

import { Col, Row, Table, Typography } from 'antd'

import NewAndFilterBar, { useFilterProps } from './newAndFilterBar'
import BountiesSelected from './bountySelected'
import CustomPagination from 'components/systems/pagination'
import { COLUMN_BOUNTY } from './column'

import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'

import { BountyData, BountyService } from 'services/bountySystem/bounty'

import { DEFAULT_PAGE } from 'constant'
import { ROUTES } from 'constant/routes'
import { BountyCategory, BountyState } from 'constant/bounty'

import './index.less'

export type BountySelected = Record<BountyState, Array<Key>>
export type Bounties = Array<BountyData>
const { Ended, Publish, UnPublish } = BountyState
export const useSetBounties = createGlobalState<Bounties>([])

const DUMMY_DATA = { [Publish]: [], [UnPublish]: [], [Ended]: [] }

type ListBountyProps = { category: BountyCategory }

const ListBounty = ({ category }: ListBountyProps) => {
  const [community] = useCurrentCommunity()
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [totalPage, setTotalPage] = useState(0)
  const [bounties, setBounties] = useSetBounties()
  const [bountySelected, setBountySelected] =
    useState<BountySelected>(DUMMY_DATA)
  const [{ search, status }] = useFilterProps()
  const navigate = useNavigate()

  let statusFilter = useRef({})
  const currentStatus = useRef(status)
  const currentPage = useRef(page)

  const handleFilterStatus = useCallback(() => {
    if (currentStatus.current !== status) {
      currentPage.current = DEFAULT_PAGE
    }

    switch (status) {
      case BountyState.Publish: {
        statusFilter.current = { isEnabled: true }
        break
      }

      case BountyState.Ended: {
        statusFilter.current = { isEnded: true }
        break
      }

      case BountyState.UnPublish: {
        statusFilter.current = { isEnabled: false }
        break
      }

      default: {
        statusFilter.current = { isEnabled: true }
      }
    }
  }, [status])

  const fetchBounties = useCallback(async () => {
    try {
      handleFilterStatus()

      if (page === currentPage.current) {
        currentPage.current = DEFAULT_PAGE
      } else {
        currentPage.current = page
      }
      const { data: bounties, total } = await BountyService.getAll({
        communityId: community?._id,
        page: currentPage.current,
        category,
        search: search.length <= 3 ? '' : search,
        ...statusFilter.current,
      })
      setBounties(bounties)
      setTotalPage(total)
    } catch (error) {}
  }, [handleFilterStatus, page, community?._id, category, search, setBounties])

  const onSelect = (bounties: Key[]) => {
    setBountySelected({ ...bountySelected, [status]: bounties })
  }

  const updateBounties = (oldBounties: Key[]) => {
    let nextBounties = [...bounties]
    nextBounties = nextBounties.filter(({ _id }) => !oldBounties.includes(_id))
    return setBounties(nextBounties)
  }

  useEffect(() => {
    fetchBounties()
  }, [fetchBounties])

  return (
    <Row gutter={[24, 64]}>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <NewAndFilterBar />
          </Col>
          <Col span={24}>
            <Table
              rowKey="_id"
              rowSelection={{
                selectedRowKeys: bountySelected[status],
                onChange: onSelect,
              }}
              className="table-border-collapse"
              columns={COLUMN_BOUNTY}
              dataSource={bounties}
              onRow={({ _id }) => {
                return {
                  onClick: () => {
                    navigate(`${ROUTES.BOUNTY.INDEX}/${_id}`)
                  },
                }
              }}
              pagination={false}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row align="middle">
          <Col flex="auto">
            <Typography.Text type="success">Total: {totalPage}</Typography.Text>
          </Col>
          <Col>
            <CustomPagination
              total={totalPage}
              onChange={setPage}
              page={page}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <BountiesSelected
          updateBounties={updateBounties}
          bountySelected={bountySelected}
          setBountySelected={setBountySelected}
        />
      </Col>
    </Row>
  )
}

export default ListBounty
