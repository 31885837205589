import { Table } from 'antd'

import { WrapLoading } from 'components/systems/loading'
import { COLUMN_ADS } from './Column'

import { AdsData } from 'types/ads.type'

type AdsListTableProps = {
  data: AdsData[]
  isLoading: boolean
}

function AdsListTable({ data, isLoading }: AdsListTableProps) {
  return (
    <WrapLoading loading={isLoading} type="stick">
      <Table
        rowKey="_id"
        className="table-border-collapse"
        columns={COLUMN_ADS}
        dataSource={data}
        pagination={false}
      />
    </WrapLoading>
  )
}

export default AdsListTable
