import countries from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'

countries.registerLocale(en)

export const countriesObject = countries.getNames('en', { select: 'official' })
export const countryOptions = Object.keys(countriesObject).map((country) => {
  return {
    label: countriesObject[country],
    value: country,
    searchKey: countriesObject[country] + ' ' + country,
  }
})
