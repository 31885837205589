import { useCallback, useEffect, useState } from 'react'

import { Col, Row, Select, Typography } from 'antd'

import { useBountySetup } from 'view/bounty/management'
import { useBounties } from 'hooks/bounty/useBounties'

import { notifyError } from 'helper'
import { CreateBounty } from 'services/bountySystem/bounty'
import { ValueOf } from 'constant'

type SelectType = {
  value: string
  label: string
}

function LinkedMissions() {
  const [search, setSearch] = useState('')
  const { bounties } = useBounties({ search })
  const [options, setOptions] = useState<SelectType[]>([])
  const [bountyData, setBountyData] = useBountySetup()

  useEffect(() => {
    const newOptions: SelectType[] = Object.values(bounties).map((bounty) => ({
      value: bounty._id,
      label: bounty.title,
    }))
    setOptions(newOptions)
  }, [bounties])

  const onChange = (name: keyof CreateBounty, value: ValueOf<CreateBounty>) => {
    setBountyData({ ...bountyData, [name]: value })
  }

  const onSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Typography.Text type="secondary">Linked missions</Typography.Text>
      </Col>
      <Col span={24}>
        <Select
          value={bountyData.linkedMissions || []}
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Enter Id"
          onChange={(value) => {
            if (value.length > 6)
              return notifyError('Please input less than 6 items!')
            onChange('linkedMissions', value)
          }}
          onSearch={onSearch}
          options={options}
          filterOption={(input, option) => {
            return (option?.label ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }}
        />
      </Col>
    </Row>
  )
}

export default LinkedMissions
