import { useQuery } from 'react-query'

import { useUserProfile } from 'hooks/user/useUserProfile'

import { bountySystemApi as api } from 'services/base-axios'

export enum Role {
  User = 'USER_ROLE::USER',
  Admin = 'USER_ROLE::ADMIN',
  Operator = 'USER_ROLE::OPERATOR',
}

export interface User {
  _id: string
  uid: string
  role: Role
}

export const ROLE_LABEL: Record<Role, string> = {
  [Role.Admin]: 'Admin',
  [Role.Operator]: 'Operator',
  [Role.User]: 'User',
}

export const useUser = () => {
  const profile = useUserProfile()

  const data = useQuery(
    `user:data:${profile._id}`,
    () => api.get<User>('/user'),
    {
      staleTime: Infinity,
      enabled: !!profile._id,
    },
  )

  return data
}

export const useAdmins = () => {
  const data = useQuery('user:admins', () => api.get<User[]>('/user/admins'), {
    staleTime: 100000000,
  })
  return data
}
