import { Fragment, useMemo } from 'react'

import { Button, Form, type FormInstance, Select } from 'antd'

import WeightFormItem from './shared/WeightFormItem'
import TypeFormItem from './shared/TypeFormItem'
import TierFormItem from './shared/TierFormItem'
import AvailableFormItem from './shared/AvailableFormItem'

import { useDigitalCardsInfinite } from 'hooks/digitalCards/useDigitalCards'

import { type RewardItemProps } from '.'

function RewardDigitalCardItem({
  prefixNamePath,
  form,
}: RewardItemProps<{ form: FormInstance }>) {
  const {
    digitalCards: digitalCardsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useDigitalCardsInfinite({
    enabled: true,
  })

  const digitalCardsItem = useMemo(
    () =>
      digitalCardsData.map((e) => ({
        value: e._id,
        label: `${e.title} - available: ${e.total}`,
      })),
    [digitalCardsData],
  )

  return (
    <Fragment>
      <TierFormItem prefixNamePath={prefixNamePath} />

      <Form.Item
        name={[prefixNamePath, 'config', 'amount']}
        initialValue={1}
        hidden
        noStyle
      />

      <Form.Item name={[prefixNamePath, 'config', 'digitalCardId']}>
        <Select
          showSearch
          placeholder="Search and Select Digital Card"
          options={digitalCardsItem}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => {
            const digitalCard = digitalCardsData.find(
              (item) => item._id === value,
            )

            form.setFieldValue(
              ['rewards', prefixNamePath, 'available'],
              digitalCard?.total ?? 0,
            )
          }}
          dropdownRender={(menu) => (
            <Fragment>
              {menu}
              {hasNextPage && (
                <Button
                  block
                  type="primary"
                  onClick={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                >
                  Load more
                </Button>
              )}
            </Fragment>
          )}
        />
      </Form.Item>

      <AvailableFormItem prefixNamePath={prefixNamePath} hidden noStyle />

      <WeightFormItem prefixNamePath={prefixNamePath} />

      {/* NOTE: for css dom correctly, type must be placed at the very bottom */}
      <TypeFormItem prefixNamePath={prefixNamePath} />
    </Fragment>
  )
}

export default RewardDigitalCardItem
