import { useQuery } from 'react-query'

import { GachaService } from 'services/marketplace/gacha'

import { FindGachaRewardDto } from 'types/gacha.type'

export const useGachaReward = (dto: FindGachaRewardDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['GET_GACHA_REWARDS', dto],
    queryFn: () => GachaService.getGachaRewards(dto),
    enabled: !!dto.gachaId,
  })

  return {
    ...rest,
    data: data?.data ?? [],
    total: data?.total ?? 0,
  }
}
