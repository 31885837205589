import { useEffect, useState } from 'react'
import moment from 'moment'

import {
  Col,
  Row,
  Typography,
  Button,
  InputNumber,
  Space,
  DatePicker,
} from 'antd'

import Loading from 'components/systems/loading'

import { useCommunity } from 'hooks/community/useCommunity'

import { bountySystemApi as api } from 'services/base-axios'
import { notifyError, notifySuccess } from 'helper'

import { DATE_FORMAT } from 'constant'

const SystemCcp = () => {
  const [ccpExpired, setCcpExpired] = useState(0)
  const [ccpMerged, setCcpMerged] = useState(0)
  const [timeMark, setTimeMark] = useState<moment.Moment | null>(null)
  const [loading, setLoading] = useState(false)
  const { data } = useCommunity()

  useEffect(() => {
    if (!data?.data) return
    setCcpExpired(data.data.ccpLifeMonths)
    setCcpMerged(data.data.ccpMergeMonths)
    setTimeMark(moment.utc(data.data.ccpTimeMark))
  }, [data?.data])

  const handleSave = async () => {
    try {
      setLoading(true)
      if (!timeMark) throw new Error('Invalid time mark')

      await api.patch('/community', {
        ccpTimeMark: timeMark.toISOString(),
        ccpLifeMonths: ccpExpired,
        ccpMergeMonths: ccpMerged,
      })
      notifySuccess('Updated System CCP')
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  if (!data) return <Loading loading />

  return (
    <Row gutter={[24, 24]} style={{ paddingTop: 20, maxWidth: 486 }}>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">
            {`Time Mark (${DATE_FORMAT})`}
          </Typography.Text>
          <DatePicker
            value={timeMark}
            style={{ width: '100%' }}
            onChange={(e) => setTimeMark(e)}
            format={DATE_FORMAT}
          />
        </Space>
      </Col>

      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">Expired Months</Typography.Text>
          <InputNumber
            value={ccpExpired}
            placeholder="Enter discord id..."
            onChange={(e) => setCcpExpired(Number(e))}
          />
        </Space>
      </Col>

      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text type="secondary">Merged Months</Typography.Text>
          <InputNumber
            value={ccpMerged}
            placeholder="Enter discord id..."
            onChange={(e) => setCcpMerged(Number(e))}
          />
        </Space>
      </Col>

      <Col span={8}>
        <Button type="primary" onClick={handleSave} loading={loading}>
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default SystemCcp
