import { useCallback, useState } from 'react'

import { FeaturedService } from 'services/bountySystem/featuredConfig'
import { FeaturedItemType } from 'services/bountySystem/featuredConfig/type'

import { notifyError, notifySuccess } from 'helper'

export const useFeaturedItems = () => {
  const [loading, setLoading] = useState(false)

  const findFeaturedItems = useCallback(async (type: FeaturedItemType) => {
    try {
      setLoading(true)
      const data = await FeaturedService.find({ type })
      return data
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  const createFeaturedItems = useCallback(
    async (payload: Parameters<typeof FeaturedService.create>[0]) => {
      try {
        setLoading(true)
        const data = await FeaturedService.create(payload)
        notifySuccess('Updated successfully')
        return data
      } catch (err) {
        notifyError(err)
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  return { loading, findFeaturedItems, createFeaturedItems }
}
