import { stringify } from 'qs'
import { bountySystemApi as api } from 'services/base-axios'

const CONTROLLER = '/storage'
const PATHS = {
  upload: '/upload',
}

class Storage {
  async upload(data: FormData, cacheMaxAge?: number) {
    let url = CONTROLLER + PATHS.upload

    if (cacheMaxAge) {
      url += `?${stringify({
        cacheMaxAge,
      })}`
    }

    return api.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    })
  }
}

export const StorageService = new Storage()
