import { ChainID } from 'constant'
import { Data } from 'types'
import { PriceBadge } from 'types/marketplace.type'
import { IBasicInformation, IProductData } from 'types/product.type'

export const FILE_SIZE_ONE_MB = 2 ** 10 * 2 ** 10

export enum MarketplaceTabs {
  Popular = 'Popular',
  Newest = 'Newest',
  MyFavorite = 'My Favorite',
}

export enum ProductType {
  NFT = 'PRODUCT_TYPE:NFT',
  REAL_NFT = 'PRODUCT_TYPE:REAL_NFT',
}

export enum MarketItemType {
  Digital = 'NFT',
  Physical = 'Off-chain Item',
}

export enum Category {
  All = 'All',
  NFT = 'NFT',
  Real = 'Real',
  Cosmetic = 'Cosmetic',
}

export enum SortOptions {
  SortAZ = 'sort-a-z',
  SortZA = 'sort-z-a',
  PriceHighLow = 'price-high-low',
  PriceLowHigh = 'price-low-high',
}

export const SORT_OPTIONS = [
  { value: SortOptions.SortAZ, label: 'Sort A-Z' },
  { value: SortOptions.SortZA, label: 'Sort Z-A' },
  { value: SortOptions.PriceHighLow, label: 'Price High-Low' },
  { value: SortOptions.PriceLowHigh, label: 'Price Low-High' },
]

export enum MarketItemCreateType {
  Physical = 'Add Physical Item',
  Digital = 'Add Digital Item',
  Code = 'Add Code Item',
}

export enum MarketItemCreateRoute {
  Physical = 'new-physical',
  Digital = 'new-digital',
  Code = 'new-code',
}

export enum MarketItemState {
  Publish = 'Publish',
  Unpublish = 'Unpublish',
  Ended = 'Ended',
}

export enum TableMarketActions {
  Edit = 'Edit',
  Delete = 'Delete',
  Publish = 'Publish',
  UnPublish = 'Unpublish',
  Export = 'Export',
}

export enum Currency {
  SOL = 'sol',
  USDT = 'usdt',
  USDC = 'usdc',
  BUSD = 'busd',
  BNB = 'bnb',
  ETH = 'eth',
  MATIC = 'matic',
  WETH = 'weth',
  ARB = 'arb',
}

export enum PriceType {
  CCP = 'CCP',
  NFTBadge = 'NFTBadge',
  TOKEN = '',
}

export const DUMMY_PRICE_TOKEN = {
  price: 0,
  tokenAddress: '',
  chainId: ChainID.BSC,
  tokenName: Currency.BNB,
}

export const DUMMY_PRICE_BADGE: PriceBadge[] = []

export enum UserShippingAction {
  MoveToShipping = 'Move to Shipping',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export const DUMMY_PRODUCT_DATA: Data<IProductData> = {
  communityId: '',
  _id: '',
  availableQuantity: 0,
  categoryId: '',
  chainId: ChainID.BSC,
  description: '',
  priceBadges: DUMMY_PRICE_BADGE,
  priceCCP: 0,
  priceToken: DUMMY_PRICE_TOKEN,
  productType: ProductType.REAL_NFT,
  thumbnails: [''],
  title: '',
  slug: '',
  totalQuantity: 0,
  startedAt: '',
  deliveryAttributes: [],
  isDeleted: false,
  specification: {},
  variationAttributes: [],
  isPublish: true,
  sellerWallet: '',
  totalFavorite: 0,
  web3ItemId: '',
  originalPriceCCP: 0,
  originalPriceToken: DUMMY_PRICE_TOKEN,
}

export const DEFAULT_BASIC_INFO_DATA: IBasicInformation = {
  communityId: '',
  web3ItemId: '',
  thumbnails: [],
  title: '',
  description: '',
  chainId: ChainID.BSC,
  productType: ProductType.REAL_NFT,
  categoryId: '',
  slug: '',
}
