import { useQuery } from 'react-query'

import { Acceler8TaskService } from 'services/acceler8-task'

export const useAcceler8TasksByPrograms = (programIds?: number[]) => {
  return useQuery({
    queryKey: ['get-player-point-distribution', programIds],
    queryFn: () => Acceler8TaskService.getTasksByProgramIds(programIds!),
    enabled: !!programIds?.length,
  })
}
