import { Card, Col, Row } from 'antd'
import useIsMobile from 'hooks/systems/useIsMobile'
import HeaderContainer from './container'
import HeaderMobileView from './mobileView'

import './index.less'

const Header = () => {
  const isMobile = useIsMobile()

  return (
    <Card
      bordered={false}
      style={{ background: '#070D0E', borderBottom: '1px solid #242F30' }}
      bodyStyle={{ padding: 16 }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {isMobile ? <HeaderMobileView /> : <HeaderContainer />}
        </Col>
      </Row>
    </Card>
  )
}

export default Header
